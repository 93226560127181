import React from "react";
import { Link } from "react-router-dom";

function NavItem(props) {
    return (
        <li>
            <Link to={ props.url }>
                <span className={"icon "+ props.icon}></span>
                { props.title }
            </Link>
        </li>
    );
};

export default NavItem;
