import React, {useEffect, useState}  from 'react';
import axios from 'axios';
import {useDropzone} from 'react-dropzone'

const prettyBytes = require('pretty-bytes');

function TechnicianCertificationSubmit(props) {
    useEffect(function() {

        const getCertification = async () => {
            let count = props.overlayVisible;
            props.setOverlayVisible( (count) => count + 1);


            axios.get(process.env.REACT_APP_API_ENDPOINT+'/certifications/technician/'+props.match.params.certification+'/',
                { withCredentials: true, headers: {'Content-Type': 'application/json'}})
            .then((response) => {

                setCertification({...certification, technician: response.data.technician, id: response.data.id, study_exam: response.data.study_exam, status: response.data.status, study: response.data.study});
                props.setOverlayVisible( (count) => count - 1);

            }, (error) => {
                props.setOverlayVisible( (count) => count - 1);
            });
        };

        props.setNavLinks([{title: 'Studies', url: '/studies/', icon: 'studies'},{title: 'Certification', url: '/studies/'+props.match.params.id+'/certifications/', icon: 'certification'},{title: 'Submit', url: props.location.pathname, icon: 'submit'}]);

        getCertification();

        props.setShowPrompt(true);
        window.addEventListener('beforeunload', props.showPromptHandler);
        //on unmount
        return () => {
            props.setShowPrompt(false);
            window.removeEventListener('beforeunload', props.showPromptHandler);
        };
    }, []);

    const [certification, setCertification] = useState({
        "id": 0,
        "technician": null,
        "user_submitted": null,
        "admin": null,
        "study_exam": null,
        "status": "",
        "created_at": "",
        "updated_at": "",
        "deleted_at": null,
        "submission_date": "",
        "grading_date": "",
        "submitter_comment": "",
        "study": {},
        "technician_files": [],
        "technician_certification_grading": [],
    });

    const [certificationErrors, setCertificationErrors] = useState([]);
    const [noFiles, setNoFiles] = useState(false);
    const [showAdminFiles, setShowAdminFiles] = useState(false);
    const [uploadedFiles, setUploadedFiles] = useState([]);
    const [acceptTerms, setAcceptTerms] = useState(false);

    const [percentUploaded, setPercentUploaded] = useState(0);

    const closeErrorPopup = () => {
        setCertificationErrors([]);
    }

    const submitHandler = (e) => {
        e.preventDefault();

        submitCertification();
    };

    const submitCertification = async () => {

        const errMsg = "Sorry, something went wrong when updating this patient. Please contact your IT helpdesk.";
        const url = process.env.REACT_APP_API_ENDPOINT+'/certifications/technician/'+certification.id+'/';
        const formData = new FormData();
        const config = {
            withCredentials: true,
            headers: {
                'content-type': 'multipart/form-data'
            },
            onUploadProgress: progressEvent => {
                setPercentUploaded( Math.round( (progressEvent.loaded * 100) / progressEvent.total ) );
            }
        };

        let errors = [];

        if (!noFiles && !uploadedFiles.length) {
            errors.push("You are submitting without files. Please add files or check the checkbox");
        }

        if (!acceptTerms) {
            errors.push("Please accept the terms of this certification");
        }

        // if validation fails, show error popup
        if (errors.length) {
            setCertificationErrors(errors);
            return;
        }


        for (let i in uploadedFiles) {
            formData.append('file',uploadedFiles[i].file);
        }

        formData.set('study', certification.study.id);
        formData.set('comment', certification.submitter_comment ? certification.submitter_comment.trim() : "");
        formData.set('submission_date', certification.submission_date);

        let count = props.overlayVisible;
        props.setFilesUploading(true);
        props.setOverlayVisible( (count) => count + 1);

        axios.patch(url, formData, config).then(function (response) {

            props.setOverlayVisible( (count) => count - 1);
            props.setFilesUploading(false);

            if (response.status === 200) {
                props.setShowPrompt(false);

                // redirect to list of visits of this patient
                props.history.push("/studies/"+props.match.params.id+"/certifications");
            }
            else {
                alert(errMsg);
            }
        },
            (error) => {
                props.setOverlayVisible( (count) => count - 1);
                props.setFilesUploading(false);
                alert(errMsg);
            }
        );


    };

    return (

        <React.Fragment>

        {certificationErrors.length > 0 && (
            <React.Fragment>
            <div className="modal-backdrop fade in"></div>
            <div id="alertModal" className="modal hide fade in" tabIndex="-1" role="dialog" aria-labelledby="myModalLabel" aria-hidden="false" style={{display: "block"}}>
                <div className="modal-header">
                    <button type="button" className="close" data-dismiss="modal" aria-hidden="true">×</button>
                    <h3 id="alert-modal-title">Errors!</h3>
                </div>
                <div className="modal-body"><br/>
                    <div className="errorSummary"><p>Please fix the following input errors:</p>
                        <ul>
                            {certificationErrors.map((message) => (
                            <li key={message}>{message}</li>
                            ))}
                        </ul>
                    </div><br />
                </div>
                <div className="modal-footer">
                    <button onClick={closeErrorPopup} id="alert-modal-ok" aria-hidden="true" className="btn small orange align-center right" style={{display: "block"}}>OK</button>
                </div>
            </div>
            </React.Fragment>
        )}

        <div className="home_header_container_class">
            <div className="content">
                <div className="breadcrumbs">
                    <div className="path">
                        <ul>
                            <li><span className="orange text-shadow">{certification.study && certification.study.acronym ? certification.study.acronym.toUpperCase() : ""}</span></li>
                            <li className="separator text-shadow">&gt;</li>
                            <li><span className="orange text-shadow">Certifications</span></li>
                            <li className="separator text-shadow">&gt;</li>
                            <li><span className="orange text-shadow">Technician</span></li>
                            <li className="separator text-shadow">&gt;</li>
                            <li><span className="orange text-shadow">{certification.study_exam && certification.study_exam.exam_type.name}</span></li>
                            <li className="separator text-shadow">&gt;</li>
                            <li>{certification.technician && certification.technician.username}</li>
                        </ul>
                    </div>
                </div> {/* .breadcrumbs */}

                <div className="main-form">
                    <form onSubmit={submitHandler} id="register-exam" method="post">

                        <TechnicianCertificationData
                            certification={certification}
                            setCertification={setCertification}
                        />

                        <TechnicianCertificationTerms
                            acceptTerms={acceptTerms}
                            setAcceptTerms={setAcceptTerms}
                        />
                    </form>

                    <TechnicianCertificationFiles
                        certification={certification}
                        setCertification={setCertification}
                        noFiles={noFiles}
                        setNoFiles={setNoFiles}
                        showAdminFiles={showAdminFiles}
                        setShowAdminFiles={setShowAdminFiles}
                        uploadedFiles={uploadedFiles}
                        setUploadedFiles={setUploadedFiles}
                        percentUploaded={percentUploaded}
                    />

                    <TechnicianCertificationActions
                        submitCertification={submitCertification}
                    />
                </div>
            </div>
        </div>
        </React.Fragment>

    );
}

export default TechnicianCertificationSubmit;

function TechnicianCertificationData(props) {

    const onChangeHandler = (ev) => {
        let name = ev.target.name;
        let value = ev.target.value;

        props.setCertification({...props.certification, [name]: value});
    };

    return (
        <div className="form-group">

            <div className="span5" style={{width: "96%"}}>
                <textarea
                    style={{height: "200px", width: "100%"}}
                    className="submit-comment"
                    maxLength="5000"
                    placeholder="Comments"
                    name="submitter_comment"
                    id="submitter_comment"
                    value={props.certification.submitter_comment}
                    onChange={(ev) => onChangeHandler(ev)}
                ></textarea>
            </div>
        </div>
    );
}

function TechnicianCertificationTerms(props) {

    return (
        <div className="form-group content">
            <br />
            <input
                type="checkbox"
                id="checkbox-accept"
                checked={props.acceptTerms}
                onChange={(ev) => {props.setAcceptTerms(ev.target.checked)}}
            />
            <label htmlFor="checkbox-accept" className="wrapped">I have read and understand the relevant protocols (i.e.. Study Protocol and CORC Technical Protocol(s)), and I agree to abide by the design and procedures of the trial.</label>
            <br />
        </div>
    );
}


function TechnicianCertificationFiles(props) {

    return (
        <React.Fragment>

        {!props.noFiles && (
        <TechnicianCertificationFileUploader
            uploadedFiles={props.uploadedFiles}
            setUploadedFiles={props.setUploadedFiles}
            percentUploaded={props.percentUploaded}
        />
        )}

        <TechnicianCertificationNoFiles
            noFiles={props.noFiles}
            setNoFiles={props.setNoFiles}
        />

        {props.certification.technician_files.map( (file, i) => (
        <TechnicianCertificationFileItem
            certification={props.certification}
            key={i}
            file={file}
        />
        ))}

        </React.Fragment>
    );

}


function TechnicianCertificationFileUploader(props) {

    const removeFileHandler = (ev,file) =>  {

        ev.preventDefault();

        let editedUploadedFiles = props.uploadedFiles.filter(f => f.key !== file.key);
        props.setUploadedFiles(editedUploadedFiles);

        return false;
    };

    const onDrop = acceptedFiles => {
        let newFiles = [];

        acceptedFiles.map(file => {
            newFiles.push({file: file, key: Math.floor(Math.random(5) * 1000), name: file.name});
            return false;
        });

        newFiles = newFiles.concat(props.uploadedFiles);
        props.setUploadedFiles(newFiles);
    };

    const {getRootProps, getInputProps} = useDropzone({onDrop});

    return (
        <form id="fileupload" method="post" encType="multipart/form-data">
            <div className="form-group">
                <div id="files" className="files">
                    <div {...getRootProps({
                            className: "info"
                    })}>
                        <span className="icon submit ico"></span>
                        <h2 className="text-shadow">Drop files here</h2>
                        <span className="or">
                            <hr />
                            <span>or</span>
                            <hr />
                        </span>
                        <span className="btn large orange fileinput-button">
                            <span>Select files from your computer</span>
                            <input {...getInputProps()} />
                        </span>
                    </div>
                    <div id="filesProgress" className="clearfix">
                        {props.uploadedFiles.map((file, i) => (
                        <div
                            key={i}
                            className="file-upload-box">
                            <p>
                                <span className="file-name">{file.name}</span>
                                <span className="file-actions">
                                    <button
                                        className="btn small grey align-center left other"
                                        onClick={(ev) => removeFileHandler(ev, file)}
                                    >REMOVE</button>
                                </span>
                                <span className="progress active">
                                    <span className="progress-bar progress-bar-warning" role="progressbar" style={{width: `${props.percentUploaded}%`}}></span>
                                    <span className="progress_value">
                                        {props.percentUploaded ? `${props.percentUploaded} % (a few seconds left)` : 'READY TO UPLOAD' }
                                    </span>
                                </span>
                            </p>
                        </div>
                        ))}
                    </div>
                </div>
            </div>
        </form>
    );
}


function TechnicianCertificationNoFiles(props) {
    return (
        <div className="form-group content" id="nofileinput">
            <br />

            <input
                type="checkbox"
                id="checkbox-nofiles"
                checked={props.noFiles}
                onChange={(ev) => props.setNoFiles(ev.target.checked)}
            />
            <label htmlFor="checkbox-nofiles">No files are to be submitted</label>
            <br />
        </div>
    );
}


function TechnicianCertificationFileItem(props) {
    return (
        <div className="form-group">
            <div className="file-uploaded">
                <div className="row">
                    <a target="_blank" rel="noopener noreferrer" href={process.env.REACT_APP_API_ENDPOINT + "/certifications/equipment/"+props.certification.id+'/file/'+props.file.id+'/download/'} className="cancel-btn-class btn small grey align-center">Download</a>
                    <div className="file-name">
                        <span className="filename-class">{props.file.name}<b>{" (" + prettyBytes(props.file.file && props.file.file.size ? props.file.file.size : 0) + ")"}</b></span>
                    </div>
                    <div className="file-comment" style={{width: "40%", float: "right", marginRight: "32px"}}>
                        <input
                            maxLength="255"
                            type="text"
                            className="uploaded_file"
                            value={props.file.description}
                            placeholder="Comment"
                        />
                    </div>
                </div>
            </div>
        </div>
    );
}

function TechnicianCertificationActions(props) {
    const onSubmitHandler = () => {
        props.submitCertification();
    };

    const buttons = () => {
        return (
            <div style={{textAlign: "center",overflow: "hidden"}}>
                <button
                    className="btn-submit-form-two"
                    onClick={onSubmitHandler}
                >
                    <span className="submit-icon"></span>
                    Finish and Submit
                </button>
            </div>
        );
    };

    return (
        <div className="form-actions" style={{float: "right"}}>
            {buttons()}
        </div>

    );
}
