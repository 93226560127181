import React, {useEffect, useState}  from 'react';
import axios from 'axios';
import {Link} from 'react-router-dom';
import CorcSelect from 'components/utils/CorcSelect';

function Certifications(props) {

    useEffect(function() {

        const getStudy = async () => {
            let count = props.overlayVisible;
            props.setOverlayVisible( (count) => count + 1);

            const items = await axios.get(process.env.REACT_APP_API_ENDPOINT+'/studies/'+props.match.params.id+'/certifications/pending/',
                { withCredentials: true, headers: {'Content-Type': 'application/json'}});

            setStudy(items.data);

            let centers = {};
            items.data.centers.map( center => {
                centers[center.center.id] = center.study_site_num;
                return false;
            });
            setInitialCenters(centers);


            setInitialEquipCertifications(JSON.parse(JSON.stringify(items.data.equipment_certifications)));
            setInitialTechCertifications(JSON.parse(JSON.stringify(items.data.technician_certifications)));

            setActiveStatus('pending');
            //applyFilters();

            props.setOverlayVisible( (count) => count - 1);
        };

        if (props.userAuth.user.role === 'tech' || props.userAuth.user.role === 'coordinator') {
            props.history.push("/studies/"+props.match.params.id);
        }

        props.setNavLinks([{title: 'Studies', url: '/studies/', icon: 'studies'},{title: 'Certification', url: props.location.pathname, icon: 'certification'}]);
        getStudy();
    }, []);


    const [initialEquipCertifications, setInitialEquipCertifications] = useState([]);
    const [initialTechCertifications, setInitialTechCertifications] = useState([]);
    const [initialCenters, setInitialCenters] = useState({});

    const [study, setStudy] = useState({
        'id': 0,
        'status': '',
        'acronym': '',
        'full_name': '',
        'start_date': '',
        'end_date': '',
        'description': '',
        'select_patient_eye': false,
        'patient_details': '',
        'protocol_num': '',
        'corc_num': '',
        'exams': [],
        'centers': [],
        'scheduled_visits': [],
        'technicians': [],
        'graders': [],
        'monitors': [],
        'technician_certifications': [],
        'equipment_certifications': [],
    });

    const [activeStatus, setActiveStatus] = useState(false);
    const [activeCenter, setActiveCenter] = useState(false);
    const [activeExam, setActiveExam] = useState(false);

    const [hasNotStarted, setHasNotStarted] = useState(false);
    const [hasCompleted, setHasCompleted] = useState(false);

    const [technicianHistory, setTechnicianHistory] = useState({});
    const [equipmentHistory, setEquipmentHistory] = useState({});

    const [recertifyPopup, setRecertifyPopup] = useState({});
    const [deleteEquipmentPopup, setDeleteEquipmentPopup] = useState({});

    const applyFilters = () => {
        let filteredTechCerts = false,
            filteredEquipCerts = false;

        if(activeStatus) {
            filteredTechCerts = initialTechCertifications.filter(c => {
                if(activeStatus === 'completed') {
                    return c.status === 'certified' || c.status === 'notcertified';
                }
                else if(activeStatus === 'pending') {
                    return c.status === 'pending' || c.status === 'incertification';
                }
                else if(activeStatus === 'notstarted') {
                    return c.status === 'notstarted';
                }
                else {
                    return c;
                }

                return false;
            });
            filteredEquipCerts = initialEquipCertifications.filter(c => {
                if(activeStatus === 'completed') {
                    return c.status === 'certified' || c.status === 'notcertified';
                }
                else if(activeStatus === 'pending') {
                    return c.status === 'pending' || c.status === 'incertification';
                }
                else if(activeStatus === 'notstarted') {
                    return c.status === 'notstarted';
                }
                else {
                    return c;
                }

                return false;
            });
        }

        if(activeCenter) {
            let srcTechCerts = filteredTechCerts || initialTechCertifications;
            filteredTechCerts = srcTechCerts.filter(c =>
                parseInt(c.technician.center.id, 10) === parseInt(activeCenter, 10)
            );

            let srcEquipCerts = filteredEquipCerts || initialEquipCertifications;
            filteredEquipCerts = srcEquipCerts.filter(c =>
                parseInt(c.technician.center.id, 10) === parseInt(activeCenter, 10)
            );
        }

        if(activeExam) {
            let srcTechCerts = filteredTechCerts || initialTechCertifications;
            filteredTechCerts = srcTechCerts.filter(c =>
                parseInt(c.study_exam.exam_type.id, 10) === parseInt(activeExam, 10)
            );

            let srcEquipCerts = filteredEquipCerts || initialEquipCertifications;
            filteredEquipCerts = srcEquipCerts.filter(c =>
                parseInt(c.study_exam.exam_type.id, 10) === parseInt(activeExam, 10)
            );
        }

        let editedStudy = JSON.parse(JSON.stringify(study));
        editedStudy.technician_certifications = filteredTechCerts || initialTechCertifications;
        editedStudy.equipment_certifications = filteredEquipCerts || initialEquipCertifications;

        setStudy(editedStudy);
    };

    const getStatusDisplay = status => {

        if (status === 'notstarted') {
            return 'Not Started';
        }
        else if (status === 'pending') {
            return 'Pending';
        }
        else if (status === 'notcertified') {
            return 'Not Certified'
        }
        else if (status === 'incertification') {
            return 'In Certification'
        }
        else if (status === 'certified') {
            return 'Certified'
        }

        return '';

    }

    const deleteEquipment = (id) => {

        const errMsg = "Sorry, something went wrong when deleting this equipment certification. Please contact your IT helpdesk.";
        let count = props.overlayVisible;
        props.setOverlayVisible( (count) => count + 1);

        let response = axios.delete(process.env.REACT_APP_API_ENDPOINT+'/certifications/equipment/'+id+'/')
            .then((response) => {
                props.setOverlayVisible( (count) => count - 1);

                let editedInitialEquipCertifications = JSON.parse(JSON.stringify(initialEquipCertifications));
                let editedEquipmentCertifications = JSON.parse(JSON.stringify(study.equipment_certifications));

                editedInitialEquipCertifications = editedInitialEquipCertifications.filter((c) => {
                    return parseInt(c.id, 10) !== parseInt(id, 10);
                });

                editedEquipmentCertifications = editedEquipmentCertifications.filter((c) => {
                    return parseInt(c.id, 10) !== parseInt(id, 10);
                });

                setStudy({...study, equipment_certifications: editedEquipmentCertifications});
                setInitialEquipCertifications(editedInitialEquipCertifications);

                setDeleteEquipmentPopup({});

        }, (error) => {
            props.setOverlayVisible( (count) => count - 1);
            setDeleteEquipmentPopup({});
            alert(errMsg);
        });


    };

    const setRecertifyLink = (type, id) => {
        setRecertifyPopup({type: type, link: '/studies/'+props.match.params.id+'/certifications/'+type+'/'+id+'/recertify'});
    };

    return (

        <React.Fragment>

        {Object.keys(deleteEquipmentPopup).length > 0 && (
        <React.Fragment>
        <div id="alertModal" className="modal hide fade in" tabIndex="-1" role="dialog" aria-labelledby="myModalLabel" aria-hidden="false" style={{display: "block"}}>
            <div className="modal-header">
                <button type="button" className="close" data-dismiss="modal" aria-hidden="true">×</button>
                <h3 id="alert-modal-title">Confirmation</h3>
            </div>
            <div className="modal-body"><br/>Do you really want delete this equipment??<br/></div>
            <div className="modal-footer">
                <button onClick={() => deleteEquipment(deleteEquipmentPopup.id)} id="alert-modal-ok" aria-hidden="true" className="btn small orange align-center right">Yes</button>
                <button onClick={() => setDeleteEquipmentPopup({})} data-dismiss="modal" id="alert-modal-cancel" aria-hidden="true" className="btn small grey align-center right">No</button>
            </div>
        </div>
        <div className="modal-backdrop fade in"></div>
        </React.Fragment>
        )}



        {Object.keys(recertifyPopup).length > 0 && (
        <React.Fragment>
            <div id="alertModal" className="modal hide fade in" tabIndex="-1" role="dialog" aria-labelledby="myModalLabel" aria-hidden="false" style={{display: "block"}}>
                <div className="modal-header">
                    <button type="button" className="close" data-dismiss="modal" aria-hidden="true">×</button>
                    <h3 id="alert-modal-title">Confirmation</h3>
                </div>
                <div className="modal-body"><br/>Do you really want to recertify this {recertifyPopup.type}?<br/></div>
                <div className="modal-footer">
                    <button onClick={() => props.history.push(recertifyPopup.link)} id="alert-modal-ok" aria-hidden="true" className="btn small orange align-center right">Yes</button>
                    <button onClick={() => setRecertifyPopup({})} data-dismiss="modal" id="alert-modal-cancel" aria-hidden="true" className="btn small grey align-center right">No</button>
                </div>
            </div>
            <div className="modal-backdrop fade in"></div>
        </React.Fragment>
        )}

        <div className="home_header_container_class">
            <div className="content">
                <div className="breadcrumbs">
                    <div className="path">
                        <ul>
                            <li><span className="orange text-shadow">{study.acronym ? study.acronym.toUpperCase() : study.acronym}</span></li>
                            <li className="separator text-shadow">&gt;</li>
                            <li>Certifications</li>
                        </ul>
                    </div>
                </div> {/* .breadcrumbs */}
                <div className="top-buttons">
                    <CertificationFilters
                        study={study}
                        userAuth={props.userAuth}
                        setStudy={setStudy}
                        activeExam={activeExam}
                        setActiveExam={setActiveExam}
                        activeStatus={activeStatus}
                        setActiveStatus={setActiveStatus}
                        activeCenter={activeCenter}
                        setActiveCenter={setActiveCenter}
                        applyFilters={applyFilters}
                        hasNotStarted={hasNotStarted}
                        setHasNotStarted={setHasNotStarted}
                        hasCompleted={hasCompleted}
                        setHasCompleted={setHasCompleted}
                        initialEquipCertifications={initialEquipCertifications}
                        setInitialEquipCertifications={setInitialEquipCertifications}
                        initialTechCertifications={initialTechCertifications}
                        setInitialTechCertifications={setInitialTechCertifications}
                        overlayVisible={props.overlayVisible}
                        setOverlayVisible={props.setOverlayVisible}
                    />
                </div>

                <div className="certifications">
                    <div className="overview">
                        <h2 className="text-shadow" style={{display: "inline-block"}}>Equipment</h2>
                        {activeCenter && study.status === "open" && (props.userAuth.user.role === 'admin' || props.userAuth.user.role === 'tech') && (
                        <Link
                            className="btn small align-center"
                            to={"/studies/" + study.id + "/certifications/equipment/new/"+activeCenter}
                            style={{color: "red", display: "inline-block"}}
                        >Admin Submit
                        </Link>
                        )}
                    </div>
                    <div className="list visits-list">
                        <EquipmentCertificationList
                            deleteEquipment={deleteEquipment}
                            setDeleteEquipmentPopup={setDeleteEquipmentPopup}
                            setRecertifyLink={setRecertifyLink}
                            userAuth={props.userAuth}
                            getStatusDisplay={getStatusDisplay}
                            study={study}
                            equipmentHistory={equipmentHistory}
                            setEquipmentHistory={setEquipmentHistory}
                            overlayVisible={props.overlayVisible}
                            setOverlayVisible={props.setOverlayVisible}
                        />
                    </div>
                    <div className="overview">
                        <h2 className="text-shadow">Technician</h2>
                    </div>
                    <div className="list visits-list">
                        <TechnicianCertificationList
                            initialCenters= {initialCenters}
                            setRecertifyLink={setRecertifyLink}
                            userAuth={props.userAuth}
                            getStatusDisplay={getStatusDisplay}
                            study={study}
                            technicianHistory={technicianHistory}
                            setTechnicianHistory={setTechnicianHistory}
                            overlayVisible={props.overlayVisible}
                            setOverlayVisible={props.setOverlayVisible}
                        />
                    </div>
                </div>
            </div> {/* .content */}
        </div>
        </React.Fragment>
    );
}

export default Certifications;

function CertificationFilters(props) {
    return (
        <React.Fragment>

        <ExamFilters
            study={props.study}
            activeExam={props.activeExam}
            setActiveExam={props.setActiveExam}
            applyFilters={props.applyFilters}
        />

        <StatusFilters
            userAuth={props.userAuth}
            study={props.study}
            setStudy={props.setStudy}
            activeStatus={props.activeStatus}
            setActiveStatus={props.setActiveStatus}
            applyFilters={props.applyFilters}
            hasNotStarted={props.hasNotStarted}
            setHasNotStarted={props.setHasNotStarted}
            hasCompleted={props.hasCompleted}
            setHasCompleted={props.setHasCompleted}
            initialEquipCertifications={props.initialEquipCertifications}
            setInitialEquipCertifications={props.setInitialEquipCertifications}
            initialTechCertifications={props.initialTechCertifications}
            setInitialTechCertifications={props.setInitialTechCertifications}
            overlayVisible={props.overlayVisible}
            setOverlayVisible={props.setOverlayVisible}
        />

        <CenterFilter
            study={props.study}
            activeCenter={props.activeCenter}
            setActiveCenter={props.setActiveCenter}
            applyFilters={props.applyFilters}
        />
        </React.Fragment>
    );
}

function StatusFilters(props) {
    useEffect(() => {
        props.applyFilters()
    }, [props.activeStatus]);

    const toggleNotStartedFilter = async () => {
        if(props.activeStatus !== 'notstarted') {

            if(!props.hasNotStarted) {
                let count = props.overlayVisible;
                props.setOverlayVisible( (count) => count + 1);

                const items = await axios.get(process.env.REACT_APP_API_ENDPOINT+'/studies/'+props.study.id+'/certifications/notstarted/',
                    { withCredentials: true, headers: {'Content-Type': 'application/json'}});

                let editedEquipCert = JSON.parse(JSON.stringify(props.initialEquipCertifications));
                editedEquipCert = editedEquipCert.concat(JSON.parse(JSON.stringify(items.data.equipment_certifications)));
                props.setInitialEquipCertifications(editedEquipCert);
                let editedTechCert = JSON.parse(JSON.stringify(props.initialTechCertifications));
                editedTechCert = editedTechCert.concat(JSON.parse(JSON.stringify(items.data.technician_certifications)));
                props.setInitialTechCertifications(editedTechCert);

                props.setHasNotStarted(true);

                props.setOverlayVisible((count) => count - 1);
            }

            props.setActiveStatus("notstarted");
        }
        else {
            if(!props.hasCompleted) {
                let count = props.overlayVisible;
                props.setOverlayVisible( (count) => count + 1);

                const items = await axios.get(process.env.REACT_APP_API_ENDPOINT+'/studies/'+props.study.id+'/certifications/completed/',
                    { withCredentials: true, headers: {'Content-Type': 'application/json'}});

                let editedEquipCert = JSON.parse(JSON.stringify(props.initialEquipCertifications));
                editedEquipCert = editedEquipCert.concat(JSON.parse(JSON.stringify(items.data.equipment_certifications)));
                props.setInitialEquipCertifications(editedEquipCert);

                let editedTechCert = JSON.parse(JSON.stringify(props.initialTechCertifications));
                editedTechCert = editedTechCert.concat(JSON.parse(JSON.stringify(items.data.technician_certifications)));
                props.setInitialTechCertifications(editedTechCert);

                props.setHasCompleted(true);

                props.setOverlayVisible( (count) => count - 1);
            }

            props.setActiveStatus(-1);
        }
    };

    const togglePendingFilter = async () => {
        if(props.activeStatus !== 'pending') {
            props.setActiveStatus("pending");
        }
        else {
            if(!props.hasCompleted || !props.hasNotStarted) {
                let count = props.overlayVisible;
                props.setOverlayVisible( (count) => count + 1);

                let editedEquipCert = JSON.parse(JSON.stringify(props.initialEquipCertifications));
                let editedTechCert = JSON.parse(JSON.stringify(props.initialTechCertifications));

                if(!props.hasCompleted) {
                    const completed = await axios.get(process.env.REACT_APP_API_ENDPOINT+'/studies/'+props.study.id+'/certifications/completed/',
                        { withCredentials: true, headers: {'Content-Type': 'application/json'}});

                    editedEquipCert = editedEquipCert.concat(JSON.parse(JSON.stringify(completed.data.equipment_certifications)));

                    editedTechCert = editedTechCert.concat(JSON.parse(JSON.stringify(completed.data.technician_certifications)));
                    props.setHasCompleted(true);
                }

                if(!props.hasNotStarted) {
                    const notStarted = await axios.get(process.env.REACT_APP_API_ENDPOINT+'/studies/'+props.study.id+'/certifications/notstarted/',
                        { withCredentials: true, headers: {'Content-Type': 'application/json'}});

                    editedEquipCert = editedEquipCert.concat(JSON.parse(JSON.stringify(notStarted.data.equipment_certifications)));
                    editedTechCert = editedTechCert.concat(JSON.parse(JSON.stringify(notStarted.data.technician_certifications)));

                    props.setHasNotStarted(true);
                }

                props.setInitialEquipCertifications(editedEquipCert);
                props.setInitialTechCertifications(editedTechCert);
                props.setOverlayVisible( (count) => count - 1);
            }

            props.setActiveStatus(-1);
        }
    };

    const toggleCompletedFilter = async () => {
        if(props.activeStatus !== 'completed') {

            if(!props.hasCompleted) {
                let count = props.overlayVisible;
                props.setOverlayVisible( (count) => count + 1);

                const items = await axios.get(process.env.REACT_APP_API_ENDPOINT+'/studies/'+props.study.id+'/certifications/completed/',
                    { withCredentials: true, headers: {'Content-Type': 'application/json'}});

                let editedEquipCert = JSON.parse(JSON.stringify(props.initialEquipCertifications));
                editedEquipCert = editedEquipCert.concat(JSON.parse(JSON.stringify(items.data.equipment_certifications)));
                props.setInitialEquipCertifications(editedEquipCert);

                let editedTechCert = JSON.parse(JSON.stringify(props.initialTechCertifications));
                editedTechCert = editedTechCert.concat(JSON.parse(JSON.stringify(items.data.technician_certifications)));
                props.setInitialTechCertifications(editedTechCert);

                props.setHasCompleted(true);

                props.setOverlayVisible( (count) => count - 1);
            }

            props.setActiveStatus("completed");
        }
        else {
            if(!props.hasNotStarted) {
                let count = props.overlayVisible;
                props.setOverlayVisible( (count) => count + 1);

                const items = await axios.get(process.env.REACT_APP_API_ENDPOINT+'/studies/'+props.study.id+'/certifications/notstarted/',
                    { withCredentials: true, headers: {'Content-Type': 'application/json'}});

                let editedEquipCert = JSON.parse(JSON.stringify(props.initialEquipCertifications));
                editedEquipCert = editedEquipCert.concat(JSON.parse(JSON.stringify(items.data.equipment_certifications)));
                props.setInitialEquipCertifications(editedEquipCert);
                let editedTechCert = JSON.parse(JSON.stringify(props.initialTechCertifications));
                editedTechCert = editedTechCert.concat(JSON.parse(JSON.stringify(items.data.technician_certifications)));
                props.setInitialTechCertifications(editedTechCert);

                props.setHasNotStarted(true);

                props.setOverlayVisible((count) => count - 1);
            }

            props.setActiveStatus(-1);
        }
    };

    return (
        <ul className="button-group">
            {props.userAuth.user.role !== 'grader' && (
            <li>
                <button
                    className={"btn normal grey align-center " + (props.activeStatus === 'notstarted' ? "select" : "")}
                    onClick={toggleNotStartedFilter}
                >
                    <span className="icon "></span>Not Started
                </button>
            </li>
            )}
            <li>
                <button
                    className={"btn normal grey align-center " + (props.activeStatus === 'pending' ? "select" : "")}
                    onClick={togglePendingFilter}
                >
                    <span className="icon "></span>Pending Analysis
                </button>
            </li>
            <li>
                <button
                    className={"btn normal grey align-center " + (props.activeStatus === 'completed' ? "select" : "")}
                    onClick={toggleCompletedFilter}
                >
                    <span className="icon "></span>Completed Analysis
                    </button>
            </li>
        </ul>
    );
}

function CenterFilter(props) {
    useEffect(() => {
        props.applyFilters();
    }, [props.activeCenter]);

    const changeCenter = val => {
        val = val === -1 ? false : val;

        props.setActiveCenter(val);
    }

    const centerOptions = [];
    props.study.centers.map( center => {

        if (center.is_active) {
            centerOptions.push({label: center.study_site_num, value: center.center.id})
        }

        return false;
    });

    // sort by label
    centerOptions.sort(function(a,b) {
        if (a.label.toLowerCase() > b.label.toLowerCase()) {
            return 1;
        }
        if (a.label.toLowerCase() < b.label.toLowerCase()) {
            return -1;
        }
        return 0;
    });

    centerOptions.unshift({label: 'All Centers', value: -1});


    return (
        <ul className="button-group">
            <li>
                <CorcSelect
                    //containerStyles={{width: "165px"}}
                    id="center-select"
                    name="center-select"
                    value={centerOptions.filter(c => !props.activeCenter ? c.value === -1 : c.value === props.activeCenter)}
                    onChange={selectedOption => changeCenter(selectedOption.value)}
                    options={centerOptions}
                    isSearchable={false}
                    containerStyles={{width: "155px", marginTop: "1px"}}
                />
            </li>
        </ul>
    );
}

function ExamFilters(props) {
    useEffect(() => {
        props.applyFilters();
    }, [props.activeExam]);

    const toggleExamFilter = (examID) => {
        if(props.activeExam === examID) {
            props.setActiveExam(false);
        }
        else {
            props.setActiveExam(examID);
        }
    };

    return (
        <ul className="button-group exams-certifications">
            {props.study.exams.map((exam, i) => (
            <li key={i}>
                <button
                    id={exam.exam_type.uid}
                    className={"btn normal grey align-center " + (props.activeExam === exam.exam_type.id ? 'select' : '')}
                    onClick={() => toggleExamFilter(exam.exam_type.id)}
                >
                    <span className="icon "></span>{exam.exam_type.name}
                </button>
            </li>
            ))}
        </ul>
    );
}

function EquipmentCertificationList(props) {
    return (
        <React.Fragment>
        <div className="list-head">
            <table>
                <tbody>
                    <tr>
                        <th width="8%">Equipment for</th>
                        <th width="8%">Center</th>
                        <th width="10%">Maker and Model</th>
                        <th width="10%">Serial Number</th>
                        <th width="10%">Software Version</th>
                        <th width="10%">Status</th>
                        <th width="10%">Date Submitted</th>
                        <th>Date Certified</th>
                    </tr>
                </tbody>
            </table>
        </div>

        <div className="list-body">
            <table id="list-table">
                <tbody>
                    {props.study.equipment_certifications.map((cert, i) => (
                    <EquipmentCertificationListItem
                        setRecertifyLink={props.setRecertifyLink}
                        deleteEquipment={props.deleteEquipment}
                        setDeleteEquipmentPopup={props.setDeleteEquipmentPopup}
                        key={i}
                        certification={cert}
                        userAuth={props.userAuth}
                        study={props.study}
                        equipmentHistory={props.equipmentHistory}
                        setEquipmentHistory={props.setEquipmentHistory}
                        getStatusDisplay={props.getStatusDisplay}
                        overlayVisible={props.overlayVisible}
                        setOverlayVisible={props.setOverlayVisible}
                    />
                    ))}
                </tbody>
            </table>
        </div>
        </React.Fragment>
    );
}

function EquipmentCertificationListItem(props) {

    const deleteEquipmentCertification = (id) => {

        props.setDeleteEquipmentPopup({id: id});
    };

    const expandHandler = (ev, cert) => {

        let editedHistory = JSON.parse(JSON.stringify(props.equipmentHistory)), response, error;
        const errMsg = "Sorry, something went wrong when retrieving the history of this certification. Please contact your IT helpdesk.";

        if(ev.target.classList.contains("open")) {

            if (editedHistory.hasOwnProperty(cert.id)) {
                editedHistory[cert.id].expanded = false;
                props.setEquipmentHistory(editedHistory);
            }

            ev.target.classList.remove("open");
        }
        else {
            ev.target.classList.add("open");

            let count = props.overlayVisible;
            props.setOverlayVisible( (count) => count + 1);

            response = axios.get(process.env.REACT_APP_API_ENDPOINT+'/certifications/equipment/'+props.certification.id+'/history/')
                .then((response) => {

                props.setOverlayVisible( (count) => count - 1);
                if (response.status === 200) {

                    if (!editedHistory.hasOwnProperty(cert.id)) {
                        editedHistory[cert.id] = {'expanded': true, history: response.data};
                    }
                    else {
                        editedHistory[cert.id].expanded = true;
                    }

                    props.setEquipmentHistory(editedHistory);
                }
                else {
                    alert(errMsg);
                }

            }, (error) => {
                props.setOverlayVisible( (count) => count - 1);
                alert(errMsg);
            });

        }
    };


    return (
        <React.Fragment>
        <tr className="visit">
            <td width="8%">{props.certification.study_exam.exam_type.name}</td>
            <td width="8%">{props.certification.study_center.study_site_num}</td>
            <td width="10%">{props.certification.equipment_make_model}</td>
            <td width="10%">{props.certification.serial_num}</td>
            <td width="10%">{props.certification.software_version}</td>
            <td width="10%">{props.getStatusDisplay(props.certification.status)}</td>
            <td width="10%">{props.certification.submission_date || '--/--/--'}</td>
            <td width="10%">{props.certification.grading_date || '--/--/--'}

                {props.certification.number_history_certifications > 0 && (
                <button
                    className="expand"
                    onClick={(ev) => expandHandler(ev, props.certification)}
                    >&nbsp;
                </button>
                )}
            </td>


            <td className="btn-list" width="30%">
                <ul style={{float: "right"}}>

                    {props.study.status === "open" && (props.certification.status === 'incertification' || props.certification.status === 'pending') && props.userAuth.user.role === 'grader' && (
                    <li>
                        <Link
                            to={"/studies/" + props.study.id + "/certifications/equipment/" + props.certification.id+"/grade"}
                            className="btn small grey align-center"
                        >Grade
                        </Link>
                    </li>
                    )}

                    {props.study.status === "open" && props.certification.status === 'certified' && (props.userAuth.user.role === 'grader') && (
                    <li>
                        <button
                            className="btn small grey align-center left"
                            onClick={() => props.setRecertifyLink('equipment', props.certification.id)}
                        >Recertify
                        </button>
                    </li>
                    )}

                    {props.userAuth.user.role === 'grader' && props.certification.status !== 'incertification' && (
                    <li>
                        <Link
                            to={"/studies/" + props.study.id + "/certifications/equipment/" + props.certification.id}
                            className="btn small grey align-center left"
                        >View Report
                        </Link>
                    </li>
                    )}

                    {props.userAuth.user.role !== 'grader' && (
                    <li>
                        <Link
                            to={"/studies/" + props.study.id + "/certifications/equipment/" + props.certification.id}
                            className="btn small grey align-center left"
                        >View Report
                        </Link>
                    </li>
                    )}

                    {props.userAuth.user.role === 'grader' && (props.certification.status === 'incertification' || props.certification.status === 'pending') && props.certification.number_history_certifications === 0 && (
                    <li>
                        <button
                            onClick={() => deleteEquipmentCertification(props.certification.id)}
                            className="btn xsmall grey align-center left">
                            <span className="icon-list remove-small">&nbsp;</span>
                        </button>
                    </li>
                    )}

                </ul>
            </td>
        </tr>

        <tr className="expand" style={props.equipmentHistory.hasOwnProperty(props.certification.id) && props.equipmentHistory[props.certification.id].expanded ? {display: 'table-row'} : {}}>
            <td colSpan="8">
                <table>
                    <thead>
                        <tr className="head">
                            <th width="20%">Username</th>
                            <th width="20%">Status</th>
                            <th width="20%">Date Submitted</th>
                            <th width="20%">Date Certified</th>
                            <th></th>
                        </tr>
                    </thead>
                    <tbody>
                        {props.equipmentHistory.hasOwnProperty(props.certification.id) && props.equipmentHistory[props.certification.id].history.map((item, i) => (
                            <EquipmentHistoryItem
                                getStatusDisplay={props.getStatusDisplay}
                                item={item}
                                key={i}
                                study={props.study}
                            />
                        ))}

                    </tbody>
                </table>
            </td>
        </tr>
        </React.Fragment>

    );
}

function TechnicianCertificationList(props) {

    return (
        <React.Fragment>
        <div className="list-head">
            <table>
                <tbody>
                    <tr>
                        <th width="14.5%">Center</th>
                        <th width="14.5%">Username</th>
                        <th width="13.5%">Exam Type</th>
                        <th width="13.5%">Status</th>
                        <th width="12.5%">Date Submitted</th>
                        <th width="12.5%">Date Certified</th>
                        <th width="19%"></th>
                    </tr>
                </tbody>
            </table>
        </div>

        <div className="list-body">
            <table id="list-table">
                <tbody>
                    {props.study.technician_certifications.map((cert, i) => (
                    <TechnicianCertificationListItem
                        userAuth={props.userAuth}
                        initialCenters={props.initialCenters}
                        setRecertifyLink={props.setRecertifyLink}
                        key={i}
                        getStatusDisplay={props.getStatusDisplay}
                        certification={cert}
                        study={props.study}
                        technicianHistory={props.technicianHistory}
                        setTechnicianHistory={props.setTechnicianHistory}
                        overlayVisible={props.overlayVisible}
                        setOverlayVisible={props.setOverlayVisible}
                    />
                    ))}
                </tbody>
            </table>
        </div>
        </React.Fragment>
    );
}

function EquipmentHistoryItem(props) {

    return (
        <tr>
            <td>{props.item.technician && props.item.technician.username}</td>
            <td>{props.getStatusDisplay(props.item.status)}</td>
            <td>{props.item.submission_date || '--/--/--'}</td>
            <td>{props.item.grading_date || '--/--/--'}</td>
            <td className="btn-list" width="12%">
                <ul style={{float: "right"}}>
                    <li>
                        <Link
                            to={"/studies/" + props.study.id + "/certifications/equipment/" + props.item.id}
                            className="btn small grey align-center"
                        >View Report
                        </Link>
                    </li>
                </ul>
            </td>

        </tr>
    );
}

function TechnicianHistoryItem(props) {

    return (
        <tr>
            <td>{props.item.technician && props.item.technician.username}</td>
            <td>{props.getStatusDisplay(props.item.status)}</td>
            <td>{props.item.submission_date || '--/--/--'}</td>
            <td>{props.item.grading_date || '--/--/--'}</td>

            <td className="btn-list" width="14%">
                <ul style={{float: "right"}}>
                    {props.item.status !== 'notstarted' && (
                    <li>
                        <Link
                            to={"/studies/" + props.study.id + "/certifications/technician/" + props.item.id}
                            className="btn small grey align-center"
                        >View Report
                        </Link>
                    </li>
                    )}
                </ul>
            </td>

        </tr>
    );
}
function TechnicianCertificationListItem(props) {

    const expandHandler = (ev, cert) => {

        let editedHistory = JSON.parse(JSON.stringify(props.technicianHistory)), response, error;
        const errMsg = "Sorry, something went wrong when retrieving the history of this certification.. Please contact your IT helpdesk.";

        if(ev.target.classList.contains("open")) {

            if (editedHistory.hasOwnProperty(cert.id)) {
                editedHistory[cert.id].expanded = false;
                props.setTechnicianHistory(editedHistory);
            }

            ev.target.classList.remove("open");
        }
        else {
            ev.target.classList.add("open");

            let count = props.overlayVisible;
            props.setOverlayVisible( (count) => count + 1);

            response = axios.get(process.env.REACT_APP_API_ENDPOINT+'/certifications/technician/'+props.certification.id+'/history/')
                .then((response) => {

                if (response.status === 200) {

                    props.setOverlayVisible( (count) => count - 1);
                    if (!editedHistory.hasOwnProperty(cert.id)) {
                        editedHistory[cert.id] = {'expanded': true, history: response.data};
                    }
                    else {
                        editedHistory[cert.id].expanded = true;
                    }

                    props.setTechnicianHistory(editedHistory);
                }
                else {
                    alert(errMsg);
                }

            }, (error) => {
                props.setOverlayVisible( (count) => count - 1);
                alert(errMsg);
            });

        }

    };

    // if we are a grader and the certification is in the state "notstarted", return nothing
    if (props.certification.status === 'notstarted' && props.userAuth.user.role === 'grader') {
        return false;
    }

    return (
        <React.Fragment>
        <tr className="visit">
            <td width="14.5%">{props.certification.technician && props.initialCenters[props.certification.technician.center.id]}</td>
            <td width="14.5%">{props.certification.technician && props.certification.technician.username}</td>
            <td width="13.5%">{props.certification.study_exam.exam_type.name}</td>
            <td width="13.5%">{props.getStatusDisplay(props.certification.status)}</td>
            <td width="12.5%">{props.certification.submission_date || '--/--/--' }</td>
            <td width="12.5%">{props.certification.grading_date || '--/--/--' }

                {props.certification.number_history_certifications > 0 && (
                <button
                    className="expand"
                    onClick={(ev) => expandHandler(ev, props.certification)}
                    >&nbsp;
                </button>
                )}

            </td>
            <td className="btn-list" width="19%">
                <ul style={{float: "right"}}>
                    <React.Fragment>
                    {props.study.status === "open" && props.certification.status !== 'certified' && props.userAuth.user.role === 'admin' && (
                    <li>
                        <Link
                            to={"/studies/" + props.study.id + "/certifications/technician/" + props.certification.id+"/submit"}
                            className="btn small align-center"
                            style={{color:"red", display: "inline-block"}}
                        >Admin Submit
                        </Link>
                    </li>
                    )}

                    {props.study.status === "open" && (props.certification.status === 'incertification' || props.certification.status === 'pending') && props.userAuth.user.role === 'grader' && (
                    <li>
                        <Link
                            to={"/studies/" + props.study.id + "/certifications/technician/" + props.certification.id+"/grade"}
                            className="btn small grey align-center"
                        >Grade
                        </Link>
                    </li>
                    )}

                    {props.study.status === "open" && props.certification.status === 'certified' && (props.userAuth.user.role === 'admin' || props.userAuth.user.role === 'grader') && (
                    <li>
                        <button
                            className="btn small grey align-center"
                            onClick={() => props.setRecertifyLink('technician', props.certification.id)}
                        >Recertify
                        </button>
                    </li>
                    )}

                    {props.userAuth.user.role === 'grader' && props.certification.status !== 'incertification' && props.certification.status !== 'notstarted' && (
                    <li>
                        <Link
                            to={"/studies/" + props.study.id + "/certifications/technician/" + props.certification.id}
                            className="btn small grey align-center"
                        >View Report
                        </Link>
                    </li>
                    )}

                    {props.userAuth.user.role !== 'grader' && props.certification.status !== 'notstarted' && (
                    <li>
                        <Link
                            to={"/studies/" + props.study.id + "/certifications/technician/" + props.certification.id}
                            className="btn small grey align-center"
                        >View Report
                        </Link>
                    </li>
                    )}

                    </React.Fragment>
                </ul>
            </td>
        </tr>
        <tr className="expand" style={props.technicianHistory.hasOwnProperty(props.certification.id) && props.technicianHistory[props.certification.id].expanded ? {display: 'table-row'} : {}}>
            <td colSpan="6">
                <table>
                    <thead>
                        <tr className="head">
                            <th width="20%">Username</th>
                            <th width="20%">Status</th>
                            <th width="20%">Date Submitted</th>
                            <th width="20%">Date Certified</th>
                            <th></th>
                        </tr>
                    </thead>
                    <tbody>
                        {props.technicianHistory.hasOwnProperty(props.certification.id) && props.technicianHistory[props.certification.id].history.map((item, i) => (
                            <TechnicianHistoryItem
                                getStatusDisplay={props.getStatusDisplay}
                                item={item}
                                key={i}
                                study={props.study}
                            />
                        ))}

                    </tbody>
                </table>
            </td>
        </tr>
        </React.Fragment>
    );
}

