import React, {useEffect, useState} from 'react';
//import {Link} from 'react-router-dom';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import axios from 'axios';
import {initDate} from 'components/utils/Helpers.js';

function EditHomepage (props) {

    useEffect(function() {

        const getHomepage = async () => {

            let count = props.overlayVisible;
            props.setOverlayVisible( (count) => count + 1);

            await axios.get(process.env.REACT_APP_API_ENDPOINT+'/settings/homepage/', { withCredentials: true}).then(response => {

                props.setOverlayVisible( (count) => count - 1);
                if (response.status === 200) {
                    setInfo(response.data);
                }

            }, (error) => {
                props.setOverlayVisible( (count) => count - 1);
                if (error.response.status === 401) {
                    props.resetAuth();
                    props.history.push('/login');
                }
            });
        };

        // do we have permissions?
        if (props.userAuth.user.role === 'monitor') {
            props.history.push('/studies');
            return;
        }

        props.setNavLinks([{title: 'Edit homepage', url: '/settings/homepage/', icon: 'study-details'}]);
        getHomepage();
    }, []);

    const [info, setInfo] = useState({
        id: 0,
        name: '',
        address: '',
        postcode: '',
        phone: '',
        fax: '',
        email: '',
        description: '',
        informative_panel: '',
        picture: {'name' : '', 'url': ''},
        studies: [],
        maintenance_mode: false,
        maintenance_message: '',
    });
    const [errorPopupMessages, setErrorPopupMessages] = useState([]);
    const [showSavePopup, setShowSavePopup] = useState(false);
    const [uploadedPicture, hasUploadedPicture] = useState(false);

    const submitHandler = e => {
        e.preventDefault();
    };

    const submitHomepage = async () => {
        let editedInfo = JSON.parse(JSON.stringify(info));
        const errMsg = "Sorry, something went wrong when saving the homepage. Please contact your IT helpdesk.";
        const formData = new FormData();
        const config = {
            headers: {
                'content-type': 'multipart/form-data'
            }
        };

        setShowSavePopup(false);

        // did we upload a new picture?
        if (!uploadedPicture) {
            delete editedInfo.picture;
        }
        else {
            editedInfo.picture = info.picture;
        }

        for(let k in editedInfo) {
            formData.append(k, (k === 'studies' ? JSON.stringify(editedInfo[k]) : editedInfo[k]));
        }

        let count = props.overlayVisible;
        props.setOverlayVisible( (count) => count + 1);

        await axios.patch(process.env.REACT_APP_API_ENDPOINT+'/settings/homepage/'+editedInfo.id+'/', formData, config)
            .then((response) => {
            props.setOverlayVisible( (count) => count - 1);
            if (response.status === 200) {
                window.location.reload();
            }
            else {
                alert(errMsg);
            }

        }, (error) => {
            props.setOverlayVisible( (count) => count - 1);
            alert(errMsg);
        });
    };

    const validateHomepage = () => {
        let errors = [];
        let editedInfo = JSON.parse(JSON.stringify(info));

        editedInfo.name = editedInfo.name.trim();
        editedInfo.address = editedInfo.address.trim();
        editedInfo.postcode = editedInfo.postcode.trim();
        editedInfo.phone = editedInfo.phone.trim();
        editedInfo.fax = editedInfo.fax.trim();
        editedInfo.email = editedInfo.email.trim();
        editedInfo.maintenance_message = editedInfo.maintenance_message.trim();

        // do we have requiredfields?
        if (!editedInfo.name.trim()) {
            errors.push('Name cannot be blank.');
        }

        if (!editedInfo.address.trim()) {
            errors.push('Address cannot be blank.');
        }

        if (!editedInfo.postcode.trim()) {
            errors.push('Postal code cannot be blank.');
        }

        if (!editedInfo.phone.trim()) {
            errors.push('Phone cannot be blank.');
        }

        if (!editedInfo.fax.trim()) {
            errors.push('Fax cannot be blank.');
        }

        if (!editedInfo.email.trim()) {
            errors.push('E-mail cannot be blank.');
        }

        if (editedInfo.maintenance_mode && !editedInfo.maintenance_message) {
            errors.push('Maintenance message cannot be blank.');
        }

        for(let i=editedInfo.studies.length-1; i>=0; --i) {
            let s = editedInfo.studies[i];

            if(!s.name.trim() || !s.date.trim() || !s.link.trim()) {
                errors.push('On-going study information must be complete.');
                break;
            }
        }

        editedInfo.picture = info.picture;

        setInfo(editedInfo);

        // if validation fails, show error popup
        if (errors.length) {
            setErrorPopupMessages(errors);
        }
        else {
            // otherwise confirm with save popup
            setShowSavePopup(true);
        }
    }

    return (
        <React.Fragment>
        <div className="home_header_container_class">
            <div className="content">
                <div className="breadcrumbs">
                    <div className="path">
                        <ul>
                            <li>Edit homepage</li>
                        </ul>
                    </div>
                </div> {/* .breadcrumbs */}
                <div className="main-form edit_homepage">
                    <form onSubmit={submitHandler} method="post" encType="multipart/form-data">
                        <HomepagePicture
                            info={info}
                            setInfo={setInfo}
                            hasUploadedPicture={hasUploadedPicture}
                        />
                        <HomepageMainInfo
                            info={info}
                            setInfo={setInfo}
                        />
                        <HomepageMaintenance
                            info={info}
                            setInfo={setInfo}
                        />
                        <HomepageInformativePanel
                            info={info}
                            setInfo={setInfo}
                        />
                        <br />
                        <HomepageOngoingStudies
                            info={info}
                            setInfo={setInfo}
                        />
                        <div className="form-actions right">
                            <input
                                type="submit"
                                className="btn small orange align-center left"
                                value="Save"
                                onClick={() => validateHomepage()}
                            />
                        </div>
                    </form>
                </div>
            </div> {/* .content */}
        </div>
        {showSavePopup && (
            <HomepageSavePopup setShowSavePopup={setShowSavePopup} submitHomepage={submitHomepage}/>
        )}

        {errorPopupMessages.length > 0 && (
            <HomepageErrorPopup errorPopupMessages={errorPopupMessages} setErrorPopupMessages={setErrorPopupMessages}/>
        )}
        </React.Fragment>
    );
}

export default EditHomepage;

function HomepagePicture(props) {

    const showFileUpload = ev => {
        ev.target.parentNode.querySelector('input[type="file"]').click();
    };

    const fileSelect = ev => {
        props.setInfo({...props.info, picture: ev.target.files[0]});
        props.hasUploadedPicture(true);

    };

    return (
        <div className="form-group">
            <div className="span5">
                <div className="row" style={{paddingTop: "4px"}}>
                    <div className="label">
                        <label htmlFor="picture">Home picture</label>
                    </div>
                    <div className="input">
                        <input
                            disabled="disabled"
                            name="picture_path"
                            id="picture_path"
                            type="text"
                            defaultValue={props.info.picture ? props.info.picture.name : ''}
                        />
                    </div>
                </div>
            </div> {/* .span5 */}
            <div className="span5">
                <div className="row" style={{paddingTop: "4px"}}>
                    <label style={{width: "73%"}}>Upload images with 470 x 451 pixels in PNG, JPEG or GIF</label>
                    <button
                        className="btn small grey align-center left change-file"
                        onClick={(ev) => showFileUpload(ev)}
                    >Upload image</button>
                    <input
                        style={{display: "none"}}
                        name="picture"
                        id="picture"
                        type="file"
                        onChange={(ev) => fileSelect(ev)}
                        accept=".jpg, .JPG, .gif, .GIF, .JPEG, .jpeg, .png, .PNG"
                    />
                </div>
            </div>
        </div>
    );
}

function HomepageMainInfo(props) {
    const onChangeHandler = (ev) => {
        let name = ev.target.name,
            val = ev.target.value;

        props.setInfo({...props.info, [name]: val});
    };

    return (
        <div className="form-group">
            <div className="span5">
                <div className="row" style={{paddingTop: "4px"}}>
                    <div className="label">
                        <label htmlFor="name" className="required">Name <span className="required">*</span></label>
                    </div>
                    <div className="input">
                        <input
                            name="name"
                            id="name"
                            type="text"
                            value={props.info.name}
                            onChange={(ev)=>onChangeHandler(ev)}
                        />
                    </div>
                </div>

                <div className="row" style={{paddingTop: "4px"}}>
                    <div className="label">
                        <label htmlFor="address" className="required">Address <span className="required">*</span></label>
                    </div>
                    <div className="input">
                        <input
                            name="address"
                            id="address"
                            type="text"
                            value={props.info.address}
                            onChange={(ev)=>onChangeHandler(ev)}
                        />
                    </div>
                </div>

                <div className="row" style={{paddingTop: "4px"}}>
                    <div className="label">
                        <label htmlFor="postcode" className="required">Postal Code <span className="required">*</span></label>
                    </div>
                    <div className="input">
                        <input
                            name="postcode"
                            id="postcode"
                            type="text"
                            value={props.info.postcode}
                            onChange={(ev)=>onChangeHandler(ev)}
                        />
                    </div>
                </div>

                <div className="row" style={{paddingTop: "4px"}}>
                    <div className="label">
                        <label htmlFor="phone" className="required">Phone <span className="required">*</span></label>
                    </div>
                    <div className="input">
                        <input
                            name="phone"
                            id="phone"
                            type="text"
                            value={props.info.phone}
                            onChange={(ev)=>onChangeHandler(ev)}
                        />
                    </div>
                </div>

                <div className="row" style={{paddingTop: "4px"}}>
                    <div className="label">
                        <label htmlFor="fax" className="required">Fax <span className="required">*</span></label>
                    </div>
                    <div className="input">
                        <input
                            name="fax"
                            id="fax"
                            type="text"
                            value={props.info.fax}
                            onChange={(ev)=>onChangeHandler(ev)}
                        />
                    </div>
                </div>

                <div className="row" style={{paddingTop: "4px"}}>
                    <div className="label">
                        <label htmlFor="email" className="required">Email <span className="required">*</span></label>
                    </div>
                    <div className="input">
                        <input
                            name="email"
                            id="email"
                            type="text"
                            value={props.info.email}
                            onChange={(ev)=>onChangeHandler(ev)}
                        />
                    </div>
                </div>
            </div>
            <div className="span5">
                <div className="row" style={{paddingTop: "4px"}}>
                    <textarea
                        className="submit-comment text_page"
                        name="description"
                        id="editHomepageForm_description"
                        value={props.info.description}
                        onChange={(ev) => onChangeHandler(ev)}
                    ></textarea>
                </div>
            </div>
        </div>
    );
}

function HomepageInformativePanel(props) {
    const onChangeHandler = (ev) => {
        props.setInfo({...props.info, informative_panel: ev.target.value});
    };

    return (
        <React.Fragment>
        <div className="overview">
            <h2 className="text-shadow">
                Informative Panel
            </h2>
        </div>
        <div className="form-group">
            <div className="span5" style={{width: "100%"}}>
                <div className="row" style={{paddingTop: "4px"}}>
                    <textarea
                        className="submit-comment"
                        name="informative_panel"
                        id="informative_panel"
                        value={props.info.informative_panel}
                        onChange={(ev) => onChangeHandler(ev)}
                    ></textarea>
                </div>
            </div>
        </div>
        </React.Fragment>
    );
}

function HomepageOngoingStudies(props) {
    const addStudy = () => {
        let editedInfo = JSON.parse(JSON.stringify(props.info));
        editedInfo.studies.push({id: Math.floor(Math.random(4) * -1000), name: '', date: '', link: '', description: '', is_visible: true});

        props.setInfo(editedInfo);
    }

    return (
        <React.Fragment>
        <div className="overview">
            <h2 className="text-shadow">Ongoing studies:</h2>
            <button
                type="button"
                className="btn normal grey right add-study-details"
                onClick={() => addStudy()}
            >
                <span className="icon icon-list report"></span>Add Ongoing Study
            </button>
        </div>
        <div className="homepage-studies">
            {props.info.studies && props.info.studies.map((study, i) => (
                <HomepageOngoingStudy
                    key={i}
                    study={study}
                    info={props.info}
                    setInfo={props.setInfo}
                />
            ))}
        </div>
        </React.Fragment>
    );
}

function HomepageOngoingStudy(props) {
    const deleteStudy = (study) => {
        let editedInfo = JSON.parse(JSON.stringify(props.info));

        let idx = editedInfo.studies.findIndex(el => el.id === study.id);
        if(idx > -1) {
            editedInfo.studies.splice(idx, 1);
        }

        props.setInfo(editedInfo);
    };

    const toggleVisibility = (study) => {
        let editedInfo = JSON.parse(JSON.stringify(props.info));
        let el = editedInfo.studies.find(el => el.id === study.id);
        el.is_visible = !study.is_visible;

        props.setInfo(editedInfo);
    };

    const onChangeStudyInfo = (ev, study) => {
        let editedInfo = JSON.parse(JSON.stringify(props.info)),
            el = editedInfo.studies.find(el => el.id === study.id),
            name = ev.target.name,
            val = ev.target.value;

        el[name] = val;
        props.setInfo(editedInfo);
    };

    const onChangeStudyDate = (date, study) => {
        let editedInfo = JSON.parse(JSON.stringify(props.info)),
            el = editedInfo.studies.find(el => el.id === study.id),
            txtDate = '';

        if (date) {
            txtDate = date.getFullYear() + '-' + (date.getMonth()+1) + '-' + date.getDate();
        }

        el.date = txtDate;
        props.setInfo(editedInfo);
    };

    return (
        <div className="form-group">
            <div className="span5">
                <div className="row" style={{paddingTop: "4px"}}>
                    <div className="label">
                        Study:
                    </div>
                    <div className="input">
                        <input
                            className="text-study-name"
                            type="text"
                            name="name"
                            value={props.study.name}
                            onChange={(ev) => onChangeStudyInfo(ev, props.study)}
                        />
                    </div>
                </div>

                <div className="row" style={{paddingTop: "4px"}}>
                    <div className="label">
                        Date:
                    </div>
                    <div className="input">
                        <DatePicker
                            dateFormat="dd/MM/yyyy"
                            name="date"
                            selected={props.study.date && initDate(props.study.date)}
                            onChange={(date) => onChangeStudyDate(date, props.study)}
                        />
                    </div>
                </div>

                <div className="row" style={{paddingTop: "4px"}}>
                    <div className="label">
                        Add link:
                    </div>
                    <div className="input">
                        <input
                            type="text"
                            name="link"
                            value={props.study.link}
                            onChange={(ev) => onChangeStudyInfo(ev, props.study)}
                        />
                    </div>
                </div>

                <div className="row" style={{paddingTop: "4px"}}>
                    <div className="label">
                        Status:
                    </div>
                    <div className="input" style={{paddingTop:"5px"}}>
                        <button
                            className={"btn normal orange align-center left visible-study" + (props.study.is_visible ? ' select' : '')}
                            onClick={() => toggleVisibility(props.study)}
                            type="button"
                        >Visible</button>
                        <button
                            className="btn small grey align-center left delete-study"
                            onClick={() => deleteStudy(props.study)}
                            type="button"
                        >Delete</button>
                    </div>
                </div>

            </div>

            <div className="span5">
                <div className="row" style={{paddingTop: "4px"}}>
                    <div className="label">
                        Description:
                    </div>
                    <div className="input">
                        <textarea
                            className="submit-comment info_study"
                            name="description"
                            value={props.study.description}
                            onChange={(ev) => onChangeStudyInfo(ev, props.study)}
                        ></textarea>
                    </div>
                </div>
            </div>
        </div>
    );
}

function HomepageMaintenance(props) {
    const toggleMaintenance = state => {
        props.setInfo({...props.info, maintenance_mode: state });
    };

    const onChangeHandler = ev => {
        props.setInfo({...props.info, maintenance_message: ev.target.value});
    }

    return (
        <div className="form-group">
            <div className="span5">
                <div className="row" style={{padding: "4px 0 10px"}}>
                    <input
                        type="checkbox"
                        id="checkbox-maintenance"
                        name="maintenance-mode"
                        checked={props.info.maintenance_mode}
                        onChange={(ev) => toggleMaintenance(ev.target.checked)}
                    />
                    <label style={{fontSize: "14px"}} htmlFor="checkbox-maintenance">Enter maintenance mode</label>
                </div>
            </div>
            {props.info.maintenance_mode && (
                <div className="span5">
                    <div className="row" style={{paddingTop: "4px"}}>
                        <textarea
                            className="submit-comment text_page"
                            name="maintenance_message"
                            onChange={(ev) => onChangeHandler(ev)}
                            placeholder="Type in the maintenance message..."
                            value={props.info.maintenance_message}
                        ></textarea>
                    </div>
                </div>
            )}
        </div>
    );
}


function HomepageSavePopup(props) {
    return (
        <React.Fragment>
            <div id="alertModal" className="modal hide fade in" style={{display: "block"}}>
                <div className="modal-header">
                    <h3 id="alert-modal-title">Confirmation!</h3>
                </div>
                <div className="modal-body"><br/>Do you really want to save the homepage?<br/><br/>
                </div>
                <div className="modal-footer">
                    <button onClick={props.submitHomepage} id="alert-modal-ok" aria-hidden="true" className="btn small orange align-center right">Yes</button>
                    <button onClick={() => props.setShowSavePopup(false)} data-dismiss="modal" id="alert-modal-cancel" aria-hidden="true" className="btn small grey align-center right">No</button>
                </div>
            </div>
            <div className="modal-backdrop fade in"></div>
        </React.Fragment>
    );
}


function HomepageErrorPopup(props) {

    return (
        <React.Fragment>
            <div id="alertModal" className="modal hide fade in" tabIndex="-1" role="dialog" aria-labelledby="myModalLabel" aria-hidden="false" style={{display: "block"}}>
                <div className="modal-header">
                    <h3 id="alert-modal-title">Errors!</h3>
                </div>
                <div className="modal-body">
                    <div className="errorSummary"><p>Please fix the following input errors:</p>
                        <ul>
                            {props.errorPopupMessages.map((message) => (
                            <li key={message}>{message}</li>
                            ))}
                        </ul>
                    </div>
                </div>
                <div className="modal-footer">
                    <button onClick={() => props.setErrorPopupMessages(false)} data-dismiss="modal" id="alert-modal-ok" aria-hidden="true" className="btn small orange align-center right">OK</button>
                </div>
            </div>
            <div className="modal-backdrop fade in"></div>
        </React.Fragment>
    );
}
