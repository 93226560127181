import React, {useEffect, useState}  from 'react';
import axios from 'axios';
import {Link} from 'react-router-dom';
import ReactHtmlParser from 'react-html-parser';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import {initDateTime, formatDate} from 'components/utils/Helpers.js';
import CorcSelect from 'components/utils/CorcSelect';
const prettyBytes = require('pretty-bytes');

function TechnicianCertificationRecertify(props) {
    useEffect(function() {

        const getCertification = async () => {
            let count = props.overlayVisible;
            props.setOverlayVisible( (count) => count + 1);

            axios.get(process.env.REACT_APP_API_ENDPOINT+'/certifications/technician/'+props.match.params.certification+'/',
                { withCredentials: true, headers: {'Content-Type': 'application/json'}})
            .then((response) => {

                setCertification(response.data);
                setAcceptTerms(true);

                props.setOverlayVisible( (count) => count - 1);

                // is the certification in the right state?
                if (response.data.status !== 'certified') {
                    props.history.push("/studies/"+props.match.params.id+"/certifications");
                    return;
                }

                // we can't recertify a certification that is archived or from a study that is not opened
                if (response.data.archived || response.data.study.status !== 'open') {
                    props.history.push("/studies/"+props.match.params.id+"/certifications");
                    return;
                }

                props.setOverlayVisible( (count) => count - 1);

            }, (error) => {
                props.setOverlayVisible( (count) => count - 1);
            });

        };

        props.setNavLinks([{title: 'Studies', url: '/studies/', icon: 'studies'},{title: 'Certification', url: '/studies/'+props.match.params.id+'/certifications/', icon: 'certification'},{title: 'Technician', url: props.location.pathname, icon: 'submit'}]);

        // only the grader can access this view
        if (props.userAuth.user.role !== 'grader') {
            props.setOverlayVisible( (count) => count - 1);
            props.history.push("/studies/"+props.match.params.id+"/certifications");
            return;
        }


        getCertification();
    }, []);

    const [certification, setCertification] = useState({
        "id": 0,
        "technician": null,
        "user_submitted": null,
        "admin": null,
        "study_exam": null,
        "status": "",
        "created_at": "",
        "updated_at": "",
        "deleted_at": null,
        "submission_date": "",
        "grading_date": "",
        "submitter_comment": "",
        "study": {},
        "technician_files": [],
        "technician_certification_grading": [],
    });

    const [acceptTerms, setAcceptTerms] = useState(false);
    const [certificationErrors, setCertificationErrors] = useState([]);
    const [recertifyComment, setRecertifyComment] = useState('');
    const [showConfirmSubmit, setShowConfirmSubmit] = useState(false);
    const [validateGradingFields, setValidateGradingFields] = useState([]);
    const [gradingStatus, setGradingStatus] = useState('');

    const closeErrorPopup = () => {
        setCertificationErrors([]);
    }

    const submitHandler = async (e) => {

        const errMsg = "Sorry, something went wrong when recertifying this technician. Please contact your IT helpdesk.";
        let changedCertification = {id: certification.id, grading: {}, comment: recertifyComment, status: gradingStatus}


        changedCertification.grading.file_labelling = certification.technician_certification_grading[0].file_labelling;
        changedCertification.grading.file_labelling_comments = certification.technician_certification_grading[0].file_labelling_comments;
        changedCertification.grading.focus = certification.technician_certification_grading[0].focus;
        changedCertification.grading.field_definition = certification.technician_certification_grading[0].field_definition;
        changedCertification.grading.stereopsis_definition = certification.technician_certification_grading[0].stereopsis_definition;
        changedCertification.grading.photo_quality_comments = certification.technician_certification_grading[0].photo_quality_comments;
        changedCertification.grading.quality = certification.technician_certification_grading[0].quality;
        changedCertification.grading.quality_comments = certification.technician_certification_grading[0].quality_comments;
        changedCertification.grading.protocol_procedures = certification.technician_certification_grading[0].protocol_procedures;
        changedCertification.grading.photo_fields = certification.technician_certification_grading[0].photo_fields;
        changedCertification.grading.comments = certification.technician_certification_grading[0].comments;
        changedCertification.grading.internal_comments = certification.technician_certification_grading[0].internal_comments;


        // delete fields that should not be sent to the server
        if (!validateGradingFields.includes('file_labelling')) {
            delete changedCertification.grading.file_labelling;
        }

        if (!validateGradingFields.includes('focus')) {
            delete changedCertification.grading.focus;
        }

        if (!validateGradingFields.includes('field_definition')) {
            delete changedCertification.grading.field_definition;
        }

        if (!validateGradingFields.includes('stereopsis_definition')) {
            delete changedCertification.grading.stereopsis_definition;
        }

        if (!validateGradingFields.includes('quality')) {
            delete changedCertification.grading.quality;
        }

        if (!validateGradingFields.includes('protocol_procedures')) {
            delete changedCertification.grading.protocol_procedures;
        }

        if (!validateGradingFields.includes('photo_fields')) {
            delete changedCertification.grading.photo_fields;
        }

        let count = props.overlayVisible;
        props.setOverlayVisible( (count) => count + 1);

        await axios.post(process.env.REACT_APP_API_ENDPOINT+'/certifications/technician/'+props.match.params.certification+'/recertify/', {...changedCertification}, { withCredentials: true})
            .then((response) => {

                props.setOverlayVisible( (count) => count - 1);
                if (response.status === 200) {
                    props.history.push("/studies/"+props.match.params.id+"/certifications");
                }
                else {
                    alert(errMsg);
                }

        }, (error) => {
            props.setOverlayVisible( (count) => count - 1);
            alert(errMsg);
        });

    }

    const validateSubmit = e => {

        const changedGrading = JSON.parse(JSON.stringify(certification.technician_certification_grading[0]));
        let errors = [];

        e.preventDefault();

        if (!gradingStatus) {
            errors.push("Certification status not set.");
        }

        // trim all comments
        changedGrading.file_labelling_comments = changedGrading.file_labelling_comments.trim();
        changedGrading.photo_quality_comments = changedGrading.photo_quality_comments.trim();
        changedGrading.quality_comments = changedGrading.quality_comments.trim();
        changedGrading.comments = changedGrading.comments.trim();
        changedGrading.internal_comments = changedGrading.internal_comments.trim();

        if (!changedGrading.field_definition && validateGradingFields.includes('field_definition')) {
            errors.push("Field definition not graded.");
        }

        if (!changedGrading.file_labelling && validateGradingFields.includes('file_labelling')) {
            errors.push("File labelling not graded.");
        }

        if (!changedGrading.focus && validateGradingFields.includes('focus')) {
            errors.push("Focus/Clarity not graded.");
        }

        if (!changedGrading.photo_fields && validateGradingFields.includes('photo_fields')) {
            errors.push("Photography fields not graded.");
        }

        if (!changedGrading.protocol_procedures && validateGradingFields.includes('protocol_procedures')) {
            errors.push("Protocol procedures not graded.");
        }

        if (!changedGrading.quality && validateGradingFields.includes('quality')) {
            errors.push("Quality not graded.");
        }

        if (!changedGrading.stereopsis_definition && validateGradingFields.includes('stereopsis_definition')) {
            errors.push("Stereopsis not graded.");
        }

        if (errors.length) {
            setCertificationErrors(errors);
            return;
        }

        setCertification({...certification, technician_certification_grading: [changedGrading]});
        setShowConfirmSubmit(true);


    };

    return (

        <React.Fragment>

        {showConfirmSubmit && (
            <React.Fragment>
                <div id="alertModal" className="modal hide fade in" tabIndex="-1" role="dialog" aria-labelledby="myModalLabel" aria-hidden="false" style={{display: "block"}}>
                    <div className="modal-header">
                        <button type="button" className="close" data-dismiss="modal" aria-hidden="true">×</button>
                        <h3 id="alert-modal-title">Do you really want to submit this Recertification?</h3>
                    </div>
                    <div className="modal-body">
                        <textarea
                            id="grader-notes"
                            placeholder="Comment"
                            className="form-comment"
                            name="grader-notes"
                            style={{width: "95% !important"}}
                            value={recertifyComment}
                            onChange={(ev) => {setRecertifyComment(ev.target.value)}}
                            >
                        </textarea>
                    </div>
                    <div className="modal-footer">
                        <button onClick={() => submitHandler()} id="alert-modal-ok" aria-hidden="true" className="btn small orange align-center right">Yes</button>
                        <button onClick={() => setShowConfirmSubmit(false)} data-dismiss="modal" id="alert-modal-cancel" aria-hidden="true" className="btn small grey align-center right">No</button>
                    </div>
                </div>
                <div className="modal-backdrop fade in"></div>
            </React.Fragment>
        )}


        {certificationErrors.length > 0 && (
            <React.Fragment>
            <div className="modal-backdrop fade in"></div>
            <div id="alertModal" className="modal hide fade in" tabIndex="-1" role="dialog" aria-labelledby="myModalLabel" aria-hidden="false" style={{display: "block"}}>
                <div className="modal-header">
                    <button type="button" className="close" data-dismiss="modal" aria-hidden="true">×</button>
                    <h3 id="alert-modal-title">Errors!</h3>
                </div>
                <div className="modal-body"><br/>
                    <div className="errorSummary"><p>Please fix the following input errors:</p>
                        <ul>
                            {certificationErrors.map((message) => (
                            <li key={message}>{message}</li>
                            ))}
                        </ul>
                    </div><br />
                </div>
                <div className="modal-footer">
                    <button onClick={closeErrorPopup} id="alert-modal-ok" aria-hidden="true" className="btn small orange align-center right" style={{display: "block"}}>OK</button>
                </div>
            </div>
            </React.Fragment>
        )}


        <div className="home_header_container_class">
            <div className="content">
                <div className="breadcrumbs">
                    <div className="path">
                        <ul>
                            <li><span className="orange text-shadow">{certification.study && certification.study.acronym ? certification.study.acronym.toUpperCase() : ""}</span></li>
                            <li className="separator text-shadow">&gt;</li>
                            <li><span className="orange text-shadow">Certifications</span></li>
                            <li className="separator text-shadow">&gt;</li>
                            <li><span className="orange text-shadow">Technician</span></li>
                            <li className="separator text-shadow">&gt;</li>
                            <li><span className="orange text-shadow">{certification.study_exam && certification.study_exam.exam_type.name}</span></li>
                            <li className="separator text-shadow">&gt;</li>
                            <li>{certification.technician && certification.technician.username}</li>
                        </ul>
                    </div>
                </div> {/* .breadcrumbs */}

                <div className="main-form">
                    <form onSubmit={validateSubmit} id="register-exam" method="post">
                        <TechnicianCertificationSubmissionInfo
                            certification={certification}
                        />

                        <TechnicianCertificationData
                            certification={certification}
                            setCertification={setCertification}
                        />

                        <TechnicianCertificationTerms
                            acceptTerms={acceptTerms}
                            setAcceptTerms={setAcceptTerms}
                        />
                    </form>

                    <TechnicianCertificationFiles
                        certification={certification}
                        setCertification={setCertification}

                    />

                    <TechnicianCertificationGraderVars
                        setValidateGradingFields={setValidateGradingFields}
                        certification={certification}
                        setCertification={setCertification}
                        gradingStatus={gradingStatus}
                        setGradingStatus={setGradingStatus}
                        gradingVars={certification.technician_certification_grading}
                    />

                    <TechnicianCertificationActions
                        studyId={props.match.params.id}
                        submitCertification={validateSubmit}

                    />
                </div>
            </div>
        </div>
        </React.Fragment>

    );
}

export default TechnicianCertificationRecertify;

function TechnicianCertificationSubmissionInfo(props) {

    let evaluatedBy = "", submittedBy = "";

    const getStatusDisplay = status => {

        if (status === 'notstarted') {
            return 'Not Started';
        }
        else if (status === 'pending') {
            return 'Pending';
        }
        else if (status === 'notcertified') {
            return 'Not Certified'
        }
        else if (status === 'incertification') {
            return 'In Certification'
        }
        else if (status === 'certified') {
            return 'Certified'
        }

        return '';

    }

    if (props.certification.user_graded) {
        evaluatedBy = '<p>EVALUATED BY: '+props.certification.user_graded.name + ' ' + props.certification.user_graded.last_name +' ('+props.certification.user_graded.username+')</p><p>EVALUATED ON: '+formatDate(props.certification.grading_date, true)+'</p><br />';
    }

    if (props.certification.user_submitted) {
        submittedBy = '<p>SUBMITTED BY: '+props.certification.user_submitted.name + ' ' + props.certification.user_submitted.last_name +' ('+props.certification.user_submitted.username+')</p><p>SUBMITTED ON: '+formatDate(props.certification.submission_date, true)+'</p><br />';
    }

    return (
        <div className="form-group content">
            <br />

            { ReactHtmlParser (evaluatedBy) }
            { ReactHtmlParser (submittedBy) }

            <p>CERTIFICATION STATUS: {getStatusDisplay(props.certification.status)}</p>
        </div>
    );

}

function TechnicianCertificationData(props) {

    return (
        <div className="form-group">
            <div className="span5 insert-visit" style={{width: "46%"}}>
                <div className="row exam-date" >
                    <div className="label">
                        <label htmlFor="submission_date" className="required">Date *</label>
                    </div>
                    <div className="input">
                        <DatePicker
                            dateFormat="dd/MM/yyyy hh:mm"
                            id="submission_date"
                            name="submission_date"
                            disabled={true}
                            selected={props.certification.submission_date && initDateTime(props.certification.submission_date)}
                        />
                    </div>
                </div> {/* .row */}
            </div>

            <div className="span5" style={{width: "54%"}}>
                <textarea
                    style={{height: "200px"}}
                    className="submit-comment"
                    maxLength="5000"
                    placeholder="Comments"
                    name="submitter_comment"
                    id="submitter_comment"
                    disabled={true}
                    value={props.certification.submitter_comment}
                ></textarea>
            </div>
        </div>
    );
}

function TechnicianCertificationTerms(props) {
    return (
        <div className="form-group content">
            <br />
            <input
                type="checkbox"
                id="checkbox-accept"
                checked={props.acceptTerms}
                disabled={true}
                onClick={(ev) => {props.setAcceptTerms(ev.target.checked)}}
            />
            <label htmlFor="checkbox-accept" className="wrapped">I have read and understand the relevant protocols (i.e.. Study Protocol and CORC Technical Protocol(s)), and I agree to abide by the design and procedures of the trial.</label>
            <br />
        </div>
    );
}


function TechnicianCertificationFiles(props) {

    return (
        <React.Fragment>

        {props.certification.technician_files.map( (file, i) => (
        <TechnicianCertificationFileItem
            certification={props.certification}
            key={i}
            file={file}
        />
        ))}

        </React.Fragment>
    );

}


function TechnicianCertificationFileItem(props) {
    return (
        <div className="form-group">
            <div className="file-uploaded">
                <div className="row">
                    <a target="_blank" rel="noopener noreferrer" href={process.env.REACT_APP_API_ENDPOINT + "/certifications/equipment/"+props.certification.id+'/file/'+props.file.id+'/download/'} className="cancel-btn-class btn small grey align-center">Download</a>
                    <div className="file-name">
                        <span className="filename-class">{props.file.name}<b>{" (" + prettyBytes(props.file.file && props.file.file.size ? props.file.file.size : 0) + ")"}</b></span>
                    </div>
                    <div className="file-comment" style={{display: 'block', width: "40%", float: "right", marginRight: "32px"}} ref={node => {if (node) { node.style.setProperty("float", "right", "important")}}}>
                        <input
                            maxLength="255"
                            type="text"
                            className="uploaded_file"
                            value={props.file.description}
                            placeholder="Comment"
                            disabled
                        />
                    </div>
                </div>
            </div>
        </div>
    );
}

function TechnicianCertificationGraderVars(props) {

    useEffect(function() {

        let fields = [];

        if(props.certification.study_exam && props.certification.study_exam.exam_type.include_photo_quality) {
             fields.push("focus");
             fields.push("field_definition");
             fields.push("stereopsis_definition");
        }

        if(props.certification.study_exam && props.certification.study_exam.exam_type.include_quality) {
            fields.push("quality");
        }

        if(props.certification.study_exam && props.certification.study_exam.exam_type.include_photo_fields) {
            fields.push("photo_fields");
        }

        fields.push("file_labelling");
        fields.push("protocol_procedures");

        props.setValidateGradingFields(fields);

    }, [props.certification.study_exam]);


    const onChangeHandler = (ev) => {
        let name = ev.target.name;
        let value = ev.target.value;

        let editedGradingVars = JSON.parse(JSON.stringify(props.gradingVars));

        editedGradingVars[0][name] = value;
        props.setCertification({...props.certification, technician_certification_grading: editedGradingVars});
    };

    const statusOptions = [
        {value:"pending", label: "Pending"},
        {value:"certified", label: "Certified"},
        {value:"notcertified", label: "Not Certified"},
    ];

    const onStatusChangeHandler = sel => {
        props.setGradingStatus(sel);
    };

    return (
        <React.Fragment>
            <div className="overview">
                <h2 className="text-shadow">Grader Variables</h2>
            </div>

            <div className="form-group checkbox-group group-page">
                <form id="form-grader-vars" className="certification-grade-vars" action="" name="" method="POST">
                    <div className="form-vars check-onload">
                        <table>
                            <thead>
                                <tr className="head-table">
                                    <th width="28%"></th>
                                    {props.gradingVars[0] && props.gradingVars[0].labelling_choices && Object.keys(props.gradingVars[0].labelling_choices).map((k, i) => (
                                    <th key={i}>{props.gradingVars[0].labelling_choices[k]}</th>
                                    ))}
                                    <th width="35%">Comments</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td>FILE(S) / FOLDER (S) LABELLING</td>
                                    {props.gradingVars[0] && props.gradingVars[0].labelling_choices && Object.keys(props.gradingVars[0].labelling_choices).map((k, i) => (
                                    <td key={i}>
                                        <input
                                            className="native"
                                            type="radio"
                                            name="file_labelling"
                                            value={k}
                                            checked={props.gradingVars[0] && props.gradingVars[0].file_labelling === k}
                                            onChange={ev => onChangeHandler(ev)}
                                        />
                                    </td>
                                    ))}
                                    <td>
                                        <textarea
                                            style={{width: "95%", height: "100px"}}
                                            name="file_labelling_comments"
                                            value={props.gradingVars[0] && props.gradingVars[0].file_labelling_comments}
                                            onChange={ev => onChangeHandler(ev)}
                                        ></textarea>
                                    </td>
                                </tr>
                            </tbody>
                        </table>

                        {props.certification.study_exam && props.certification.study_exam.exam_type.include_photo_quality && (
                        <table>
                            <thead>
                                <tr className="head-table">
                                    <th width="28%">PHOTOGRAPHIC QUALITY</th>
                                    {props.gradingVars[0] && props.gradingVars[0].photo_quality_choices && Object.keys(props.gradingVars[0].photo_quality_choices).map((k, i) => (
                                    <th key={i}>{props.gradingVars[0].photo_quality_choices[k]}</th>
                                    ))}
                                    <th width="35%">Comments</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td>FOCUS / CLARITY</td>
                                    {props.gradingVars[0] && props.gradingVars[0].photo_quality_choices && Object.keys(props.gradingVars[0].photo_quality_choices).map((k, i) => (
                                    <td key={i}>
                                        <input
                                            className="native"
                                            type="radio"
                                            name="focus"
                                            value={k}
                                            checked={props.gradingVars[0] && props.gradingVars[0].focus === k}
                                            onChange={ev => onChangeHandler(ev)}
                                        />
                                    </td>
                                    ))}
                                    <td rowSpan="3">
                                        <textarea
                                            style={{width: "95%", height: "100px"}}
                                            name="photo_quality_comments"
                                            value={props.gradingVars[0] && props.gradingVars[0].photo_quality_comments}
                                            onChange={ev => onChangeHandler(ev)}
                                        ></textarea>
                                    </td>
                                </tr>
                                <tr>
                                    <td>FIELD DEFINITION</td>
                                    {props.gradingVars[0] && props.gradingVars[0].photo_quality_choices && Object.keys(props.gradingVars[0].photo_quality_choices).map((k, i) => (
                                    <td key={i}>
                                        <input
                                            className="native"
                                            type="radio"
                                            name="field_definition"
                                            value={k}
                                            checked={props.gradingVars[0] && props.gradingVars[0].field_definition === k}
                                            onChange={ev => onChangeHandler(ev)}
                                        />
                                    </td>
                                    ))}
                                </tr>
                                <tr>
                                    <td>STEREOPSIS</td>
                                    {props.gradingVars[0] && props.gradingVars[0].photo_quality_choices && Object.keys(props.gradingVars[0].photo_quality_choices).map((k, i) => (
                                    <td key={i}>
                                        <input
                                            className="native"
                                            type="radio"
                                            name="stereopsis_definition"
                                            value={k}
                                            checked={props.gradingVars[0] && props.gradingVars[0].stereopsis_definition === k}
                                            onChange={ev => onChangeHandler(ev)}
                                        />
                                    </td>
                                    ))}
                                </tr>

                            </tbody>
                        </table>
                        )}

                        {props.certification.study_exam && props.certification.study_exam.exam_type.include_quality && (
                        <table>
                            <thead>
                                <tr className="head-table">
                                    <th width="28%"></th>
                                    {props.gradingVars[0] && props.gradingVars[0].quality_choices && Object.keys(props.gradingVars[0].quality_choices).map((k, i) => (
                                    <th key={i}>{props.gradingVars[0].quality_choices[k]}</th>
                                    ))}
                                    <th width="35%">Comments</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td>QUALITY</td>
                                    {props.gradingVars[0] && props.gradingVars[0].photo_quality_choices && Object.keys(props.gradingVars[0].photo_quality_choices).map((k, i) => (
                                    <td key={i}>
                                        <input
                                            className="native"
                                            type="radio"
                                            name="quality"
                                            value={k}
                                            checked={props.gradingVars[0] && props.gradingVars[0].quality === k}
                                            onChange={ev => onChangeHandler(ev)}
                                        />
                                    </td>
                                    ))}
                                    <td>
                                        <textarea
                                            style={{width: "95%", height: "100px"}}
                                            name="quality_comments"
                                            value={props.gradingVars[0] && props.gradingVars[0].quality_comments}
                                            onChange={ev => onChangeHandler(ev)}
                                        ></textarea></td>
                                </tr>
                            </tbody>
                        </table>
                        )}

                        <table>
                            <thead>
                                <tr className="head-table">
                                    <th width="28%"></th>
                                    {props.gradingVars[0] && props.gradingVars[0].protocol_choices && Object.keys(props.gradingVars[0].protocol_choices).map((k, i) => (
                                    <th key={i}>{props.gradingVars[0].protocol_choices[k]}</th>
                                    ))}
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td>PROTOCOL PROCEDURES</td>
                                    {props.gradingVars[0] && props.gradingVars[0].protocol_choices && Object.keys(props.gradingVars[0].protocol_choices).map((k, i) => (
                                    <td key={i}>
                                        <input
                                            className="native"
                                            type="radio"
                                            name="protocol_procedures"
                                            value={k}
                                            checked={props.gradingVars[0] && props.gradingVars[0].protocol_procedures === k}
                                            onChange={ev => onChangeHandler(ev)}
                                        />
                                    </td>
                                    ))}
                                </tr>
                            </tbody>
                        </table>

                        {props.certification.study_exam && props.certification.study_exam.exam_type.include_photo_fields && (
                        <table>
                            <thead>
                                <tr className="head-table">
                                    <th width="28%"></th>
                                    {props.gradingVars[0] && props.gradingVars[0].photo_fields_choices && Object.keys(props.gradingVars[0].photo_fields_choices).map((k, i) => (
                                    <th key={i}>{props.gradingVars[0].photo_fields_choices[k]}</th>
                                    ))}
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td>PHOTOGRAPHY FIELDS</td>
                                    {props.gradingVars[0] && props.gradingVars[0].photo_fields_choices && Object.keys(props.gradingVars[0].photo_fields_choices).map((k, i) => (
                                    <td key={i}>
                                        <input
                                            className="native"
                                            type="radio"
                                            name="photo_fields"
                                            value={k}
                                            checked={props.gradingVars[0] && props.gradingVars[0].photo_fields === k}
                                            onChange={ev => onChangeHandler(ev)}
                                        />
                                    </td>
                                    ))}
                                </tr>
                            </tbody>
                        </table>
                        )}

                        <textarea
                            placeholder="Comments"
                            className="form-comment"
                            name="comments"
                            value={props.gradingVars[0] && props.gradingVars[0].comments}
                            onChange={ev => onChangeHandler(ev)}
                        ></textarea>
                        <br /><br />
                        <textarea
                            placeholder="Other Comments"
                            className="form-comment"
                            name="internal_comments"
                            value={props.gradingVars[0] && props.gradingVars[0].internal_comments}
                            onChange={ev => onChangeHandler(ev)}
                        ></textarea>
                    </div>

                    <div className="span5" style={{width: "33.5%", float: "right", marginBottom: "10px", marginTop: "-8px"}}>
                        <div className="row" style={{paddingTop: "4px"}}>
                            <div className="label">
                                <label htmlFor="status" className="required">Certification Status <span className="required"></span></label>
                            </div>
                            <CorcSelect
                                options={statusOptions}
                                name="status"
                                id="status"
                                value={statusOptions.filter(o => o.value === props.gradingStatus)}
                                onChange={selectedOption => onStatusChangeHandler(selectedOption.value)}
                                isSearchable={false}
                                containerStyles={{width: "175px", float: "left"}}
                                placeholder="Select a status"
                            />
                        </div>
                    </div>

                </form>
            </div>

        </React.Fragment>
    );
}


function TechnicianCertificationActions(props) {

    const buttons = () => {
        return (
            <React.Fragment>
            <Link
                className="btn small grey align-center left"
                to={"/studies/" + props.studyId + "/certifications"}
            >Back</Link>
            <button
                name=""
                className="btn small orange align-center left"
                onClick={props.submitCertification}
            >Submit</button>
            </React.Fragment>
        );
    };

    return (
        <div className="form-actions" style={{float: "right"}}>
            {buttons()}
        </div>

    );
}
