import React, {useEffect, useState}  from 'react';
import axios from 'axios';
import {Link} from 'react-router-dom';
import { CSVLink } from "react-csv";
import jsPDF from "jspdf";
import {formatDate} from 'components/utils/Helpers';
import "jspdf-autotable";

function Patients(props) {
    useEffect(function() {

        const getStudy = async () => {
            let count = props.overlayVisible;
            props.setOverlayVisible( (count) => count + 1);

            const dataCSV = [];
            const items = await axios.get(process.env.REACT_APP_API_ENDPOINT+'/studies/'+props.match.params.id+'/centers/'+props.match.params.center+'/patients/',
                { withCredentials: true, headers: {'Content-Type': 'application/json'}});

            setStudy(items.data);

            if (items.data.centers.length > 0) {

                items.data.centers[0].patients.map(function(p) {

                    //let me = JSON.parse(JSON.stringify(s));
                    let me = {}, i;

                    me.patient_num = p.patient_num;
                    me.status = p.status[0].toUpperCase() + p.status.substr(1);
                    if (p.study_left_eye && p.study_right_eye) {
                        me.study_eye = "Left & Right";
                    }
                    else if (p.study_left_eye) {
                        me.study_eye = "Left";
                    }
                    else if (p.study_right_eye) {
                        me.study_eye = "Right";
                    }

                    for (i in p.card_vars) {

                        if (p.card_vars[i].var_type.uid === "patient-initials") {
                            me.patient_initials = p.card_vars[i].value;
                        }

                        if (p.card_vars[i].var_type.uid === "randomization-number") {
                            me.randomization_number = p.card_vars[i].value;
                        }
                    }

                    dataCSV.push(me);
                    return false;
                });

                setDataToCSV(dataCSV);
            }

            props.setOverlayVisible( (count) => count - 1);
        };

        props.setNavLinks([
            {title: 'Studies', url: '/studies/', icon: 'studies'},
            {title: 'Clinical Site', url: '/studies/' + props.match.params.id + '/navigate', icon: 'center'},
            {title: 'Patients', url: '/studies/' + props.match.params.id + '/center/'+props.match.params.center+'/patients', icon: 'pacients'},
        ]);

        localStorage.setItem('corc_study_patients_sortRandomizationNumber', "");
        localStorage.setItem('corc_study_patients_sortPatientNumber', "");
        localStorage.setItem('corc_study_patients_sortPatientInitials', "");
        localStorage.setItem('corc_study_patients_sortPatientStatus', "");
        localStorage.setItem('corc_study_patients_sortStudyEye', "");

        getStudy();

    }, []);


    const [study, setStudy] = useState({
        'id': 0,
        'status': '',
        'acronym': '',
        'full_name': '',
        'start_date': '',
        'end_date': '',
        'description': '',
        'select_patient_eye': false,
        'patient_details': '',
        'patient_card_vars': [],
        'protocol_num': '',
        'corc_num': '',
        'exams': [],
        'centers': [],
        'scheduled_visits': [],
        'technicians': [],
        'graders': [],
        'monitors': []
    });

    const [dataToCSV, setDataToCSV] = useState([]);

    let headersCSV = [];

    headersCSV.push({ label: "Patient Number", key: "patient_num" });

    if (study.centers.length > 0 && study.centers[0].patients.length > 0 && study.centers[0].patients[0].card_vars.filter(f => f.var_type.uid === 'randomization-number').length > 0) {
        headersCSV.push({label: "Randomization Number", key: "randomization_number"});
    }

    if (study.centers.length > 0 && study.centers[0].patients.length > 0 && study.centers[0].patients[0].card_vars.filter(f => f.var_type.uid === 'patient-initials').length > 0) {
        headersCSV.push({ label: "Patient Initials", key: "patient_initials" });
    }

    headersCSV.push({ label: "Study Eye", key: "study_eye" });
    headersCSV.push({ label: "Last Visit", key: "" });
    headersCSV.push({ label: "Patient Status", key: "status" });
    headersCSV.push({ label: "Next Visit Date", key: "" });


    const exportPDF = () => {

        const marginLeft = 40;
        const title = "";
        const doc = new jsPDF("landscape", "pt", "A4");

        doc.setFontSize(15);


        let headers = [];
        let tmp = [];

        tmp.push("Patient Number");
        if (study.patient_card_vars.length > 0 && study.patient_card_vars.filter(f => f.uid === 'randomization-number').length > 0) {
            tmp.push("Randomization Number");
        }
        if (study.patient_card_vars.length > 0 && study.patient_card_vars.filter(f => f.uid === 'patient-initials').length > 0) {
            tmp.push("Patient Initials");
        }
        tmp.push("Study Eye");
        tmp.push("Last Visit");
        tmp.push("Patient Status");
        tmp.push("Next Visit Date");

        headers.push(tmp);

        const data = dataToCSV.map(function(i) {
          let tmp = [];

            tmp.push(i.patient_num);
            if (study.patient_card_vars.length > 0 && study.patient_card_vars.filter(f => f.uid === 'randomization-number').length > 0) {
                tmp.push(i.randomization_number);
            }
            if (study.patient_card_vars.length > 0 && study.patient_card_vars.filter(f => f.uid === 'patient-initials').length > 0) {
                tmp.push(i.patient_initials);
            }

            tmp.push(i.study_eye);
            tmp.push("");
            tmp.push(i.status);
            tmp.push("");

            return tmp;
        });

        let content = {
          startY: 50,
          head: headers,
          body: data
        };

        doc.text(title, marginLeft, 40);
        doc.autoTable(content);
        doc.save("patients-export.pdf")
  }


    return (
        <div className="home_header_container_class wide">
            <div className="content">
                <div className="breadcrumbs">
                    <div className="path">
                        <ul>
                            <li><span className="orange text-shadow">{study.acronym ? study.acronym.toUpperCase() : ''}</span></li>
                            <li className="separator text-shadow">&gt;</li>
                            <li><span className="orange text-shadow">{study.centers && study.centers.length && study.centers[0].study_site_num}</span></li>
                            <li className="separator text-shadow">&gt;</li>
                            <li>Patients</li>
                        </ul>
                    </div>
                    <div className="sub-menu">
                        <CSVLink className="btn normal grey right" data={dataToCSV} headers={headersCSV} filename={"patients-export.csv"}>
                            <span className="icon icon-list report"></span>
                            Export to CSV
                        </CSVLink>
                        <button onClick={exportPDF} className="btn normal grey right">
                            <span className="icon icon-list report"></span>Export to PDF
                        </button>
                    </div>
                </div> {/* .breadcrumbs */}

                <PatientsList
                    center = {study.centers[0]}
                    study = {study}
                    setStudy = {setStudy}
                    params = {props.match.params}
                />
            </div>
        </div>
    );
}

export default Patients;

function PatientsList(props) {

    const togglePatientNumberSort = () => {

        const centers = JSON.parse(JSON.stringify(props.study.centers));
        let sort = localStorage.getItem('corc_study_patients_sortPatientNumber');

        if (sort === ">") {
            centers[0].patients.sort((a, b) => (a.patient_num.toLowerCase() < b.patient_num.toLowerCase()) ? 1 : -1)
            localStorage.setItem('corc_study_patients_sortPatientNumber', "<")
        }
        else {
            centers[0].patients.sort((a, b) => (a.patient_num.toLowerCase() > b.patient_num.toLowerCase()) ? 1 : -1)
            localStorage.setItem('corc_study_patients_sortPatientNumber', ">")
        }

        props.setStudy({...props.study, centers: centers});
    }

    const toggleRandomizationNumberSort = () => {

        const centers = JSON.parse(JSON.stringify(props.study.centers));
        let sort = localStorage.getItem('corc_study_patients_sortRandomizationNumber');

        if (sort === ">") {
            centers[0].patients.sort(function(a, b) {

                let randA = a.card_vars.find(r => {
                   return r.var_type.uid === 'randomization-number';
                });

                let randB = b.card_vars.find(r => {
                   return r.var_type.uid === 'randomization-number';
                });

                if (randA === undefined) {
                    randA = '';
                }
                else {
                    randA = randA.value;
                }

                if (randB === undefined) {
                    randB = '';
                }
                else {
                    randB = randB.value;
                }

               return (randA.toLowerCase() < randB.toLowerCase()) ? 1 : -1;
            });

            localStorage.setItem('corc_study_patients_sortRandomizationNumber', "<")

        }
        else {

            centers[0].patients.sort(function(a, b) {

                let randA = a.card_vars.find(r => {
                   return r.var_type.uid === 'randomization-number';
                });

                let randB = b.card_vars.find(r => {
                   return r.var_type.uid === 'randomization-number';
                });

                if (randA === undefined) {
                    randA = '';
                }
                else {
                    randA = randA.value;
                }

                if (randB === undefined) {
                    randB = '';
                }
                else {
                    randB = randB.value;
                }

               return (randA.toLowerCase() > randB.toLowerCase()) ? 1 : -1;
            });

            localStorage.setItem('corc_study_patients_sortRandomizationNumber', ">")
        }

        props.setStudy({...props.study, centers: centers});
    }

    const togglePatientInitialsSort = () => {

        const centers = JSON.parse(JSON.stringify(props.study.centers));
        let sort = localStorage.getItem('corc_study_patients_sortPatientInitials');

        if (sort === ">") {
            centers[0].patients.sort(function(a, b) {

                let randA = a.card_vars.find(r => {
                   return r.var_type.uid === 'patient-initials';
                });

                let randB = b.card_vars.find(r => {
                   return r.var_type.uid === 'patient-initials';
                });

                if (randA === undefined) {
                    randA = '';
                }
                else {
                    randA = randA.value;
                }

                if (randB === undefined) {
                    randB = '';
                }
                else {
                    randB = randB.value;
                }

               return (randA.toLowerCase() < randB.toLowerCase()) ? 1 : -1;
            });

            localStorage.setItem('corc_study_patients_sortPatientInitials', "<")

        }
        else {

            centers[0].patients.sort(function(a, b) {

                let randA = a.card_vars.find(r => {
                   return r.var_type.uid === 'patient-initials';
                });

                let randB = b.card_vars.find(r => {
                   return r.var_type.uid === 'patient-initials';
                });

                if (randA === undefined) {
                    randA = '';
                }
                else {
                    randA = randA.value;
                }

                if (randB === undefined) {
                    randB = '';
                }
                else {
                    randB = randB.value;
                }

               return (randA.toLowerCase() > randB.toLowerCase()) ? 1 : -1;
            });

            localStorage.setItem('corc_study_patients_sortPatientInitials', ">")
        }

        props.setStudy({...props.study, centers: centers});
    }

    const togglePatientStatusSort = () => {

        const centers = JSON.parse(JSON.stringify(props.study.centers));
        let sort = localStorage.getItem('corc_study_patients_sortPatientStatus');

        if (sort === ">") {
            centers[0].patients.sort((a, b) => (a.status.toLowerCase() < b.status.toLowerCase()) ? 1 : -1)
            localStorage.setItem('corc_study_patients_sortPatientStatus', "<")
        }
        else {
            centers[0].patients.sort((a, b) => (a.status.toLowerCase() > b.status.toLowerCase()) ? 1 : -1)
            localStorage.setItem('corc_study_patients_sortPatientStatus', ">")
        }

        props.setStudy({...props.study, centers: centers});
    }

    const toggleStudyEyeSort = () => {

        const centers = JSON.parse(JSON.stringify(props.study.centers));
        let sort = localStorage.getItem('corc_study_patients_sortStudyEye');

        if (sort === ">") {
            centers[0].patients.sort((a, b) => (a.study_left_eye === b.study_left_eye) ? 0 : a.study_right_eye ? 1 : -1);
            localStorage.setItem('corc_study_patients_sortStudyEye', "<")
        }
        else {
            centers[0].patients.sort((a, b) => (a.study_left_eye === b.study_left_eye) ? 0 : a.study_right_eye ? -1 : 1);
            localStorage.setItem('corc_study_patients_sortStudyEye', ">")
        }

        props.setStudy({...props.study, centers: centers});
    }

    return (
        <div className="visits-list list">
            <div className="list-head">
                <table>
                    <tbody>
                        <tr>
                            <th width="11%">
                                <button onClick={togglePatientNumberSort}>Patient Number</button>
                            </th>

                            {props.study.patient_card_vars.length > 0 && props.study.patient_card_vars.filter(f => f.uid === 'randomization-number').length > 0 && (
                            <th width="15%">
                                <button onClick={toggleRandomizationNumberSort}>Randomization Number</button>
                            </th>
                            )}

                            {props.study.patient_card_vars.length > 0 && props.study.patient_card_vars.filter(f => f.uid === 'patient-initials').length > 0 && (
                            <th width="11%">
                                <button onClick={togglePatientInitialsSort}>Patient Initials</button>
                            </th>
                            )}
                            <th width="13%">
                                <button onClick={toggleStudyEyeSort}>Study Eye</button>
                            </th>
                            <th width="12%">Last Visit</th>
                            <th width="14%">
                                <button onClick={togglePatientStatusSort}>Patient Status</button>
                            </th>
                            <th>Next Visit Date</th>
                        </tr>
                    </tbody>
                </table>
            </div>
            <div className="list-body">
                <table id="list-table">
                    <tbody className="list-visits">
                        {props.center && props.center.patients.map((patient, i) => (
                        <PatientsListItem
                            study={props.study}
                            key={i}
                            patient={patient}
                            params={props.params}
                        />
                        ))}
                    </tbody>
                </table>
            </div>
        </div>
    );
}

function PatientsListItem(props) {

    const getPatientInitials = () => {
        let initials = props.patient.card_vars.filter(f => f.var_type.uid === 'patient-initials');

        if(initials.length) {
            return initials[0].value;
        }

        return "";
    };

    const getPatientRandomNum = () => {
        let randNum = props.patient.card_vars.filter(f => f.var_type.uid === 'randomization-number');

        if(randNum.length) {
            return randNum[0].value;
        }

        return "";
    };

    return (
        <tr className="visit">
            <td width="11%">{props.patient.patient_num}</td>

            {props.study.patient_card_vars.length > 0 && props.study.patient_card_vars.filter(f => f.uid === 'randomization-number').length > 0 && (
            <td width="15%">{getPatientRandomNum()}</td>
            )}

            {props.study.patient_card_vars.length > 0 && props.study.patient_card_vars.filter(f => f.uid === 'patient-initials').length > 0 && (
            <td width="11%">{getPatientInitials()}</td>
            )}

            <td width="13%">
                <span className={"icon min-eye" + (props.patient.study_right_eye ? '' : ' enabled')}></span>
                <span className={"icon min-eye" + (props.patient.study_left_eye ? '' : ' enabled')}></span>
            </td>
            <td width="12%">{props.patient.last_visit || '--'}</td>
            <td width="14%">{props.patient.status}</td>
            <td width="10%">{ formatDate(props.patient.next_visit) || '--/--/--' }</td>
            <td className="btn-list">
                <ul style={{float: 'right'}}>
                    <li>
                        <Link
                            to={"/studies/" + props.params.id + "/center/" + props.params.center + "/patients/" + props.patient.id}
                            className="btn xsmall grey"
                        >
                            <span className="icon-list user-small"></span>
                        </Link>
                    </li>
                    <li>
                        <Link
                            to={"/studies/" + props.params.id + "/center/" + props.params.center + "/patients/" + props.patient.id + "/visits"}
                            className="btn small grey"
                        >
                            <span className="icon-list visits-small" style={{width: "30px"}}></span>Visits
                        </Link>
                    </li>
                </ul>
            </td>
        </tr>
    );
}
