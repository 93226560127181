import React, {useEffect, useState}  from 'react';
import axios from 'axios';
import {Link} from 'react-router-dom';
import { CSVLink } from "react-csv";
import CorcSelect from 'components/utils/CorcSelect';
import jsPDF from "jspdf";
import "jspdf-autotable";

function StudyUsers(props) {

    const [study, setStudy] = useState({
        'id': 0,
        'status': '',
        'acronym': '',
        'full_name': '',
        'start_date': '',
        'end_date': '',
        'description': '',
        'select_patient_eye': false,
        'patient_details': '',
        'patient_card_vars': [],
        'protocol_num': '',
        'corc_num': '',
        'exams': [],
        'centers': [],
        'technicians': [],
        'coordinators': [],
        'graders': [],
        'monitors': []
    });

    useEffect(function() {

        const getCenters = async () => {
            let count = props.overlayVisible;
            props.setOverlayVisible( (count) => count + 1);

            const items = await axios.get(process.env.REACT_APP_API_ENDPOINT+'/centers/summary/',
                { withCredentials: true, headers: {'Content-Type': 'application/json'}});

            setAllCenters(items.data);
            props.setOverlayVisible( (count) => count - 1);
        };

        const getStudy = async () => {
            let count = props.overlayVisible;
            props.setOverlayVisible( (count) => count + 1);

            const items = await axios.get(process.env.REACT_APP_API_ENDPOINT+'/studies/'+props.match.params.id+'/users/',
                { withCredentials: true, headers: {'Content-Type': 'application/json'}});


            if (props.userAuth.user.role === 'monitor') {
                items.data.status = 'closed';
            }

            setStudy(items.data);

            if(items.data.status === 'open') {
                getCenters();
            }

            props.setOverlayVisible( (count) => count - 1);
        };


        if (props.userAuth.user.role !== 'admin' && props.userAuth.user.role !== 'monitor') {
            props.history.push('/studies');
            return;
        }


        props.setNavLinks([{title: 'Studies', url: '/studies/', icon: 'studies'},{title: 'Users', url: props.location.pathname, icon: 'users'}]);
        getStudy();

        localStorage.setItem('corc_study_users_centers_sortName', "");
        localStorage.setItem('corc_study_users_monitors_sortName', "");
        localStorage.setItem('corc_study_users_graders_sortName', "");
        localStorage.setItem('corc_study_users_technicians_sortName', "");
        localStorage.setItem('corc_study_users_technicians_sortCenter', "");
        localStorage.setItem('corc_study_users_technicians_sortCenter', "");
        localStorage.setItem('corc_study_users_coordinators_sortName', "");
        localStorage.setItem('corc_study_users_coordinators_sortCenter', "");
        localStorage.setItem('corc_study_users_coordinators_sortCenter', "");



    }, []);

    useEffect(function() {

        const monitorsCSV = [];
        const centersCSV = [];
        const gradersCSV = [];
        const coordinatorsCSV = [];
        const techCSV = [];

         /* CSV stuff */
        if (study.centers) {
            study.centers.map(function(c) {

                let me = JSON.parse(JSON.stringify(c));

                me.technicians = me.total_technicians;
                me.patients = me.total_patients;

                me.study_site_num = me.study_site_num.toUpperCase();
                centersCSV.push(me);

                return false;
            });

            setCentersToCSV(centersCSV);

        }

        if (study.monitors) {
            study.monitors.map(function(c) {

                let me = JSON.parse(JSON.stringify(c));

                monitorsToCSV.push(me);

                return false;
            });

            setMonitorsToCSV(monitorsCSV);
        }

        if (study.graders) {
            study.graders.map(function(c) {

                let me = JSON.parse(JSON.stringify(c));
                me.pending_exams = c.pending_exams;
                me.graded_exams = c.graded_exams;
                me.rejected_exams = c.rejected_exams;

                gradersCSV.push(me);

                return false;
            });

            setGradersToCSV(gradersCSV);
        }

        if (study.coordinators) {
            study.coordinators.map(function(c) {

                let me = JSON.parse(JSON.stringify(c));

                if (me.user.center) {
                    me.user.center.acronym = me.user.center.acronym.toUpperCase();
                }

                coordinatorsCSV.push(me);

                return false;
            });

            setCoordinatorsToCSV(coordinatorsCSV);
        }

        if (study.technicians) {
            study.technicians.map(function(c) {

                let me = JSON.parse(JSON.stringify(c));
                me.submitted_exams = c.submitted_exams;
                me.successfull_submissions = c.successful_exams;
                me.rejected_submissions = c.rejected_exams;

                if (me.user.center) {
                    me.user.center.acronym = me.user.center.acronym.toUpperCase();
                }

                techCSV.push(me);

                return false;
            });

            setTechniciansToCSV(techCSV);
        }
    }, [study]);

    const [centersToCSV, setCentersToCSV] = useState([]);
    const centersHeadersCSV = [
      { label: "Clinical Site", key: "study_site_num" },
      { label: "Technicians", key: "technicians" },
      { label: "Patients", key: "patients" }
    ];

    const [monitorsToCSV, setMonitorsToCSV] = useState([]);
    const monitorsHeadersCSV = [
      { label: "Monitor", key: "user.username" },
      { label: "Registered", key: "registered" }
    ];

    const [gradersToCSV, setGradersToCSV] = useState([]);
    const gradersHeadersCSV = [
        { label: "Grader", key: "user.username" },
        { label: "Pending Exams", key: "pending_exams" },
        { label: "Graded Exams", key: "graded_exams" },
        { label: "Rejected Exams", key: "rejected_exams" },
        { label: "Registered", key: "registered" }
    ];

    const [techniciansToCSV, setTechniciansToCSV] = useState([]);
    const techniciansHeadersCSV = [
      { label: "Technician", key: "user.username" },
      { label: "Clinical Site", key: "user.center.acronym" },
      { label: "Submitted Exams", key: "submitted_exams" },
      { label: "Successfull submissions", key: "successfull_submissions" },
      { label: "Rejected submissions", key: "rejected_submissions"},
        { label: "Registered", key: "registered" }
    ];

    const [coordinatorsToCSV, setCoordinatorsToCSV] = useState([]);
    const coordinatorsHeadersCSV = [
      { label: "Coordinator", key: "user.username" },
      { label: "Clinical Site", key: "user.center.acronym" },
    ];

    const [allCenters, setAllCenters] = useState([]);


    const [allUsers, setAllUsers] = useState([]);

    const [hasMonitors, setHasMonitors] = useState(false);
    const [hasCoordinators, setHasCoordinators] = useState(false);
    const [hasTechnicians, setHasTechnicians] = useState(false);
    const [hasGraders, setHasGraders] = useState(false);

    const [currentStudy, setCurrentStudy] = useState({});

    /*
        Errors
    */
    const [addNewMonitorErrors, setAddNewMonitorErrors] = useState([]);
    const [addNewGraderErrors, setAddNewGraderErrors] = useState([]);
    const [addNewTechnicianErrors, setAddNewTechnicianErrors] = useState([]);
    const [addNewCoordinatorErrors, setAddNewCoordinatorErrors] = useState([]);
    /*
     *  Popups
     * */
    //add & edit
    const [addEditCenterPopupVisible, setAddEditCenterPopupVisible] = useState(false);
    const [addMonitorPopupVisible, setAddMonitorPopupVisible] = useState(false);
    const [addGraderPopupVisible, setAddGraderPopupVisible] = useState(false);
    const [addTechnicianPopupVisible, setAddTechnicianPopupVisible] = useState(false);
    const [addCoordinatorPopupVisible, setAddCoordinatorPopupVisible] = useState(false);
    const [graderExamsPopupVisible, setGraderExamsPopupVisible] = useState(false);
    const [technicianExamsPopupVisible, setTechnicianExamsPopupVisible] = useState(false);

    //create new items popup
    const [addNewCenterPopupVisible, setAddNewCenterPopupVisible] = useState(false);
    const [addNewMonitorPopupVisible, setAddNewMonitorPopupVisible] = useState(false);
    const [addNewGraderPopupVisible, setAddNewGraderPopupVisible] = useState(false);
    const [addNewTechnicianPopupVisible, setAddNewTechnicianPopupVisible] = useState(false);
    const [addNewCoordinatorPopupVisible, setAddNewCoordinatorPopupVisible] = useState(false);

    //delete
    const [deleteCenterPopupVisible, setDeleteCenterPopupVisible] = useState(false);
    const [deleteMonitorPopupVisible, setDeleteMonitorPopupVisible] = useState(false);
    const [deleteGraderPopupVisible, setDeleteGraderPopupVisible] = useState(false);
    const [deleteTechnicianPopupVisible, setDeleteTechnicianPopupVisible] = useState(false);
    const [deleteCoordinatorPopupVisible, setDeleteCoordinatorPopupVisible] = useState(false);

    //active items in popups
    const [currentCenter, setCurrentCenter] = useState({});
    const [currentMonitor, setCurrentMonitor] = useState({});
    const [currentGrader, setCurrentGrader] = useState({});
    const [currentTechnician, setCurrentTechnician] = useState({});
    const [currentCoordinator, setCurrentCoordinator] = useState({});
    const [currentNewCenter, setCurrentNewCenter] = useState({});
    const [currentNewMonitor, setCurrentNewMonitor] = useState({});
    const [currentNewGrader, setCurrentNewGrader] = useState({});
    const [currentNewTechnician, setCurrentNewTechnician] = useState({});
    const [currentNewCoordinator, setCurrentNewCoordinator] = useState({});

    const [activeTechCenter, setActiveTechCenter] = useState(false);
    const [centerTechs, setCenterTechs] = useState([]);

    const [activeCoordCenter, setActiveCoordCenter] = useState(false);
    const [centerCoords, setCenterCoords] = useState([]);

    const [emptyStudyCenter, setEmptyStudyCenter] = useState({
        "id": 0,
        "center": {
           "id": 0,
            "created_at": "",
            "updated_at": "",
            "deleted_at": null,
            "is_active": true,
            "full_name": "",
            "acronym": "",
            "address": "",
            "city": "",
            "postcode": "",
            "country": "",
            "website": "",
            "email": "",
            "telephone": "",
            "fax": "",
            "comment": ""
        },
        "is_active": true,
        "principal_investigator": "",
        "pi_notification_email": "",
        "pi_contact_email": "",
        "study_site_num": "",
        "study_coordinator_name": "",
        "study_coordinator_email": "",
        "study_coordinator_phone": "",
        "study_coordinator_fax": "",
        "other_emails": "",
        "total_patients": 0,
        "total_visits": 0,
        "total_failure": 0,
        "total_ongoing": 0,
        "total_completed": 0,
        "total_dropped_out": 0,
        "total_endpoint": 0,
        "total_enrolled": 0
    });

    const [emptyNewStudyUser, setEmptyNewStudyUser] = useState({
        "id": 0,
        "center": {},
        "email": "",
        "username": "",
        "name": "",
        "role": "",
        "exams": []
    });

    const [newCenterErrors, setNewCenterErrors] = useState([]);
    const [addEditCenterErrors, setAddEditCenterErrors] = useState([]);

    const [changeUserViewPermsPopupVisible, setChangeUserViewPermsPopupVisible] = useState(false);
    const [activeUserViewPerms, setActiveUserViewPerms] = useState({});
    const [userViewPerm, setUserViewPerm] = useState(false);

    return (
        <div className="home_header_container_class">
            <div className="content">

                <div className="users">
                    <CentersList
                        centersToCSV={centersToCSV}
                        centersHeadersCSV={centersHeadersCSV}
                        study={study}
                        setStudy={setStudy}
                        emptyStudyCenter={emptyStudyCenter}
                        setCurrentCenter={setCurrentCenter}
                        setAddEditCenterPopupVisible={setAddEditCenterPopupVisible}
                        setDeleteCenterPopupVisible={setDeleteCenterPopupVisible}
                    />

                    <CoordinatorsList
                        coordinatorsToCSV={coordinatorsToCSV}
                        coordinatorsHeadersCSV={coordinatorsHeadersCSV}
                        study={study}
                        setStudy={setStudy}
                        emptyNewStudyUser={emptyNewStudyUser}
                        setCurrentCoordinator={setCurrentCoordinator}
                        setCurrentStudy={setCurrentStudy}
                        setAddCoordinatorPopupVisible={setAddCoordinatorPopupVisible}
                        setDeleteCoordinatorPopupVisible={setDeleteCoordinatorPopupVisible}
                        setChangeUserViewPermsPopupVisible={setChangeUserViewPermsPopupVisible}
                        setActiveUserViewPerms={setActiveUserViewPerms}
                        setUserViewPerm={setUserViewPerm}
                        hasCoordinators={hasCoordinators}
                        setHasCoordinators={setHasCoordinators}
                        allUsers={allUsers}
                        setAllUsers={setAllUsers}
                        overlayVisible={props.overlayVisible}
                        setOverlayVisible={props.setOverlayVisible}
                    />

                    <MonitorsList
                        monitorsToCSV={monitorsToCSV}
                        monitorsHeadersCSV={monitorsHeadersCSV}
                        study={study}
                        setStudy={setStudy}
                        emptyNewStudyUser={emptyNewStudyUser}
                        setCurrentMonitor={setCurrentMonitor}
                        setAddMonitorPopupVisible={setAddMonitorPopupVisible}
                        setDeleteMonitorPopupVisible={setDeleteMonitorPopupVisible}
                        setChangeUserViewPermsPopupVisible={setChangeUserViewPermsPopupVisible}
                        setActiveUserViewPerms={setActiveUserViewPerms}
                        setUserViewPerm={setUserViewPerm}
                        hasMonitors={hasMonitors}
                        setHasMonitors={setHasMonitors}
                        allUsers={allUsers}
                        setAllUsers={setAllUsers}
                        overlayVisible={props.overlayVisible}
                        setOverlayVisible={props.setOverlayVisible}
                    />

                    <GradersList
                        study={study}
                        setStudy={setStudy}
                        gradersToCSV={gradersToCSV}
                        gradersHeadersCSV={gradersHeadersCSV}
                        emptyNewStudyUser={emptyNewStudyUser}
                        setCurrentGrader={setCurrentGrader}
                        setCurrentStudy={setCurrentStudy}
                        setAddGraderPopupVisible={setAddGraderPopupVisible}
                        setDeleteGraderPopupVisible={setDeleteGraderPopupVisible}
                        setGraderExamsPopupVisible={setGraderExamsPopupVisible}
                        setChangeUserViewPermsPopupVisible={setChangeUserViewPermsPopupVisible}
                        setActiveUserViewPerms={setActiveUserViewPerms}
                        setUserViewPerm={setUserViewPerm}
                        hasGraders={hasGraders}
                        setHasGraders={setHasGraders}
                        allUsers={allUsers}
                        setAllUsers={setAllUsers}
                        overlayVisible={props.overlayVisible}
                        setOverlayVisible={props.setOverlayVisible}
                    />

                    <TechniciansList
                        techniciansToCSV={techniciansToCSV}
                        techniciansHeadersCSV={techniciansHeadersCSV}
                        study={study}
                        setStudy={setStudy}
                        emptyNewStudyUser={emptyNewStudyUser}
                        setCurrentTechnician={setCurrentTechnician}
                        setCurrentStudy={setCurrentStudy}
                        setAddTechnicianPopupVisible={setAddTechnicianPopupVisible}
                        setDeleteTechnicianPopupVisible={setDeleteTechnicianPopupVisible}
                        setTechnicianExamsPopupVisible={setTechnicianExamsPopupVisible}
                        setChangeUserViewPermsPopupVisible={setChangeUserViewPermsPopupVisible}
                        setActiveUserViewPerms={setActiveUserViewPerms}
                        setUserViewPerm={setUserViewPerm}
                        hasTechnicians={hasTechnicians}
                        setHasTechnicians={setHasTechnicians}
                        allUsers={allUsers}
                        setAllUsers={setAllUsers}
                        overlayVisible={props.overlayVisible}
                        setOverlayVisible={props.setOverlayVisible}
                    />
                </div>

                {addEditCenterPopupVisible && (
                <AddEditCenterPopup
                    study={study}
                    setStudy={setStudy}
                    emptyStudyCenter={emptyStudyCenter}
                    allCenters={allCenters}
                    currentCenter={currentCenter}
                    setCurrentCenter={setCurrentCenter}
                    setCurrentNewCenter={setCurrentNewCenter}
                    setAddEditCenterPopupVisible={setAddEditCenterPopupVisible}
                    setAddNewCenterPopupVisible={setAddNewCenterPopupVisible}
                    addEditCenterErrors={addEditCenterErrors}
                    setAddEditCenterErrors={setAddEditCenterErrors}
                    userAuth={props.userAuth}
                    overlayVisible={props.overlayVisible}
                    setOverlayVisible={props.setOverlayVisible}

                />
                )}
                {addCoordinatorPopupVisible && (
                <AddCoordinatorPopup
                    study={study}
                    setStudy={setStudy}
                    allUsers={allUsers}
                    setAllUsers={setAllUsers}
                    allCenters={allCenters}
                    centerCoords={centerCoords}
                    setCenterCoords={setCenterCoords}
                    currentCoordinator={currentCoordinator}
                    emptyNewStudyUser={emptyNewStudyUser}
                    setCurrentCoordinator={setCurrentCoordinator}
                    setCurrentNewCoordinator={setCurrentNewCoordinator}
                    activeCoordCenter={activeCoordCenter}
                    setActiveCoordCenter={setActiveCoordCenter}
                    setAddCoordinatorPopupVisible={setAddCoordinatorPopupVisible}
                    setAddNewCoordinatorPopupVisible={setAddNewCoordinatorPopupVisible}
                    userAuth={props.userAuth}
                    overlayVisible={props.overlayVisible}
                    setOverlayVisible={props.setOverlayVisible}
                />
                )}
                {addMonitorPopupVisible && (
                <AddMonitorPopup
                    allUsers={allUsers}
                    study={study}
                    setStudy={setStudy}
                    currentMonitor={currentMonitor}
                    emptyNewStudyUser={emptyNewStudyUser}
                    setCurrentMonitor={setCurrentMonitor}
                    setCurrentNewMonitor={setCurrentNewMonitor}
                    setAddMonitorPopupVisible={setAddMonitorPopupVisible}
                    setAddNewMonitorPopupVisible={setAddNewMonitorPopupVisible}
                    userAuth={props.userAuth}
                    overlayVisible={props.overlayVisible}
                    setOverlayVisible={props.setOverlayVisible}
                />
                )}
                {addGraderPopupVisible && (
                <AddGraderPopup
                    allUsers={allUsers}
                    study={study}
                    setStudy={setStudy}
                    currentGrader={currentGrader}
                    emptyNewStudyUser={emptyNewStudyUser}
                    setCurrentGrader={setCurrentGrader}
                    setCurrentNewGrader={setCurrentNewGrader}
                    setAddGraderPopupVisible={setAddGraderPopupVisible}
                    setAddNewGraderPopupVisible={setAddNewGraderPopupVisible}
                    userAuth={props.userAuth}
                    overlayVisible={props.overlayVisible}
                    setOverlayVisible={props.setOverlayVisible}
                />
                )}
                {addTechnicianPopupVisible && (
                <AddTechnicianPopup
                    study={study}
                    setStudy={setStudy}
                    allUsers={allUsers}
                    setAllUsers={setAllUsers}
                    allCenters={allCenters}
                    centerTechs={centerTechs}
                    setCenterTechs={setCenterTechs}
                    currentTechnician={currentTechnician}
                    emptyNewStudyUser={emptyNewStudyUser}
                    setCurrentTechnician={setCurrentTechnician}
                    setCurrentNewTechnician={setCurrentNewTechnician}
                    activeTechCenter={activeTechCenter}
                    setActiveTechCenter={setActiveTechCenter}
                    setAddTechnicianPopupVisible={setAddTechnicianPopupVisible}
                    setAddNewTechnicianPopupVisible={setAddNewTechnicianPopupVisible}
                    userAuth={props.userAuth}
                    overlayVisible={props.overlayVisible}
                    setOverlayVisible={props.setOverlayVisible}
                />
                )}
                {graderExamsPopupVisible && (
                <GraderExamsPopup
                    study={study}
                    currentStudy={currentStudy}
                    setCurrentStudy={setCurrentStudy}
                    setStudy={setStudy}
                    currentGrader={currentGrader}
                    setCurrentGrader={setCurrentGrader}
                    setGraderExamsPopupVisible={setGraderExamsPopupVisible}
                    userAuth={props.userAuth}
                    overlayVisible={props.overlayVisible}
                    setOverlayVisible={props.setOverlayVisible}
                />
                )}
                {technicianExamsPopupVisible && (
                <TechnicianExamsPopup
                    study={study}
                    currentStudy={currentStudy}
                    setCurrentStudy={setCurrentStudy}
                    setStudy={setStudy}
                    currentTechnician={currentTechnician}
                    setCurrentTechnician={setCurrentTechnician}
                    setTechnicianExamsPopupVisible={setTechnicianExamsPopupVisible}
                    userAuth={props.userAuth}
                    overlayVisible={props.overlayVisible}
                    setOverlayVisible={props.setOverlayVisible}
                />
                )}
                {deleteCenterPopupVisible && (
                <DeleteCenterPopup
                    study={study}
                    setStudy={setStudy}
                    currentCenter={currentCenter}
                    setCurrentCenter={setCurrentCenter}
                    setDeleteCenterPopupVisible={setDeleteCenterPopupVisible}
                    userAuth={props.userAuth}
                    overlayVisible={props.overlayVisible}
                    setOverlayVisible={props.setOverlayVisible}
                />
                )}

                {deleteCoordinatorPopupVisible && (
                <DeleteCoordinatorPopup
                    study={study}
                    setStudy={setStudy}
                    currentCoordinator={currentCoordinator}
                    setCurrentCoordinator={setCurrentCoordinator}
                    setDeleteCoordinatorPopupVisible={setDeleteCoordinatorPopupVisible}
                    userAuth={props.userAuth}
                    overlayVisible={props.overlayVisible}
                    setOverlayVisible={props.setOverlayVisible}
                />
                )}

                {deleteMonitorPopupVisible && (
                <DeleteMonitorPopup
                    study={study}
                    setStudy={setStudy}
                    currentMonitor={currentMonitor}
                    setCurrentMonitor={setCurrentMonitor}
                    setDeleteMonitorPopupVisible={setDeleteMonitorPopupVisible}
                    userAuth={props.userAuth}
                    overlayVisible={props.overlayVisible}
                    setOverlayVisible={props.setOverlayVisible}
                />
                )}
                {deleteGraderPopupVisible && (
                <DeleteGraderPopup
                    study={study}
                    setStudy={setStudy}
                    currentGrader={currentGrader}
                    setCurrentGrader={setCurrentGrader}
                    setDeleteGraderPopupVisible={setDeleteGraderPopupVisible}
                    userAuth={props.userAuth}
                    overlayVisible={props.overlayVisible}
                    setOverlayVisible={props.setOverlayVisible}
                />
                )}
                {deleteTechnicianPopupVisible && (
                <DeleteTechnicianPopup
                    study={study}
                    setStudy={setStudy}
                    currentTechnician={currentTechnician}
                    setCurrentTechnician={setCurrentTechnician}
                    setDeleteTechnicianPopupVisible={setDeleteTechnicianPopupVisible}
                    userAuth={props.userAuth}
                />
                )}
                {addNewCenterPopupVisible && (
                <AddNewCenterPopup
                    allCenters={allCenters}
                    setAllCenters={setAllCenters}
                    currentCenter={currentCenter}
                    emptyStudyCenter={emptyStudyCenter}
                    setCurrentCenter={setCurrentCenter}
                    currentNewCenter={currentNewCenter}
                    setCurrentNewCenter={setCurrentNewCenter}
                    setAddNewCenterPopupVisible={setAddNewCenterPopupVisible}
                    setAddEditCenterPopupVisible={setAddEditCenterPopupVisible}
                    newCenterErrors={newCenterErrors}
                    setNewCenterErrors={setNewCenterErrors}
                    userAuth={props.userAuth}
                    overlayVisible={props.overlayVisible}
                    setOverlayVisible={props.setOverlayVisible}
                />
                )}
                {addNewCoordinatorPopupVisible && (
                <AddNewCoordinatorPopup
                    study={study}
                    setStudy={setStudy}
                    allUsers={allUsers}
                    setAllUsers={setAllUsers}
                    setCenterCoords={setCenterCoords}
                    emptyNewStudyUser={emptyNewStudyUser}
                    currentNewCoordinator={currentNewCoordinator}
                    setCurrentCoordinator={setCurrentCoordinator}
                    setCurrentNewCoordinator={setCurrentNewCoordinator}
                    setAddCoordinatorPopupVisible={setAddCoordinatorPopupVisible}
                    setAddNewCoordinatorPopupVisible={setAddNewCoordinatorPopupVisible}
                    setAddNewCoordinatorErrors={setAddNewCoordinatorErrors}
                    addNewCoordinatorErrors={addNewCoordinatorErrors}
                    userAuth={props.userAuth}
                    overlayVisible={props.overlayVisible}
                    setOverlayVisible={props.setOverlayVisible}
                />
                )}
                {addNewMonitorPopupVisible&& (
                <AddNewMonitorPopup
                    study={study}
                    setStudy={setStudy}
                    allUsers={allUsers}
                    setAllUsers={setAllUsers}
                    emptyNewStudyUser={emptyNewStudyUser}
                    currentNewMonitor={currentNewMonitor}
                    setCurrentMonitor={setCurrentMonitor}
                    setCurrentNewMonitor={setCurrentNewMonitor}
                    setAddMonitorPopupVisible={setAddMonitorPopupVisible}
                    setAddNewMonitorPopupVisible={setAddNewMonitorPopupVisible}
                    setAddNewMonitorErrors={setAddNewMonitorErrors}
                    addNewMonitorErrors={addNewMonitorErrors}
                    userAuth={props.userAuth}
                    overlayVisible={props.overlayVisible}
                    setOverlayVisible={props.setOverlayVisible}
                />
                )}
                {addNewGraderPopupVisible && (
                <AddNewGraderPopup
                    study={study}
                    setStudy={setStudy}
                    allUsers={allUsers}
                    setAllUsers={setAllUsers}
                    emptyNewStudyUser={emptyNewStudyUser}
                    currentNewGrader={currentNewGrader}
                    setCurrentGrader={setCurrentGrader}
                    setCurrentNewGrader={setCurrentNewGrader}
                    setAddGraderPopupVisible={setAddGraderPopupVisible}
                    setAddNewGraderPopupVisible={setAddNewGraderPopupVisible}
                    setAddNewGraderErrors={setAddNewGraderErrors}
                    addNewGraderErrors={addNewGraderErrors}
                    userAuth={props.userAuth}
                    overlayVisible={props.overlayVisible}
                    setOverlayVisible={props.setOverlayVisible}
                />
                )}
                {addNewTechnicianPopupVisible && (
                <AddNewTechnicianPopup
                    study={study}
                    setStudy={setStudy}
                    allUsers={allUsers}
                    setAllUsers={setAllUsers}
                    setCenterTechs={setCenterTechs}
                    emptyNewStudyUser={emptyNewStudyUser}
                    currentNewTechnician={currentNewTechnician}
                    setCurrentTechnician={setCurrentTechnician}
                    setCurrentNewTechnician={setCurrentNewTechnician}
                    setAddTechnicianPopupVisible={setAddTechnicianPopupVisible}
                    setAddNewTechnicianPopupVisible={setAddNewTechnicianPopupVisible}
                    setAddNewTechnicianErrors={setAddNewTechnicianErrors}
                    addNewTechnicianErrors={addNewTechnicianErrors}
                    userAuth={props.userAuth}
                    overlayVisible={props.overlayVisible}
                    setOverlayVisible={props.setOverlayVisible}
                />
                )}

                {changeUserViewPermsPopupVisible && (
                <ChangeUserViewPermsPopup
                    setChangeUserViewPermsPopupVisible={setChangeUserViewPermsPopupVisible}
                    activeUserViewPerms={activeUserViewPerms}
                    setActiveUserViewPerms={setActiveUserViewPerms}
                    userViewPerm={userViewPerm}
                    setUserViewPerm={setUserViewPerm}
                    study={study}
                    setStudy={setStudy}
                    overlayVisible={props.overlayVisible}
                    setOverlayVisible={props.setOverlayVisible}
                />
                )}
            </div> {/* .content */}
        </div>

    );
}

export default StudyUsers;

function CentersList(props) {
    const showAddCenterPopup = () => {
        props.setCurrentCenter(JSON.parse(JSON.stringify(props.emptyStudyCenter)));
        props.setAddEditCenterPopupVisible(true);
    };

    const toggleCenterNameSort = () => {

        const centers = JSON.parse(JSON.stringify(props.study.centers));
        let sort = localStorage.getItem('corc_study_users_centers_sortName');

        if (sort === ">") {
            centers.sort((a, b) => (a.study_site_num.toLowerCase() < b.study_site_num.toLowerCase()) ? 1 : -1)
            localStorage.setItem('corc_study_users_centers_sortName', "<")
        }
        else {
            centers.sort((a, b) => (a.study_site_num.toLowerCase() > b.study_site_num.toLowerCase()) ? 1 : -1)
            localStorage.setItem('corc_study_users_centers_sortName', ">")
        }

        props.setStudy({...props.study, centers: centers});
    }

    const exportPDF = () => {

        const marginLeft = 40;
        const title = "";
        const doc = new jsPDF("portrait", "pt", "A4");

        doc.setFontSize(15);

        const headers = [["Clinical Site", "Technicians", "Patients"]];

        const data = props.centersToCSV.map(i=> [i.study_site_num, i.technicians, i.patients]);

        let content = {
          startY: 50,
          head: headers,
          body: data
        };

        doc.text(title, marginLeft, 40);
        doc.autoTable(content);
        doc.save("study-centers.pdf")
  }

    return (
        <React.Fragment>

            <div className="breadcrumbs">
                <div className="path">
                    <ul>
                        <li>
                            <span className="text-shadow orange">{props.study.acronym && props.study.acronym.toUpperCase()}</span>
                        </li>
                        <li className="separator text-shadow">&gt;</li>
                        <li>Clinical Sites</li>
                    </ul>
                </div> {/* .path */}

                <div className="sub-menu">

                    {props.study.status === "open" && (
                    <button
                        className="btn normal grey align-center right"
                        onClick={showAddCenterPopup}
                    >Add clinical site
                    </button>
                    )}

                    <button
                        onClick={() => exportPDF()}
                        className="btn normal grey right"
                    >
                        <span className="icon icon-list report"></span>Export to PDF
                    </button>

                    <CSVLink className="btn normal grey right" data={props.centersToCSV} headers={props.centersHeadersCSV} filename={"study-centers.csv"}>
                        <span className="icon icon-list report"></span>
                        Export to CSV
                    </CSVLink>

                </div>
            </div> {/* .breadcrumbs */}

        <div className="overview">
        </div>

        <div className="list visits-list">
            <div className="list-head">
                <table>
                    <tbody>
                        <tr>
                            <th width="25%">
                                <button onClick={toggleCenterNameSort}>Clinical Site</button>
                            </th>
                            <th width="25%">
                                Technicians
                            </th>
                            <th>
                                Patients
                            </th>
                        </tr>
                    </tbody>
                </table>
            </div> {/* .list-head */}

            <div className="list-body">
                <table id="centers-table" className="users-table">
                    <tbody className="list-vists">
                        {props.study.centers.map((center, i) => (
                        <CentersListItem
                            key={i}
                            center={center}
                            study={props.study}
                            setCurrentCenter={props.setCurrentCenter}
                            setAddEditCenterPopupVisible={props.setAddEditCenterPopupVisible}
                            setDeleteCenterPopupVisible={props.setDeleteCenterPopupVisible}
                            setChangeUserViewPermsPopupVisible={props.setChangeUserViewPermsPopupVisible}
                            setActiveUserViewPerms={props.setActiveUserViewPerms}
                            setUserViewPerm={props.setUserViewPerm}
                        />
                        ))}
                    </tbody>
                </table>
            </div> {/* .list-body */}
        </div>
        </React.Fragment>
    );
}

function CentersListItem(props) {
    const showEditCenterPopup = (center) => {

        let currCenter = JSON.parse(JSON.stringify(center));
        let tmp = currCenter.other_emails.split(',').join('\n');

        currCenter.other_emails = tmp;
        props.setCurrentCenter(currCenter);
        props.setAddEditCenterPopupVisible(true);
    };

    return (
        <tr>
            <td width="25%">{props.center.study_site_num}</td>
            <td width="25%">{props.center.total_technicians}</td>
            <td>{props.center.total_patients}</td>

            {props.study.status === "open" && (
            <td className="btn-list" style={{position: "relative"}} width="10%">
                <ul style={{top: "0", float: "right"}}>

                    <li>
                        <button
                            className="btn xsmall grey edit-user"
                            onClick={() => showEditCenterPopup(props.center)}
                        >
                        <span className="icon-list study-details-small" style={{marginLeft: "0"}}></span></button>
                    </li>
                </ul>
            </td>
            )}
        </tr>
    );
}

function MonitorsList(props) {
    const showAddMonitorPopup = async () => {
        if(!props.hasMonitors) {
            let count = props.overlayVisible;
            props.setOverlayVisible( (count) => count + 1);

            const items = await axios.get(process.env.REACT_APP_API_ENDPOINT+'/users/monitors/',
                { withCredentials: true, headers: {'Content-Type': 'application/json'}});

            let editedUsers = JSON.parse(JSON.stringify(props.allUsers));
            editedUsers = editedUsers.concat(items.data);
            props.setAllUsers(editedUsers);
            props.setHasMonitors(true);

            props.setOverlayVisible( (count) => count - 1);
        }
        props.setCurrentMonitor({id: ""});
        props.setAddMonitorPopupVisible(true);
    };


    const toggleMonitorNameSort = () => {

        const monitors = JSON.parse(JSON.stringify(props.study.monitors));
        let sort = localStorage.getItem('corc_study_users_monitors_sortName');

        if (sort === ">") {
            monitors.sort((a, b) => (a.user.name.toLowerCase() < b.user.name.toLowerCase()) ? 1 : -1)
            localStorage.setItem('corc_study_users_monitors_sortName', "<")
        }
        else {
            monitors.sort((a, b) => (a.user.name.toLowerCase() > b.user.name.toLowerCase()) ? 1 : -1)
            localStorage.setItem('corc_study_users_monitors_sortName', ">")
        }

        props.setStudy({...props.study, monitors: monitors});
    }

    const exportPDF = () => {

        const marginLeft = 40;
        const title = "";
        const doc = new jsPDF("portrait", "pt", "A4");

        doc.setFontSize(15);

        const headers = [["Monitor", "Registered"]];

        const data = props.monitorsToCSV.map(i=> [i.user.username, i.registered]);

        let content = {
          startY: 50,
          head: headers,
          body: data
        };

        doc.text(title, marginLeft, 40);
        doc.autoTable(content);
        doc.save("study-monitors.pdf")
  }

    return (
        <React.Fragment>

            <div className="breadcrumbs">
                <div className="path">
                    <ul>
                        <li>
                            <span className="text-shadow orange">{props.study.acronym && props.study.acronym.toUpperCase()}</span>
                        </li>
                        <li className="separator text-shadow">&gt;</li>
                        <li>Monitors</li>
                    </ul>
                </div> {/* .path */}

                <div className="sub-menu">

                    {props.study.status === "open" && (
                    <button
                        className="btn normal grey align-center right"
                        onClick={showAddMonitorPopup}
                    >Add Monitor
                    </button>
                    )}

                    <button onClick={exportPDF}
                        className="btn normal grey right"
                    >
                        <span className="icon icon-list report"></span>Export to PDF
                    </button>


                    <CSVLink className="btn normal grey right" data={props.monitorsToCSV} headers={props.monitorsHeadersCSV} filename={"study-monitors.csv"}>
                        <span className="icon icon-list report"></span>
                        Export to CSV
                    </CSVLink>

                </div>
            </div> {/* .breadcrumbs */}


        <div className="overview">
        </div>

        <div className="list visits-list">
            <div className="list-head">
                <table>
                    <tbody>
                        <tr>
                            <th>
                                <button onClick={toggleMonitorNameSort}>Monitor</button>
                            </th>
                        </tr>
                    </tbody>
                </table>
            </div> {/* .list-head */}

            <div className="list-body">
                <table id="monitors-table" className="users-table">
                    <tbody className="list-vists">
                        {props.study.monitors.map((monitor, i) => (
                        <MonitorsListItem
                            key={i}
                            monitor={monitor}
                            study={props.study}
                            setCurrentMonitor={props.setCurrentMonitor}
                            setDeleteMonitorPopupVisible={props.setDeleteMonitorPopupVisible}
                            setChangeUserViewPermsPopupVisible={props.setChangeUserViewPermsPopupVisible}
                            setActiveUserViewPerms={props.setActiveUserViewPerms}
                            setUserViewPerm={props.setUserViewPerm}
                        />
                        ))}
                    </tbody>
                </table>
            </div> {/* .list-body */}
        </div>
        </React.Fragment>
    );
}

function MonitorsListItem(props) {
    const showMonitorViewPermsPopup = viewPerm => {
        props.setActiveUserViewPerms(props.monitor)
        props.setUserViewPerm(viewPerm)
        props.setChangeUserViewPermsPopupVisible(true)
    }

    return (
        <tr>
            <td>

                <Link to={"/users/edit/" + props.monitor.user.id} target="_blank" className="">
                    {props.monitor.user.username}
                </Link>

            </td>
            <td className="btn-list" style={{position: "relative"}}>
                <ul style={{top: "0", float: "right"}}>
                    {props.study.status === 'closed' && !props.monitor.has_view_permission && (
                    <li>
                        <button onClick={() => showMonitorViewPermsPopup(true)} className="btn xsmall grey">
                            <span className="study-visit-icon add-small" style={{marginLeft:"0"}}></span>
                        </button>
                   </li>
                    )}
                    {props.study.status === 'closed' && props.monitor.has_view_permission && (
                    <li>
                        <button onClick={() => showMonitorViewPermsPopup(false)} className="btn xsmall grey">
                            <span className="study-visit-icon clear-small"></span>
                        </button>
                   </li>
                    )}
                </ul>
            </td>
        </tr>
    );
}

function GradersList(props) {
    const showAddGraderPopup = async () => {
        if(!props.hasGraders) {
            let count = props.overlayVisible;
            props.setOverlayVisible( (count) => count + 1);

            const items = await axios.get(process.env.REACT_APP_API_ENDPOINT+'/users/graders/',
                { withCredentials: true, headers: {'Content-Type': 'application/json'}});

            let editedUsers = JSON.parse(JSON.stringify(props.allUsers));
            editedUsers = editedUsers.concat(items.data);
            props.setAllUsers(editedUsers);
            props.setHasGraders(true);

            props.setOverlayVisible( (count) => count - 1);
        }

        props.setCurrentGrader({id: "", exams: []});
        props.setAddGraderPopupVisible(true);
    };

    const toggleGraderNameSort = () => {

        const graders = JSON.parse(JSON.stringify(props.study.graders));
        let sort = localStorage.getItem('corc_study_users_graders_sortName');

        if (sort === ">") {
            graders.sort((a, b) => (a.user.username.toLowerCase() < b.user.username.toLowerCase()) ? 1 : -1)
            localStorage.setItem('corc_study_users_graders_sortName', "<")
        }
        else {
            graders.sort((a, b) => (a.user.username.toLowerCase() > b.user.username.toLowerCase()) ? 1 : -1)
            localStorage.setItem('corc_study_users_graders_sortName', ">")
        }
        props.setStudy({...props.study, graders: graders});
    }

    const exportPDF = () => {

        const marginLeft = 40;
        const title = "";
        const doc = new jsPDF("portrait", "pt", "A4");

        doc.setFontSize(15);

        const headers = [["Grader", "Pending Exams", "Graded Exams", "Rejected Exams", "Registered"]];

        const data = props.gradersToCSV.map(i=> [i.user.username, i.pending_exams, i.graded_exams, i.rejected_exams, i.registered]);

        let content = {
          startY: 50,
          head: headers,
          body: data
        };

        doc.text(title, marginLeft, 40);
        doc.autoTable(content);
        doc.save("study-graders.pdf")
  }
    return (
        <React.Fragment>

            <div className="breadcrumbs">
                <div className="path">
                    <ul>
                        <li>
                            <span className="text-shadow orange">{props.study.acronym && props.study.acronym.toUpperCase()}</span>
                        </li>
                        <li className="separator text-shadow">&gt;</li>
                        <li>Graders</li>
                    </ul>
                </div> {/* .path */}

                <div className="sub-menu">

                    {props.study.status === "open" && (
                    <button
                        className="btn normal grey align-center right"
                        onClick={showAddGraderPopup}
                    >Add Grader
                    </button>
                    )}
                    <button onClick={exportPDF}
                        className="btn normal grey right"
                    >
                        <span className="icon icon-list report"></span>Export to PDF
                    </button>


                    <CSVLink className="btn normal grey right" data={props.gradersToCSV} headers={props.gradersHeadersCSV} filename={"study-graders.csv"}>
                        <span className="icon icon-list report"></span>
                        Export to CSV
                    </CSVLink>

                </div>
            </div> {/* .breadcrumbs */}

        <div className="overview">
        </div>

        <div className="list visits-list">
            <div className="list-head">
                <table>
                    <tbody>
                        <tr>
                            <th width="15%">
                                <button onClick={toggleGraderNameSort}>Grader</button>
                            </th>
                            <th width="15%">
                                Pending Exams
                            </th>
                            <th width="15%">Graded Exams</th>
                            <th>Rejected Exams</th>
                        </tr>
                    </tbody>
                </table>
            </div> {/* .list-head */}

            <div className="list-body">
                <table id="graders-table" className="users-table">
                    <tbody className="list-vists">
                        {props.study.graders.map((grader, i) => (
                        <GradersListItem
                            key={i}
                            grader={grader}
                            study={props.study}
                            setCurrentGrader={props.setCurrentGrader}
                            setCurrentStudy={props.setCurrentStudy}
                            setDeleteGraderPopupVisible={props.setDeleteGraderPopupVisible}
                            setGraderExamsPopupVisible={props.setGraderExamsPopupVisible}
                            setChangeUserViewPermsPopupVisible={props.setChangeUserViewPermsPopupVisible}
                            setActiveUserViewPerms={props.setActiveUserViewPerms}
                            setUserViewPerm={props.setUserViewPerm}
                        />
                        ))}
                    </tbody>
                </table>
            </div> {/* .list-body */}
        </div>
        </React.Fragment>
    );
}

function GradersListItem(props) {
    const showGraderExamsPopup = (grader) => {
        props.setCurrentGrader(grader);

        let currStudy = JSON.parse(JSON.stringify(props.study));
        props.setCurrentStudy(currStudy);
        props.setGraderExamsPopupVisible(true);
    };

    const showGraderViewPermsPopup = viewPerm => {
        props.setActiveUserViewPerms(props.grader)
        props.setUserViewPerm(viewPerm)
        props.setChangeUserViewPermsPopupVisible(true)
    };


    return (
        <tr>
            <td width="15%">

                <Link to={"/users/edit/" + props.grader.user.id} target="_blank" className="">
                    {props.grader.user.username}
                </Link>

            </td>


            <td width="15%">{props.grader.pending_exams}</td>
            <td width="15%">{props.grader.graded_exams}</td>
            <td width="15%">{props.grader.rejected_exams}</td>
            <td className="btn-list" style={{position: "relative"}}>
                <ul style={{top: "0", float: "right"}}>
                    {props.study.status === 'closed' && !props.grader.has_view_permission && (
                    <li>
                        <button onClick={() => showGraderViewPermsPopup(true)} className="btn xsmall grey">
                            <span className="study-visit-icon add-small" style={{marginLeft:"0"}}></span>
                        </button>
                   </li>
                    )}
                    {props.study.status === 'closed' && props.grader.has_view_permission && (
                    <li>
                        <button onClick={() => showGraderViewPermsPopup(false)} className="btn xsmall grey">
                            <span className="study-visit-icon clear-small"></span>
                        </button>
                   </li>
                    )}
                    <li>
                        <button onClick={() => showGraderExamsPopup(props.grader)} className="btn xsmall grey">
                            <span className="icon-list user-small" style={{marginLeft:"0"}}></span>
                        </button>
                   </li>
                </ul>
            </td>
        </tr>
    );
}

function CoordinatorsList(props) {
    const showAddCoordinatorPopup = async () => {
        if(!props.hasCoordinators) {
            let count = props.overlayVisible;
            props.setOverlayVisible( (count) => count + 1);

            const items = await axios.get(process.env.REACT_APP_API_ENDPOINT+'/users/coordinators/',
                { withCredentials: true, headers: {'Content-Type': 'application/json'}});

            let editedUsers = JSON.parse(JSON.stringify(props.allUsers));
            editedUsers = editedUsers.concat(items.data);
            props.setAllUsers(editedUsers);
            props.setHasCoordinators(true);

            props.setOverlayVisible( (count) => count - 1);
        }
        props.setCurrentCoordinator({id: "", center: ""});
        props.setAddCoordinatorPopupVisible(true);
    };

    const toggleCoordinatorNameSort = () => {

        const coordinators = JSON.parse(JSON.stringify(props.study.coordinators));
        let sort = localStorage.getItem('corc_study_users_coordinators_sortName');

        if (sort === ">") {
            coordinators.sort((a, b) => (a.user.username.toLowerCase() < b.user.username.toLowerCase()) ? 1 : -1)
            localStorage.setItem('corc_study_users_coordinators_sortName', "<")
        }
        else {
            coordinators.sort((a, b) => (a.user.username.toLowerCase() > b.user.username.toLowerCase()) ? 1 : -1)
            localStorage.setItem('corc_study_users_coordinators_sortName', ">")
        }
        props.setStudy({...props.study, coordinators: coordinators});
    }

    const toggleCoordinatorCenterSort = () => {

        const coordinators = JSON.parse(JSON.stringify(props.study.coordinators));
        let sort = localStorage.getItem('corc_study_users_coordinators_sortCenter');

        if (sort === ">") {

            coordinators.sort(function(a,b) {

                let aName = '', bName = '';
                if (a.user.center) {
                    aName = a.user.center.acronym.toLowerCase();
                }
                if (b.user.center) {
                    bName = b.user.center.acronym.toLowerCase();
                }

                return (aName < bName) ? 1 : -1
            })

            localStorage.setItem('corc_study_users_coordinators_sortCenter', "<")
        }
        else {

            coordinators.sort(function(a,b) {

                let aName = '', bName = '';
                if (a.user.center) {
                    aName = a.user.center.acronym.toLowerCase();
                }
                if (b.user.center) {
                    bName = b.user.center.acronym.toLowerCase();
                }

                return (aName > bName) ? 1 : -1
            })

            localStorage.setItem('corc_study_users_coordinators_sortCenter', ">")
        }
        props.setStudy({...props.study, coordinators: coordinators});
    }


    const exportPDF = () => {

        const marginLeft = 40;
        const title = "";
        const doc = new jsPDF("portrait", "pt", "A4");

        doc.setFontSize(15);

        const headers = [["Coordinator", "Clinical Site"]];

        const data = props.coordinatorsToCSV.map(i=> [i.user.username, i.user.center.acronym]);

        let content = {
          startY: 50,
          head: headers,
          body: data
        };

        doc.text(title, marginLeft, 40);
        doc.autoTable(content);
        doc.save("study-coordinators.pdf")
  }

    return (
        <React.Fragment>

            <div className="breadcrumbs">
                <div className="path">
                    <ul>
                        <li>
                            <span className="text-shadow orange">{props.study.acronym && props.study.acronym.toUpperCase()}</span>
                        </li>
                        <li className="separator text-shadow">&gt;</li>
                        <li>Coordinators</li>
                    </ul>
                </div> {/* .path */}

                <div className="sub-menu">

                    {props.study.status === "open" && (
                    <button
                        className="btn normal grey align-center right"
                        onClick={showAddCoordinatorPopup}
                    >Add Coordinator
                    </button>
                    )}

                    <button
                        onClick={exportPDF}
                        className="btn normal grey right"
                    >
                        <span className="icon icon-list report"></span>Export to PDF
                    </button>

                    <CSVLink className="btn normal grey right" data={props.coordinatorsToCSV} headers={props.coordinatorsHeadersCSV} filename={"study-coordinators.csv"}>
                        <span className="icon icon-list report"></span>
                        Export to CSV
                    </CSVLink>

                </div>
            </div> {/* .breadcrumbs */}



        <div className="overview">
        </div>

        <div className="list visits-list">
            <div className="list-head">
                <table>
                    <tbody>
                        <tr>
                            <th width="25%">
                                <button onClick={toggleCoordinatorNameSort}>Coordinator</button>
                            </th>
                            <th>
                                <button onClick={toggleCoordinatorCenterSort}>Clinical Site</button>
                            </th>
                        </tr>
                    </tbody>
                </table>
            </div> {/* .list-head */}

            <div className="list-body">
                <table id="coordinators-table" className="users-table">
                    <tbody className="list-vists">
                        {props.study.coordinators.map((coordinator, i) => (
                        <CoordinatorsListItem
                            key={i}
                            coordinator={coordinator}
                            study={props.study}
                            setCurrentCoordinator={props.setCurrentCoordinator}
                            setCurrentStudy={props.setCurrentStudy}
                            setDeleteCoordinatorPopupVisible={props.setDeleteCoordinatorPopupVisible}
                            setChangeUserViewPermsPopupVisible={props.setChangeUserViewPermsPopupVisible}
                            setActiveUserViewPerms={props.setActiveUserViewPerms}
                            setUserViewPerm={props.setUserViewPerm}
                        />
                        ))}
                    </tbody>
                </table>
            </div> {/* .list-body */}
        </div>
        </React.Fragment>
    );
}

function CoordinatorsListItem(props) {

    const showCoordViewPermsPopup = viewPerm => {
        props.setActiveUserViewPerms(props.coordinator)
        props.setUserViewPerm(viewPerm)
        props.setChangeUserViewPermsPopupVisible(true)
    };

    return (
        <tr>
            <td width="25%">

                <Link to={"/users/edit/" + props.coordinator.user.id} target="_blank" className="">
                    {props.coordinator.user.username}
                </Link>

            </td>
            <td>{props.coordinator.user.center && props.coordinator.user.center.acronym}</td>
            <td className="btn-list" width="5.5%" style={{position: "relative"}}>
                <ul style={{top: "0", float: "right"}}>
                    {props.study.status === 'closed' && !props.coordinator.has_view_permission && (
                    <li>
                        <button onClick={() => showCoordViewPermsPopup(true)} className="btn xsmall grey">
                            <span className="study-visit-icon add-small" style={{marginLeft:"0"}}></span>
                        </button>
                   </li>
                    )}
                    {props.study.status === 'closed' && props.coordinator.has_view_permission && (
                    <li>
                        <button onClick={() => showCoordViewPermsPopup(false)} className="btn xsmall grey">
                            <span className="study-visit-icon clear-small"></span>
                        </button>
                   </li>
                    )}
                </ul>
            </td>
        </tr>
    );
}

function TechniciansList(props) {
    const showAddTechnicianPopup = async () => {
        if(!props.hasTechnicians) {
            let count = props.overlayVisible;
            props.setOverlayVisible( (count) => count + 1);

            const items = await axios.get(process.env.REACT_APP_API_ENDPOINT+'/users/technicians/',
                { withCredentials: true, headers: {'Content-Type': 'application/json'}});

            let editedUsers = JSON.parse(JSON.stringify(props.allUsers));
            editedUsers = editedUsers.concat(items.data);
            props.setAllUsers(editedUsers);
            props.setHasTechnicians(true);

            props.setOverlayVisible( (count) => count - 1);
        }

        props.setCurrentTechnician({id: "", center: "", exams: []});
        props.setAddTechnicianPopupVisible(true);
    };

    const toggleTechnicianNameSort = () => {

        const technicians = JSON.parse(JSON.stringify(props.study.technicians));
        let sort = localStorage.getItem('corc_study_users_technicians_sortName');

        if (sort === ">") {
            technicians.sort((a, b) => (a.user.username.toLowerCase() < b.user.username.toLowerCase()) ? 1 : -1)
            localStorage.setItem('corc_study_users_technicians_sortName', "<")
        }
        else {
            technicians.sort((a, b) => (a.user.username.toLowerCase() > b.user.username.toLowerCase()) ? 1 : -1)
            localStorage.setItem('corc_study_users_technicians_sortName', ">")
        }
        props.setStudy({...props.study, technicians: technicians});
    }

    const toggleTechnicianCenterSort = () => {

        const technicians = JSON.parse(JSON.stringify(props.study.technicians));
        let sort = localStorage.getItem('corc_study_users_technicians_sortCenter');

        if (sort === ">") {

            technicians.sort(function(a,b) {

                let aName = '', bName = '';
                if (a.user.center) {
                    aName = a.user.center.acronym.toLowerCase();
                }
                if (b.user.center) {
                    bName = b.user.center.acronym.toLowerCase();
                }

                return (aName < bName) ? 1 : -1
            })

            localStorage.setItem('corc_study_users_technicians_sortCenter', "<")
        }
        else {

            technicians.sort(function(a,b) {

                let aName = '', bName = '';
                if (a.user.center) {
                    aName = a.user.center.acronym.toLowerCase();
                }
                if (b.user.center) {
                    bName = b.user.center.acronym.toLowerCase();
                }

                return (aName > bName) ? 1 : -1
            })

            localStorage.setItem('corc_study_users_technicians_sortCenter', ">")
        }
        props.setStudy({...props.study, technicians: technicians});
    }

    const exportPDF = () => {

        const marginLeft = 40;
        const title = "";
        const doc = new jsPDF("landscape", "pt", "A4");

        doc.setFontSize(15);

        const headers = [["Technician", "Clinical Site", "Submitted Exams", "Successfull submissions", "Rejected submissions", "Registered"]];

        const data = props.techniciansToCSV.map(i=> [i.user.username, i.user.center.acronym, i.submitted_exams, i.successfull_submissions, i.rejected_submissions, i.registered]);

        let content = {
          startY: 50,
          head: headers,
          body: data
        };

        doc.text(title, marginLeft, 40);
        doc.autoTable(content);
        doc.save("study-technicians.pdf")
  }

    return (
        <React.Fragment>

            <div className="breadcrumbs">
                <div className="path">
                    <ul>
                        <li>
                            <span className="text-shadow orange">{props.study.acronym && props.study.acronym.toUpperCase()}</span>
                        </li>
                        <li className="separator text-shadow">&gt;</li>
                        <li>Technicians</li>
                    </ul>
                </div> {/* .path */}

                <div className="sub-menu">

                    {props.study.status === "open" && (
                    <button
                        className="btn normal grey align-center right"
                        onClick={showAddTechnicianPopup}
                    >Add Technician
                    </button>
                    )}
                    <button
                        onClick={exportPDF}
                        className="btn normal grey right"
                    >
                        <span className="icon icon-list report"></span>Export to PDF
                    </button>

                    <CSVLink className="btn normal grey right" data={props.techniciansToCSV} headers={props.techniciansHeadersCSV} filename={"study-technicians.csv"}>
                        <span className="icon icon-list report"></span>
                        Export to CSV
                    </CSVLink>

                </div>
            </div> {/* .breadcrumbs */}

        <div className="overview">
        </div>

        <div className="list visits-list">
            <div className="list-head">
                <table>
                    <tbody>
                        <tr>
                            <th width="15%">
                                <button onClick={toggleTechnicianNameSort}>Technician</button>
                            </th>
                            <th width="20%">
                                <button onClick={toggleTechnicianCenterSort}>Clinical Site</button>
                            </th>
                            <th width="15%">
                                Submitted Exams
                            </th>
                            <th width="15%">
                                Successful Submissions
                            </th>
                            <th>
                                Rejected Submissions
                            </th>
                        </tr>
                    </tbody>
                </table>
            </div> {/* .list-head */}

            <div className="list-body">
                <table id="technicians-table" className="users-table">
                    <tbody className="list-vists">
                        {props.study.technicians.map((technician, i) => (
                        <TechniciansListItem
                            key={i}
                            technician={technician}
                            study={props.study}
                            setCurrentTechnician={props.setCurrentTechnician}
                            setCurrentStudy={props.setCurrentStudy}
                            setDeleteTechnicianPopupVisible={props.setDeleteTechnicianPopupVisible}
                            setTechnicianExamsPopupVisible={props.setTechnicianExamsPopupVisible}
                            setChangeUserViewPermsPopupVisible={props.setChangeUserViewPermsPopupVisible}
                            setActiveUserViewPerms={props.setActiveUserViewPerms}
                            setUserViewPerm={props.setUserViewPerm}
                        />
                        ))}
                    </tbody>
                </table>
            </div> {/* .list-body */}
        </div>
        </React.Fragment>
    );
}

function TechniciansListItem(props) {

    const showTechnicianExamsPopup = (tech) => {
        props.setCurrentTechnician(tech);

        let currStudy = JSON.parse(JSON.stringify(props.study));
        props.setCurrentStudy(currStudy);
        props.setTechnicianExamsPopupVisible(true);
    };

    const showTechViewPermsPopup = viewPerm => {
        props.setActiveUserViewPerms(props.technician)
        props.setUserViewPerm(viewPerm)
        props.setChangeUserViewPermsPopupVisible(true)
    };

    return (
        <tr>
            <td width="15%">

                <Link to={"/users/edit/" + props.technician.user.id} target="_blank" className="">
                    {props.technician.user.username}
                </Link>

            </td>
            <td width="20%">{props.technician.user.center && props.technician.user.center.acronym}</td>
            <td width="15%">{props.technician.submitted_exams}</td>
            <td width="15%">{props.technician.successful_exams}</td>
            <td width="15%">{props.technician.rejected_exams}</td>
            <td className="btn-list" style={{position: "relative"}}>
                <ul style={{top: "0", float: "right"}}>
                    {props.study.status === 'closed' && !props.technician.has_view_permission && (
                    <li>
                        <button onClick={() => showTechViewPermsPopup(true)} className="btn xsmall grey">
                            <span className="study-visit-icon add-small" style={{marginLeft:"0"}}></span>
                        </button>
                   </li>
                    )}
                    {props.study.status === 'closed' && props.technician.has_view_permission && (
                    <li>
                        <button onClick={() => showTechViewPermsPopup(false)} className="btn xsmall grey">
                            <span className="study-visit-icon clear-small"></span>
                        </button>
                   </li>
                    )}
                    <li>
                        <button onClick={() => showTechnicianExamsPopup(props.technician)} className="btn xsmall grey">
                            <span className="icon-list user-small"></span>
                        </button>
                   </li>
                </ul>
            </td>
        </tr>
    );
}

function AddEditCenterPopup(props) {
    const closePopup = () => {
        props.setAddEditCenterPopupVisible(false);
        props.setCurrentCenter({});
    };

    const confirmAddCenter = async () => {
        const editedStudy = JSON.parse(JSON.stringify(props.study));
        let currCenter = JSON.parse(JSON.stringify(props.currentCenter));
        const errMsg = "Sorry, something went wrong when adding this center. Please contact your IT helpdesk.";
        let errors = [];

        // trim all fields before validating
        currCenter.principal_investigator = currCenter.principal_investigator.trim();
        currCenter.pi_notification_email = currCenter.pi_notification_email.trim();
        currCenter.pi_contact_email = currCenter.pi_contact_email.trim();
        currCenter.study_site_num = currCenter.study_site_num.trim();
        currCenter.study_coordinator_name = currCenter.study_coordinator_name.trim();
        currCenter.study_coordinator_email = currCenter.study_coordinator_email.trim();
        currCenter.study_coordinator_phone = currCenter.study_coordinator_phone.trim();
        currCenter.study_coordinator_fax = currCenter.study_coordinator_fax.trim();

        currCenter.other_emails = currCenter.other_emails.split('\n').join(',');
        currCenter.other_emails = currCenter.other_emails.split(',').reduce(function(obj, item) {
            item = item.trim();
            if (item) {
                obj.push(item.trim());
            }
            return obj;
        }, []);

        if (currCenter.other_emails.length) {
            currCenter.other_emails = currCenter.other_emails.join();
        }
        else {
            currCenter.other_emails = "";
        }

        // do we have requiredfields?
        if (!currCenter.study_site_num) {
            errors.push('Study Site Number cannot be blank.');
        }
        if(currCenter.center.id <= 0) {
            errors.push('Clinical Site cannot be blank.');
        }
        if (errors.length) {
            props.setAddEditCenterErrors(errors);
            return;
        }
        else {
            props.setAddEditCenterErrors([]);
        }

        let count = props.overlayVisible;
        props.setOverlayVisible( (count) => count + 1);
        // are we editing or adding a new?
        if (currCenter.id > 0) {

            delete currCenter.center;

            await axios.put(process.env.REACT_APP_API_ENDPOINT+'/studies/'+props.study.id+'/update_center/', {...currCenter}, { withCredentials: true})
                .then((response) => {

                props.setOverlayVisible( (count) => count - 1);
                if (response.status === 200 && response.data && response.data.id) {

                    currCenter = response.data;
                    for(let i=0, len=editedStudy.centers.length; i<=len-1; ++i) {
                        if(editedStudy.centers[i].id === currCenter.id) {
                            editedStudy.centers.splice(i, 1, currCenter);
                        }
                    }

                    props.setStudy(editedStudy);
                    props.setAddEditCenterPopupVisible(false);

                }
                else {
                    alert(errMsg);
                }

            }, (error) => {
                alert(errMsg);
                props.setOverlayVisible( (count) => count - 1);
            });
        }
        else {

            await axios.post(process.env.REACT_APP_API_ENDPOINT+'/studies/'+props.study.id+'/add_center/', {...currCenter}, { withCredentials: true})
                .then((response) => {

                props.setOverlayVisible( (count) => count - 1);
                if (response.status === 201 && response.data && response.data.id) {

                    currCenter = response.data;
                    editedStudy.centers.push(currCenter);

                    props.setStudy(editedStudy);
                    props.setAddEditCenterPopupVisible(false);

                }
                else {
                    alert(errMsg);
                }

            }, (error) => {
                alert(errMsg);
                props.setOverlayVisible( (count) => count - 1);
            });
        }

    };

    const onChangeHandler = (ev) => {
        let val = ev.target.value;
        let name = ev.target.name;

        props.setCurrentCenter({...props.currentCenter, [name]:val});
    };

    const centerChangeHandler = val => {
        let currCenter = JSON.parse(JSON.stringify(props.currentCenter));
        const center = props.allCenters.find(c => {
            return c.id === parseInt(val,10);
        })

        currCenter.center = center;
        props.setCurrentCenter(currCenter);
    };

    const showNewCenterPopup = () => {
        props.setAddEditCenterPopupVisible(false);
        props.setCurrentNewCenter(JSON.parse(JSON.stringify(props.emptyStudyCenter.center)));
        props.setAddNewCenterPopupVisible(true);
    };

    const isCenterInStudy = (center) => {
        return props.study.centers.filter(c => parseInt(c.center.id, 10) === parseInt(center.id, 10)).length > 0;
    };

    const centersOptions = [];
    props.allCenters.map(center => {
        if(!isCenterInStudy(center)) {
            centersOptions.push({label: center.acronym, value: center.id})
        }
        return false;
    });

    const submitHandler = e => {
        e.preventDefault();
    };

    return (
        <React.Fragment>
        <div className="modal fade in">
            <div className="modal-header">
                <h3>
                    {props.currentCenter.id > 0 ? "Edit" : "Add" } Clinical Site
                </h3>
            </div>
            <div className="modal-body">
                <form onSubmit={submitHandler}>
                    <div className="modal-form">
                        <div className="form-group">

                            {props.addEditCenterErrors.length > 0 && (

                            <div className="modal-errors" style={{marginLeft: "15px"}}>
                                <div className="errorSummary"><p>Please fix the following input errors:</p>
                                    <ul>
                                        {props.addEditCenterErrors.map((message) => (
                                        <li key={message}>{message}</li>
                                        ))}
                                    </ul>
                                </div>
                            </div>

                            )}


                            <div className="span5" style={{width: "100%", marginTop: "-19px"}}>
                                {props.currentCenter.id <= 0 && (
                                <div className="row">
                                    <div className="label" style={{width: "136px"}}>
                                        Pick Clinical Site:
                                    </div>
                                    <div className="input" style={{marginRight: "15px", float: "right"}}>
                                        <CorcSelect
                                            id="center"
                                            name="center"
                                            value={centersOptions.filter(c => c.value === props.currentCenter.center.id)}
                                            onChange={selectedOption => centerChangeHandler(selectedOption.value)}
                                            placeholder="Select Clinical Site"
                                            options={centersOptions}
                                            isSearchable={false}
                                            containerStyles={{width: "303px"}}
                                        />
                                    </div>
                                </div>
                                )}
                                <div className="row">
                                    <div className="label" style={{width: "136px"}}>
                                        P. Investigator:
                                    </div>
                                    <div
                                        className="input"
                                        style={{marginRight: "18px", float: "right"}}
                                    >
                                        <input
                                            className="no-required"
                                            type="text"
                                            style={{width: "288px"}}
                                            name="principal_investigator"
                                            value={props.currentCenter.principal_investigator}
                                            onChange={onChangeHandler}
                                        />
                                    </div>
                                </div> {/* .row */}
                                <div className="row">
                                    <div className="label" style={{width: "136px"}}>
                                        Notifications E-mail:
                                    </div>
                                    <div
                                        className="input"
                                        style={{marginRight: "18px", float: "right"}}
                                    >
                                        <input
                                            className="no-required"
                                            type="text"
                                            style={{width: "288px"}}
                                            name="pi_notification_email"
                                            value={props.currentCenter.pi_notification_email}
                                            onChange={onChangeHandler}
                                        />
                                    </div>
                                </div> {/* .row */}
                                <div className="row">
                                    <div className="label" style={{width: "136px"}}>
                                        Contact E-mail:
                                    </div>
                                    <div
                                        className="input"
                                        style={{marginRight: "18px", float: "right"}}
                                    >
                                        <input
                                            className="no-required"
                                            type="text"
                                            style={{width: "288px"}}
                                            name="pi_contact_email"
                                            value={props.currentCenter.pi_contact_email}
                                            onChange={onChangeHandler}
                                        />
                                    </div>
                                </div> {/* .row */}

                                <br />

                                <div className="row">
                                    <div className="label" style={{width: "136px"}}>
                                        Study Site Number*:
                                    </div>
                                    <div
                                        className="input"
                                        style={{marginRight: "18px", float: "right"}}
                                    >
                                        <input
                                            type="text"
                                            style={{width: "288px"}}
                                            name="study_site_num"
                                            value={props.currentCenter.study_site_num}
                                            onChange={onChangeHandler}
                                        />
                                    </div>
                                </div> {/* .row */}
                                <div className="row">
                                    <div className="label" style={{width: "136px"}}>
                                        SC:
                                    </div>
                                    <div
                                        className="input"
                                        style={{marginRight: "18px", float: "right"}}
                                    >
                                        <input
                                            className="no-required"
                                            type="text"
                                            style={{width: "288px"}}
                                            name="study_coordinator_name"
                                            value={props.currentCenter.study_coordinator_name}
                                            onChange={onChangeHandler}
                                        />
                                    </div>
                                </div> {/* .row */}
                                <div className="row">
                                    <div className="label" style={{width: "136px"}}>
                                        E-mails:
                                    </div>
                                    <div
                                        className="input"
                                        style={{marginRight: "18px", float: "right"}}
                                    >
                                        <input
                                            className="no-required"
                                            type="text"
                                            style={{width: "288px"}}
                                            name="study_coordinator_email"
                                            value={props.currentCenter.study_coordinator_email}
                                            onChange={onChangeHandler}
                                        />
                                    </div>
                                </div> {/* .row */}
                                <div className="row">
                                    <div className="label" style={{width: "136px"}}>
                                        Phone:
                                    </div>
                                    <div
                                        className="input"
                                        style={{marginRight: "18px", float: "right"}}
                                    >
                                        <input
                                            className="no-required"
                                            type="text"
                                            style={{width: "288px"}}
                                            name="study_coordinator_phone"
                                            value={props.currentCenter.study_coordinator_phone}
                                            onChange={onChangeHandler}
                                        />
                                    </div>
                                </div> {/* .row */}
                                <div className="row">
                                    <div className="label" style={{width: "136px"}}>
                                        Fax:
                                    </div>
                                    <div
                                        className="input"
                                        style={{marginRight: "18px", float: "right"}}
                                    >
                                        <input
                                            className="no-required"
                                            type="text"
                                            style={{width: "288px"}}
                                            name="study_coordinator_fax"
                                            value={props.currentCenter.study_coordinator_fax}
                                            onChange={onChangeHandler}
                                        />
                                    </div>
                                </div> {/* .row */}

                               <br />

                                <div className="row">
                                    <div className="label" style={{width: "136px"}}>
                                        Other E-mails:
                                    </div>
                                    <div
                                        className="input"
                                        style={{marginRight: "18px", float: "right"}}
                                    >
                                        <textarea
                                            className="no-required"
                                            type="text"
                                            style={{width: "288px", height: "128px"}}
                                            name="other_emails"
                                            value={props.currentCenter.other_emails}
                                            onChange={onChangeHandler}
                                        />
                                    </div>
                                </div> {/* .row */}

                                <div style={{width: "100%", marginBottom: "15px", height: "15px", borderBottom: "1px solid #eee"}}></div>

                                {props.currentCenter.id <= 0 && (
                                <div className="row">
                                    <div className="label" style={{width: "198px"}}>
                                        Add Clinical Site:
                                    </div>
                                    <div className="input">
                                        <button
                                            className="btn large grey align-center right"
                                            onClick={showNewCenterPopup}
                                        >Add New Clinical Site
                                        </button>
                                    </div>
                                </div>
                                )}
                            </div> {/* .span5 */}
                        </div>
                    </div>
                </form>
            </div> {/* .modal-body */}
            <div className="modal-footer">
                <button
                    className="btn small orange align-center right"
                    onClick={confirmAddCenter}
                >Save</button>
                <button
                    className="btn small grey align-center right"
                    onClick={closePopup}
                >Close</button>
            </div> {/* .modal-body */}
        </div>

        <div className="modal-backdrop fade in"></div>
        </React.Fragment>
    );
}

function AddMonitorPopup(props) {
    const closePopup = () => {
        props.setAddMonitorPopupVisible(false);
        props.setCurrentMonitor({});
    };

    const confirmAddMonitor = async () => {
        if(!props.currentMonitor.id) {
            return false;
        }


        let editedStudy = JSON.parse(JSON.stringify(props.study));
        const errMsg = "Sorry, something went wrong when removing this center. Please contact your IT helpdesk.";

        let count = props.overlayVisible;
        props.setOverlayVisible( (count) => count + 1);

        await axios.post(process.env.REACT_APP_API_ENDPOINT+'/studies/'+props.study.id+'/add_monitor/', {...props.currentMonitor}, { withCredentials: true})
            .then((response) => {

            props.setOverlayVisible( (count) => count - 1);
            if (response.status === 200 && response.data && response.data.id) {

                editedStudy.monitors.push(response.data);

                props.setStudy(editedStudy);
                props.setAddMonitorPopupVisible(false);
                props.setCurrentMonitor({});

            }
            else {
                alert(errMsg);
            }


        }, (error) => {
            alert(errMsg);
            props.setOverlayVisible( (count) => count - 1);
        });



    };

    const onChangeHandler = val => {
        let currMonitor = JSON.parse(JSON.stringify(props.currentMonitor));
        currMonitor.id = val;

        props.setCurrentMonitor(currMonitor);
    };

    const showAddNewMonitor = () => {
        props.setAddMonitorPopupVisible(false);
        props.setCurrentNewMonitor(JSON.parse(JSON.stringify(props.emptyNewStudyUser)));
        props.setAddNewMonitorPopupVisible(true);
    };

    const isMonitorInStudy = (monitor) => {
        return props.study.monitors.filter(mon => parseInt(mon.user.id, 10) === parseInt(monitor.id, 10)).length > 0;
    };

    const monitorsOptions = [];
    props.allUsers.map((user, i) => {
        if(user.role === 'monitor' && !isMonitorInStudy(user)) {
            monitorsOptions.push({label: user.username, value: user.id});
        }
        return false;
    });

    const submitHandler = e => {
        e.preventDefault();
    };

    return (
        <React.Fragment>
        <div className="modal fade in">
            <div className="modal-header">
                <h3>
                    Add Monitor
                </h3>
            </div>
            <div className="modal-body no-overflow">
                <form onSubmit={submitHandler}>
                    <div className="modal-form">
                        <div className="form-group">
                            <div className="span5" style={{width: "100%"}}>
                                <div className="row">
                                    <div className="label" style={{width: "210px"}}>
                                        Pick a monitor:
                                    </div>
                                    <div className="input">
                                        <CorcSelect
                                            id="user"
                                            name="user"
                                            containerStyles={{width: "304px"}}
                                            value={monitorsOptions.filter(m => m.value === props.currentMonitor.id)}
                                            options={monitorsOptions}
                                            onChange={selectedOption => onChangeHandler(selectedOption.value)}
                                            placeholder="Select monitor"
                                            isSearchable={false}
                                        />
                                    </div>
                                </div> {/* .row */}
                                <div style={{width: "100%", marginBottom: "15px", height: "15px", borderBottom: "1px solid #eee"}}></div>
                                <div className="row">
                                    <div className="label" style={{width: "210px"}}>
                                        Add new monitor:
                                    </div>
                                    <div className="input">
                                        <button
                                            className="btn large grey align-center right"
                                            onClick={showAddNewMonitor}
                                        >Add new monitor
                                        </button>
                                    </div>
                                </div> {/* .row */}
                            </div>
                        </div> {/* .form-group */}
                    </div>
                </form>
            </div> {/* .modal-body */}
                <div className="modal-footer">
                    <button
                        className="btn small orange align-center right"
                        onClick={confirmAddMonitor}
                    >Save
                    </button>
                    <button
                        className="btn small grey align-center right"
                        onClick={closePopup}
                    >Close
                    </button>
                </div>
        </div>
        <div className="modal-backdrop fade in"></div>
        </React.Fragment>
    );
}

function AddGraderPopup(props) {

    const closePopup = () => {
        props.setAddGraderPopupVisible(false);
        props.setCurrentGrader({});
    };

    const confirmAddGrader = async () => {
        if(!props.currentGrader.id || !props.currentGrader.exams.length) {
            return false;
        }

        let editedStudy = JSON.parse(JSON.stringify(props.study));
        const errMsg = "Sorry, something went wrong when removing this center. Please contact your IT helpdesk.";

        let count = props.overlayVisible;
        props.setOverlayVisible( (count) => count + 1);

        await axios.post(process.env.REACT_APP_API_ENDPOINT+'/studies/'+props.study.id+'/add_grader/', {...props.currentGrader}, { withCredentials: true})
            .then((response) => {

            props.setOverlayVisible( (count) => count - 1);
            if (response.status === 200 && response.data && response.data.id) {

                editedStudy.graders.push(response.data);

                /*
                editedStudy.exams.map(exam => {
                    if(props.currentGrader.exams.indexOf(exam.id) > -1) {
                        exam.graders.push(response.data);
                    }
                });
                */

                props.setStudy(editedStudy);
                props.setAddGraderPopupVisible(false);
                props.setCurrentGrader({});

            }
            else {
                alert(errMsg);
            }


        }, (error) => {
            alert(errMsg);
            props.setOverlayVisible( (count) => count - 1);
        });

    };

    const onChangeGraderHandler = val => {
        props.setCurrentGrader({...props.currentGrader, id: val});
    };

    const onChangeHandler = (ev) => {
        let currGrader = JSON.parse(JSON.stringify(props.currentGrader));
        let val = parseInt(ev.target.value, 10);
        let isChecked = ev.target.checked;

        if(isChecked) {
            currGrader.exams.push(val);
        }
        else {
            let idx = currGrader.exams.indexOf(val);

            if(idx > -1) {
                currGrader.exams.splice(idx, 1);
            }
        }

        props.setCurrentGrader(currGrader);
    };

    const showAddNewGrader = () => {
        props.setAddGraderPopupVisible(false);
        props.setCurrentNewGrader(JSON.parse(JSON.stringify(props.emptyNewStudyUser)));
        props.setAddNewGraderPopupVisible(true);
    };

    const isChecked = (examId) => {
        let currGrader = JSON.parse(JSON.stringify(props.currentGrader));

        return currGrader.exams.indexOf(examId) > -1;
    };

    const isGraderInStudy = (grader) => {
        return props.study.graders.filter(grad => parseInt(grad.user.id, 10) === parseInt(grader.id, 10)).length > 0;
    };

    const gradersOptions = [];
    props.allUsers.map(user => {
        if(user.role === 'grader' && !isGraderInStudy(user)) {
            gradersOptions.push({label: user.username, value: user.id});
        }
        return false;
    });

    const submitHandler = e => {
        e.preventDefault();
    };

    return (
        <React.Fragment>
        <div className="modal fade in">
            <div className="modal-header">
                <h3>Add Grader</h3>
            </div>
            <div className="modal-body no-overflow">
                <form onSubmit={submitHandler}>
                    <div className="modal-form">
                        <div className="form-group">
                            <div className="span5" style={{width: "100%"}}>
                                <div className="row">
                                    <div className="label" style={{width: "210px"}}>
                                        Pick a grader:
                                    </div>
                                    <div className="input">
                                        <CorcSelect
                                            id="user"
                                            name="user"
                                            containerStyles={{width: "304px"}}
                                            value={gradersOptions.filter(g => g.value === props.currentGrader.id)}
                                            onChange={selectedOption => onChangeGraderHandler(selectedOption.value)}
                                            isSearchable={false}
                                            options={gradersOptions}
                                        />
                                    </div>
                                </div> {/* .row */}

                                <div style={{width: "100%", marginBottom: "15px", height: "15px", borderBottom: "1px solid #eee"}}></div>

                                <div className="row">
                                    <div className="label" style={{width: "100%"}}>
                                        Select User Exams:
                                    </div>
                                    <div style={{width: "100%", overflow: "hidden"}}>
                                        <div className="form-group checkbox-group group-page">
                                            <div>
                                                {props.study.exams.map((exam, i) =>(
                                                <div key={i} className="checkbox">
                                                    <input
                                                        type="checkbox"
                                                        id={"exams_"+exam.id}
                                                        name="exams"
                                                        value={exam.id}
                                                        checked={isChecked(exam.id)}
                                                        onChange={onChangeHandler}
                                                    />
                                                    <label htmlFor={"exams_"+exam.id}>{exam.exam_type.name}</label>
                                                </div>
                                                ))}
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div style={{width: "100%", marginBottom: "15px", height: "15px", borderBottom: "1px solid #eee"}}></div>

                                <div className="row">
                                    <div className="label" style={{width: "210px"}}>
                                        Add new grader:
                                    </div>
                                    <div className="input">
                                        <button
                                            className="btn large grey align-center right"
                                            onClick={showAddNewGrader}
                                        >Add new grader
                                        </button>
                                    </div>
                                </div> {/* .row */}
                            </div>
                        </div> {/* .form-group */}
                    </div>
                </form>
            </div> {/* .modal-body */}
                <div className="modal-footer">
                    <button
                        className="btn small orange align-center right"
                        onClick={confirmAddGrader}
                    >Save
                    </button>
                    <button
                        className="btn small grey align-center right"
                        onClick={closePopup}
                    >Close
                    </button>
                </div>
        </div>
        <div className="modal-backdrop fade in"></div>
        </React.Fragment>
    );
}

function AddTechnicianPopup(props) {
    const closePopup = () => {
        props.setAddTechnicianPopupVisible(false);
        props.setCurrentTechnician({});
    };

    const confirmAddTechnician = async () => {
        if(!props.currentTechnician.center || !props.currentTechnician.id || !props.currentTechnician.exams.length) {
            return false;
        }

        let editedStudy = JSON.parse(JSON.stringify(props.study));
        const errMsg = "Sorry, something went wrong when adding this technician. Please contact your IT helpdesk.";

        let count = props.overlayVisible;
        props.setOverlayVisible( (count) => count + 1);

        await axios.post(process.env.REACT_APP_API_ENDPOINT+'/studies/'+props.study.id+'/add_technician/', {...props.currentTechnician}, { withCredentials: true})
            .then((response) => {

            props.setOverlayVisible( (count) => count - 1);
            if (response.status === 200 && response.data && response.data.id) {

                editedStudy.technicians.push(response.data);

                props.setStudy(editedStudy);
                props.setAddTechnicianPopupVisible(false);
                props.setCurrentTechnician({});

            }
            else {
                alert(errMsg);
            }


        }, (error) => {
            alert(errMsg);
            props.setOverlayVisible( (count) => count - 1);
        });

    };

    const onChangeTechHandler = val => {
        props.setCurrentTechnician({...props.currentTechnician, id: val});
    };

    const onChangeHandler = (ev) => {
        let currTech = JSON.parse(JSON.stringify(props.currentTechnician));
        let val = parseInt(ev.target.value, 10);
        let isChecked = ev.target.checked;

        if(isChecked) {
            currTech.exams.push(val);
        }
        else {
            let idx = currTech.exams.indexOf(val);

            if(idx > -1) {
                currTech.exams.splice(idx, 1);
            }
        }

        props.setCurrentTechnician(currTech);
    };

    const onChangeCenter = val => {
        let centerId = parseInt(val, 10);
        props.setCurrentTechnician({...props.currentTechnician, center: centerId});
        props.setActiveTechCenter(centerId);

        let centerUsers = props.allUsers.filter(u => u.center && u.center.id === centerId && u.role === 'tech');
        props.setCenterTechs(centerUsers);
    };

    const showAddNewTechnician = () => {
        props.setAddTechnicianPopupVisible(false);
        props.setCurrentNewTechnician(JSON.parse(JSON.stringify(props.emptyNewStudyUser)));
        props.setAddNewTechnicianPopupVisible(true);
    };

    const isChecked = (examId) => {
        let currTech = JSON.parse(JSON.stringify(props.currentTechnician));

        return currTech.exams.indexOf(examId) > -1;
    };

    const isTechInStudy = (technician) => {
        return props.study.technicians.filter(tech => parseInt(tech.user.id, 10) === parseInt(technician.id, 10)).length > 0;
    };

    const isCenterInStudy = (center) => {
        return props.study.centers.filter(c => parseInt(c.center.id, 10) === parseInt(center.id, 10)).length > 0;
    };

    const centersOptions = [];
    props.allCenters.map(center => {
        if(isCenterInStudy(center)) {
            centersOptions.push({label: center.acronym, value: center.id})
        }
        return false;
    });

    const techsOptions = [];
    props.centerTechs.map(user => {
        if(!isTechInStudy(user)) {
            techsOptions.push({label: user.username, value: user.id});
        }
        return false;
    });

    const submitHandler = e => {
        e.preventDefault();
    };

    return (
        <React.Fragment>
        <div className="modal fade in">
            <div className="modal-header">
                <h3>Add Technician</h3>
            </div>
            <div className="modal-body no-overflow">
                <form onSubmit={submitHandler}>
                    <div className="modal-form">
                        <div className="form-group">
                            <div className="span5" style={{width: "100%"}}>
                                <div className="row">
                                    <div className="label" style={{width: "210px"}}>
                                        Pick Clinical Site:
                                    </div>
                                    <div className="input">
                                        <CorcSelect
                                            id="center"
                                            name="center"
                                            containerStyles={{width: "304px"}}
                                            onChange={selectedOption => onChangeCenter(selectedOption.value)}
                                            value={centersOptions.filter(c => c.value === props.currentTechnician.center)}
                                            options={centersOptions}
                                            isSearchable={false}
                                            placeholder="Select clinical site"
                                        />
                                    </div>
                                </div> {/* .row */}

                                <div className="row">
                                    <div className="label" style={{width: "210px"}}>
                                        Pick a technician:
                                    </div>
                                    <div className="input">
                                        <CorcSelect
                                            id="user"
                                            name="user"
                                            containerStyles={{width: "304px"}}
                                            value={techsOptions.filter(t => t.value === props.currentTechnician.id)}
                                            onChange={selectedOption => onChangeTechHandler(selectedOption.value)}
                                            isSearchable={false}
                                            placeholder="Select Technician"
                                            options={techsOptions}
                                        />
                                    </div>

                                </div> {/* .row */}

                                <div style={{width: "100%", marginBottom: "15px", height: "15px", borderBottom: "1px solid #eee"}}></div>

                                <div className="row">
                                    <div className="label" style={{width: "100%"}}>
                                        Select User Exams:
                                    </div>
                                    <div style={{width: "100%", overflow: "hidden"}}>
                                        <div className="form-group checkbox-group group-page">
                                            <div>
                                                {props.study.exams.map((exam, i) =>(
                                                <div key={i} className="checkbox">
                                                    <input
                                                        type="checkbox"
                                                        name="exams"
                                                        id={"exams_"+exam.id}
                                                        value={exam.id}
                                                        checked={isChecked(exam.id)}
                                                        onChange={onChangeHandler}
                                                    />
                                                    <label htmlFor={"exams_"+exam.id}>{exam.exam_type.name}</label>
                                                </div>
                                                ))}
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div style={{width: "100%", marginBottom: "15px", height: "15px", borderBottom: "1px solid #eee"}}></div>

                                <div className="row">

                                    <div className="input">
                                        <button
                                            className="btn large grey align-center right"
                                            onClick={showAddNewTechnician}
                                        >Add new technician
                                        </button>
                                    </div>
                                </div> {/* .row */}
                            </div>
                        </div> {/* .form-group */}
                    </div>
                </form>
            </div> {/* .modal-body */}
                <div className="modal-footer">
                    <button
                        className="btn small orange align-center right"
                        onClick={confirmAddTechnician}
                    >Save
                    </button>
                    <button
                        className="btn small grey align-center right"
                        onClick={closePopup}
                    >Close
                    </button>
                </div>
        </div>
        <div className="modal-backdrop fade in"></div>
        </React.Fragment>
    );
}

function AddCoordinatorPopup(props) {
    const closePopup = () => {
        props.setAddCoordinatorPopupVisible(false);
        props.setCurrentCoordinator({});
    };

    const confirmAddCoordinator = async () => {

        if (!props.currentCoordinator.id) {
            return false;
        }

        let editedStudy = JSON.parse(JSON.stringify(props.study));
        const errMsg = "Sorry, something went wrong when adding this coordinator. Please contact your IT helpdesk.";

        let count = props.overlayVisible;
        props.setOverlayVisible( (count) => count + 1);

        await axios.post(process.env.REACT_APP_API_ENDPOINT+'/studies/'+props.study.id+'/add_coordinator/', {...props.currentCoordinator}, { withCredentials: true})
            .then((response) => {

            props.setOverlayVisible( (count) => count - 1);
            if (response.status === 200 && response.data && response.data.id) {

                editedStudy.coordinators.push(response.data);

                props.setStudy(editedStudy);
                props.setAddCoordinatorPopupVisible(false);
                props.setCurrentCoordinator({});

            }
            else {
                alert(errMsg);
            }


        }, (error) => {
            alert(errMsg);
            props.setOverlayVisible( (count) => count - 1);
        });

    };

    const onChangeHandler = val => {
        props.setCurrentCoordinator({...props.currentCoordinator, id: parseInt(val, 10)});
    };

    const onChangeCenter = val => {
        let centerId = parseInt(val, 10);
        props.setCurrentCoordinator({...props.currentCoordinator, center: centerId});
        props.setActiveCoordCenter(centerId);

        let centerUsers = props.allUsers.filter(u => u.center && u.center.id === centerId && u.role === 'coordinator');
        props.setCenterCoords(centerUsers);
    };

    const showAddNewCoordinator = () => {
        props.setAddCoordinatorPopupVisible(false);
        props.setCurrentNewCoordinator(JSON.parse(JSON.stringify(props.emptyNewStudyUser)));
        props.setAddNewCoordinatorPopupVisible(true);
    };

    const isCoordInStudy = (coordinator) => {
        return props.study.coordinators.filter(coord => parseInt(coord.user.id, 10) === parseInt(coordinator.id, 10)).length > 0;
    };

    const isCenterInStudy = (center) => {
        return props.study.centers.filter(c => parseInt(c.center.id, 10) === parseInt(center.id, 10)).length > 0;
    };

    const centersOptions = [];
    props.allCenters.map(center => {
        if(isCenterInStudy(center)) {
            centersOptions.push({label: center.acronym, value: center.id})
        }
        return false;
    });

    const coordsOptions = [];
    props.centerCoords.map(user => {
        if(!isCoordInStudy(user)) {
            coordsOptions.push({label: user.username, value: user.id});
        }
        return false;
    });

    const submitHandler = e => {
        e.preventDefault();
    };

    return (
        <React.Fragment>
        <div className="modal fade in">
            <div className="modal-header">
                <h3>Add Coordinator</h3>
            </div>
            <div className="modal-body no-overflow">
                <form onSubmit={submitHandler}>
                    <div className="modal-form">
                        <div className="form-group">
                            <div className="span5" style={{width: "100%"}}>
                                <div className="row">
                                    <div className="label" style={{width: "210px"}}>
                                        Pick Clinical Site:
                                    </div>
                                    <div className="input">
                                        <CorcSelect
                                            id="center"
                                            name="center"
                                            containerStyles={{width: "304px"}}
                                            onChange={selectedOption => onChangeCenter(selectedOption.value)}
                                            value={centersOptions.filter(c => c.value === props.currentCoordinator.center)}
                                            isSearchable={false}
                                            placeholder="Select Clinical Site"
                                            options={centersOptions}
                                        />
                                    </div>
                                </div> {/* .row */}

                                <div className="row">
                                    <div className="label" style={{width: "210px"}}>
                                        Pick a coordinator:
                                    </div>
                                    <div className="input">
                                        <CorcSelect
                                            id="user"
                                            name="user"
                                            containerStyles={{width: "304px"}}
                                            value={coordsOptions.filter(c => c.value === props.currentCoordinator.id)}
                                            onChange={selectedOption => onChangeHandler(selectedOption.value)}
                                            placeholder="Select Coordinator"
                                            isSearchable={false}
                                            options={coordsOptions}
                                        />
                                    </div>

                                </div> {/* .row */}

                                <div style={{width: "100%", marginBottom: "15px", height: "15px", borderBottom: "1px solid #eee"}}></div>

                                <div className="row">
                                    <div className="input">
                                        <button
                                            className="btn large grey align-center right"
                                            onClick={showAddNewCoordinator}
                                        >Add new coordinator
                                        </button>
                                    </div>
                                </div> {/* .row */}
                            </div>
                        </div> {/* .form-group */}
                    </div>
                </form>
            </div> {/* .modal-body */}
                <div className="modal-footer">
                    <button
                        className="btn small orange align-center right"
                        onClick={confirmAddCoordinator}
                    >Save
                    </button>
                    <button
                        className="btn small grey align-center right"
                        onClick={closePopup}
                    >Close
                    </button>
                </div>
        </div>
        <div className="modal-backdrop fade in"></div>
        </React.Fragment>
    );
}

function DeleteCenterPopup(props) {
    const closePopup = () => {
        props.setCurrentCenter({});
        props.setDeleteCenterPopupVisible(false);
    };

    const confirmDelete = async () => {
        const centerToDel = props.currentCenter;
        let editedStudy = JSON.parse(JSON.stringify(props.study));
        const errMsg = "Sorry, something went wrong when removing this center. Please contact your IT helpdesk.";

        let count = props.overlayVisible;
        props.setOverlayVisible( (count) => count + 1);

        await axios.put(process.env.REACT_APP_API_ENDPOINT+'/studies/'+props.study.id+'/delete_center/', {...centerToDel}, { withCredentials: true})
            .then((response) => {

            props.setOverlayVisible( (count) => count - 1);
            if (response.status === 200) {

                //remove center
                for(let i=0, len = editedStudy.centers.length; i<=len-1;++i) {
                    if(parseInt(editedStudy.centers[i].center.id, 10) === parseInt(centerToDel.center.id, 10) ) {
                        editedStudy.centers.splice(i, 1);
                        break;
                    }
                }

                //remove techs from center
                editedStudy.technicians = editedStudy.technicians.filter(t => t.user.center && parseInt(t.user.center.id, 10) !== parseInt(centerToDel.center.id, 10));

                // remove coords from center
                editedStudy.coordinators = editedStudy.coordinators.filter(t => t.user.center && parseInt(t.user.center.id, 10) !== parseInt(centerToDel.center.id, 10));

                props.setCurrentCenter({});
                props.setDeleteCenterPopupVisible(false);
                props.setStudy(editedStudy);

            }
            else {
                alert(errMsg);
            }

        }, (error) => {
            alert(errMsg);
            props.setOverlayVisible( (count) => count - 1);
        });



    };

    const centerHasTechs = () => {
        const currCenter = props.currentCenter;
        let centerTechs = props.study.technicians.filter(function(tec) {
          if (tec.user.center && currCenter.center) {
              return currCenter.center.id === tec.user.center.id;
          }
          return false;
        });

        return centerTechs.length > 0;
    };

    const centerHasCoords = () => {
        const currCenter = props.currentCenter;
        let centerCoords = props.study.coordinators.filter(function(coord) {
          if (coord.user.center && currCenter.center) {
              return currCenter.center.id === coord.user.center.id;
          }
          return false;
        });

        return centerCoords.length > 0;
    };

    return (
        <React.Fragment>
        <div className="modal fade in">
            <div className="modal-header">
                <h3>Confirmation!</h3>
            </div>
            <div className="modal-body">
                <br />
                {centerHasTechs() || centerHasCoords() ? (

                <React.Fragment>
                <p>This center has technicians and/or coordinators associated to this study.</p>
                <br />

                <p>If you continue, these users will also be deleted. Are you sure you want to continue?</p>
                </React.Fragment>

                ) : (

                <p>Do you really want to delete this center?</p>

                )}
                <br />
            </div> {/* .modal-body */}
            <div className="modal-footer">
                <button
                    className="btn small orange align-center right"
                    onClick={confirmDelete}
                >Yes
                </button>
                <button
                    className="btn small grey align-center right"
                    onClick={closePopup}
                >No
                </button>
            </div>
        </div>
        <div className="modal-backdrop fade in"></div>
        </React.Fragment>
    );
}

function DeleteMonitorPopup(props) {
    const closePopup = () => {
        props.setDeleteMonitorPopupVisible(false);
        props.setCurrentMonitor({});
    };

    const confirmDelete = async () => {
        let currMon = props.currentMonitor;
        let editedStudy = JSON.parse(JSON.stringify(props.study));
        const errMsg = "Sorry, something went wrong when removing this center. Please contact your IT helpdesk.";

        let count = props.overlayVisible;
        props.setOverlayVisible( (count) => count + 1);

        await axios.put(process.env.REACT_APP_API_ENDPOINT+'/studies/'+props.study.id+'/delete_monitor/', {...props.currentMonitor}, { withCredentials: true})
            .then((response) => {

            props.setOverlayVisible( (count) => count - 1);

            if (response.status === 200) {

                for(let i=0, len = editedStudy.monitors.length; i<=len-1;++i) {
                    if(editedStudy.monitors[i].user.id === currMon.user.id) {
                        editedStudy.monitors.splice(i, 1);
                        break;
                    }
                }

                props.setStudy(editedStudy);
                props.setDeleteMonitorPopupVisible(false);
                props.setCurrentMonitor({});

            }
            else {
                alert(errMsg);
            }


        }, (error) => {
            alert(errMsg);
            props.setOverlayVisible( (count) => count - 1);
        });


    };

    return (
        <React.Fragment>
        <div className="modal fade in">
            <div className="modal-header">
                <h3>Confirmation!</h3>
            </div>
            <div className="modal-body">
                <br />
                <p>Do you really want to delete this monitor?</p>
                <br />
            </div> {/* .modal-body */}
            <div className="modal-footer">
                <button
                    className="btn small orange align-center right"
                    onClick={confirmDelete}
                >Yes
                </button>
                <button
                    className="btn small grey align-center right"
                    onClick={closePopup}
                >No
                </button>
            </div>
        </div>
        <div className="modal-backdrop fade in"></div>
        </React.Fragment>
    );
}

function DeleteGraderPopup(props) {
    const closePopup = () => {
        props.setDeleteGraderPopupVisible(false);
        props.setCurrentGrader({});
    };

    const confirmDelete = async () => {
        let currGrad = props.currentGrader;
        let editedStudy = JSON.parse(JSON.stringify(props.study));
        const errMsg = "Sorry, something went wrong when removing this grader. Please contact your IT helpdesk.";

        let count = props.overlayVisible;
        props.setOverlayVisible( (count) => count + 1);

        await axios.put(process.env.REACT_APP_API_ENDPOINT+'/studies/'+props.study.id+'/delete_grader/', {...props.currentGrader}, { withCredentials: true})
            .then((response) => {

            props.setOverlayVisible( (count) => count - 1);
            if (response.status === 200) {

                for(let i=0, len = editedStudy.graders.length; i<=len-1;++i) {
                    if(editedStudy.graders[i].user.id === currGrad.user.id) {
                        editedStudy.graders.splice(i, 1);
                        break;
                    }
                }

                props.setStudy(editedStudy);
                props.setDeleteGraderPopupVisible(false);
                props.setCurrentGrader({});

            }
            else {
                alert(errMsg);
            }


        }, (error) => {
            alert(errMsg);
            props.setOverlayVisible( (count) => count - 1);
        });


    };

    return (
        <React.Fragment>
        <div className="modal fade in">
            <div className="modal-header">
                <h3>Confirmation!</h3>
            </div>
            <div className="modal-body">
                <br />
                <p>Do you really want to delete this grader?</p>
                <br />
            </div> {/* .modal-body */}
            <div className="modal-footer">
                <button
                    className="btn small orange align-center right"
                    onClick={confirmDelete}
                >Yes
                </button>
                <button
                    className="btn small grey align-center right"
                    onClick={closePopup}
                >No
                </button>
            </div>
        </div>
        <div className="modal-backdrop fade in"></div>
        </React.Fragment>
    );
}

function DeleteCoordinatorPopup(props) {
    const closePopup = () => {
        props.setDeleteCoordinatorPopupVisible(false);
        props.setCurrentCoordinator({});
    };

    const confirmDelete = async () => {
        let currCoord = props.currentCoordinator;
        let editedStudy = JSON.parse(JSON.stringify(props.study));
        const errMsg = "Sorry, something went wrong when removing this coordinator. Please contact your IT helpdesk.";

        let count = props.overlayVisible;
        props.setOverlayVisible( (count) => count + 1);

        await axios.put(process.env.REACT_APP_API_ENDPOINT+'/studies/'+props.study.id+'/delete_coordinator/', {...props.currentCoordinator}, { withCredentials: true})
            .then((response) => {

            props.setOverlayVisible( (count) => count - 1);
            if (response.status === 200) {

                for(let i=0, len = editedStudy.coordinators.length; i<=len-1;++i) {
                    if(editedStudy.coordinators[i].user.id === currCoord.user.id) {
                        editedStudy.coordinators.splice(i, 1);
                        break;
                    }
                }

                props.setStudy(editedStudy);
                props.setDeleteCoordinatorPopupVisible(false);
                props.setCurrentCoordinator({});


            }
            else {
                alert(errMsg);
            }


        }, (error) => {
            alert(errMsg);
            props.setOverlayVisible( (count) => count - 1);
        });


    };

    return (
        <React.Fragment>
        <div className="modal fade in">
            <div className="modal-header">
                <h3>Confirmation!</h3>
            </div>
            <div className="modal-body">
                <br />
                <p>Do you really want to delete this coordinator?</p>
                <br />
            </div> {/* .modal-body */}
            <div className="modal-footer">
                <button
                    className="btn small orange align-center right"
                    onClick={confirmDelete}
                >Yes
                </button>
                <button
                    className="btn small grey align-center right"
                    onClick={closePopup}
                >No
                </button>
            </div>
        </div>
        <div className="modal-backdrop fade in"></div>
        </React.Fragment>
    );
}

function DeleteTechnicianPopup(props) {
    const closePopup = () => {
        props.setDeleteTechnicianPopupVisible(false);
        props.setCurrentTechnician({});
    };

    const confirmDelete = async () => {
        let currTec = props.currentTechnician;
        let editedStudy = JSON.parse(JSON.stringify(props.study));
        const errMsg = "Sorry, something went wrong when removing this technician. Please contact your IT helpdesk.";

        let count = props.overlayVisible;
        props.setOverlayVisible( (count) => count + 1);

        await axios.put(process.env.REACT_APP_API_ENDPOINT+'/studies/'+props.study.id+'/delete_technician/', {...props.currentTechnician}, { withCredentials: true})
            .then((response) => {

            props.setOverlayVisible( (count) => count - 1);
            if (response.status === 200) {

                for(let i=0, len = editedStudy.technicians.length; i<=len-1;++i) {
                    if(editedStudy.technicians[i].user.id === currTec.user.id) {
                        editedStudy.technicians.splice(i, 1);
                        break;
                    }
                }

                props.setStudy(editedStudy);
                props.setDeleteTechnicianPopupVisible(false);
                props.setCurrentTechnician({});


            }
            else {
                alert(errMsg);
            }


        }, (error) => {
            alert(errMsg);
            props.setOverlayVisible( (count) => count - 1);
        });


    };

    return (
        <React.Fragment>
        <div className="modal fade in">
            <div className="modal-header">
                <h3>Confirmation!</h3>
            </div>
            <div className="modal-body">
                <br />
                <p>Do you really want to delete this technician?</p>
                <br />
            </div> {/* .modal-body */}
            <div className="modal-footer">
                <button
                    className="btn small orange align-center right"
                    onClick={confirmDelete}
                >Yes
                </button>
                <button
                    className="btn small grey align-center right"
                    onClick={closePopup}
                >No
                </button>
            </div>
        </div>
        <div className="modal-backdrop fade in"></div>
        </React.Fragment>
    );
}

function GraderExamsPopup(props) {

    const closePopup = () => {
        props.setGraderExamsPopupVisible(false);
        props.setCurrentStudy({});
    };

    const saveExams = async () => {
        let editedStudy = JSON.parse(JSON.stringify(props.study));
        let currGrad = JSON.parse(JSON.stringify(props.currentGrader));
        const errMsg = "Sorry, something went wrong when removing this grader's exams. Please contact your IT helpdesk.";

        let count = props.overlayVisible;
        props.setOverlayVisible( (count) => count + 1);

        await axios.put(process.env.REACT_APP_API_ENDPOINT+'/studies/'+props.study.id+'/update_grader/', {...currGrad}, { withCredentials: true})
            .then((response) => {

            props.setOverlayVisible( (count) => count - 1);
            if (response.status === 200) {

                for(let i=0, len = editedStudy.graders.length; i<=len-1;++i) {
                    if(editedStudy.graders[i].id === currGrad.id) {

                        editedStudy.graders.splice(i, 1, currGrad);
                        break;
                    }
                }

                props.setStudy(editedStudy);
                props.setGraderExamsPopupVisible(false);
                props.setCurrentGrader({});

            }
            else {
                alert(errMsg);
            }


        }, (error) => {
            alert(errMsg);
            props.setOverlayVisible( (count) => count - 1);
        });


    };

    const examChangeHandler = (ev, exam) => {
        let editedStudy = JSON.parse(JSON.stringify(props.currentStudy));
        let editedExams = editedStudy.exams;
        let isChecked = ev.target.checked;
        let currGrad = JSON.parse(JSON.stringify(props.currentGrader));

        editedExams.map(ex => {
            if(ex.id === exam.id) {
                if(isChecked) {
                    //ex.graders.push(currGrad.user);
                    currGrad.exams.push(ex);
                }
                else {

                    for(let i=0, len=currGrad.exams.length; i<=len-1; ++i) {
                        if(currGrad.exams[i].id === exam.id) {
                            currGrad.exams.splice(i, 1);
                            break;
                        }
                    }

                    /*
                    let graders = ex.graders;

                    for(let i=0, len=graders.length; i<=len-1; ++i) {
                        if(graders[i].id === props.currentGrader.id) {
                            graders.splice(i, 1);
                            break;
                        }
                    }
                    */
                }
            }
            return false;
        });

        props.setCurrentGrader(currGrad);
        //props.setCurrentStudy(editedStudy);
    };

    const isExamChecked = (exam) => {

        //let found = exam.graders.filter(grad => grad.id === props.currentGrader.id);

        const examFound = props.currentGrader.exams.find(ex => {
            return ex.id === exam.id;
        })

        if (examFound === undefined) {
            return false;
        }

        return true;

    };

    return (
        <React.Fragment>
        <div className="modal fade in">
            <div className="modal-header">
                <h3>Grader Exams</h3>
            </div>
            <div className="modal-body">
                <div className="form-group checkbox-group group-page">
                    <div className="row">
                        {props.currentStudy.exams.map((exam, i) => (
                        <div className="checkbox" key={i}>
                            <input
                                className="study-exam"
                                type="checkbox"
                                id={"study-exam-"+exam.id}
                                name="study-exam"
                                value={exam.id}
                                checked={isExamChecked(exam)}
                                onChange={(ev) => examChangeHandler(ev, exam)}
                                disabled={props.study.status === "open" ? "" : "disabled"}
                            />
                            <label htmlFor={"study-exam-"+exam.id}>{exam.exam_type.name}</label>
                        </div>

                        ))}
                    </div>
                </div>

            </div> {/* .modal-body */}
            <div className="modal-footer">
                {props.study.status === "open" && (
                <button
                    className="btn small orange align-center right"
                    onClick={saveExams}
                >OK
                </button>
                )}

                <button
                    className="btn small grey align-center right"
                    onClick={closePopup}
                >{props.study.status === "open" ? "Cancel" : "OK"}
                </button>
            </div>
        </div>
        <div className="modal-backdrop fade in"></div>
        </React.Fragment>
    );
}

function TechnicianExamsPopup(props) {

    const closePopup = () => {
        props.setTechnicianExamsPopupVisible(false);
        props.setCurrentStudy({});
    };

    const saveExams = async () => {
        let editedStudy = JSON.parse(JSON.stringify(props.study));
        let currTech = JSON.parse(JSON.stringify(props.currentTechnician));
        const errMsg = "Sorry, something went wrong when updating this technicians' exams. Please contact your IT helpdesk.";

        let count = props.overlayVisible;
        props.setOverlayVisible( (count) => count + 1);

        await axios.put(process.env.REACT_APP_API_ENDPOINT+'/studies/'+props.study.id+'/update_technician/', {...currTech}, { withCredentials: true})
            .then((response) => {

            props.setOverlayVisible( (count) => count - 1);
            if (response.status === 200) {

                for(let i=0, len = editedStudy.technicians.length; i<=len-1;++i) {
                    if(editedStudy.technicians[i].id === currTech.id) {

                        editedStudy.technicians.splice(i, 1, currTech);
                        break;
                    }
                }

                props.setStudy(editedStudy);
                props.setTechnicianExamsPopupVisible(false);

                props.setCurrentTechnician({});

            }
            else {
                alert(errMsg);
            }


        }, (error) => {
            alert(errMsg);
            props.setOverlayVisible( (count) => count - 1);
        });


    };

    const examChangeHandler = (ev, exam) => {
        let isChecked = ev.target.checked;
        let currTech = JSON.parse(JSON.stringify(props.currentTechnician));

        props.currentStudy.exams.map(ex => {
            if(ex.id === exam.id) {
                if(isChecked) {
                    currTech.exams.push(ex);
                }
                else {

                    for(let i=0, len=currTech.exams.length; i<=len-1; ++i) {
                        if(currTech.exams[i].id === exam.id) {
                            currTech.exams.splice(i, 1);
                            break;
                        }
                    }

                }
            }
            return false;
        });

        props.setCurrentTechnician(currTech);
    };

    const isExamChecked = (exam) => {

        const examFound = props.currentTechnician.exams.find(ex => {
            return ex.id === exam.id;
        })

        if (examFound === undefined) {
            return false;
        }

        return true;
    };

    return (
        <React.Fragment>
        <div className="modal fade in">
            <div className="modal-header">
                <h3>Technician Exams</h3>
            </div>
            <div className="modal-body">
                <div className="form-group checkbox-group group-page">
                    <div className="row">
                        {props.currentStudy.exams.map((exam, i) => (
                        <div className="checkbox" key={i}>
                            <input
                                className="study-exam"
                                type="checkbox"
                                id={"study-exam-"+exam.id}
                                name="study-exam"
                                value={exam.id}
                                checked={isExamChecked(exam)}
                                onChange={(ev) => examChangeHandler(ev, exam)}
                                disabled={props.study.status === "open" ? "" : "disabled"}
                            />
                            <label htmlFor={"study-exam-"+exam.id}>{exam.exam_type.name}</label>
                        </div>
                        ))}
                    </div>
                </div>

            </div> {/* .modal-body */}
            <div className="modal-footer">
                {props.study.status === "open" && (
                <button
                    className="btn small orange align-center right"
                    onClick={saveExams}
                >OK
                </button>
                )}
                <button
                    className="btn small grey align-center right"
                    onClick={closePopup}
                >{props.study.status === "open" ? "Cancel" : "OK"}
                </button>
            </div>
        </div>
        <div className="modal-backdrop fade in"></div>
        </React.Fragment>
    );
}

function AddNewCenterPopup(props) {

    const onChangeHandler = (ev) => {
        let val = ev.target.value;
        let name = ev.target.name;

        props.setCurrentNewCenter({...props.currentNewCenter, [name]: val});
    };

    const closePopup = () => {
        props.setCurrentCenter({});
        props.setCurrentNewCenter({});
        props.setAddNewCenterPopupVisible(false);
    };

    const confirmAddNewCenter = async () => {

        const errMsg = "Sorry, something went wrong when saving this center. Please contact your IT helpdesk.";
        const currCenter = JSON.parse(JSON.stringify(props.emptyStudyCenter));
        const editedCenters = JSON.parse(JSON.stringify(props.allCenters));
        let newCenter, tmp = JSON.parse(JSON.stringify(props.currentNewCenter));
        let errors = [];

        // trim all fields before validating
        tmp.acronym = tmp.acronym.trim();
        tmp.full_name = tmp.full_name.trim();
        tmp.address = tmp.address.trim();
        tmp.city = tmp.city.trim();
        tmp.postcode = tmp.postcode.trim();
        tmp.country = tmp.country.trim();
        tmp.website = tmp.website.trim();
        tmp.email = tmp.email.trim();
        tmp.telephone = tmp.telephone.trim();
        tmp.fax = tmp.fax.trim();
        tmp.comment = tmp.comment.trim();


        // do we have requiredfields?
        if (!tmp.acronym) {
            errors.push('Acronym cannot be blank.');
        }

        if (!tmp.full_name) {
            errors.push('Full Name cannot be blank.');
        }

        if (!tmp.address) {
            errors.push('Address cannot be blank.');
        }

        if (!tmp.city) {
            errors.push('City cannot be blank.');
        }

        if (!tmp.postcode) {
            errors.push('Post Code cannot be blank.');
        }

        if (!tmp.country) {
            errors.push('Country cannot be blank.');
        }

        if (!tmp.telephone) {
            errors.push('Telephone cannot be blank.');
        }

        if (errors.length) {
            props.setNewCenterErrors(errors);
            return;
        }
        else {
            props.setNewCenterErrors([]);
        }

        let count = props.overlayVisible;
        props.setOverlayVisible( (count) => count + 1);

        await axios.post(process.env.REACT_APP_API_ENDPOINT+'/centers/', {...tmp}, { withCredentials: true})
            .then((response) => {

            if (response.status === 201 && response.data && response.data.id) {

                newCenter = response.data;

                editedCenters.push(newCenter);
                props.setAllCenters(editedCenters);

                currCenter.center = newCenter;

                props.setCurrentCenter(currCenter);
                props.setAddNewCenterPopupVisible(false);
                props.setAddEditCenterPopupVisible(true);

            }
            else {
                alert(errMsg);
            }

            props.setOverlayVisible( (count) => count - 1);

        }, (error) => {
            props.setOverlayVisible( (count) => count - 1);
            alert(errMsg);
        });

    };

    const submitHandler = e => {
        e.preventDefault();
    };

    return (
        <React.Fragment>
        <div className="modal fade in">
            <div className="modal-header">
                <h3>
                    Add New Clinical Site
                </h3>
            </div>
            <div className="modal-body">
                <form onSubmit={submitHandler}>
                    <div className="modal-form">
                        <div className="form-group">

                            {props.newCenterErrors.length > 0 && (
                            <div className="modal-errors" style={{marginLeft: "15px"}}>
                                <div className="errorSummary"><p>Please fix the following input errors:</p>
                                    <ul>
                                        {props.newCenterErrors.map((message) => (
                                        <li key={message}>{message}</li>
                                        ))}
                                    </ul>
                                </div>
                            </div>
                            )}


                            <div className="span5" style={{width: "100%"}}>
                                <div className="row">
                                    <div className="label" style={{width: "136px"}}>
                                        Full Name *:
                                    </div>
                                    <div className="input">
                                        <input
                                            type="text"
                                            style={{width: "338px"}}
                                            name="full_name"
                                            value={props.currentNewCenter.full_name}
                                            onChange={onChangeHandler}
                                        />
                                    </div>
                                </div> {/* .row */}
                                <div className="row">
                                    <div className="label" style={{width: "136px"}}>
                                        Acronym *:
                                    </div>
                                    <div className="input">
                                        <input
                                            type="text"
                                            style={{width: "338px"}}
                                            name="acronym"
                                            value={props.currentNewCenter.acronym}
                                            onChange={onChangeHandler}
                                        />
                                    </div>
                                </div> {/* .row */}

                                <br />

                                <div className="row">
                                    <div className="label" style={{width: "136px"}}>
                                        Address *:
                                    </div>
                                    <div className="input">
                                        <input
                                            type="text"
                                            style={{width: "338px"}}
                                            name="address"
                                            value={props.currentNewCenter.address}
                                            onChange={onChangeHandler}
                                        />
                                    </div>
                                </div> {/* .row */}
                                <div className="row">
                                    <div className="label" style={{width: "136px"}}>
                                        City *:
                                    </div>
                                    <div className="input">
                                        <input
                                            type="text"
                                            style={{width: "338px"}}
                                            name="city"
                                            value={props.currentNewCenter.city}
                                            onChange={onChangeHandler}
                                        />
                                    </div>
                                </div> {/* .row */}
                                <div className="row">
                                    <div className="label" style={{width: "136px"}}>
                                        Post Code *:
                                    </div>
                                    <div className="input">
                                        <input
                                            type="text"
                                            style={{width: "131px"}}
                                            name="postcode"
                                            value={props.currentNewCenter.postcode}
                                            onChange={onChangeHandler}
                                        />
                                    </div>
                                </div> {/* .row */}
                                <div className="row">
                                    <div className="label" style={{width: "136px"}}>
                                        Country *:
                                    </div>
                                    <div className="input">
                                        <input
                                            type="text"
                                            style={{width: "338px"}}
                                            name="country"
                                            value={props.currentNewCenter.country}
                                            onChange={onChangeHandler}
                                        />
                                    </div>
                                </div> {/* .row */}
                                <div className="row">
                                    <div className="label" style={{width: "136px"}}>
                                        Website:
                                    </div>
                                    <div className="input">
                                        <input
                                            type="text"
                                            style={{width: "338px"}}
                                            name="website"
                                            value={props.currentNewCenter.website}
                                            onChange={onChangeHandler}
                                        />
                                    </div>
                                </div> {/* .row */}
                                <div className="row">
                                    <div className="label" style={{width: "136px"}}>
                                        E-mail:
                                    </div>
                                    <div className="input">
                                        <input
                                            type="text"
                                            style={{width: "338px"}}
                                            name="email"
                                            value={props.currentNewCenter.email}
                                            onChange={onChangeHandler}
                                        />
                                    </div>
                                </div> {/* .row */}
                                <div className="row">
                                    <div className="label" style={{width: "136px"}}>
                                        Telephone *:
                                    </div>
                                    <div className="input">
                                        <input
                                            type="text"
                                            style={{width: "131px"}}
                                            name="telephone"
                                            value={props.currentNewCenter.telephone}
                                            onChange={onChangeHandler}
                                        />
                                    </div>
                                </div> {/* .row */}
                                <div className="row">
                                    <div className="label" style={{width: "136px"}}>
                                        Fax:
                                    </div>
                                    <div className="input">
                                        <input
                                            type="text"
                                            style={{width: "131px"}}
                                            name="fax"
                                            value={props.currentNewCenter.fax}
                                            onChange={onChangeHandler}
                                        />
                                    </div>
                                </div> {/* .row */}

                                <br />

                                <div className="row">
                                    <div className="label" style={{width: "136px"}}>
                                        Observations:
                                    </div>
                                    <div className="input">
                                        <textarea
                                            className="no-required"
                                            type="text"
                                            style={{width: "328px"}}
                                            name="comment"
                                            value={props.currentNewCenter.comment}
                                            onChange={onChangeHandler}
                                        ></textarea>
                                    </div>
                                </div> {/* .row */}

                            </div> {/* .span5 */}
                        </div>
                    </div>
                </form>
            </div> {/* .modal-body */}
            <div className="modal-footer">
                <button
                    className="btn small orange align-center right"
                    onClick={confirmAddNewCenter}
                >Save</button>
                <button
                    className="btn small grey align-center right"
                    onClick={closePopup}
                >Close</button>
            </div> {/* .modal-body */}
        </div>

        <div className="modal-backdrop fade in"></div>
        </React.Fragment>
    );
}

function AddNewMonitorPopup(props) {
    const confirmAddNewMonitor = async () => {

        const errMsg = "Sorry, something went wrong when saving this center. Please contact your IT helpdesk.";
        let newMonitor = JSON.parse(JSON.stringify(props.currentNewMonitor));
        let editedUsers = JSON.parse(JSON.stringify(props.allUsers));
        let addErrors = [];

        newMonitor.role = 'monitor';

        let count = props.overlayVisible;
        props.setOverlayVisible( (count) => count + 1);

        // create new user
        await axios.post(process.env.REACT_APP_API_ENDPOINT+'/users/', {...newMonitor}, { withCredentials: true})
            .then((response) => {

            if (response.status === 201 && response.data && response.data.id) {

                newMonitor = response.data;
                editedUsers.push(newMonitor);
                props.setAllUsers(editedUsers);

                props.setCurrentMonitor(newMonitor);
                props.setAddNewMonitorPopupVisible(false);
                props.setAddMonitorPopupVisible(true);
            }
            else {
                alert(errMsg);
            }

            props.setOverlayVisible( (count) => count - 1);

        }, (error) => {

            if (error.response.data.hasOwnProperty("email")) {
                addErrors.push("Email is not a valid email address.");
            }

            if (error.response.data.hasOwnProperty("username")) {
                addErrors.push("The Username inserted already exists!");
            }

            props.setAddNewMonitorErrors(addErrors);
            props.setOverlayVisible( (count) => count - 1);
        });



    };

    const closePopup = () => {
        props.setAddNewMonitorPopupVisible(false);
        props.setCurrentNewMonitor({});
    };

    const onChangeHandler = (ev) => {
        let val = ev.target.value;
        let name = ev.target.name;

        props.setCurrentNewMonitor({...props.currentNewMonitor, [name]: val});
    };

    const submitHandler = e => {
        e.preventDefault();
    };

    return (
        <React.Fragment>
        <div className="modal fade in">
            <div className="modal-header">
                <h3>Add new monitor</h3>
            </div>
            <div className="modal-body">
                <form onSubmit={submitHandler}>
                    <div className="modal-form">
                        <div className="form-group">

                            {props.addNewMonitorErrors.length > 0 && (
                            <div className="modal-errors">
                                <div className="errorSummary">
                                    <p>Please fix the following input errors:</p>
                                    <ul>
                                        {props.addNewMonitorErrors.map((message) => (
                                        <li key={message}>{message}</li>
                                        ))}
                                    </ul>
                                </div>
                            </div>
                            )}

                            <div className="span5" style={{width: "100%"}}>
                                <div className="row">
                                    <div className="label" style={{width: "216px"}}>
                                        Username:
                                    </div>
                                    <div className="input">
                                        <input
                                            type="text"
                                            style={{width: "282px"}}
                                            name="username"
                                            value={props.currentNewMonitor.username}
                                            onChange={onChangeHandler}
                                        />
                                    </div>
                                </div> {/* .row */}

                                <div style={{width: "100%", marginBottom: "15px", height: "15px", borderBottom: "1px solid #eee"}}></div>

                                <div className="row">
                                    <div className="label" style={{width: "216px"}}>
                                        E-mail:
                                    </div>
                                    <div className="input">
                                        <input
                                            type="text"
                                            style={{width: "282px"}}
                                            name="email"
                                            value={props.currentNewMonitor.email}
                                            onChange={onChangeHandler}
                                        />
                                    </div>
                                </div> {/* .row */}

                            </div> {/* .span5 */}
                        </div>
                    </div>
                </form>
            </div> {/* .modal-body */}
            <div className="modal-footer">
                <button
                    className="btn small orange align-center right"
                    onClick={confirmAddNewMonitor}
                >Save</button>
                <button
                    className="btn small grey align-center right"
                    onClick={closePopup}
                >Close</button>
            </div> {/* .modal-body */}
        </div>

        <div className="modal-backdrop fade in"></div>
        </React.Fragment>
    );
}

function AddNewGraderPopup(props) {
    const confirmAddNewGrader = async () => {

        const errMsg = "Sorry, something went wrong when saving this grader. Please contact your IT helpdesk.";
        let newGrader = JSON.parse(JSON.stringify(props.currentNewGrader));
        let editedUsers = JSON.parse(JSON.stringify(props.allUsers));
        let addErrors = [];

        newGrader.role = 'grader';
        newGrader.name = newGrader.name.trim();
        newGrader.username = newGrader.username.trim();

        let count = props.overlayVisible;
        props.setOverlayVisible( (count) => count + 1);

        // create new user
        await axios.post(process.env.REACT_APP_API_ENDPOINT+'/users/', {...newGrader}, { withCredentials: true})
            .then((response) => {

            if (response.status === 201 && response.data && response.data.id) {

                newGrader = response.data;
                editedUsers.push(newGrader);
                props.setAllUsers(editedUsers);

                props.setCurrentGrader({...newGrader, exams: []});
                props.setAddNewGraderPopupVisible(false);
                props.setAddGraderPopupVisible(true);

            }
            else {
                alert(errMsg);
            }

            props.setOverlayVisible( (count) => count - 1);

        }, (error) => {

            if (error.response.data.hasOwnProperty("email")) {
                addErrors.push("Email is not a valid email address.");
            }

            if (error.response.data.hasOwnProperty("username")) {
                addErrors.push("The Username inserted already exists!");
            }

            props.setOverlayVisible( (count) => count - 1);
            props.setAddNewGraderErrors(addErrors);

        });


    };

    const closePopup = () => {
        props.setAddNewGraderPopupVisible(false);
        props.setCurrentNewGrader({});
    };

    const onChangeHandler = (ev) => {
        let val = ev.target.value;
        let name = ev.target.name;

        props.setCurrentNewGrader({...props.currentNewGrader, [name]: val});
    };

    const submitHandler = e => {
        e.preventDefault();
    };

    return (
        <React.Fragment>
        <div className="modal fade in">
            <div className="modal-header">
                <h3>
                    Add New Grader
                </h3>
            </div>
            <div className="modal-body">
                <form onSubmit={submitHandler}>
                    <div className="modal-form">
                        <div className="form-group">

                            {props.addNewGraderErrors.length > 0 && (
                            <div className="modal-errors">
                                <div className="errorSummary">
                                    <p>Please fix the following input errors:</p>
                                    <ul>
                                        {props.addNewGraderErrors.map((message) => (
                                        <li key={message}>{message}</li>
                                        ))}
                                    </ul>
                                </div>
                            </div>
                            )}

                            <div className="span5" style={{width: "100%"}}>
                                <div className="row">
                                    <div className="label" style={{width: "216px"}}>
                                        Username:
                                    </div>
                                    <div className="input">
                                        <input
                                            type="text"
                                            style={{width: "282px"}}
                                            name="username"
                                            value={props.currentNewGrader.username}
                                            onChange={onChangeHandler}
                                        />
                                    </div>
                                </div> {/* .row */}

                                <div style={{width: "100%", marginBottom: "15px", height: "15px", borderBottom: "1px solid #eee"}}></div>

                                <div className="row">
                                    <div className="label" style={{width: "216px"}}>
                                        E-mail:
                                    </div>
                                    <div className="input">
                                        <input
                                            type="text"
                                            style={{width: "282px"}}
                                            name="email"
                                            value={props.currentNewGrader.email}
                                            onChange={onChangeHandler}
                                        />
                                    </div>
                                </div> {/* .row */}

                            </div> {/* .span5 */}
                        </div>
                    </div>
                </form>
            </div> {/* .modal-body */}
            <div className="modal-footer">
                <button
                    className="btn small orange align-center right"
                    onClick={confirmAddNewGrader}
                >Save</button>
                <button
                    className="btn small grey align-center right"
                    onClick={closePopup}
                >Close</button>
            </div> {/* .modal-body */}
        </div>

        <div className="modal-backdrop fade in"></div>
        </React.Fragment>
    );
}

function AddNewTechnicianPopup(props) {


    const confirmAddNewTechnician = async () => {

        const errMsg = "Sorry, something went wrong when saving this technician. Please contact your IT helpdesk.";
        let newTech = JSON.parse(JSON.stringify(props.currentNewTechnician));
        let editedUsers = JSON.parse(JSON.stringify(props.allUsers));
        const centerUsers = editedUsers.filter(u => u.center && u.center.id === newTech.center.id && u.role === 'tech');
        let addErrors = [];

        newTech.role = 'tech';
        newTech.name = newTech.name.trim();
        newTech.username = newTech.username.trim();

        let count = props.overlayVisible;
        props.setOverlayVisible( (count) => count + 1);

        // create new user
        await axios.post(process.env.REACT_APP_API_ENDPOINT+'/users/', {...newTech}, { withCredentials: true})
            .then((response) => {

            if (response.status === 201 && response.data && response.data.id) {

                newTech = response.data;
                editedUsers.push(newTech);
                props.setAllUsers(editedUsers);

                props.setAddNewTechnicianPopupVisible(false);
                props.setAddTechnicianPopupVisible(true);

                props.setCurrentTechnician({...newTech, exams: []});
                props.setCenterTechs(centerUsers);

            }
            else {
                alert(errMsg);
            }

            props.setOverlayVisible( (count) => count - 1);

        }, (error) => {

            if (error.response.data.hasOwnProperty("email")) {
                addErrors.push("Email is not a valid email address.");
            }

            if (error.response.data.hasOwnProperty("username")) {
                addErrors.push("The Username inserted already exists!");
            }

            props.setOverlayVisible( (count) => count - 1);
            props.setAddNewTechnicianErrors(addErrors);

        });


    };


    const closePopup = () => {
        props.setAddNewTechnicianPopupVisible(false);
        props.setCurrentNewTechnician({});
    };

    const onChangeHandler = (ev) => {
        let val = ev.target.value.trim();
        let name = ev.target.name;

        props.setCurrentNewTechnician({...props.currentNewTechnician, [name]: val});
    };

    const onChangeCenter = val => {
        let center = {id: parseInt(val, 10)}

        props.setCurrentNewTechnician({...props.currentNewTechnician, center: center});
    };

    const centersOptions = [];
    props.study.centers.map(center => {
        centersOptions.push({label: center.center.acronym, value: center.center.id});
        return false;
    });

    const submitHandler = e => {
        e.preventDefault();
    };

    return (
        <React.Fragment>
        <div className="modal fade in">
            <div className="modal-header">
                <h3>
                    Add New Technician
                </h3>
            </div>
            <div className="modal-body">
                <form onSubmit={submitHandler}>
                    <div className="modal-form">
                        <div className="form-group">


                            {props.addNewTechnicianErrors.length > 0 && (

                            <div className="modal-errors" style={{marginLeft: "15px"}}>
                                <div className="errorSummary"><p>Please fix the following input errors:</p>
                                    <ul>
                                        {props.addNewTechnicianErrors.map((message) => (
                                        <li key={message}>{message}</li>
                                        ))}
                                    </ul>
                                </div>
                            </div>

                            )}

                            <div className="span5" style={{width: "100%"}}>
                                <div className="row">
                                    <div className="label" style={{width: "210px"}}>
                                        Pick Clinical Site:
                                    </div>
                                    <div className="input">
                                        <CorcSelect
                                            id="center"
                                            name="center"
                                            containerStyles={{width: "304px"}}
                                            onChange={selectedOption => onChangeCenter(selectedOption.value)}
                                            value={centersOptions.filter(c => c.value === props.currentNewTechnician.center.id)}
                                            options={centersOptions}
                                            isSearchable={false}
                                            placeholder="Select clinical site"
                                        />
                                    </div>
                                </div> {/* .row */}

                                <div className="row">
                                    <div className="label" style={{width: "216px"}}>
                                        Username:
                                    </div>
                                    <div className="input">
                                        <input
                                            type="text"
                                            style={{width: "282px"}}
                                            name="username"
                                            value={props.currentNewTechnician.username}
                                            onChange={onChangeHandler}
                                        />
                                    </div>
                                </div> {/* .row */}

                                <div style={{width: "100%", marginBottom: "15px", height: "15px", borderBottom: "1px solid #eee"}}></div>

                                <div className="row">
                                    <div className="label" style={{width: "216px"}}>
                                        E-mail:
                                    </div>
                                    <div className="input">
                                        <input
                                            type="text"
                                            style={{width: "282px"}}
                                            name="email"
                                            value={props.currentNewTechnician.email}
                                            onChange={onChangeHandler}
                                        />
                                    </div>
                                </div> {/* .row */}

                            </div> {/* .span5 */}
                        </div>
                    </div>
                </form>
            </div> {/* .modal-body */}
            <div className="modal-footer">
                <button
                    className="btn small orange align-center right"
                    onClick={confirmAddNewTechnician}
                >Save</button>
                <button
                    className="btn small grey align-center right"
                    onClick={closePopup}
                >Close</button>
            </div> {/* .modal-body */}
        </div>

        <div className="modal-backdrop fade in"></div>
        </React.Fragment>
    );
}

function AddNewCoordinatorPopup(props) {


    const confirmAddNewCoordinator = async () => {

        const errMsg = "Sorry, something went wrong when saving this coordinator. Please contact your IT helpdesk.";
        let newCoord = JSON.parse(JSON.stringify(props.currentNewCoordinator));
        let editedUsers = JSON.parse(JSON.stringify(props.allUsers));
        const centerUsers = editedUsers.filter(u => u.center && u.center.id === newCoord.center.id && u.role === 'coordinator');
        let addErrors = [];

        newCoord.role = 'coordinator';
        newCoord.name = newCoord.name.trim();
        newCoord.username = newCoord.username.trim();

        let count = props.overlayVisible;
        props.setOverlayVisible( (count) => count + 1);

        // create new user
        await axios.post(process.env.REACT_APP_API_ENDPOINT+'/users/', {...newCoord}, { withCredentials: true})
            .then((response) => {

            if (response.status === 201 && response.data && response.data.id) {

                newCoord = response.data;
                editedUsers.push(newCoord);
                props.setAllUsers(editedUsers);

                props.setAddNewCoordinatorPopupVisible(false);
                props.setAddCoordinatorPopupVisible(true);

                props.setCurrentCoordinator({...newCoord});
                props.setCenterCoords(centerUsers);

            }
            else {
                alert(errMsg);
            }

            props.setOverlayVisible( (count) => count - 1);

        }, (error) => {

            if (error.response.data.hasOwnProperty("email")) {
                addErrors.push("Email is not a valid email address.");
            }

            if (error.response.data.hasOwnProperty("username")) {
                addErrors.push("The Username inserted already exists!");
            }

            props.setAddNewCoordinatorErrors(addErrors);
            props.setOverlayVisible( (count) => count - 1);
        });


    };


    const closePopup = () => {
        props.setAddNewCoordinatorPopupVisible(false);
        props.setCurrentNewCoordinator({});
    };

    const onChangeHandler = (ev) => {
        let val = ev.target.value.trim();
        let name = ev.target.name;

        props.setCurrentNewCoordinator({...props.currentNewCoordinator, [name]: val});
    };

    const onChangeCenterHandler = val => {
        props.setCurrentNewCoordinator({...props.currentNewCoordinator, center: {id: parseInt(val, 10)}});
    };

    const centersOptions = [];
    props.study.centers.map((center, i) => {
        centersOptions.push({label: center.center.acronym, value: center.center.id})
        return false;
    });

    const submitHandler = e => {
        e.preventDefault();
    };

    return (
        <React.Fragment>
        <div className="modal fade in">
            <div className="modal-header">
                <h3>
                    Add New Coordinator
                </h3>
            </div>
            <div className="modal-body">
                <form onSubmit={submitHandler}>
                    <div className="modal-form">
                        <div className="form-group">


                            {props.addNewCoordinatorErrors.length > 0 && (

                            <div className="modal-errors" style={{marginLeft: "15px"}}>
                                <div className="errorSummary"><p>Please fix the following input errors:</p>
                                    <ul>
                                        {props.addNewCoordinatorErrors.map((message) => (
                                        <li key={message}>{message}</li>
                                        ))}
                                    </ul>
                                </div>
                            </div>

                            )}

                            <div className="span5" style={{width: "100%"}}>
                                <div className="row">
                                    <div className="label" style={{width: "216px"}}>
                                        Pick Clinical Site:
                                    </div>
                                    <div className="input">
                                        <CorcSelect
                                            id="center"
                                            name="center"
                                            containerStyles={{width: "297px"}}
                                            onChange={selectedOption => onChangeCenterHandler(selectedOption.value)}
                                            value={centersOptions.filter(c => c.value === props.currentNewCoordinator.center.id)}
                                            isSearchable={false}
                                            placeholder="Select Clinical Site"
                                            options={centersOptions}
                                        />
                                    </div>
                                </div> {/* .row */}

                                <div className="row">
                                    <div className="label" style={{width: "216px"}}>
                                        Username:
                                    </div>
                                    <div className="input">
                                        <input
                                            type="text"
                                            style={{width: "282px"}}
                                            name="username"
                                            value={props.currentNewCoordinator.username}
                                            onChange={onChangeHandler}
                                        />
                                    </div>
                                </div> {/* .row */}

                                <div style={{width: "100%", marginBottom: "15px", height: "15px", borderBottom: "1px solid #eee"}}></div>

                                <div className="row">
                                    <div className="label" style={{width: "216px"}}>
                                        E-mail:
                                    </div>
                                    <div className="input">
                                        <input
                                            type="text"
                                            style={{width: "282px"}}
                                            name="email"
                                            value={props.currentNewCoordinator.email}
                                            onChange={onChangeHandler}
                                        />
                                    </div>
                                </div> {/* .row */}

                            </div> {/* .span5 */}
                        </div>
                    </div>
                </form>
            </div> {/* .modal-body */}
            <div className="modal-footer">
                <button
                    className="btn small orange align-center right"
                    onClick={confirmAddNewCoordinator}
                >Save</button>
                <button
                    className="btn small grey align-center right"
                    onClick={closePopup}
                >Close</button>
            </div> {/* .modal-body */}
        </div>

        <div className="modal-backdrop fade in"></div>
        </React.Fragment>
    );
}

function ChangeUserViewPermsPopup(props) {
    const closePopup = () => {
        props.setActiveUserViewPerms({});
        props.setChangeUserViewPermsPopupVisible(false);
    }

    const confirmChangePerm = async () => {
        const errMsg = "Sorry, something went wrong when changing this user's view permission. Please contact your IT helpdesk.";

        let count = props.overlayVisible;
        props.setOverlayVisible( (count) => count + 1);

        await axios.put(process.env.REACT_APP_API_ENDPOINT+'/studies/'+props.study.id+'/change_user_view_permission/', {"user_id": props.activeUserViewPerms.id, "role": props.activeUserViewPerms.user.role, "permission": props.userViewPerm }, { withCredentials: true})
            .then((response) => {

            props.setOverlayVisible( (count) => count - 1);
            if (response.status === 200) {
                let editedObj, editedType = '';
                if(props.activeUserViewPerms.user.role === 'tech') {
                    editedObj = JSON.parse(JSON.stringify(props.study.technicians));
                    editedType = 'technicians';
                }
                else if(props.activeUserViewPerms.user.role === 'coordinator') {
                    editedObj = JSON.parse(JSON.stringify(props.study.coordinators));
                    editedType = 'coordinators';
                }
                else if(props.activeUserViewPerms.user.role === 'monitor') {
                    editedObj = JSON.parse(JSON.stringify(props.study.monitors));
                    editedType = 'monitors';
                }
                else if(props.activeUserViewPerms.user.role === 'grader') {
                    editedObj = JSON.parse(JSON.stringify(props.study.graders));
                    editedType = 'graders';
                }

                let editedUser = editedObj.find(u => u.id === props.activeUserViewPerms.id);
                editedUser.has_view_permission = props.userViewPerm;

                props.setStudy({...props.study, [editedType]: editedObj});

                props.setChangeUserViewPermsPopupVisible(false);
                props.setActiveUserViewPerms({});
                props.setUserViewPerm(false);
            }
            else {
                alert(errMsg);
            }


        }, (error) => {
            alert(errMsg);
            props.setOverlayVisible( (count) => count - 1);
        });


    };

    return (
        <React.Fragment>
        <div className="modal fade in">
            <div className="modal-header">
                <h3>Confirmation</h3>
            </div>
            <div className="modal-body">
                <br />
                <p>Do you really want to {props.userViewPerm ? 'add' : 'remove'} view permissions to this user?</p>
                <br />
            </div> {/* .modal-body */}
            <div className="modal-footer">
                <button
                    className="btn small orange align-center right"
                    onClick={confirmChangePerm}
                >Yes
                </button>
                <button
                    className="btn small grey align-center right"
                    onClick={closePopup}
                >No
                </button>
            </div>
        </div>
        <div className="modal-backdrop fade in"></div>
        </React.Fragment>
    );
}
