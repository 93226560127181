import React, {useEffect, useState} from 'react';
import axios from 'axios';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import {initDate} from 'components/utils/Helpers.js';

function RegisterUser(props) {

    useEffect(function() {

        const validateToken = async () => {
            let count = props.overlayVisible;
            props.setOverlayVisible(count => count + 1);

            await axios.get(process.env.REACT_APP_API_ENDPOINT+'/users/register/'+props.match.params.token+'/' ).then((response) => {
                props.setOverlayVisible(count => count - 1);
                if(response.status !== 200) {
                    window.location = '/';
                }
            }, (error) => {
                props.setOverlayVisible(count => count - 1);
                window.location = '/';
            });
        }

        validateToken();
    }, []);

    const passwordValidator = require('password-validator');
    let passwordSchema = new passwordValidator();
    passwordSchema.is().min(5).has().lowercase().has().digits().has().symbols()

    const [user, setUser] = useState({
        name: '',
        last_name: '',
        password: '',
        confirm_password: '',
        birth_date: '',
        city: '',
        address: '',
        postcode: '',
        country: '',
        phone: '',
    });

    const [errorFields, setErrorFields] = useState([])

    const getErrorClass = (type) => {

        if (errorFields.indexOf(type) !== -1) {
            return "error";
        }

        return "";
    };

    const submitRegistration = async () => {
        const errMsg = "Sorry, something went wrong when registering this user. Please contact your IT helpdesk.";
        let editedUser;

        editedUser = validateUser(user);

        if (editedUser !== false) {
            let count = props.overlayVisible;
            props.setOverlayVisible(count => count + 1);

            await axios.post(process.env.REACT_APP_API_ENDPOINT+'/users/register/'+props.match.params.token+'/', {...editedUser})
                .then((response) => {
                props.setOverlayVisible(count => count - 1);

                if (response.status === 200) {
                    window.location = '/';
                }
                else {
                    alert(errMsg);
                }

            }, (error) => {
                alert(errMsg);
                props.setOverlayVisible(count => count - 1);
            });
        }
    };

    const validateUser = () => {
        let errorFields = [];
        let editedUser = JSON.parse(JSON.stringify(user));

        // validate required fields
        editedUser.name = editedUser.name.trim();
        editedUser.last_name = editedUser.last_name.trim();
        editedUser.birth_date = editedUser.birth_date ? editedUser.birth_date.trim() : null;
        if (!editedUser.birth_date) {
            editedUser.birth_date = null;
        }
        editedUser.address = editedUser.address.trim();
        editedUser.city = editedUser.city.trim();
        editedUser.postcode = editedUser.postcode.trim();
        editedUser.country = editedUser.country.trim();
        editedUser.phone = editedUser.phone.trim();
        editedUser.password = editedUser.password.trim();
        editedUser.confirm_password = editedUser.confirm_password.trim();

        if (!editedUser.name) {
            errorFields.push('name');
        }

        if (!editedUser.last_name) {
            errorFields.push('last_name');
        }

        if(!editedUser.password) {
            errorFields.push('password');
        }

        if(!editedUser.confirm_password || editedUser.password !== editedUser.confirm_password) {
            errorFields.push('confirm_password');
        }

        if (!passwordSchema.validate(editedUser.password)) {
            errorFields.push('invalid_password');
        }

        if (!editedUser.country) {
            errorFields.push('country');
        }

        if (!editedUser.phone) {
            errorFields.push('phone');
        }

        setUser({...editedUser});
        setErrorFields(errorFields);

        if (errorFields.length) {
            return false;
        }

      return editedUser;
    };

    const handleSubmit = (e) => {
        e.preventDefault();
    };

    const onChangeHandler = ev => {
        let name = ev.target.name;
        let value = ev.target.value;

        let editedUser = JSON.parse(JSON.stringify(user));
        setUser({...editedUser, [name]: value});
    }

    const birthDateChangeHandler = (date) => {
        let txtDate = '';

        if (date) {
            txtDate = date.getFullYear() + '-' + (date.getMonth()+1) + '-' + date.getDate();
            setUser({...user, 'birth_date': txtDate});
        }
    };

    return (
        <React.Fragment>
        <div id="home_header_container" className="home_header_container_class home">
            <div id="home_header_middle_container">
                <img alt="logo" id="logo_home" src={require("images/logo_home.png")} />
            </div>
        </div>
        <div className="form-recover">
            <form id="recover_form" onSubmit={handleSubmit} method="post">
                <div className="form-row">
                    <div className="label">
                    </div>
                    <div className="input">
                        <h1>Register User</h1>
                    </div>
                </div>
                <div className="form-row">
                    <div className="label">
                        <label
                            htmlFor="name"
                            className={"required " + getErrorClass('name')}
                        >Name *</label>
                    </div>
                    <div className="input">
                        <input
                            className={"large_text_box_class " + getErrorClass('name')}
                            name="name"
                            id="name"
                            type="text"
                            onChange={onChangeHandler}
                        />
                        <span className="error">{getErrorClass('name') ? 'Name cannot be blank.' : ''}</span>
                    </div>
                </div>
                <div className="form-row">
                    <div className="label">
                        <label
                            className={"required " + getErrorClass('last_name')}
                            htmlFor="last_name"
                        >Last Name *</label>
                    </div>
                    <div className="input">
                        <input
                            className={"text_box_class " + getErrorClass('last_name')}
                            name="last_name"
                            id="last_name"
                            type="text"
                            onChange={onChangeHandler}
                        />
                        <span className="error">{getErrorClass('last_name') ? 'Last name cannot be blank.' : ''}</span>
                    </div>
                </div>
                <div className="form-row-space"></div>
                <div className="form-row">
                    <div className="label">
                        <label
                            className={"required " + getErrorClass('password')}
                            htmlFor="password"
                        >Password *</label>
                    </div>
                    <div className="input">
                        <input
                            className={"text_box_class " + getErrorClass('password')}
                            name="password"
                            id="password"
                            type="password"
                            onChange={onChangeHandler}
                        />
                        <span className="error">{getErrorClass('password') ? 'Password cannot be blank.' : ''}</span>
                        <span className="error">{getErrorClass('invalid_password') ? 'Your password must have at least 5 characters combining letters, numbers and symbols/special characters (#,@,&,%,/,*, etc).' : ''}</span>


                    </div>
                </div>
                <div className="form-row">
                    <div className="label">
                        <label
                            htmlFor="password"
                            className={"required " + getErrorClass('confirm_password')}
                        >Confirm Password *</label>
                    </div>
                    <div className="input">
                        <input
                            className={"text_box_class " + getErrorClass('confirm_password')}
                            name="confirm_password"
                            id="confirm_password"
                            type="password"
                            onChange={onChangeHandler}
                        />
                        <span className="error">{getErrorClass('confirm_password') ? 'Confirmation and password must be the same.' : ''}</span>
                    </div>
                </div>
                <div className="form-row-space"></div>
                <div className="form-row">
                    <div className="label">
                        <label htmlFor="birth_date">Date of Birth</label>
                    </div>
                    <div className="input">
                        <DatePicker
                            dateFormat="dd/MM/yyyy"
                            id="birth_date"
                            name="birth_date"
                            className="text_box_class"
                            selected={user.birth_date && initDate(user.birth_date)}
                            onChange={birthDateChangeHandler}
                        />
                    </div>
                </div>
                <div className="form-row">
                    <div className="label">
                        <label htmlFor="address">Address</label>
                    </div>
                    <div className="input">
                        <input
                            className="large_text_box_class"
                            name="address"
                            id="address"
                            type="text"
                            onChange={onChangeHandler}
                        />
                    </div>
                </div>
                <div className="form-row">
                    <div className="label">
                        <label htmlFor="city">City</label>
                    </div>
                    <div className="input">
                        <input
                            className="text_box_class"
                            name="city"
                            id="city"
                            type="text"
                            onChange={onChangeHandler}
                        />
                    </div>
                </div>
                <div className="form-row">
                    <div className="label">
                        <label htmlFor="postcode">Post Code</label>
                    </div>
                    <div className="input">
                        <input
                            className="text_box_class"
                            name="postcode"
                            id="postcode"
                            type="text"
                            onChange={onChangeHandler}
                        />
                    </div>
                </div>
                <div className="form-row">
                    <div className="label">
                        <label
                            htmlFor="Country"
                            className={"required " + getErrorClass('country')}
                        >Country *</label>
                    </div>
                    <div className="input">
                        <input
                            className={"text_box_class " + getErrorClass('country')}
                            name="country"
                            id="country"
                            type="text"
                            onChange={onChangeHandler}
                        />
                        <span className="error">{getErrorClass('country') ? 'Country cannot be blank.' : ''}</span>
                    </div>
                </div>
                <div className="form-row">
                    <div className="label">
                        <label
                            className={"required " + getErrorClass('country')}
                            htmlFor="phone"
                        >Telephone *</label>
                    </div>
                    <div className="input">
                        <input
                            className={"text_box_class " + getErrorClass('phone')}
                            name="phone"
                            id="phone"
                            type="text"
                            onChange={onChangeHandler}
                         />
                        <span className="error">{getErrorClass('phone') ? 'Phone cannot be blank.' : ''}</span>
                    </div>
                </div>
                <div className="form-row">
                    <div className="label"></div>
                    <div className="input">
                        <input
                            id="recover_button"
                            className="submit_button_class"
                            type="submit"
                            value="Confirm"
                            onClick={submitRegistration}
                        />
                    </div>
                </div>
            </form>
        </div>
        </React.Fragment>
    );
}

export default RegisterUser;
