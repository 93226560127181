import React, {useEffect, useState}  from 'react';
import axios from 'axios';
import {Link} from 'react-router-dom';
import {formatDate, initDate} from 'components/utils/Helpers.js';
const prettyBytes = require('pretty-bytes');

function PatientsVisits(props) {

    useEffect(function() {
        const getPatient = async () => {
            let count = props.overlayVisible;
            props.setOverlayVisible( (count) => count + 1);

            await axios.get(process.env.REACT_APP_API_ENDPOINT+'/patients/'+props.match.params.patient+'/exams/'+props.match.params.exam+'/',
                { withCredentials: true, headers: {'Content-Type': 'application/json'}}).then(response => {

                setPatient(response.data);
                props.setOverlayVisible( (count) => count - 1);

            }, (error) => {
                props.history.push('/studies/');
                props.setOverlayVisible( (count) => count - 1);
            });
        };

        props.setNavLinks([
            {title: 'Studies', url: '/studies/', icon: 'studies'},
            {title: 'Patients', url: '/studies/' + props.match.params.id + '/exams/'+props.match.params.exam+'/patients', icon: 'pacients'},
            {title: 'Visits', url: props.location.pathname, icon: 'visits'}
        ]);

        localStorage.setItem('corc_study_patients_visits_sortVisit', "");
        localStorage.setItem('corc_study_patients_visits_sortExamDate', "");
        localStorage.setItem('corc_study_patients_visits_sortScheduledDate', "");

        getPatient();
    }, []);

    const [confirmSubmitPopup, setConfirmSubmitPopup] = useState(false);
    const [examName, setExamName] = useState('');
    const [patient, setPatient] = useState({
        id: '',
        name: '',
        patient_num: '',
        submit_comment: '',
        basal_date: '',
        study_left_eye: false,
        study_right_eye: false,
        card_vars: [],
        visits: [],
        study: {},
        center: {},
    });

    useEffect(function() {
        getExamName();
    }, [patient]);

    const getExamName = () => {
        if(patient.study && patient.study.exams) {
            let exam = patient.study.exams.find(e => parseInt(e.id, 10) === parseInt(props.match.params.exam));

            setExamName(exam.exam_type.name)
        }
        return true;
    };

    return (

        <React.Fragment>

        {confirmSubmitPopup && (
            <React.Fragment>
            <div className="modal-backdrop fade in"></div>
            <div id="alertModal" className="modal hide fade in" tabIndex="-1" role="dialog" aria-labelledby="myModalLabel" aria-hidden="false" style={{display: "block"}}>
                <div className="modal-header">
                    <button type="button" className="close" data-dismiss="modal" aria-hidden="true">×</button>
                    <h3 id="alert-modal-title">Warning!</h3>
                </div>
                <div className="modal-body"><br/>The Exam in previous Visit is not yet Submited<br/><br/></div>
                <div className="modal-footer">
                    <button onClick={() => setConfirmSubmitPopup(false)} data-dismiss="modal" id="alert-modal-cancel" aria-hidden="true" className="btn small grey align-center right">OK</button>
                </div>
            </div>
            </React.Fragment>
        )}


        <div className="home_header_container_class wide">
            <div className="content">
                <div className="breadcrumbs">
                    <div className="path">
                        <ul>
                            <li><span className="orange text-shadow">{patient.study.acronym ? patient.study.acronym.toUpperCase() :  ''}</span></li>
                            <li className="separator text-shadow">&gt;</li>
                            <li><span className="orange text-shadow">{examName}</span></li>
                            <li className="separator text-shadow">&gt;</li>
                            <li><span className="orange text-shadow">Patient {patient.patient_num}</span></li>
                            <li className="separator text-shadow">&gt;</li>
                            <li>Visits</li>
                        </ul>
                    </div>
                    <div className="sub-menu">
                        <Link to={"/studies/"+props.match.params.id+"/exams/"+props.match.params.exam+'/patients/'+props.match.params.patient+'/visits/new'} className="btn normal grey right">
                            <span className="icon icon-list min-eye"></span>Unscheduled Visit
                        </Link>
                    </div>
                </div> {/* .breadcrumbs */}

                <VisitsList
                    visits={patient.visits}
                    patient={patient}
                    params={props.match.params}
                    setPatient={setPatient}
                    history={props.history}
                    setConfirmSubmitPopup={setConfirmSubmitPopup}
                />
            </div>
        </div>

        </React.Fragment>

    );
}

export default PatientsVisits;

function VisitsList(props) {

    const toggleVisitSort = () => {

        const visits = JSON.parse(JSON.stringify(props.patient.visits));
        let sort = localStorage.getItem('corc_study_patients_visits_sortVisit');

        if (sort === ">") {
            visits.sort((a, b) => {
                let aName = a.scheduled_visit ? a.scheduled_visit.name.toLowerCase() : '';
                let bName = b.scheduled_visit ? b.scheduled_visit.name.toLowerCase() : '';
                return aName < bName ? 1 : -1;
            });
            localStorage.setItem('corc_study_patients_visits_sortVisit', "<")
        }
        else {
            visits.sort((a, b) => {
                let aName = a.scheduled_visit ? a.scheduled_visit.name.toLowerCase() : '';
                let bName = b.scheduled_visit ? b.scheduled_visit.name.toLowerCase() : '';
                return aName > bName ? 1 : -1;
            });
            localStorage.setItem('corc_study_patients_visits_sortVisit', ">")
        }

        props.setPatient({...props.patient, visits: visits})
    };

    const toggleExamDateSort = () => {

        const visits = JSON.parse(JSON.stringify(props.patient.visits));
        let sort = localStorage.getItem('corc_study_patients_visits_sortExamDate');

        if (sort === ">") {

            visits.sort(function(a, b) {

                a = a.exam_date ? initDate(a.exam_date) : new Date(a.exam_date);
                b = b.exam_date ? initDate(b.exam_date) : new Date(b.exam_date);

                return (a < b ? 1 : -1);
            });

            localStorage.setItem('corc_study_patients_visits_sortExamDate', "<")
        }
        else {

            visits.sort(function(a, b) {
                a = a.exam_date ? initDate(a.exam_date) : new Date(a.exam_date);
                b = b.exam_date ? initDate(b.exam_date) : new Date(b.exam_date);

                return (a > b ? 1 : -1);
            });

            localStorage.setItem('corc_study_patients_visits_sortExamDate', ">")
        }

        props.setPatient({...props.patient, visits: visits})
    }

    const toggleScheduledDateSort = () => {

        const visits = JSON.parse(JSON.stringify(props.patient.visits));
        let sort = localStorage.getItem('corc_study_patients_visits_sortScheduledDate');

        if (sort === ">") {

            visits.sort(function(a, b) {
                a = a.scheduled_date ? initDate(a.scheduled_date) : new Date(a.scheduled_date);
                b = b.scheduled_date ? initDate(b.scheduled_date) : new Date(b.scheduled_date);

                return (a < b ? 1 : -1);

            });

            localStorage.setItem('corc_study_patients_visits_sortScheduledDate', "<")
        }
        else {

            visits.sort(function(a, b) {
                a = a.scheduled_date ? initDate(a.scheduled_date) : new Date(a.scheduled_date);
                b = b.scheduled_date ? initDate(b.scheduled_date) : new Date(b.scheduled_date);

                return (a > b ? 1 : -1);

            });

            localStorage.setItem('corc_study_patients_visits_sortScheduledDate', ">")
        }

        props.setPatient({...props.patient, visits: visits})
    }

    const toggleOperatorSort = () => {
        const visits = JSON.parse(JSON.stringify(props.patient.visits));
        let sort = localStorage.getItem('corc_study_patients_visits_sortOperator');

        if (sort === ">") {
            visits.sort((a, b) => {
                let aName = a.operator ? a.operator.username.toLowerCase() : '';
                let bName = b.operator ? b.operator.username.toLowerCase() : '';
                return aName < bName ? 1 : -1;
            });
            localStorage.setItem('corc_study_patients_visits_sortOperator', "<")
        }
        else {
            visits.sort((a, b) => {
                let aName = a.operator ? a.operator.username.toLowerCase() : '';
                let bName = b.operator ? b.operator.username.toLowerCase() : '';
                return aName > bName ? 1 : -1;
            });
            localStorage.setItem('corc_study_patients_visits_sortOperator', ">")
        }

        props.setPatient({...props.patient, visits: visits})
    };

    const toggleUploaderSort = () => {
        const visits = JSON.parse(JSON.stringify(props.patient.visits));
        let sort = localStorage.getItem('corc_study_patients_visits_sortUploader');

        if (sort === ">") {
            visits.sort((a, b) => {
                let aName = a.user_submitted ? a.user_submitted.username.toLowerCase() : '';
                let bName = b.user_submitted ? b.user_submitted.username.toLowerCase() : '';
                return aName < bName ? 1 : -1;
            });
            localStorage.setItem('corc_study_patients_visits_sortUploader', "<")
        }
        else {
            visits.sort((a, b) => {
                let aName = a.user_submitted ? a.user_submitted.username.toLowerCase() : '';
                let bName = b.user_submitted ? b.user_submitted.username.toLowerCase() : '';
                return aName > bName ? 1 : -1;
            });
            localStorage.setItem('corc_study_patients_visits_sortUploader', ">")
        }

        props.setPatient({...props.patient, visits: visits})
    };

    const toggleUploadDateSort = () => {};

    const toggleExamStatusSort = () => {
        const visits = JSON.parse(JSON.stringify(props.patient.visits));
        let sort = localStorage.getItem('corc_study_patients_visits_sortExamStatus');

        if (sort === ">") {
            visits.sort((a, b) => {
                let aName = a.exam_status.toLowerCase();
                let bName = b.exam_status.toLowerCase();
                return aName < bName ? 1 : -1;
            });
            localStorage.setItem('corc_study_patients_visits_sortExamStatus', "<")
        }
        else {
            visits.sort((a, b) => {
                let aName = a.exam_status.toLowerCase();
                let bName = b.exam_status.toLowerCase();
                return aName > bName ? 1 : -1;
            });
            localStorage.setItem('corc_study_patients_visits_sortExamStatus', ">")
        }

        props.setPatient({...props.patient, visits: visits})
    };

    const toggleCompletionSort = () => {
        const visits = JSON.parse(JSON.stringify(props.patient.visits));
        let sort = localStorage.getItem('corc_study_patients_visits_sortCompletion');

        if (sort === ">") {
            visits.sort((a, b) => {
                let aName = a.exam_status === 'submitted' || a.exam_status === 'pending' ? 'Incomplete' : 'Complete';
                let bName = b.exam_status === 'submitted' || b.exam_status === 'pending' ? 'Incomplete' : 'Complete';
                return aName < bName ? 1 : -1;
            });
            localStorage.setItem('corc_study_patients_visits_sortCompletion', "<")
        }
        else {
            visits.sort((a, b) => {
                let aName = a.exam_status === 'submitted' || a.exam_status === 'pending' ? 'Incomplete' : 'Complete';
                let bName = b.exam_status === 'submitted' || b.exam_status === 'pending' ? 'Incomplete' : 'Complete';
                return aName > bName ? 1 : -1;
            });
            localStorage.setItem('corc_study_patients_visits_sortCompletion', ">")
        }

        props.setPatient({...props.patient, visits: visits})
    };


    return (
        <div className="visits-list list">
            <div className="list-head">
                <table>
                    <tbody>
                        <tr>
                            <th width="9%">
                                <button onClick={toggleVisitSort}>Visit</button>
                            </th>
                            <th width="9%">
                                <button onClick={toggleExamDateSort}>Exam Date</button>
                            </th>
                            <th width="9.5%">
                                <button onClick={toggleScheduledDateSort}>Scheduled Date</button>
                            </th>
                            <th width="9%">Submitted Eyes</th>
                            <th width="10%">
                                <button onClick={toggleOperatorSort}>Operator</button>
                            </th>
                            <th width="9.5%">
                                <button onClick={toggleUploaderSort}>Uploader</button>
                            </th>
                            <th width="9%">
                                <button onClick={toggleUploadDateSort}>Upload Date</button>
                            </th>
                            <th width="10%">File name & size</th>
                            <th width="8%">
                                <button onClick={toggleExamStatusSort}>Exam Status</button>
                            </th>
                            <th>
                                <button onClick={toggleCompletionSort}>Completion</button>
                            </th>
                        </tr>
                    </tbody>
                </table>
            </div>
            <div className="list-body">
                <table id="list-table">
                    <tbody className="list-visits">
                        {props.patient.visits.map((visit, i) => (
                            <VisitItem
                                key={i}
                                visit={visit}
                                patient={props.patient}
                                params={props.params}
                                history={props.history}
                                visits={props.visits}
                                setConfirmSubmitPopup={props.setConfirmSubmitPopup}
                            />
                        ))}
                    </tbody>
                </table>
            </div>
        </div>
    );
}


function VisitItem(props) {
    const getStatusDisplay = status => {

        if (status === 'pending') {
            return 'Scheduled';
        }

        else if (status === 'missed') {
            return 'Missed';
        }

        else if (status === 'rejected') {
            return 'Rejected';
        }
        else if (status === 'received') {
            return 'Received';
        }
        else if (status === 'submitted') {
            return 'Submitted'
        }

        return '';
    }

    const submitExam = () => {

        let order, prevVisits;

        // check if we need to submit earlier exams first
        if (props.visit.scheduled_visit) {
            order = props.visit.scheduled_visit.order;

            // find earlier exams
            prevVisits = props.visits.filter(v => v.scheduled_visit && v.scheduled_visit.order < order && v.exam_status === 'pending');
            if (prevVisits.length) {
                props.setConfirmSubmitPopup(true);
                return;
            }
        }

        props.history.push('/studies/'+props.params.id+'/exams/' + props.params.exam + '/patients/'+ props.patient.id + '/visits/'+props.visit.id+'/submit');

    }

    return (
        <tr>
            <td width="9%">{props.visit.scheduled_visit ? props.visit.scheduled_visit.name : ''}</td>
            <td width="9%">{formatDate(props.visit.exam_date) || '--/--/--'}</td>
            <td width="9.5%">{formatDate(props.visit.scheduled_date) || '--/--/--'}</td>
            <td width="9%">
                <span className={"icon min-eye " + ( props.patient.study_right_eye ? "" : "enabled")}></span>
                <span className={"icon min-eye " + ( props.patient.study_left_eye ? "" : "enabled")}></span>
            </td>
            <td width="10%">{props.visit.operator && props.visit.operator.username ? props.visit.operator.username : ''}</td>
            <td width="9.5%">{props.visit.user_submitted && props.visit.user_submitted.username ? props.visit.user_submitted.username : ''}</td>
            <td width="9%">{props.visit.visit_files && props.visit.visit_files[0] ? formatDate(props.visit.visit_files[0].created_at, true) : ''}</td>
            <td width="10%">{props.visit.visit_files && props.visit.visit_files[0] ? props.visit.visit_files[0].name + " (" + prettyBytes(props.visit.visit_files[0].file.size && props.visit.visit_files[0].file.size ? props.visit.visit_files[0].file.size : 0) + ")" : ''}</td>
            <td width="8%">{getStatusDisplay(props.visit.exam_status)}</td>
            {(props.visit.exam_status === 'submitted' || props.visit.exam_status === 'pending') ? (
                <td width="8%" className="orange">Incomplete</td>
            ) : (
                <td width="8%">Complete</td>
            )}

            <td className="btn-list">
                {(props.visit.exam_status === 'pending' || props.visit.exam_status === 'rejected') && (
                    <button onClick={submitExam} className="btn small grey">
                        <span className="icon-list report"></span>Submit
                    </button>
                )}

                {props.visit.exam_status !== 'pending' && (
                <Link to={"/studies/"+props.params.id+"/exams/"+props.params.exam+"/patients/"+props.patient.id+"/visits/"+props.visit.id} className="btn small grey">
                    <span className="icon-list report"></span>Details
                </Link>
                )}



            </td>
        </tr>
    );
}
