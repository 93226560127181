import React, {useEffect, useState} from 'react';
import {Link} from 'react-router-dom';
import { CSVLink } from "react-csv";
import StudyListItem from './StudyListItem'
import axios from 'axios';
import jsPDF from "jspdf";
import "jspdf-autotable";

function Studies(props) {
    const userType = props.userAuth.user.role;
    const [studies, setStudies] = useState([]);
    const [initialStudies, setInitialStudies] = useState([]);
    const [openStudiesFilter, setOpenStudiesFilter] = useState("");
    const [closedStudiesFilter, setClosedStudiesFilter] = useState("");
    const [dataToCSV, setDataToCSV] = useState([]);

    useEffect(function() {

        const getStudies = async () => {
            let count = props.overlayVisible;
            props.setOverlayVisible( (count) => count + 1);

            await axios.get(process.env.REACT_APP_API_ENDPOINT+'/studies/', { withCredentials: true}).then(response => {

                const dataCSV = [];

                props.setOverlayVisible( (count) => count - 1);

                if (response.status === 200) {

                    setStudies(response.data);
                    setInitialStudies(response.data);

                    response.data.map(function(s) {

                        let me = JSON.parse(JSON.stringify(s));

                        me.acronym = me.acronym.toUpperCase();
                        me.status = me.status[0].toUpperCase() + me.status.substr(1);

                        dataCSV.push(me);
                        return false;
                    });

                    setDataToCSV(dataCSV);


                }

            }, (error) => {

                props.setOverlayVisible( (count) => count - 1);
                if (error.response.status === 401) {
                    props.resetAuth();
                    props.history.push('/login');
                }
            });
        }

        getStudies();
        props.setNavLinks([{title: 'Studies', url: '/studies/', icon: 'studies'}]);

        localStorage.setItem('corc_studies_status', '');
    }, []);


    return (
        <div className="home_header_container_class">
            <div className="content">
                <div className="breadcrumbs">
                    <div className="sub-menu">
                        <StudyListActions
                            initialStudies={initialStudies}
                            studies={studies}
                            setStudies={setStudies}
                            userType={userType}
                            setOpenStudiesFilter={setOpenStudiesFilter}
                            setClosedStudiesFilter={setClosedStudiesFilter}
                            openStudiesFilter={openStudiesFilter}
                            closedStudiesFilter={closedStudiesFilter}
                            dataToCSV={dataToCSV}
                        />
                    </div>
                </div>
                <div className="overview" style={{marginTop: "27px", marginBottom: "-15px"}}>
                    <h2 className="text-shadow">Studies Overview</h2>
                </div>

                <div className="list-studies">
                    {studies.map( (study, i) => (
                        <StudyListItem
                            key={i}
                            studyId={study.id}
                            acronym={study.acronym}
                            status={study.status}
                            totalPatients={study.total_patients}
                            totalFailure={study.total_failure}
                            totalEnrolled={study.total_enrolled}
                            totalVisits={study.total_visits}
                            totalOngoing={study.total_ongoing}
                            totalCompleted={study.total_completed}
                            totalDroppedOut={study.total_dropped_out}
                            totalEndpoint={study.total_endpoint}
                            requiresCertification={study.requires_certification}
                            {...props}
                        />
                    ))}
                </div>
            </div>
        </div>
    );
}



function StudyListActions(props) {

    const toggleOpenStudies = () => {

        props.setClosedStudiesFilter("");

        if (props.openStudiesFilter === "select") {
            props.setOpenStudiesFilter("");
            props.setStudies(props.initialStudies);
        }
        else {
            props.setOpenStudiesFilter("select");
            props.setStudies(props.initialStudies.filter(s => s.status === "open"));
        }

    }

    const toggleClosedStudies = () => {

        props.setOpenStudiesFilter("");

        if (props.closedStudiesFilter === "select") {
            props.setClosedStudiesFilter("");
            props.setStudies(props.initialStudies);
        }
        else {
            props.setClosedStudiesFilter("select");
            props.setStudies(props.initialStudies.filter(s => s.status === "closed"));
        }

    }

    const headersCSV = [
      { label: "Acronym", key: "acronym" },
      { label: "Status", key: "status" },
      { label: "Total Patients", key: "total_patients" },
      { label: "Total Visits", key: "total_visits" },
      { label: "Failure", key: "total_failure" },
      { label: "Ongoing", key: "total_ongoing" },
      { label: "Completed", key: "total_completed" },
      { label: "Dropped Out", key: "total_dropped_out" },
      { label: "Endpoint", key: "total_endpoint" },
      { label: "Enrolled", key: "total_enrolled" }
    ];

    const exportPDF = () => {

        const marginLeft = 40;
        const title = "";
        const doc = new jsPDF("landscape", "pt", "A4");

        doc.setFontSize(15);

        const headers = [["Acronym", "Status", "Total Patients", "Total Visits", "Failure", "Ongoing", "Completed", "Dropped Out", "Endpoint", "Enrolled"]];

        const data = props.dataToCSV.map(i=> [i.acronym, i.status, i.total_patients, i.total_visits, i.total_failure, i.total_ongoing, i.total_completed, i.total_dropped_out, i.total_endpoint, i.total_enrolled]);

        let content = {
          startY: 50,
          head: headers,
          body: data
        };

        doc.text(title, marginLeft, 40);
        doc.autoTable(content);
        doc.save("studies-export.pdf")
    }

    return (
        <React.Fragment>
            <button className={"btn normal grey left " + props.openStudiesFilter} onClick={toggleOpenStudies}>
                <span className="icon studies-small"></span>
                Open Studies
            </button>
            <button className={"btn normal grey left " + props.closedStudiesFilter} onClick={toggleClosedStudies}>
                <span className="icon studies-small"></span>
                Closed Studies
            </button>
        { props.userType === 'admin' && (
            <Link to="/studies/new" className="btn normal grey right">
                <span className="icon icon-list report"></span>
                Create new Study
            </Link>
        )}
        { props.userType !== 'tech' && props.userType !== 'coordinator' && (
            <React.Fragment>
            <CSVLink className="btn normal grey right" data={props.dataToCSV} headers={headersCSV} filename={"studies-export.csv"}>
                <span className="icon icon-list report"></span>
                Export to CSV
            </CSVLink>
            <button onClick={() => exportPDF()} className="btn normal grey right">
                <span className="icon icon-list report"></span>
                Export to PDF
            </button>
            </React.Fragment>
        )}
        </React.Fragment>
    );
}

export default Studies;


