import React, {useEffect, useState} from 'react';
//import {Link} from 'react-router-dom';
import CorcSelect from 'components/utils/CorcSelect';
import axios from 'axios';

function Settings(props) {
    const [exams, setExams] = useState([]);
    const [techVars, setTechVars] = useState([]);
    const [cardVars, setCardVars] = useState([]);

    useEffect(function () {
        
        const getExams = async () => {
            let count = props.overlayVisible;
            props.setOverlayVisible( (count) => count + 1);

            const items = await axios.get(process.env.REACT_APP_API_ENDPOINT+'/settings/examtypes/',
                { withCredentials: true, headers: {'Content-Type': 'application/json'}});

            setExams(items.data);
            props.setOverlayVisible( (count) => count - 1);
        };

        const getTechVars = async () => {
            let count = props.overlayVisible;
            props.setOverlayVisible( (count) => count + 1);

            const items = await axios.get(process.env.REACT_APP_API_ENDPOINT+'/settings/technicianformvars/',
                { withCredentials: true, headers: {'Content-Type': 'application/json'}});

            setTechVars(items.data);
            props.setOverlayVisible( (count) => count - 1);
        };

        const getCardVars = async () => {
            let count = props.overlayVisible;
            props.setOverlayVisible( (count) => count + 1);

            const items = await axios.get(process.env.REACT_APP_API_ENDPOINT+'/settings/patientcardvars/',
                { withCredentials: true, headers: {'Content-Type': 'application/json'}});

            setCardVars(items.data);
            props.setOverlayVisible( (count) => count - 1);
        };

        props.setNavLinks([{title: 'Settings', url: '/settings/', icon: 'study-details'}]);

        // do we have permissions?
        if (props.userAuth.user.role !== 'admin') {
            props.history.push('/studies');
            return;
        }

        getExams();
        getTechVars();
        getCardVars();

    }, []);

    const [currentExam, setCurrentExam] = useState({});
    const [currentTechVar, setCurrentTechVar] = useState({});
    const [currentCardVar, setCurrentCardVar] = useState({});

    const [addEditExamPopupVisible, setAddEditExamPopupVisible] = useState(false);
    const [addEditTechVarPopupVisible, setAddEditTechVarPopupVisible] = useState(false);
    const [addEditCardVarPopupVisible, setAddEditCardVarPopupVisible] = useState(false);
    const [hasOptions, setHasOptions] = useState(false);

    const [addEditExamErrors, setAddEditExamErrors] = useState([]);
    const [addEditTechVarErrors, setAddEditTechVarErrors] = useState([]);
    const [addEditCardVarErrors, setAddEditCardVarErrors] = useState([]);

    const [emptyNewExam, setEmptyNewExam] = useState({
        "id": 0,
        "name": "",
        "include_photo_fields": false,
        "include_photo_quality": false,
        "include_quality": false,
        "has_equipments_with_camera": false,
    });
    const [emptyNewTechVar, setEmptyNewTechVar] = useState({
        "id": 0,
        "name": "",
        "group": "other",
        "field_type": "input",
        "technician_vars_options": [{id: '', value: '', order: 0}],
        "exams": [],
    });
    const [emptyNewCardVar, setEmptyNewCardVar] = useState({
        "id": 0,
        "name": "",
        "group": "other",
        "field_type": "input",
        "card_vars_options": [{id: '', value: '', order: 0}],
    });

    const fieldGroupOptions = [
        {value:"general", label: "General"},
        {value:"other", label: "Other"},
    ];

    const fieldTypeOptions = [
        {value:"input", label: "Input"},
        {value:"date", label: "Date"},
        {value:"textarea", label: "Text"},
        {value:"eye", label: "Eyes"},
        {value:"list", label: "List"},
        {value:"check_one", label: "Check one"},
        {value:"check_many", label: "Check many"},
    ];

    const getFieldGroupDisplay = group => {
        if(group === 'general') {
            return 'General';
        }
        else if(group === 'other') {
            return 'Other';
        }
    };

    const getFieldTypeDisplay = field => {
        if(field === 'input') {
            return 'Input';
        }
        else if(field === 'date') {
            return 'Date';
        }
        else if(field === 'textarea') {
            return 'Text';
        }
        else if(field === 'eye') {
            return 'Eyes';
        }
        else if(field === 'list') {
            return 'List';
        }
        else if(field === 'check_one') {
            return 'Choose one';
        }
        else if(field === 'check_many') {
            return 'Choose many';
        }
    };

    return (
        <div className="home_header_container_class">
            <div className="content">
                <div className="settings">
                    <ExamTypesList
                        exams={exams}
                        setCurrentExam={setCurrentExam}
                        setAddEditExamPopupVisible={setAddEditExamPopupVisible}
                        emptyNewExam={emptyNewExam}
                    />

                    <TechVarsList
                        techVars={techVars}
                        getFieldTypeDisplay={getFieldTypeDisplay}
                        getFieldGroupDisplay={getFieldGroupDisplay}
                        setCurrentTechVar={setCurrentTechVar}
                        setAddEditTechVarPopupVisible={setAddEditTechVarPopupVisible}
                        emptyNewTechVar={emptyNewTechVar}
                        setHasOptions={setHasOptions}
                    />

                    <PatientCardVarsList
                        cardVars={cardVars}
                        getFieldTypeDisplay={getFieldTypeDisplay}
                        getFieldGroupDisplay={getFieldGroupDisplay}
                        setCurrentCardVar={setCurrentCardVar}
                        setAddEditCardVarPopupVisible={setAddEditCardVarPopupVisible}
                        emptyNewCardVar={emptyNewCardVar}
                        setHasOptions={setHasOptions}
                    />
                </div>

                {addEditExamPopupVisible && (
                    <AddEditExamTypePopup
                        setAddEditExamPopupVisible={setAddEditExamPopupVisible}
                        exams={exams}
                        setExams={setExams}
                        currentExam={currentExam}
                        setCurrentExam={setCurrentExam}
                        addEditExamErrors={addEditExamErrors}
                        userAuth={props.userAuth}
                        setAddEditExamErrors={setAddEditExamErrors}
                        overlayVisible={props.overlayVisible}
                        setOverlayVisible={props.setOverlayVisible}
                    />
                )}

                {addEditTechVarPopupVisible && (
                    <AddEditTechVarPopup
                        setAddEditTechVarPopupVisible={setAddEditTechVarPopupVisible}
                        techVars={techVars}
                        setTechVars={setTechVars}
                        currentTechVar={currentTechVar}
                        setCurrentTechVar={setCurrentTechVar}
                        addEditTechVarErrors={addEditTechVarErrors}
                        fieldTypeOptions={fieldTypeOptions}
                        fieldGroupOptions={fieldGroupOptions}
                        hasOptions={hasOptions}
                        setHasOptions={setHasOptions}
                        userAuth={props.userAuth}
                        setAddEditTechVarErrors={setAddEditTechVarErrors}
                        exams={exams}
                        overlayVisible={props.overlayVisible}
                        setOverlayVisible={props.setOverlayVisible}
                    />
                )}

                {addEditCardVarPopupVisible && (
                    <AddEditCardVarPopup
                        setAddEditCardVarPopupVisible={setAddEditCardVarPopupVisible}
                        cardVars={cardVars}
                        setCardVars={setCardVars}
                        currentCardVar={currentCardVar}
                        setCurrentCardVar={setCurrentCardVar}
                        addEditCardVarErrors={addEditCardVarErrors}
                        fieldTypeOptions={fieldTypeOptions}
                        fieldGroupOptions={fieldGroupOptions}
                        hasOptions={hasOptions}
                        setHasOptions={setHasOptions}
                        userAuth={props.userAuth}
                        setAddEditCardVarErrors={setAddEditCardVarErrors}
                        overlayVisible={props.overlayVisible}
                        setOverlayVisible={props.setOverlayVisible}
                    />
                )}
            </div>
        </div>
    );
}

export default Settings;

/*
 *
 * Exam types
 *
 */
function ExamTypesList(props) {
    const showAddExamTypePopup = () => {
        props.setCurrentExam(JSON.parse(JSON.stringify(props.emptyNewExam)));
        props.setAddEditExamPopupVisible(true);
    };

    return (
        <React.Fragment>
        <div className="breadcrumbs">
            <div className="path">
                <ul>
                    <li>Exam Types:</li>
                </ul>
            </div> {/* .path */}
            <div className="sub-menu">
                <button
                    className="btn normal grey align-center right"
                    onClick={showAddExamTypePopup}
                >Add exam type
                </button>
            </div>
        </div> {/* .breadcrumbs */}
        <div className="list visits-list">
            <div className="list-head">
                <table>
                    <tbody>
                        <tr>
                            <th width="20%">Exam type</th>
                            <th width="20%">Photo fields?</th>
                            <th width="20%">Photo quality?</th>
                            <th width="20%">Quality?</th>
                            <th width="16%">With camera?</th>
                            <th width="4%"></th>
                        </tr>
                    </tbody>
                </table>
            </div> {/* .list-head */}

            <div className="list-body">
                <table id="exams-table">
                    <tbody className="list-vists">
                        {props.exams.map((exam, i) => (
                        <ExamTypesListItem
                            key={i}
                            exam={exam}
                            setCurrentExam={props.setCurrentExam}
                            setAddEditExamPopupVisible={props.setAddEditExamPopupVisible}
                        />
                        ))}
                    </tbody>
                </table>
            </div> {/* .list-body */}
        </div>
        </React.Fragment>
    );
}

function ExamTypesListItem(props) {
    const showEditExamPopup = (exam) => {
        props.setCurrentExam(exam);
        props.setAddEditExamPopupVisible(true);
    };

    return (
        <tr>
            <td width="20%">{props.exam.name}</td>
            <td width="20%">{props.exam.include_photo_fields ? 'Yes' : 'No'}</td>
            <td width="20%">{props.exam.include_photo_quality ? 'Yes' : 'No'}</td>
            <td width="20%">{props.exam.include_quality ? 'Yes' : 'No'}</td>
            <td width="16%">{props.exam.has_equipments_with_camera ? 'Yes' : 'No'}</td>
            <td className="btn-list" width="4%" style={{position: "relative"}}>
                <ul style={{top: "0"}}>

                    <li>
                        <button
                            className="btn xsmall grey edit-user"
                            onClick={() => showEditExamPopup(props.exam)}
                        >
                        <span className="icon-list study-details-small" style={{marginLeft: "0"}}></span></button>
                    </li>
                    {/* <li> */}
                    {/*     <button */}
                    {/*         className="btn xsmall grey" */}
                    {/*         onClick={() => showDeleteExamPopup(props.exam)} */}
                    {/*     > */}
                    {/*         <span className="icon-list remove-small">&nbsp;</span> */}
                    {/*     </button> */}
                    {/* </li> */}
                </ul>
            </td>
        </tr>
    );
}

function AddEditExamTypePopup(props) {
    const closePopup = () => {
        props.setAddEditExamPopupVisible(false);
        props.setCurrentExam({});
    };

    const submitHandler = (e) => {
        e.preventDefault();
    };

    const confirmAddExam = async () => {

        const errMsg = "Sorry, something went wrong when saving this exam type. Please contact your IT helpdesk.";
        let editedExams = JSON.parse(JSON.stringify(props.exams));
        let newExam, tmp = JSON.parse(JSON.stringify(props.currentExam));
        let errors = [];

        // trim all fields before validating
        tmp.name = tmp.name.trim();

        // do we have requiredfields?
        if (!tmp.name) {
            errors.push('Name cannot be blank.');
        }

        if (errors.length) {
            props.setAddEditExamErrors(errors);
            return;
        }
        else {
            props.setAddEditExamErrors([]);
        }

        let count = props.overlayVisible;
        props.setOverlayVisible( (count) => count + 1);
        //we're editing
        if(tmp.id > 0) {

            await axios.patch(process.env.REACT_APP_API_ENDPOINT+'/settings/examtypes/'+tmp.id+'/', {...tmp}, { withCredentials: true})
                .then((response) => {
                    props.setOverlayVisible( (count) => count - 1);

                    if (response.status === 200 && response.data && response.data.id) {

                        newExam = response.data;

                        for(let i=0, len = editedExams.length; i<=len-1;++i) {
                            if(parseInt(editedExams[i].id, 10) === parseInt(newExam.id, 10) ) {
                                editedExams.splice(i, 1, newExam);
                                break;
                            }
                        }
                        props.setExams(editedExams);

                        props.setAddEditExamPopupVisible(false);

                    }
                    else {
                        alert(errMsg);
                    }

                }, (error) => {
                    props.setOverlayVisible( (count) => count - 1);
                    alert(errMsg);
                });
        }
        else { //we're adding
            await axios.post(process.env.REACT_APP_API_ENDPOINT+'/settings/examtypes/', {...tmp}, { withCredentials: true})
                .then((response) => {
                    props.setOverlayVisible( (count) => count - 1);

                    if (response.status === 201 && response.data && response.data.id) {

                        newExam = response.data;

                        editedExams.push(newExam);
                        props.setExams(editedExams);

                        props.setAddEditExamPopupVisible(false);

                    }
                    else {
                        alert(errMsg);
                    }

                }, (error) => {
                    props.setOverlayVisible( (count) => count - 1);
                    alert(errMsg);
                });
        }


    };

    const onChangeHandler = (ev) => {
        let name = ev.target.name;
        let val = ev.target.value;

        if(ev.target.type === 'radio') {
            val = val === 'y' ? true : false;
        }

        props.setCurrentExam({...props.currentExam, [name]: val});
    };

    return (
        <React.Fragment>
            <div className="modal fade in">
                <div className="modal-header">
                    <h3>
                        {props.currentExam.id > 0 ? "Edit" : "Add" } Exam Type
                    </h3>
                </div>

                <div className="modal-body">
                    <form onSubmit={submitHandler}>
                        <div className="modal-form">
                            <div className="form-group">

                                {props.addEditExamErrors.length > 0 && (

                                    <div className="modal-errors" style={{marginLeft: "15px"}}>
                                        <div className="errorSummary"><p>Please fix the following input errors:</p>
                                            <ul>
                                                {props.addEditExamErrors.map((message) => (
                                                    <li key={message}>{message}</li>
                                                ))}
                                            </ul>
                                        </div>
                                    </div>

                                )}


                                <div className="span5" style={{width: "100%", marginTop: "-19px"}}>
                                    <div className="row">
                                        <div className="label" style={{width: "186px"}}>
                                            Name*:
                                        </div>
                                        <div className="input">
                                            <input
                                                type="text"
                                                style={{width: "288px"}}
                                                name="name"
                                                value={props.currentExam.name}
                                                onChange={onChangeHandler}
                                            />
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="label" style={{width: "186px"}}>
                                            Include photographic fields?
                                        </div>
                                        <div className="checkbox-group check">
                                            <input
                                                className="no-required"
                                                type="radio"
                                                name="include_photo_fields"
                                                id="include_photo_fields_y"
                                                value="y"
                                                checked={props.currentExam.include_photo_fields}
                                                onChange={onChangeHandler}
                                            />
                                            <label htmlFor="include_photo_fields_y">Yes</label>
                                            <input
                                                className="no-required"
                                                type="radio"
                                                value="n"
                                                name="include_photo_fields"
                                                id="include_photo_fields_n"
                                                checked={!props.currentExam.include_photo_fields}
                                                onChange={onChangeHandler}
                                            />
                                            <label htmlFor="include_photo_fields_n">No</label>
                                        </div>
                                    </div> {/* .row */}
                                    <div className="row">
                                        <div className="label" style={{width: "186px"}}>
                                            Include photographic quality?
                                        </div>
                                        <div className="checkbox-group check">
                                            <input
                                                className="no-required"
                                                type="radio"
                                                name="include_photo_quality"
                                                id="include_photo_quality_y"
                                                value="y"
                                                checked={props.currentExam.include_photo_quality}
                                                onChange={onChangeHandler}
                                            />
                                            <label htmlFor="include_photo_quality_y">Yes</label>
                                            <input
                                                className="no-required"
                                                type="radio"
                                                value="n"
                                                name="include_photo_quality"
                                                id="include_photo_quality_n"
                                                checked={!props.currentExam.include_photo_quality}
                                                onChange={onChangeHandler}
                                            />
                                            <label htmlFor="include_photo_quality_n">No</label>
                                        </div>
                                    </div> {/* .row */}
                                    <div className="row">
                                        <div className="label" style={{width: "186px"}}>
                                            Include quality fields?
                                        </div>
                                        <div className="checkbox-group check">
                                            <input
                                                className="no-required"
                                                type="radio"
                                                name="include_quality"
                                                id="include_quality_y"
                                                value="y"
                                                checked={props.currentExam.include_quality}
                                                onChange={onChangeHandler}
                                            />
                                            <label htmlFor="include_quality_y">Yes</label>
                                            <input
                                                className="no-required"
                                                type="radio"
                                                value="n"
                                                name="include_quality"
                                                id="include_quality_n"
                                                checked={!props.currentExam.include_quality}
                                                onChange={onChangeHandler}
                                            />
                                            <label htmlFor="include_quality_n">No</label>
                                        </div>
                                    </div> {/* .row */}
                                    <div className="row">
                                        <div className="label" style={{width: "186px"}}>
                                            Has equipment with camera?
                                        </div>
                                        <div
                                            className="check-group check"
                                            style={{marginRight: "18px"}}
                                        >
                                            <input
                                                className="no-required"
                                                type="radio"
                                                name="has_equipments_with_camera"
                                                id="has_equipments_with_camera_y"
                                                value="y"
                                                checked={props.currentExam.has_equipments_with_camera}
                                                onChange={onChangeHandler}
                                            />
                                            <label htmlFor="has_equipments_with_camera_y">Yes</label>
                                            <input
                                                className="no-required"
                                                type="radio"
                                                value="n"
                                                name="has_equipments_with_camera"
                                                id="has_equipments_with_camera_n"
                                                checked={!props.currentExam.has_equipments_with_camera}
                                                onChange={onChangeHandler}
                                            />
                                            <label htmlFor="has_equipments_with_camera_n">No</label>
                                        </div>
                                    </div> {/* .row */}
                                </div>
                            </div>
                        </div>
                    </form>
                </div> {/* .modal-body */}
                <div className="modal-footer">
                    <button
                        className="btn small orange align-center right"
                        onClick={confirmAddExam}
                    >Save</button>
                    <button
                        className="btn small grey align-center right"
                        onClick={closePopup}
                    >Close</button>
                </div> {/* .modal-body */}
            </div>

            <div className="modal-backdrop fade in"></div>

        </React.Fragment>
    );
}


/*
 *
 * Tech var forms
 *
 */
function TechVarsList(props) {
    const showAddTechVarPopup = () => {
        props.setCurrentTechVar(JSON.parse(JSON.stringify(props.emptyNewTechVar)));
        props.setHasOptions(false);
        props.setAddEditTechVarPopupVisible(true);
    };

    return (
        <React.Fragment>
        <div className="breadcrumbs">
            <div className="path">
                <ul>
                    <li>Technician Form Vars:</li>
                </ul>
            </div> {/* .path */}
            <div className="sub-menu">
                <button
                    className="btn normal grey align-center right"
                    onClick={showAddTechVarPopup}
                >Add technician form var
                </button>
            </div>
        </div> {/* .breadcrumbs */}
        <div className="list visits-list">
            <div className="list-head">
                <table>
                    <tbody>
                        <tr>
                            <th width="50%">Name</th>
                            <th width="20%">Group</th>
                            <th width="20%">Type</th>
                            <th width="4%"></th>
                        </tr>
                    </tbody>
                </table>
            </div> {/* .list-head */}

            <div className="list-body">
                <table id="exams-table">
                    <tbody className="list-vists">
                        {props.techVars.map((techVar, i) => (
                        <TechVarsListItem
                            key={i}
                            techVar={techVar}
                            getFieldTypeDisplay={props.getFieldTypeDisplay}
                            getFieldGroupDisplay={props.getFieldGroupDisplay}
                            setCurrentTechVar={props.setCurrentTechVar}
                            setAddEditTechVarPopupVisible={props.setAddEditTechVarPopupVisible}
                            setHasOptions={props.setHasOptions}
                        />
                        ))}
                    </tbody>
                </table>
            </div> {/* .list-body */}
        </div>
        </React.Fragment>
    );
}

function TechVarsListItem(props) {
    const showEditTechVarPopup = (tVar) => {
        if(tVar.field_type === 'check_many' || tVar.field_type === 'check_one' || tVar.field_type === 'list') {
            props.setHasOptions(true);
        }
        else {
            props.setHasOptions(false);
        }

        props.setCurrentTechVar(tVar);
        props.setAddEditTechVarPopupVisible(true);
    };

    return (
        <tr>
            <td width="50%">{props.techVar.name}</td>
            <td width="20%">{props.getFieldGroupDisplay(props.techVar.group)}</td>
            <td width="20%">{props.getFieldTypeDisplay(props.techVar.field_type)}</td>
            <td className="btn-list" width="4%" style={{position: "relative"}}>
                <ul style={{top: "0"}}>

                    <li>
                        <button
                            className="btn xsmall grey edit-user"
                            onClick={() => showEditTechVarPopup(props.techVar)}
                        >
                        <span className="icon-list study-details-small" style={{marginLeft: "0"}}></span></button>
                    </li>
                </ul>
            </td>
        </tr>
    );
}

function AddEditTechVarPopup(props) {
    const closePopup = () => {
        props.setAddEditTechVarErrors([]);
        props.setAddEditTechVarPopupVisible(false);
        props.setCurrentTechVar({});
    };

    const submitHandler = (e) => {
        e.preventDefault();
    };

    const confirmAddTechVar = async () => {

        const errMsg = "Sorry, something went wrong when saving this technician var. Please contact your IT helpdesk.";
        let editedTechVars = JSON.parse(JSON.stringify(props.techVars));
        let newTechVar, tmp = JSON.parse(JSON.stringify(props.currentTechVar));
        let errors = [];

        // trim all fields before validating
        tmp.name = tmp.name.trim();

        // do we have requiredfields?
        if (!tmp.name) {
            errors.push('Name cannot be blank.');
        }

        if((tmp.field_type === 'check_one' ||
            tmp.field_type === 'check_many' ||
            tmp.field_type === 'list'
            ) && (
            !tmp.technician_vars_options.length ||
            !tmp.technician_vars_options.filter(o => o.value.trim().length).length)
        ) {
            errors.push('There must be at least one option.');
        }

        if(!tmp.exams || !tmp.exams.length) {
            errors.push('There must be at least one exam.');
        }

        if (errors.length) {
            props.setAddEditTechVarErrors(errors);
            return;
        }
        else {
            props.setAddEditTechVarErrors([]);
        }

        // filter duplicate values on options
        if(tmp.field_type === 'check_one' ||
            tmp.field_type === 'check_many' ||
            tmp.field_type === 'list') {
                tmp.technician_vars_options = tmp.technician_vars_options.filter((thing, index, self) =>
                    index === self.findIndex((t) => (
                        t.value.toLowerCase() === thing.value.toLowerCase()))
                )
        }

        let count = props.overlayVisible;
        props.setOverlayVisible( (count) => count + 1);

        //we're editing
        if(tmp.id > 0) {
            await axios.patch(process.env.REACT_APP_API_ENDPOINT+'/settings/technicianformvars/'+tmp.id+'/', {...tmp}, { withCredentials: true})
                .then((response) => {

                    props.setOverlayVisible( (count) => count - 1);
                    if (response.status === 200 && response.data && response.data.id) {

                        newTechVar = response.data;

                        for(let i=0, len = editedTechVars.length; i<=len-1;++i) {
                            if(parseInt(editedTechVars[i].id, 10) === parseInt(newTechVar.id, 10) ) {
                                editedTechVars.splice(i, 1, newTechVar);
                                break;
                            }
                        }
                        props.setTechVars(editedTechVars);

                        props.setAddEditTechVarPopupVisible(false);

                    }
                    else {
                        alert(errMsg);
                    }

                }, (error) => {
                    props.setOverlayVisible( (count) => count - 1);
                    alert(errMsg);
                });
        }
        else { //we're adding
            await axios.post(process.env.REACT_APP_API_ENDPOINT+'/settings/technicianformvars/', {...tmp}, { withCredentials: true})
                .then((response) => {

                    props.setOverlayVisible( (count) => count - 1);
                    if (response.status === 201 && response.data && response.data.id) {

                        newTechVar = response.data;

                        editedTechVars.push(newTechVar);
                        props.setTechVars(editedTechVars);

                        props.setAddEditTechVarPopupVisible(false);

                    }
                    else {
                        alert(errMsg);
                    }

                }, (error) => {
                    props.setOverlayVisible( (count) => count - 1);
                    alert(errMsg);
                });
        }
    };

    const onChangeHandler = (ev) => {
        let name = ev.target.name;
        let val = ev.target.value;

        props.setCurrentTechVar({...props.currentTechVar, [name]: val});
    };

    const onTypeChangeHandler = selected => {
        if(selected === 'check_many' || selected === 'check_one' || selected === 'list') {
            props.setCurrentTechVar({...props.currentTechVar, field_type: selected, technician_vars_options: [{"id": "tmp"+Math.floor(Math.random(3)*1000), "value": "", "order": 0}]});
            props.setHasOptions(true);
        }
        else {
            props.setCurrentTechVar({...props.currentTechVar, field_type: selected});
            props.setHasOptions(false);
        }
    };

    const onOptionChangeHandler = (ev, option) => {
        let editedVars = JSON.parse(JSON.stringify(props.currentTechVar));
        let edOption = editedVars.technician_vars_options.find(o => o.id === option.id );
        edOption.value = ev.target.value;

        props.setCurrentTechVar(editedVars);
    };

    const addTechVar = order => {
        let newVar = {
            "id": "tmp"+Math.floor(Math.random(3)*1000),
            "value": "",
            "order": 0
        }

        let tmp = JSON.parse(JSON.stringify(props.currentTechVar.technician_vars_options));

        if(!isNaN(+order)) {
            newVar.order = +order + 1;

            tmp.map((v) => {
                if(v.order >= newVar.order) {
                    ++v.order;
                }
                return true;
            });

            tmp.push(newVar);

            tmp.sort((a,b) => {
                if(a.order > b.order) {
                    return 1
                }
                else if(b.order > a.order) {
                    return -1
                }
                return 0
            });

        }
        else {
            tmp.push(newVar);
            newVar.order = tmp.length ? tmp.length-1 : 0;
        }

        props.setCurrentTechVar({...props.currentTechVar, 'technician_vars_options': tmp});
    };

    const deleteVar = tVar => {
        let editedOptions = JSON.parse(JSON.stringify(props.currentTechVar.technician_vars_options));

        editedOptions = editedOptions.filter(v => {return v.id !== tVar.id});

        if(editedOptions.length) {
            editedOptions.map( (v, i) => v.order = i);
        }
        props.setCurrentTechVar({...props.currentTechVar, technician_vars_options: editedOptions});
    };

    const isExamInTechForm = examId => {
        const ex = props.currentTechVar.exams.find(e => {
            return e.id === examId;
        })

        if (ex !== undefined) {
            return true;
        }

        return false;
    };

    const onCheckHandler = (e, exam) => {
        let editedExams = JSON.parse(JSON.stringify(props.currentTechVar.exams));

        if(e.target.checked) {
            editedExams.push({id: parseInt(exam.id, 10)});
        }
        else {
            let idx = editedExams.findIndex(e => parseInt(e.id, 10) === parseInt(exam.id, 10));
            editedExams.splice(idx, 1);
        }

        props.setCurrentTechVar({...props.currentTechVar, exams: editedExams})
    };

    return (
        <React.Fragment>
            <div className="modal fade in">
                <div className="modal-header">
                    <h3>
                        {props.currentTechVar.id > 0 ? "Edit" : "Add" } Technician Form Var
                    </h3>
                </div>

                <div className="modal-body">
                    <form onSubmit={submitHandler}>
                        <div className="modal-form">
                            <div className="form-group">

                                {props.addEditTechVarErrors.length > 0 && (
                                    <div className="modal-errors" style={{marginLeft: "15px"}}>
                                        <div className="errorSummary"><p>Please fix the following input errors:</p>
                                            <ul>
                                                {props.addEditTechVarErrors.map((message) => (
                                                    <li key={message}>{message}</li>
                                                ))}
                                            </ul>
                                        </div>
                                    </div>
                                )}

                                <div className="span5" style={{width: "100%", marginTop: "-19px"}}>
                                    <div className="row">
                                        <div className="label" style={{width: "186px"}}>
                                            Name*:
                                        </div>
                                        <div className="input">
                                            <input
                                                type="text"
                                                style={{width: "288px"}}
                                                name="name"
                                                value={props.currentTechVar.name}
                                                onChange={onChangeHandler}
                                            />
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="label" style={{width: "186px"}}>
                                            Type:
                                        </div>
                                        <CorcSelect
                                            options={props.fieldTypeOptions}
                                            name="field_type"
                                            id="field_type"
                                            value={props.fieldTypeOptions.filter(o => o.value === props.currentTechVar.field_type)}
                                            onChange={selectedOption => onTypeChangeHandler(selectedOption.value)}
                                            isSearchable={false}
                                            containerStyles={{width: "175px", float: "left"}}
                                        />

                                    </div> {/* .row */}
                                    {props.hasOptions && (
                                    <div className="row" style={{marginBottom: "10px"}}>
                                        <div className="label" style={{width: "186px"}}>
                                            Values:
                                        </div>
                                        {props.currentTechVar.technician_vars_options.map((tVar, i) => (
                                            <div
                                                key={i}
                                                className="input"
                                                style={{marginLeft: "186px", float: "none"}}
                                            >
                                                <input
                                                    type="text"
                                                    style={{width: "188px", marginRight: "5px"}}
                                                    name="name"
                                                    value={tVar.value}
                                                    onChange={(ev) => onOptionChangeHandler(ev, tVar)}
                                                />
                                                <button
                                                    className="btn xsmall grey add-visit"
                                                    style={{marginRight: "2px"}}
                                                    onClick={() => addTechVar(tVar.order)}
                                                >
                                                    <span className="study-visit-icon add-small">&nbsp;</span>
                                                </button>
                                                {props.currentTechVar.technician_vars_options.length > 1 && (
                                                <button
                                                    className="btn xsmall grey remove-visit"
                                                    onClick={() => deleteVar(tVar)}
                                                >
                                                    <span className="study-visit-icon delete-small">&nbsp;</span>
                                                </button>
                                                )}
                                            </div>
                                        ))}
                                    </div>
                                    )}

                                    <div className="row">
                                        <React.Fragment>
                                        <div className="label" style={{width: "186px"}}>
                                            Exams:
                                        </div>
                                        <div style={{width: "328px", float: "left"}}>
                                            {props.exams.map((exam) => (
                                            <div key={exam.id} className="checkbox">
                                                <input
                                                    id={"tech_form_exam_" + exam.id}
                                                    name="tech_form_exam"
                                                    type="checkbox"
                                                    value={exam.id}
                                                    checked={ isExamInTechForm(exam.id) }
                                                    onChange={(e) => onCheckHandler(e, exam) }
                                                />
                                                <label htmlFor={"tech_form_exam_" + exam.id}>{exam.name}</label>
                                            </div>
                                            ))}
                                        </div>
                                        </React.Fragment>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </form>
                </div> {/* .modal-body */}
                <div className="modal-footer">
                    <button
                        className="btn small orange align-center right"
                        onClick={confirmAddTechVar}
                    >Save</button>
                    <button
                        className="btn small grey align-center right"
                        onClick={closePopup}
                    >Close</button>
                </div> {/* .modal-body */}
            </div>

            <div className="modal-backdrop fade in"></div>

        </React.Fragment>
    );
}

/*
 *
 * Patient Card Vars
 *
 */
function PatientCardVarsList(props) {
    const showAddCardVarPopup = () => {
        props.setCurrentCardVar(JSON.parse(JSON.stringify(props.emptyNewCardVar)));
        props.setHasOptions(false);
        props.setAddEditCardVarPopupVisible(true);
    };

    return (
        <React.Fragment>
        <div className="breadcrumbs">
            <div className="path">
                <ul>
                    <li>Patient Card Vars:</li>
                </ul>
            </div> {/* .path */}
            <div className="sub-menu">
                <button
                    className="btn normal grey align-center right"
                    onClick={showAddCardVarPopup}
                >Add patient card var
                </button>
            </div>
        </div> {/* .breadcrumbs */}
        <div className="list visits-list">
            <div className="list-head">
                <table>
                    <tbody>
                        <tr>
                            <th width="50%">Name</th>
                            <th width="20%">Group</th>
                            <th width="20%">Type</th>
                            <th width="4%"></th>
                        </tr>
                    </tbody>
                </table>
            </div> {/* .list-head */}

            <div className="list-body">
                <table id="exams-table">
                    <tbody className="list-vists">
                        {props.cardVars.map((cardVar, i) => (
                        <PatientCardVarsListItem
                            key={i}
                            cardVar={cardVar}
                            getFieldTypeDisplay={props.getFieldTypeDisplay}
                            getFieldGroupDisplay={props.getFieldGroupDisplay}
                            setCurrentCardVar={props.setCurrentCardVar}
                            setAddEditCardVarPopupVisible={props.setAddEditCardVarPopupVisible}
                            setHasOptions={props.setHasOptions}
                        />
                        ))}
                    </tbody>
                </table>
            </div> {/* .list-body */}
        </div>
        </React.Fragment>
    );
}

function PatientCardVarsListItem(props) {

    const showEditCardVarPopup = (cVar) => {
        if(cVar.field_type === 'check_many' || cVar.field_type === 'check_one' || cVar.field_type === 'list') {
            props.setHasOptions(true);
        }
        else {
            props.setHasOptions(false);
        }

        props.setCurrentCardVar(cVar);
        props.setAddEditCardVarPopupVisible(true);
    };

    return (
        <tr>
            <td width="50%">{props.cardVar.name}</td>
            <td width="20%">{props.getFieldGroupDisplay(props.cardVar.group)}</td>
            <td width="20%">{props.getFieldTypeDisplay(props.cardVar.field_type)}</td>
            <td className="btn-list" width="4%" style={{position: "relative"}}>
                <ul style={{top: "0"}}>

                    <li>
                        <button
                            className="btn xsmall grey edit-user"
                            onClick={() => showEditCardVarPopup(props.cardVar)}
                        >
                        <span className="icon-list study-details-small" style={{marginLeft: "0"}}></span></button>
                    </li>
                </ul>
            </td>
        </tr>
    );
}

function AddEditCardVarPopup(props) {
    const closePopup = () => {
        props.setAddEditCardVarPopupVisible(false);
        props.setCurrentCardVar({});
    };

    const submitHandler = (e) => {
        e.preventDefault();
    };

    const confirmAddCardVar = async () => {

        const errMsg = "Sorry, something went wrong when saving this card var. Please contact your IT helpdesk.";
        let editedCardVars = JSON.parse(JSON.stringify(props.cardVars));
        let newCardVar, tmp = JSON.parse(JSON.stringify(props.currentCardVar));
        let errors = [];

        // trim all fields before validating
        tmp.name = tmp.name.trim();

        // do we have requiredfields?
        if (!tmp.name) {
            errors.push('Name cannot be blank.');
        }

        if((tmp.field_type === 'check_one' ||
            tmp.field_type === 'check_many' ||
            tmp.field_type === 'list'
            ) && (
            !tmp.card_vars_options.length ||
            !tmp.card_vars_options.filter(o => o.value.trim().length).length)
        ) {
            errors.push('There must be at least one option');
        }

        if (errors.length) {
            props.setAddEditCardVarErrors(errors);
            return;
        }
        else {
            props.setAddEditCardVarErrors([]);
        }

        // filter duplicate values on options
        if(tmp.field_type === 'check_one' ||
            tmp.field_type === 'check_many' ||
            tmp.field_type === 'list') {
                tmp.card_vars_options = tmp.card_vars_options.filter((thing, index, self) =>
                    index === self.findIndex((t) => (
                        t.value.toLowerCase() === thing.value.toLowerCase()))
                )
        }

        let count = props.overlayVisible;
        props.setOverlayVisible( (count) => count + 1);

        //we're editing
        if(tmp.id > 0) {
            await axios.patch(process.env.REACT_APP_API_ENDPOINT+'/settings/patientcardvars/'+tmp.id+'/', {...tmp}, { withCredentials: true})
                .then((response) => {

                    props.setOverlayVisible( (count) => count - 1);
                    if (response.status === 200 && response.data && response.data.id) {

                        newCardVar = response.data;

                        for(let i=0, len = editedCardVars.length; i<=len-1;++i) {
                            if(parseInt(editedCardVars[i].id, 10) === parseInt(newCardVar.id, 10) ) {
                                editedCardVars.splice(i, 1, newCardVar);
                                break;
                            }
                        }
                        props.setCardVars(editedCardVars);

                        props.setAddEditCardVarPopupVisible(false);

                    }
                    else {
                        alert(errMsg);
                    }

                }, (error) => {
                    props.setOverlayVisible( (count) => count - 1);
                    alert(errMsg);
                });
        }
        else { //we're adding
            await axios.post(process.env.REACT_APP_API_ENDPOINT+'/settings/patientcardvars/', {...tmp}, { withCredentials: true})
                .then((response) => {

                    props.setOverlayVisible( (count) => count - 1);
                    if (response.status === 201 && response.data && response.data.id) {

                        newCardVar = response.data;

                        editedCardVars.push(newCardVar);
                        props.setCardVars(editedCardVars);

                        props.setAddEditCardVarPopupVisible(false);

                    }
                    else {
                        alert(errMsg);
                    }

                }, (error) => {
                    props.setOverlayVisible( (count) => count - 1);
                    alert(errMsg);
                });
        }
    };

    const onChangeHandler = (ev) => {
        let name = ev.target.name;
        let val = ev.target.value;

        props.setCurrentCardVar({...props.currentCardVar, [name]: val});
    };

    const onTypeChangeHandler = selected => {
        if(selected === 'check_many' || selected === 'check_one' || selected === 'list') {
            props.setCurrentCardVar({...props.currentCardVar, field_type: selected, card_vars_options: [{"id": "tmp"+Math.floor(Math.random(3)*1000), "value": "", "order": 0}]});
            props.setHasOptions(true);
        }
        else {
            props.setCurrentCardVar({...props.currentCardVar, field_type: selected});
            props.setHasOptions(false);
        }
    };

    const onGroupChangeHandler = selected => {
        props.setCurrentCardVar({...props.currentCardVar, group: selected});
    };

    const onOptionChangeHandler = (ev, option) => {
        let editedVars = JSON.parse(JSON.stringify(props.currentCardVar));
        let edOption = editedVars.card_vars_options.find(o => o.id === option.id );
        edOption.value = ev.target.value;

        props.setCurrentCardVar(editedVars);
    };

    const addCardVar = order => {
        let newVar = {
            "id": "tmp"+Math.floor(Math.random(3)*1000),
            "value": "",
            "order": order
        }

        let tmp = JSON.parse(JSON.stringify(props.currentCardVar.card_vars_options));

        if(!isNaN(+order)) {
            newVar.order = +order + 1;

            tmp.map((v) => {
                if(v.order >= newVar.order) {
                    ++v.order;
                }
                return true;
            });

            tmp.push(newVar);

            tmp.sort((a,b) => {
                if(a.order > b.order) {
                    return 1
                }
                else if(b.order > a.order) {
                    return -1
                }
                return 0
            });

        }
        else {
            tmp.push(newVar);
            newVar.order = tmp.length ? tmp.length-1 : 0;
        }

        props.setCurrentCardVar({...props.currentCardVar, 'card_vars_options': tmp});
    };

    const deleteVar = cVar => {
        let editedOptions = JSON.parse(JSON.stringify(props.currentCardVar.card_vars_options));

        editedOptions = editedOptions.filter(v => {return v.id !== cVar.id});

        if(editedOptions.length) {
            editedOptions.map( (v, i) => v.order = i);
        }
        props.setCurrentCardVar({...props.currentCardVar, card_vars_options: editedOptions});
    };

    return (
        <React.Fragment>
            <div className="modal fade in">
                <div className="modal-header">
                    <h3>
                        {props.currentCardVar.id > 0 ? "Edit" : "Add" } Patient Card Var
                    </h3>
                </div>

                <div className={props.currentCardVar.card_vars_options.length < 9 ? "modal-body no-overflow" : "modal-body"}>
                    <form onSubmit={submitHandler}>
                        <div className="modal-form">
                            <div className="form-group">

                                {props.addEditCardVarErrors.length > 0 && (
                                    <div className="modal-errors" style={{marginLeft: "15px"}}>
                                        <div className="errorSummary"><p>Please fix the following input errors:</p>
                                            <ul>
                                                {props.addEditCardVarErrors.map((message) => (
                                                    <li key={message}>{message}</li>
                                                ))}
                                            </ul>
                                        </div>
                                    </div>
                                )}

                                <div className="span5" style={{width: "100%", marginTop: "-19px"}}>
                                    <div className="row">
                                        <div className="label" style={{width: "186px"}}>
                                            Name*:
                                        </div>
                                        <div className="input">
                                            <input
                                                type="text"
                                                style={{width: "288px"}}
                                                name="name"
                                                value={props.currentCardVar.name}
                                                onChange={onChangeHandler}
                                            />
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="label" style={{width: "186px"}}>
                                            Group
                                        </div>
                                        <CorcSelect
                                            options={props.fieldGroupOptions}
                                            name="field_type"
                                            id="field_type"
                                            value={props.fieldGroupOptions.filter(o => o.value === props.currentCardVar.group)}
                                            onChange={selectedOption => onGroupChangeHandler(selectedOption.value)}
                                            isSearchable={false}
                                            containerStyles={{width: "175px", float: "left"}}
                                        />
                                    </div> {/* .row */}
                                    <div className="row">
                                        <div className="label" style={{width: "186px"}}>
                                            Type
                                        </div>
                                        <CorcSelect
                                            options={props.fieldTypeOptions}
                                            name="field_type"
                                            id="field_type"
                                            value={props.fieldTypeOptions.filter(o => o.value === props.currentCardVar.field_type)}
                                            onChange={selectedOption => onTypeChangeHandler(selectedOption.value)}
                                            isSearchable={false}
                                            containerStyles={{width: "175px", float: "left"}}
                                        />

                                    </div> {/* .row */}
                                    {props.hasOptions && (
                                    <div className="row">
                                        <div className="label" style={{width: "186px"}}>
                                            Values:
                                        </div>
                                        {props.currentCardVar.card_vars_options.map((tVar, i) => (
                                            <div
                                                key={i}
                                                className="input"
                                                style={{marginLeft: "186px", float: "none"}}
                                            >
                                                <input
                                                    type="text"
                                                    style={{width: "188px", marginRight: "5px"}}
                                                    name="name"
                                                    value={tVar.value}
                                                    onChange={(ev) => onOptionChangeHandler(ev, tVar)}
                                                />
                                                <button
                                                    className="btn xsmall grey add-visit"
                                                    style={{marginRight: "2px"}}
                                                    onClick={() => addCardVar(tVar.order)}
                                                >
                                                    <span className="study-visit-icon add-small">&nbsp;</span>
                                                </button>
                                                {props.currentCardVar.card_vars_options.length > 1 && (
                                                <button
                                                    className="btn xsmall grey remove-visit"
                                                    onClick={() => deleteVar(tVar)}
                                                >
                                                    <span className="study-visit-icon delete-small">&nbsp;</span>
                                                </button>
                                                )}
                                            </div>
                                        ))}
                                    </div>
                                    )}
                                </div>
                            </div>
                        </div>
                    </form>
                </div> {/* .modal-body */}
                <div className="modal-footer">
                    <button
                        className="btn small orange align-center right"
                        onClick={confirmAddCardVar}
                    >Save</button>
                    <button
                        className="btn small grey align-center right"
                        onClick={closePopup}
                    >Close</button>
                </div> {/* .modal-body */}
            </div>

            <div className="modal-backdrop fade in"></div>

        </React.Fragment>
    );
}

