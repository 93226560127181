import React, {useState}  from 'react';
import './App.css';
import { BrowserRouter as Router, Switch, Route, Redirect, Prompt } from 'react-router-dom';
import Header from './components/Header';
import Nav from './components/Nav';
import Homepage from './components/homepage/Homepage';
import EditHomepage from './components/homepage/EditHomepage';
import Studies from './components/studies/Studies';
import Users from './components/users/Users';
import RegisterUser from './components/users/RegisterUser';
import ResetPassword from './components/users/ResetPassword';
import EditUser from './components/users/EditUser';
import Centers from './components/centers/Centers';
import Study from './components/studies/Study';
import StudyVars from './components/studies/StudyVars';
import Documentation from './components/studies/Documentation';
import Navigate from './components/studies/navigate/Navigate';
import StudyUsers from './components/studies/StudyUsers';
import CertificationsList from './components/studies/certifications/CertificationsList';
import CertificationsTechnicianList from './components/studies/certifications/CertificationsTechnicianList';
import EquipmentNewCertification from './components/studies/certifications/equipment/EquipmentNewCertification';
import EquipmentCertificationReport from './components/studies/certifications/equipment/EquipmentCertificationReport';
import EquipmentCertificationRecertify from './components/studies/certifications/equipment/EquipmentCertificationRecertify';
import EquipmentCertificationSubmit from './components/studies/certifications/equipment/EquipmentCertificationSubmit';
import EquipmentCertificationGrade from './components/studies/certifications/equipment/EquipmentCertificationGrade';
import TechnicianCertificationReport from './components/studies/certifications/technician/TechnicianCertificationReport';
import TechnicianCertificationGrade from './components/studies/certifications/technician/TechnicianCertificationGrade';
import TechnicianCertificationRecertify from './components/studies/certifications/technician/TechnicianCertificationRecertify';
import TechnicianCertificationSubmit from './components/studies/certifications/technician/TechnicianCertificationSubmit';
import Patients from './components/studies/patients/Patients';
import PatientDetails from './components/studies/patients/PatientDetails';
import Visits from './components/studies/visits/Visits';
import Exams from './components/studies/exams/Exams';
import PatientsExams from './components/studies/exams/PatientsExams';
import PatientsVisits from './components/studies/exams/PatientsVisits';
import PatientsVisitSubmit from './components/studies/exams/PatientsVisitSubmit';
import PatientsVisitNewUnscheduled from './components/studies/exams/PatientsVisitNewUnscheduled';
import PatientsVisitDetails from './components/studies/exams/PatientsVisitDetails';
import ViewPatient from './components/studies/exams/ViewPatient';
import NewPatient from './components/studies/exams/NewPatient';
import ExamGrade from './components/studies/exams/ExamGrade';
import VisitReport from './components/studies/visits/VisitReport';
import VisitSubmit from './components/studies/visits/VisitSubmit';
import Settings from './components/settings/Settings';
import Logs from './components/logs/Logs';
import LoadingOverlay from 'components/utils/LoadingOverlay';
import FileBrowser from 'components/file_browser/FileBrowser';
import CertificationBrowser from 'components/certification_browser/CertificationBrowser';
import Diagnostics from 'components/diagnostics/Diagnostics';
import axios from 'axios';

function App() {

    let token;
    const [navLinks, setNavLinks] = useState([]);
    const [userAuth, setUserAuth] = useState({
        'isAuth': false,
        'user': {},
        'token': ''
    });
    const [overlayVisible, setOverlayVisible] = useState(0);
    const [filesUploading, setFilesUploading] = useState(false);
    const resetAuth = async() => {

        localStorage.removeItem("corc_auth");
        setUserAuth({'isAuth': false, 'user': {}, 'token': ''});

    }
    const promptMessage = "If you proceed, the submission will be lost and you will have to do it again.";
    const [showPrompt, setShowPrompt] = useState(false);
    //show alert on reload or close tab
    const showPromptHandler = e => {
        e.preventDefault();
        (e || window.event).returnValue = promptMessage;
        return promptMessage;
    };

    const updateUserInAuth = (user) => {

        token = localStorage.getItem("corc_auth");
        if (token !== null) {
            try {
                token = JSON.parse(token);
                if (token && token.hasOwnProperty('token') && token.hasOwnProperty('isAuth') && token.hasOwnProperty('user')) {
                    token.user = user;
                    localStorage.setItem('corc_auth', JSON.stringify(token));
                    setUserAuth(token);
                }
                else {
                    resetAuth();
                }
            }
            catch(e) {
                resetAuth();
            }
        }

    }

    // Add a response interceptor
    axios.interceptors.response.use(function (response) {
        // Do something with response data
        return response;
      }, function (error) {
        if (error.response.status === 401 || error.response.status === 403) {
            resetAuth();
        }
        return Promise.reject(error);

      });


    // if isAuth is false, try to fetch it from localstorage
    if (!userAuth.isAuth) {
        token = localStorage.getItem("corc_auth");
        if (token !== null) {
            try {
                token = JSON.parse(token);
                if (token && token.hasOwnProperty('token') && token.hasOwnProperty('isAuth') && token.hasOwnProperty('user')) {
                    setUserAuth(token);
                }
                else {
                    resetAuth();
                }
            }
            catch(e) {
                resetAuth();
            }
        }
    }



    return (
        <Router>
            {overlayVisible > 0 && (
                <LoadingOverlay
                    filesUploading={filesUploading}
                />
            )}
            <div id="body-content">
                {userAuth.isAuth ? (
                <React.Fragment>
                    <Prompt
                        when={showPrompt}
                        message={promptMessage}
                    />

                    <Header userAuth={userAuth} setUserAuth={setUserAuth}/>
                    <Nav userAuth={userAuth} navLinks={navLinks}/>
                    <Switch>
                        <Route exact path="/" render={props =>
                          (<Studies {...props} overlayVisible={overlayVisible} setOverlayVisible={setOverlayVisible} resetAuth={resetAuth} userAuth={userAuth} setNavLinks={setNavLinks}/>)
                        }/>
                        <Route exact path="/users" render={props =>
                          (<Users {...props} overlayVisible={overlayVisible} setOverlayVisible={setOverlayVisible} resetAuth={resetAuth} userAuth={userAuth} setNavLinks={setNavLinks}/>)
                        }/>
                        <Route exact path="/users/edit/:id" render={props =>
                          (<Users {...props} overlayVisible={overlayVisible} setOverlayVisible={setOverlayVisible} resetAuth={resetAuth} userAuth={userAuth} setNavLinks={setNavLinks}/>)
                        }/>
                        <Route exact path="/users/:id" render={props =>
                          (<EditUser {...props} overlayVisible={overlayVisible} setOverlayVisible={setOverlayVisible} resetAuth={resetAuth} userAuth={userAuth} updateUserInAuth={updateUserInAuth} setNavLinks={setNavLinks}/>)
                        }/>
                        <Route exact path="/studies" render={props =>
                          (<Studies {...props} overlayVisible={overlayVisible} setOverlayVisible={setOverlayVisible} resetAuth={resetAuth} userAuth={userAuth} setNavLinks={setNavLinks}/>)
                        }/>
                        <Route exact path="/centers" render={props =>
                          (<Centers {...props} overlayVisible={overlayVisible} setOverlayVisible={setOverlayVisible} resetAuth={resetAuth} userAuth={userAuth} setNavLinks={setNavLinks}/>)
                        }/>

                        <Route exact path="/studies/new" render={props =>
                           (<Study {...props} overlayVisible={overlayVisible} setOverlayVisible={setOverlayVisible} resetAuth={resetAuth} userAuth={userAuth} setNavLinks={setNavLinks}/>)
                        }/>

                        <Route exact path="/studies/:id/vars" render={props =>
                           (<StudyVars {...props} overlayVisible={overlayVisible} setOverlayVisible={setOverlayVisible} resetAuth={resetAuth} userAuth={userAuth} setNavLinks={setNavLinks}/>)
                        }/>

                        <Route exact path="/studies/:id/documentation" render={props =>
                           (<Documentation {...props} overlayVisible={overlayVisible} setOverlayVisible={setOverlayVisible} resetAuth={resetAuth} userAuth={userAuth} setNavLinks={setNavLinks}/>)
                        }/>

                        <Route exact path="/studies/:id/navigate" render={props =>
                           (<Navigate {...props} overlayVisible={overlayVisible} setOverlayVisible={setOverlayVisible} resetAuth={resetAuth} userAuth={userAuth} setNavLinks={setNavLinks}/>)
                        }/>

                        <Route exact path="/studies/:id" render={props =>
                           (<Study {...props} overlayVisible={overlayVisible} setOverlayVisible={setOverlayVisible} resetAuth={resetAuth} userAuth={userAuth} setNavLinks={setNavLinks}/>)
                        }/>

                        <Route exact path="/studies/:id/users" render={props =>
                           (<StudyUsers {...props} overlayVisible={overlayVisible} setOverlayVisible={setOverlayVisible} resetAuth={resetAuth} userAuth={userAuth} setNavLinks={setNavLinks}/>)
                        }/>

                        {(userAuth.user.role === 'tech' || userAuth.user.role === 'coordinator') && (
                        <Route exact path="/studies/:id/certifications" render={props =>
                           (<CertificationsTechnicianList {...props} overlayVisible={overlayVisible} setOverlayVisible={setOverlayVisible} resetAuth={resetAuth} userAuth={userAuth} setNavLinks={setNavLinks}/>)
                        }/>
                        )}

                        {userAuth.user.role !== 'tech' && userAuth.user.role !== 'coordinator' && (
                        <Route exact path="/studies/:id/certifications" render={props =>
                           (<CertificationsList {...props} overlayVisible={overlayVisible} setOverlayVisible={setOverlayVisible} resetAuth={resetAuth} userAuth={userAuth} setNavLinks={setNavLinks}/>)
                        }/>
                        )}


                        <Route exact path="/studies/:id/certifications/equipment/new/:center" render={props =>
                           (<EquipmentNewCertification {...props} overlayVisible={overlayVisible} setOverlayVisible={setOverlayVisible} resetAuth={resetAuth} userAuth={userAuth} setNavLinks={setNavLinks} setFilesUploading={setFilesUploading} setShowPrompt={setShowPrompt} showPromptHandler={showPromptHandler} />)
                        }/>

                        <Route exact path="/studies/:id/certifications/equipment/:certification" render={props =>
                           (<EquipmentCertificationReport {...props} overlayVisible={overlayVisible} setOverlayVisible={setOverlayVisible} resetAuth={resetAuth} userAuth={userAuth} setNavLinks={setNavLinks} setFilesUploading={setFilesUploading} setShowPrompt={setShowPrompt} showPromptHandler={showPromptHandler} />)
                        }/>

                        <Route exact path="/studies/:id/certifications/equipment/:certification/grade" render={props =>
                           (<EquipmentCertificationGrade {...props} overlayVisible={overlayVisible} setOverlayVisible={setOverlayVisible} resetAuth={resetAuth} userAuth={userAuth} setNavLinks={setNavLinks}/>)
                        }/>

                        <Route exact path="/studies/:id/certifications/equipment/:certification/recertify" render={props =>
                           (<EquipmentCertificationRecertify {...props} overlayVisible={overlayVisible} setOverlayVisible={setOverlayVisible} resetAuth={resetAuth} userAuth={userAuth} setNavLinks={setNavLinks}/>)
                        }/>

                        <Route exact path="/studies/:id/certifications/equipment/:certification/submit" render={props =>
                           (<EquipmentCertificationSubmit {...props} overlayVisible={overlayVisible} setOverlayVisible={setOverlayVisible} resetAuth={resetAuth} userAuth={userAuth} setNavLinks={setNavLinks} setFilesUploading={setFilesUploading} setShowPrompt={setShowPrompt} showPromptHandler={showPromptHandler} />)
                        }/>

                        <Route exact path="/studies/:id/certifications/technician/:certification/grade" render={props =>
                           (<TechnicianCertificationGrade {...props} overlayVisible={overlayVisible} setOverlayVisible={setOverlayVisible} resetAuth={resetAuth} userAuth={userAuth} setNavLinks={setNavLinks}/>)
                        }/>

                        <Route exact path="/studies/:id/certifications/technician/:certification/recertify" render={props =>
                           (<TechnicianCertificationRecertify {...props} overlayVisible={overlayVisible} setOverlayVisible={setOverlayVisible} resetAuth={resetAuth} userAuth={userAuth} setNavLinks={setNavLinks}/>)
                        }/>

                        <Route exact path="/studies/:id/certifications/technician/:certification/submit" render={props =>
                           (<TechnicianCertificationSubmit {...props} overlayVisible={overlayVisible} setOverlayVisible={setOverlayVisible} resetAuth={resetAuth} userAuth={userAuth} setNavLinks={setNavLinks} setFilesUploading={setFilesUploading} setShowPrompt={setShowPrompt} showPromptHandler={showPromptHandler} />)
                        }/>

                        <Route exact path="/studies/:id/certifications/technician/:certification" render={props =>
                           (<TechnicianCertificationReport {...props} overlayVisible={overlayVisible} setOverlayVisible={setOverlayVisible} resetAuth={resetAuth} userAuth={userAuth} setNavLinks={setNavLinks} setFilesUploading={setFilesUploading} setShowPrompt={setShowPrompt} showPromptHandler={showPromptHandler} />)
                        }/>

                        <Route exact path="/studies/:id/center/:center/patients" render={props =>
                           (<Patients {...props} userAuth={userAuth} overlayVisible={overlayVisible} setOverlayVisible={setOverlayVisible} resetAuth={resetAuth} setNavLinks={setNavLinks}/>)
                        }/>

                        <Route exact path="/studies/:id/center/:center/patients/:patient" render={props =>
                           (<PatientDetails {...props} overlayVisible={overlayVisible} setOverlayVisible={setOverlayVisible} resetAuth={resetAuth} userAuth={userAuth} setNavLinks={setNavLinks}/>)
                        }/>

                        <Route exact path="/studies/:id/exams/:exam" render={props =>
                           (<Exams {...props} overlayVisible={overlayVisible} setOverlayVisible={setOverlayVisible} resetAuth={resetAuth} userAuth={userAuth} setNavLinks={setNavLinks}/>)
                        }/>

                        <Route exact path="/studies/:id/exams/:exam/patients/:patient/visits/new" render={props =>
                           (<PatientsVisitNewUnscheduled {...props} overlayVisible={overlayVisible} setOverlayVisible={setOverlayVisible} resetAuth={resetAuth} userAuth={userAuth} setNavLinks={setNavLinks} setFilesUploading={setFilesUploading} setShowPrompt={setShowPrompt} showPromptHandler={showPromptHandler} />)
                        }/>

                        <Route exact path="/studies/:id/exams/:exam/patients/:patient/visits/:visit/submit" render={props =>
                           (<PatientsVisitSubmit {...props} overlayVisible={overlayVisible} setOverlayVisible={setOverlayVisible} resetAuth={resetAuth} userAuth={userAuth} setNavLinks={setNavLinks} setFilesUploading={setFilesUploading} setShowPrompt={setShowPrompt} showPromptHandler={showPromptHandler} />)
                        }/>

                        <Route exact path="/studies/:id/exams/:exam/patients/:patient/visits/:visit" render={props =>
                           (<PatientsVisitDetails {...props} overlayVisible={overlayVisible} setOverlayVisible={setOverlayVisible} resetAuth={resetAuth} userAuth={userAuth} setNavLinks={setNavLinks}/>)
                        }/>


                        <Route exact path="/studies/:id/exams/:exam/patients/:patient/visits" render={props =>
                           (<PatientsVisits {...props} overlayVisible={overlayVisible} setOverlayVisible={setOverlayVisible} resetAuth={resetAuth} userAuth={userAuth} setNavLinks={setNavLinks}/>)
                        }/>

                        <Route exact path="/studies/:id/exams/:exam/patients/new" render={props =>
                           (<NewPatient {...props} overlayVisible={overlayVisible} setOverlayVisible={setOverlayVisible} resetAuth={resetAuth} userAuth={userAuth} setNavLinks={setNavLinks}/>)
                        }/>

                        <Route exact path="/studies/:id/exams/:exam/patients/:patient" render={props =>
                           (<ViewPatient {...props} overlayVisible={overlayVisible} setOverlayVisible={setOverlayVisible} resetAuth={resetAuth} userAuth={userAuth} setNavLinks={setNavLinks}/>)
                        }/>

                        <Route exact path="/studies/:id/exams/:exam/patients" render={props =>
                           (<PatientsExams {...props} overlayVisible={overlayVisible} setOverlayVisible={setOverlayVisible} resetAuth={resetAuth} userAuth={userAuth} setNavLinks={setNavLinks}/>)
                        }/>

                        <Route exact path="/studies/:id/exams/:exam/grade/:visit" render={props =>
                           (<ExamGrade {...props} overlayVisible={overlayVisible} setOverlayVisible={setOverlayVisible} resetAuth={resetAuth} userAuth={userAuth} setNavLinks={setNavLinks}/>)
                        }/>

                        <Route exact path="/studies/:id/center/:center/patients/:patient/visits" render={props =>
                           (<Visits {...props} overlayVisible={overlayVisible} setOverlayVisible={setOverlayVisible} resetAuth={resetAuth} userAuth={userAuth} setNavLinks={setNavLinks}/>)
                        }/>

                        <Route exact path="/studies/:id/center/:center/patients/:patient/visits/:visit/exams/:exam/submit" render={props =>
                           (<VisitSubmit {...props} overlayVisible={overlayVisible} setOverlayVisible={setOverlayVisible} resetAuth={resetAuth} userAuth={userAuth} setNavLinks={setNavLinks} setShowPrompt={setShowPrompt} showPromptHandler={showPromptHandler} />)
                        }/>

                        <Route exact path="/studies/:id/center/:center/patients/:patient/visits/:visit" render={props =>
                           (<VisitReport {...props} overlayVisible={overlayVisible} setOverlayVisible={setOverlayVisible} resetAuth={resetAuth} userAuth={userAuth} setNavLinks={setNavLinks} setFilesUploading={setFilesUploading} />)
                        }/>

                        <Route exact path="/logs" render={props =>
                          (<Logs {...props} overlayVisible={overlayVisible} setOverlayVisible={setOverlayVisible}  resetAuth={resetAuth} userAuth={userAuth} setUserAuth={setUserAuth} setNavLinks={setNavLinks}/>)
                        }/>

                        <Route exact path="/filebrowser" render={props =>
                          (<FileBrowser {...props} overlayVisible={overlayVisible} setOverlayVisible={setOverlayVisible}  resetAuth={resetAuth} userAuth={userAuth} setUserAuth={setUserAuth} setNavLinks={setNavLinks}/>)
                        }/>
                        <Route exact path="/certificationbrowser" render={props =>
                          (<CertificationBrowser {...props} overlayVisible={overlayVisible} setOverlayVisible={setOverlayVisible}  resetAuth={resetAuth} userAuth={userAuth} setUserAuth={setUserAuth} setNavLinks={setNavLinks}/>)
                        }/>
                        <Route exact path="/diagnostics" render={props =>
                          (<Diagnostics {...props} overlayVisible={overlayVisible} setOverlayVisible={setOverlayVisible}  resetAuth={resetAuth} userAuth={userAuth} setUserAuth={setUserAuth} setNavLinks={setNavLinks}/>)
                        }/>
                        <Route exact path="/settings/homepage/" render={props =>
                           (<EditHomepage {...props} overlayVisible={overlayVisible} setOverlayVisible={setOverlayVisible} resetAuth={resetAuth} userAuth={userAuth} setNavLinks={setNavLinks}/>)
                        }/>
                        <Route exact path="/login" render={props =>
                          (<Homepage {...props} overlayVisible={overlayVisible} setOverlayVisible={setOverlayVisible}  resetAuth={resetAuth} userAuth={userAuth} setUserAuth={setUserAuth}/>)
                        }/>
                        <Route exact path="/users/register/:token" render={props =>
                          (<RegisterUser {...props} overlayVisible={overlayVisible} setOverlayVisible={setOverlayVisible}/>)
                        }/>
                        <Route exact path="/users/reset/:token" render={props =>
                          (<ResetPassword {...props} userAuth={userAuth} overlayVisible={overlayVisible} setOverlayVisible={setOverlayVisible}  />)
                        }/>
                        <Route exact path="/settings" render={props =>
                          (<Settings {...props} overlayVisible={overlayVisible} setOverlayVisible={setOverlayVisible} resetAuth={resetAuth} userAuth={userAuth} setNavLinks={setNavLinks}/>)
                        }/>

                    </Switch>
                </React.Fragment>
                ) : (
                <Switch>
                    <Route exact path="/" render={props =>
                      (<Homepage {...props} resetAuth={resetAuth} userAuth={userAuth} setUserAuth={setUserAuth}/>)
                    }/>
                    <Route exact path="/users/register/:token" render={props =>
                      (<RegisterUser {...props} userAuth={userAuth} overlayVisible={overlayVisible} setOverlayVisible={setOverlayVisible}/>)
                    }/>
                    <Route exact path="/users/reset/" render={props =>
                      (<ResetPassword {...props} userAuth={userAuth} overlayVisible={overlayVisible} setOverlayVisible={setOverlayVisible}  />)
                    }/>
                    <Route exact path="/users/reset/:token" render={props =>
                      (<ResetPassword {...props} userAuth={userAuth} overlayVisible={overlayVisible} setOverlayVisible={setOverlayVisible}  />)
                    }/>
                    <Redirect to='/' />

                </Switch>
                )}
            </div>
        </Router>
    );
}

export default App;
