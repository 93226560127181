import React, {useEffect, useState}  from 'react';
import axios from 'axios';
import {useDropzone} from 'react-dropzone'
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import CorcSelect from 'components/utils/CorcSelect';
import {initDate} from 'components/utils/Helpers.js';

function PatientsVisitSubmit(props) {

    const [equipmentOptions, setEquipmentOptions] = useState([{value:"-1", label: "N/A"}]);
    const [operatorOptions, setOperatorOptions] = useState([{value:"-1", label: "N/A"}]);
    const [confirmSubmitPopup, setConfirmSubmitPopup] = useState(false);
    const [submitErrorMessagesPopup, setSubmitErrorMessagesPopup] = useState([]);
    const [showAdminFiles, setShowAdminFiles] = useState(true);
    const [uploadedFiles, setUploadedFiles] = useState([]);
    const [initialVisit, setInitialVisit] = useState({
        id: 0,
        exam_date: '',
        patient: {
            study: {},
            center: {},
        },
        study_exam: {
            exam_type: {},
        },
        user_submitted: {},
        operator: {},
        user_graded: {},
        visit_files: [],
        related_visits: [],
        form_vars: [],
        equipment_certification: 0,
    });

    const [visit, setVisit] = useState({
        id: '',
        patient: {
            study: {},
            center: {},
        },
        exam_date: '',
        operator: 0,
        comment: '',
        study_exam: {
            exam_type: {},
        },
        left_eye_submit: false,
        right_eye_submit: false,
        missed_visit: false,
        form_vars: [],
        equipment_certification: 0,
    });

    const [percentUploaded, setPercentUploaded] = useState(0);

    useEffect(function() {

        const getVisit = async () => {
            let count = props.overlayVisible;
            props.setOverlayVisible( (count) => count + 1);

            await axios.get(process.env.REACT_APP_API_ENDPOINT+'/visits/'+props.match.params.visit+'/',
                { withCredentials: true, headers: {'Content-Type': 'application/json'}}).then(items => {

                // we cant submit a visit that has already been submitte
                setInitialVisit(items.data);

                props.setOverlayVisible( (count) => count - 1);


            }, (error) => {
                props.history.push('/studies/');
                props.setOverlayVisible( (count) => count - 1);
            });

        };

        // only admins and techs can submit exams in a visit
        if (props.userAuth.user.role !== 'tech' && props.userAuth.user.role !== 'coordinator') {
            props.history.push('/studies/'+props.match.params.id+'/exams/' + props.match.params.exam + '/patients/'+ props.match.params.patient + '/visits');
            return;
        }

        props.setNavLinks([
            {title: 'Studies', url: '/studies/', icon: 'studies'},
            {title: 'Patients', url: '/studies/' + props.match.params.id + '/exams/'+props.match.params.exam+'/patients', icon: 'pacients'},
            {title: 'Visits', url: '/studies/' + props.match.params.id + '/exams/' + props.match.params.exam + '/patients/' + props.match.params.patient + '/visits/', icon: 'visits'},
            {title: 'Submit', url: props.location.pathname, icon: 'submit'}
        ]);

        getVisit();

        props.setShowPrompt(true);
        window.addEventListener('beforeunload', props.showPromptHandler);
        //on unmount
        return () => {
            props.setShowPrompt(false);
            window.removeEventListener('beforeunload', props.showPromptHandler);
        };
    }, []);

    useEffect(function() {
        const getEquipment = async (exam_id) => {
            let count = props.overlayVisible;
            let operators = [{value:"-1", label: "N/A"}];
            props.setOverlayVisible( (count) => count + 1);

            const items = await axios.get(process.env.REACT_APP_API_ENDPOINT+'/studies/'+props.match.params.id+'/centers/'+props.userAuth.user.center.id+'/exam/'+exam_id + '/exam_equipment/',
                { withCredentials: true, headers: {'Content-Type': 'application/json'}});

            let eqOpts = [{value:"-1", label: "N/A"}];
            items.data.equipment_certifications.forEach( cert => {
                eqOpts.push({label: cert.equipment_make_model, value: cert.id});
            });

            setEquipmentOptions(eqOpts);

            props.setOverlayVisible( (count) => count - 1);
        };

        const getOperators = async () => {

            let count = props.overlayVisible;
            let operators = [{value:"-1", label: "N/A"}];
            props.setOverlayVisible( (count) => count + 1);

            const items = await axios.get(process.env.REACT_APP_API_ENDPOINT+'/studies/'+props.match.params.id+'/centers/'+props.userAuth.user.center.id+'/technicians/',
                { withCredentials: true, headers: {'Content-Type': 'application/json'}});

            items.data.technicians.forEach( (tech) => {
                let found = false;

                if (tech.has_permission) {
                    found = tech.exams.find(exam => exam.exam_type.id === initialVisit.study_exam.exam_type.id);
                    if (found) {

                        // does he need certification? if so, does he have it?
                        if (initialVisit.study_exam.technician_certification_required) {
                            found = tech.certifications.find(cert => cert.study_exam.exam_type.id === initialVisit.study_exam.exam_type.id && cert.status === 'certified');
                            if (found) {
                                operators.push({value: tech.user.id, label: tech.user.username+" ( "+tech.user.name+")"})
                                // are we one of the tecnicians that can submit this exam?
                                if (tech.user.id === props.userAuth.user.id) {
                                    setVisit({...visit, operator: tech.user.id});
                                }
                            }
                        }
                        else {
                            operators.push({value: tech.user.id, label: tech.user.username+" ( "+tech.user.name+")"})
                            // are we one of the tecnicians that can submit this exam?
                            if (tech.user.id === props.userAuth.user.id) {
                                setVisit({...visit, operator: tech.user.id});
                            }
                        }

                    }
                }

            });

            if (operators.length) {
                setOperatorOptions(operators);
            }

            props.setOverlayVisible( (count) => count - 1);

        };

        if(initialVisit.study_exam.hasOwnProperty('technician_form_vars')) {
            if(initialVisit.study_exam.technician_form_vars.find(t => t.technician_form_var.uid === 'operator')) {
                getOperators();
            }
        }

        if(initialVisit.study_exam.hasOwnProperty('technician_form_vars')) {
            if(initialVisit.study_exam.technician_form_vars.find(t => t.technician_form_var.uid === 'equipment')) {
                getEquipment(initialVisit.study_exam.exam_type.id);
            }
        }
    }, [initialVisit]);

    const onChangeHandler = (ev) => {
        let name = ev.target.name;
        let val = ev.target.value;

        setVisit({...visit, [name]: val});
    };

    const confirmSubmitHandler = (visit) => {
        setConfirmSubmitPopup(false);
        submitHandler(visit);
    }

    const validateVisit = () => {

        let errors = [];
        let editedVisit = JSON.parse(JSON.stringify(visit));

        editedVisit.comment = editedVisit.comment ? editedVisit.comment.trim() : "";
        editedVisit.operator = parseInt(editedVisit.operator, 10);
        editedVisit.equipment_certification = parseInt(editedVisit.equipment_certification, 10);
        if (isNaN(editedVisit.operator)) {
            editedVisit.operator = 0;
        }

        if (!editedVisit.missed_visit) {

            if(props.userAuth.user.role === 'coordinator' && !isGeneralVarVisible('operator')) {
                errors.push("You won't be able to submit this visit because the operator field is not available.");
            }

            if(props.userAuth.user.role === 'coordinator' && editedVisit.operator <= 0) {
                errors.push('As a coordinator you must select an operator for this exam.');
            }

            if (!uploadedFiles.length) {
                setUploadedFiles([]);
                errors.push('Please Submit one or more Files.');
            }

            // check if operator, date and submitted eye are valid
            if (isGeneralVarVisible('submitted-eye') && !editedVisit.left_eye_submit && !editedVisit.right_eye_submit) {
                errors.push('Please select a submitted eye for this exam.');
            }

            if (isGeneralVarVisible('operator') && editedVisit.operator <= 0) {
                errors.push('Please select the operator that performed the exam.');
            }

            if (isGeneralVarVisible('equipment') && editedVisit.equipment_certification <= 0) {
                errors.push('Please select the equipment used to perform the exam.');
            }

            if (isGeneralVarVisible('exam-date') && isNaN(Date.parse(editedVisit.exam_date))) {
                errors.push('Exam date is invalid.');
            }

            for (let i in editedVisit.form_vars) {
                let v = editedVisit.form_vars[i];
                if(v.var_type.field_type === 'input' || v.var_type.field_type === 'text') {
                    v.value = v.value.trim();
                }
            }
        }
        else {

            if(isGeneralVarVisible('comment') && !editedVisit.comment) {
                errors.push("Please write a comment regarding the reason for this missed visit.");
            }

        }

        if (errors.length) {
            setSubmitErrorMessagesPopup(errors);
            return false;
        }


        confirmSubmitHandler(editedVisit);

    }

    const closeSubmitErrorMessagesPopup = () => {
        setSubmitErrorMessagesPopup([]);
    }

    const closeConfirmSubmitPopup = () => {
        setConfirmSubmitPopup(false);
    }
    const submitHandler = (visit) => {

        let count = props.overlayVisible;
        const errMsg = "Sorry, something went wrong when adding this center. Please contact your IT helpdesk.";
        const url = process.env.REACT_APP_API_ENDPOINT+'/visits/'+initialVisit.id+'/';
        const formData = new FormData();
        const config = {
            withCredentials: true,
            headers: {
                'content-type': 'multipart/form-data'
            },
            onUploadProgress: progressEvent => {
                setPercentUploaded( Math.round( (progressEvent.loaded * 100) / progressEvent.total ) );
            }
        };

        if (!visit.missed_visit) {
            for (var i in uploadedFiles) {
                formData.append('file',uploadedFiles[i].file);
            }
            formData.set('exam_date', visit.exam_date);
            formData.set('operator', visit.operator);
            formData.set('equipment_certification', visit.equipment_certification);
            formData.set('left_eye_submit', visit.left_eye_submit);
            formData.set('right_eye_submit', visit.right_eye_submit);
            formData.set('form_vars', JSON.stringify(visit.form_vars));
        }


        formData.set('missed_visit', visit.missed_visit);
        formData.set('comment', visit.comment);


        props.setFilesUploading(true);
        props.setOverlayVisible( (count) => count + 1);

        axios.patch(url, formData, config).then(function (response) {

            props.setOverlayVisible( (count) => count - 1);
            props.setFilesUploading(false);

            if (response.status === 200) {
                props.setShowPrompt(false);

                // redirect to list of visits of this patient
                props.history.push('/studies/'+props.match.params.id+'/exams/' + props.match.params.exam + '/patients/'+ props.match.params.patient + '/visits');
            }
            else {
                alert(errMsg);
            }

        }, (error) => {
            alert(errMsg);
            props.setOverlayVisible( (count) => count - 1);
            props.setFilesUploading(false);
        });
        return false;

    };

    const onChangeDate = (date) => {
        let txtDate = '';

        if (date) {
            txtDate = date.getFullYear() + '-' + (date.getMonth()+1) + '-' + date.getDate();
        }

        setVisit({...visit, 'exam_date': txtDate})
    };

    const submittedEyeOptions = [
        {value:"left", label: "Left"},
        {value:"right", label: "Right"},
        {value:"both", label: "Both"},
    ];

    const onSubmittedEyeChangeHandler = selected => {

        if (selected === "both") {
            setVisit({...visit, left_eye_submit: true, right_eye_submit: true});
        }
        else if (selected === "left") {
            setVisit({...visit, left_eye_submit: true, right_eye_submit: false});
        }
        else if (selected === "right") {
            setVisit({...visit, left_eye_submit: false, right_eye_submit: true});
        }
        else {
            setVisit({...visit, left_eye_submit: false, right_eye_submit: false});
        }
    }

    const onOperatorChangeHandler = selected => {
        setVisit({...visit, operator: selected})
    }

    const onEquipmentChangeHandler = selected => {
        setVisit({...visit, equipment_certification: selected})
    }

    const getCheckedSubmittedEye = () => {

        if (visit.left_eye_submit && visit.right_eye_submit) {
            return submittedEyeOptions.filter(o => o.value === "both");
        }
        else if (visit.left_eye_submit && !visit.right_eye_submit) {
            return submittedEyeOptions.filter(o => o.value === "left");
        }
        else if (!visit.left_eye_submit && visit.right_eye_submit) {
            return submittedEyeOptions.filter(o => o.value === "right");
        }
        else {
            return "";
        }

    }

    const missedCheckHandler = (e) => {
        let checked = e.target.checked;

        setVisit({...visit, exam_date: "", operator: "", equipment_certification: "", left_eye_submit: false, right_eye_submit: false, missed_visit: checked});
    };

    const isGeneralVarVisible = (uid) => {
        if(initialVisit.study_exam.hasOwnProperty('technician_form_vars')) {
            if(initialVisit.study_exam.technician_form_vars.find(v => v.technician_form_var.uid === uid && v.technician_form_var.group === 'general')) {
                return true;
            }
        }

        return false;
    };


    return (

        <React.Fragment>

        {submitErrorMessagesPopup.length > 0 && (
            <React.Fragment>
            <div className="modal-backdrop fade in"></div>
            <div id="alertModal" className="modal hide fade in" tabIndex="-1" role="dialog" aria-labelledby="myModalLabel" aria-hidden="false" style={{display: "block"}}>
                <div className="modal-header">
                    <button type="button" className="close" data-dismiss="modal" aria-hidden="true">×</button>
                    <h3 id="alert-modal-title">Warning!</h3>
                </div>
                <div className="modal-body"><br/>
                    <ul>
                    {submitErrorMessagesPopup.map( (msg, i) => (
                        <li key={i}>{msg}</li>
                    ))}
                    </ul>

                </div>

                <div className="modal-footer">
                    <button onClick={closeSubmitErrorMessagesPopup} data-dismiss="modal" id="alert-modal-cancel" aria-hidden="true" className="btn small grey align-center right">OK</button>
                </div>
            </div>
            </React.Fragment>
        )}

        {confirmSubmitPopup && (
            <React.Fragment>
            <div className="modal-backdrop fade in"></div>
            <div id="alertModal" className="modal hide fade in" tabIndex="-1" role="dialog" aria-labelledby="myModalLabel" aria-hidden="false" style={{display: "block"}}>
                <div className="modal-header">
                    <button type="button" className="close" data-dismiss="modal" aria-hidden="true">×</button>
                    <h3 id="alert-modal-title">Confirmation!</h3>
                </div>
                <div className="modal-body"><br/>Do you really want to finish and submit this Visit?<br/><br/></div>
                <div className="modal-footer">
                    <button onClick={validateVisit} id="alert-modal-ok" aria-hidden="true" className="btn small orange align-center right">Yes</button>
                    <button onClick={closeConfirmSubmitPopup} data-dismiss="modal" id="alert-modal-cancel" aria-hidden="true" className="btn small grey align-center right">No</button>
                </div>
            </div>
            </React.Fragment>
        )}

        <div className="home_header_container_class wide">
            <div className="content">
                <div className="breadcrumbs">
                    <div className="path">
                        <ul>
                            <li><span className="orange text-shadow">{initialVisit.patient.study.acronym ? initialVisit.patient.study.acronym.toUpperCase() : ''}</span></li>
                            <li className="separator text-shadow">&gt;</li>
                            <li><span className="orange text-shadow">{initialVisit.study_exam.exam_type.name}</span></li>
                            <li className="separator text-shadow">&gt;</li>
                            <li><span className="orange text-shadow">Patient {initialVisit.patient.patient_num}</span></li>
                            <li className="separator text-shadow">&gt;</li>
                            <li><span className="orange text-shadow">{initialVisit.scheduled_visit ? initialVisit.scheduled_visit.name : 'Unscheduled visit'}</span></li>
                            <li className="separator text-shadow">&gt;</li>
                            <li>Submit</li>

                        </ul>
                    </div>
                </div> {/* .breadcrumbs */}

                <div className="report-info">
                    <div className="content-report">
                        <div className="content-visit-exam">
                            <div className="visit-content">
                                <div className="main-form">
                                    <form id="register-exam" method="post">
                                        <div className="form-group">

                                            <div className="span5 insert-visit" style={{width:"46%"}}>

                                                {isGeneralVarVisible('exam-date') && (
                                                <div className="row exam-date" style={{paddingTop: "4px"}}>
                                                    <div className="label">
                                                        <label className="required">Exam Date *</label>
                                                    </div>

                                                    <div className="input">
                                                        <DatePicker
                                                            dateFormat="dd/MM/yyyy"
                                                            name="exam_date"
                                                            selected={visit.exam_date && initDate(visit.exam_date)}
                                                            onChange={(ev) => onChangeDate(ev)}
                                                            disabled={visit.missed_visit}
                                                        />
                                                    </div>
                                                </div>
                                                )}

                                                {isGeneralVarVisible('submitted-eye') && (
                                                <div className="row" style={{paddingTop: "4px"}}>
                                                    <div className="label">
                                                        <label className="required" htmlFor="submitted_eye">Submitted Eye(s) *</label>
                                                    </div>

                                                    <CorcSelect
                                                        options={submittedEyeOptions}
                                                        name="submitted_eye"
                                                        id="submitted_eye"
                                                        value={getCheckedSubmittedEye()}
                                                        onChange={selectedOption => onSubmittedEyeChangeHandler(selectedOption.value)}
                                                        isSearchable={false}
                                                        containerStyles={{width: "175px", float: "left"}}
                                                        isDisabled={visit.missed_visit ? "disabled" : ""}
                                                    />

                                                </div>
                                                )}

                                                {isGeneralVarVisible('operator') && (
                                                <div className="row" style={{paddingTop: "4px"}}>
                                                    <div className="label">
                                                        <label className="required" htmlFor="operator">Operator *</label>
                                                    </div>

                                                    <CorcSelect
                                                        options={operatorOptions}
                                                        name="operator"
                                                        id="operator"
                                                        value={operatorOptions.filter(o => o.value === visit.operator)}
                                                        onChange={selectedOption => onOperatorChangeHandler(selectedOption.value)}
                                                        isSearchable={false}
                                                        containerStyles={{width: "175px", float: "left"}}
                                                        isDisabled={visit.missed_visit ? "disabled" : ""}
                                                    />

                                                </div>
                                                )}

                                                {isGeneralVarVisible('equipment') && (
                                                <div className="row" style={{paddingTop: "4px"}}>
                                                    <div className="label">
                                                        <label className="required" htmlFor="equipment">Equipment *</label>
                                                    </div>

                                                    <CorcSelect
                                                        options={equipmentOptions}
                                                        name="equipment"
                                                        id="equipment"
                                                        value={equipmentOptions.filter(o => o.value === visit.equipment_certification)}
                                                        onChange={selectedOption => onEquipmentChangeHandler(selectedOption.value)}
                                                        isSearchable={false}
                                                        containerStyles={{width: "175px", float: "left"}}
                                                        isDisabled={visit.missed_visit ? "disabled" : ""}
                                                    />

                                                </div>
                                                )}
                                            </div> {/* .span5 */}

                                            <div className="span5" style={{width: "54%", marginBottom: "10px"}}>
                                                <div className="row" style={{paddingTop: "4px"}}>

                                                    <div
                                                        className="checkbox" style={{marginBottom: "10px"}}
                                                    >
                                                        <input
                                                            checked={visit.missed_visit}
                                                            value={true}
                                                            id="missed-visit"
                                                            name="missed-visit"
                                                            type="checkbox"
                                                            onChange={(e) => missedCheckHandler(e)}
                                                        />
                                                        <label htmlFor="missed-visit">Missed visit</label>
                                                    </div>

                                                    {isGeneralVarVisible('comment') && (
                                                    <div>
                                                        <textarea
                                                            className="submit-comment"
                                                            style={{margin: "0px 0", width: "81.5%"}}
                                                            name="comment"
                                                            onChange={(ev) => onChangeHandler(ev)}
                                                            value={visit.comment}
                                                            placeholder="Comment"
                                                        >
                                                        </textarea>
                                                    </div>
                                                    )}
                                                </div>{/* span5 */}
                                            </div>

                                        </div>

                                        <div className="form-group visit-other-vars">
                                            <VisitOtherVars
                                                initialVisit={initialVisit}
                                                visit={visit}
                                                setVisit={setVisit}
                                                initDate={initDate}
                                            />
                                        </div>

                                    </form>

                                    {!visit.missed_visit && (
                                    <VisitFiles
                                        visit={visit}
                                        setVisit={setVisit}
                                        showAdminFiles={showAdminFiles}
                                        setShowAdminFiles={setShowAdminFiles}
                                        uploadedFiles={uploadedFiles}
                                        setUploadedFiles={setUploadedFiles}
                                        userAuth={props.userAuth}
                                        percentUploaded={percentUploaded}
                                    />
                                    )}
                                </div> {/* .main-form */}
                            </div> {/* .visit-content */}
                        </div> {/* .content-visit-exam */}
                    </div> {/* .content-report */}
                </div>

            <div className="form-actions">
            <div style={{'textAlign': 'center', 'overflow': 'hidden'}}>
                <button onClick={validateVisit} className="btn-submit-form-two">
                    <span className="submit-icon"></span>
                    Finish and Submit
                </button>
            </div>

        </div>

            </div>
        </div>

        </React.Fragment>
    );
}

export default PatientsVisitSubmit;


function VisitFiles(props) {
    return (
        <React.Fragment>

            <VisitFileUpload
                showAdminFiles={props.showAdminFiles}
                setShowAdminFiles={props.setShowAdminFiles}
                uploadedFiles={props.uploadedFiles}
                setUploadedFiles={props.setUploadedFiles}
                visit={props.visit}
                setVisit={props.setVisit}
                userAuth={props.userAuth}
                percentUploaded={props.percentUploaded}
            />
        </React.Fragment>
    );
}


function VisitFileUpload(props) {
    const showAdminFilesHandler = () => {
        props.setShowAdminFiles(true);
    };


    const removeFileHandler = (ev,file) =>  {

        ev.preventDefault();

        let editedUploadedFiles = props.uploadedFiles.filter(f => f.key !== file.key);
        props.setUploadedFiles(editedUploadedFiles);

        return false;
    };

    const onDrop = acceptedFiles => {
        let newFiles = [];

        acceptedFiles.map(file => {
            newFiles.push({file: file, key: Math.floor(Math.random(5) * 1000), name: file.name});
            return false;
        });

        newFiles = newFiles.concat(props.uploadedFiles);
        props.setUploadedFiles(newFiles);
    };

    const {getRootProps, getInputProps} = useDropzone({onDrop});

    return (
        <React.Fragment>

        {props.showAdminFiles && (
        <form id="fileupload" method="post" encType="multipart/form-data">
            <div className="form-group">
                <div id="files" className="files">
                    <div {...getRootProps({
                        className: "info"
                    })}>
                        <span className="icon submit ico"></span>
                        <h2 className="text-shadow">Drop files here</h2>
                        <span className="or">
                            <hr />
                            <span>or</span>
                            <hr />
                        </span>
                        <span className="btn large orange fileinput-button">
                            <span>Select files from your computer</span>
                            <input {...getInputProps()} />
                        </span>
                    </div>
                    <div id="filesProgress" className="clearfix">
                        {props.uploadedFiles.map((file, i) => (
                        <div
                            key={i}
                            className="file-upload-box">
                            <p>
                                <span className="file-name">{file.name}</span>
                                <span className="file-actions">
                                    <button
                                        className="btn small grey align-center left other"
                                        onClick={(ev) => removeFileHandler(ev, file)}
                                    >REMOVE</button>
                                </span>
                                <span className="progress active">
                                    <span className="progress-bar progress-bar-warning" role="progressbar" style={{width: `${props.percentUploaded}%`}}></span>
                                    <span className="progress_value">
                                        {props.percentUploaded ? `${props.percentUploaded} % (a few seconds left)` : 'READY TO UPLOAD' }
                                    </span>
                                </span>
                            </p>
                        </div>
                        ))}
                    </div>
                </div>
            </div>
        </form>
        )}
        </React.Fragment>
    );
}

function VisitOtherVars(props) {
    return (
        <React.Fragment>
        {props.initialVisit.study_exam.technician_form_vars && props.initialVisit.study_exam.technician_form_vars.map((v, i) => v.technician_form_var.group === 'other' && (
            <div key={i}>
                <VisitVarItem
                    field={v.technician_form_var}
                    initialVisit={props.initialVisit}
                    visit={props.visit}
                    setVisit={props.setVisit}
                    initDate={props.initDate}
                />
            </div>
        ))}
        </React.Fragment>
    );
}

function VisitVarItem(props) {

    const onListChangeHandler = (uid, selectedOption) => {
        let editedVisit = JSON.parse(JSON.stringify(props.visit));

        const form_var = editedVisit.study_exam.technician_form_vars.find(c => {
            return c.technician_form_var.uid === uid;
        });

        if (!form_var) {
            return false;
        }

        let found = editedVisit.form_vars.find(c => {
            if (c.var_type) {
                return form_var.uid === c.var_type.uid;
            }
            else {
                return undefined;
            }

        });

        if (found === undefined) {
            let cVar = {'visit': editedVisit.id, 'var_type': form_var, 'value': selectedOption};

            editedVisit.form_vars.push(cVar);
        }
        else {
            found.value = selectedOption;
        }

        props.setVisit(editedVisit);
    };

    const onChangeHandler = (ev) => {
        let name = ev.target.name;
        let val = ev.target.value.toString();
        let editedVisit = JSON.parse(JSON.stringify(props.visit));
        let tmp, tmp_array;

        let form_var = props.initialVisit.study_exam.technician_form_vars.find(c => {
            return c.technician_form_var.uid === name;
        })

        if (!form_var) {
            return false;
        }
        else {
            form_var = form_var.technician_form_var;
        }

        let found = editedVisit.form_vars.find(c => {
            if (c.var_type) {
                return form_var.uid === c.var_type.uid;
            }
            else {
                return undefined;
            }

        });

        if (found === undefined) {
            let cVar = {'visit': editedVisit.id, 'var_type': form_var, 'value': val};

            editedVisit.form_vars.push(cVar);
        }
        else {
            if (form_var.field_type === 'check_many') {
                tmp_array = found.value.split(",");
                tmp = tmp_array.indexOf(val);
                if (tmp === -1) {
                    tmp_array.push(val);
                    found.value = tmp_array.toString();
                }
                else {
                    tmp_array.splice(tmp, 1);
                    // is it the last value?
                    if (!tmp_array.length) {
                        for(let i=editedVisit.form_vars.length-1;i>=0;--i) {
                            if(editedVisit.form_vars[i].var_type.uid === form_var.uid) {
                                editedVisit.form_vars.splice(i, 1);
                                break;
                            }
                        }
                    }
                    found.value = tmp_array.toString();
                }
            }
            else {
                found.value = val;
            }
        }

        props.setVisit(editedVisit);

    };

    const onChangeDate = (date, form_var) => {
        let txtDate = '';
        let editedVisit = JSON.parse(JSON.stringify(props.visit));

        if (date) {
            txtDate = date.getFullYear() + '-' + (date.getMonth()+1) + '-' + date.getDate();
        }

        if (!form_var) {
            return false;
        }

        let found = editedVisit.form_vars.find(c => {
            if (c.var_type) {
                return form_var.uid === c.var_type.uid;
            }
            else {
                return undefined;
            }

        });

        if (found === undefined) {
            editedVisit.form_vars.push({'visit': editedVisit.id, 'value': txtDate, 'var_type': form_var});
        }
        else {
            found.value = txtDate;
        }

        props.setVisit(editedVisit);

    };

    const getVisitField = (field_id) => {

        let found = props.visit.form_vars.find(c => {
            if (c.var_type) {
                return c.var_type.id === field_id;
            }
            else {
                return undefined;
            }
        });

        if (found === undefined) {
            return "";
        }

        if(found.var_type.field_type === 'list') {
            let selOption = found.var_type.technician_vars_options.find(e => parseInt(e.id, 10) === parseInt(found.value, 10));
            return {label: selOption.value, value: selOption.id};
        }
        else {
            return found.value;
        }
    }

    const getMultipleVisitField = (field_id) => {

        let found = props.visit.form_vars.find(c => {
            if (c.var_type) {
                return c.var_type.id === field_id;
            }
            else {
                return undefined;
            }
        });

        if (found === undefined) {
            return [];
        }


        return found.value.split(",");
    }

    const buildField = (field) => {
        if(field.field_type === 'input') {
            return (
                <input
                    name={field.uid}
                    type="text"
                    value={getVisitField(field.id)}
                    onChange={(ev) => onChangeHandler(ev)}
                    disabled={props.visit.missed_visit}
                />
            );
        }
        else if(field.field_type === 'date') {
            return (
                <DatePicker
                    dateFormat="dd/MM/yyyy"
                    name={field.uid}
                    selected={getVisitField(field.id) && props.initDate(getVisitField(field.id))}
                    onChange={(date) => onChangeDate(date, field)}
                    isDisabled={props.visit.missed_visit ? "disabled" : ""}
                />
            );
        }
        else if(field.field_type === 'text') {
            return (
                <textarea
                    name={field.uid}
                    value={getVisitField(field.id)}
                    onChange={(ev) => onChangeHandler(ev)}
                    disabled={props.visit.missed_visit}
                >
                </textarea>
            );
        }
        else if(field.field_type === 'list') {
            let listOptions = [];
            field.technician_vars_options.map(opt => listOptions.push({label: opt.value, value: opt.id}));

            return (
                <CorcSelect
                    name={field.uid}
                    id={field.uid}
                    value={getVisitField(field.id)}
                    onChange={(selectedOption) => onListChangeHandler(field.uid, selectedOption.value)}
                    options={listOptions}
                    placeholder="-"
                    containerStyles={{width: "125px"}}
                    isDisabled={props.visit.missed_visit ? "disabled" : ""}
                />
            );
        }
        else if(field.field_type === 'check_one') {

            return (
                <div className="checkbox-group check">
                {field.technician_vars_options.map((opt, i) => (
                    <React.Fragment
                        key={i}
                    >
                    <input
                        value={opt.id}
                        id={field.uid + '_' + i}
                        name={field.uid}
                        type="radio"
                        checked={getVisitField(field.id) === opt.id.toString()}
                        onChange={(ev) => onChangeHandler(ev)}
                        disabled={props.visit.missed_visit}
                    />
                    <label htmlFor={field.uid + '_' + i}>{opt.value}</label>
                    </React.Fragment>
                ))}
                </div>
            );
        }
        else if(field.field_type === 'check_many') {

            return (
                <div className="checkbox-group check form-flex">
                {field.technician_vars_options.map((opt, i) => (
                    <div className="form-flex-3-col" key={i}>
                        <input
                            value={opt.id}
                            id={field.uid + '_' + i}
                            name={field.uid}
                            type="checkbox"
                            checked={getMultipleVisitField(field.id).indexOf(opt.id.toString()) !== -1}
                            onChange={(ev) => onChangeHandler(ev)}
                            disabled={props.visit.missed_visit}
                        />
                        <label htmlFor={field.uid + '_' + i}>{opt.value}</label>
                    </div>
                ))}
                </div>
            );
        }

        else if(field.field_type === 'eye') {
            return (
                <div className="checkbox-group check">
                    <input
                        value="right"
                        id={field.uid + '_re'}
                        name={field.uid}
                        type="radio"
                        checked={getVisitField(field.id) === 'right'}
                        onChange={(ev) => onChangeHandler(ev)}
                        disabled={props.visit.missed_visit}
                    />
                    <label htmlFor={field.uid + '_re'}>RE</label>
                    <input
                        value="left"
                        id={field.uid + '_le'}
                        name={field.uid}
                        type="radio"
                        checked={getVisitField(field.id) === 'left'}
                        onChange={(ev) => onChangeHandler(ev)}
                        disabled={props.visit.missed_visit}
                    />
                    <label htmlFor={field.uid + '_le'}>LE</label>
                    <input
                        value="both"
                        id={field.uid + '_rle'}
                        name={field.uid}
                        type="radio"
                        checked={getVisitField(field.id) === 'both'}
                        onChange={(ev) => onChangeHandler(ev)}
                        disabled={props.visit.missed_visit}
                    />
                    <label htmlFor={field.uid + '_rle'}>RLE</label>
                </div>
            );
        }
    };

    return (
        <div className="row" style={{paddingTop: "4px"}}>
            <div className="label">
                <label>{props.field.name}</label>
            </div>
            <div className="input">
                {buildField(props.field)}
            </div>
        </div>
    );
}

