import React, {useEffect, useState}  from 'react';
import axios from 'axios';
import {Link} from 'react-router-dom';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import CorcSelect from 'components/utils/CorcSelect';
import {initDate} from 'components/utils/Helpers.js';

function ViewPatient(props) {
    useEffect(function() {

        const getPatient = async () => {
            let count = props.overlayVisible;
            props.setOverlayVisible( (count) => count + 1);

            const items = await axios.get(process.env.REACT_APP_API_ENDPOINT+'/patients/'+props.match.params.patient+'/',
                { withCredentials: true, headers: {'Content-Type': 'application/json'}});

            setPatient(items.data);

            props.setOverlayVisible( (count) => count - 1);
        };

        props.setNavLinks([
            {title: 'Studies', url: '/studies/', icon: 'studies'},
            {title: 'View Patient', url: props.location.pathname, icon: 'pacients'}
        ]);
        getPatient();

    }, []);

    const [patient, setPatient] = useState({
        id: '',
        name: '',
        patient_num: '',
        comment: '',
        basal_date: '',
        study_left_eye: false,
        study_right_eye: false,
        card_vars: [],
        study: {}
    });

    const submitHandler = e => {
        e.preventDefault();
    };

    return (

        <React.Fragment>

        <div className="home_header_container_class wide">
            <div className="content">
                <div className="breadcrumbs">
                    <div className="path">
                        <ul>
                            <li>Patient details</li>
                        </ul>
                    </div>
                </div> {/* .breadcrumbs */}
                <div className="main-form">
                    <form onSubmit={submitHandler} id="register-participant" method="post">
                        <div className="form-group">
                            <div className="form-flex">
                                <PatientGeneralVars
                                    patient={patient}
                                    setPatient={setPatient}
                                    initDate={initDate}
                                    userAuth={props.userAuth}
                                />
                            </div>
                            <div className="visit-custom-vars form-flex">
                                <PatientOtherVars
                                    patient={patient}
                                    setPatient={setPatient}
                                    initDate={initDate}
                                />
                            </div>

                            <div className="span5" style={{width: "100%", marginBottom: "10px"}}>
                                <div className="row" style={{paddingTop: "4px"}}>
                                    <div className="label">
                                        <label>Comments</label>
                                    </div>
                                    <div>
                                        <textarea
                                            className="submit-comment"
                                            style={{margin: "0px 0", width: "81.5%"}}
                                            name="comment"
                                            value={patient.comment}
                                            disabled="disabled"
                                        >
                                        </textarea>
                                    </div>
                                </div>
                            </div>


                        </div> {/* .form-group  */}


                        <div className="overview">
                            <h2 className="text-shadow">Patient Card Information</h2>
                        </div>

                        <div className="form-group checkbox-group group-page">
                            <div className="row" style={{paddingTop: "4px"}}>

                                <div>
                                    <textarea value={patient.study.patient_details} className="submit-comment" disabled="disabled" style={{marginTop: "10px", width: "94.5% !important" }}></textarea>
                                </div>
                            </div>
                        </div>

                        <div className="form-actions" style={{float: "right"}}>
                            <Link to={`/studies/${props.match.params.id}/exams/${props.match.params.exam}/patients`} className="btn small grey align-center left">Back</Link>
                        </div> {/* .form-actions */}
                    </form>
                </div>
            </div>
        </div>
        </React.Fragment>
    );
}

export default ViewPatient;

function PatientGeneralVars(props) {

    return (
        <React.Fragment>

        <div className="form-flex-3-col" key={1}>
            <div className="row" style={{paddingTop: "4px"}}>
                <div className="label">
                    <label>Patient number *</label>
                </div>
                <div className="input">
                    <input
                        id="patient_num"
                        name="patient_num"
                        type="text"
                        value={props.patient.patient_num}
                        disabled="disabled"

                    />
                </div>
            </div>
        </div>
        {(props.userAuth.user.role === 'grader' || props.userAuth.user.role === 'admin') && (
        <div className="form-flex-3-col" key={2}>
            <div className="row" style={{paddingTop: "4px"}}>
                <div className="label">
                    <label>Basal date</label>
                </div>
                <div className="input">
                    <DatePicker
                        dateFormat="dd/MM/yyyy"
                        name="basal_date"
                        selected={props.patient.basal_date && props.initDate(props.patient.basal_date)}
                        disabled="disabled"
                    />
                </div>
            </div>
        </div>
        )}
        <div className="form-flex-3-col" key={3}>
            <div className="row" style={{paddingTop: "4px"}}>
                <div className="label">
                    <label>Study eye(s)</label>
                </div>
                <div className="input">
                    <div className="checkbox-group check">
                        <input
                            value="right"
                            id="study_eyes_re"
                            name="study_eyes"
                            type="radio"
                            checked={props.patient.study_right_eye}
                            disabled="disabled"
                        />
                        <label htmlFor="study_eyes_re">RE</label>
                        <input
                            value="left"
                            id="study_eyes_le"
                            name="study_eyes"
                            type="radio"
                            checked={props.patient.study_left_eye}
                            disabled="disabled"
                        />
                        <label htmlFor="study_eyes_le">LE</label>
                        <input
                            value="both"
                            id="study_eyes_rle"
                            name="study_eyes"
                            type="radio"
                            checked={props.patient.study_left_eye && props.patient.study_right_eye}
                            disabled="disabled"
                        />
                        <label htmlFor="study_eyes_rle">RLE</label>
                    </div>
                </div>
            </div>
        </div>

        {props.patient.study.patient_card_vars && props.patient.study.patient_card_vars.map((v, i) => v.group === 'general' && (
            <div className="form-flex-3-col" key={i+3}>
                <PatientVarItem
                    field={v}
                    patient={props.patient}
                    setPatient={props.setPatient}
                    initDate={props.initDate}
                />
            </div>
        ))}
        </React.Fragment>
    );
}

function PatientOtherVars(props) {
    return (
        <React.Fragment>
        {props.patient.study.patient_card_vars && props.patient.study.patient_card_vars.map((v, i) => v.group === 'other' && (
            <div className="form-flex-2-col" key={i}>
                <PatientVarItem
                    field={v}
                    patient={props.patient}
                    setPatient={props.setPatient}
                    initDate={props.initDate}
                />
            </div>
        ))}
        </React.Fragment>
    );
}

function PatientVarItem(props) {

    const onListChangeHandler = (uid, selectedOption) => {
        let editedPatient = JSON.parse(JSON.stringify(props.patient));

        const card_var = editedPatient.study.patient_card_vars.find(c => {
            return c.uid === uid;
        });

        if (!card_var) {
            return false;
        }

        let found = editedPatient.card_vars.find(c => {
            if (c.var_type) {
                return card_var.uid === c.var_type.uid;
            }
            else {
                return undefined;
            }

        });

        if (found === undefined) {
            let cVar = {'patient': editedPatient.id, 'var_type': card_var, 'value': selectedOption};

            editedPatient.card_vars.push(cVar);
        }
        else {
            found.value = selectedOption;
        }

        props.setPatient(editedPatient);
    };


    const getPatientField = (field_id) => {

        let found = props.patient.card_vars.find(c => {
            if (c.var_type) {
                return c.var_type.id === field_id;
            }
            else {
                return undefined;
            }
        });

        if (found === undefined) {
            return "";
        }

        if(found.var_type.field_type === 'list') {
            let selOption = found.var_type.card_vars_options.find(e => parseInt(e.id, 10) === parseInt(found.value, 10));
            return {label: selOption.value, value: selOption.id};
        }
        else {
            return found.value;
        }
    }

    const getMultiplePatientField = (field_id) => {

        let found = props.patient.card_vars.find(c => {
            if (c.var_type) {
                return c.var_type.id === field_id;
            }
            else {
                return undefined;
            }
        });

        if (found === undefined) {
            return [];
        }


        return found.value.split(",");
    }

    const buildField = (field) => {
        if(field.field_type === 'input') {
            return (
                <input
                    name={field.uid}
                    type="text"
                    value={getPatientField(field.id)}
                    disabled="disabled"
                />
            );
        }
        else if(field.field_type === 'date') {
            return (
                <DatePicker
                    dateFormat="dd/MM/yyyy"
                    name={field.uid}
                    selected={getPatientField(field.id) && props.initDate(getPatientField(field.id))}
                    disabled="disabled"
                />
            );
        }
        else if(field.field_type === 'text') {
            return (
                <textarea
                    name={field.uid}
                    value={getPatientField(field.id)}
                    disabled="disabled"
                >
                </textarea>
            );
        }
        else if(field.field_type === 'list') {
            let listOptions = [];
            field.card_vars_options.map(opt => listOptions.push({label: opt.value, value: opt.id}));

            return (
                <CorcSelect
                    name={field.uid}
                    id={field.uid}
                    value={getPatientField(field.id)}
                    options={listOptions}
                    placeholder="-"
                    containerStyles={{width: "125px"}}
                    isDisabled="disabled"
                />
            );
        }
        else if(field.field_type === 'check_one') {

            return (
                <div className="checkbox-group check">
                {field.card_vars_options.map((opt, i) => (
                    <React.Fragment
                        key={i}
                    >
                    <input
                        value={opt.id}
                        id={field.uid + '_' + i}
                        name={field.uid}
                        type="radio"
                        checked={getPatientField(field.id) === opt.id.toString()}
                        disabled="disabled"
                    />
                    <label htmlFor={field.uid + '_' + i}>{opt.value}</label>
                    </React.Fragment>
                ))}
                </div>
            );
        }
        else if(field.field_type === 'check_many') {

            return (
                <div className="checkbox-group check">
                {field.card_vars_options.map((opt, i) => (
                    <React.Fragment
                        key={i}
                    >
                    <input
                        value={opt.id}
                        id={field.uid + '_' + i}
                        name={field.uid}
                        type="checkbox"
                        checked={getMultiplePatientField(field.id).indexOf(opt.id.toString()) !== -1}
                        disabled="disabled"
                    />
                    <label htmlFor={field.uid + '_' + i}>{opt.value}</label>
                    </React.Fragment>
                ))}
                </div>
            );
        }

        else if(field.field_type === 'eye') {
            return (
                <div className="checkbox-group check">
                    <input
                        value="right"
                        id={field.uid + '_re'}
                        name={field.uid}
                        type="radio"
                        checked={getPatientField(field.id) === 'right'}
                        disabled="disabled"

                    />
                    <label htmlFor={field.uid + '_re'}>RE</label>
                    <input
                        value="left"
                        id={field.uid + '_le'}
                        name={field.uid}
                        type="radio"
                        checked={getPatientField(field.id) === 'left'}
                        disabled="disabled"
                    />
                    <label htmlFor={field.uid + '_le'}>LE</label>
                    <input
                        value="both"
                        id={field.uid + '_rle'}
                        name={field.uid}
                        type="radio"
                        checked={getPatientField(field.id) === 'both'}
                        disabled="disabled"
                    />
                    <label htmlFor={field.uid + '_rle'}>RLE</label>
                </div>
            );
        }
    };

    return (
        <div className="row" style={{paddingTop: "4px"}}>
            <div className="label">
                <label>{props.field.name}</label>
            </div>
            <div className="input">
                {buildField(props.field)}
            </div>
        </div>
    );
}

