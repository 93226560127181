import React, {useEffect, useState} from 'react';
import { Link } from 'react-router-dom';
import axios from 'axios';

function StudyListItem(props) {

    const userType = props.userAuth.user.role;

    const [examTypes, setExamTypes] = useState([]);
    const [certificationMissingPopup, setCertificationMissingPopup] = useState(false);


    let buttons, status = 'Ongoing';

    if(userType === 'admin' || userType === 'monitor') {
        buttons = <AdminMonitorButtons userType={userType} {...props}/>;
    }
    else {
        buttons = <TechGraderButtons {...props} certificationMissingPopup={certificationMissingPopup} setCertificationMissingPopup={setCertificationMissingPopup} examTypes={examTypes} setExamTypes={setExamTypes} studyId={props.studyId} />;
    }

    if (props.status === 'closed') {
        status = 'Closed';
    }

    return (

        <React.Fragment>

        {certificationMissingPopup && (
            <TechCertificationMissing setCertificationMissingPopup={setCertificationMissingPopup}/>
        )}


        <div className="studie shadow">
            <div className="studie-title">
                <span>{ props.acronym.toUpperCase() }</span>
                <label>{status}</label>
            </div>
            <div className="studie-info">
                <div className="block">
                    <table className="studie-details" border="1">
                        <tbody>
                            <tr>
                                <td className="total">
                                    <span>{ props.totalPatients }</span>
                                    <label>Total Study Patients</label>
                                </td>
                                <td className="grid-details">
                                    <ul>
                                        <li>Screening failure: <label>{ props.totalFailure }</label></li>
                                        <li>Enrolled: <label>{ props.totalEnrolled }</label></li>
                                    </ul>
                                </td>
                            </tr>
                            <tr>
                                <td className="total">
                                    <span>{ props.totalVisits }</span>
                                    <label>Total Study Visits</label>
                                </td>
                                <td className="grid-details">
                                    <ul>
                                        <li>Ongoing: <label>{ props.totalOngoing }</label></li>
                                        <li>Completed: <label>{ props.totalCompleted }</label></li>
                                        <li>Dropped-out: <label>{ props.totalDroppedOut }</label></li>
                                        <li>Reached End Point: <label>{ props.totalEndpoint }</label></li>
                                    </ul>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>

                <div className="block">
                    <ul className="button-group">
                        {buttons}

                        { (userType === 'monitor' || userType === 'admin')  && (
                        <li>
                            <Link to={`/studies/${props.studyId}/users`} id="users-list-link" className="btn large grey">
                                <span className="icon user-small"></span>
                                {props.userAuth.user.role === 'monitor' ? 'List Users' : 'Manage Users'}
                            </Link>
                        </li>
                        )}

                    </ul>
                </div>

                <div className="block">
                    <ul className="button-group">

                        { (userType === 'monitor' || userType === 'admin' || userType === 'grader')  && (
                        <li>
                            <Link to={`/studies/${props.studyId}/navigate`} id="navigate-link" className="btn large grey">
                                <span className="icon navigate-small"></span>
                                Navigate
                            </Link>
                        </li>
                        )}


                        <li>
                            <Link to={`/studies/${props.studyId}/documentation`} id="documentation-link" className="btn large grey">
                                <span className="icon study"></span>
                                Study Documentation
                            </Link>
                        </li>

                        { userType !== 'tech' && userType !== 'coordinator' && (
                        <li>
                            <Link to={`/studies/${props.studyId}/certifications/`} id="certifications-link" className="btn large grey">
                                <span className="icon certification-small"></span>
                                Certifications
                            </Link>
                        </li>
                        )}

                        { userType === 'tech' && props.requiresCertification && (
                        <li>
                            <Link to={`/studies/${props.studyId}/certifications/`} id="certifications-link" className="btn large grey">
                                <span className="icon certification-small"></span>
                                Certifications
                            </Link>
                        </li>
                        )}



                    </ul>
                </div>
            </div>
        </div>
        </React.Fragment>
    );
}

export default StudyListItem;


function AdminMonitorButtons(props) {


    const FileDownload = require('js-file-download');
    const alertArchiveStudy = async(e) => {

        let count = props.overlayVisible;
        props.setOverlayVisible( (count) => count + 1);
        await axios.get(process.env.REACT_APP_API_ENDPOINT+'/studies/'+props.studyId+'/archive/', { withCredentials: true})
           .then((response) => {
                FileDownload(response.data, 'study.xml');
           });
        props.setOverlayVisible( (count) => count - 1);
    };

    return (
        <React.Fragment>
            <li><Link to={`/studies/${props.studyId}`} id="study-details" className="btn large grey "><span className="icon-list study-details-small"></span>Study details</Link></li>

            {/*li><Link to={`/studies/${props.studyId}/customlists/`} id="study-custom-lists" className="btn large grey "><span className="icon-list report"></span>Custom Lists</Link></li>*/}

        { /*props.userType === 'admin' && props.status === 'closed' && (
            <li><button className="btn large grey " onClick={alertArchiveStudy}><span className="icon-list danger"></span>Archive Study</button></li>
        )*/}

        </React.Fragment>
    );
}

function TechGraderButtons(props) {

    const [techCertifications, setTechCertifications] = useState([]);
    const [equipCertifications, setEquipCertifications] = useState([]);

    useEffect(function() {

        const getUser = async () => {
            let count = props.overlayVisible;
            props.setOverlayVisible( (count) => count + 1);
            await axios.get(process.env.REACT_APP_API_ENDPOINT+'/studies/'+props.studyId+'/users/'+props.userAuth.user.id+'/', { withCredentials: true}).then(response => {

                if (response.status === 200 && response.data.hasOwnProperty('exams')) {
                    props.setExamTypes(response.data.exams);
                    setTechCertifications(response.data.certifications);
                    setEquipCertifications(response.data.equipment_certifications);
                }

            });
            props.setOverlayVisible( (count) => count - 1);
        };

        getUser();
    }, []);

    const checkCertificationForExam = async (exam) => {

        if (props.userAuth.user.role === 'tech') {

            let count = props.overlayVisible;
            props.setOverlayVisible( (count) => count + 1);
            await axios.get(process.env.REACT_APP_API_ENDPOINT+'/studies/'+props.studyId+'/users/'+props.userAuth.user.id+'/', { withCredentials: true}).then(response => {

                if (response.status === 200 && response.data.hasOwnProperty('exams')) {
                    props.setExamTypes(response.data.exams);
                    setTechCertifications(response.data.certifications);
                    setEquipCertifications(response.data.equipment_certifications);

                    let techCert = techCertifications.find(e => e.study_exam.id === exam.id && e.status === 'certified' && e.archived === false);
                    let equipCert = equipCertifications.find(e => e.study_exam.id === exam.id && e.status === 'certified' && e.archived === false);

                    if ( (exam.technician_certification_required && !techCert) || (exam.equipment_certification_required && !equipCert)) {
                        props.setCertificationMissingPopup(true);
                    }
                    else {
                        props.history.push('/studies/'+props.studyId+'/exams/'+exam.id+'/patients');    
                    }
                }

                props.setOverlayVisible( (count) => count - 1);

            });
            

        }

        // redirect user to list of visits per exam
        else if (props.userAuth.user.role === 'coordinator') {
            props.history.push('/studies/'+props.studyId+'/exams/'+exam.id+'/patients');
        }
        else if (props.userAuth.user.role === 'grader') {
            props.history.push('/studies/'+props.studyId+'/exams/'+exam.id);
        }
    };

    return (
            <React.Fragment>

            {props.examTypes && props.examTypes.map((exam) => (

            <li key={exam.id}>
                <button onClick={() => checkCertificationForExam(exam)}  id={ exam.id } className="btn large orange">
                    <span className="icon upload"></span>
                    {exam.exam_type.name}

                    {props.userAuth.user.role === 'grader' && exam.pending_visits > 0 && (
                    <span className="label orange right">{exam.pending_visits}</span>
                    )}

                    {props.userAuth.user.role === 'grader' && !exam.pending_visits && (
                    <span className="label grey right">{exam.pending_visits}</span>
                    )}


                </button>
            </li>
            ))}
            </React.Fragment>

    );

}

function TechCertificationMissing(props) {

    return (
        <React.Fragment>
            <div id="alertModal" className="modal hide fade in" tabIndex="-1" role="dialog" aria-labelledby="myModalLabel" aria-hidden="false" style={{display: "block"}}>
                <div className="modal-header">
                    <button type="button" className="close" data-dismiss="modal" aria-hidden="true">×</button>
                    <h3 id="alert-modal-title">Warning!</h3>
                </div>
                <div className="modal-body">
                    <p>Missing Technician or Equipment Certification.</p>
                </div>
                <div className="modal-footer">
                    <button onClick={() => props.setCertificationMissingPopup(false)} data-dismiss="modal" id="alert-modal-ok" aria-hidden="true" className="btn small orange align-center right">OK</button>
                </div>
            </div>
            <div className="modal-backdrop fade in"></div>
        </React.Fragment>
    )


}
