import React, {useEffect, useState}  from 'react';
import axios, {post} from 'axios';
import {useDropzone} from 'react-dropzone'
import {Link} from 'react-router-dom';
import ReactHtmlParser from 'react-html-parser';
import CorcSelect from 'components/utils/CorcSelect';
import {formatDate} from 'components/utils/Helpers.js';
const prettyBytes = require('pretty-bytes');

function EquipmentCertificationReport(props) {

    useEffect(function() {

        const getCertification = async () => {
            let count = props.overlayVisible;
            props.setOverlayVisible( (count) => count + 1);

            axios.get(process.env.REACT_APP_API_ENDPOINT+'/certifications/equipment/'+props.match.params.certification+'/',
                { withCredentials: true, headers: {'Content-Type': 'application/json'}})
                .then((response) => {
                    props.setOverlayVisible( (count) => count - 1);

                    if(response.data.equipment_type) {
                        setShowEquipmentType(true);
                        setEquipmentOptions({label: response.data.equipment_type.name, value: response.data.equipment_type.id});
                        response.data.equipment_type = response.data.equipment_type.id;
                    }

                    if (response.data.study_exam.exam_type.has_equipments_with_camera) {
                        setShowCameraFields(true);
                    }

                    setCertification(response.data);
                })
                .catch((error) => {
                    console.log(error)
                    props.setOverlayVisible( (count) => count - 1);
                    props.history.push("/studies/"+props.match.params.id+"/certifications");
                })
        };


        props.setNavLinks([{title: 'Studies', url: '/studies/', icon: 'studies'},{title: 'Certification', url: '/studies/'+props.match.params.id+'/certifications/', icon: 'certification'},{title: 'Equipment', url: props.location.pathname, icon: 'submit'}]);

        getCertification();

        props.setShowPrompt(true);
        window.addEventListener('beforeunload', props.showPromptHandler);
        //on unmount
        return () => {
            props.setShowPrompt(false);
            window.removeEventListener('beforeunload', props.showPromptHandler);
        };
    }, []);


    const certListURL = `/studies/${props.match.params.id}/certifications`;
    const [certification, setCertification] = useState({
        "id": 0,
        "technician": {id: 0, username: ''},
        "study_exam": {id: 0, exam_type: {id: 0, name: ''}},
        "study_center": {id: 0, study_site_num: ''},
        "status": "",
        "submission_date": "",
        "evaluation_date": "",
        "equipment_make_model": "",
        "camera_make_model": "",
        "camera_resolution": "",
        "serial_num": "",
        "software_version": "",
        "submitter_comment": "",
        "reject_message": "",
        "study": {},
        "equipment_files": [],
    });

    const [noFiles, setNoFiles] = useState(false);
    const [noFilesErrorPopup, setNoFilesErrorPopup] = useState(false);
    const [showAdminFiles, setShowAdminFiles] = useState(false);
    const [uploadedFiles, setUploadedFiles] = useState([]);
    const [uploadedFilesComment, setUploadedFilesComment] = useState("");
    const [showCameraFields, setShowCameraFields] = useState(false)

    const [showRenameFilePopup, setShowRenameFilePopup] = useState(false);
    const [renameFileName, setRenameFileName] = useState("");
    const [renameFileId, setRenameFileId] = useState(0);

    const [showEquipmentType, setShowEquipmentType] = useState(false);
    const [equipmentOptions, setEquipmentOptions] = useState([]);

    const [percentUploaded, setPercentUploaded] = useState(0);

    const closeNoFilesPopup = () => {
        setNoFilesErrorPopup(false);
    }

    const submitHandler = e => {
        e.preventDefault();
    };

    const closeRenameFilePopup = () => {
        setRenameFileName("");
        setRenameFileId(0);
        setShowRenameFilePopup(false);
    };

    const submitRenameFilePopup = async () => {

        const errMsg = "Sorry, something went wrong when renaming this file. Please contact your IT helpdesk.";
        let name = renameFileName;

        // check if name is trimmed
        if (!name || !name.trim()) {
            return false;
        }

        name = name.trim();

        let count = props.overlayVisible;
        props.setOverlayVisible( (count) => count + 1);

        // change status of exam
        await axios.post(process.env.REACT_APP_API_ENDPOINT+'/certifications/equipment/'+certification.id+'/file/'+ renameFileId +'/rename/', {'name': name}, { withCredentials: true})
            .then((response) => {

            let files, ext;
            if (response.status === 200) {

                files = certification.equipment_files.map(f => {
                    if (f.id == renameFileId) {
                        ext = f.name.lastIndexOf('.');
                        if (ext !== -1) {
                            f.name = name + f.name.substr(ext);
                        }
                        else {
                            f.name = name;
                        }
                    }

                    return f;

                })

                setCertification({...certification, 'equipment_files': files});
                setRenameFileName("");
                setRenameFileId(0);
                setShowRenameFilePopup(false);
                props.setOverlayVisible( (count) => count - 1);
            }
            else {
                props.setOverlayVisible( (count) => count - 1);
                alert(errMsg);
            }

        }, (error) => {
            props.setOverlayVisible( (count) => count - 1);
            alert(errMsg);
        });

    };

    return (

        <React.Fragment>

            {showRenameFilePopup && (
                <React.Fragment>
                <div className="modal-backdrop fade in"></div>
                <div id="alertModal" className="modal hide fade in" tabIndex="-1" role="dialog" aria-labelledby="myModalLabel" aria-hidden="false" style={{display: "block"}}>
                    <div className="modal-body"><br/>Do you want to change the name of this file?<br/><br/>

                        <input
                            type="text"
                            name="rename-file"
                            style={{width: "90%"}}
                            onChange={(ev) => setRenameFileName(ev.target.value)}
                            value={renameFileName}>
                        </input>
                    </div>

                    <div className="modal-footer">
                        <button onClick={submitRenameFilePopup} id="alert-modal-ok" aria-hidden="true" className="btn small orange align-center right">Save</button>
                        <button onClick={closeRenameFilePopup} data-dismiss="modal" id="alert-modal-cancel" aria-hidden="true" className="btn small grey align-center right">Cancel</button>
                    </div>
                </div>
                </React.Fragment>
            )}

            {noFilesErrorPopup && (

                <React.Fragment>
                <div className="modal-backdrop fade in"></div>
                <div id="alertModal" className="modal hide fade in" tabIndex="-1" role="dialog" aria-labelledby="myModalLabel" aria-hidden="false" style={{display: "block"}}>
                    <div className="modal-header">
                        <button type="button" className="close" data-dismiss="modal" aria-hidden="true">×</button>
                        <h3 id="alert-modal-title">Warning!</h3>
                    </div>
                    <div className="modal-body"><br/><br/>You are submitting without files. Please add files or check the checkbox<br/></div>
                    <div className="modal-footer">
                        <button onClick={closeNoFilesPopup} data-dismiss="modal" id="alert-modal-ok" aria-hidden="true" className="btn small orange align-center right">OK</button>
                    </div>
                </div>
                </React.Fragment>
            )}

            <div className="home_header_container_class">
                <div className="content">
                    <div className="breadcrumbs">
                        <div className="path">
                            <ul>
                                <li><span className="orange text-shadow">{certification.study && certification.study.acronym ? certification.study.acronym.toUpperCase() : ""}</span></li>
                                <li className="separator text-shadow">&gt;</li>
                                <li><span className="orange text-shadow">Certifications</span></li>
                                <li className="separator text-shadow">&gt;</li>
                                <li><span className="orange text-shadow">Equipment</span></li>
                                <li className="separator text-shadow">&gt;</li>
                                <li><span className="orange text-shadow">{certification.study_exam && certification.study_exam.exam_type.name}</span></li>
                                <li className="separator text-shadow">&gt;</li>
                                <li>{certification.study_center.study_site_num}</li>
                            </ul>
                        </div>
                    </div> {/* .breadcrumbs */}

                    <div className="main-form">
                        <form onSubmit={submitHandler} id="register-certification" method="post">
                            <EquipmentCertificationSubmissionInfo
                                certification={certification}
                            />

                            <EquipmentCertificationData
                                userAuth={props.userAuth}
                                certification={certification}
                                setCertification={setCertification}
                                showCameraFields={showCameraFields}
                                setShowCameraFields={setShowCameraFields}
                                showEquipmentType={showEquipmentType}
                                equipmentOptions={equipmentOptions}
                            />

                        </form>

                        <EquipmentCertificationFiles
                            setOverlayVisible={props.setOverlayVisible}
                            certification={certification}
                            setCertification={setCertification}
                            noFiles={noFiles}
                            setNoFiles={setNoFiles}
                            showAdminFiles={showAdminFiles}
                            setShowAdminFiles={setShowAdminFiles}
                            userType={props.userAuth.user.role}
                            uploadedFiles={uploadedFiles}
                            setUploadedFiles={setUploadedFiles}
                            setUploadedFilesComment={setUploadedFilesComment}
                            uploadedFilesComment={uploadedFilesComment}
                            setShowRenameFilePopup={setShowRenameFilePopup}
                            setRenameFileName={setRenameFileName}
                            setRenameFileId={setRenameFileId}
                            setOverlayVisible={props.setOverlayVisible}
                            overlayVisible={props.overlayVisible}
                            percentUploaded={percentUploaded}
                            setPercentUploaded={setPercentUploaded}
                            setFilesUploading={props.setFilesUploading}
                            setShowPrompt={props.setShowPrompt}
                            history={props.history}
                            certListURL={certListURL}
                        />

                        {(certification.status === 'notcertified' || certification.status === 'certified' || certification.status === 'pending') && (

                        <EquipmentCertificationGraderComment
                            certification={certification}
                        />
                        )}

                        <EquipmentCertificationActions
                            userType={props.userAuth.user.role}
                            setNoFilesErrorPopup={setNoFilesErrorPopup}
                            noFiles={noFiles}
                            uploadedFiles={uploadedFiles}
                            setUploadedFiles={setUploadedFiles}
                            certification={certification}
                            setCertification={setCertification}
                            setOverlayVisible={props.setOverlayVisible}
                            overlayVisible={props.overlayVisible}
                        />
                    </div>
                </div> {/* .content */}
            </div>
        </React.Fragment>
    );
}

export default EquipmentCertificationReport;

function EquipmentCertificationSubmissionInfo(props) {

    let evaluatedBy = "", submittedBy = "";

    const getStatusDisplay = status => {

        if (status === 'notstarted') {
            return 'Not Started';
        }
        else if (status === 'pending') {
            return 'Pending';
        }
        else if (status === 'notcertified') {
            return 'Not Certified'
        }
        else if (status === 'incertification') {
            return 'In Certification'
        }
        else if (status === 'certified') {
            return 'Certified'
        }

        return '';

    }

    if (props.certification.user_graded) {
        evaluatedBy = '<p>EVALUATED BY: '+props.certification.user_graded.name +' '+ props.certification.user_graded.last_name +' ('+props.certification.user_graded.username+')</p><p>EVALUATED ON: '+formatDate(props.certification.grading_date, true)+'</p><br />';
    }

    if (props.certification.user_submitted) {
        submittedBy = '<p>SUBMITTED BY: '+props.certification.user_submitted.name +' '+props.certification.user_submitted.last_name +' ('+props.certification.user_submitted.username+')</p><p>SUBMITTED ON: '+formatDate(props.certification.submission_date, true)+'</p><br />';
    }

    return (
        <div className="form-group content">
            <br />

            { ReactHtmlParser (evaluatedBy) }
            { ReactHtmlParser (submittedBy) }

            <p>CERTIFICATION STATUS: {getStatusDisplay(props.certification.status)}</p>
        </div>
    );
}

function EquipmentCertificationData(props) {

    return (
        <div className="form-group">
            <div className="span5 insert-visit" style={{width: "46%"}}>

                <div className="row" >
                    <div className="label">
                        <label htmlFor="SubmitEquipmentForm_examId">Exam</label>
                    </div>
                    <div className="input">
                        <CorcSelect
                            id="study_exam"
                            name="study_exam"
                            value={{label: props.certification.study_exam.exam_type.name, value: props.certification.study_exam.id}}
                            placeholder="----"
                            isSearchable={false}
                            isDisabled={true}
                            containerStyles={{width: "300px"}}
                        />
                    </div>
                </div> {/* .row */}

                <div className="row" >
                    <div className="label">
                        <label htmlFor="SubmitEquipmentForm_operator">Operator</label>
                    </div>
                    <div className="input">
                        <CorcSelect
                            name="technician"
                            id="technician"
                            isDisabled={true}
                            value={{label: props.certification.technician.username, value: props.certification.technician.id}}
                            isSearchable={false}
                            placeholder="----"
                            containerStyles={{width: "300px"}}
                        />
                    </div>
                </div> {/* .row */}

                {(props.userAuth.user.role === 'admin' || props.userAuth.user.role === 'grader') && props.showEquipmentType && (
                <div className="row" >
                    <div className="label">
                        <label>Type</label>
                    </div>
                    <div className="input">
                        <CorcSelect
                            id="equipment_type"
                            name="equipment_type"
                            isDisabled={true}
                            value={props.equipmentOptions}
                            placeholder="----"
                            isSearchable={false}
                            containerStyles={{width: "300px"}}
                        />
                    </div>
                </div>
                )}

                {props.showCameraFields && (
                <React.Fragment>
                <div className="row">
                    <div className="label">
                        <label htmlFor="SubmitEquipmentForm_cameraMakeModel" className="required">Camera Make Model *</label>
                    </div>
                    <div className="input">
                        <input
                            name="camera_make_model"
                            id="camera_make_model"
                            type="text"
                            disabled={true}
                            value={props.certification.camera_make_model}
                        />
                    </div>
                </div>

                <div className="row" >
                    <div className="label">
                        <label htmlFor="SubmitEquipmentForm_cameraResolution" className="required">Camera Resolution *</label>
                    </div>
                    <div className="input">
                        <input
                            name="camera_resolution"
                            id="camera_resolution"
                            type="text"
                            disabled={true}
                            value={props.certification.camera_resolution}
                        />
                    </div>
                </div>
                </React.Fragment>
                )}

                <div className="row" >
                    <div className="label">
                        <label htmlFor="SubmitEquipmentForm_equipmentName" className="required">Equipment Make and Model *</label>
                    </div>
                    <div className="input">
                        <input
                            name="equipment_make_model"
                            id="name"
                            type="text"
                            disabled={true}
                            value={props.certification.equipment_make_model}
                        />
                    </div>
                </div> {/* .row */}

                <div className="row" >
                    <div className="label">
                        <label htmlFor="SubmitEquipmentForm_serialNumber" className="required">Serial Number *</label>
                    </div>
                    <div className="input">
                        <input
                            name="serial_num"
                            id="serial_num"
                            type="text"
                            maxLength="100"
                            disabled={true}
                            value={props.certification.serial_num}
                        />
                    </div>
                </div> {/* .row */}


                <div className="row" >
                    <div className="label">
                        <label htmlFor="SubmitEquipmentForm_softwareVersion" className="required">Software Version *</label>
                    </div>
                    <div className="input">
                        <input
                            name="software_version"
                            id="software_version"
                            type="text"
                            maxLength="100"
                            disabled={true}
                            value={props.certification.software_version}
                        />
                    </div>
                </div> {/* .row */}

            </div>

            <div className="span5" style={{width: "54%"}}>
                <textarea
                    style={{height: "200px"}}
                    className="submit-comment"
                    maxLength="5000"
                    placeholder="Comments"
                    name="submitter_comment"
                    id="submitter_comment"
                    disabled={true}
                    value={props.certification.submitter_comment}
                ></textarea>
            </div>
        </div>
    );
}


function EquipmentCertificationFiles(props) {

    return (
        <React.Fragment>


        {props.certification.equipment_files.map( (file, i) => (
        <EquipmentCertificationFileItem
            certification={props.certification}
            key={i}
            file={file}
            userType={props.userType}
            setShowRenameFilePopup={props.setShowRenameFilePopup}
            setRenameFileName={props.setRenameFileName}
            setRenameFileId={props.setRenameFileId}
        />
        ))}

        {props.userType === 'admin' && props.certification.study.status === 'open' && !props.certification.archived && (
        <EquipmentCertificationFileUploaderAdmin
            setUploadedFilesComment={props.setUploadedFilesComment}
            uploadedFilesComment={props.uploadedFilesComment}
            showAdminFiles={props.showAdminFiles}
            setShowAdminFiles={props.setShowAdminFiles}
            uploadedFiles={props.uploadedFiles}
            setUploadedFiles={props.setUploadedFiles}
            setCertification={props.setCertification}
            certification={props.certification}
            percentUploaded={props.percentUploaded}
            setPercentUploaded={props.setPercentUploaded}
            setOverlayVisible={props.setOverlayVisible}
            setFilesUploading={props.setFilesUploading}
            setShowPrompt={props.setShowPrompt}
            certListURL={props.certListURL}
            history={props.history}
        />
        )}

        </React.Fragment>
    );
}


function EquipmentCertificationFileItem(props) {


    const style = {
        width: "40%",
        float: "right",
        marginRight: "32px",
        display: "block"
    }

    const renameFile = () => {

        let name = props.file ? props.file.name : '';
        let id = props.file ? props.file.id : 0;
        let ext = name.lastIndexOf('.');

        if (ext !== -1) {
            name = name.substr(0, name.lastIndexOf('.'));
        }

        props.setRenameFileName(name);
        props.setRenameFileId(id);
        props.setShowRenameFilePopup(true);
    }

    return (
        <div className="form-group">
            <div className="file-uploaded">
                <div className="row">

                    <a target="_blank" rel="noopener noreferrer" href={process.env.REACT_APP_API_ENDPOINT + "/certifications/equipment/"+props.certification.id+'/file/'+props.file.id+'/download/'} className="cancel-btn-class btn small grey align-center">Download</a>

                    {props.userType != 'admin' && (
                    <div className="file-name">
                        <span className="filename-class">{props.file.name}<b>{" (" + prettyBytes(props.file.file && props.file.file.size ? props.file.file.size : 0) + ")"}</b></span>
                    </div>
                    )}
                    {props.userType == 'admin' && (
                    <div className="file-name">
                        <span onClick={() => renameFile()} style={{'cursor': 'pointer'}} className="filename-class">{props.file ? props.file.name : ''}<b>{" (" + prettyBytes(props.file.file && props.file.file.size ? props.file.file.size : 0) + ")"}</b></span>
                    </div>
                    )}

                   <div className="file-comment" style={style} ref={(node) => {
                      if (node) {
                        node.style.setProperty("float", "right", "important");
                      }
                    }}>
                        <input
                            maxLength="255"
                            type="text"
                            className="uploaded_file"
                            value={props.file.description}
                            placeholder="Comment"
                            disabled={true}
                        />
                    </div>
                </div>
            </div>
        </div>
    );
}

function EquipmentCertificationGraderComment(props) {
    return (
        <React.Fragment>
            <div className="overview">
                <h2 className="text-shadow">Grader Comment</h2>
            </div>
            <div className="form-group checkbox-group group-page">
                <form id="form-grader-vars" className="certification-grade-vars" action="" name="" method="POST">
                    <div className="form-vars check-onload">
                        <textarea
                            placeholder="Comments"
                            className="form-comment"
                            name="comments"
                            disabled={true}
                            value={props.certification.grader_comment}
                        ></textarea>
                    </div>
                </form>
            </div>
        </React.Fragment>
    )
}

function EquipmentCertificationFileUploaderAdmin(props) {
    const showAdminFilesHandler = () => {
        props.setShowAdminFiles(true);
    };

    const onChangeHandler = ev => {
        let val = ev.target.value;

        props.setUploadedFilesComment(val);
    };

    const adminFileSubmitHandler = (event) => {

        const url = process.env.REACT_APP_API_ENDPOINT+'/certifications/equipment/'+props.certification.id+'/uploader/';
        const formData = new FormData();
        const config = {
            withCredentials: true,
            headers: {
                'content-type': 'multipart/form-data'
            },
            onUploadProgress: progressEvent => {
                props.setPercentUploaded( Math.round( (progressEvent.loaded * 100) / progressEvent.total ) );
            }
        }

        event.preventDefault();

        let count = props.overlayVisible;
        props.setFilesUploading(true);
        props.setOverlayVisible( (count) => count + 1);

        if (!props.uploadedFiles.length) {
            props.setUploadedFilesComment('');
            props.setUploadedFiles([]);
            props.setShowAdminFiles(false);
            props.setFilesUploading(false);
            return false;
        }

        for (let i in props.uploadedFiles) {
            formData.append('file',props.uploadedFiles[i].file);
        }
        formData.append('comment', props.uploadedFilesComment);

        axios.post(url, formData,config).then(function (response) {

            props.setOverlayVisible( (count) => count - 1);
            props.setFilesUploading(false);

            if (response.status === 200 && response.data) {
                props.setUploadedFilesComment('');
                props.setUploadedFiles([]);
                props.setShowAdminFiles(false);

                // update certification object
                props.setCertification(response.data);
                props.setPercentUploaded(0);

                props.setShowPrompt(false);

                // go to certifications of study
                props.history.push(props.certListURL);

            }

        }, (error) => {
            props.setOverlayVisible( (count) => count - 1);
            props.setFilesUploading(false);
            alert(error);
        });

        return false;

    };

    const removeFileHandler = (ev,file) =>  {

        ev.preventDefault();

        let editedUploadedFiles = props.uploadedFiles.filter(f => f.key !== file.key);
        props.setUploadedFiles(editedUploadedFiles);

        if (!editedUploadedFiles.length) {
            props.setUploadedFilesComment('');
            props.setShowAdminFiles(false);
        }


        return false;
    };

    const onDrop = acceptedFiles => {
        let editedUploadedFiles = JSON.parse(JSON.stringify(props.uploadedFiles));

        acceptedFiles.map(file => {
            editedUploadedFiles.push({file: file, key: Math.floor(Math.random(5) * 1000), name: file.name});
            return false;
        });

        props.setUploadedFiles(editedUploadedFiles);
    };
    const {getRootProps, getInputProps} = useDropzone({onDrop});

    return (
        <React.Fragment>
        {!props.showAdminFiles && (
        <div className="form-group">
            <button
                className="btn large orange  align-center right"
                id="save-form"
                onClick={() => showAdminFilesHandler()}
            >Add files</button>
        </div>
        )}

        {props.showAdminFiles && (
        <form id="fileupload" method="post" encType="multipart/form-data">
            <div className="form-group">
                <div id="files" className="files">
                    <div {...getRootProps({
                        className: "info"
                    })}>
                        <span className="icon submit ico"></span>
                        <h2 className="text-shadow">Drop files here</h2>
                        <span className="or">
                            <hr />
                            <span>or</span>
                            <hr />
                        </span>
                        <span className="btn large orange fileinput-button">
                            <span>Select files from your computer</span>
                            <input {...getInputProps()} />
                        </span>
                    </div>
                    <div id="filesProgress" className="clearfix">
                        {props.uploadedFiles.map((file, i) => (
                        <div
                            key={i}
                            className="file-upload-box">
                            <p>
                                <span className="file-name">{file.name}</span>
                                <span className="file-actions">
                                    <button
                                        className="btn small grey align-center left other"
                                        onClick={(ev) => removeFileHandler(ev, file)}
                                    >REMOVE</button>
                                </span>
                                <span className="progress active">
                                    <span className="progress-bar progress-bar-warning" role="progressbar" style={{width: `${props.percentUploaded}%`}}></span>
                                    <span className="progress_value">
                                        {props.percentUploaded ? `${props.percentUploaded} % (a few seconds left)` : 'READY TO UPLOAD' }
                                    </span>
                                </span>
                            </p>
                        </div>
                        ))}
                    </div>
                </div>
                <textarea
                    className="submit-comment"
                    data-disabled="false"
                    name="info_admin_submit"
                    placeholder="Reason for adding files as admin.."
                    style={{margin: "10px 15px", width: "94.5%"}}
                    onChange={(ev) => onChangeHandler(ev)}
                    value={props.uploadedFilesComment}
                ></textarea>
                <p>
                    <input
                        type="submit"
                        value="Submit"
                        className="btn small orange align-center right"
                        onClick={adminFileSubmitHandler}
                    />
                </p>
            </div>
        </form>
        )}
        </React.Fragment>
    );
}

function EquipmentCertificationActions(props) {
    const resendNotification = async (e) => {
        const errMsg = "Sorry, something went wrong when renaming this file. Please contact your IT helpdesk.";

        let count = props.overlayVisible;
        props.setOverlayVisible( (count) => count + 1);

        await axios.get(process.env.REACT_APP_API_ENDPOINT+'/certifications/equipment/'+props.certification.id+'/resend_notification/', { withCredentials: true})
        .then((response) => {

            if (response.status === 200) {
                props.setOverlayVisible( (count) => count - 1);
            }
            else {
                props.setOverlayVisible( (count) => count - 1);
                alert(errMsg);
            }
        }, (error) => {
            props.setOverlayVisible( (count) => count - 1);
            alert(errMsg);
        });
    };


    const buttons = () => {
        return (
            <React.Fragment>
                {props.userType === 'admin' && props.certification.study.status === 'open' && !props.certification.archived && props.certification.study.notifications.find(n => n.uid === 'equip-cert') && (
                    <button
                        className="btn normal grey align-center left other"
                        onClick={resendNotification}
                    >Resend notification</button>
                )}
                <Link
                    to={"/studies/" + props.certification.study.id + "/certifications/"}
                    className="btn small grey align-center left"
                >Back</Link>
            </React.Fragment>
        );
    };

    return (
        <div className="form-actions" style={{float: "right"}}>
            {buttons()}
        </div>

    );
}

