import React, {useEffect, useState}  from 'react';
import axios from 'axios';
import CorcSelect from 'components/utils/CorcSelect';
import ReactHtmlParser from 'react-html-parser';

function Diagnostics(props) {
    const [tables, setTables] = useState([]);
    const [activeTable, setActiveTable] = useState({
        title: '',
        rows: [],
        cols: [],
    });

    const [tableOptions, setTableOptions] = useState([]);

    useEffect(function() {
        const getTables = async () => {
            let count = props.overlayVisible;
            props.setOverlayVisible( (count) => count + 1);

            await axios.get(process.env.REACT_APP_API_ENDPOINT+'/diagnostics/',
                { withCredentials: true, headers: {'Content-Type': 'application/json'}}).then(response => {

                setTables(response.data);

                let tOptions = [];
                for(let i = 0; i<= response.data.length-1; ++i) {
                    tOptions.push({'label': response.data[i].title, 'value': response.data[i].uid});
                }

                setTableOptions(tOptions);

                props.setOverlayVisible( (count) => count - 1);

            }, (error) => {
                props.setOverlayVisible( (count) => count - 1);
            });
        };

        props.setNavLinks([
            {title: 'Diagnose Errors', url: '/diagnostics', icon: 'documents'},
        ]);


        // do we have permissions?
        if (props.userAuth.user.role !== 'admin' && props.userAuth.user.role !== 'grader') {
            props.history.push('/studies');
            return;
        }

        getTables();

    }, []);

    const changeTable = val => {
        let active = tables.find(t => t.uid === val);
        setActiveTable(active);
    };

    return (
        <div className="home_header_container_class wide">
            <div className="content">
                <div className="breadcrumbs">
                    <div className="path">
                        <ul>
                            <li><a href="https://intranet.interno.aibili.pt/IT/diagnostics_IT/diagnose_errors.php" target="_blank">Diagnose Errors</a></li>
                            <li></li>
                        </ul>
                    </div>

                    <div className="sub-menu">
                        <ul className="button-group right"></ul>

                    </div>
                </div> {/* .breadcrumbs */}
                <div className="top-buttons all-users">
                    <ul className="button-group right">
                        <li>
                            {tableOptions.length > 0 && (
                            <CorcSelect
                                id="table-select"
                                containerStyles={{width: "165px"}}
                                name="table"
                                onChange={selectedOption => changeTable(selectedOption.value) }
                                options={tableOptions}
                                defaultValue={{label: 'Choose table', value: '-1'}}
                                isSearchable={false}
                            />
                            )}
                        </li>
                    </ul>
                </div>

                {activeTable && (
                <DiagnosticsTable
                    activeTable={activeTable}
                />
                )}
            </div>
        </div>

    );
}

export default Diagnostics;

function DiagnosticsTable(props) {
    return (
        <div>
            <table id="diagnostics-table">
                <thead>
                    <tr>
                        {props.activeTable.cols.map((col, i) => (
                        <th key={i}>{col}</th>
                        ))}
                    </tr>
                </thead>
                <tbody>
                    {props.activeTable.rows.map((row, i) => (
                        <DiagnosticsTableRow
                            key={i}
                            row={row}
                        />
                    ))}
                </tbody>
            </table>
        </div>
    );
}

function DiagnosticsTableRow(props) {
    return (
        <tr>
            {props.row.map((cell, i) => (
                <td key={i}>{ReactHtmlParser(cell)}</td>
            ))}
        </tr>
    );
}
