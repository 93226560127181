import React, {useEffect, useState} from 'react';
//import {Link} from 'react-router-dom';
import axios from 'axios';

function Centers(props) {
    //const userType = 'admin';

    useEffect(function() {

        const getCenters = async () => {
            let count = props.overlayVisible;
            props.setOverlayVisible( (count) => count + 1);

            await axios.get(process.env.REACT_APP_API_ENDPOINT+'/centers/', { withCredentials: true}).then(response => {

                props.setOverlayVisible( (count) => count - 1);
                if (response.status === 200) {
                        setCenters(response.data);
                    }

                }, (error) => {
                    props.setOverlayVisible( (count) => count - 1);
                    if (error.response.status === 401) {
                        props.resetAuth();
                        props.history.push('/login');
                    }
                }
            );

        }

        // do we have permissions?
        if (props.userAuth.user.role !== 'admin') {
            props.history.push('/studies');
            return;
        }

        getCenters();
        props.setNavLinks([{title: 'Centers', url: '/centers/', icon: 'center'}]);

        // set empty filter
        localStorage.setItem('corc_centers_sortAcronym', '>');
        localStorage.setItem('corc_centers_sortFullName', '>');
        localStorage.setItem('corc_centers_sortCountry', '>');
        localStorage.setItem('corc_centers_sortPostCode', '>');
        localStorage.setItem('corc_centers_sortCity', '>');

    }, []);

    const [centers, setCenters] = useState([]);

    const toggleAcronymSort = () => {

        let sort = localStorage.getItem('corc_centers_sortAcronym');

        if (sort === ">") {
            centers.sort((a, b) => (a.acronym.toLowerCase() < b.acronym.toLowerCase()) ? 1 : -1)
            localStorage.setItem('corc_centers_sortAcronym', "<")
        }
        else {
            centers.sort((a, b) => (a.acronym.toLowerCase() > b.acronym.toLowerCase()) ? 1 : -1)
            localStorage.setItem('corc_centers_sortAcronym', ">")
        }

        setCenters(centers.slice());
    }

    const toggleFullNameSort = () => {

        let sort = localStorage.getItem('corc_centers_sortFullName');

        if (sort === ">") {
            centers.sort((a, b) => (a.full_name.toLowerCase() < b.full_name.toLowerCase()) ? 1 : -1)
            localStorage.setItem('corc_centers_sortFullName', "<")
        }
        else {
            centers.sort((a, b) => (a.full_name.toLowerCase() > b.full_name.toLowerCase()) ? 1 : -1)
            localStorage.setItem('corc_centers_sortFullName', ">")
        }

        setCenters(centers.slice());
    }

    const toggleCountrySort = () => {

        let sort = localStorage.getItem('corc_centers_sortCountry');

        if (sort === ">") {
            centers.sort((a, b) => (a.country.toLowerCase() < b.country.toLowerCase()) ? 1 : -1)
            localStorage.setItem('corc_centers_sortCountry', "<")
        }
        else {
            centers.sort((a, b) => (a.country.toLowerCase() > b.country.toLowerCase()) ? 1 : -1)
            localStorage.setItem('corc_centers_sortCountry', ">")
        }

        setCenters(centers.slice());
    }

    const togglePostCodeSort = () => {

        let sort = localStorage.getItem('corc_centers_sortPostCode');

        if (sort === ">") {
            centers.sort((a, b) => (a.postcode.toLowerCase() < b.postcode.toLowerCase()) ? 1 : -1)
            localStorage.setItem('corc_centers_sortPostCode', "<")
        }
        else {
            centers.sort((a, b) => (a.postcode.toLowerCase() > b.postcode.toLowerCase()) ? 1 : -1)
            localStorage.setItem('corc_centers_sortPostCode', ">")
        }

        setCenters(centers.slice());
    }

    const toggleCitySort = () => {

        let sort = localStorage.getItem('corc_centers_sortCity');

        if (sort === ">") {
            centers.sort((a, b) => (a.city.toLowerCase() < b.city.toLowerCase()) ? 1 : -1)
            localStorage.setItem('corc_centers_sortCity', "<")
        }
        else {
            centers.sort((a, b) => (a.city.toLowerCase() > b.city.toLowerCase()) ? 1 : -1)
            localStorage.setItem('corc_centers_sortCity', ">")
        }

        setCenters(centers.slice());
    }

    const [centerPopupVisible, setCenterPopupVisible] = useState(false);
    const [currentCenter, setCurrentCenter] = useState({id: 0, errorFields: []});
    const [activeTab, setActiveTab] = useState(1);

    return (

        <div className="home_header_container_class">
            <div className="content">
                <div className="breadcrumbs">
                    <div className="path">
                        <ul>
                            <li>Manage Centers</li>
                        </ul>
                    </div>
                    <div className="sub-menu">
                    </div>
                </div>
                <div className="visits-list list">

                    <div className="list-head">
                        <table>
                            <tbody>
                                <tr>
                                    <th style={{width: "15%"}}>
                                        <button onClick={toggleAcronymSort}>Acronym</button>
                                    </th>
                                    <th style={{width: "45%"}}>
                                        <button onClick={toggleFullNameSort}>Full Name</button>
                                    </th>
                                    <th style={{width: "15%"}}>
                                        <button onClick={toggleCountrySort}>Country</button>
                                    </th>
                                    <th style={{width: "10%"}}>
                                        <button onClick={togglePostCodeSort}>Post Code</button>
                                    </th>
                                    <th>
                                        <button onClick={toggleCitySort}>City</button>
                                    </th>
                                </tr>
                            </tbody>
                        </table>
                    </div>

                    <div className="list-body">
                        <table id="list-table">
                            <tbody className="list-visits">
                            {centers.map( (center, i) => (
                                <CenterListItem
                                    key={i}
                                    center={center}
                                    acronym={center.acronym}
                                    setCurrentCenter={setCurrentCenter}
                                    setCenterPopupVisible={setCenterPopupVisible}
                                />
                            ))}
                            </tbody>
                        </table>
                    </div>
                </div> {/* .list */}
                {centerPopupVisible && (
                    <CenterPopup
                        setCenterPopupVisible={setCenterPopupVisible}
                        currentCenter={currentCenter}
                        setCurrentCenter={setCurrentCenter}
                        activeTab={activeTab}
                        setActiveTab={setActiveTab}
                        centers={centers}
                        setCenters={setCenters}
                        userAuth={props.userAuth}
                        setOverlayVisible={props.setOverlayVisible}
                        overlayVisible={props.overlayVisible}
                    />
                )}
            </div>
        </div>
    );
}


export default Centers;

function CenterListItem(props) {
    const showCenterHandler = center => {
        props.setCurrentCenter({...center, errorFields: []});
        props.setCenterPopupVisible(true);
    };

    return (
        <tr className="visit">
            <td style={{width: "15%", verticalAlign: "middle"}}>{props.center.acronym}</td>
            <td style={{width: "45%", verticalAlign: "middle"}}>{props.center.full_name}</td>
            <td style={{width: "15%", verticalAlign: "middle"}}>{props.center.country}</td>
            <td style={{width: "10%", verticalAlign: "middle"}}>{props.center.postcode}</td>
            <td style={{width: "10%", verticalAlign: "middle"}}>{props.center.city}</td>
            <td className="btn-list">
                <ul style={{float: "right"}}>
                    <li>
                        <button
                            className="btn xsmall grey edit-center"
                            onClick={() => showCenterHandler(props.center)}
                        >
                            <span className="icon-list study-details-small"></span>
                        </button>
                    </li>
                </ul>
            </td>
        </tr>
    );
}

function CenterPopup(props) {
    const onChangeTab = (ev, tab) => {
        ev.target.classList.toggle('select');
        props.setActiveTab(tab);
    };

    const validateCenter = (center) => {

        let editedCenter = JSON.parse(JSON.stringify(center));
        const errMsg = "Please fill all fields!";
        let errorFields = [];

        // validate required fields
        editedCenter.acronym = editedCenter.acronym.trim();
        editedCenter.address = editedCenter.address.trim();
        editedCenter.city = editedCenter.city.trim();
        editedCenter.comment = editedCenter.comment.trim();
        editedCenter.country = editedCenter.country.trim();
        editedCenter.email = editedCenter.email.trim();
        editedCenter.fax = editedCenter.fax.trim();
        editedCenter.full_name = editedCenter.full_name.trim();
        editedCenter.postcode = editedCenter.postcode.trim();
        editedCenter.telephone = editedCenter.telephone.trim();
        editedCenter.website = editedCenter.website.trim();

        if (!editedCenter.full_name) {
            errorFields.push('full_name');
        }

        if (!editedCenter.acronym) {
            errorFields.push('acronym');
        }

        if (!editedCenter.address) {
            errorFields.push('address');
        }

        if (!editedCenter.city) {
            errorFields.push('city');
        }

        if (!editedCenter.postcode) {
            errorFields.push('postcode');
        }

        if (!editedCenter.country) {
            errorFields.push('country');
        }

        if (!editedCenter.telephone) {
            errorFields.push('telephone');
        }

        props.setCurrentCenter({...editedCenter, errorFields: errorFields});

        if (errorFields.length) {
            alert(errMsg);
            return false;
        }

      return true;

    }

    const cancelCenterHandler = () => {

        props.setCurrentCenter({id: 0, errorFields: []});
        props.setCenterPopupVisible(false)
    };

    const submitCenterHandler = async () => {
        const errMsg = "Sorry, something went wrong when editing this center. Please contact your IT helpdesk.";

        if (validateCenter(props.currentCenter)) {
            let count = props.overlayVisible;
            props.setOverlayVisible( (count) => count + 1);

            await axios.patch(process.env.REACT_APP_API_ENDPOINT+'/centers/'+props.currentCenter.id+'/', {...props.currentCenter}, { withCredentials: true})
            .then((response) => {

                props.setOverlayVisible( (count) => count - 1);
                if (response.status === 200 && response.data && response.data.id) {

                    let currCenter = response.data;
                    let editedCenters = JSON.parse(JSON.stringify(props.centers));
                    for(let i=0, len=editedCenters.length; i<=len-1; ++i) {
                        if(editedCenters[i].id === currCenter.id) {
                            editedCenters.splice(i, 1, currCenter);
                        }
                    }


                    props.setCenters(editedCenters);
                    props.setCenterPopupVisible(false);
                    props.setCurrentCenter({id: 0, errorFields: []});

                }
                else {
                    alert(errMsg);
                }

            }, (error) => {
                props.setOverlayVisible( (count) => count - 1);
                alert(errMsg);
            });

        }



    };

    return (
        <React.Fragment>
        <div className="modal fade in">
            <div className="modal-body">
                <div className="modal-tabs">
                    <ul>
                        <li><a href="#tab1" onClick={(ev) => onChangeTab(ev, 1)} className={props.activeTab === 1 ? "select" : ""}>Center Details</a></li>
                        <li><a href="#tab2" onClick={(ev) => onChangeTab(ev, 2)} className={props.activeTab === 2 ? "select" : ""}>Studies</a></li>
                    </ul>

                    <div className="content-tabs">
                        <div id="tab1" style={{display: props.activeTab === 1 ? 'block' : 'none'}}>
                            <CenterPopupDetails
                                currentCenter={props.currentCenter}
                                setCurrentCenter={props.setCurrentCenter}
                            />
                        </div>
                        <div id="tab2" style={{display: props.activeTab === 2 ? 'block' : 'none'}}>
                            <CenterPopupStudies
                                currentCenter={props.currentCenter}
                                setCurrentCenter={props.setCurrentCenter}
                                centers={props.centers}
                                setCenters={props.setCenters}
                                setCenterPopupVisible={props.setCenterPopupVisible}
                                userAuth={props.userAuth}
                                setOverlayVisible={props.setOverlayVisible}
                                overlayVisible={props.overlayVisible}
                            />
                        </div>
                    </div> {/* .content-tabs */}
                </div> {/* .modal-tabs */}
            </div> {/* .modal-body */}
            <div className="modal-footer">
                <input
                    type="submit"
                    className="btn small orange align-center right"
                    value="Save"
                    onClick={() => submitCenterHandler()}
                />
                <button
                    className="btn small grey align-center right"
                    onClick={() => cancelCenterHandler()}
                >Cancel</button>
            </div>
        </div>

        <div className="modal-backdrop fade in"></div>
        </React.Fragment>
    );
}

function CenterPopupDetails(props) {
    const onChangeHandler = ev => {
        let name = ev.target.name;
        let value = ev.target.value;

        props.setCurrentCenter({...props.currentCenter, [name]: value});
    };

    const getErrorClass = (center, type) => {

        if (center.errorFields.indexOf(type) !== -1) {
            return "error";
        }

        return "";
    }

    const submitHandler = e => {
        e.preventDefault();
    };

    return (
        <form onSubmit={submitHandler} id="register-center" method="post">
            <div className="modal-form">
                <div className="form-group">
                    <div className="modal-errors" style={{marginLeft: "15px"}}></div>

                    <div className="span5" style={{width: "100%"}}>
                        <div className="row">
                            <div className="label" style={{width: "136px"}}>
                                <label htmlFor="full_name" className="required">Full Name <span className="required">*</span></label>
                            </div>
                            <div className="input">
                                <input
                                    style={{width: "338px"}}
                                    name="full_name"
                                    id="full_name"
                                    type="text"
                                    value={props.currentCenter.full_name}
                                    onChange={ev => onChangeHandler(ev)}
                                    className={getErrorClass(props.currentCenter, 'full_name')}
                                />
                            </div>
                        </div>

                        <div className="row">
                            <div className="label" style={{width: "136px"}}>
                                <label htmlFor="acronym" className="required">Acronym <span className="required">*</span></label>
                            </div>
                            <div className="input">
                                <input
                                    style={{width: "338px"}}
                                    name="acronym"
                                    id="acronym"
                                    type="text"
                                    value={props.currentCenter.acronym}
                                    onChange={ev => onChangeHandler(ev)}
                                    className={getErrorClass(props.currentCenter, 'acronym')}
                                />
                            </div>
                        </div>

                        <br />

                        <div className="row">
                            <div className="label" style={{width: "136px"}}>
                                <label htmlFor="address" className="required">Address <span className="required">*</span></label>
                            </div>
                            <div className="input">
                                <input
                                    style={{width: "338px"}}
                                    name="address"
                                    id="address"
                                    type="text"
                                    value={props.currentCenter.address}
                                    onChange={ev => onChangeHandler(ev)}
                                    className={getErrorClass(props.currentCenter, 'address')}
                                />
                            </div>
                        </div>

                        <div className="row">
                            <div className="label" style={{width: "136px"}}>
                                <label htmlFor="city" className="required">City <span className="required">*</span></label>
                            </div>
                            <div className="input">
                                <input
                                    style={{width: "338px"}}
                                    name="city"
                                    id="city"
                                    type="text"
                                    value={props.currentCenter.city}
                                    onChange={ev => onChangeHandler(ev)}
                                    className={getErrorClass(props.currentCenter, 'city')}
                                />
                            </div>
                        </div>

                        <div className="row">
                            <div className="label" style={{width: "136px"}}>
                                <label htmlFor="postcode" className="required">Post Code <span className="required">*</span></label>
                            </div>
                            <div className="input">
                                <input
                                    style={{width: "131px"}}
                                    name="postcode"
                                    id="postcode"
                                    type="text"
                                    value={props.currentCenter.postcode}
                                    onChange={ev => onChangeHandler(ev)}
                                    className={getErrorClass(props.currentCenter, 'postcode')}
                                />
                            </div>
                        </div>

                        <div className="row">
                            <div className="label" style={{width: "136px"}}>
                                <label htmlFor="country" className="required">Country <span className="required">*</span></label>
                            </div>
                            <div className="input">
                                <input
                                    style={{width: "338px"}}
                                    name="country"
                                    id="country"
                                    type="text"
                                    value={props.currentCenter.country}
                                    onChange={ev => onChangeHandler(ev)}
                                    className={getErrorClass(props.currentCenter, 'country')}
                                />
                            </div>
                        </div>

                        <div className="row">
                            <div className="label" style={{width: "136px"}}>
                                <label htmlFor="website">Website</label>
                            </div>
                            <div className="input">
                                <input
                                    style={{width: "338px"}}
                                    name="website"
                                    id="website"
                                    type="text"
                                    value={props.currentCenter.website}
                                    onChange={ev => onChangeHandler(ev)}
                                />
                            </div>
                        </div>

                        <div className="row">
                            <div className="label" style={{width: "136px"}}>
                                <label htmlFor="email">Email</label>
                            </div>
                            <div className="input">
                                <input
                                    style={{width: "338px"}}
                                    name="email"
                                    id="email"
                                    type="text"
                                    value={props.currentCenter.email}
                                    onChange={ev => onChangeHandler(ev)}
                                />
                            </div>
                        </div>

                        <div className="row">
                            <div className="label" style={{width: "136px"}}>
                                <label htmlFor="telephone" className="required">Telephone <span className="required">*</span></label>
                            </div>
                            <div className="input">
                                <input
                                    style={{width: "131px"}}
                                    name="telephone"
                                    id="telephone"
                                    type="text"
                                    value={props.currentCenter.telephone}
                                    onChange={ev => onChangeHandler(ev)}
                                    className={getErrorClass(props.currentCenter, 'telephone')}
                                />
                            </div>
                        </div>

                        <div className="row">
                            <div className="label" style={{width: "136px"}}>
                                <label htmlFor="fax">Fax</label>
                            </div>
                            <div className="input">
                                <input
                                    style={{width: "131px"}}
                                    name="fax"
                                    id="fax"
                                    type="text"
                                    value={props.currentCenter.fax}
                                    onChange={ev => onChangeHandler(ev)}
                                />
                            </div>
                        </div>

                        <br />

                        <div className="row">
                            <div className="label" style={{width: "126px"}}>
                                <label htmlFor="comment">Observation</label>
                            </div>
                            <div className="input">
                                <textarea
                                    className="submit-comment"
                                    style={{width: "328px !important"}}
                                    name="comment"
                                    id="comment"
                                    value={props.currentCenter.comment}
                                    onChange={ev => onChangeHandler(ev)}
                                ></textarea>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </form>
    );
}

function CenterPopupStudies(props) {

    const changeCenterStudyState = async (studyCenter) => {
        const errMsg = "Sorry, something went wrong when changing this center's state. Please contact your IT helpdesk.";

        let count = props.overlayVisible;
        props.setOverlayVisible( (count) => count + 1);

        await axios.put(process.env.REACT_APP_API_ENDPOINT+'/studies/'+studyCenter.study.id+'/centers/'+studyCenter.id+'/change_center_state/', {state: !studyCenter.is_active}, { withCredentials: true})
            .then((response) => {
                let editedCenters = JSON.parse(JSON.stringify(props.centers));
                let i,j,len,len2;

                props.setOverlayVisible( (count) => count - 1);
                if (response.status === 200) {

                    for(i=0, len=editedCenters.length; i<=len-1; ++i) {
                        if(editedCenters[i].id === props.currentCenter.id) {
                            for (j=0, len2=editedCenters[i].study_centers.length; j<=len2-1; ++j) {
                                if (editedCenters[i].study_centers[j].id === studyCenter.id) {
                                     editedCenters[i].study_centers[j].is_active = !editedCenters[i].study_centers[j].is_active
                                }
                            }
                        }
                    }


                    props.setCenters(editedCenters);
                    props.setCenterPopupVisible(false);
                    props.setCurrentCenter({id: 0, errorFields: []});


                }
                else {
                    alert(errMsg);
                }
            }, (error)=> {
                props.setOverlayVisible( (count) => count - 1);
                alert(errMsg);
            });
    };

    return (
        <div className="studies-user">
            {props.currentCenter.study_centers.map((sCenter, i) => (
            <div className="study-user" key={i}>

                {sCenter.is_active ? (

                <React.Fragment>
                <label>{sCenter.study.acronym}</label>
                </React.Fragment>
                ) :
                (
                <React.Fragment>
                <label style={{textDecorationLine: "line-through"}}>{sCenter.study.acronym}</label>
                </React.Fragment>
                )}
                <button
                    onClick={() => changeCenterStudyState(sCenter)}
                    className="btn small orange align-center right"
                >{sCenter.is_active ? 'Deactivate' : 'Activate'}</button>
            </div>
            ))}
        </div>
    );
}
