import React, {useEffect, useState} from 'react';
import {Link} from 'react-router-dom';
import axios from 'axios';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import CorcSelect from 'components/utils/CorcSelect';
import {initDate} from 'components/utils/Helpers.js';

function Study(props) {

    const [isAdd, setIsAdd] = useState(true);

    const handleSubmit = e => {
        e.preventDefault();
    }


    const validateStudy = () => {

        let errors = [], tmp, multipleEmails = false;

        // do we have requiredfields?
        if (!study.acronym.trim()) {
            errors.push('Acronym cannot be blank.');
        }

        if (!study.corc_num.trim()) {
            errors.push('CORC Nº cannot be blank.');
        }

        if (!study.protocol_num.trim()) {
            errors.push('Protocol Nº cannot be blank.');
        }

        if (!study.full_name.trim()) {
            errors.push('Full Name cannot be blank.');
        }

        if (!study.description.trim()) {
            errors.push('Description cannot be blank.');
        }

        if (isNaN(Date.parse(study.start_date)) || isNaN(Date.parse(study.end_date))) {

            if (isNaN(Date.parse(study.start_date))) {
                errors.push('Start date is invalid.');
            }

            if (isNaN(Date.parse(study.end_date))) {
                errors.push('End date is invalid.');
            }

        }
        else {
            if (Date.parse(study.start_date) >= Date.parse(study.end_date)) {
                errors.push('End date longer then start date.');
            }

        }

        if (!study.exams.length) {
            errors.push('Please add at least one exam in study.');
        }

        if (!study.scheduled_visits.length) {
            errors.push('Please add at least one valid scheduled visit.');
        }
        else {
            for(let i=0, len = study.scheduled_visits.length;i<=len-1;++i) {
                let vis = study.scheduled_visits[i];

                if(!vis.name.length) {
                    errors.push(`Please add a name to scheduled visit.`);
                }

                if(!vis.exams.length) {
                    errors.push(`Please add a at least one exam to scheduled visit ${vis.name}.`);
                }

                vis.is_reference = false;

                if(vis.days !== '' && vis.months !== '' && vis.years !== '') {
                    if(vis.days >= 0 || vis.months >= 0 || vis.years >= 0) {
                        if(vis.days >= 0 && vis.months >= 0 && vis.years >=0) {
                            //let's compare with previous visit date
                            if(i > 0) {
                                let prevVis = study.scheduled_visits[i-1],
                                    prevYears = prevVis.years,
                                    prevMonths = prevVis.months,
                                    prevDays = prevVis.days;

                                if(prevYears >= 0 && prevMonths >= 0 && prevDays >= 0) {
                                    if(((prevYears*365) + (prevMonths*30.4166667) + prevDays) > ((vis.years*365) + (vis.months*30.4166667) + vis.days)) {
                                        errors.push(`Reference date in visit ${vis.name} is older than previous visit.`);
                                    }
                                }
                            }
                        }
                        else {
                            errors.push(`Years, Months or Days in scheduled visit ${vis.name} are not valid.`);
                        }
                    }
                    else {
                        vis.is_reference = true;
                    }
                }
                else {
                    errors.push(`Years, Months or Days in scheduled visit ${vis.name} are not valid.`);
                }
            }
        }

        tmp = study.corc_email.trim();
        tmp = tmp.replace(';', ',');
        tmp = tmp.replace(' ', '');
        tmp = tmp.split(',');

        multipleEmails = tmp.length > 1;

        for (let i = tmp.length-1; i>=0; --i) {

            tmp[i] = tmp[i].trim();

            if (tmp[i] && !/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(tmp[i])) {
                if (multipleEmails) {
                    errors.push('CORC E-mail must be valid. Multiple e-mail addresses must be separated by , or ;');
                }
                else {
                    errors.push('CORC E-mail must be valid.');
                }
                break;
            }
        }


        // if validation fails, show error popup
        if (errors.length) {
            setErrorPopupMessages(errors);
        }
        else {
            // otherwise confirm with save popup
            setShowSavePopup(true);
        }


    }

    const closeStudy = async(e) => {
        let changedStudy = {};
        const errMsg = "Sorry, something went wrong when closing this study. Please contact your IT helpdesk.";

        setShowCloseStudyPopup(false);

        if (!isAdd) {
            let count = props.overlayVisible;
            props.setOverlayVisible( (count) => count + 1);

            changedStudy.id = study.id;
            changedStudy.status = 'closed';

            await axios.post(process.env.REACT_APP_API_ENDPOINT+'/studies/'+props.match.params.id+'/status/', {...changedStudy}, { withCredentials: true})
                .then((response) => {

                props.setOverlayVisible( (count) => count - 1);
                if (response.status === 200) {
                    setStudy({...study, status: 'closed'});
                }
                else {
                    alert(errMsg);
                }

            }, (error) => {

                props.setOverlayVisible( (count) => count - 1);
                alert(errMsg);
            });


        }
    }

    const reopenStudy = async(e) => {
        let changedStudy = {};
        const errMsg = "Sorry, something went wrong when saving this study. Please contact your IT helpdesk.";

        setShowReopenStudyPopup(false);

        if (!isAdd) {
            let count = props.overlayVisible;
            props.setOverlayVisible( (count) => count + 1);

            changedStudy.id = study.id;
            changedStudy.status = 'open';

            await axios.post(process.env.REACT_APP_API_ENDPOINT+'/studies/'+props.match.params.id+'/status/', {...changedStudy}, { withCredentials: true})
                .then((response) => {

                props.setOverlayVisible( (count) => count - 1);
                if (response.status === 200) {
                    setStudy({...study, status: 'open'});
                }
                else {
                    alert(errMsg);
                }

            }, (error) => {
                props.setOverlayVisible( (count) => count - 1);
                alert(errMsg);
            });

        }
    }
    const submitStudy = async(e) => {

        const changesToSave = ['id', 'acronym', 'corc_num', 'description', 'corc_email', 'end_date', 'exams', 'scheduled_visits', 'start_date', 'full_name', 'patient_details', 'protocol_num', 'select_patient_eye'];
        const changedStudy = JSON.parse(JSON.stringify(study));

        const errMsg = "Sorry, something went wrong when saving this study. Please contact your IT helpdesk.";

        for (let i in changedStudy) {
            if (changedStudy.hasOwnProperty(i) && !changesToSave.includes(i)) {
                delete changedStudy[i];
            }
        }

        changedStudy.acronym = changedStudy.acronym.trim();
        changedStudy.corc_num = changedStudy.corc_num.trim();
        changedStudy.full_name = changedStudy.full_name.trim();
        changedStudy.protocol_num = changedStudy.protocol_num.trim();
        changedStudy.patient_details = changedStudy.patient_details.trim();
        changedStudy.corc_email = changedStudy.corc_email.trim();
        changedStudy.corc_email = changedStudy.corc_email.replace(';', ',');

        setShowSavePopup(false);

        let count = props.overlayVisible;
        props.setOverlayVisible( (count) => count + 1);

        if (isAdd) {

            await axios.post(process.env.REACT_APP_API_ENDPOINT+'/studies/', {...changedStudy }, { withCredentials: true})
                .then((response) => {

                props.setOverlayVisible( (count) => count - 1);
                if (response.status === 201) {

                    props.history.push({
                      pathname: '/studies/'+response.data+"/vars",
                      state: { isAdd: true }
                    })
                }
                else {
                    alert(errMsg);
                }

            }, (error) => {
                props.setOverlayVisible( (count) => count - 1);
                alert(errMsg);
            });

        }
        else {

            await axios.patch(process.env.REACT_APP_API_ENDPOINT+'/studies/'+props.match.params.id+'/', {...changedStudy}, { withCredentials: true})
                .then((response) => {

                props.setOverlayVisible( (count) => count - 1);

                if (response.status === 200) {
                    //setStudy(response.data)
                    props.history.push("/studies");
                }
                else {
                    alert(errMsg);
                }

            }, (error) => {
                props.setOverlayVisible( (count) => count - 1);
                alert(errMsg);
            });

        }

    };


    const [study, setStudy] = useState({
        'id': 0,
        'status': 'open',
        'acronym': '',
        'full_name': '',
        'start_date': '',
        'end_date': '',
        'description': '',
        'select_patient_eye': true,
        'patient_details': '',
        'patient_card_vars': [],
        'protocol_num': '',
        'corc_num': '',
        'exams': [],
        'scheduled_visits': [],
        'corc_email': 'corc@aibili.pt',
        'visits_num': 0,
    });
    const [examTypes, setExamTypes] = useState([])


    const [errorPopupMessages, setErrorPopupMessages] = useState([]);
    const [showSavePopup, setShowSavePopup] = useState(false);
    const [showCloseStudyPopup, setShowCloseStudyPopup] = useState(false);
    const [showReopenStudyPopup, setShowReopenStudyPopup] = useState(false);
    const [showStudyAlertExamsPopup, setStudyAlertExamsPopup] = useState(false);
    const [examPopupVisible, setExamPopupVisible] = useState(false);
    const [currentVisit, setCurrentVisit] = useState({});

    const addVisit = order => {
        let newVisit = {
            "id": "tmp"+Math.floor(Math.random(3)*1000),
            "name": "",
            "years": "",
            "months": "",
            "days": "",
            "is_reference": false,
            "exams": [],
            "order": 0
        }

        let tmp = JSON.parse(JSON.stringify(study.scheduled_visits));

        if(!isNaN(+order)) {
            newVisit.order = +order + 1;

            tmp.map((v) => {
                if(v.order >= newVisit.order) {
                    ++v.order;
                }
                return true;
            });

            tmp.push(newVisit);

            tmp.sort((a,b) => {
                if(a.order > b.order) {
                    return 1
                }
                else if(b.order > a.order) {
                    return -1
                }
                return 0
            });

        }
        else {
            tmp.push(newVisit);
            newVisit.order = tmp.length ? tmp.length-1 : 0;
        }

        setStudy({...study, 'visits_num': tmp.length, 'scheduled_visits': tmp});
    };

    const studyChangeHandler = e => {
        const target = e.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const name = target.name;

        setStudy({...study, [name]: value})


    };

    const studyHasExams = study.exams.length > 0;

    useEffect(function() {

        const getStudy = async () => {
            let count = props.overlayVisible;

            if (props.match.params.id) {

                props.setOverlayVisible( (count) => count + 1);

                await axios.get(process.env.REACT_APP_API_ENDPOINT+'/studies/'+props.match.params.id+'/summary/', { withCredentials: true}).then(response => {

                props.setOverlayVisible( (count) => count - 1);
                if (response.status === 200) {

                        // if we user is a monitor, we need to "close" this study
                        if (props.userAuth.user.role === 'monitor') {
                            response.data.status = 'closed';
                        }

                        setStudy({...response.data, 'visits_num': response.data.scheduled_visits.length});
                         setIsAdd(false);
                    }

                }, (error) => {
                    props.setOverlayVisible( (count) => count - 1);
                    if (error.response.status === 401) {
                        props.resetAuth();
                        props.history.push('/login');
                    }
                    else {
                        props.history.push('/studies');
                        return false;
                    }
                });

            }

        }

        const getExamTypes = async () => {
            let count = props.overlayVisible;
            props.setOverlayVisible( (count) => count + 1);

            await axios.get(process.env.REACT_APP_API_ENDPOINT+'/settings/examtypes/', { withCredentials: true}).then(response => {

                props.setOverlayVisible( (count) => count - 1);

                if (response.status === 200) {
                    setExamTypes(response.data);
                    }

                }, (error) => {

                    props.setOverlayVisible( (count) => count - 1);
                    if (error.response.status === 401) {
                        props.resetAuth();
                        props.history.push('/login');
                    }
                });
        }

        if (props.userAuth.user.role !== 'admin' && props.userAuth.user.role !== 'monitor') {
            props.history.push('/studies');
            return;
        }

        getStudy();
        getExamTypes();
        props.setNavLinks([{title: 'Studies', url: '/studies/', icon: 'studies'}]);

    }, []);


    return (

        <React.Fragment>

            {showCloseStudyPopup && (
                <StudyClosePopup closeStudy={closeStudy} setShowCloseStudyPopup={setShowCloseStudyPopup}/>
            )}

            {showReopenStudyPopup && (
                <StudyReopenPopup reopenStudy={reopenStudy} setShowReopenStudyPopup={setShowReopenStudyPopup}/>
            )}

            {showSavePopup && (
                <StudySavePopup setShowSavePopup={setShowSavePopup} submitStudy={submitStudy}/>
            )}

            {errorPopupMessages.length > 0 && (
                <StudyErrorPopup errorPopupMessages={errorPopupMessages} setErrorPopupMessages={setErrorPopupMessages}/>
            )}

            {showStudyAlertExamsPopup && (
              <StudyAlertExamsPopup  setStudyAlertExamsPopup={setStudyAlertExamsPopup}/>
            )}


            <div className="home_header_container_class">
                <div className="content">
                    <div className="main-form">
                        <form onSubmit={handleSubmit} id="register-study">
                            <div className="overview">
                                <h2 className="text-shadow">Study details:</h2>
                                { !isAdd && (
                                <StudyFormHeaderButtons
                                    userAuth={props.userAuth}
                                    study={props.match.params.id}
                                    status={study.status}
                                    setShowCloseStudyPopup={setShowCloseStudyPopup}
                                    setShowReopenStudyPopup={setShowReopenStudyPopup}
                                />
                                )}
                            </div>
                            <StudyFormDetails
                                study={study}
                                setStudy={setStudy}
                                studyChangeHandler={studyChangeHandler}
                                initDate={initDate}
                            />

                            <div className="overview">
                                <h2 className="text-shadow">Exams *</h2>
                            </div>
                            <StudyFormExams
                                study={study}
                                setStudy={setStudy}
                                examTypes={examTypes}
                                setStudyAlertExamsPopup={setStudyAlertExamsPopup}
                            />

                            <div className="overview">
                                <h2 className="text-shadow">Exams Certifications *</h2>
                            </div>
                            { study.exams && (<StudyFormExamsCertifications
                                study={study}
                                setStudy={setStudy}
                                examTypes={examTypes}
                            />)}

                            <div className="overview" style={{marginTop: "39px"}}>
                                <h2 className="text-shadow">Scheduled Visits *</h2>

                                { study.status === "open" && (
                                <button id="add-visit" className="btn small grey align-center right" onClick={() => addVisit()}>Add visit</button>
                                )}
                            </div>
                            <div className="form-group">
                                <div style={{width: "100%", marginTop: "10px"}}></div>
                                <table className="scheduled-visits">
                                    <tbody>
                                        { study.scheduled_visits.map((visit, i) =>(
                                        <StudyFormScheduledVisitsItem
                                            key={i}
                                            order={i}
                                            setStudy={setStudy}
                                            study={study}
                                            visit={visit}
                                            setExamPopupVisible={setExamPopupVisible}
                                            setCurrentVisit={setCurrentVisit}
                                            addVisit={addVisit}
                                        />
                                        ))}
                                    </tbody>
                                </table>
                            </div>

                            <div className="overview">
                                <h2 className="text shadow">Patient Card Information</h2>
                            </div>
                            <StudyFormPatientCardInfo
                                study={study}
                                studyChangeHandler={studyChangeHandler}
                            />

                            <StudyFormControls isAdd={isAdd} validateStudy={validateStudy} study={study} />
                        </form>
                    </div>
                    {examPopupVisible && (
                        studyHasExams ? (
                        <StudyFormScheduledVisitsExams
                            study={study}
                            setStudy={setStudy}
                            currentVisit={currentVisit}
                            examTypes={examTypes}
                            setCurrentVisit={setCurrentVisit}
                            setExamPopupVisible={setExamPopupVisible}
                        />
                        ) : (
                        <StudyFormScheduledVisitsNoExams
                            setExamPopupVisible={setExamPopupVisible}
                        />
                        )
                    )}
                </div>
            </div>
        </React.Fragment>
    );
}

export default Study;

function StudyClosePopup(props) {

    return (
        <React.Fragment>
            <div id="alertModal" className="modal hide fade in" tabIndex="-1" role="dialog" aria-labelledby="myModalLabel" aria-hidden="false" style={{display: "block"}}>
                <div className="modal-header">
                    <button type="button" className="close" data-dismiss="modal" aria-hidden="true">×</button>
                    <h3 id="alert-modal-title">Confirmation</h3>
                </div>
                <div className="modal-body"><br/><br/>Do you really want <b>End</b> this Study?<br/></div>
                <div className="modal-footer">
                    <button onClick={props.closeStudy} id="alert-modal-ok" aria-hidden="true" className="btn small orange align-center right">Yes</button>
                    <button onClick={() => props.setShowCloseStudyPopup(false)} data-dismiss="modal" id="alert-modal-cancel" aria-hidden="true" className="btn small grey align-center right">No</button>
                </div>
            </div>
            <div className="modal-backdrop fade in"></div>
        </React.Fragment>
    )
}

function StudyReopenPopup(props) {

    return (
        <React.Fragment>
            <div id="alertModal" className="modal hide fade in" tabIndex="-1" role="dialog" aria-labelledby="myModalLabel" aria-hidden="false" style={{display: "block"}}>
                <div className="modal-header">
                    <button type="button" className="close" data-dismiss="modal" aria-hidden="true">×</button>
                    <h3 id="alert-modal-title">Confirmation</h3>
                </div>
                <div className="modal-body"><br/><br/>Do you really want <b>Re-Open</b> this Study?<br/></div>
                <div className="modal-footer">
                    <button onClick={props.reopenStudy} id="alert-modal-ok" aria-hidden="true" className="btn small orange align-center right">Yes</button>
                    <button onClick={() => props.setShowReopenStudyPopup(false)} data-dismiss="modal" id="alert-modal-cancel" aria-hidden="true" className="btn small grey align-center right">No</button>
                </div>
            </div>
            <div className="modal-backdrop fade in"></div>
        </React.Fragment>
    )
}

function StudyAlertExamsPopup(props) {

    return (
        <React.Fragment>
            <div id="alertModal" className="modal hide fade in" tabIndex="-1" role="dialog" aria-labelledby="myModalLabel" aria-hidden="false" style={{display: "block"}}>
                <div className="modal-header">
                    <button type="button" className="close" data-dismiss="modal" aria-hidden="true">×</button>
                    <h3 id="alert-modal-title">Cannot remove this exam!</h3>
                </div>
                <div className="modal-body">
                    <div className="errorSummary"><p>Cannot remove this exam because either:</p>
                        <ul>
                            <li>The exam exists in one or more scheduled visits</li>
                            <li>A Technician already issued his certification on this exam</li>
                            <li>An equipment already certified for this exam</li>
                            <li>A patient already made this exam</li>
                        </ul>
                    </div>
                </div>

                <div className="modal-footer">
                    <button onClick={() => props.setStudyAlertExamsPopup(false)} data-dismiss="modal" id="alert-modal-ok" aria-hidden="true" className="btn small orange align-center right">OK</button>
                </div>
            </div>
            <div className="modal-backdrop fade in"></div>
        </React.Fragment>
    )


}
function StudyErrorPopup(props) {

    return (
        <React.Fragment>
            <div id="alertModal" className="modal hide fade in" tabIndex="-1" role="dialog" aria-labelledby="myModalLabel" aria-hidden="false" style={{display: "block"}}>
                <div className="modal-header">
                    <button type="button" className="close" data-dismiss="modal" aria-hidden="true">×</button>
                    <h3 id="alert-modal-title">Errors!</h3>
                </div>
                <div className="modal-body">
                    <div className="errorSummary"><p>Please fix the following input errors:</p>
                        <ul>
                            {props.errorPopupMessages.map((message) => (
                            <li key={message}>{message}</li>
                            ))}
                        </ul>
                    </div>
                </div>
                <div className="modal-footer">
                    <button onClick={() => props.setErrorPopupMessages(false)} data-dismiss="modal" id="alert-modal-ok" aria-hidden="true" className="btn small orange align-center right">OK</button>
                </div>
            </div>
            <div className="modal-backdrop fade in"></div>
        </React.Fragment>
    )


}

function StudySavePopup(props) {
    return (
        <React.Fragment>
            <div id="alertModal" className="modal hide fade in" tabIndex="-1" role="dialog" aria-labelledby="myModalLabel" aria-hidden="false" style={{display: "block"}}>
                <div className="modal-header">
                    <button type="button" className="close" data-dismiss="modal" aria-hidden="true">×</button>
                    <h3 id="alert-modal-title">Confirmation!</h3>
                </div>
                <div className="modal-body"><br/>Do you really want to save this Study?<br/><br/>
                </div>
                <div className="modal-footer">
                    <button onClick={props.submitStudy} id="alert-modal-ok" aria-hidden="true" className="btn small orange align-center right">Yes</button>
                    <button onClick={() => props.setShowSavePopup(false)} data-dismiss="modal" id="alert-modal-cancel" aria-hidden="true" className="btn small grey align-center right">No</button>
                </div>
            </div>
            <div className="modal-backdrop fade in"></div>
        </React.Fragment>
    );
}

function StudyFormHeaderButtons(props) {

    return (
        <React.Fragment>

            {props.status === "open" && props.userAuth.user.role !== 'monitor' ? (
                    <button onClick={() => props.setShowCloseStudyPopup(true)} className="btn small grey align-center right" id="end-study">End study</button>
                ) : ''
            }

            {props.status === "closed" && props.userAuth.user.role !== 'monitor' ? (
                    <button onClick={() => props.setShowReopenStudyPopup(true)} className="btn normal grey align-center right" id="end-study">Re-open study</button>
                ) : ''
            }

            {props.userAuth.user.role !== 'monitor' && (
                <Link to={"/studies/" + props.study + "/vars"} className="btn small orange align-center right" id="end-study">Study Vars</Link>
            )}
        </React.Fragment>
    );
}

function StudyFormControlsOpen(props) {

 return (
        <div className="form-actions" style={{float:"right"}}>
            <Link to="/studies" className="btn small grey align-center left">Cancel</Link>
            {props.isAdd === true ? (
                <input type="submit" onClick={() => props.validateStudy()}name="" className="btn small orange align-center left" value="Next Step" />
            ) : (
                <input type="submit" onClick={() => props.validateStudy()} name="" className="btn small orange align-center left" value="Save" />
            )}
        </div>
 )
}

function StudyFormControlsClosed(props) {
    return (

        <div className="form-actions" style={{float:"right"}}>
            <Link to="/studies" className="btn small grey align-center left">Back</Link>
        </div>

    )
}

function StudyFormControls(props) {

    return (

        <React.Fragment>
        {props.study.status === "open" ? (
            <StudyFormControlsOpen isAdd={props.isAdd} validateStudy={props.validateStudy}/>
        ) : (
            <StudyFormControlsClosed/>
        )}
        </React.Fragment>
    );
}

function StudyFormPatientCardInfo(props) {
    return (
        <div className="form-group checkbox-group group-page">
            <div className="row" style={{paddingTop: "4px"}}>
                <input
                    checked={props.study.select_patient_eye}
                    value="true"
                    id="select_patient_eye"
                    name="select_patient_eye"
                    type="checkbox"
                    onChange={props.studyChangeHandler}
                    disabled={props.study.status === "open" ? "" : "disabled"}
                />
                <label htmlFor="select_patient_eye">Technician have to select Patient Eye</label>
                <div>
                    <textarea
                        className="submit-comment"
                        style={{margin: "10px 0", width: "94.5% !important"}}
                        name="patient_details"
                        id="patient_details"
                        value={props.study.patient_details}
                        onChange={props.studyChangeHandler}
                        disabled={props.study.status === "open" ? "" : "disabled"}
                    ></textarea>
                </div>
            </div>
        </div>
    );
}

function StudyFormScheduledVisitsNoExams(props) {
    const closeNoExamsPopup = () => {
        props.setExamPopupVisible(false);
    };

    return (
        <React.Fragment>
        <div className="modal fade in">
            <div className="modal-header">
                <h3>Warning!</h3>
            </div>

            <div className="modal-body">
                <p>Please, select first one or more exams in the study.</p>
            </div>

            <div className="modal-footer">
                <button
                    onClick={closeNoExamsPopup}
                    className="btn small grey align-center right">OK
                </button>
            </div>
        </div>

        <div className="modal-backdrop fade in"></div>
        </React.Fragment>
    );
}

function StudyFormScheduledVisitsExams(props) {
    const closeExamsPopup = () => {
        props.setExamPopupVisible(false);
        props.setCurrentVisit({});
    };

    const isExamInVisit = (examId) => {

        const ex = props.currentVisit.exams.find(e => {
            return e.exam_type.id === examId;
        })

        if (ex !== undefined) {
            return true;
        }

        return false;

    };
    const onCheckHandler = (e, visit, exam) => {
        let exams;

        if (!e.target.checked) {
            exams = visit.exams.filter(ex => {
               return ex.exam_type.id !== exam.exam_type.id;
            });
        }
        else {
            exams = visit.exams.slice();
            exams.push(exam);
        }
        props.setCurrentVisit({...visit, 'exams': exams});

    };

    const checkAllExamsPopup = (visit) => {

        props.study.exams.map(exam => {
            let ex = visit.exams.find(ex => {
                return ex.exam_type.id === exam.exam_type.id;
            });

            if (ex === undefined) {
                visit.exams.push(exam);
            }
            return false;
        });

        props.setCurrentVisit({...visit});


    };

    const saveExamsPopup = (visit) => {

        props.study.scheduled_visits.map(vis => {
            if (vis.id === visit.id) {
                vis.exams = visit.exams;
            }
            return false;
        });

        props.setExamPopupVisible(false);
        props.setStudy({...props.study});
        props.setCurrentVisit({});

    };

    return (
        <React.Fragment>
        <div className="modal fade in">
            <div className="modal-header">
                <h3>Visits Exams</h3>
            </div> {/* .modal-header */}

            <div className="modal-body checkbox-group group-page">
                <div className="row" id="exams-modal">
                    {props.study.exams.map((exam) => (
                    <div key={exam.exam_type.id} className="checkbox">
                        <input
                            id={"study_exam_" + exam.exam_type.id}
                            name="study_exam"
                            type="checkbox"
                            value={exam.exam_type.id}
                            checked={ isExamInVisit(exam.exam_type.id) }
                            onChange={(e) => onCheckHandler(e, props.currentVisit, exam) }
                            disabled={props.study.status === "open" ? "" : "disabled"}
                        />
                        <label htmlFor={"study_exam_" + exam.exam_type.id}>{exam.exam_type.name}</label>
                    </div>
                    ))}
                </div>
            </div> {/* .modal-body */}

            <div className="modal-footer">

                {props.study.status === "open" && (
                <button
                    onClick={() => checkAllExamsPopup(props.currentVisit)}
                    className="btn small grey align-center left">Add all
                </button>
                )}

                {props.study.status === "open" && (
                <button
                    onClick={() => saveExamsPopup(props.currentVisit)}
                    className="btn small orange align-center right">Save
                </button>
                )}
                <button
                    onClick={closeExamsPopup}
                    className="btn small grey align-center right">Cancel
                </button>
            </div>
        </div>

        <div className="modal-backdrop fade in"></div>
        </React.Fragment>
    );
}

function StudyFormScheduledVisitsItem(props) {
    const showExamsPopup = visit => {
        let currVisit = JSON.parse(JSON.stringify(visit));
        props.setCurrentVisit(currVisit);
        props.setExamPopupVisible(true);
    };

    const visitChangeHandler = (e, type, visit) => {
        let editedVisits = JSON.parse(JSON.stringify(props.study.scheduled_visits));
        editedVisits.map(v => {
            if(visit.id === v.id) {
                v[type] = e.target.value;
            }
            return true;
        });

        props.setStudy({...props.study, scheduled_visits: editedVisits});
    };

    const visitDateChangeHandler = (type, val, visit) => {
        let editedVisits = JSON.parse(JSON.stringify(props.study.scheduled_visits));
        editedVisits.map(v => {
            if(visit.id === v.id) {
                v[type] = val;
            }
            return true;
        });

        props.setStudy({...props.study, scheduled_visits: editedVisits});
    };

    const deleteVisit = visit => {
        let editedVisits = JSON.parse(JSON.stringify(props.study.scheduled_visits));

        editedVisits = editedVisits.filter(v => {return v.id !== visit.id});

        if(editedVisits.length) {
            editedVisits.map( (v, i) => v.order = i);
        }
        props.setStudy({...props.study, 'visits_num': editedVisits.length, scheduled_visits: editedVisits});
    };

    const buildYearsOptions = () => {
        let years = [
            {label: 'N/A', value: '-1'},
        ];

        [...Array(11).keys()].map((item)=> {
            years.push({label: item, value: item});
            return false;
        })

        return years;
    };

    const buildMonthsOptions = () => {
        let months = [
            {label: 'N/A', value: '-1'},
        ];

        [...Array(13).keys()].map((item)=> {
            months.push({label: item, value: item});
            return false;
        })

        return months;
    };

    const buildDaysOptions = () => {
        let days = [
            {label: 'N/A', value: '-1'},
        ];

        [...Array(31).keys()].map((item)=> {
            days.push({label: item, value: item});
            return false;
        })

        return days;
    };

    return (
        <tr className="added" id={"visit-" + props.visit.id} order={props.order}>
            <td width="38%">
                <input
                    type="text"
                    name={"schedule_visit_name_"+props.visit.id}
                    style={{width: "92%"}}
                    value={props.visit.name}
                    onChange={(e) => visitChangeHandler(e, 'name', props.visit)}
                    disabled={props.study.status === "open" ? "" : "disabled"}
                />
            </td>

            <td width="16%">
                <label>From reference date:</label>
            </td>

            <td width="8%">
                <CorcSelect
                    options={buildYearsOptions()}
                    name={"schedule_visit_years_"+props.visit.id}
                    isSearchable={false}
                    value={props.visit.years !== "" ? {label: parseInt(props.visit.years, 10) === -1 ? 'N/A' : props.visit.years, value: props.visit.years} : ''}
                    onChange={selectedOption => visitDateChangeHandler('years', selectedOption.value, props.visit)}
                    placeholder="Years"
                    isDisabled={props.study.status === "open" ? "" : "disabled"}
                />
            </td>

            <td width="8%">
                <CorcSelect
                    options={buildMonthsOptions()}
                    name={"schedule_visit_months_"+props.visit.id}
                    placeholder="Months"
                    isSearchable={false}
                    value={props.visit.months !== "" ? {label: parseInt(props.visit.months, 10) === -1 ? 'N/A' : props.visit.months, value: props.visit.months} : ''}
                    onChange={selectedOption => visitDateChangeHandler('months', selectedOption.value, props.visit)}
                    isDisabled={props.study.status === "open" ? "" : "disabled"}
                />
            </td>

            <td width="8%">
                <CorcSelect
                    options={buildDaysOptions()}
                    name={"schedule_visit_days_"+props.visit.id}
                    placeholder="Days"
                    isSearchable={false}
                    value={props.visit.days !== "" ? {label: parseInt(props.visit.days, 10) === -1 ? 'N/A' : props.visit.days, value: props.visit.days} : ''}
                    onChange={selectedOption => visitDateChangeHandler('days', selectedOption.value, props.visit)}
                    isDisabled={props.study.status === "open" ? "" : "disabled"}
                />
            </td>

            <td width="5%">
                <button
                    style={{margin: "0 5px"}}
                    className="btn small grey align-center"
                    onClick={() => { showExamsPopup(props.visit) } }
                >Exams
                </button>
            </td>

            {props.study.status === "open" && (
            <td width="4%">
                <button
                    className="btn xsmall grey add-visit"
                    onClick={() => {props.addVisit(props.order)}}
                >
                    <span className="study-visit-icon add-small">&nbsp;</span>
                </button>
            </td>
            )}

            {props.study.status === "open" && (
            <td width="4%">
                <button
                    className="btn xsmall grey remove-visit"
                    onClick={() => {deleteVisit(props.visit)}}
                >
                    <span className="study-visit-icon delete-small">&nbsp;</span>
                </button>
            </td>
            )}
        </tr>
    );
}

function StudyFormExamsCertifications(props) {

    const isTechChecked = (exam) => {
        return exam.technician_certification_required;
    };

    const isEquipChecked = (exam) => {
        return exam.equipment_certification_required;
    };

    const onCheckHandler = (ev, exam, type) => {

        let editedStudy = JSON.parse(JSON.stringify(props.study));
        let ex = editedStudy.exams.find(ex => {
            return ex.exam_type.id === exam.exam_type.id;
        });

        if (ex === undefined) {
            return false;
        }

        if(type === 'technician') {
            ex.technician_certification_required = ev.target.checked;
        }
        else if(type === 'equipment') {
            ex.equipment_certification_required = ev.target.checked;
        }

        props.setStudy(editedStudy);

    };

    return (
        <div className="form-group checkbox-group group-page" id="exam-certifications">
            <span style={{fontSize: "12px", color: "#ABABAB", marginLeft: "388px", position: "absolute",marginTop: "-26px"}}>
                Required Technician Certification
            </span>
            <span style={{fontSize: "12px", color: "#ABABAB", marginLeft: "727px", marginTop: "-26px", position: "absolute"}}>
                Required Equipment Certification
            </span>

            {props.study.exams.map(exam =>
                (
                <div className="row" key={"cert-exam-"+exam.exam_type.id} id={"cert-exam-"+exam.exam_type.id} style={{paddingTop: "4px"}}>
                    <label className="exam-name" style={{marginTop: "12px", display: "block", width: "41%", float: "left", fontSize: "14px"}}><b>{exam.exam_type.name}</b></label>
                    <div className="checkbox" style={{width: "36%"}}>
                        <input
                            className="technician-input no-label"
                            value="true"
                            type="checkbox"
                            id={"study_exam_technician_cert_" + exam.exam_type.uid}
                            name={"study_exam_technician_cert_" + exam.exam_type.uid}
                            checked={ isTechChecked(exam) }
                            onChange={ (e) => onCheckHandler(e, exam, 'technician') }
                            disabled={props.study.status === "open" ? "" : "disabled"}
                        />
                        <label htmlFor={"study_exam_technician_cert_" + exam.exam_type.uid}></label>
                    </div>

                    <div className="checkbox" style={{width: "15%"}}>
                        <input
                            className="equipment-input no-label"
                            value="true"
                            type="checkbox"
                            id={"study_exam_equipment_cert_" + exam.exam_type.uid }
                            name={"study_exam_equipment_cert_" + exam.exam_type.uid }
                            checked={ isEquipChecked(exam) }
                            onChange={ (e) => onCheckHandler(e, exam, 'equipment') }
                            disabled={props.study.status === "open" ? "" : "disabled"}
                        />
                        <label htmlFor={"study_exam_equipment_cert_" + exam.exam_type.uid }></label>
                    </div>
                </div>
            ))}
        </div>
    );
}

function StudyFormExams(props) {

    const isExamChecked = (exam) => {

      let found = props.study.exams.find(obj => {
         return obj.exam_type.id === exam.id;
      });

      if (found !== undefined) {
          return true;
      }

      return false;

    };


    const examsCheckHandler = (event, exam) => {

        let isChecked = event.target.checked,
            editedStudy = JSON.parse(JSON.stringify(props.study));

        if(isChecked) {
            editedStudy.exams.push({
                "equipment_certification_required": true,
                "technician_certification_required": true,
                "exam_type": exam,
                "is_deleteable": true
            });
        }
        else {

            for(let i=0;i<=editedStudy.exams.length-1;++i) {
                if(editedStudy.exams[i].exam_type.id === exam.id) {
                    if (editedStudy.exams[i].is_deleteable) {
                        editedStudy.exams.splice(i, 1);
                    }
                    else {
                        props.setStudyAlertExamsPopup(true);
                    }
                    break;
                }
            }
        }

        props.setStudy(editedStudy);

        return true;
    };

    return (
        <div className="form-group checkbox-group group-page">
            <div className="row" id="study-exams" style={{paddingTop: "4px"}}>
                {props.examTypes.map((exam, i) => (
                    <div
                        className="checkbox"
                        key={i}
                    >
                        <input
                            key={exam.id}
                            checked={ isExamChecked(exam) }
                            value={exam.id}
                            id={"exams_"+ exam.id}
                            name="exams"
                            type="checkbox"
                            onChange={(e) => examsCheckHandler(e, exam)}
                            disabled={props.study.status === "open" ? "" : "disabled"}
                        />
                        <label htmlFor={"exams_"+ exam.id}>{exam.name}</label>
                    </div>
                ))}
            </div>
        </div>
    );
}

function StudyFormDetails(props) {
    const studyStartDateChangeHandler = (date) => {
        let txtDate = '';

        if (date) {
            txtDate = date.getFullYear() + '-' + (date.getMonth()+1) + '-' + date.getDate();
        }

        props.setStudy({...props.study, 'start_date': txtDate})
    };

    const studyEndDateChangeHandler = (date) => {
        let txtDate = '';

        if (date) {
            txtDate = date.getFullYear() + '-' + (date.getMonth()+1) + '-' + date.getDate();
        }

         props.setStudy({...props.study, 'end_date': txtDate})
    };

    return (
        <div className="form-group">
            <div style={{width: '100%', marginTop: '10px'}}></div>

            <div className="span5" style={{width: "34%"}}>
                <div className="row" style={{paddingTop: "4px"}}>
                    <div className="label">
                        <label htmlFor="acronym" className="required">Acronym <span className="required">*</span></label>
                    </div>
                    <div className="input">
                        <input
                            name="acronym"
                            id="acronym"
                            type="text"
                            maxLength={25}
                            value={props.study.acronym}
                            onChange={props.studyChangeHandler}
                            disabled={props.study.status === "open" ? "" : "disabled"}
                        />
                    </div>
                </div>
            </div> {/* .span5 Acronym */}

            <div className="span5" style={{width: "32%"}}>
                <div className="row" style={{paddingTop: "4px"}}>
                    <div className="label" style={{width: "66px"}}>
                        <label htmlFor="start_date">Start Date *</label>
                    </div>
                    <div className="input">
                        <DatePicker
                            dateFormat="dd/MM/yyyy"
                            name="start_date"
                            selected={props.study.start_date && props.initDate(props.study.start_date)}
                            onChange={studyStartDateChangeHandler}
                            disabled={props.study.status === "open" ? "" : "disabled"}
                        />
                    </div> {/* .input */}
                </div> {/* .row */}
            </div> {/* .span5  start-date */}

            <div className="span5" style={{width: "32%", marginLeft: "10px"}}>
                <div className="row" style={{paddingTop: "4px"}}>
                    <div className="label" style={{width: "66px"}}>
                        <label htmlFor="addStudyEndDate">End Date *</label>
                    </div>
                    <div className="input">
                        <DatePicker
                            dateFormat="dd/MM/yyyy"
                            name="end_date"
                            selected={props.study.end_date && props.initDate(props.study.end_date)}
                            onChange={studyEndDateChangeHandler}
                            disabled={props.study.status === "open" ? "" : "disabled"}
                        />
                    </div> {/* .input */}
                </div> {/* .row */}
            </div> {/* .span5  End Date */}

            <div className="span5" style={{width: "100%", marginBottom: "5px"}}>
                <div className="row" style={{paddingTop: "4px"}}>
                    <div className="label">
                        <label htmlFor="full_name" className="required">Full Name <span className="required">*</span></label>
                    </div> {/* .row */}
                    <div>
                        <input
                            style={{width: "83%"}}
                            name="full_name"
                            id="full_name"
                            type="text"
                            maxLength="1000"
                            value={props.study.full_name}
                            onChange={props.studyChangeHandler}
                            disabled={props.study.status === "open" ? "" : "disabled"}
                        />
                    </div>
                </div> {/* .row */}
            </div> {/* .span5  Full Name  */}

            <div className="span5" style={{width: "32%"}}>
                <div className="row" style={{paddingTop: "4px"}}>
                    <div className="label">
                        <label htmlFor="protocol_num" className="required">Protocol Nº <span className="required">*</span></label>
                    </div>
                    <div className="input">
                        <input
                            name="protocol_num"
                            id="protocol_num"
                            type="text"
                            maxLength="100"
                            value={props.study.protocol_num}
                            onChange={props.studyChangeHandler}
                            disabled={props.study.status === "open" ? "" : "disabled"}
                        />
                    </div>
                </div> {/* .row */}
            </div> {/* .span5  Protocol Num */}

            <div className="span5" style={{width: "33%"}}>
                <div className="row" style={{paddingTop: "4px"}}>
                    <div className="label" style={{width: "69px"}}>
                        <label htmlFor="corc_num" className="required">CORC Nº <span className="required">*</span></label>
                    </div>
                    <div>
                        <input
                            style={{width: "70%"}}
                            name="corc_num"
                            id="corc_num"
                            type="text"
                            maxLength="100"
                            value={props.study.corc_num}
                            onChange={props.studyChangeHandler}
                            disabled={props.study.status === "open" ? "" : "disabled"}
                        />
                    </div>
                </div> {/* .row  */}
            </div> {/* .span5  CORC Num */}

            <div className="span5" style={{width: "33%"}}>
                <div className="row" style={{paddingTop: "4px"}}>
                    <div className="label" style={{width: "69px"}}>
                        <label htmlFor="visits_num" className="required">Nº Visits<span className="required">*</span></label>
                    </div>
                    <div>
                        <input
                            style={{width: "70%"}}
                            name="visits_num"
                            id="visits_num"
                            type="text"
                            maxLength="100"
                            disabled
                            value={props.study.visits_num}
                            
                        />
                    </div>
                </div> {/* .row  */}
            </div> {/* .span5  Visits Num */}


            <div className="span5" style={{width: "100%"}}>
                <div className="row" style={{paddingTop: "4px"}}>
                    <div className="label">
                        <label htmlFor="description" className="required">Description <span className="required">*</span></label>
                    </div>
                    <div>
                        <textarea
                            className="submit-comment"
                            style={{margin: "3px 0", width: "81.5% !important"}}
                            name="description"
                            id="description"
                            value={props.study.description}
                            onChange={props.studyChangeHandler}
                            disabled={props.study.status === "open" ? "" : "disabled"}
                         >
                        </textarea>
                    </div>
               </div>
            </div> {/* .span5 description */}

            <div className="span5" style={{width: "100%",  marginBottom: "10px"}}>
                <div className="row" style={{paddingTop: "4px"}}>
                    <div className="label">
                        <label htmlFor="corc_email" className="required">CORC E-mail <span className="required">*</span></label>
                    </div>
                    <div className="input">
                        <input
                            style={{width: "473px"}}
                            name="corc_email"
                            id="corc_email"
                            type="text"
                            maxLength="150"
                            value={props.study.corc_email}
                            onChange={props.studyChangeHandler}
                            disabled={props.study.status === "open" ? "" : "disabled"}
                        />
                    </div>
                </div> {/* .row */}
            </div> {/* .span5  Protocol Num */}


        </div>
    );
}

