import React from 'react';

function LoadingOverlay(props) {
    return (
        <div id="loading-main">
            <span>
            {props.filesUploading && (
                'Please wait while your files are being uploaded...'
            )}
            </span>
        </div>
    );
}

export default LoadingOverlay;

