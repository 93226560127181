import React, {useEffect, useState}  from 'react';
import axios, {post} from 'axios';
import {useDropzone} from 'react-dropzone'
import {Link} from 'react-router-dom';
import ReactHtmlParser from 'react-html-parser';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import {initDateTime, formatDate} from 'components/utils/Helpers.js';
const prettyBytes = require('pretty-bytes');

function TechnicianCertificationReport(props) {
    useEffect(function() {

        const getCertification = async () => {
            let count = props.overlayVisible;
            props.setOverlayVisible( (count) => count + 1);

            axios.get(process.env.REACT_APP_API_ENDPOINT+'/certifications/technician/'+props.match.params.certification+'/',
                { withCredentials: true, headers: {'Content-Type': 'application/json'}})

            .then((response) => {

                setCertification(response.data);
                setAcceptTerms(true);
                props.setOverlayVisible( (count) => count - 1);


            }, (error) => {
                props.setOverlayVisible( (count) => count - 1);
            });

        };

        props.setNavLinks([{title: 'Studies', url: '/studies/', icon: 'studies'},{title: 'Certification', url: '/studies/'+props.match.params.id+'/certifications/', icon: 'certification'},{title: 'Technician', url: props.location.pathname, icon: 'submit'}]);

        getCertification();

        props.setShowPrompt(true);
        window.addEventListener('beforeunload', props.showPromptHandler);
        //on unmount
        return () => {
            props.setShowPrompt(false);
            window.removeEventListener('beforeunload', props.showPromptHandler);
        };
    }, []);

    const certListURL = `/studies/${props.match.params.id}/certifications`;
    const [certification, setCertification] = useState({
        "id": 0,
        "technician": null,
        "grader": null,
        "user_submitted": null,
        "admin": null,
        "study_exam": null,
        "status": "",
        "created_at": "",
        "updated_at": "",
        "deleted_at": null,
        "name": "",
        "submission_date": "",
        "grading_date": "",
        "submitter_comment": "",
        "grade_comment": "",
        "study": {},
        "technician_files": [],
        "technician_certification_grading": [],
    });

    const [noFiles, setNoFiles] = useState(false);
    const [noFilesErrorPopup, setNoFilesErrorPopup] = useState(false);
    const [showAdminFiles, setShowAdminFiles] = useState(false);
    const [uploadedFiles, setUploadedFiles] = useState([]);
    const [uploadedFilesComment, setUploadedFilesComment] = useState("");
    const [acceptTerms, setAcceptTerms] = useState(false);

    const [showRenameFilePopup, setShowRenameFilePopup] = useState(false);
    const [renameFileName, setRenameFileName] = useState("");
    const [renameFileId, setRenameFileId] = useState(0);

    const [percentUploaded, setPercentUploaded] = useState(0);

    const closeNoFilesPopup = () => {
        setNoFilesErrorPopup(false);
    }

    const submitHandler = e => {
        e.preventDefault();
    };

    const closeRenameFilePopup = () => {
        setRenameFileName("");
        setRenameFileId(0);
        setShowRenameFilePopup(false);
    };

    const submitRenameFilePopup = async () => {

        const errMsg = "Sorry, something went wrong when renaming this file. Please contact your IT helpdesk.";
        let name = renameFileName;

        // check if name is trimmed
        if (!name || !name.trim()) {
            return false;
        }

        name = name.trim();

        let count = props.overlayVisible;
        props.setOverlayVisible( (count) => count + 1);

        // change status of exam
        await axios.post(process.env.REACT_APP_API_ENDPOINT+'/certifications/technician/'+certification.id+'/file/'+ renameFileId +'/rename/', {'name': name}, { withCredentials: true})
            .then((response) => {

            let files, ext;
            if (response.status === 200) {

                files = certification.technician_files.map(f => {
                    if (f.id === renameFileId) {
                        ext = f.name.lastIndexOf('.');
                        if (ext !== -1) {
                            f.name = name + f.name.substr(ext);
                        }
                        else {
                            f.name = name;
                        }
                    }

                    return f;

                })

                setCertification({...certification, 'technician_files': files});
                setRenameFileName("");
                setRenameFileId(0);
                setShowRenameFilePopup(false);
                props.setOverlayVisible( (count) => count - 1);
            }
            else {
                props.setOverlayVisible( (count) => count - 1);
                alert(errMsg);
            }

        }, (error) => {
            props.setOverlayVisible( (count) => count - 1);
            alert(errMsg);
        });

    };

    return (

        <React.Fragment>

            {showRenameFilePopup && (
                <React.Fragment>
                <div className="modal-backdrop fade in"></div>
                <div id="alertModal" className="modal hide fade in" tabIndex="-1" role="dialog" aria-labelledby="myModalLabel" aria-hidden="false" style={{display: "block"}}>
                    <div className="modal-body"><br/>Do you want to change the name of this file?<br/><br/>

                        <input
                            type="text"
                            name="rename-file"
                            style={{width: "90%"}}
                            onChange={(ev) => setRenameFileName(ev.target.value)}
                            value={renameFileName}>
                        </input>
                    </div>

                    <div className="modal-footer">
                        <button onClick={submitRenameFilePopup} id="alert-modal-ok" aria-hidden="true" className="btn small orange align-center right">Save</button>
                        <button onClick={closeRenameFilePopup} data-dismiss="modal" id="alert-modal-cancel" aria-hidden="true" className="btn small grey align-center right">Cancel</button>
                    </div>
                </div>
                </React.Fragment>
            )}

            {noFilesErrorPopup && (

                <React.Fragment>
                <div className="modal-backdrop fade in"></div>
                <div id="alertModal" className="modal hide fade in" tabIndex="-1" role="dialog" aria-labelledby="myModalLabel" aria-hidden="false" style={{display: "block"}}>
                    <div className="modal-header">
                        <button type="button" className="close" data-dismiss="modal" aria-hidden="true">×</button>
                        <h3 id="alert-modal-title">Warning!</h3>
                    </div>
                    <div className="modal-body"><br/><br/>You are submitting without files. Please add files or check the checkbox<br/></div>
                    <div className="modal-footer">
                        <button onClick={closeNoFilesPopup} data-dismiss="modal" id="alert-modal-ok" aria-hidden="true" className="btn small orange align-center right">OK</button>
                    </div>
                </div>
                </React.Fragment>
            )}

        <div className="home_header_container_class">
            <div className="content">
                <div className="breadcrumbs">
                    <div className="path">
                        <ul>
                            <li><span className="orange text-shadow">{certification.study && certification.study.acronym ? certification.study.acronym.toUpperCase() : ""}</span></li>
                            <li className="separator text-shadow">&gt;</li>
                            <li><span className="orange text-shadow">Certifications</span></li>
                            <li className="separator text-shadow">&gt;</li>
                            <li><span className="orange text-shadow">Technician</span></li>
                            <li className="separator text-shadow">&gt;</li>
                            <li><span className="orange text-shadow">{certification.study_exam && certification.study_exam.exam_type.name}</span></li>
                            <li className="separator text-shadow">&gt;</li>
                            <li>{certification.technician && certification.technician.username}</li>
                        </ul>
                    </div>
                </div> {/* .breadcrumbs */}

                <div className="main-form">
                    <form onSubmit={submitHandler} id="register-exam" method="post">
                        <TechnicianCertificationSubmissionInfo
                            certification={certification}
                        />

                        <TechnicianCertificationData
                            certification={certification}
                            setCertification={setCertification}
                        />

                        <TechnicianCertificationTerms
                            acceptTerms={acceptTerms}
                            setAcceptTerms={setAcceptTerms}
                        />
                    </form>

                    <TechnicianCertificationFiles
                        setOverlayVisible={props.setOverlayVisible}
                        certification={certification}
                        setCertification={setCertification}
                        noFiles={noFiles}
                        setNoFiles={setNoFiles}
                        showAdminFiles={showAdminFiles}
                        setShowAdminFiles={setShowAdminFiles}
                        userType={props.userAuth.user.role}
                        uploadedFiles={uploadedFiles}
                        setUploadedFiles={setUploadedFiles}
                        setUploadedFilesComment={setUploadedFilesComment}
                        uploadedFilesComment={uploadedFilesComment}
                        setShowRenameFilePopup={setShowRenameFilePopup}
                        setRenameFileName={setRenameFileName}
                        setRenameFileId={setRenameFileId}
                        percentUploaded={percentUploaded}
                        setPercentUploaded={setPercentUploaded}
                        setFilesUploading={props.setFilesUploading}
                        setShowPrompt={props.setShowPrompt}
                        history={props.history}
                        certListURL={certListURL}
                    />

                    {(props.userAuth.user.role === 'grader' || props.userAuth.user.role === 'admin') && (certification.status === 'notcertified' || certification.status === 'certified' || certification.status === 'pending') && (
                    <TechnicianCertificationGraderComment
                        certification={certification}
                    />
                    )}

                    {(props.userAuth.user.role === 'grader' || props.userAuth.user.role === 'monitor' || props.userAuth.user.role === 'admin' || props.userAuth.user.role === 'tech') && (

                    <TechnicianCertificationGraderVars
                        certification={certification}
                        setCertification={setCertification}
                        gradingVars={certification.technician_certification_grading}
                    />

                    )}

                    <TechnicianCertificationActions
                        userType={props.userAuth.user.role}
                        certification={certification}
                        studyId={props.match.params.id}
                        setUploadedFilesComment={setUploadedFilesComment}
                        uploadedFilesComment={uploadedFilesComment}
                        setOverlayVisible={props.setOverlayVisible}
                        overlayVisible={props.overlayVisible}
                    />
                </div>
            </div>
        </div>
        </React.Fragment>

    );
}

export default TechnicianCertificationReport;

function TechnicianCertificationSubmissionInfo(props) {

    let evaluatedBy = "", submittedBy = "";

    const getStatusDisplay = status => {

        if (status === 'notstarted') {
            return 'Not Started';
        }
        else if (status === 'pending') {
            return 'Pending';
        }
        else if (status === 'notcertified') {
            return 'Not Certified'
        }
        else if (status === 'incertification') {
            return 'In Certification'
        }
        else if (status === 'certified') {
            return 'Certified'
        }

        return '';

    }

    if (props.certification.user_graded) {
        evaluatedBy = '<p>EVALUATED BY: '+props.certification.user_graded.name + ' ' + props.certification.user_graded.last_name +' ('+props.certification.user_graded.username+')</p><p>EVALUATED ON: '+formatDate(props.certification.grading_date, true)+'</p><br />';
    }

    if (props.certification.user_submitted) {
        submittedBy = '<p>SUBMITTED BY: '+props.certification.user_submitted.name + ' ' + props.certification.user_submitted.last_name +' ('+props.certification.user_submitted.username+')</p><p>SUBMITTED ON: '+formatDate(props.certification.submission_date, true)+'</p><br />';
    }

    return (
        <div className="form-group content">
            <br />

            { ReactHtmlParser (evaluatedBy) }
            { ReactHtmlParser (submittedBy) }

            <p>CERTIFICATION STATUS: {getStatusDisplay(props.certification.status)}</p>
        </div>
    );

}

function TechnicianCertificationData(props) {
    const onDateChangeHandler = (date) => {
        let txtDate = '';

        if (date) {
            txtDate = date.getFullYear() + '-' + (date.getMonth()+1) + '-' + date.getDate();
        }

        props.setCertification({...props.certification, 'submission_date': txtDate})
    };

    const onChangeHandler = (ev) => {
        let name = ev.target.name;
        let value = ev.target.value;

        props.setCertification({...props.certification, [name]: value});
    };

    return (
        <div className="form-group">
            <div className="span5 insert-visit" style={{width: "46%"}}>
                <div className="row exam-date" >
                    <div className="label">
                        <label htmlFor="submission_date" className="required">Date *</label>
                    </div>
                    <div className="input">
                        <DatePicker
                            dateFormat="dd/MM/yyyy"
                            id="submission_date"
                            name="submission_date"
                            disabled={true}
                            onChange={onDateChangeHandler}
                            selected={props.certification.submission_date && initDateTime(props.certification.submission_date)}
                        />
                    </div>
                </div> {/* .row */}
            </div>

            <div className="span5" style={{width: "54%"}}>
                <textarea
                    style={{height: "200px"}}
                    className="submit-comment"
                    maxLength="5000"
                    placeholder="Comments"
                    name="submitter_comment"
                    id="submitter_comment"
                    disabled={true}
                    value={props.certification.submitter_comment}
                    onChange={(ev) => onChangeHandler(ev)}
                ></textarea>
            </div>
        </div>
    );
}

function TechnicianCertificationTerms(props) {
    return (
        <div className="form-group content">
            <br />
            <input
                type="checkbox"
                id="checkbox-accept"
                checked={props.acceptTerms}
                disabled={true}
                onClick={(ev) => {props.setAcceptTerms(ev.target.checked)}}
            />
            <label htmlFor="checkbox-accept" className="wrapped">I have read and understand the relevant protocols (i.e.. Study Protocol and CORC Technical Protocol(s)), and I agree to abide by the design and procedures of the trial.</label>
            <br />
        </div>
    );
}


function TechnicianCertificationFiles(props) {

    return (
        <React.Fragment>

        {props.certification.technician_files.map( (file, i) => (
        <TechnicianCertificationFileItem
            certification={props.certification}
            key={i}
            file={file}
            userType={props.userType}
            setShowRenameFilePopup={props.setShowRenameFilePopup}
            setRenameFileName={props.setRenameFileName}
            setRenameFileId={props.setRenameFileId}
        />
        ))}

        {props.userType === 'admin' && !props.certification.archived && props.certification.study.status === 'open' && (
        <TechnicianCertificationFileUploaderAdmin
            setOverlayVisible={props.setOverlayVisible}
            showAdminFiles={props.showAdminFiles}
            setShowAdminFiles={props.setShowAdminFiles}
            uploadedFiles={props.uploadedFiles}
            setUploadedFiles={props.setUploadedFiles}
            setCertification={props.setCertification}
            certification={props.certification}
            setUploadedFilesComment={props.setUploadedFilesComment}
            uploadedFilesComment={props.uploadedFilesComment}
            percentUploaded={props.percentUploaded}
            setPercentUploaded={props.setPercentUploaded}
            setFilesUploading={props.setFilesUploading}
            setShowPrompt={props.setShowPrompt}
            certListURL={props.certListURL}
            history={props.history}
        />
        )}

        </React.Fragment>
    );

}


function TechnicianCertificationFileItem(props) {


    const renameFile = () => {

        let name = props.file ? props.file.name : '';
        let id = props.file ? props.file.id : 0;
        let ext = name.lastIndexOf('.');

        if (ext !== -1) {
            name = name.substr(0, name.lastIndexOf('.'));
        }

        props.setRenameFileName(name);
        props.setRenameFileId(id);
        props.setShowRenameFilePopup(true);
    }

    return (
        <div className="form-group">
            <div className="file-uploaded">
                <div className="row">
                    <a target="_blank" rel="noopener noreferrer" href={process.env.REACT_APP_API_ENDPOINT + "/certifications/technician/"+props.certification.id+'/file/'+props.file.id+'/download/'} className="cancel-btn-class btn small grey align-center">Download</a>

                    {props.userType !== 'admin' && (
                    <div className="file-name">
                        <span className="filename-class">{props.file.name}<b>{" (" + prettyBytes(props.file.file && props.file.file.size ? props.file.file.size : 0) + ")"}</b></span>
                    </div>
                    )}
                    {props.userType === 'admin' && (
                    <div className="file-name">
                        <span onClick={() => renameFile()} style={{'cursor': 'pointer'}} className="filename-class">{props.file ? props.file.name : ''}<b>{" (" + prettyBytes(props.file ? props.file.file.size : 0) + ")"}</b></span>
                    </div>
                    )}

                    <div className="file-comment" style={{display: 'block', width: "40%", float: "right", marginRight: "32px"}} ref={node => {if (node) { node.style.setProperty("float", "right", "important")}}}>
                        <input
                            maxLength="255"
                            type="text"
                            className="uploaded_file"
                            value={props.file.description}
                            placeholder="Comment"
                            disabled={true}
                        />
                    </div>
                </div>
            </div>
        </div>
    );
}



function TechnicianCertificationFileUploaderAdmin(props) {
    const showAdminFilesHandler = () => {
        props.setShowAdminFiles(true);
    };

    const onChangeHandler = ev => {
        let val = ev.target.value;

        props.setUploadedFilesComment(val);
    };

    const adminFileSubmitHandler = (event) => {
        event.preventDefault();

        const url = process.env.REACT_APP_API_ENDPOINT+'/certifications/technician/'+props.certification.id+'/uploader/';
        const formData = new FormData();
        const config = {
            withCredentials: true,
            headers: {
                'content-type': 'multipart/form-data'
            },
            onUploadProgress: progressEvent => {
                props.setPercentUploaded( Math.round( (progressEvent.loaded * 100) / progressEvent.total ) );
            }
        };

        let count = props.overlayVisible;
        props.setFilesUploading(true);
        props.setOverlayVisible( (count) => count + 1);

        if (!props.uploadedFiles.length) {
            props.setUploadedFilesComment('');
            props.setUploadedFiles([]);
            props.setShowAdminFiles(false);
            props.setFilesUploading(false);
            return false;
        }

        for (let i in props.uploadedFiles) {
            formData.append('file',props.uploadedFiles[i].file);
        }
        formData.append('comment', props.uploadedFilesComment);

        axios.post(url, formData,config).then(function (response) {
            props.setOverlayVisible( (count) => count - 1);
            props.setFilesUploading(false);

            if (response.status === 200 && response.data) {
                props.setUploadedFilesComment('');
                props.setUploadedFiles([]);
                props.setShowAdminFiles(false);

                // update certification object
                props.setCertification(response.data);
                props.setPercentUploaded(0);

                props.setShowPrompt(false);

                // go to certifications of study
                props.history.push(props.certListURL);
            }
        }, (error) => {
            props.setOverlayVisible( (count) => count - 1);
            props.setFilesUploading(false);
            alert(error);
        });

        return false;

    };


    const removeFileHandler = (ev,file) =>  {

        ev.preventDefault();

        let editedUploadedFiles = props.uploadedFiles.filter(f => f.key !== file.key);
        props.setUploadedFiles(editedUploadedFiles);

        if (!editedUploadedFiles.length) {
            props.setUploadedFilesComment('');
            props.setShowAdminFiles(false);
        }


        return false;
    };


    const onDrop = acceptedFiles => {
        let editedUploadedFiles = JSON.parse(JSON.stringify(props.uploadedFiles));

        acceptedFiles.map(file => {
            editedUploadedFiles.push({file: file, key: Math.floor(Math.random(5) * 1000), name: file.name});
            return false;
        });

        props.setUploadedFiles(editedUploadedFiles);
    };
    const {getRootProps, getInputProps} = useDropzone({onDrop});

    return (
        <React.Fragment>
        {!props.showAdminFiles && props.certification.study.status === "open" && !props.certification.archived && (
        <div className="form-group">
            <button
                className="btn large orange  align-center right"
                id="save-form"
                onClick={() => showAdminFilesHandler()}
            >Adicionar ficheiros</button>
        </div>
        )}

        {props.showAdminFiles && (
        <form id="fileupload" method="post" encType="multipart/form-data">
            <div className="form-group">
                <div id="files" className="files">
                    <div {...getRootProps({
                        className: "info"
                    })}>
                        <span className="icon submit ico"></span>
                        <h2 className="text-shadow">Drop files here</h2>
                        <span className="or">
                            <hr />
                            <span>or</span>
                            <hr />
                        </span>
                        <span className="btn large orange fileinput-button">
                            <span>Select files from your computer</span>
                            <input {...getInputProps()} />
                        </span>
                    </div>
                    <div id="filesProgress" className="clearfix">
                        {props.uploadedFiles.map((file, i) => (
                        <div
                            key={i}
                            className="file-upload-box">
                            <p>
                                <span className="file-name">{file.name}</span>
                                <span className="file-actions">
                                    <button
                                        className="btn small grey align-center left other"
                                        onClick={(ev) => removeFileHandler(ev, file)}
                                    >REMOVE</button>
                                </span>
                                <span className="progress active">
                                    <span className="progress-bar progress-bar-warning" role="progressbar" style={{width: `${props.percentUploaded}%`}}></span>
                                    <span className="progress_value">
                                        {props.percentUploaded ? `${props.percentUploaded} % (a few seconds left)` : 'READY TO UPLOAD' }
                                    </span>
                                </span>
                            </p>
                        </div>
                        ))}
                    </div>
                </div>
                <textarea
                    className="submit-comment"
                    data-disabled="false"
                    name="info_admin_submit"
                    placeholder="Reason for adding files as admin.."
                    style={{margin: "10px 15px", width: "94.5%"}}
                    onChange={(ev) => onChangeHandler(ev)}
                    value={props.uploadedFilesComment}

                ></textarea>
                <p>
                    <input
                        type="submit"
                        value="Submit"
                        className="btn small orange align-center right"
                        onClick={adminFileSubmitHandler}
                    />
                </p>
            </div>
        </form>
        )}
        </React.Fragment>
    );
}

function TechnicianCertificationGraderComment(props) {
    return (
        <React.Fragment>
            <div className="overview">
                <h2 className="text-shadow">Grader Comment</h2>
            </div>
            <div className="form-group checkbox-group group-page">
                <form id="form-grader-vars" className="certification-grade-vars" action="" name="" method="POST">
                    <div className="form-vars check-onload">
                        <textarea
                            placeholder="Comments"
                            className="form-comment"
                            name="comments"
                            disabled={true}
                            value={props.certification.grader_comment}
                        ></textarea>
                    </div>
                </form>
            </div>
        </React.Fragment>
    )
}

function TechnicianCertificationGraderVars(props) {
    return (
        <React.Fragment>
            <div className="overview">
                <h2 className="text-shadow">Grader Variables</h2>
            </div>

            <div className="form-group checkbox-group group-page">
                <form id="form-grader-vars" className="certification-grade-vars" action="" name="" method="POST">
                    <div className="form-vars check-onload">
                        <table>
                            <thead>
                                <tr className="head-table">
                                    <th width="28%"></th>
                                    {props.gradingVars[0] && props.gradingVars[0].labelling_choices && Object.keys(props.gradingVars[0].labelling_choices).map((k, i) => (
                                    <th key={i}>{props.gradingVars[0].labelling_choices[k]}</th>
                                    ))}
                                    <th width="35%">Comments</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td>FILE(S) / FOLDER (S) LABELLING</td>
                                    {props.gradingVars[0] && props.gradingVars[0].labelling_choices && Object.keys(props.gradingVars[0].labelling_choices).map((k, i) => (
                                    <td key={i}>
                                        <input
                                            className="native"
                                            disabled={true}
                                            type="radio"
                                            name="file_labelling"
                                            value={k}
                                            checked={props.gradingVars[0] && props.gradingVars[0].file_labelling === k}
                                        />
                                    </td>
                                    ))}
                                    <td>
                                        <textarea
                                            style={{width: "95%", height: "100px"}}
                                            name="file_labelling_comments"
                                            disabled={true}
                                            value={props.gradingVars[0] && props.gradingVars[0].file_labelling_comments}
                                        ></textarea>
                                    </td>
                                </tr>
                            </tbody>
                        </table>

                        { props.certification.study_exam && props.certification.study_exam.exam_type.include_photo_quality
                          && (
                        <table>
                            <thead>
                                <tr className="head-table">
                                    <th width="28%">PHOTOGRAPHIC QUALITY</th>
                                    {props.gradingVars[0] && props.gradingVars[0].photo_quality_choices && Object.keys(props.gradingVars[0].photo_quality_choices).map((k, i) => (
                                    <th key={i}>{props.gradingVars[0].photo_quality_choices[k]}</th>
                                    ))}
                                    <th width="35%">Comments</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td>FOCUS / CLARITY</td>
                                    {props.gradingVars[0] && props.gradingVars[0].photo_quality_choices && Object.keys(props.gradingVars[0].photo_quality_choices).map((k, i) => (
                                    <td key={i}>
                                        <input
                                            className="native"
                                            disabled={true}
                                            type="radio"
                                            name="focus"
                                            value={k}
                                            checked={props.gradingVars[0] && props.gradingVars[0].focus === k}
                                        />
                                    </td>
                                    ))}
                                    <td rowSpan="3">
                                        <textarea
                                            style={{width: "95%", height: "100px"}}
                                            name="photo_quality_comments"
                                            disabled={true}
                                            value={props.gradingVars[0] && props.gradingVars[0].photo_quality_comments}
                                        ></textarea>
                                    </td>
                                </tr>
                                <tr>
                                    <td>FIELD DEFINITION</td>
                                    {props.gradingVars[0] && props.gradingVars[0].photo_quality_choices && Object.keys(props.gradingVars[0].photo_quality_choices).map((k, i) => (
                                    <td key={i}>
                                        <input
                                            className="native"
                                            type="radio"
                                            name="field_definition"
                                            value={k}
                                            disabled={true}
                                            checked={props.gradingVars[0] && props.gradingVars[0].field_definition === k}
                                        />
                                    </td>
                                    ))}
                                </tr>
                                <tr>
                                    <td>STEREOPSIS</td>
                                    {props.gradingVars[0] && props.gradingVars[0].photo_quality_choices && Object.keys(props.gradingVars[0].photo_quality_choices).map((k, i) => (
                                    <td key={i}>
                                        <input
                                            className="native"
                                            type="radio"
                                            name="stereopsis_definition"
                                            value={k}
                                            disabled={true}
                                            checked={props.gradingVars[0] && props.gradingVars[0].stereopsis_definition === k}
                                        />
                                    </td>
                                    ))}
                                </tr>

                            </tbody>
                        </table>
                        )}

                        {props.certification.study_exam && props.certification.study_exam.exam_type.include_quality && (
                        <table>
                            <thead>
                                <tr className="head-table">
                                    <th width="28%"></th>
                                    {props.gradingVars[0] && props.gradingVars[0].quality_choices && Object.keys(props.gradingVars[0].quality_choices).map((k, i) => (
                                    <th key={i}>{props.gradingVars[0].quality_choices[k]}</th>
                                    ))}
                                    <th width="35%">Comments</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td>QUALITY</td>
                                    {props.gradingVars[0] && props.gradingVars[0].photo_quality_choices && Object.keys(props.gradingVars[0].photo_quality_choices).map((k, i) => (
                                    <td key={i}>
                                        <input
                                            className="native"
                                            type="radio"
                                            name="quality"
                                            value={k}
                                            disabled={true}
                                            checked={props.gradingVars[0] && props.gradingVars[0].quality === k}
                                        />
                                    </td>
                                    ))}
                                    <td>
                                        <textarea
                                            style={{width: "95%", height: "100px"}}
                                            name="quality_comments"
                                            disabled={true}
                                            value={props.gradingVars[0] && props.gradingVars[0].quality_comments}
                                        ></textarea></td>
                                </tr>
                            </tbody>
                        </table>
                        )}

                        <table>
                            <thead>
                                <tr className="head-table">
                                    <th width="28%"></th>
                                    {props.gradingVars[0] && props.gradingVars[0].protocol_choices && Object.keys(props.gradingVars[0].protocol_choices).map((k, i) => (
                                    <th key={i}>{props.gradingVars[0].protocol_choices[k]}</th>
                                    ))}
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td>PROTOCOL PROCEDURES</td>
                                    {props.gradingVars[0] && props.gradingVars[0].protocol_choices && Object.keys(props.gradingVars[0].protocol_choices).map((k, i) => (
                                    <td key={i}>
                                        <input
                                            className="native"
                                            type="radio"
                                            name="protocol_procedures"
                                            value={k}
                                            disabled={true}
                                            checked={props.gradingVars[0] && props.gradingVars[0].protocol_procedures === k}
                                        />
                                    </td>
                                    ))}
                                </tr>
                            </tbody>
                        </table>

                        {props.certification.study_exam && props.certification.study_exam.exam_type.include_photo_fields && (
                        <table>
                            <thead>
                                <tr className="head-table">
                                    <th width="28%"></th>
                                    {props.gradingVars[0] && props.gradingVars[0].photo_fields_choices && Object.keys(props.gradingVars[0].photo_fields_choices).map((k, i) => (
                                    <th key={i}>{props.gradingVars[0].photo_fields_choices[k]}</th>
                                    ))}
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td>PHOTOGRAPHY FIELDS</td>
                                    {props.gradingVars[0] && props.gradingVars[0].photo_fields_choices && Object.keys(props.gradingVars[0].photo_fields_choices).map((k, i) => (
                                    <td key={i}>
                                        <input
                                            className="native"
                                            type="radio"
                                            name="photo_fields"
                                            value={k}
                                            disabled={true}
                                            checked={props.gradingVars[0] && props.gradingVars[0].photo_fields === k}
                                        />
                                    </td>
                                    ))}
                                </tr>
                            </tbody>
                        </table>
                        )}

                        <textarea
                            placeholder="Comments"
                            className="form-comment"
                            name="comments"
                            disabled={true}
                            value={props.gradingVars[0] && props.gradingVars[0].comments}
                        ></textarea>
                        <br /><br />
                        <textarea
                            placeholder="Other Comments"
                            className="form-comment"
                            name="internal_comments"
                            disabled={true}
                            value={props.gradingVars[0] && props.gradingVars[0].internal_comments}
                        ></textarea>
                    </div>
                </form>
            </div>

        </React.Fragment>
    );
}

function TechnicianCertificationActions(props) {
    const resendNotification = async (e) => {
        const errMsg = "Sorry, something went wrong when renaming this file. Please contact your IT helpdesk.";

        let count = props.overlayVisible;
        props.setOverlayVisible( (count) => count + 1);

        await axios.get(process.env.REACT_APP_API_ENDPOINT+'/certifications/technician/'+props.certification.id+'/resend_notification/', { withCredentials: true})
        .then((response) => {

            if (response.status === 200) {
                props.setOverlayVisible( (count) => count - 1);
            }
            else {
                props.setOverlayVisible( (count) => count - 1);
                alert(errMsg);
            }
        }, (error) => {
            props.setOverlayVisible( (count) => count - 1);
            alert(errMsg);
        });
    };

    const buttons = () => {
        return (
            <React.Fragment>
                {props.userType === 'admin' && props.certification.study.status === 'open' && !props.certification.archived && props.certification.study.notifications.find(n => n.uid === 'tech-cert') && (
                    <button
                        className="btn normal grey align-center left other"
                        onClick={resendNotification}
                    >Resend notification</button>
                )}
                <Link
                    to={"/studies/" + props.studyId + "/certifications/"}
                    className="btn small grey align-center left"
                >Back</Link>
            </React.Fragment>
        );
    };

    return (
        <div className="form-actions" style={{float: "right"}}>
            {buttons()}
        </div>

    );
}
