import React, {useEffect, useState} from 'react';
import axios, {post} from 'axios';
import CorcSelect from 'components/utils/CorcSelect';
const prettyBytes = require('pretty-bytes');

function Documentation(props) {

    useEffect(function() {


        const getStudy = async () => {
            let count = props.overlayVisible;
            props.setOverlayVisible( (count) => count + 1);

            await axios.get(process.env.REACT_APP_API_ENDPOINT+'/studies/'+props.match.params.id+'/documents/', { withCredentials: true}).then(response => {

                props.setOverlayVisible( (count) => count - 1);
                if (response.status === 200) {
                        setStudy(response.data);
                    }

                }, (error) => {
                    props.setOverlayVisible( (count) => count - 1);
                    if (error.response.status === 401) {
                        props.resetAuth();
                        props.history.push('/login');
                    }
                });
        }

        getStudy();
        props.setNavLinks([{title: 'Studies', url: '/studies/', icon: 'studies'},{title: 'Documents', url: props.location.pathname, icon: 'documents'}]);
    }, []);


    const [study, setStudy] = useState({});

    const [newDoc, setNewDoc] = useState([]);
    const [deleteDoc, setDeleteDoc] = useState(false);
    const [uploadedFile, setUploadedFile] = useState({});
    const [deletePopupVisible, setDeletePopupVisible] = useState(false);
    const [uploadPopupVisible, setUploadPopupVisible] = useState(false);
    const [popupHasErrors, setPopupHasErrors] = useState(false);

    const documentHasPermissions = (doc) => {

        if (doc.permission_technician || doc.permission_grader || doc.permission_monitor || doc.permission_admin || doc.permission_coordinator) {
            return true;
        }

        return false;

    }

    const setDocumentPermission = (val, doc) => {

        switch(val) {

            case 1:
                doc.permission_technician = true;
                doc.permission_grader = false;
                doc.permission_monitor = false;
                doc.permission_admin = true;
                doc.permission_coordinator = false;

                break;
            case 2:
                doc.permission_technician = false;
                doc.permission_grader = true;
                doc.permission_monitor = false;
                doc.permission_admin = true;
                doc.permission_coordinator = false;

                break;
            case 3:
                doc.permission_technician = false;
                doc.permission_grader = false;
                doc.permission_monitor = true;
                doc.permission_admin = true;
                doc.permission_coordinator = false;

                break;
            case 4:
                doc.permission_technician = true;
                doc.permission_grader = false;
                doc.permission_monitor = true;
                doc.permission_admin = true;
                doc.permission_coordinator = false;

                break;
            case 5:
                doc.permission_technician = true;
                doc.permission_grader = true;
                doc.permission_monitor = false;
                doc.permission_admin = true;
                doc.permission_coordinator = false;

                break;
            case 6:
                doc.permission_technician = false;
                doc.permission_grader = true;
                doc.permission_monitor = true;
                doc.permission_admin = true;
                doc.permission_coordinator = false;

                break;
            case 7:
                doc.permission_technician = false;
                doc.permission_grader = false;
                doc.permission_monitor = false;
                doc.permission_admin = true;
                doc.permission_coordinator = false;

                break;
            case 8:
                doc.permission_technician = true;
                doc.permission_grader = true;
                doc.permission_monitor = true;
                doc.permission_admin = true;
                doc.permission_coordinator = true;

                break;

            case 9:
                doc.permission_technician = false;
                doc.permission_grader = false;
                doc.permission_monitor = false;
                doc.permission_admin = true;
                doc.permission_coordinator = true;

                break;
            default:
                doc.permission_technician = false;
                doc.permission_grader = false;
                doc.permission_monitor = false;
                doc.permission_admin = true;
                doc.permission_coordinator = false;
        }

    };

    const getDocumentPermission = (doc) => {
        if(doc.permission_technician && doc.permission_grader && doc.permission_monitor && doc.permission_admin && doc.permission_coordinator) {
            return 8;
        }
        else if(doc.permission_technician && doc.permission_grader) {
            return 5;
        }
        else if(doc.permission_technician && doc.permission_monitor) {
            return 4;
        }
        else if(doc.permission_grader && doc.permission_monitor) {
            return 6;
        }
        else if(doc.permission_technician) {
            return 1;
        }
        else if(doc.permission_grader) {
            return 2;
        }
        else if(doc.permission_monitor) {
            return 3;
        }
        else if(doc.permission_coordinator) {
            return 9;
        }
        else {
            return 7;
        }

    };



    const deleteDocument = async(docId) => {

        const errMsg = "Sorry, something went wrong when deleting this document. Please contact your IT helpdesk.";

        setDeleteDoc({});

        let count = props.overlayVisible;
        props.setOverlayVisible( (count) => count + 1);

        await axios.delete(process.env.REACT_APP_API_ENDPOINT+'/studies/'+props.match.params.id+'/documents/'+docId+'/', { withCredentials: true})
            .then((response) => {
            props.setOverlayVisible( (count) => count - 1);
            if (response.status === 200) {

                let editedStudy = JSON.parse(JSON.stringify(study));
                for(let i=0, len=editedStudy.documents.length;i<=len-1;++i) {
                    if(editedStudy.documents[i].id === docId) {
                        editedStudy.documents.splice(i, 1);
                        break;
                    }
                }
                setStudy(editedStudy);
            }
            else {
                alert(errMsg);
            }
        }, (error) => {
            alert(errMsg);
            props.setOverlayVisible( (count) => count - 1);
        });

    };

    const updateDocument = async(doc) => {

        const changesToSave = ['id', 'name', 'permission_technician', 'permission_grader', 'permission_monitor', 'permission_admin', 'permission_coordinator'];
        let editedDoc = JSON.parse(JSON.stringify(doc));

        const errMsg = "Sorry, something went wrong when updating this document. Please contact your IT helpdesk.";

        for (let i in editedDoc) {
            if (editedDoc.hasOwnProperty(i) && !changesToSave.includes(i)) {
                delete editedDoc[i];
            }
        }

        editedDoc.name = editedDoc.name.trim();

        let count = props.overlayVisible;
        props.setOverlayVisible( (count) => count + 1);

        await axios.put(process.env.REACT_APP_API_ENDPOINT+'/studies/'+props.match.params.id+'/documents/'+editedDoc.id+'/', {...editedDoc}, { withCredentials: true})
            .then((response) => {
            props.setOverlayVisible( (count) => count - 1);
            if (response.status !== 200) {
                alert(errMsg);
            }
        }, (error) => {
            props.setOverlayVisible( (count) => count - 1);
            alert(errMsg);
        });

    };

    const showUploadPopup = () => {
        setNewDoc({
            name: "",
            permission_technician: false,
            permission_grader: false,
            permission_monitor: false,
            permission_admin: true,
            permission_coordinator: false,
        });

        setUploadPopupVisible(true);
    };

    const docPermissionsOptions = [
        {label: 'Technician', value: 1},
        {label: 'Grader', value: 2},
        {label: 'Monitor', value: 3},
        {label: 'Technician + Monitor', value: 4},
        {label: 'Technician + Grader', value: 5},
        {label: 'Grader + Monitor', value: 6},
        {label: 'Administrator', value: 7},
        {label: 'Study Coordinator', value: 9},
        {label: 'All', value: 8},
    ];

    return (
        <div className="home_header_container_class">
            <div className="content">
                <div className="breadcrumbs">
                    <div className="path">
                        <ul>
                            <li><span className="text-shadow orange">{study.acronym && study.acronym.toUpperCase()}</span></li>
                            <li className="separator text-shadow">&gt;</li>
                            <li>Documents</li>
                        </ul>
                    </div> {/* .path */}

                    {study.status === "open" && props.userAuth.user.role === 'admin' && (
                    <div className="sub-menu">
                        <button
                            className="btn normal grey right"
                            id="upload-file"
                            onClick={showUploadPopup}
                        >
                            <span className="icon "></span>&nbsp;&nbsp; Upload new file to study
                        </button>
                    </div>
                    )}
                </div> {/* .breadcrumbs */}

                <div className="documents">

                    {props.userAuth.user.role === 'admin' && study.documents && study.documents.map( (doc, i) => (
                    <DocumentationItem
                        key={i}
                        document={doc}
                        study={study}
                        setStudy={setStudy}
                        setDeletePopupVisible={setDeletePopupVisible}
                        setDeleteDoc={setDeleteDoc}
                        getDocumentPermission={getDocumentPermission}
                        setDocumentPermission={setDocumentPermission}
                        updateDocument={updateDocument}
                        permissionOptions={docPermissionsOptions}
                        userAuth={props.userAuth}
                        overlayVisible={props.overlayVisible}
                        setOverlayVisible={props.setOverlayVisible}
                    />
                    ))}

                    {(props.userAuth.user.role === 'monitor' || props.userAuth.user.role === 'tech' || props.userAuth.user.role === 'grader' || props.userAuth.user.role === 'coordinator') && (
                    <DocumentationReadOnlyItems
                        study={study}
                    />
                    )}


                </div>
                {deletePopupVisible && (
                <DocumentDeletePopup
                    setDeletePopupVisible={setDeletePopupVisible}
                    setStudy={setStudy}
                    study={study}
                    deleteDoc={deleteDoc}
                    deleteDocument={deleteDocument}
                />
                )}

                {uploadPopupVisible && (
                <DocumentUploadPopup
                    newDoc={newDoc}
                    setNewDoc={setNewDoc}
                    setStudy={setStudy}
                    study={study}
                    setUploadPopupVisible={setUploadPopupVisible}
                    getDocumentPermission={getDocumentPermission}
                    setDocumentPermission={setDocumentPermission}
                    setUploadedFile={setUploadedFile}
                    uploadedFile={uploadedFile}
                    permissionOptions={docPermissionsOptions}
                    userAuth={props.userAuth}
                    setPopupHasErrors={setPopupHasErrors}
                    popupHasErrors={popupHasErrors}
                    documentHasPermissions={documentHasPermissions}
                />
                )}
            </div> {/* .content */}
        </div>
    );
}

export default Documentation;

function DocumentationReadOnlyItems(props) {

    return (
        
        <React.Fragment>
        {props.study.documents && props.study.documents.length > 0 && (
        <div className="block-border">
            <table>
                <tbody>

                    {props.study.documents.map( (doc, i) => (

                        <tr key={i}>
                            <td width="80%">
                                <span>{doc.name}</span>
                            </td>
                            <td width="20%">
                                <a download rel="noopener noreferrer" href={process.env.REACT_APP_API_ENDPOINT + '/studies/' + props.study.id+'/documents/' + doc.id+'/download/'} target="_blank" className="btn small grey align-center">Download</a>
                            </td>
                        </tr>
                    ))}

                </tbody>
            </table>
        </div>
        )}
        </React.Fragment>        
    );

}

function DocumentationItem(props) {


    const onChangePermsHandler = val => {
        let editedStudy = JSON.parse(JSON.stringify(props.study));

        for(let i=0, len=editedStudy.documents.length; i<=len-1;++i) {
            if(editedStudy.documents[i].id === props.document.id) {
                props.setDocumentPermission(val, editedStudy.documents[i]);
                props.updateDocument(editedStudy.documents[i]);
                break;
            }
        }
        // TO-DO: fazer um ajax e gravar o objecto
        props.setStudy(editedStudy);
    }

    const showDeletePopup = () => {
        props.setDeleteDoc(props.document.id);
        props.setDeletePopupVisible(true);
    };

  


    return (
        <React.Fragment>
        <div className="block-border">
            <table>
                <tbody>
                    <tr>
                        <td width="65%">
                            <span>Document: </span>

                            <input
                                type="text"
                                value={props.document.name}
                                disabled={"disabled"}

                            />
                        </td>
                        <td width="35%">
                            <span>Available to:</span>
                            <CorcSelect
                                name={"document-permission-"+props.document.id}
                                id={"document-permission-"+props.document.id}
                                containerStyles={{width: "165px", display: "inline-block", verticalAlign: 'middle'}}
                                value={props.permissionOptions.filter(perm => perm.value === props.getDocumentPermission(props.document))}
                                onChange={selectedOption => onChangePermsHandler(selectedOption.value)}
                                options={props.permissionOptions}
                                isSearchable={false}
                                isDisabled={props.study.status === "open" ? "" : "disabled"}
                            />

                            {props.study.status === "open" && (
                            <button onClick={showDeletePopup} className="btn xsmall grey align-center">
                                <span className="study-visit-icon delete-small">&nbsp;</span>
                            </button>
                            )}

                            <a download rel="noopener noreferrer" href={process.env.REACT_APP_API_ENDPOINT + "/studies/"+props.study.id+"/documents/" + props.document.id+"/download/"} className="btn xsmall grey align-center">
                                <span className="study-visit-icon download-small">&nbsp;</span>
                            </a>
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
        <br />
        </React.Fragment>
    );
}

function DocumentUploadPopup(props) {

    const onChangeHandler = (ev) => {
        let editedDoc= JSON.parse(JSON.stringify(props.newDoc));
        let name = ev.target.name;

        if(name === 'name') {
            editedDoc.name = ev.target.value;
        }
        else if(name === 'file') {
            props.setUploadedFile(ev.target.files[0]);
        }

        props.setNewDoc(editedDoc);
    };

    const onChangePermissionHandler = val => {
        let editedDoc = JSON.parse(JSON.stringify(props.newDoc));

        props.setDocumentPermission(val.value, editedDoc);
        props.setNewDoc(editedDoc);
    }

    const closePopup = () => {
        props.setUploadPopupVisible(false);
    };

    const confirmUpload = () => {

        const url = process.env.REACT_APP_API_ENDPOINT+'/studies/'+props.study.id+'/documents/';
        const formData = new FormData();
        const config = {
            headers: {
                'content-type': 'multipart/form-data'
            },
            withCredentials: true

        }
        let editedDoc= JSON.parse(JSON.stringify(props.newDoc));


        editedDoc.name = editedDoc.name.trim();
        if (!editedDoc.name || !props.documentHasPermissions(editedDoc) || (Object.entries(props.uploadedFile).length === 0 && props.uploadedFile.constructor === Object)) {
            props.setPopupHasErrors(true);
            return false;
        }

        formData.append('file',props.uploadedFile);
        formData.append('document', JSON.stringify(props.newDoc));

        post(url, formData,config).then(function (response) {

           if (response.status === 200 && response.data) {

                props.setUploadedFile({});
                props.setUploadPopupVisible(false);

                props.setStudy(response.data);

           }


        });

    };

    const submitHandler = e => {
        e.preventDefault();
    };


    return (
        <React.Fragment>
            <div className="modal fade in">
                <div className="modal-header">
                    <h3>Upload new file to study</h3>
                </div>

                <div className="modal-body  no-overflow">
                    <form onSubmit={submitHandler}>
                        <div className="modal-form">
                            <div className="form-group">

                                {props.popupHasErrors && (
                                    <div className="modal-errors" style={{marginLeft: "15px"}}>All Fields are required!</div>
                                )}

                                <div className="modal-errors" style={{marginLeft: "15px"}}></div>
                                <div className="span5" style={{width: "100%"}}>
                                    <div className="row">
                                        <div className="label" style={{width: "136px"}}>
                                            <label className="required">Document <span className="required">*</span></label>
                                        </div>
                                        <div className="input" style={{float: "right", marginRight: "18px"}}>
                                            <input
                                                name="name"
                                                style={{width: "285px"}}
                                                type="text"
                                                onChange={(ev) => onChangeHandler(ev)}
                                            />
                                        </div>
                                    </div> {/* .row */}

                                    <div className="row">
                                        <div className="label" style={{width: "136px"}}>
                                            <label htmlFor="uploadDocumentForm_studyDocUser" className="required">Pick a User Group <span className="required">*</span></label>
                                        </div>
                                        <div className="input" style={{float: "right", marginRight: "15px"}}>
                                            <CorcSelect
                                                name="document-permission"
                                                id="document-permission"
                                                onChange={(ev) => onChangePermissionHandler(ev)}
                                                value={props.permissionOptions.filter(perm => perm.value === props.getDocumentPermission(props.newDoc))}
                                                options={props.permissionOptions}
                                                containerStyles={{width: "300px"}}
                                                isSearchable={false}
                                            />
                                        </div>
                                    </div> {/* .row */}

                                    <div style={{width: "100%", marginBottom: "15px", height: "15px", borderBottom: "1px solid #eee"}}></div>

                                    <div className="row">
                                        <div className="label" style={{width: "136px"}}>
                                            <label>Upload File</label>
                                        </div>
                                        <div className="input" style={{float: "right", marginRight: "68px"}}>
                                            <input name="file" type="file" onChange={(ev) => onChangeHandler(ev)} />
                                        </div>
                                    </div> {/* .row */}

                                </div> {/* .span5 */}
                            </div> {/* .form-group */}
                        </div>
                    </form>
                </div>

                <div className="modal-footer">
                    <button
                        onClick={confirmUpload}
                        className="btn small orange align-center right">Save
                    </button>
                    <button
                        onClick={closePopup}
                        className="btn small grey align-center right">Close
                    </button>
                </div> {/* .modal-footer */}
            </div>

            <div className="modal-backdrop fade in"></div>
        </React.Fragment>
    );
}

function DocumentDeletePopup(props) {
    const closePopup = () => {
        props.setDeletePopupVisible(false);
    };

    const confirmDelete = () => {

        props.deleteDocument(props.deleteDoc);
        props.setDeletePopupVisible(false);
    }

    return (
        <React.Fragment>
            <div className="modal fade in">
                <div className="modal-header">
                    <h3>Confirmation</h3>
                </div>

                <div className="modal-body">
                    <p>Do you really want to delete this document?</p>
                </div>

                <div className="modal-footer">
                    <button
                        onClick={confirmDelete}
                        className="btn small orange align-center right">Yes
                    </button>
                    <button
                        onClick={closePopup}
                        className="btn small grey align-center right">No
                    </button>
                </div>
            </div>

            <div className="modal-backdrop fade in"></div>
        </React.Fragment>
    );
}
