import React, {useEffect, useState}  from 'react';
import axios from 'axios';
import {Link} from 'react-router-dom';
import { CSVLink } from "react-csv";
import jsPDF from "jspdf";
import "jspdf-autotable";
import CorcSelect from 'components/utils/CorcSelect';
import {initDate} from 'components/utils/Helpers.js';

function Exams(props) {

    const [studyExam, setStudyExam] = useState({
        'id': '',
        'study': {'id': '', 'acronym': '', 'status': 'closed'},
        'exam_type': {'id' : '', 'uid': '', 'name': ''},
        'visits': []
    });

    const [initialStudyExam, setInitialStudyExam] = useState({});

    useEffect(function() {

        const getVisitsByExam = async () => {
            let count = props.overlayVisible;
            props.setOverlayVisible( (count) => count + 1);

            await axios.get(process.env.REACT_APP_API_ENDPOINT+'/studies/'+props.match.params.id+'/exams/'+props.match.params.exam+'/',
                { withCredentials: true, headers: {'Content-Type': 'application/json'}}).then(response => {

                let visits = [];
                response.data.visits.map(function(v) {
                    if (!v.archived) {

                        if (props.userAuth.user.role === 'grader') {
                            if (!v.missed_visit) {
                                visits.push(v);
                            }
                        }
                        else {
                            visits.push(v);
                        } 
                    }
                });

                response.data.visits = visits;

                setStudyExam(response.data);
                setInitialStudyExam(response.data);

                props.setOverlayVisible( (count) => count - 1);

            }, (error) => {
                props.setOverlayVisible( (count) => count - 1);
            });
        };

        props.setNavLinks([
            {title: 'Studies', url: '/studies/', icon: 'studies'},
            {title: 'Exams', url: props.location.pathname, icon: 'exam'}
        ]);

        localStorage.setItem('corc_users_filteredByStatus', "");
        localStorage.setItem('corc_study_exams_sortClinical', "");
        localStorage.setItem('corc_study_exams_sortPatient', "");
        localStorage.setItem('corc_study_exams_sortVisit', "");
        localStorage.setItem('corc_study_exams_sortDate', "");
        localStorage.setItem('corc_study_exams_sortStudyEye', "");
        localStorage.setItem('corc_study_exams_sortSubmitEye', "");
        localStorage.setItem('corc_study_exams_sortOperator', "");
        localStorage.setItem('corc_study_exams_sortStatus', "");

        getVisitsByExam();
    }, []);


    useEffect(function() {
        const dataCSV = [];

        studyExam.visits.map(function(v) {
            let me = {};

            me.clinical_site = v.patient.center.study_site_num;
            me.patient_number = v.patient.patient_num;
            me.visit_number = v.scheduled_visit ? v.scheduled_visit.name : ''

            me.exam_date = v.exam_date;

            if (v.patient.study_left_eye && v.patient_study_right_eye) {
                me.study_eye = 'Left & Right';
            }
            else if (v.patient.study_left_eye) {
                me.study_eye = 'Left';
            }
            else if (v.patient.patient_study_right_eye) {
                me.study_eye = 'Right';
            }

            if (v.left_eye_submit && v.right_eye_submit) {
                me.submit_eye = 'Left & Right';
            }
            else if (v.left_eye_submit) {
                me.submit_eye = 'Left';
            }
            else if (v.right_eye_submit) {
                me.submit_eye = 'Right';
            }

            me.operator = v.operator ? v.operator.username : '';
            me.status = v.exam_status;

            dataCSV.push(me);


            return false;

        });

        setDataToCSV(dataCSV);

    }, [studyExam]);

    const [dataToCSV, setDataToCSV] = useState([]);

    const headersCSV = [
        { label: "Clinical Site", key: "clinical_site" },
        { label: "Patient Number", key: "patient_number" },
        { label: "Visit Number", key: "visit_number" },
        { label: "Exam Date", key: "exam_date" },
        { label: "Study Eye(s)", key: "study_eye" },
        { label: "Submitted Eye(s)", key: "submit_eye" },
        { label: "Operator", key: "operator" },
        { label: "Status", key: "status" },
    ];

    const exportPDF = () => {

        const marginLeft = 40;
        const title = "";
        const doc = new jsPDF("landscape", "pt", "A4");

        doc.setFontSize(15);

        const headers = [["Clinical Site", "Patient Number", "Visit Number", "Exam Date", "Study Eye(s)", "Submitted Eye(s)", "Operator", "Status"]];

        const data = dataToCSV.map(i=> [i.clinical_site, i.patient_number, i.visit_number, i.exam_date, i.study_eye, i.submit_eye, i.operator, i.status]);

        let content = {
            startY: 50,
            head: headers,
            body: data
        };

        doc.text(title, marginLeft, 40);
        doc.autoTable(content);
        doc.save("visits_exams-export.pdf")
    }

    const filter = () => {

        let filteredVisits = initialStudyExam.visits;
        let filteredByStatus = localStorage.getItem('corc_users_filteredByStatus');

        if (filteredByStatus) {
            filteredVisits = filteredVisits.filter(v => v.exam_status === filteredByStatus);
        }

        setStudyExam({...studyExam, 'visits': filteredVisits});
    }


    return (
        <div className="home_header_container_class wide">
            <div className="content">
                <div className="breadcrumbs">
                    <div className="path">
                        <ul>
                            <li><span className="orange text-shadow">{studyExam.study.acronym.toUpperCase()}</span></li>
                            <li className="separator text-shadow">&gt;</li>
                            <li>{studyExam.exam_type.name} Exams</li>
                        </ul>
                    </div>

                    <div className="sub-menu">
                        <CSVLink className="btn normal grey right" data={dataToCSV} headers={headersCSV} filename={"visits_exams-export.csv"}>
                            <span className="icon icon-list report"></span>
                            Export to CSV
                        </CSVLink>
                        <button onClick={exportPDF} className="btn normal grey right">
                            <span className="icon icon-list report"></span>Export to PDF
                        </button>
                        <ul className="button-group right">
                            <li>
                                <ExamStatusDropdownFilter filter={filter}/>
                            </li>
                        </ul>

                    </div>
                </div> {/* .breadcrumbs */}

                <VisitList
                    studyExam={studyExam}
                    setStudyExam={setStudyExam}
                    params={props.match.params}
                />
            </div>
        </div>

    );
}

export default Exams;

function ExamStatusDropdownFilter(props) {

    const filterByStatus = val => {

        if (val === '-1') {
            localStorage.setItem('corc_users_filteredByStatus', "");
        }
        else {
            localStorage.setItem('corc_users_filteredByStatus', val);
        }

        props.filter();
    }

    const filterOptions = [
        {label: 'All Exams', value: '-1'},
        {label: 'Rejected Exams', value: 'rejected'},
        {label: 'Submitted Exams', value: 'submitted'},
        {label: 'Received Exams', value: 'received'},
    ];

     return (

        <CorcSelect
            id="profile-select"
            containerStyles={{width: "165px"}}
            name="profile"
            onChange={selectedOption => filterByStatus(selectedOption.value) }
            options={filterOptions}
            isSearchable={false}
            defaultValue={{label: 'All Exams', value: '-1'}}
        />
     );

}

function VisitList(props) {

    const toggleCenterSort = () => {

        const visits = JSON.parse(JSON.stringify(props.studyExam.visits));
        let sort = localStorage.getItem('corc_study_exams_sortClinical');

        if (sort === ">") {
            visits.sort((a, b) => (a.patient.center.study_site_num.toLowerCase() < b.patient.center.study_site_num.toLowerCase()) ? 1 : -1)
            localStorage.setItem('corc_study_exams_sortClinical', "<")
        }
        else {
            visits.sort((a, b) => (a.patient.center.study_site_num.toLowerCase() > b.patient.center.study_site_num.toLowerCase()) ? 1 : -1)
            localStorage.setItem('corc_study_exams_sortClinical', ">")
        }

        props.setStudyExam({...props.studyExam, visits: visits})
    }

    const toggleVisitSort = () => {

        const visits = JSON.parse(JSON.stringify(props.studyExam.visits));
        let sort = localStorage.getItem('corc_study_exams_sortVisit');

        if (sort === ">") {
            visits.sort((a, b) => (a.scheduled_visit.name.toLowerCase() < b.scheduled_visit.name.toLowerCase()) ? 1 : -1)
            localStorage.setItem('corc_study_exams_sortVisit', "<")
        }
        else {
            visits.sort((a, b) => (a.scheduled_visit.name.toLowerCase() > b.scheduled_visit.name.toLowerCase()) ? 1 : -1)
            localStorage.setItem('corc_study_exams_sortVisit', ">")
        }

        props.setStudyExam({...props.studyExam, visits: visits})
    }


    const toggleExamDateSort = () => {

        const visits = JSON.parse(JSON.stringify(props.studyExam.visits));
        let sort = localStorage.getItem('corc_study_exams_sortDate');

        if (sort === ">") {

            visits.sort(function(a, b) {
                a = a.exam_date ? initDate(a.exam_date) : new Date(a.exam_date);
                b = b.exam_date ? initDate(b.exam_date) : new Date(b.exam_date);

                return (a < b ? 1 : -1);

            });

            localStorage.setItem('corc_study_exams_sortDate', "<")
        }
        else {

            visits.sort(function(a, b) {
                a = a.exam_date ? initDate(a.exam_date) : new Date(a.exam_date);
                b = b.exam_date ? initDate(b.exam_date) : new Date(b.exam_date);

                return (a > b ? 1 : -1);

            });

            localStorage.setItem('corc_study_exams_sortDate', ">")
        }

        props.setStudyExam({...props.studyExam, visits: visits})
    }

    const togglePatientSort = () => {

        const visits = JSON.parse(JSON.stringify(props.studyExam.visits));
        let sort = localStorage.getItem('corc_study_exams_sortPatient');

        if (sort === ">") {
            visits.sort((a, b) => (a.patient.patient_num.toLowerCase() < b.patient.patient_num.toLowerCase()) ? 1 : -1)
            localStorage.setItem('corc_study_exams_sortPatient', "<")
        }
        else {
            visits.sort((a, b) => (a.patient.patient_num.toLowerCase() > b.patient.patient_num.toLowerCase()) ? 1 : -1)
            localStorage.setItem('corc_study_exams_sortPatient', ">")
        }

        props.setStudyExam({...props.studyExam, visits: visits})
    }

    const toggleOperatorSort = () => {

        const visits = JSON.parse(JSON.stringify(props.studyExam.visits));
        let sort = localStorage.getItem('corc_study_exams_sortOperator');

        if (sort === ">") {

            visits.sort((a, b) => {

                let userA, userB;
                if (!a.operator) {
                   userA = '';
                }
                else {
                   userA = a.operator.username.toLowerCase();
                }

                if (!b.operator) {
                   userB = '';
                }
                else {
                   userB = b.operator.username.toLowerCase();
                }

                return userA < userB ? 1 : -1;

            });
            localStorage.setItem('corc_study_exams_sortOperator', "<")
        }
        else {
            visits.sort((a, b) => {

                let userA, userB;
                if (!a.operator) {
                   userA = '';
                }
                else {
                   userA = a.operator.username.toLowerCase();
                }

                if (!b.operator) {
                   userB = '';
                }
                else {
                   userB = b.operator.username.toLowerCase();
                }

                return userA > userB ? 1 : -1;

            });

            localStorage.setItem('corc_study_exams_sortOperator', ">")
        }

        props.setStudyExam({...props.studyExam, visits: visits})
    }

    const toggleStatusSort = () => {

        const visits = JSON.parse(JSON.stringify(props.studyExam.visits));
        let sort = localStorage.getItem('corc_study_exams_sortStatus');

        if (sort === ">") {
            visits.sort((a, b) => (a.exam_status.toLowerCase() < b.exam_status.toLowerCase()) ? 1 : -1)
            localStorage.setItem('corc_study_exams_sortStatus', "<")
        }
        else {
            visits.sort((a, b) => (a.exam_status.toLowerCase() > b.exam_status.toLowerCase()) ? 1 : -1)
            localStorage.setItem('corc_study_exams_sortStatus', ">")
        }

        props.setStudyExam({...props.studyExam, visits: visits})
    }

    const toggleStudyEyeSort = () => {

        const visits = JSON.parse(JSON.stringify(props.studyExam.visits));
        let sort = localStorage.getItem('corc_study_exams_sortStudyEye');

        if (sort === ">") {
            visits.sort((a, b) => (a.patient.study_left_eye === b.patient.study_left_eye) ? 0 : a.patient.study_right_eye ? 1 : -1);
            localStorage.setItem('corc_study_exams_sortStudyEye', "<")
        }
        else {
            visits.sort((a, b) => (a.patient.study_left_eye === b.patient.study_left_eye) ? 0 : a.patient.study_right_eye ? -1 : 1);
            localStorage.setItem('corc_study_exams_sortStudyEye', ">")
        }

        props.setStudyExam({...props.studyExam, visits: visits})
    }

    const toggleSubmitEyeSort = () => {

        const visits = JSON.parse(JSON.stringify(props.studyExam.visits));
        let sort = localStorage.getItem('corc_study_exams_sortSubmitEye');

        if (sort === ">") {
            visits.sort((a, b) => (a.left_eye_submit === b.left_eye_submit) ? 0 : a.right_eye_submit ? 1 : -1);
            localStorage.setItem('corc_study_exams_sortSubmitEye', "<")
        }
        else {
            visits.sort((a, b) => (a.left_eye_submit === b.left_eye_submit) ? 0 : a.right_eye_submit ? -1 : 1);
            localStorage.setItem('corc_study_exams_sortSubmitEye', ">")
        }

        props.setStudyExam({...props.studyExam, visits: visits})
    }

    return (

        <div className="visits-list list">
            <div className="list-head">
                <table>
                    <tbody>
                        <tr>
                            <th width="10%">
                                <button onClick={toggleCenterSort}><b>Clinical site</b></button>
                            </th>
                            <th width="11%">
                                <button onClick={togglePatientSort}>Patients Number</button>
                            </th>
                            <th width="13%">
                                <button onClick={toggleVisitSort}>Visit Number</button>
                            </th>
                            <th width="9%">
                                <button onClick={toggleExamDateSort}>Exam Date</button>
                            </th>
                            <th width="9%">
                                <button onClick={toggleStudyEyeSort}>Study eye(s)</button>
                            </th>
                            <th width="10%">
                                <button onClick={toggleSubmitEyeSort}>Submitted eye(s)</button>
                            </th>

                            <th width="14%">
                                <button onClick={toggleOperatorSort}>Operator</button>
                            </th>
                            <th colSpan="2">
                                <button onClick={toggleStatusSort}>Status</button>
                            </th>
                        </tr>
                    </tbody>
                </table>
            </div>
            <div className="list-body">
                <table id="list-table">
                    <tbody className="list-visits">

                        {props.studyExam.visits && props.studyExam.visits.map((visit, i) => (
                            <VisitItem
                                key={i}
                                visit={visit}
                                params={props.params}
                            />
                        ))}


                    </tbody>
                </table>
            </div>
        </div>
    );
}

function VisitItem(props) {

    const getStatusDisplay = status => {

        if (status === 'rejected') {
            return 'Rejected';
        }
        else if (status === 'received') {
            return 'Received';
        }
        else if (status === 'submitted') {
            return 'Submitted'
        }

        return '';

    }

    return (
        <tr className="visit">
            <td width="10%">{props.visit.patient.center.study_site_num}</td>
            <td width="11%">{props.visit.patient.patient_num}</td>
            <td width="13%">{props.visit.scheduled_visit ? props.visit.scheduled_visit.name : ''}</td>
            <td width="9%">{props.visit.exam_date}</td>
            <td width="9%">
                <span className={"icon min-eye "+( props.visit.patient.study_right_eye ? "" : "enabled") }></span>
                <span className={"icon min-eye "+( props.visit.patient.study_left_eye ? "" : "enabled") }></span>
            </td>
            <td width="10%">
                <span className={"icon min-eye "+( props.visit.right_eye_submit ? "" : "enabled") }></span>
                <span className={"icon min-eye "+( props.visit.left_eye_submit ? "" : "enabled") }></span>
            </td>
            <td width="14%">{props.visit.operator ? props.visit.operator.username : '-'}</td>
            <td width="14%">{getStatusDisplay(props.visit.exam_status)}</td>
            <td className="btn-list" width="10%">
                <ul>
                    <li>
                        <Link
                            to={"/studies/"+props.params.id+'/exams/'+props.params.exam+'/grade/'+props.visit.id}
                            className="btn small grey"
                        >
                            &nbsp;&nbsp;<span className="icon-list visits-small"></span>View
                        </Link>
                    </li>
                </ul>
            </td>
        </tr>
    );
}
