import React from "react";

import NavItem from "./NavItem";

function Nav(props) {
    
    const roles = {     
         'tech': 'Technician',
         'admin': 'Admin',
         'grader': 'Grader',
         'monitor': 'Monitor',
         'coordinator': 'Coordinator'           
    }

    const centers = {     
         'tech': props.userAuth.user.center ? props.userAuth.user.center.acronym : '',
         'admin': '',
         'grader': '',
         'monitor': '',
         'coordinator': ''           
    }

    return (
        <div className="home_header_container_class">
            <div className="menu-user">
                <div className="menu">
                    <ul id="new-menu">
                        {props.navLinks.map((link, i) =>
                        <NavItem
                            url={link.url}
                            icon={link.icon}
                            title={link.title}
                            key={i}
                        />
                        )}
                    </ul>
                </div> {/* .menu */}
                <div className="title">
                    <div className="sup">
                        <span className="text-shadow">{roles[props.userAuth.user.role]}</span>
                    </div> {/* .sup */}
                    <span>{centers[props.userAuth.user.role]}</span>
                </div> {/* .title */}
            </div> {/* .menu-user */}
        </div>
    )
}

export default Nav;
