import React, {useEffect, useState} from 'react';
import {Link} from 'react-router-dom';
import axios from 'axios';

function StudyVars(props) {

    const [isAdd, setIsAdd] = useState(false);
    const [study, setStudy] = useState({
        'id': 0,
        'status': '',
        'acronym': '',
        'full_name': '',
        'start_date': '',
        'end_date': '',
        'description': '',
        'select_patient_eye': false,
        'patient_details': '',
        'patient_card_vars': [],
        'protocol_num': '',
        'corc_num': '',
        'exams': [],
        'scheduled_visits': [],
        'notifications': [],
        'technician_form_vars': []
    });
    //const [examTypes, setExamTypes] = useState([])
    const [notificationTypes, setNotificationTypes] = useState([])

    const handleSubmit = e => {
        e.preventDefault();
        submitStudy();
    }

    const submitStudy = async(e) => {

        //const changesToSave = ['id', 'acronym', 'corc_num', 'description', 'end_date', 'exams', 'scheduled_visits', 'start_date', 'full_name', 'patient_details', 'protocol_num', 'select_patient_eye', 'notifications', 'exams', 'patient_card_vars'];
        const changesToSave = ['id', 'notifications', 'exams', 'patient_card_vars'];

        const changedStudy = JSON.parse(JSON.stringify(study));
        const errMsg = "Sorry, something went wrong when saving this study. Please contact your IT helpdesk.";

        let i;
        for (i in changedStudy) {
            if (changedStudy.hasOwnProperty(i) && !changesToSave.includes(i)) {
                delete changedStudy[i];
            }
        }

        let count = props.overlayVisible;
        props.setOverlayVisible( (count) => count + 1);

        await axios.patch(process.env.REACT_APP_API_ENDPOINT+'/studies/'+props.match.params.id+'/', {...changedStudy}, { withCredentials: true})
            .then((response) => {
            props.setOverlayVisible( (count) => count - 1);
            if (response.status === 200) {
                props.history.push("/studies");
            }
            else {
                alert(errMsg);
            }

        }, (error) => {
            alert(errMsg);
            props.setOverlayVisible( (count) => count - 1);
        });

    };


    /*
     *  Patient Vars
     * */
    const [patientVars, setPatientVars] = useState([]);
    const [tmpPatientVars, setTmpPatientVars] = useState([]);
    const [currentExam, setCurrentExam] = useState({});

    /*
     *  Popups
     * */
    const [patientVarsPopupVisible, setPatientVarsPopupVisible] = useState(false);
    const [examVarsPopupVisible, setExamVarsPopupVisible] = useState(false);

    const [allTechFormVarsSelected, setAllTechFormVarsSelected] = useState(false);

    useEffect(function() {

        const getNotificationTypes = async () => {
            let count = props.overlayVisible;
            props.setOverlayVisible( (count) => count + 1);

            await axios.get(process.env.REACT_APP_API_ENDPOINT+'/settings/notificationtypes/', { withCredentials: true}).then(response => {

                props.setOverlayVisible( (count) => count - 1);

                if (response.status === 200) {
                    setNotificationTypes(response.data);
                }

            }, (error) => {

                props.setOverlayVisible( (count) => count - 1);
                if (error.response.status === 401) {
                    props.resetAuth();
                    props.history.push('/login');
                }
            });

        }

        const getPatientVars = async () => {
            let count = props.overlayVisible;
            props.setOverlayVisible( (count) => count + 1);

            await axios.get(process.env.REACT_APP_API_ENDPOINT+'/settings/patientcardvars/', { withCredentials: true}).then(response => {

                props.setOverlayVisible( (count) => count - 1);
                if (response.status === 200) {
                    setPatientVars(response.data);
                }

            }, (error) => {

                props.setOverlayVisible( (count) => count - 1);
                if (error.response.status === 401) {
                    props.resetAuth();
                    props.history.push('/login');
                }
            });

        }

        const getStudy = async () => {
            let count = props.overlayVisible;
            props.setOverlayVisible( (count) => count + 1);

            if (props.match.params.id) {
                await axios.get(process.env.REACT_APP_API_ENDPOINT+'/studies/'+props.match.params.id+'/summary/', { withCredentials: true}).then(response => {


                props.setOverlayVisible( (count) => count - 1);
                if (response.status === 200) {

                        // if we user is a monitor, we need to "close" this study
                        if (props.userAuth.user.role === 'monitor') {
                            response.data.status = 'closed';
                        }

                        setStudy(response.data);
                    }

                }, (error) => {

                    props.setOverlayVisible( (count) => count - 1);
                    if (error.response.status === 401) {
                        props.resetAuth();
                        props.history.push('/login');
                    }
                });
            }
        }

        if (props.userAuth.user.role !== 'admin') {
            props.history.push('/studies');
            return;
        }

        if (props.location.state && props.location.state.hasOwnProperty("isAdd") && props.location.state.isAdd) {
            setIsAdd(true);
            props.history.replace({
                pathname: props.location.pathname,
                state: {}
            });
        }


        getStudy();
        //getExamTypes();
        getNotificationTypes();
        getPatientVars();
        props.setNavLinks([{title: 'Studies', url: '/studies/', icon: 'studies'}, {title: 'Create Study', url: '/studies/'+props.match.params.id, icon: 'study-details'}]);
    }, []);

    return (
        <div className="home_header_container_class">
            <div className="content">
                <div className="main-form">
                    <form onSubmit={handleSubmit} action="">
                        <div className="overview">
                            <h2 className="text-shadow">Notifications</h2>
                        </div>
                        <StudyVarsNotifications
                            study={study}
                            setStudy={setStudy}
                            notificationTypes={notificationTypes}
                            setNotificationTypes={setNotificationTypes}
                        />
                        <br />

                        <div className="form-group">
                            <StudyVarsPatientSection
                                key={1}
                                setPatientVarsPopupVisible={setPatientVarsPopupVisible}
                                study={study}
                                setStudy={study}
                                patientVars={patientVars}
                                setTmpPatientVars={setTmpPatientVars}
                            />
                            <StudyVarsTechnicianSection
                                key={2}
                                study={study}
                                setCurrentExam={setCurrentExam}
                                setExamVarsPopupVisible={setExamVarsPopupVisible}
                            />
                        </div>

                        {study.status === "open" ? (
                            <StudyFormControlsOpen isAdd={props.isAdd} submitStudy={props.submitStudy}/>
                        ) : (
                            <StudyFormControlsClosed study={study}/>
                        )}

                    </form>
                </div>
                { patientVarsPopupVisible && (
                <StudyVarsPatientPopup
                    setPatientVarsPopupVisible={setPatientVarsPopupVisible}
                    patientVars={patientVars}
                    setPatientVars={setPatientVars}
                    study={study}
                    setStudy={setStudy}
                    tmpPatientVars={tmpPatientVars}
                    setTmpPatientVars={setTmpPatientVars}
                />
                )}

                { examVarsPopupVisible && (
                <StudyVarsExamPopup
                    setExamVarsPopupVisible={setExamVarsPopupVisible}
                    currentExam={currentExam}
                    setCurrentExam={setCurrentExam}
                    study={study}
                    setStudy={setStudy}
                    allTechFormVarsSelected={allTechFormVarsSelected}
                    setAllTechFormVarsSelected={setAllTechFormVarsSelected}
                />
                )}
            </div> {/* .content */}
        </div>
    );
}

export default StudyVars;

function StudyFormControlsOpen(props) {

 return (

    <div className="form-actions" style={{float: "right"}}>
        {props.isAdd === true ? (
            <input type="submit" onClick={props.submitStudy} name="" className="btn normal orange align-center left" value="Save and Finish" />
        ) : (
            <React.Fragment>
            <Link to="/studies" className="btn normal grey align-center left">Cancel</Link>
            <input type="submit" onClick={props.submitStudy} name="" className="btn normal orange align-center left" value="Save" />
            </React.Fragment>
        )}

    </div>
 )
}

function StudyFormControlsClosed(props) {
 return (

    <div className="form-actions" style={{float: "right"}}>
        <Link to={"/studies/" + props.study.id} className="btn normal grey align-center left">Back</Link>
    </div>
)}


function StudyVarsExamPopup(props) {

    const examVarsClickHandler = (ev, formVar, type) => {
        let editedExam = JSON.parse(JSON.stringify(props.currentExam));

        if (props.study.status !== "open") {
            return;
        }


        let found = false;
        for(let i=0, len=editedExam.technician_form_vars.length; i<=len-1;++i) {
            let studyFormVar = editedExam.technician_form_vars[i];
            if(studyFormVar.technician_form_var.id === formVar.id) {
                found = true;
                if(type === 'var') {
                    editedExam.technician_form_vars.splice(i, 1);
                }
                else if(type === 'notification') {
                    studyFormVar.send_email = !studyFormVar.send_email;
                }
                break;
            }
        }

        if(!found) {
            editedExam.technician_form_vars.push({technician_form_var: {id: formVar.id}, send_email: (type==='notification') });
        }

        props.setCurrentExam(editedExam);
    };

    const saveExamVarsPopup = () => {
        let editedExams = JSON.parse(JSON.stringify(props.study.exams));
        let currExam = JSON.parse(JSON.stringify(props.currentExam));
        for(let i=0, len=editedExams.length; i<=len-1; ++i) {
            if(editedExams[i].id === currExam.id) {
                editedExams.splice(i, 1, currExam);
                break;
            }
        }

        props.setStudy({...props.study, exams: editedExams});
        props.setCurrentExam({});
        props.setExamVarsPopupVisible(false);
    };

    const closeExamVarsPopup = () => {
        props.setExamVarsPopupVisible(false);
        props.setCurrentExam({});
    };

    const isCheckedVar = (exam, formVar, type) => {

        let isChecked = false;
        exam.technician_form_vars.map(studyFormVar => {
            if(type === 'var' && studyFormVar.technician_form_var.id === formVar.id) {
                isChecked = true;
            }
            else if(type === 'notification' && studyFormVar.technician_form_var.id === formVar.id && studyFormVar.send_email) {
                isChecked = true;
            }
            return false;
        });

        return isChecked;
    };

    const loadVars = (type) => {
        let vars = [];

        props.currentExam && props.currentExam.exam_type.technician_form_vars.map( (formVar, i) => {
            if(formVar.group === type) {
                vars.push([
                    <li key={i}>
                        <button
                            className={"btn xsmall grey left check-var" + ( isCheckedVar(props.currentExam, formVar, 'var') ? "" : " unchecked")}
                            onClick={(ev) => examVarsClickHandler(ev, formVar, 'var')}
                        >
                            <span className="icon-list report" style={{marginLeft: "0"}}></span>
                        </button>

                        <button
                            className={"btn xsmall grey left check-var" + ( isCheckedVar(props.currentExam, formVar, 'notification') ? "" : " unchecked")}
                            onClick={(ev) => examVarsClickHandler(ev, formVar, 'notification')}
                        >
                            <span className="icon-list notification" style={{marginLeft: "0"}}></span>
                        </button>
                        <span> {formVar.name}</span>
                    </li>
                ]);
            }
            return false;
        });

        return vars;
    };

    const selectAllTechFormVars = selectAll => {
        let editedExam = JSON.parse(JSON.stringify(props.currentExam));

        editedExam.technician_form_vars = []
        if(selectAll) {
            editedExam && editedExam.exam_type.technician_form_vars.forEach( (formVar, i) => {
                editedExam.technician_form_vars.push({technician_form_var: {id: formVar.id}, send_email: true });
            });
        }

        props.setCurrentExam(editedExam);
        props.setAllTechFormVarsSelected(selectAll);
    };

    const isAllSelected = () => {
        if(props.currentExam) {
            let found = props.currentExam.technician_form_vars.find(f => f.technician_form_var.send_email === false);
            if(found) {
                props.setAllTechFormVarsSelected(false);
                return true;
            }

            if(props.currentExam.technician_form_vars.length === props.currentExam.exam_type.technician_form_vars.length) {
                props.setAllTechFormVarsSelected(true);
                return true;
            }

            props.setAllTechFormVarsSelected(false);
            return true;
        }
    };
    isAllSelected();

    return (
        <React.Fragment>
        <div className="modal fade in" style={{width: "943px", left: "35.2%"}}>
            <div className="modal-header">
                <h3>
                    Technician submissions forms: Customize {props.currentExam.exam_type.name} 
                    <button 
                        className="btn grey small" 
                        style={{float: "right"}}
                        onClick={() => selectAllTechFormVars(!props.allTechFormVarsSelected)}
                    >{props.allTechFormVarsSelected ? 'Unselect all' : 'Select all'}</button>
                </h3>
                
            </div> {/* .modal-header */}

            <div className="modal-body">
                <ul className="customize-vars">
                    <li className="title">General Vars</li>
                    {loadVars('general')}
                </ul>

                <ul className="customize-vars">
                    <li className="title">Other Vars</li>
                    {loadVars('other')}
                </ul>
            </div>

            <div className="modal-footer">
                {props.study.status === "open" && (
                <button
                    onClick={saveExamVarsPopup}
                    className="btn orange align-center right normal"
                >Save and Close
                </button>
                )}
                <button
                    onClick={closeExamVarsPopup}
                    className="btn grey align-center right normal"
                >Cancel and Close
                </button>
            </div>
        </div>

        <div className="modal-backdrop fade in"></div>
        </React.Fragment>

    );
}

function StudyVarsTechnicianSection(props) {

    const showExamVarsPopup = (e, exam) => {
        e.preventDefault();
        props.setCurrentExam(JSON.parse(JSON.stringify(exam)));
        props.setExamVarsPopupVisible(true);
    }


    return (
        <div className="study-vars-group">
            <h2 className="text-shadow">Technicians submissions forms:</h2>
            <br />
            {props.study.exams && props.study.exams.map( (exam, i) => (
            <button
                key={i}
                className="btn large grey align-center left customize-vars"
                onClick={(e) => showExamVarsPopup(e, exam)}
            >{exam.exam_type.name}
            </button>
            ))}
        </div>
    );
}

function StudyVarsPatientSection(props) {

    const showPatientVarsPopup = (e) => {
        e.preventDefault();
        let tPVars = props.study.patient_card_vars.slice();

        props.setTmpPatientVars(tPVars);
        props.setPatientVarsPopupVisible(true);
    }

    return (
        <div className="study-vars-group">
            <h2 className="text-shadow">
                Patient Card Information:
            </h2>
            <br />
            <button
                className="btn large grey align-center left customize-vars"
                onClick={(e) => showPatientVarsPopup(e)}
            >Customize Patient Card
            </button>
        </div>
    );
}

function StudyVarsPatientPopup(props) {

    const isPatientCardInStudy = (pVar) => {

        let item = props.tmpPatientVars.find((p) => {
            return p.id === pVar.id;
        });

        if (item !== undefined) {
            return true;
        }

        return false;
    };

    const patientVarClickHandler = (pVar) => {
        let found = false;
        let editPVars = props.tmpPatientVars.slice();

        if (props.study.status !== "open") {
            return;
        }


        for (var i = 0; i < editPVars.length; ++i) {

            // if we find the patient card var, lets remove it
            if (editPVars[i].id === pVar.id) {
                editPVars.splice(i,1);
                found = true;
                break;
            }
        }

        // lets add the patient card var
        if (!found) {
            editPVars.push(pVar);
        }

        props.setTmpPatientVars(editPVars);
    };

    const savePatientVarsPopup = () => {

        let newPVars = props.tmpPatientVars.slice();
        props.setTmpPatientVars([]);
        props.setStudy({...props.study, patient_card_vars: newPVars});


        props.setPatientVarsPopupVisible(false);
    };

    const closePatientVarsPopup = () => {
        props.setTmpPatientVars([]);
        props.setPatientVarsPopupVisible(false);
    };

    return (
        <React.Fragment>
        <div className="modal fade in" style={{width: "943px", left: "35.2%"}}>
            <div className="modal-header">
                <h3>Patient Card Information: Customize Patient Card</h3>
            </div> {/* .modal-header */}

            <div className="modal-body checkbox-group group-page">
                <ul className="customize-vars">
                    <li className="title">General Vars</li>
                    {props.patientVars && props.patientVars.map((pVar, i) => pVar.group === 'general' && (
                    <li key={i}>
                        <button
                            className={"btn xsmall grey left" + (isPatientCardInStudy(pVar) ? "" : " unchecked")}
                            onClick={() => patientVarClickHandler(pVar)}
                        >
                            <span className="icon-list report" style={{marginLeft: "0"}}></span>
                        </button>
                        <span>{pVar.name}</span>
                    </li>
                    ))}
                </ul>
                <ul className="customize-vars">
                    <li className="title">Other Vars</li>
                    {props.patientVars && props.patientVars.map((pVar, i) => pVar.group === 'other' && (
                    <li key={i}>
                        <button
                            className={"btn xsmall grey left" + (isPatientCardInStudy(pVar) ? "" : " unchecked")}
                            onClick={() => patientVarClickHandler(pVar)}
                        >
                            <span className="icon-list report" style={{marginLeft: "0"}}></span>
                        </button>
                        <span>{pVar.name}</span>
                    </li>
                    ))}
                </ul>
            </div> {/* .modal-body */}

            <div className="modal-footer">
                {props.study.status === "open" && (
                <button
                    onClick={savePatientVarsPopup}
                    className="btn orange align-center right normal"
                >Save and Close
                </button>
                )}
                <button
                    onClick={closePatientVarsPopup}
                    className="btn grey align-center right normal"
                >Cancel and Close
                </button>
            </div>
        </div>

        <div className="modal-backdrop fade in"></div>
        </React.Fragment>
    );
}

function StudyVarsNotifications(props) {

    const isNotificationInStudy = (notification) => {

      let not = props.study.notifications.find((not) =>{
          return not.id === notification.id;
      });

      if (not !== undefined) {
          return true;
      }

      return false;

    };

    const onNotificationChangeHandler = (ev, notification) => {

        let isChecked = ev.target.checked, notifications = props.study.notifications.slice();

        if (isChecked) {
            notifications.push(notification);
        }
        else {
            notifications = notifications.filter((not) =>{
              return not.id !== notification.id;
            })
        }

        props.setStudy({...props.study, notifications:notifications});

    };

    return(
        <div className="form-group checkbox-group group-page">
            <div className="row notifications-group" style={{paddingTop: "4px"}}>

            {props.notificationTypes.map((notification, i) => (
                <div className="checkbox" key={i}>
                    <input
                        value={notification.id}
                        id={"notifications_"+notification.id}
                        name="notifications"
                        type="checkbox"
                        checked={isNotificationInStudy(notification)}
                        onChange={(e) => onNotificationChangeHandler(e, notification)}
                        disabled={props.study.status === "open" ? "" : "disabled"}
                    />
                    <label htmlFor={"notifications_"+notification.id}>{notification.name}</label>
                </div>
            ))}

            </div>
        </div>
    );
}

