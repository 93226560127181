import React, {useEffect, useState} from 'react';
import {Link} from 'react-router-dom';
import ReactHtmlParser from 'react-html-parser';
import axios from 'axios';

function Homepage (props) {

    const [info, setInfo] = useState({
        name: '',
        address: '',
        postcode: '',
        phone: '',
        fax: '',
        email: '',
        description: '',
        informative_panel: '',
        picture: {'name': '', 'url': ''},
        studies: [],
        maintenance_mode: false,
        maintenance_message: '',
    });

    useEffect(function() {

            const getHomepage = async () => {

                await axios.get(process.env.REACT_APP_API_ENDPOINT+'/settings/homepage/', {'withCredentials': false}
                ).then(response => {
                    if (response.status === 200) {
                        if (response.data.picture) {
                            setInfo(response.data);
                        }
                        else {
                            setInfo({...response.data, 'picture': {'name': '', 'url': ''}});
                        }
                    }

                }, (error) => {
                    if (error.response && error.response.status === 401) {
                        props.resetAuth();
                    }
                });
            }

        // are we logged in?
        if (props.userAuth && props.userAuth.isAuth) {
            props.history.push('/studies');
            return;
        }


        getHomepage();
    }, []);

    return (
        <React.Fragment>
        {info.maintenance_mode && (
        <HomepageMaintenance
            info={info}
        />
        )}
        <HomepageLogin
            maintenanceMode={info.maintenance_mode}
            setUserAuth={props.setUserAuth}
            userAuth={props.userAuth}
        />
        <div id="home_main_container" className="home_main_container_class">
            <HomepageNews
                info={info}
            />
            <HomepageContacts
                info={info}
            />
            <HomepageStudies
                info={info}
            />
        </div>
        </React.Fragment>
    );
}

export default Homepage;

function HomepageLogin(props) {

    const [loginLock, setLoginLock] = useState(false);
    const [loginDetails, setLoginDetails] = useState({
        'username': '',
        'password': ''
    });
    const [loginError, setLoginError] = useState('')

    const inputHandler = e => {
        const value = e.target.value;
        const name = e.target.name;

        setLoginDetails({...loginDetails, [name]: value})
    };

    const handleSubmit = async(e) => {
        e.preventDefault();

        if (loginLock) {
            return false;
        }
        setLoginLock(true);

        await axios.post(process.env.REACT_APP_API_ENDPOINT+'/auth/', {...loginDetails})
            .then((response) => {
            
            if (!response || !response.data) {
                setLoginError('Incorrect username or password.');
            }
            else {
                if (response.status === 200 && response.data.hasOwnProperty('token') && response.data.hasOwnProperty('user')) {
                    props.setUserAuth({'isAuth': true, 'token': '', 'user': response.data.user});
                    localStorage.setItem('corc_auth', JSON.stringify({'isAuth': true, 'token': '', 'user': response.data.user}));
                }
                else if (response.data.hasOwnProperty('error')) {
                    setLoginError(response.data.error);
                }
                else {
                    setLoginError('Incorrect username or password.');
                }                
            }
                
            setLoginLock(false);
        }, (error) => {
            setLoginLock(false);
            setLoginError('Incorrect username or password.');
        });


        return false;
      }

    return (
        <div id="home_header_container" className="home_header_container_class home">
            <div id="home_header_middle_container">
                <img alt="logo" id="logo_home" src={require("images/logo_home.png")} />

                <form onSubmit={handleSubmit} id="input_form" method="post">
                    <div id="form_div">
                        <div>
                            <input
                                id="login_username"
                                className="text_box_class"
                                name="username"
                                type="text"
                                placeholder="Username"
                                autoComplete="off"
                                style={{cursor: "auto"}}
                                onChange={inputHandler}
                                value={loginDetails.username}
                            />
                            <input
                                id="login_password"
                                className="text_box_class"
                                name="password"
                                type="password"
                                placeholder="Password"
                                autoComplete="off"
                                onChange={inputHandler}
                                value={loginDetails.password}
                            />
                        </div>
                        <Link
                            id="recover_password"
                            style={{marginTop: "5px"}}
                            to="/users/reset/"
                        >Recover Password
                        </Link>
                        <span id="login_error">{loginError}</span>
                    </div>

                    <input
                        className="submit_button_class"
                        id="login_btn"
                        type="submit"
                        value="Login"
                    />
                </form>
            </div>
        </div>
    );
}

function HomepageNews(props) {
    return (
        <div id="home_image" className="home_image_cell">
            <img alt="" src={props.info.picture.url} />
            <div className="text-home-page">
                { ReactHtmlParser (props.info.description) }
            </div>
        </div>

    );
}

function HomepageContacts(props) {
    return (
        <div className="home_contacts">
            <ul>
                <li>{props.info.name}</li>
                <li>{props.info.address}</li>
                <li>{props.info.postcode}</li>
                <li>Tel: {props.info.phone}</li>
                <li>Fax: {props.info.fax}</li>
                <li>Email: {props.info.email}</li>
                <li>URL: <a href="http://www.aibili.pt/corc_about.php" rel="noopener noreferrer" target="_blank">http://www.aibili.pt/corc_about.php</a></li>
            </ul>

            <div className="painel_information">{ReactHtmlParser(props.info.informative_panel)}</div>
        </div>
    );
}

function HomepageStudies(props) {
    return (
        <div className="home_studies">
            {props.info.studies && props.info.studies.map((study, i) => study.is_visible && (
                <HomepageStudyItem
                    key={i}
                    study={study}
                />
            ))}
        </div>
    );
}

function HomepageStudyItem(props) {
    return (
        <div className="home_study">
            <div className="title_study">{props.study.name}</div>
            <div className="date_study">{props.study.date}</div>
            <div className="description_study">{props.study.description} <a href={props.study.link} rel="noopener noreferrer" target="_blank" className="readmore">[read more]</a></div>
        </div>
    );
}

function HomepageMaintenance(props) {
    return (
        <div className="maintenance-message-wrapper">
            <div className="maintenance-message">
                {props.info.maintenance_message}
            </div>
        </div>
    );
}
