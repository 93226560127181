import React, {useState, useRef, useEffect} from "react";
import axios from 'axios';
import {Link} from "react-router-dom";

function Header(props) {

    const [menuPopupVisible, setMenuPopupVisible] = useState(false);
    const wrapperRef = useRef(null);

    useEffect(function() {
        function handleClickOutside(event) {
            if (wrapperRef.current && !wrapperRef.current.contains(event.target)) {
                setMenuPopupVisible(false);
            }
        }

        // Bind the event listener
        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            // Unbind the event listener on clean up
            document.removeEventListener("mousedown", handleClickOutside);
        };

    }, [wrapperRef]);

    const toggleMenuPopup = () => {

        if (menuPopupVisible) {
            setMenuPopupVisible(false);
        }
        else {
            setMenuPopupVisible(true);
        }
    };

    const logoutUser = (e) => {

        e.preventDefault();
        toggleMenuPopup();

        axios.post(process.env.REACT_APP_API_ENDPOINT+'/users/'+props.userAuth.user.id+'/logout/', false, { withCredentials: true});

        props.setUserAuth({isAuth: false, user: {}, token: ''});
        localStorage.removeItem('corc_auth');

        return false;
    }

    return (
        <div className="header-bar">
            <div className="home_header_container_class">
                <div className="logo">
                    <img alt="logo CORC" src={require("../images/corc_white.png")}/>
                </div> {/* .logo */}
                <div className="user">
                    <div ref={wrapperRef} className="user-options">
                        <button className="options_btn" onClick={toggleMenuPopup}>&nbsp;</button>
                        {menuPopupVisible && (
                        <div className="options">
                            <ul>
                                <li key="profile"><Link to={"/users/"+props.userAuth.user.id} onClick={toggleMenuPopup}>Edit Profile</Link></li>
                                <li key="studies"><Link onClick={toggleMenuPopup} to={{
                                    pathname: "/studies",
                                    state: {}
                                    }}>Main Page</Link>
                                </li>

                                { props.userAuth.user.role === 'grader' && (

                                    [
                                    <li key="diagnostics"><Link onClick={toggleMenuPopup} to={{
                                        pathname: "/diagnostics",
                                        state: {},
                                        }}>Diagnose Errors</Link>
                                    </li>,

                                    <li key="certificationbrowser"><Link onClick={toggleMenuPopup} to={{
                                        pathname: "/certificationbrowser",
                                        state: {},
                                        }}>Certifications Browser</Link>
                                    </li>
                                    ]

                                )}

                                { props.userAuth.user.role === 'admin' && (
                                    [
                                    <li key="diagnostics"><Link onClick={toggleMenuPopup} to={{
                                        pathname: "/diagnostics",
                                        state: {},
                                        }}>Diagnose Errors</Link>
                                    </li>,
                                    <li key="homepage"><Link onClick={toggleMenuPopup} to={{
                                        pathname: "/settings/homepage",
                                        state: {},
                                        }}>Edit Homepage</Link>
                                    </li>,
                                    <li key="settings"><Link onClick={toggleMenuPopup} to={{
                                        pathname: "/settings",
                                        state: {},
                                        }}>Settings</Link>
                                    </li>,
                                    <li key="users"><Link onClick={toggleMenuPopup} to={{
                                        pathname: "/users",
                                        state: {},
                                        }}>Manage Users</Link>
                                    </li>,
                                    <li key="centers"><Link onClick={toggleMenuPopup} to={{
                                        pathname: "/centers",
                                        state: {}
                                        }}>Manage Centers</Link>
                                    </li>,
                                    <li key="logs"><Link onClick={toggleMenuPopup} to={{
                                        pathname: "/logs",
                                        state: {}
                                        }}>View Logs</Link>
                                    </li>,
                                    <li key="filebrowser"><Link onClick={toggleMenuPopup} to={{
                                        pathname: "/filebrowser",
                                        state: {},
                                        }}>File Browser</Link>
                                    </li>,
                                    <li key="certificationbrowser"><Link onClick={toggleMenuPopup} to={{
                                        pathname: "/certificationbrowser",
                                        state: {},
                                        }}>Certifications Browser</Link>
                                    </li>,

                                    //<li key="import_study"><Link to="/Archive/index/import" onClick={toggleMenuPopup}>Import Study</Link></li>,
                                    ]
                                )}

                                <li key="logout"><Link to="#" onClick={logoutUser}>Logout</Link></li>
                            </ul>
                        </div>
                        )}
                    </div> {/* .user-options */}
                    <div className="username">
                        <span>{props.userAuth.user.username}</span>
                    </div> {/* .username */}
                </div> {/* .user */}
            </div> {/* .home_header_container_class */}
        </div>
    );

}

export default Header;
