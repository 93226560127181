import React, {useEffect, useState}  from 'react';
import axios, {post} from 'axios';
import {useDropzone} from 'react-dropzone'
import CorcSelect from 'components/utils/CorcSelect';

const prettyBytes = require('pretty-bytes');

function EquipmentCertification(props) {

    useEffect(function() {

        const getStudy = async () => {
            let count = props.overlayVisible, exOptions = [], tmp;
            props.setOverlayVisible( (count) => count + 1);

            // get study and add it to object certification
            axios.get(process.env.REACT_APP_API_ENDPOINT+'/studies/'+props.match.params.id+'/centers/'+props.match.params.center+'/technicians/',
                { withCredentials: true, headers: {'Content-Type': 'application/json'}})
            .then((response) => {

                // can we add a new equipment? is the study open?
                if (response.data.status === "open") {
                    setCertification({...certification, study: response.data});

                    if(props.userAuth.user.role == 'admin') {

                        if (response.data.exams) {
                            response.data.exams.map(exam => {
                                if (exam.equipment_certification_required) {
                                    exOptions.push({label: exam.exam_type.name, value: exam.id});
                                }
                            });
                            setExamOptions(exOptions);
                        }
                    }
                    else if (props.userAuth.user.role == 'tech') {

                        tmp = response.data.technicians.find(tech => parseInt(tech.user.id,10) === props.userAuth.user.id);
                        if (tmp) {
                            tmp.exams.map(exam => {
                                if (exam.equipment_certification_required) {
                                    exOptions.push({label: exam.exam_type.name, value: exam.id});
                                }
                            });
                            setExamOptions(exOptions);
                        }

                    }

                }
                else {
                    props.setOverlayVisible( (count) => count - 1);
                    props.history.push('/studies/'+props.match.params.id+'/certifications');
                    return;
                }

                props.setOverlayVisible( (count) => count - 1);


            }, (error) => {
                props.setOverlayVisible( (count) => count - 1);
            });

        };

        if (props.userAuth.user.role !== 'tech' && props.userAuth.user.role !== 'admin') {
            props.history.push('/studies/'+props.match.params.id+'/certifications');
            return;
        }

        props.setNavLinks([{title: 'Studies', url: '/studies/', icon: 'studies'},{title: 'Certification', url: '/studies/'+props.match.params.id+'/certifications/', icon: 'certification'},{title: 'Equipment', url: props.location.pathname, icon: 'submit'}]);

        getStudy();

        props.setShowPrompt(true);
        window.addEventListener('beforeunload', props.showPromptHandler);
        //on unmount
        return () => {
            props.setShowPrompt(false);
            window.removeEventListener('beforeunload', props.showPromptHandler);
        };
    }, []);


    const [certification, setCertification] = useState({
        "id": 0,
        "technician": 0,
        "study_exam": 0,
        "study_center": {id: 0, study_site_num: ''},
        "status": "",
        "submission_date": "",
        "evaluation_date": "",
        "equipment_make_model": "",
        "camera_make_model": "",
        "camera_resolution": "",
        "serial_num": "",
        "software_version": "",
        "reject_message": "",
        "submitter_comment": "",
        "study": {},
        "equipment_files": [],
        "equipment_type": null,
    });

    const [noFiles, setNoFiles] = useState(false);
    const [noFilesErrorPopup, setNoFilesErrorPopup] = useState(false);
    const [confirmSubmitPopup, setConfirmSubmitPopup] = useState(false);
    const [certificationErrors, setCertificationErrors] = useState([])
    const [showAdminFiles, setShowAdminFiles] = useState(false);
    const [uploadedFiles, setUploadedFiles] = useState([]);
    const [uploadedFilesComment, setUploadedFilesComment] = useState("");
    const [showCameraFields, setShowCameraFields] = useState(false);
    //const [showEquipmentType, setShowEquipmentType] = useState(false);
    const [equipmentOptions, setEquipmentOptions] = useState([]);
    const [operatorOptions, setOperatorOptions] = useState([]);
    const [examOptions, setExamOptions] = useState([]);

    const [percentUploaded, setPercentUploaded] = useState(0);

    const closeNoFilesPopup = () => {
        setNoFilesErrorPopup(false);
    }

    const closeConfirmSubmitPopup = () => {
        setConfirmSubmitPopup(false);
    }

    const closeCertificationErrors = () => {
        setCertificationErrors([]);
    }

    const validateCertification = () => {

        let errors = [], i;
        const changesToSave = ['technician', 'study_exam', 'equipment_make_model', 'camera_make_model', 'camera_resolution', 'serial_num', 'software_version', 'submitter_comment'];
        const changedCertification = JSON.parse(JSON.stringify(certification));

        for (i in changedCertification) {
            if (changedCertification.hasOwnProperty(i) && !changesToSave.includes(i)) {
                delete changedCertification[i];
            }
        }

        changedCertification.study = {'id': certification.study.id, centers: [{'id': certification.study.centers[0].id}]}

        if (props.userAuth.user.role === 'tech') {
            changedCertification.technician = props.userAuth.user.id;
        }
        else {
            if (!changedCertification.technician) {
                errors.push("Operator cannot be blank.")
            }
        }


        if (!changedCertification.study_exam) {
            errors.push("Exam cannot be blank.")
        }

        if (showCameraFields) {
            changedCertification.camera_make_model = changedCertification.camera_make_model.trim();
            if (!changedCertification.camera_make_model) {
                errors.push("Camera Make and Model cannot be blank.")
            }

            changedCertification.camera_resolution = changedCertification.camera_resolution.trim();
            if (!changedCertification.camera_resolution) {
                errors.push("Camera Resolution cannot be blank.")
            }
        }

        changedCertification.equipment_make_model = changedCertification.equipment_make_model.trim();
        if (!changedCertification.equipment_make_model) {
            errors.push("Equipment Make and Model cannot be blank.")
        }

        changedCertification.serial_num = changedCertification.serial_num.trim();
        if (!changedCertification.serial_num) {
            errors.push("Serial Number cannot be blank.")
        }

        changedCertification.software_version = changedCertification.software_version.trim();
        if (!changedCertification.software_version) {
            errors.push("Software Version cannot be blank.")
        }
        // if validation fails, show error popup
        if (errors.length) {
            setConfirmSubmitPopup(false);
            setCertificationErrors(errors);
        }
        else {
            // otherwise submit the certification
            setConfirmSubmitPopup(false);
            submitCertification(changedCertification);
        }
    }

    const submitCertification = (cert) => {

        const url = process.env.REACT_APP_API_ENDPOINT+'/certifications/equipment/';
        const errMsg = "Sorry, something went wrong when submitting this certification. Please contact your IT helpdesk.";
        const formData = new FormData();
        const config = {
            withCredentials: true,
            headers: {
                'content-type': 'multipart/form-data'
            },
            onUploadProgress: progressEvent => {
                setPercentUploaded( Math.round( (progressEvent.loaded * 100) / progressEvent.total ) );
            }
        }

        for (var i in uploadedFiles) {
            formData.append('file',uploadedFiles[i].file);
        }
        formData.append('certification', JSON.stringify(cert));

        let count = props.overlayVisible;
        props.setFilesUploading(true);
        props.setOverlayVisible( (count) => count + 1);
        post(url, formData,config).then(function (response) {

            props.setFilesUploading(false);
            props.setOverlayVisible( (count) => count - 1);
            setConfirmSubmitPopup(false);

           if (response.status === 200 && response.data) {
                props.setShowPrompt(false);

               // update certification object
               setCertification(response.data);

               // go to certifications of study
               props.history.push("/studies/"+props.match.params.id+"/certifications");

           }

        }).catch(function(error) {
            props.setOverlayVisible( (count) => count - 1);
            props.setFilesUploading(false);
            setConfirmSubmitPopup(false);
            alert(errMsg);
        });

        return false;
    };

    const submitHandler = e => {
        e.preventDefault();
    };

    return (
        <React.Fragment>

            {certificationErrors.length > 0 && (
                <React.Fragment>
                <div className="modal-backdrop fade in"></div>
                <div id="alertModal" className="modal hide fade in" tabIndex="-1" role="dialog" aria-labelledby="myModalLabel" aria-hidden="false" style={{display: "block"}}>
                    <div className="modal-header">
                        <button type="button" className="close" data-dismiss="modal" aria-hidden="true">×</button>
                        <h3 id="alert-modal-title">Errors!</h3>
                    </div>
                    <div className="modal-body"><br/><br/>
                        <div className="errorSummary"><p>Please fix the following input errors:</p>
                            <ul>
                                {certificationErrors.map((message) => (
                                <li key={message}>{message}</li>
                                ))}
                            </ul>
                        </div><br />
                    </div>
                    <div className="modal-footer">
                        <button onClick={closeCertificationErrors} id="alert-modal-ok" aria-hidden="true" className="btn small orange align-center right" style={{display: "block"}}>OK</button>
                    </div>
                </div>
                </React.Fragment>
            )}

            {confirmSubmitPopup && (

                <React.Fragment>
                <div className="modal-backdrop fade in"></div>
                <div id="alertModal" className="modal hide fade in" tabIndex="-1" role="dialog" aria-labelledby="myModalLabel" aria-hidden="false" style={{display: "block"}}>
                    <div className="modal-header">
                        <button type="button" className="close" data-dismiss="modal" aria-hidden="true">×</button>
                        <h3 id="alert-modal-title">Confirmation!</h3>
                    </div>
                    <div className="modal-body"><br/>Do you really want to finish and submit?<br/></div>
                    <div className="modal-footer">
                        <button onClick={validateCertification} id="alert-modal-ok" aria-hidden="true" className="btn small orange align-center right">Yes</button>
                        <button onClick={closeConfirmSubmitPopup} data-dismiss="modal" id="alert-modal-cancel" aria-hidden="true" className="btn small grey align-center right" style={{display: "block"}}>No</button>
                    </div>
                </div>
                </React.Fragment>

            )}

            {noFilesErrorPopup && (

                <React.Fragment>
                <div className="modal-backdrop fade in"></div>
                <div id="alertModal" className="modal hide fade in" tabIndex="-1" role="dialog" aria-labelledby="myModalLabel" aria-hidden="false" style={{display: "block"}}>
                    <div className="modal-header">
                        <button type="button" className="close" data-dismiss="modal" aria-hidden="true">×</button>
                        <h3 id="alert-modal-title">Warning!</h3>
                    </div>
                    <div className="modal-body"><br/><br/>You are submitting without files. Please add files or check the checkbox<br/></div>
                    <div className="modal-footer">
                        <button onClick={closeNoFilesPopup} data-dismiss="modal" id="alert-modal-ok" aria-hidden="true" className="btn small orange align-center right">OK</button>
                    </div>
                </div>
                </React.Fragment>
            )}

            <div className="home_header_container_class">
                <div className="content">
                    <div className="breadcrumbs">
                        <div className="path">
                            <ul>
                                <li><span className="orange text-shadow">{certification.study && certification.study.acronym ? certification.study.acronym.toUpperCase() : ""}</span></li>
                                <li className="separator text-shadow">&gt;</li>
                                <li><span className="orange text-shadow">Certifications</span></li>
                                <li className="separator text-shadow">&gt;</li>
                                <li><span className="orange text-shadow">Equipment</span></li>
                                <li className="separator text-shadow">&gt;</li>
                                <li>{certification.study.centers && certification.study.centers[0].study_site_num}</li>
                            </ul>
                        </div>
                    </div> {/* .breadcrumbs */}

                    <div className="main-form">
                        <form onSubmit={submitHandler} id="register-certification" method="post">

                            <EquipmentCertificationData
                                userAuth={props.userAuth}
                                certification={certification}
                                setCertification={setCertification}
                                showCameraFields={showCameraFields}
                                setShowCameraFields={setShowCameraFields}
                                //showEquipmentType={showEquipmentType}
                                //setShowEquipmentType={setShowEquipmentType}
                                equipmentOptions={equipmentOptions}
                                setEquipmentOptions={setEquipmentOptions}
                                operatorOptions={operatorOptions}
                                setOperatorOptions={setOperatorOptions}
                                examOptions={examOptions}

                            />

                        </form>

                        <EquipmentCertificationFiles
                            certification={certification}
                            setCertification={setCertification}
                            noFiles={noFiles}
                            setNoFiles={setNoFiles}
                            showAdminFiles={showAdminFiles}
                            setShowAdminFiles={setShowAdminFiles}
                            userType={props.userAuth.user.role}
                            uploadedFiles={uploadedFiles}
                            setUploadedFiles={setUploadedFiles}
                            setUploadedFilesComment={setUploadedFilesComment}
                            uploadedFilesComment={uploadedFilesComment}
                            percentUploaded={percentUploaded}
                        />

                        <EquipmentCertificationActions
                            setConfirmSubmitPopup={setConfirmSubmitPopup}
                            setNoFilesErrorPopup={setNoFilesErrorPopup}
                            noFiles={noFiles}
                            uploadedFiles={uploadedFiles}
                            setUploadedFiles={setUploadedFiles}
                            certification={certification}
                            setCertification={setCertification}
                        />
                    </div>
                </div> {/* .content */}
            </div>
        </React.Fragment>
    );
}

export default EquipmentCertification;


function EquipmentCertificationData(props) {
    const onChangeHandler = (ev) => {
        let name = ev.target.name;
        let value = ev.target.value;

        props.setCertification({...props.certification, [name]: value});
    };

    const onChangeExamHandler = value => {
        let editedCertification = JSON.parse(JSON.stringify(props.certification));
        let equipmentTypeOptions = [], operatorOptions = [], tmp;

        editedCertification.study_exam = value;

        let foundExam = props.certification.study.exams.find(e => {
            return e.id === parseInt(value,10);
        });

        // operators with permissions in exam
        if (foundExam) {

            if(editedCertification.study.technicians) {
                editedCertification.study.technicians.map(tech => {

                    // does the technician have permission for that exam?
                    tmp = tech.exams.find(obj => obj.id == parseInt(value,10));
                    if (tmp) {
                        operatorOptions.push({label: tech.user.username, value: tech.user.id});
                    }
                });

                props.setOperatorOptions(operatorOptions);
            };
        }



        if (foundExam && foundExam.hasOwnProperty('exam_type')) {

            /*
            if (props.userAuth.user.role === 'grader' || props.userAuth.user.role === 'admin') {
                if(foundExam.exam_type.equipment_type.length) {
                    props.setShowEquipmentType(true);

                    foundExam.exam_type.equipment_type.forEach(equip => {
                        equipmentTypeOptions.push({label: equip.name, value: equip.id});
                    });
                    props.setEquipmentOptions(equipmentTypeOptions);
                }
                else {
                    props.setShowEquipmentType(false);
                    editedCertification.equipment_type = null;
                }
            }
            */

            if (!foundExam.exam_type.has_equipments_with_camera) {
                props.setShowCameraFields(false);
                editedCertification.camera_make_model = "";
                editedCertification.camera_resolution = "";
            }
            else {
                props.setShowCameraFields(true);
            }
        }
        else {
            props.setShowCameraFields(false);
            editedCertification.camera_make_model = "";
            editedCertification.camera_resolution = "";
        }

        props.setCertification(editedCertification);
    };

    /*
    const onChangeEquipmentTypeHandler = value => {
        props.setCertification({...props.certification, equipment_type: value});
    };
    */

    const onChangeOperatorHandler = value => {
        props.setCertification({...props.certification, technician: value});
    };

    return (
        <div className="form-group">
            <div className="span5 insert-visit" style={{width: "46%"}}>

                <div className="row" >
                    <div className="label">
                        <label>Exam</label>
                    </div>
                    <div className="input">
                        <CorcSelect
                            id="study_exam"
                            name="study_exam"
                            onChange={selectedOption => onChangeExamHandler(selectedOption.value)}
                            value={props.examOptions.filter(e => e.value === props.certification.study_exam) }
                            placeholder="----"
                            options={props.examOptions}
                            isSearchable={false}
                            containerStyles={{width: "300px"}}
                        />
                    </div>
                </div> {/* .row */}

                {props.userAuth.user.role === 'admin' && (
                <div className="row" >
                    <div className="label">
                        <label>Operator</label>
                    </div>
                    <div className="input">
                        <CorcSelect
                            onChange={selectedOption => onChangeOperatorHandler(selectedOption.value)}
                            name="technician"
                            id="technician"
                            value={props.operatorOptions.filter(o => o.value === props.certification.technician)}
                            isSearchable={false}
                            placeholder="----"
                            containerStyles={{width: "300px"}}
                            options={props.operatorOptions}
                        />
                    </div>
                </div>
                )}

                { /*(props.userAuth.user.role === 'admin' || props.userAuth.user.role === 'grader') && props.showEquipmentType && (
                <div className="row" >
                    <div className="label">
                        <label>Type</label>
                    </div>
                    <div className="input">
                        <CorcSelect
                            id="equipment_type"
                            name="equipment_type"
                            onChange={selectedOption => onChangeEquipmentTypeHandler(selectedOption.value)}
                            value={props.equipmentOptions.filter(e => e.value === props.certification.equipment_type) }
                            placeholder="----"
                            options={props.equipmentOptions}
                            isSearchable={false}
                            containerStyles={{width: "300px"}}
                        />
                    </div>
                </div>
                ) */}


                {props.showCameraFields && (
                <React.Fragment>
                <div className="row">
                    <div className="label">
                        <label className="required">Camera Make Model *</label>
                    </div>
                    <div className="input">
                        <input
                            name="camera_make_model"
                            id="camera_make_model"
                            type="text"
                            value={props.certification.camera_make_model}
                            onChange={(ev) => onChangeHandler(ev)}
                        />
                    </div>
                </div>

                <div className="row" >
                    <div className="label">
                        <label className="required">Camera Resolution *</label>
                    </div>
                    <div className="input">
                        <input
                            name="camera_resolution"
                            id="camera_resolution"
                            type="text"
                            value={props.certification.camera_resolution}
                            onChange={(ev) => onChangeHandler(ev)}
                        />
                    </div>
                </div>
                </React.Fragment>
                )}

                <div className="row" >
                    <div className="label">
                        <label className="required">Equipment Make and Model *</label>
                    </div>
                    <div className="input">
                        <input
                            name="equipment_make_model"
                            id="name"
                            type="text"
                            value={props.certification.equipment_make_model}
                            onChange={(ev) => onChangeHandler(ev)}
                        />
                    </div>
                </div> {/* .row */}

                <div className="row" >
                    <div className="label">
                        <label className="required">Serial Number *</label>
                    </div>
                    <div className="input">
                        <input
                            name="serial_num"
                            id="serial_num"
                            type="text"
                            maxLength="100"
                            value={props.certification.serial_num}
                            onChange={(ev) => onChangeHandler(ev)}
                        />
                    </div>
                </div> {/* .row */}


                <div className="row" >
                    <div className="label">
                        <label className="required">Software Version *</label>
                    </div>
                    <div className="input">
                        <input
                            name="software_version"
                            id="software_version"
                            type="text"
                            maxLength="100"
                            value={props.certification.software_version}
                            onChange={(ev) => onChangeHandler(ev)}
                        />
                    </div>
                </div> {/* .row */}

            </div>

            <div className="span5" style={{width: "54%"}}>
                <textarea
                    style={{height: "200px"}}
                    className="submit-comment"
                    maxLength="5000"
                    placeholder="Comments"
                    name="submitter_comment"
                    id="submitter_comment"
                    value={props.certification.submitter_comment}
                    onChange={(ev) => onChangeHandler(ev)}
                ></textarea>
            </div>
        </div>
    );
}


function EquipmentCertificationFiles(props) {

    return (
        <React.Fragment>

        {!props.noFiles && (
        <EquipmentCertificationFileUploader
            uploadedFiles={props.uploadedFiles}
            setUploadedFiles={props.setUploadedFiles}
            setShowAdminFiles={props.setShowAdminFiles}
            percentUploaded={props.percentUploaded}
        />
        )}

        <EquipmentCertificationNoFiles
            noFiles={props.noFiles}
            setNoFiles={props.setNoFiles}
        />

        {props.certification.equipment_files.map( (file, i) => (
        <EquipmentCertificationFileItem
            certification={props.certification}
            key={i}
            file={file}
        />
        ))}

        </React.Fragment>
    );
}

function EquipmentCertificationNoFiles(props) {
    return (
        <div className="form-group content" id="nofileinput">
            <br />
            <input
                type="checkbox"
                id="checkbox-nofiles"
                checked={props.noFiles}
                onChange={(ev) => props.setNoFiles(ev.target.checked)}
            />
            <label htmlFor="checkbox-nofiles">No files are to be submitted</label>
            <br />
        </div>
    );
}

function EquipmentCertificationFileUploader(props) {

    const removeFileHandler = (ev,file) =>  {

        ev.preventDefault();

        let editedUploadedFiles = props.uploadedFiles.filter(f => f.key !== file.key);
        props.setUploadedFiles(editedUploadedFiles);

        return false;
    };

    const onDrop = acceptedFiles => {
        let newFiles = [];

        acceptedFiles.map(file => {
            newFiles.push({file: file, key: Math.floor(Math.random(5) * 1000), name: file.name});
            return false;
        });

        newFiles = newFiles.concat(props.uploadedFiles);
        props.setUploadedFiles(newFiles);
    };

    const {getRootProps, getInputProps} = useDropzone({onDrop});

    return (
        <form id="fileupload" method="post" encType="multipart/form-data">
            <div className="form-group">
                <div id="files" className="files">
                    <div {...getRootProps({
                            className: "info"
                    })}>
                        <span className="icon submit ico"></span>
                        <h2 className="text-shadow">Drop files here</h2>
                        <span className="or">
                            <hr />
                            <span>or</span>
                            <hr />
                        </span>
                        <span className="btn large orange fileinput-button">
                            <span>Select files from your computer</span>
                            <input {...getInputProps()} />
                        </span>
                    </div>
                    <div id="filesProgress" className="clearfix">
                        {props.uploadedFiles.map((file, i) => (
                        <div
                            key={i}
                            className="file-upload-box">
                            <p>
                                <span className="file-name">{file.name}</span>
                                <span className="file-actions">
                                    <button
                                        className="btn small grey align-center left other"
                                        onClick={(ev) => removeFileHandler(ev, file)}
                                    >REMOVE</button>
                                </span>
                                <span className="progress active">
                                    <span className="progress-bar progress-bar-warning" role="progressbar" style={{width: `${props.percentUploaded}%`}}></span>
                                    <span className="progress_value">
                                        {props.percentUploaded ? `${props.percentUploaded} % (a few seconds left)` : 'READY TO UPLOAD' }
                                    </span>
                                </span>
                            </p>
                        </div>
                        ))}
                    </div>
                </div>
            </div>
        </form>
    );
}


function EquipmentCertificationFileItem(props) {

//    const onChangeHandler = ev => {
//        let val = ev.target.value;
//        let name = ev.target.name;
//    };

    const style = {
        width: "40%",
        float: "right",
        marginRight: "32px",
        display: "block"
    }

    return (
        <div className="form-group">
            <div className="file-uploaded">
                <div className="row">
                    <a target="_blank" rel="noopener noreferrer" href={process.env.REACT_APP_API_ENDPOINT + "/certifications/equipment/"+props.certification.id+'/file/'+props.file.id+'/download/'} className="cancel-btn-class btn small grey align-center">Download</a>
                    <div className="file-name">
                        <span className="filename-class">{props.file.name}<b>{" (" + prettyBytes(props.file.file && props.file.file.size ? props.file.file.size : 0) + ")"}</b></span>
                    </div>

                   <div className="file-comment" style={style} ref={(node) => {
                      if (node) {
                        node.style.setProperty("float", "right", "important");
                      }
                    }}>
                        <input
                            maxLength="255"
                            type="text"
                            className="uploaded_file"
                            value={props.file.description || ''}
                            placeholder="Comment"
                            //onChange={(ev) => onChangeHandler(ev)}
                        />
                    </div>
                </div>
            </div>
        </div>
    );
}

function EquipmentCertificationActions(props) {

    const onSubmitHandler = (ev) => {

        ev.preventDefault();

        if (!props.uploadedFiles.length && !props.noFiles) {
            props.setNoFilesErrorPopup(true)
            return false;
        }

        props.setConfirmSubmitPopup(true);
        return false;
    };

    const buttons = () => {
        return (
            <div style={{textAlign: "center",overflow: "hidden"}}>
                <button
                    className="btn-submit-form-two"
                    onClick={(ev) => onSubmitHandler(ev)}
                >
                    <span className="submit-icon"></span>
                    Finish and Submit
                </button>
            </div>
        );
    };

    return (
        <div className="form-actions" style={{float: "right"}}>
            {buttons()}
        </div>

    );
}

