import React, {useEffect, useState} from 'react';
import {Link} from 'react-router-dom';
import { CSVLink } from "react-csv";
import axios from 'axios';
import jsPDF from "jspdf";
import "jspdf-autotable";

function Navigate(props) {

    useEffect(function() {

        const getStudy = async () => {
            let count = props.overlayVisible;
            props.setOverlayVisible( (count) => count + 1);


            await axios.get(process.env.REACT_APP_API_ENDPOINT+'/studies/'+props.match.params.id+'/centers/', { withCredentials: true}).then(response => {

                const dataCSV = [];

                props.setOverlayVisible( (count) => count - 1);
                if (response.status === 200) {

                    setStudy(response.data);

                    if (response.data.centers) {
                        response.data.centers.map(function(c) {

                            let me = JSON.parse(JSON.stringify(c));

                            me.study_site_num = me.study_site_num.toUpperCase();
                            dataCSV.push(me);
                            return false;
                        });

                        setDataToCSV(dataCSV);
                    }
                }

            }, (error) => {
                props.setOverlayVisible( (count) => count - 1);
                if (error.response.status === 401) {
                    props.resetAuth();
                    props.history.push('/login');
                }
            });

        }

        if (props.userAuth.user.role !== 'admin' && props.userAuth.user.role !== 'monitor' && props.userAuth.user.role !== 'grader') {
            props.history.push('/studies');
            return;
        }


        getStudy();
        props.setNavLinks([{title: 'Studies', url: '/studies/', icon: 'studies'},{title: 'Clinical Site', url: props.location.pathname, icon: 'center'}]);
    }, []);

    const [dataToCSV, setDataToCSV] = useState([]);
    const [study, setStudy] = useState({});

    const headersCSV = [
      { label: "Clinical Site", key: "study_site_num" },
      { label: "Total Patients", key: "total_patients" },
      { label: "Total Visits", key: "total_visits" },
      { label: "Screening Failure", key: "total_failure" },
      { label: "Ongoing", key: "total_ongoing" },
      { label: "Completed", key: "total_completed" },
      { label: "Reached end point", key: "total_endpoint" },
      { label: "Drop-Out", key: "total_dropped_out" },
      { label: "Enrolled", key: "total_enrolled" }
    ];

    const exportPDF = () => {

        const marginLeft = 40;
        const title = "";
        const doc = new jsPDF("landscape", "pt", "A4");

        doc.setFontSize(15);

        const headers = [["Clinical Site", "Total Patients", "Total Visits", "Screening Failure", "Ongoing", "Completed", "Reached end point", "Drop-Out", "Enrolled"]];

        const data = dataToCSV.map(i=> [i.study_site_num, i.total_patients, i.total_visits, i.total_failure, i.total_ongoing, i.total_completed, i.total_endpoint, i.total_dropped_out, i.total_enrolled]);

        let content = {
          startY: 50,
          head: headers,
          body: data
        };

        doc.text(title, marginLeft, 40);
        doc.autoTable(content);
        doc.save("study-export.pdf")
  }
    return (
        <div className="home_header_container_class wide">
            <div className="content">
                <div className="breadcrumbs">
                    <div className="path">
                        <ul>
                            <li>
                                <span className="text-shadow orange">{study.acronym && study.acronym.toUpperCase()}</span>
                            </li>
                            <li className="separator text-shadow">&gt;</li>
                            <li>Clinical Site</li>
                        </ul>
                    </div> {/* .path */}

                    <div className="sub-menu">

                        <CSVLink className="btn normal grey right" data={dataToCSV} headers={headersCSV} filename={"study-export.csv"}>
                            <span className="icon icon-list report"></span>
                            Export to CSV
                        </CSVLink>
                        <button onClick={exportPDF} className="btn normal grey right">
                            <span className="icon icon-list report"></span>Export to PDF
                        </button>
                    </div>
                </div> {/* .breadcrumbs */}

                <div className="visits-list list">
                    <div className="list-body">
                        <table>
                            <tbody className="list-centers">
                                { study.centers && study.centers.map((center, i) => (

                                center.is_active && (
                                    <NavigateCenter
                                        key={i}
                                        center={center}
                                        study={study}
                                    />
                                )
                                ))}
                            </tbody>
                        </table>
                    </div>
                </div> {/* .visits-list */}
            </div>
        </div>
    );
}

export default Navigate;

function NavigateCenter(props) {
    const getClassName = (idx) => {
        let classes = []
        if(idx % 4 === 0) {
            classes.push('last');
        }

        if(idx > 3) {
            classes.push('bottom');
        }

        return classes.join(' ');
    };

    return (
        <tr key={props.center.id} className="center-row">
            <td>
                <div className="details-center">
                    <span className="title">{props.center.study_site_num.toUpperCase()}</span>
                    <ul>
                        <li className={getClassName()}>
                            Total Study Patients <label>{props.center.total_patients}</label>
                        </li>
                        <li className={getClassName()}>
                            Screening Failure <label>{props.center.total_failure}</label>
                        </li>
                        <li className={getClassName()}>
                            Ongoing <label>{props.center.total_ongoing}</label>
                        </li>
                        <li className={getClassName()}>
                            Drop-out <label>{props.center.total_dropped_out}</label>
                        </li>
                        <li className={getClassName()}>
                            Total Study Visits <label>{props.center.total_visits}</label>
                        </li>
                        <li className={getClassName()}>
                            Enrolled <label>{props.center.total_enrolled}</label>
                        </li>
                        <li className={getClassName()}>
                            Completed <label>{props.center.total_completed}</label>
                        </li>
                        <li className={getClassName()}>
                            Reached end point <label>{props.center.total_endpoint}</label>
                        </li>



                    </ul>
                </div> {/* .details-center */}

                <div className="buttons-center">
                    <Link
                        to={"/studies/"+ props.study.id + "/center/"+props.center.center.id+"/patients/"}
                        className="btn small grey"
                    >
                        <span className="icon-list user-small"></span>Patients
                    </Link>
                </div> {/* .buttons-center */}
            </td>
        </tr>
    );
}
