import React, {useEffect, useState} from 'react';
import axios from 'axios';

function ResetPassword(props) {
    useEffect(function() {

        const validateToken = async () => {
            let count = props.overlayVisible;
            props.setOverlayVisible(count => count + 1);

            await axios.get(process.env.REACT_APP_API_ENDPOINT+'/users/reset/'+props.match.params.token+'/' ).then((response) => {
                props.setOverlayVisible(count => count - 1);
                if(response.status !== 200) {
                    window.location = '/';
                }
            }, (error) => {
                props.setOverlayVisible(count => count - 1);
                window.location = '/';
            });
        }

        if(props.match.params.token) {
            validateToken();
        }
    }, []);


    const [user, setUser] = useState({
        password: '',
        confirm_password: '',
        username: '',
        email: '',
    });

    const getErrorClass = (type) => {

        if (errorFields.indexOf(type) !== -1) {
            return "error";
        }

        return "";
    };

    const [errorFields, setErrorFields] = useState([])

    const handleSubmit = (e) => {
        e.preventDefault();
    };

    const onChangeHandler = ev => {
        let name = ev.target.name;
        let value = ev.target.value;

        let editedUser = JSON.parse(JSON.stringify(user));
        setUser({...editedUser, [name]: value});
    }


    return (
        <React.Fragment>
        <div id="home_header_container" className="home_header_container_class home">
            <div id="home_header_middle_container">
                <img alt="logo" id="logo_home" src={require("images/logo_home.png")} />
            </div>
        </div>
        <div className="form-reset">
            {!props.match.params.token ? (
                <SendMailForm
                    onChangeHandler={onChangeHandler}
                    user={user}
                    setUser={setUser}
                    getErrorClass={getErrorClass}
                    handleSubmit={handleSubmit}
                    setOverlayVisible={props.setOverlayVisible}
                    setErrorFields={setErrorFields}
                />
            ) : (
                <SetPasswordForm
                    onChangeHandler={onChangeHandler}
                    user={user}
                    setUser={setUser}
                    getErrorClass={getErrorClass}
                    handleSubmit={handleSubmit}
                    token={props.match.params.token}
                    setOverlayVisible={props.setOverlayVisible}
                    setErrorFields={setErrorFields}
                />
            )}
        </div>
        </React.Fragment>
    );
}

export default ResetPassword;

function SendMailForm(props) {
    const sendRecoverEmail = async () => {
        const errMsg = "Incorrect username or email.";

        if (validateUser(props.user)) {
            let count = props.overlayVisible;
            props.setOverlayVisible( (count) => count + 1);

            await axios.post(process.env.REACT_APP_API_ENDPOINT+'/users/reset/', {...props.user})
                .then((response) => {

                props.setOverlayVisible( (count) => count - 1);

                if (response.status === 200) {
                    window.location = '/';
                }
                else {
                    alert(errMsg);
                }
            }, (error) => {
                alert(errMsg);
                props.setOverlayVisible( (count) => count - 1);
            });
        }
    };

    const validateUser = () => {
        let errorFields = [];
        let editedUser = JSON.parse(JSON.stringify(props.user));

        editedUser.username = editedUser.username.trim();
        editedUser.email = editedUser.email.trim();

        if(!editedUser.username) {
            errorFields.push('username');
        }

        if (!editedUser.email || !(/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(editedUser.email))) {
            errorFields.push('email');
        }

        props.setUser({...editedUser});
        props.setErrorFields(errorFields);

        if (errorFields.length) {
            return false;
        }

      return true;
    };


    return (
        <form id="reset_form" onSubmit={props.handleSubmit} method="post">
            <h1>Recover password</h1>
            <p>
                <input
                    type="text"
                    name="username"
                    id="username"
                    placeholder="Username"
                    className={"text_box_class " + props.getErrorClass('username')}
                    onChange={props.onChangeHandler}
                />
            </p>
            <p className="error">{props.getErrorClass('username') ? 'Username cannot be blank.' : ''}</p>
            <p>
                <input
                    type="text"
                    name="email"
                    id="email"
                    placeholder="Email"
                    className={"text_box_class " + props.getErrorClass('email')}
                    style={{marginTop: "20px"}}
                    onChange={props.onChangeHandler}
                />
            </p>
            <p className="error">{props.getErrorClass('email') ? 'Email must be valid.' : ''}</p>
            <input
                id="recover_button"
                className="submit_button_class"
                type="submit"
                onClick={sendRecoverEmail}
                value="OK"
            />
        </form>
    );
}

function SetPasswordForm(props) {

    const validatePassword = () => {

        const passwordValidator = require('password-validator');
        let passwordSchema = new passwordValidator();
        passwordSchema.is().min(5).has().lowercase().has().digits().has().symbols()

        let errorFields = [];
        let editedUser = JSON.parse(JSON.stringify(props.user));

        editedUser.password = editedUser.password.trim();
        editedUser.confirm_password = editedUser.confirm_password.trim();

        if(!editedUser.password) {
            errorFields.push('password');
        }

        if(!editedUser.confirm_password || editedUser.password !== editedUser.confirm_password) {
            errorFields.push('confirm_password');
        }

        if (!passwordSchema.validate(editedUser.password)) {
            errorFields.push('invalid_password');
        }

        props.setUser({...editedUser});
        props.setErrorFields(errorFields);

        if (errorFields.length) {
            return false;
        }

      return true;
    };

    const submitNewPassword = async () => {
        const errMsg = "Sorry, something went wrong when changing this user's password. Please contact your IT helpdesk.";

        if (validatePassword(props.user)) {
            let count = props.overlayVisible;
            props.setOverlayVisible( (count) => count + 1);

            await axios.post(process.env.REACT_APP_API_ENDPOINT+'/users/reset/'+props.token+'/', {...props.user})
                .then((response) => {

                props.setOverlayVisible( (count) => count - 1);
                if (response.status === 200) {
                    window.location = '/';
                }
                else {
                    alert(errMsg);
                }

            }, (error) => {
                props.setOverlayVisible( (count) => count - 1);
                alert(errMsg);
            });

        }

    };

    return (
        <form id="reset_form" onSubmit={props.handleSubmit} method="post">
            <h1>Recover password</h1>
            <p>
                <input
                    type="password"
                    name="password"
                    id="password"
                    placeholder="Password"
                    className={"text_box_class " + props.getErrorClass('password')}
                    onChange={props.onChangeHandler}
                />
            </p>
            <p className="error">{props.getErrorClass('password') ? 'Password cannot be blank.' : ''}</p>
            <p>
                <input
                    type="password"
                    name="confirm_password"
                    id="confirm_password"
                    placeholder="Confirm password"
                    className={"text_box_class " + props.getErrorClass('confirm_password')}
                    style={{marginTop: "20px"}}
                    onChange={props.onChangeHandler}
                />
            </p>
            <p className="error">{props.getErrorClass('confirm_password') ? 'Confirmation and password must be the same.' : ''}</p>
            <p className="error">{props.getErrorClass('invalid_password') ? 'Your password must have at least 5 characters combining letters, numbers and symbols/special characters (#,@,&,%,/,*, etc).' : ''}</p>
            <input
                id="recover_button"
                className="submit_button_class"
                type="submit"
                onClick={submitNewPassword}
                value="OK"
            />
        </form>
    );
}
