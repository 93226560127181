import React, {useEffect, useState}  from 'react';
import axios from 'axios';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import CorcSelect from 'components/utils/CorcSelect';
import {Link} from 'react-router-dom';
import {initDate} from 'components/utils/Helpers.js';
const prettyBytes = require('pretty-bytes');

function ExamGrade(props) {
    useEffect(function() {

        props.setNavLinks([
            {title: 'Studies', url: '/studies/', icon: 'studies'},
            {title: 'Exams', url: '/studies/'+props.match.params.id+'/exams/'+props.match.params.exam, icon: 'exam'},
            {title: 'Grade', url: props.location.pathname, icon: 'submit'}
        ]);

        if (props.userAuth.user.role !== 'grader') {
            props.history.push('/studies/'+props.match.params.id+'/exams/'+props.match.params.exam);
        }
        else {
            getVisit();
        }


    }, []);

    const getOperators = async (visit) => {

        let count = props.overlayVisible;
        let operators = [{value:"-1", label: "N/A"}];
        props.setOverlayVisible( (count) => count + 1);

        const items = await axios.get(process.env.REACT_APP_API_ENDPOINT+'/studies/'+props.match.params.id+'/centers/'+visit.patient.center.center.id+'/technicians/',
            { withCredentials: true, headers: {'Content-Type': 'application/json'}});

        items.data.technicians.forEach( (tech) => {
            let found = false;

            if (tech.has_permission) {
                found = tech.exams.find(exam => exam.exam_type.id === visit.study_exam.exam_type.id);
                if (found) {

                    // does he need certification? if so, does he have it?
                    if (visit.study_exam.technician_certification_required) {
                        found = tech.certifications.find(cert => cert.study_exam.exam_type.id === visit.study_exam.exam_type.id && cert.status === 'certified');
                        if (found) {
                            operators.push({value: tech.user.id, label: tech.user.username+" ( "+tech.user.name+")"})
                        }
                    }
                    else {
                        operators.push({value: tech.user.id, label: tech.user.username+" ( "+tech.user.name+")"})
                    }

                }
            }

        });

        if (operators.length) {
            setOperatorOptions(operators);
        }

        props.setOverlayVisible( (count) => count - 1);

    };

    const getVisit = async () => {
        let count = props.overlayVisible;
        props.setOverlayVisible( (count) => count + 1);

        axios.get(process.env.REACT_APP_API_ENDPOINT+'/visits/'+props.match.params.visit+'/',
            { withCredentials: true, headers: {'Content-Type': 'application/json'}})
        .then((response) => {

            let operators = [];

            if (response.data.operator) {
                operators.push({value: response.data.operator.id, label: response.data.operator.username + " (" + response.data.operator.name + ")"});
                setOperatorOptions(operators);
                response.data.operator = response.data.operator.id;
            }

            setVisit(response.data);
            getOperators(response.data);

            if (response.data.exam_status == 'rejected' || response.data.exam_status == 'received' || response.data.exam_status == 'submitted') {
                setNewStatus(response.data.exam_status);
            }
            props.setOverlayVisible( (count) => count - 1);


        }, (error) => {

            props.setOverlayVisible( (count) => count - 1);
        });


    };

    const [operatorOptions, setOperatorOptions] = useState([{value:"-1", label: "N/A"}]);
    const [visit, setVisit] = useState({
        exam_date: '',
        patient: {
            study: {},
            center: {},
        },
        study_exam: {
            exam_type: {},
        },
        user_submitted: {},
        operator: {},
        user_graded: {},
        grading_comment: '',
        grading_internal_comment: '',
        visit_files: [],
        related_visits: [],
        form_vars: [],
    });

    const submitHandler = async e => {
        e.preventDefault();

        let count = props.overlayVisible;
        const errMsg = "Sorry, something went wrong when adding this center. Please contact your IT helpdesk.";
        props.setOverlayVisible( (count) => count + 1);

        // change status of exam
        await axios.post(process.env.REACT_APP_API_ENDPOINT+'/visits/'+visit.id+'/grade/', {'operator': visit.operator, 'exam': visit.study_exam.id, 'left_eye_submit': visit.left_eye_submit, 'right_eye_submit': visit.right_eye_submit, 'exam_date': visit.exam_date, 'status': newStatus, 'reject_message': newStatusMessage, 'grading_comment': visit.grading_comment, 'grading_internal_comment': visit.grading_internal_comment}, { withCredentials: true})
            .then((response) => {

            if (response.status === 200) {

                props.setOverlayVisible( (count) => count - 1);
                closePopup();
                props.history.push('/studies/'+props.match.params.id+'/exams/'+props.match.params.exam);
            }
            else {
                props.setOverlayVisible( (count) => count - 1);
                closePopup();
                alert(errMsg);
            }

        }, (error) => {
            props.setOverlayVisible( (count) => count - 1);
            closePopup();
            alert(errMsg);
        });


    };

    const onStatusChangeHandler = selected => {
        setNewStatus(selected);
    };

    const validateSubmit = () => {

        let errors = [];
        let item = statusOptions.find(i => {
            return i.value === newStatus;
        });

        if (isGeneralVarVisible('exam-date') && isNaN(Date.parse(visit.exam_date))) {
            errors.push('Exam date is invalid.');
        }

        if (isGeneralVarVisible('submitted-eye') && !visit.left_eye_submit && !visit.right_eye_submit) {
            errors.push('Submitted eye is invalid.');
        }

        if (!newStatus) {
            errors.push('Exam status is not set.');
        }

        if (errors.length) {
            setSubmitErrorMessagesPopup(errors);
            return;
        }

        if (item) {
            setConfirmStatus(item.label);
        }

    };

    const [confirmStatus, setConfirmStatus] = useState("");
    const [newStatus, setNewStatus] = useState("");
    const [newStatusMessage, setNewStatusMessage] = useState("");

    const statusOptions = [
        {value:"submitted", label: "Submitted"},
        {value:"received", label: "Received"},
        {value:"rejected", label: "Rejected"},
    ];

    const closePopup = () => {
        setNewStatusMessage("");
        setConfirmStatus("");

    };

    const isGeneralVarVisible = (uid) => {
        if(visit.study_exam.hasOwnProperty('technician_form_vars')) {
            if(visit.study_exam.technician_form_vars.find(v => v.technician_form_var.uid === uid && v.technician_form_var.group === 'general')) {
                return true;
            }
        }

        return false;
    };

    const onChangeHandler = (ev) => {
        let name = ev.target.name;
        let val = ev.target.value;

        setVisit({...visit, [name]: val});
    };

    const onOperatorChangeHandler = selected => {
        setVisit({...visit, operator: selected})
    }

    const onSubmittedEyeChangeHandler = selected => {

        if (selected === "both") {
            setVisit({...visit, left_eye_submit: true, right_eye_submit: true});
        }
        else if (selected === "left") {
            setVisit({...visit, left_eye_submit: true, right_eye_submit: false});
        }
        else if (selected === "right") {
            setVisit({...visit, left_eye_submit: false, right_eye_submit: true});
        }
        else {
            setVisit({...visit, left_eye_submit: false, right_eye_submit: false});
        }
    }

    const submittedEyeOptions = [
        {value:"left", label: "Left"},
        {value:"right", label: "Right"},
        {value:"both", label: "Both"},
    ];

    const getCheckedSubmittedEye = () => {

        if (visit.left_eye_submit && visit.right_eye_submit) {
            return submittedEyeOptions.filter(o => o.value === "both");
        }
        else if (visit.left_eye_submit && !visit.right_eye_submit) {
            return submittedEyeOptions.filter(o => o.value === "left");
        }
        else if (!visit.left_eye_submit && visit.right_eye_submit) {
            return submittedEyeOptions.filter(o => o.value === "right");
        }
        else {
            return "";
        }

    }

    const onChangeDate = (date) => {
        let txtDate = '';

        if (date) {
            txtDate = date.getFullYear() + '-' + (date.getMonth()+1) + '-' + date.getDate();
        }

        setVisit({...visit, 'exam_date': txtDate})
    };

    const [submitErrorMessagesPopup, setSubmitErrorMessagesPopup] = useState([]);
    const closeSubmitErrorMessagesPopup = () => {
        setSubmitErrorMessagesPopup([]);
    }


    return (

        <React.Fragment>

        {submitErrorMessagesPopup.length > 0 && (
            <React.Fragment>
            <div className="modal-backdrop fade in"></div>
            <div id="alertModal" className="modal hide fade in" tabIndex="-1" role="dialog" aria-labelledby="myModalLabel" aria-hidden="false" style={{display: "block"}}>
                <div className="modal-header">
                    <button type="button" className="close" data-dismiss="modal" aria-hidden="true">×</button>
                    <h3 id="alert-modal-title">Warning!</h3>
                </div>
                <div className="modal-body"><br/>
                    <ul>
                    {submitErrorMessagesPopup.map( (msg, i) => (
                        <li key={i}>{msg}</li>
                    ))}
                    </ul>

                </div>

                <div className="modal-footer">
                    <button onClick={closeSubmitErrorMessagesPopup} data-dismiss="modal" id="alert-modal-cancel" aria-hidden="true" className="btn small grey align-center right">OK</button>
                </div>
            </div>
            </React.Fragment>
        )}

        {confirmStatus && (
            <React.Fragment>
            <div className="modal-backdrop fade in"></div>
            <div id="alertModal" className="modal hide fade in" tabIndex="-1" role="dialog" aria-labelledby="myModalLabel" aria-hidden="false" style={{display: "block"}}>
                <div className="modal-body"><br/>Do you really want to change the status of this exam to "{confirmStatus}"?<br/><br/>

                    {confirmStatus === 'Rejected' && (
                        <textarea
                            value={newStatusMessage}
                            placeholder="Please write the reasons for rejecting this exam"
                            className="form-comment"
                            onChange={(ev) => setNewStatusMessage(ev.target.value)}
                            name="reject-message" >
                        </textarea>
                    )}
                </div>

                <div className="modal-footer">
                    <button onClick={submitHandler} id="alert-modal-ok" aria-hidden="true" className="btn small orange align-center right">Yes</button>
                    <button onClick={closePopup} data-dismiss="modal" id="alert-modal-cancel" aria-hidden="true" className="btn small grey align-center right">No</button>
                </div>
            </div>
            </React.Fragment>
        )}

        <div className="home_header_container_class wide">
            <div className="content">
                <div className="breadcrumbs">
                    <div className="path">
                        <ul>
                            <li><span className="orange text-shadow">{visit.patient.study.acronym ? visit.patient.study.acronym.toUpperCase() : ''}</span></li>
                            <li className="separator text-shadow">&gt;</li>
                            <li><span className="orange text-shadow">{visit.study_exam.exam_type.name}</span></li>
                            <li className="separator text-shadow">&gt;</li>
                            <li><span className="orange text-shadow">Patient {visit.patient.patient_num}</span></li>
                            <li className="separator text-shadow">&gt;</li>
                            <li><span className="orange text-shadow">Visit {visit.scheduled_visit ? visit.scheduled_visit.name : visit.id}</span></li>
                            <li className="separator text-shadow">&gt;</li>
                            <li>Grade</li>
                        </ul>
                    </div>

                    {visit.patient.study.status === "open" && (
                    <Link
                        to={"/studies/" + props.match.params.id + "/center/" + visit.patient.center.center.id + "/patients/" + visit.patient.id}
                        className="btn normal grey right"
                    >
                            <span className="icon user-small"></span>Edit patient details
                    </Link>
                    )}

                </div> {/* .breadcrumbs */}

                <div className="report-info">

                    <div className="content-report" style={{overflow: 'unset'}}>
                        <div className="content-visit-exam" style={{overflow: 'unset'}}>

                            <div className="visit-content form-group">
                                <div className="main-form">
                                    <form onSubmit={submitHandler} id="register-exam" method="post">
                                        <div className="form-group">
                                            <div className="span5 insert-visit" style={{width:"46%"}}>

                                                <div className="row" style={{paddingTop: "4px"}}>
                                                    <div className="label">
                                                        <label>Patient Study Eye</label>
                                                    </div>
                                                    <div className="input">
                                                        <div className="study-eye">
                                                            <span className={"icon min-eye" + (visit.patient.study_right_eye ? "" : " enabled")}></span>
                                                            <span className={"icon min-eye" + (visit.patient.study_left_eye ? "" : " enabled")}></span>
                                                        </div>
                                                    </div>
                                                </div> {/* .row */}

                                                {isGeneralVarVisible('operator') && (
                                                <div className="row" style={{paddingTop: "4px"}}>
                                                    <div className="label">
                                                        <label>Operator</label>
                                                    </div>


                                                    <CorcSelect
                                                        options={operatorOptions}
                                                        name="operator"
                                                        id="operator"
                                                        value={operatorOptions.filter(o => o.value === visit.operator)}
                                                        onChange={selectedOption => onOperatorChangeHandler(selectedOption.value)}
                                                        isSearchable={false}
                                                        containerStyles={{width: "175px", float: "left"}}
                                                    />

                                                </div>
                                                )}

                                                {isGeneralVarVisible('submitted-eye') && (
                                                <div className="row" style={{paddingTop: "4px"}}>
                                                    <div className="label">
                                                        <label>Submitted Eye(s)</label>
                                                    </div>

                                                    <CorcSelect
                                                        options={submittedEyeOptions}
                                                        name="submitted_eye"
                                                        id="submitted_eye"
                                                        value={getCheckedSubmittedEye()}
                                                        onChange={selectedOption => onSubmittedEyeChangeHandler(selectedOption.value)}
                                                        isSearchable={false}
                                                        containerStyles={{width: "175px", float: "left"}}
                                                        isDisabled={""}
                                                    />


                                                </div>
                                                )}

                                                {isGeneralVarVisible('exam-date') && (
                                                <div className="row exam-date" style={{paddingTop: "4px"}}>
                                                    <div className="label">
                                                        <label>Exam Date</label>
                                                    </div>

                                                    <div className="input">
                                                        <DatePicker
                                                            dateFormat="dd/MM/yyyy"
                                                            name="exam_date"
                                                            selected={visit.exam_date && initDate(visit.exam_date)}
                                                            onChange={(ev) => onChangeDate(ev)}
                                                            disabled={false}
                                                        />
                                                    </div>
                                                </div>
                                                )}




                                            </div> {/* .span5 */}
                                            <div className="span5" style={{width: "54%"}}>
                                                {isGeneralVarVisible('comment') && (
                                                <textarea
                                                    className="submit-comment"
                                                    placeholder="Comment"
                                                    style={{height:"164px !important"}}
                                                    disabled="disabled"
                                                    value={visit.comment}
                                                ></textarea>
                                                )}
                                            </div> {/* .span5 */}

                                        </div>
                                        <div className="form-group visit-other-vars">
                                            <VisitOtherVars
                                                visit={visit}
                                                setVisit={setVisit}
                                            />
                                        </div>

                                    </form>

                                    <VisitFiles
                                        visit={visit}
                                        setVisit={setVisit}
                                        userAuth={props.userAuth}
                                    />

                                    <div className="form-group">
                                        <div className="row" style={{paddingTop: "4px"}}>
                                            <div className="label">
                                                <label htmlFor="status" className="">Grading Comment</label>
                                            </div>
                                            <textarea
                                                className="submit-comment"
                                                name="grading_comment"
                                                style={{height:"164px !important", width: "80%"}}
                                                value={visit.grading_comment}
                                                onChange={(ev) => onChangeHandler(ev)}
                                            ></textarea>
                                        </div>

                                        <div className="row" style={{paddingTop: "4px"}}>
                                            <div className="label">
                                                <label htmlFor="status" className="">Grading Internal Comment</label>
                                            </div>
                                            <textarea
                                                className="submit-comment"
                                                name="grading_internal_comment"
                                                style={{height:"164px !important", width: "80%"}}
                                                value={visit.grading_internal_comment}
                                                onChange={(ev) => onChangeHandler(ev)}
                                            ></textarea>
                                        </div>

                                        <div className="span5" style={{width: "33.5%", float: "right", marginBottom: "30px"}}>
                                            <div className="row" style={{paddingTop: "4px"}}>
                                                <div className="label">
                                                    <label htmlFor="status" className="required">Exam Status <span className="required"></span></label>
                                                </div>
                                                <CorcSelect
                                                    options={statusOptions}
                                                    name="status"
                                                    id="status"
                                                    value={statusOptions.filter(o => o.value === newStatus)}
                                                    onChange={selectedOption => onStatusChangeHandler(selectedOption.value)}
                                                    isSearchable={false}
                                                    containerStyles={{width: "175px", float: "left"}}
                                                />
                                            </div>
                                        </div>
                                    </div> {/* .form-group */}

                                    <div className="form-actions" style={{float: "right"}}>
                                        <React.Fragment>
                                        <Link
                                            to={'/studies/'+props.match.params.id+'/exams/'+props.match.params.exam}
                                            className="btn small grey align-center left"
                                        >Back</Link>
                                        <button
                                            name=""
                                            className="btn small orange align-center left"
                                            onClick={validateSubmit}
                                        >Submit</button>
                                        </React.Fragment>
                                    </div>



                                </div> {/* .main-form */}
                            </div> {/* .visit-content */}



                        </div> {/* .content-visit-exam */}
                    </div> {/* .content-report */}
                </div>

            </div>
        </div>

        </React.Fragment>
    );
}

export default ExamGrade;

function VisitFiles(props) {
    return (
        <React.Fragment>
            {props.visit.visit_files.map( (file, i) => (
            <VisitFileItem
                key={i}
                file={file}
                visit={props.visit}
            />
            ))}

        </React.Fragment>
    );
}


function VisitFileItem(props) {
//    const onChangeHandler = ev => {
//        let val = ev.target.value;
//        let name = ev.target.name;
//    };

    const style = {
        width: "40%",
        float: "right",
        marginRight: "32px",
        display: "block"
    }




    return (
        <div className="form-group">
            <div className="file-uploaded">
                <div className="row">
                    <a target="_blank" rel="noopener noreferrer" href={props.file ? process.env.REACT_APP_API_ENDPOINT+'/visits/'+props.visit.id+'/file/'+props.file.id+'/download/' : ''}  className="cancel-btn-class btn small grey align-center">Download</a>
                    <div className="file-name">
                        <span className="filename-class">{props.file ? props.file.name : ''}<b>{" (" + prettyBytes(props.file && props.file.file.size ? props.file.file.size : 0) + ")"}</b></span>
                    </div>

                   <div className="file-comment" style={style} ref={(node) => {
                      if (node) {
                        node.style.setProperty("float", "right", "important");
                      }
                    }}>
                        <input
                            maxLength="255"
                            type="text"
                            className="uploaded_file"
                            value={props.file ? props.file.description : ''}
                            placeholder="Comment"
                            //onChange={(ev) => onChangeHandler(ev)}
                            disabled="disabled"
                        />
                    </div>
                </div>
            </div>
        </div>
    );

}

function VisitOtherVars(props) {
    return (
        <React.Fragment>
        {props.visit.study_exam.technician_form_vars && props.visit.study_exam.technician_form_vars.map((v, i) => v.technician_form_var.group === 'other' && (
            <div key={i}>
                <VisitVarItem
                    field={v.technician_form_var}
                    visit={props.visit}
                    setVisit={props.setVisit}
                />
            </div>
        ))}
        </React.Fragment>
    );
}

function VisitVarItem(props) {

    const onListChangeHandler = (uid, selectedOption) => {
        let editedVisit = JSON.parse(JSON.stringify(props.visit));

        const form_var = editedVisit.study_exam.technician_form_vars.find(c => {
            return c.technician_form_var.uid === uid;
        });

        if (!form_var) {
            return false;
        }

        let found = editedVisit.form_vars.find(c => {
            if (c.var_type) {
                return form_var.uid === c.var_type.uid;
            }
            else {
                return undefined;
            }

        });

        if (found === undefined) {
            let cVar = {'visit': editedVisit.id, 'var_type': form_var, 'value': selectedOption};

            editedVisit.form_vars.push(cVar);
        }
        else {
            found.value = selectedOption;
        }

        props.setVisit(editedVisit);
    };

    const onChangeHandler = (ev) => {
        let name = ev.target.name;
        let val = ev.target.value.toString();
        let editedVisit = JSON.parse(JSON.stringify(props.visit));
        let tmp, tmp_array;

        let form_var = props.visit.study_exam.technician_form_vars.find(c => {
            return c.technician_form_var.uid === name;
        })

        if (!form_var) {
            return false;
        }
        else {
            form_var = form_var.technician_form_var;
        }

        let found = editedVisit.form_vars.find(c => {
            if (c.var_type) {
                return form_var.uid === c.var_type.uid;
            }
            else {
                return undefined;
            }

        });

        if (found === undefined) {
            let cVar = {'visit': editedVisit.id, 'var_type': form_var, 'value': val};

            editedVisit.form_vars.push(cVar);
        }
        else {
            if (form_var.field_type === 'check_many') {
                tmp_array = found.value.split(",");
                tmp = tmp_array.indexOf(val);
                if (tmp === -1) {
                    tmp_array.push(val);
                    found.value = tmp_array.toString();
                }
                else {
                    tmp_array.splice(tmp, 1);
                    // is it the last value?
                    if (!tmp_array.length) {
                        for(let i=editedVisit.form_vars.length-1;i>=0;--i) {
                            if(editedVisit.form_vars[i].var_type.uid === form_var.uid) {
                                editedVisit.form_vars.splice(i, 1);
                                break;
                            }
                        }
                    }
                    found.value = tmp_array.toString();
                }
            }
            else {
                found.value = val;
            }
        }

        props.setVisit(editedVisit);

    };

    const onChangeDate = (date) => {
        let txtDate = '';
        let editedVisit = JSON.parse(JSON.stringify(props.visit));

        if (date) {
            txtDate = date.getFullYear() + '-' + (date.getMonth()+1) + '-' + date.getDate();
        }

        return false;

    };

    const getVisitField = (field_id) => {

        let found = props.visit.form_vars.find(c => {
            if (c.var_type) {
                return c.var_type.id === field_id;
            }
            else {
                return undefined;
            }
        });

        if (found === undefined) {
            return "";
        }

        if(found.var_type.field_type === 'list') {
            let selOption = found.var_type.technician_vars_options.find(e => parseInt(e.id, 10) === parseInt(found.value, 10));
            return {label: selOption.value, value: selOption.id};
        }
        else {
            return found.value;
        }
    }

    const getMultipleVisitField = (field_id) => {

        let found = props.visit.form_vars.find(c => {
            if (c.var_type) {
                return c.var_type.id === field_id;
            }
            else {
                return undefined;
            }
        });

        if (found === undefined) {
            return [];
        }


        return found.value.split(",");
    }

    const buildField = (field) => {
        if(field.field_type === 'input') {
            return (
                <input
                    name={field.uid}
                    type="text"
                    value={getVisitField(field.id)}
                    onChange={(ev) => onChangeHandler(ev)}
                    disabled={true}
                />
            );
        }
        else if(field.field_type === 'date') {
            return (
                <input
                    name={field.uid}
                    selected={getVisitField(field.id)}
                    onChange={(date) => onChangeDate(date, field)}
                    disabled={true}
                />
            );
        }
        else if(field.field_type === 'text') {
            return (
                <textarea
                    name={field.uid}
                    value={getVisitField(field.id)}
                    onChange={(ev) => onChangeHandler(ev)}
                    disabled={true}
                >
                </textarea>
            );
        }
        else if(field.field_type === 'list') {
            let listOptions = [];
            field.technician_vars_options.map(opt => listOptions.push({label: opt.value, value: opt.id}));

            return (
                <CorcSelect
                    name={field.uid}
                    id={field.uid}
                    value={getVisitField(field.id)}
                    onChange={(selectedOption) => onListChangeHandler(field.uid, selectedOption.value)}
                    options={listOptions}
                    placeholder="-"
                    containerStyles={{width: "125px"}}
                    isDisabled={true}
                />
            );
        }
        else if(field.field_type === 'check_one') {

            return (
                <div className="checkbox-group check">
                {field.technician_vars_options.map((opt, i) => (
                    <React.Fragment
                        key={i}
                    >
                    <input
                        value={opt.id}
                        id={field.uid + '_' + i}
                        name={field.uid}
                        type="radio"
                        checked={getVisitField(field.id) === opt.id.toString()}
                        onChange={(ev) => onChangeHandler(ev)}
                        disabled={true}
                    />
                    <label htmlFor={field.uid + '_' + i}>{opt.value}</label>
                    </React.Fragment>
                ))}
                </div>
            );
        }
        else if(field.field_type === 'check_many') {

            return (
                <div className="checkbox-group check form-flex">
                {field.technician_vars_options.map((opt, i) => (
                    <div className="form-flex-3-col" key={i}>
                        <input
                            value={opt.id}
                            id={field.uid + '_' + i}
                            name={field.uid}
                            type="checkbox"
                            checked={getMultipleVisitField(field.id).indexOf(opt.id.toString()) !== -1}
                            onChange={(ev) => onChangeHandler(ev)}
                            disabled={true}
                        />
                        <label htmlFor={field.uid + '_' + i}>{opt.value}</label>
                    </div>
                ))}
                </div>
            );
        }

        else if(field.field_type === 'eye') {
            return (
                <div className="checkbox-group check">
                    <input
                        value="right"
                        id={field.uid + '_re'}
                        name={field.uid}
                        type="radio"
                        checked={getVisitField(field.id) === 'right'}
                        onChange={(ev) => onChangeHandler(ev)}
                        disabled={true}
                    />
                    <label htmlFor={field.uid + '_re'}>RE</label>
                    <input
                        value="left"
                        id={field.uid + '_le'}
                        name={field.uid}
                        type="radio"
                        checked={getVisitField(field.id) === 'left'}
                        onChange={(ev) => onChangeHandler(ev)}
                        disabled={true}
                    />
                    <label htmlFor={field.uid + '_le'}>LE</label>
                    <input
                        value="both"
                        id={field.uid + '_rle'}
                        name={field.uid}
                        type="radio"
                        checked={getVisitField(field.id) === 'both'}
                        onChange={(ev) => onChangeHandler(ev)}
                        disabled={true}
                    />
                    <label htmlFor={field.uid + '_rle'}>RLE</label>
                </div>
            );
        }
    };

    return (
        <div className="row" style={{paddingTop: "4px"}}>
            <div className="label">
                <label>{props.field.name}</label>
            </div>
            <div className="input">
                {buildField(props.field)}
            </div>
        </div>
    );
}

