import React, {useEffect, useState}  from 'react';
import axios, {post} from 'axios';
import {Link} from 'react-router-dom';
import CorcSelect from 'components/utils/CorcSelect';
import {set, subDays, subMonths, subYears, isAfter, parse} from 'date-fns'

function FileBrowser(props) {
    const [files, setFiles] = useState([]);
    const [initialFiles, setInitialFiles] = useState([]);

    useEffect(function() {
        const getFiles = async () => {
            let count = props.overlayVisible;
            props.setOverlayVisible( (count) => count + 1);

            await axios.get(process.env.REACT_APP_API_ENDPOINT+'/filebrowser/',
                { withCredentials: true, headers: {'Content-Type': 'application/json'}}).then(response => {

                setInitialFiles(response.data);
                filter(response.data);

                props.setOverlayVisible( (count) => count - 1);

            }, (error) => {
                props.setOverlayVisible( (count) => count - 1);
            });
        };

        props.setNavLinks([
            {title: 'File Browser', url: '/filebrowser', icon: 'menu-report'},
        ]);

        // do we have permissions?
        if (props.userAuth.user.role !== 'admin') {
            props.history.push('/studies');
            return;
        }

        // clear localstorage
        localStorage.setItem('corc_files_filteredByStudy', "");
        localStorage.setItem('corc_files_filteredByCenter', "");
        localStorage.setItem('corc_files_filteredByExam', "");
        localStorage.setItem('corc_files_filteredByPatient', "");
        localStorage.setItem('corc_files_filteredByType', "");
        localStorage.setItem('corc_files_filteredByDate', "week");

        localStorage.setItem('corc_files_sortStudy', "");
        localStorage.setItem('corc_files_sortCenter', "");
        localStorage.setItem('corc_files_sortExam', "");
        localStorage.setItem('corc_files_sortPatient', "");
        localStorage.setItem('corc_files_sortType', "");
        localStorage.setItem('corc_files_sortDate', "");
        localStorage.setItem('corc_files_sortName', "");

        getFiles();
    }, []);

    const filter = (files) => {

        let filteredFiles;
        let filteredByStudy = localStorage.getItem('corc_files_filteredByStudy');
        let filteredByCenter = localStorage.getItem('corc_files_filteredByCenter');
        let filteredByExam = localStorage.getItem('corc_files_filteredByExam');
        let filteredByPatient = localStorage.getItem('corc_files_filteredByPatient');
        let filteredByType = localStorage.getItem('corc_files_filteredByType');
        let filteredByDate = localStorage.getItem('corc_files_filteredByDate');

        if (files !== undefined) {
            filteredFiles = files;
        }
        else {
            filteredFiles = initialFiles;
        }

        if (filteredByStudy) {
            filteredFiles = filteredFiles.filter(l => l.study.id === parseInt(filteredByStudy,10));
        }

        if (filteredByCenter) {
            filteredFiles = filteredFiles.filter(l => l.center.id === parseInt(filteredByCenter,10));
        }

        if (filteredByExam) {
            filteredFiles = filteredFiles.filter(l => l.exam.id === parseInt(filteredByExam,10));
        }

        if (filteredByPatient) {
            filteredFiles = filteredFiles.filter(l => l.patient.id === parseInt(filteredByPatient,10));
        }

        if (filteredByType) {
            filteredFiles = filteredFiles.filter(l => l.type === filteredByType);
        }

        if (filteredByDate) {
            let fDate;
            if(filteredByDate === 'week') {
                fDate = subDays(set(new Date(), {hours: 0, minutes:0, seconds: 0, milliseconds: 0}), 7);
            }
            else if(filteredByDate === 'month') {
                fDate = subMonths(set(new Date(), {hours: 0, minutes:0, seconds: 0, milliseconds: 0}), 1);
            }
            else if(filteredByDate === '3_months') {
                fDate = subMonths(set(new Date(), {hours: 0, minutes:0, seconds: 0, milliseconds: 0}), 3);
            }
            else if(filteredByDate === '6_months') {
                fDate = subMonths(set(new Date(), {hours: 0, minutes:0, seconds: 0, milliseconds: 0}), 6);
            }
            else if(filteredByDate === 'year') {
                fDate = subYears(set(new Date(), {hours: 0, minutes:0, seconds: 0, milliseconds: 0}), 1);
            }
            else {
                fDate = new Date(1970, 1, 1);
            }

            filteredFiles = filteredFiles.filter(l => isAfter(set(parse(l.uploaded, 'dd-MM-yyyy HH:mm', new Date()), {seconds: 0, milliseconds: 0}), fDate));

        }

        setFiles(filteredFiles);
    }

    const forceDownload = async response => {
        const blob = new Blob([response.data], {type: response.data.type});
        const url = window.URL.createObjectURL(blob);
        const link = document.createElement('a');
        link.href = url;

        const contentDisposition = response.headers['content-disposition'];
        let fileName = 'files';
        if (contentDisposition) {
            const fileNameMatch = contentDisposition.match(/filename="(.+)"/);
            if (fileNameMatch.length === 2)
                fileName = fileNameMatch[1];
        }

        link.setAttribute('download', fileName);
        document.body.appendChild(link);
        link.click();
        link.remove();
        window.URL.revokeObjectURL(url);
    };

    const downloadItems = async () => {

        const errMsg = "Sorry, something went wrong when downloading these files. Please contact your IT helpdesk.";

        let filesToDownload = [];
        document.querySelectorAll('input[class="file-item"]:checked').forEach(el => {
            filesToDownload.push({type: el.dataset.type, id: el.dataset.id});
        });

        if (!filesToDownload.length) {
            return false;
        }

        props.setOverlayVisible( (count) => count + 1);

        await axios.post(process.env.REACT_APP_API_ENDPOINT+'/filebrowser/', filesToDownload, { withCredentials: true})
            .then(async (response) => {
                if (response.status === 200) {
                    //await forceDownload(response).then(() => {
                    //    props.setOverlayVisible( (count) => count - 1);
                    //});
                    props.setOverlayVisible( (count) => count - 1);
                    window.location.href = process.env.REACT_APP_API_ENDPOINT +response.data;

                }
                else {
                    alert(errMsg);
                    props.setOverlayVisible( (count) => count - 1);
                }

            }, (error) => {
                props.setOverlayVisible( (count) => count - 1);
                alert(errMsg);
            });

    }

    return (
        <div className="home_header_container_class wide">
            <div className="content">
                <div className="breadcrumbs">
                    <div className="path">
                        <ul>
                            <li></li>
                        </ul>
                    </div>

                    <div className="sub-menu">
                        <ul className="button-group right"></ul>

                    </div>
                </div> {/* .breadcrumbs */}

                <div className="top-buttons all-users">
                    <ul className="button-group right">
                        <li>
                            <StudyDropdownFilter initialFiles={initialFiles} filter={filter}/>
                        </li>
                        <li>
                            <CenterDropdownFilter initialFiles={initialFiles} filter={filter}/>
                        </li>
                        <li>
                            <ExamDropdownFilter initialFiles={initialFiles} filter={filter}/>
                        </li>
                        <li>
                            <PatientDropdownFilter initialFiles={initialFiles} filter={filter}/>
                        </li>
                        <li>
                            <TypeDropdownFilter filter={filter}/>
                        </li>
                        <li>
                            <DateDropdownFilter filter={filter}/>
                        </li>

                        <li>
                            <button onClick={() => downloadItems()} className="btn normal grey right">
                                <span className="icon icon-list report"></span>
                                Download items
                            </button>
                        </li>

                    </ul>
                </div>


                <FileList
                    files={files}
                    setFiles={setFiles}
                />
            </div>
        </div>

    );
}

export default FileBrowser;

function StudyDropdownFilter(props) {
    let studyTmp = [];

    const filterByStudy = val => {

        if (val === '-1') {
            localStorage.setItem('corc_files_filteredByStudy', "");
        }
        else {
            localStorage.setItem('corc_files_filteredByStudy', val);
        }

        props.filter();

        return false;
    }

    const studyOptions = [{label: 'All Studies', value: '-1'}];
    props.initialFiles.map( (file) => {

        if (file.study && studyTmp.indexOf(file.study.id) === -1) {
            studyTmp.push(file.study.id);
            studyOptions.push({label: file.study.acronym, value: file.study.id});
        }

        return false;
    });

    return (
        <CorcSelect
            id="study-select"
            containerStyles={{width: "165px"}}
            name="study"
            onChange={selectedOption => filterByStudy(selectedOption.value) }
            options={studyOptions}
            isSearchable={true}
            defaultValue={{label: 'All Studies', value: '-1'}}
        />
    );

}

function CenterDropdownFilter(props) {
    let tmp = [];

    const filterByCenter = val => {
        if (val === '-1') {
            localStorage.setItem('corc_files_filteredByCenter', "");
        }
        else {
            localStorage.setItem('corc_files_filteredByCenter', val);
        }

        props.filter();

        return false;
    }

    const centerOptions = [{label: 'All Centers', value: '-1'}];
    props.initialFiles.map( (file) => {

        if (file.center && tmp.indexOf(file.center.id) === -1) {
            tmp.push(file.center.id);
            centerOptions.push({label: file.center.acronym, value: file.center.id});
        }

        return false;
    });

    return (
        <CorcSelect
            id="center-select"
            containerStyles={{width: "165px"}}
            name="center"
            onChange={selectedOption => filterByCenter(selectedOption.value) }
            options={centerOptions}
            isSearchable={true}
            defaultValue={{label: 'All Centers', value: '-1'}}
        />
    );
}

function ExamDropdownFilter(props) {
    let tmp = [];

    const filterByExam = val => {
        if (val === '-1') {
            localStorage.setItem('corc_files_filteredByExam', "");
        }
        else {
            localStorage.setItem('corc_files_filteredByExam', val);
        }

        props.filter();

        return false;
    }

    const examOptions = [{label: 'All Exams', value: '-1'}];
    props.initialFiles.map( (file) => {

        if (file.exam && tmp.indexOf(file.exam.id) === -1) {
            tmp.push(file.exam.id);
            examOptions.push({label: file.exam.name, value: file.exam.id});
        }

        return false;
    });

    return (
        <CorcSelect
            id="exam-select"
            containerStyles={{width: "165px"}}
            name="exam"
            onChange={selectedOption => filterByExam(selectedOption.value) }
            options={examOptions}
            isSearchable={false}
            defaultValue={{label: 'All Exams', value: '-1'}}
        />
    );
}

function PatientDropdownFilter(props) {
    let tmp = [];

    const filterByPatient = val => {
        if (val === '-1') {
            localStorage.setItem('corc_files_filteredByPatient', "");
        }
        else {
            localStorage.setItem('corc_files_filteredByPatient', val);
        }

        props.filter();

        return false;
    }

    const patientOptions = [{label: 'All Patients', value: '-1'}];
    props.initialFiles.map( (file) => {

        if (file.patient && tmp.indexOf(file.patient.id) === -1) {
            tmp.push(file.patient.id);
            patientOptions.push({label: file.patient.name, value: file.patient.id});
        }

        return false;
    });

    return (
        <CorcSelect
            id="patient-select"
            containerStyles={{width: "165px"}}
            name="patient"
            onChange={selectedOption => filterByPatient(selectedOption.value) }
            options={patientOptions}
            isSearchable={true}
            defaultValue={{label: 'All Patients', value: '-1'}}
        />
    );
}

function TypeDropdownFilter(props) {

    const filterByType = val => {
        if (val === '-1') {
            localStorage.setItem('corc_files_filteredByType', "");
        }
        else {
            localStorage.setItem('corc_files_filteredByType', val);
        }

        props.filter();

        return false;
    }

    const typeOptions = [
        {label: 'All Types', value: '-1'},
        {label: 'Study Document', value: 'study_document'},
        {label: 'Exam', value: 'exam'},
        {label: 'Technician Certification', value: 'technician_certification'},
        {label: 'Equipment Certification', value: 'equipment_certification'},
    ];

    return (
        <CorcSelect
            id="type-select"
            containerStyles={{width: "165px"}}
            name="type"
            onChange={selectedOption => filterByType(selectedOption.value) }
            options={typeOptions}
            isSearchable={false}
            defaultValue={{label: 'All Types', value: '-1'}}
        />
    );
}

function DateDropdownFilter(props) {

    const filterByDate = val => {
        if (val === '-1') {
            localStorage.setItem('corc_files_filteredByDate', "");
        }
        else {
            localStorage.setItem('corc_files_filteredByDate', val);
        }

        props.filter();

        return false;
    }

    const dateOptions = [
        {label: 'Last week', value: 'week'},
        {label: 'Last month', value: 'month'},
        {label: 'Last 3 months', value: '3_months'},
        {label: 'Last 6 months', value: '6_months'},
        {label: 'Last Year', value: 'year'},
        {label: 'All Time', value: '-1'},
    ];

    return (
        <CorcSelect
            id="date-select"
            containerStyles={{width: "165px"}}
            name="date"
            onChange={selectedOption => filterByDate(selectedOption.value) }
            options={dateOptions}
            isSearchable={false}
            defaultValue={{label: 'Last week', value: 'week'}}
        />
    );
}

function FileList(props) {
    const toggleStudySort = () => {

        const files = JSON.parse(JSON.stringify(props.files));
        let sort = localStorage.getItem('corc_files_sortStudy');

        if (sort === ">") {
            files.sort((a, b) => {

                a = a.study ? a.study.acronym.toLowerCase() : '';
                b = b.study ? b.study.acronym.toLowerCase() : '';

                return a < b ? 1 : -1;
            });
            localStorage.setItem('corc_files_sortStudy', "<")
        }
        else {

            files.sort((a, b) => {

                a = a.study ? a.study.acronym.toLowerCase() : '';
                b = b.study ? b.study.acronym.toLowerCase() : '';

                return a > b ? 1 : -1;
            });

            localStorage.setItem('corc_files_sortStudy', ">")
        }

        props.setFiles(files);
    }

    const toggleCenterSort = () => {

        const files = JSON.parse(JSON.stringify(props.files));
        let sort = localStorage.getItem('corc_files_sortCenter');

        if (sort === ">") {

            files.sort((a, b) => {

                a = a.center ? a.center.acronym.toLowerCase() : '';
                b = b.center ? b.center.acronym.toLowerCase() : '';

                return a < b ? 1 : -1;
            });

            localStorage.setItem('corc_files_sortCenter', "<")
        }
        else {

            files.sort((a, b) => {

                a = a.center ? a.center.acronym.toLowerCase() : '';
                b = b.center ? b.center.acronym.toLowerCase() : '';

                return a > b ? 1 : -1;
            });

            localStorage.setItem('corc_files_sortCenter', ">")
        }

        props.setFiles(files);
    }

    const toggleExamSort = () => {

        const files = JSON.parse(JSON.stringify(props.files));
        let sort = localStorage.getItem('corc_files_sortExam');

        if (sort === ">") {

            files.sort((a, b) => {

                a = a.exam ? a.exam.name.toLowerCase() : '';
                b = b.exam ? b.exam.name.toLowerCase() : '';

                return a < b ? 1 : -1;
            });

            localStorage.setItem('corc_files_sortExam', "<")
        }
        else {

            files.sort((a, b) => {

                a = a.exam ? a.exam.name.toLowerCase() : '';
                b = b.exam ? b.exam.name.toLowerCase() : '';

                return a > b ? 1 : -1;
            });

            localStorage.setItem('corc_files_sortExam', ">")
        }

        props.setFiles(files);
    }

    const togglePatientSort = () => {

        const files = JSON.parse(JSON.stringify(props.files));
        let sort = localStorage.getItem('corc_files_sortPatient');

        if (sort === ">") {

            files.sort((a, b) => {

                a = a.patient ? a.patient.name.toLowerCase() : '';
                b = b.patient ? b.patient.name.toLowerCase() : '';

                return a < b ? 1 : -1;
            });

            localStorage.setItem('corc_files_sortPatient', "<")
        }
        else {

            files.sort((a, b) => {

                a = a.patient ? a.patient.name.toLowerCase() : '';
                b = b.patient ? b.patient.name.toLowerCase() : '';

                return a > b ? 1 : -1;
            });

            localStorage.setItem('corc_files_sortPatient', ">")
        }

        props.setFiles(files);
    }

    const toggleTypeSort = () => {

        const files = JSON.parse(JSON.stringify(props.files));
        let sort = localStorage.getItem('corc_files_sortType');

        if (sort === ">") {
            files.sort((a, b) => (a.type.toLowerCase() < b.type.toLowerCase()) ? 1 : -1)
            localStorage.setItem('corc_files_sortType', "<")
        }
        else {
            files.sort((a, b) => (a.type.toLowerCase() > b.type.toLowerCase()) ? 1 : -1)
            localStorage.setItem('corc_files_sortType', ">")
        }

        props.setFiles(files);
    }

    const toggleDateSort = () => {

        const files = JSON.parse(JSON.stringify(props.files));
        let sort = localStorage.getItem('corc_files_sortDate');

        if (sort === ">") {
            files.sort((a, b) => ( parse(a.uploaded, 'dd-MM-yyyy', new Date()) < parse(b.uploaded, 'dd-MM-yyyy', new Date()) ) ? 1 : -1 );
            localStorage.setItem('corc_files_sortDate', "<");
        }
        else {
            files.sort((a, b) => ( parse(a.uploaded, 'dd-MM-yyyy', new Date()) > parse(b.uploaded, 'dd-MM-yyyy', new Date()) ) ? 1 : -1 );
            localStorage.setItem('corc_files_sortDate', ">");
        }

        props.setFiles(files);
    }

    const toggleNameSort = () => {

        const files = JSON.parse(JSON.stringify(props.files));
        let sort = localStorage.getItem('corc_files_sortName');

        if (sort === ">") {
            files.sort((a, b) => (a.name.toLowerCase() < b.name.toLowerCase()) ? 1 : -1)
            localStorage.setItem('corc_files_sortName', "<")
        }
        else {
            files.sort((a, b) => (a.name.toLowerCase() > b.name.toLowerCase()) ? 1 : -1)
            localStorage.setItem('corc_files_sortName', ">")
        }

        props.setFiles(files);
    }

    const toggleAllItems = (ev) => {

        document.querySelectorAll('.file-item').forEach(function(el) {
            el.checked = ev.target.checked;
        })

    }

    return (
        <div className="visits-list list">
            <div className="list-head">
                <table>
                    <tbody>
                        <tr>

                            <th width="4%">
                                <input
                                    key="select-all"
                                    style={{padding: 0, opacity: 1}}
                                    type="checkbox"
                                    onChange={(ev) => toggleAllItems(ev)}
                                />
                            </th>

                            <th width="15%">
                                <button
                                    style={{padding: 0}}
                                    onClick={toggleStudySort}
                                ><b>Study</b></button>
                            </th>
                            <th width="15%">
                                <button
                                    style={{padding: 0}}
                                    onClick={toggleCenterSort}
                                >Center</button>
                            </th>
                            <th width="10%">
                                <button
                                    style={{padding: 0}}
                                    onClick={toggleExamSort}
                                >Exam</button>
                            </th>
                            <th width="10%">
                                <button
                                    style={{padding: 0}}
                                    onClick={togglePatientSort}
                                >Patient Num</button>
                            </th>
                            <th width="11%">
                                <button
                                    style={{padding: 0}}
                                    onClick={toggleTypeSort}
                                >File Type</button>
                            </th>
                            <th width="10%">
                                <button
                                    style={{padding: 0}}
                                    onClick={toggleDateSort}
                                >Uploaded</button>
                            </th>
                            <th width="25%">
                                <button
                                    style={{padding: 0}}
                                    onClick={toggleNameSort}
                                >File Name</button>
                            </th>
                            <th>
                            </th>
                        </tr>
                    </tbody>
                </table>
            </div>
            <div className="list-body">
                <table id="list-table">
                    <tbody className="list-visits">
                        {props.files.map((file, i) => (
                            <FileItem
                                key={i}
                                file={file}
                            />
                        ))}
                    </tbody>
                </table>
            </div>
        </div>
    );
}

function FileItem(props) {

    const displayType = (type) => {

        if (type == 'study_document') {
            return "Study Document";
        }
        else if (type == 'technician_certification') {
            return "Technician Certification";
        }
        else if (type == 'equipment_certification') {
            return "Equipment Certification";
        }
        if (type == 'exam') {
            return "Exam";
        }
    };


    return (
        <tr className="visit">
            <td width="4%"><input className="file-item" data-type={props.file.type} data-id={props.file.id} style={{opacity: 1}} type="checkbox"/></td>
            <td width="15%" style={{wordBreak: 'break-word'}}>{props.file.study ? props.file.study.acronym : '-'}</td>
            <td width="15%" style={{wordBreak: 'break-word'}}>{props.file.center ? props.file.center.acronym : '-'}</td>
            <td width="10%">{props.file.exam ? props.file.exam.name : '-'}</td>
            <td width="10%">{props.file.patient ? props.file.patient.name : '-'}</td>
            <td width="11%">{displayType(props.file.type)}</td>
            <td width="10%">{props.file.uploaded}</td>
            <td width="21%" style={{wordBreak: 'break-word'}}>{props.file.name}</td>
            <td width="4%" className="btn-list">
                <ul>
                    <li>
                        <a download rel="noopener noreferrer" href={props.file.url} target="_blank" className="btn xsmall grey align-center">
                            <span className="study-visit-icon download-small">&nbsp;</span>
                        </a>
                    </li>
                </ul>
            </td>
        </tr>
    );
}
