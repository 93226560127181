import React, {useEffect, useState}  from 'react';
import axios from 'axios';
import CorcSelect from 'components/utils/CorcSelect';
import {set, subDays, subMonths, subYears, isAfter, parse} from 'date-fns';
import {Link} from 'react-router-dom';
import ReactHtmlParser from 'react-html-parser'

function CertificationBrowser(props) {
    const [techCertifications, setTechCertifications] = useState([]);
    const [initialTechCertifications, setInitialTechCertifications] = useState([]);
    const [equipCertifications, setEquipCertifications] = useState([]);
    const [initialEquipCertifications, setInitialEquipCertifications] = useState([]);

    useEffect(function() {
        const getCertifications = async () => {
            let count = props.overlayVisible;
            props.setOverlayVisible( (count) => count + 1);

            await axios.get(process.env.REACT_APP_API_ENDPOINT+'/certificationbrowser/',
                { withCredentials: true, headers: {'Content-Type': 'application/json'}}).then(response => {

                setInitialTechCertifications(response.data.technician_certs);
                setInitialEquipCertifications(response.data.equipment_certs);

                filterTechs(response.data.technician_certs);
                filterEquips(response.data.equipment_certs);

                props.setOverlayVisible( (count) => count - 1);

            }, (error) => {
                props.setOverlayVisible( (count) => count - 1);
            });

        }

        props.setNavLinks([
            {title: 'Certifications', url: '/certificationbrowser', icon: 'menu-report'},
        ]);

        // do we have permissions?
        if (props.userAuth.user.role !== 'admin' && props.userAuth.user.role !== 'grader') {
            props.history.push('/studies');
            return;
        }

        // clear localstorage
        localStorage.setItem('corc_tech_filteredByUser', "");
        localStorage.setItem('corc_tech_filteredByExam', "");
        localStorage.setItem('corc_tech_filteredByDate', "6_months");
        localStorage.setItem('corc_tech_filteredByState', "");

        localStorage.setItem('corc_equip_filteredByCenter', "");
        localStorage.setItem('corc_equip_filteredByExam', "");
        localStorage.setItem('corc_equip_filteredByState', "");
        localStorage.setItem('corc_equip_filteredByDate', "week");

        getCertifications();

    }, []);

    const filterTechs = (techCerts) => {

        let filteredTechs;
        let filteredByUser = localStorage.getItem('corc_tech_filteredByUser');
        let filteredByCenter = localStorage.getItem('corc_tech_filteredByCenter');
        let filteredByExam = localStorage.getItem('corc_tech_filteredByExam');
        let filteredByDate = localStorage.getItem('corc_tech_filteredByDate');
        let filteredByState = localStorage.getItem('corc_tech_filteredByState');
        let filteredByStudy = localStorage.getItem('corc_tech_filteredByStudy');

        if (techCerts !== undefined) {
            filteredTechs = techCerts;
        }
        else {
            filteredTechs = initialTechCertifications;
        }

        if (filteredByUser) {
            filteredTechs = filteredTechs.filter(l => l.technician.id === parseInt(filteredByUser,10));
        }

        if (filteredByCenter) {
            filteredTechs = filteredTechs.filter(l => l.center.id === parseInt(filteredByCenter,10));
        }

        if (filteredByExam) {
            filteredTechs = filteredTechs.filter(l => l.exam.id === parseInt(filteredByExam,10));
        }

        if (filteredByStudy) {
            filteredTechs = filteredTechs.filter(l => l.study.id === parseInt(filteredByStudy,10));
        }

        if (filteredByState) {
            filteredTechs = filteredTechs.filter(l => l.status === filteredByState);
        }

        if (filteredByDate) {
            let fDate;
            if(filteredByDate === 'week') {
                fDate = subDays(set(new Date(), {hours: 0, minutes:0, seconds: 0, milliseconds: 0}), 7);
            }
            else if(filteredByDate === 'month') {
                fDate = subMonths(set(new Date(), {hours: 0, minutes:0, seconds: 0, milliseconds: 0}), 1);
            }
            else if(filteredByDate === '3_months') {
                fDate = subMonths(set(new Date(), {hours: 0, minutes:0, seconds: 0, milliseconds: 0}), 3);
            }
            else if(filteredByDate === '6_months') {
                fDate = subMonths(set(new Date(), {hours: 0, minutes:0, seconds: 0, milliseconds: 0}), 6);
            }
            else if(filteredByDate === 'year') {
                fDate = subYears(set(new Date(), {hours: 0, minutes:0, seconds: 0, milliseconds: 0}), 1);
            }
            else {
                fDate = new Date(1970, 1, 1);
            }

            filteredTechs = filteredTechs.filter(l => isAfter(set(parse(l.submission_date, 'dd-MM-yyyy HH:mm', new Date()), {hours: 0, minutes:0, seconds: 0, milliseconds: 0}), fDate));
        }

        setTechCertifications(filteredTechs);
    };

    const filterEquips = (equipCerts) => {

        let filteredEquips;
        let filteredByCenter = localStorage.getItem('corc_equip_filteredByCenter');
        let filteredByExam = localStorage.getItem('corc_equip_filteredByExam');
        let filteredByDate = localStorage.getItem('corc_equip_filteredByDate');
        let filteredByState = localStorage.getItem('corc_equip_filteredByState');
        let filteredByStudy = localStorage.getItem('corc_equip_filteredByStudy');

        if (equipCerts !== undefined) {
            filteredEquips = equipCerts;
        }
        else {
            filteredEquips = initialEquipCertifications;
        }

        if (filteredByCenter) {
            filteredEquips = filteredEquips.filter(l => l.center.id === parseInt(filteredByCenter,10));
        }

        if (filteredByExam) {
            filteredEquips = filteredEquips.filter(l => l.exam.id === parseInt(filteredByExam,10));
        }

        if (filteredByStudy) {
            filteredEquips = filteredEquips.filter(l => l.study.id === parseInt(filteredByStudy,10));
        }

        if (filteredByState) {
            filteredEquips = filteredEquips.filter(l => l.status === filteredByState);
        }

        if (filteredByDate) {
            let fDate;
            if(filteredByDate === 'week') {
                fDate = subDays(set(new Date(), {hours: 0, minutes:0, seconds: 0, milliseconds: 0}), 7);
            }
            else if(filteredByDate === 'month') {
                fDate = subMonths(set(new Date(), {hours: 0, minutes:0, seconds: 0, milliseconds: 0}), 1);
            }
            else if(filteredByDate === '3_months') {
                fDate = subMonths(set(new Date(), {hours: 0, minutes:0, seconds: 0, milliseconds: 0}), 3);
            }
            else if(filteredByDate === '6_months') {
                fDate = subMonths(set(new Date(), {hours: 0, minutes:0, seconds: 0, milliseconds: 0}), 6);
            }
            else if(filteredByDate === 'year') {
                fDate = subYears(set(new Date(), {hours: 0, minutes:0, seconds: 0, milliseconds: 0}), 1);
            }
            else {
                fDate = new Date(1970, 1, 1);
            }

            filteredEquips = filteredEquips.filter(function(l) {

                let d = set(parse(l.submission_date, 'dd-MM-yyyy HH:mm', new Date()), {hours: 0, minutes:0, seconds: 0, milliseconds: 0});
                if (isAfter(d, fDate)) {
                    return l;
                }

            });
        }

        setEquipCertifications(filteredEquips);
    };

    return (
        <div className="home_header_container_class wide">
            <div className="content">

                <TechnicianCertificationsList
                    techCertifications={techCertifications}
                    setTechCertifications={setTechCertifications}
                    initialTechCertifications={initialTechCertifications}
                    filterTechs={filterTechs}
                />

                <EquipmentCertificationsList
                    equipCertifications={equipCertifications}
                    setEquipCertifications={setEquipCertifications}
                    initialEquipCertifications={initialEquipCertifications}
                    filterEquips={filterEquips}
                />
            </div>
        </div>
    );
}

export default CertificationBrowser;

function TechnicianCertificationsList(props) {
    const toggleUserSort = () => {
        const techs = JSON.parse(JSON.stringify(props.techCertifications));
        let sort = localStorage.getItem('corc_tech_sortUser');

        if (sort === ">") {

            techs.sort((a, b) => {

                a = a.technician ? a.technician.username.toLowerCase() : '';
                b = b.technician ? b.technician.username.toLowerCase() : '';
                return a < b ? 1 : -1;
            });

            localStorage.setItem('corc_tech_sortUser', "<")
        }
        else {

            techs.sort((a, b) => {

                a = a.technician ? a.technician.username.toLowerCase() : '';
                b = b.technician ? b.technician.username.toLowerCase() : '';
                return a > b ? 1 : -1;
            });

            localStorage.setItem('corc_tech_sortUser', ">")
        }

        props.setTechCertifications(techs);
    };

    const toggleCenterSort = () => {

        const techs = JSON.parse(JSON.stringify(props.techCertifications));
        let sort = localStorage.getItem('corc_tech_sortCenter');

        if (sort === ">") {

            techs.sort((a, b) => {

                a = a.center ? a.center.site_num.toLowerCase() : '';
                b = b.center ? b.center.site_num.toLowerCase() : '';
                return a < b ? 1 : -1;
            });

            localStorage.setItem('corc_tech_sortCenter', "<")
        }
        else {

            techs.sort((a, b) => {

                a = a.center ? a.center.site_num.toLowerCase() : '';
                b = b.center ? b.center.site_num.toLowerCase() : '';
                return a > b ? 1 : -1;
            });

            localStorage.setItem('corc_tech_sortCenter', ">")
        }

        props.setTechCertifications(techs);
    }

    const toggleExamSort = () => {

        const techs = JSON.parse(JSON.stringify(props.techCertifications));
        let sort = localStorage.getItem('corc_tech_sortExam');

        if (sort === ">") {

            techs.sort((a, b) => {

                a = a.exam ? a.exam.name.toLowerCase() : '';
                b = b.exam ? b.exam.name.toLowerCase() : '';
                return a < b ? 1 : -1;
            });

            localStorage.setItem('corc_tech_sortExam', "<")
        }
        else {

            techs.sort((a, b) => {

                a = a.exam ? a.exam.name.toLowerCase() : '';
                b = b.exam ? b.exam.name.toLowerCase() : '';
                return a > b ? 1 : -1;
            });

            localStorage.setItem('corc_tech_sortExam', ">")
        }

        props.setTechCertifications(techs);
    }

    const toggleDateSort = () => {

        const techs = JSON.parse(JSON.stringify(props.techCertifications));
        let sort = localStorage.getItem('corc_tech_sortDate');

        if (sort === ">") {
            techs.sort((a, b) => {

                a = a.grading_date ? parse(a.grading_date, 'dd-MM-yyyy HH:mm', new Date()) : '';
                b = b.grading_date ? parse(b.grading_date, 'dd-MM-yyyy HH:mm', new Date()) : '';

                return a < b ? 1 : -1;

            });

            localStorage.setItem('corc_tech_sortDate', "<");
        }
        else {

            techs.sort((a, b) => {

                a = a.grading_date ? parse(a.grading_date, 'dd-MM-yyyy HH:mm', new Date()) : '';
                b = b.grading_date ? parse(b.grading_date, 'dd-MM-yyyy HH:mm', new Date()) : '';

                return a > b ? 1 : -1;

            });

            localStorage.setItem('corc_tech_sortDate', ">");
        }

        props.setTechCertifications(techs);
    }

    const toggleLatestExam = () => {

        const techs = JSON.parse(JSON.stringify(props.techCertifications));
        let sort = localStorage.getItem('corc_tech_sortLatestExam');

        if (sort === ">") {
            techs.sort((a, b) => {

                a = a.latest_exam ? parse(a.latest_exam, 'dd-MM-yyyy', new Date()) : '';
                b = b.latest_exam ? parse(b.latest_exam, 'dd-MM-yyyy', new Date()) : '';

                return a < b ? 1 : -1;

            });

            localStorage.setItem('corc_tech_sortLatestExam', "<");
        }
        else {

            techs.sort((a, b) => {

                a = a.latest_exam ? parse(a.latest_exam, 'dd-MM-yyyy', new Date()) : '';
                b = b.latest_exam ? parse(b.latest_exam, 'dd-MM-yyyy', new Date()) : '';

                return a > b ? 1 : -1;

            });

            localStorage.setItem('corc_tech_sortLatestExam', ">");
        }

        props.setTechCertifications(techs);
    }

    const toggleStatusSort = () => {
        const techs = JSON.parse(JSON.stringify(props.techCertifications));
        let sort = localStorage.getItem('corc_tech_sortStatus');

        if (sort === ">") {
            techs.sort((a, b) => (a.status.toLowerCase() < b.status.toLowerCase()) ? 1 : -1)
            localStorage.setItem('corc_tech_sortStatus', "<")
        }
        else {
            techs.sort((a, b) => (a.status.toLowerCase() > b.status.toLowerCase()) ? 1 : -1)
            localStorage.setItem('corc_tech_sortStatus', ">")
        }

        props.setTechCertifications(techs);
    };

    const toggleStudySort = () => {

        const techs = JSON.parse(JSON.stringify(props.techCertifications));
        let sort = localStorage.getItem('corc_tech_sortStudy');

        if (sort === ">") {

            techs.sort((a, b) => {

                a = a.study ? a.study.acronym.toLowerCase() : '';
                b = b.study ? b.study.acronym.toLowerCase() : '';

                return a < b ? 1 : -1;
            });

            localStorage.setItem('corc_tech_sortStudy', "<")
        }
        else {

            techs.sort((a, b) => {

                a = a.study ? a.study.acronym.toLowerCase() : '';
                b = b.study ? b.study.acronym.toLowerCase() : '';
                return a > b ? 1 : -1;
            });

            localStorage.setItem('corc_tech_sortStudy', ">")
        }

        props.setTechCertifications(techs);
    }

    const toggleStudyStateSort = () => {
        const techs = JSON.parse(JSON.stringify(props.techCertifications));
        let sort = localStorage.getItem('corc_tech_sortStudyState');

        if (sort === ">") {

            techs.sort((a, b) => {

                a = a.study ? a.study.status.toLowerCase() : '';
                b = b.study ? b.study.status.toLowerCase() : '';
                return a < b ? 1 : -1;
            });

            localStorage.setItem('corc_tech_sortStudyState', "<")
        }
        else {

            techs.sort((a, b) => {

                a = a.study ? a.study.status.toLowerCase() : '';
                b = b.study ? b.study.status.toLowerCase() : '';
                return a > b ? 1 : -1;
            });

            localStorage.setItem('corc_tech_sortStudyState', ">")
        }

        props.setTechCertifications(techs);
    };

    return (
        <React.Fragment>
            <div className="breadcrumbs">
                <div className="path">
                    <ul>
                        <li>Technicians</li>
                    </ul>
                </div>

                <div className="sub-menu">
                    <ul className="button-group right"></ul>
                </div>
            </div> {/* .breadcrumbs */}
            <TechnicianCertificationsFilters
                filterTechs={props.filterTechs}
                initialTechCertifications={props.initialTechCertifications}
            />
            <div className="visits-list list">
                <div className="list-head">
                    <table>
                        <tbody>
                            <tr>
                                <th width="10%">
                                    <button
                                        style={{padding: 0}}
                                        onClick={toggleUserSort}
                                    >Username</button>
                                </th>
                                <th width="15%">
                                    <button
                                        style={{padding: 0}}
                                        onClick={toggleCenterSort}
                                    >Center</button>
                                </th>
                                <th width="12%">
                                    <button
                                        style={{padding: 0}}
                                        onClick={toggleExamSort}
                                    >Exam</button>
                                </th>
                                <th width="12%">
                                    <button
                                        style={{padding: 0}}
                                        onClick={toggleStatusSort}
                                    >Status</button>
                                </th>
                                <th width="10%">
                                    <button
                                        style={{padding: 0}}
                                        onClick={toggleDateSort}
                                    >Graded</button>
                                </th>
                                <th width="10%">
                                    <button
                                        style={{padding: 0}}
                                        onClick={toggleLatestExam}
                                    >Latest Exam</button>
                                </th>
                                <th width="15%">
                                    <button
                                        style={{padding: 0}}
                                        onClick={toggleStudySort}
                                    >Study</button>
                                </th>
                                {/*
                                <th width="10%">
                                    <button
                                        style={{padding: 0}}
                                        onClick={toggleStudyStateSort}
                                    >Study State</button>
                                </th>
                                */}
                            </tr>
                        </tbody>
                    </table>
                </div>
                <div className="list-body">
                    <table id="list-table">
                        <tbody className="list-visits">
                            {props.techCertifications.map((cert, i) => (
                                <TechnicianCertificationsItem
                                    key={i}
                                    certification={cert}
                                />
                            ))}
                        </tbody>
                    </table>
                </div>
            </div>
        </React.Fragment>
    );
}

function TechnicianCertificationsItem(props) {

    const getStudyStatusDisplay = (status) => {

      if (status == 'open') {
        return "Open";
      }
      else if (status == 'closed') {
        return "Closed";
      }
      else if (status == 'archived') {
        return "Archived";
      }

      return "-";
    };

    const getCertificationStatusDisplay = (status) => {

      if (status == 'notcertified') {
        return "Not Certified";
      }
      else if (status == 'incertification') {
        return "In Certification";
      }
      else if (status == 'pending') {
        return "Pending";
      }
      else if (status == 'certified') {
        return "Certified";
      }

      return "-";

    };

    const getStudyLink = (cert) => {

        if (!cert.study) {
            return '-';
        }

        return ReactHtmlParser('<a target="_blank" rel="noopener noreferrer" href="/studies/' + props.certification.study.id + '/certifications/technician/' +props.certification.id+'">'+props.certification.study.acronym.toUpperCase()+'</a>');
    };


    return (
        <tr className="visit">
            <td width="10%" style={{wordBreak: 'break-word'}}>{props.certification.technician ? props.certification.technician.username : '-'}</td>
            <td width="15%" style={{wordBreak: 'break-word'}}>{props.certification.center ? props.certification.center.site_num : '-'}</td>
            <td width="12%">{props.certification.exam ? props.certification.exam.name : '-'}</td>
            <td width="12%">{getCertificationStatusDisplay(props.certification.status)}</td>
            <td width="10%">{props.certification.grading_date}</td>
            <td width="10%">{props.certification.latest_exam}</td>
            <td width="15%" style={{wordBreak: 'break-word'}}>{getStudyLink(props.certification)}</td>
            {/*<td width="10%">{props.certification.study ? getStudyStatusDisplay(props.certification.study.status) : '-'}</td>*/}

        </tr>
    );
}

function TechnicianCertificationsFilters(props) {
    return (
        <div className="top-buttons all-users">
            <ul className="button-group right">
                <li>
                    <TechUserDropdownFilter initialTechCertifications={props.initialTechCertifications} filterTechs={props.filterTechs}/>
                </li>

                <li>
                    <TechCenterDropdownFilter initialTechCertifications={props.initialTechCertifications} filterTechs={props.filterTechs}/>
                </li>

                <li>
                    <TechExamDropdownFilter initialTechCertifications={props.initialTechCertifications} filterTechs={props.filterTechs}/>
                </li>
                {
                <li>
                    <TechStatusDropdownFilter filterTechs={props.filterTechs}/>
                </li>
                }
                <li>
                    <TechStudyDropdownFilter initialTechCertifications={props.initialTechCertifications} filterTechs={props.filterTechs}/>
                </li>
                <li>
                    <TechDateDropdownFilter filterTechs={props.filterTechs}/>
                </li>
            </ul>
        </div>
    );
}

function TechUserDropdownFilter(props) {
    let tmp = [];

    const filterByUser = val => {
        if (val === '-1') {

            localStorage.setItem('corc_tech_filteredByUser', "");
        }
        else {
            localStorage.setItem('corc_tech_filteredByUser', val);
        }

        props.filterTechs();

        return false;
    }

    const userOptions = [];
    props.initialTechCertifications.map( (cert) => {

        if (cert.technician && tmp.indexOf(cert.technician.id) === -1) {
            tmp.push(cert.technician.id);
            userOptions.push({label: cert.technician.username, value: cert.technician.id});
        }

        return false;
    });

    userOptions.sort((a, b) =>  (a.label.toLowerCase() > b.label.toLowerCase() ? 1 : -1));
    userOptions.unshift({label: 'All Users', value: '-1'});

    return (
        <CorcSelect
            id="tech-user-select"
            containerStyles={{width: "165px"}}
            name="tech-user"
            onChange={selectedOption => filterByUser(selectedOption.value) }
            options={userOptions}
            isSearchable={true}
            defaultValue={{label: 'All Users', value: '-1'}}
        />
    );
}

function TechCenterDropdownFilter(props) {
    let tmp = [];

    const filterByCenter = val => {
        if (val === '-1') {
            localStorage.setItem('corc_tech_filteredByCenter', "");
        }
        else {
            localStorage.setItem('corc_tech_filteredByCenter', val);
        }

        props.filterTechs();

        return false;
    }

    const centerOptions = [];
    props.initialTechCertifications.map( (cert) => {

        if (cert.center  && tmp.indexOf(cert.center.id) === -1) {
            tmp.push(cert.center.id);
            centerOptions.push({label: cert.center.site_num, value: cert.center.id});
        }

        return false;
    });

    centerOptions.sort((a, b) =>  (a.label.toLowerCase() > b.label.toLowerCase() ? 1 : -1));
    centerOptions.unshift({label: 'All Centers', value: '-1'});



    return (
        <CorcSelect
            id="tech-center-select"
            containerStyles={{width: "165px"}}
            name="tech-center"
            onChange={selectedOption => filterByCenter(selectedOption.value) }
            options={centerOptions}
            isSearchable={true}
            defaultValue={{label: 'All Centers', value: '-1'}}
        />
    );

}


function TechExamDropdownFilter(props) {
    let tmp = [];

    const filterByExam = val => {
        if (val === '-1') {
            localStorage.setItem('corc_tech_filteredByExam', "");
        }
        else {
            localStorage.setItem('corc_tech_filteredByExam', val);
        }

        props.filterTechs();

        return false;
    }

    const examOptions = [];
    props.initialTechCertifications.map( (cert) => {

        if (cert.exam  && tmp.indexOf(cert.exam.id) === -1) {
            tmp.push(cert.exam.id);
            examOptions.push({label: cert.exam.name, value: cert.exam.id});
        }

        return false;
    });

    examOptions.sort((a, b) =>  (a.label.toLowerCase() > b.label.toLowerCase() ? 1 : -1));
    examOptions.unshift({label: 'All Exams', value: '-1'});

    return (
        <CorcSelect
            id="tech-exam-select"
            containerStyles={{width: "165px"}}
            name="tech-exam"
            onChange={selectedOption => filterByExam(selectedOption.value) }
            options={examOptions}
            isSearchable={true}
            defaultValue={{label: 'All Exams', value: '-1'}}
        />
    );
}

function TechStatusDropdownFilter(props) {

    const filterByStatus = val => {
        if (val === '-1') {
            localStorage.setItem('corc_tech_filteredByState', "");
        }
        else {
            localStorage.setItem('corc_tech_filteredByState', val);
        }

        props.filterTechs();

        return false;
    }

    const statusOptions = [
        {label: 'All Status', value: '-1'},
        {label: 'Not Started', value: 'notstarted'},
        {label: 'In Certification', value: 'incertification'},
        {label: 'Certified', value: 'certified'},
        {label: 'Not Certified', value: 'notcertified'},
        {label: 'Pending', value: 'pending'},
    ];

    return (
        <CorcSelect
            id="tech-status-select"
            containerStyles={{width: "165px"}}
            name="tech-status"
            onChange={selectedOption => filterByStatus(selectedOption.value) }
            options={statusOptions}
            isSearchable={false}
            defaultValue={{label: 'All Status', value: '-1'}}
        />
    );
}

function TechStudyDropdownFilter(props) {
    let tmp = [];

    const filterByStudy = val => {
        if (val === '-1') {

            localStorage.setItem('corc_tech_filteredByStudy', "");
        }
        else {
            localStorage.setItem('corc_tech_filteredByStudy', val);
        }

        props.filterTechs();

        return false;
    }

    const studyOptions = [];
    props.initialTechCertifications.map( (cert) => {

        if (cert.study && tmp.indexOf(cert.study.id) === -1) {
            tmp.push(cert.study.id);
            studyOptions.push({label: cert.study.acronym.toUpperCase(), value: cert.study.id});
        }

        return false;
    });

    studyOptions.sort((a, b) =>  (a.label.toLowerCase() > b.label.toLowerCase() ? 1 : -1));
    studyOptions.unshift({label: 'All Studies', value: '-1'});

    return (
        <CorcSelect
            id="tech-study-select"
            containerStyles={{width: "165px"}}
            name="tech-study"
            onChange={selectedOption => filterByStudy(selectedOption.value) }
            options={studyOptions}
            isSearchable={true}
            defaultValue={{label: 'All Studies', value: '-1'}}
        />
    );
}

function TechDateDropdownFilter(props) {

    const filterByDate = val => {
        if (val === '-1') {
            localStorage.setItem('corc_tech_filteredByDate', "");
        }
        else {
            localStorage.setItem('corc_tech_filteredByDate', val);
        }

        props.filterTechs();

        return false;
    }

    const dateOptions = [
        {label: 'Last week', value: 'week'},
        {label: 'Last month', value: 'month'},
        {label: 'Last 3 months', value: '3_months'},
        {label: 'Last 6 months', value: '6_months'},
        {label: 'Last Year', value: 'year'},
        {label: 'All Time', value: '-1'},
    ];

    return (
        <CorcSelect
            id="tech-date-select"
            containerStyles={{width: "165px"}}
            name="tech-date"
            onChange={selectedOption => filterByDate(selectedOption.value) }
            options={dateOptions}
            isSearchable={false}
            defaultValue={{label: 'Last 6 months', value: '6_months'}}
        />
    );
}

function EquipmentCertificationsList(props) {

    const toggleStudySort = () => {

        const equips = JSON.parse(JSON.stringify(props.equipCertifications));
        let sort = localStorage.getItem('corc_equip_sortStudy');

        if (sort === ">") {

            equips.sort((a, b) => {

                a = a.study ? a.study.acronym.toLowerCase() : '';
                b = b.study ? b.study.acronym.toLowerCase() : '';
                return a < b ? 1 : -1;
            });

            localStorage.setItem('corc_equip_sortStudy', "<")
        }
        else {

            equips.sort((a, b) => {

                a = a.study ? a.study.acronym.toLowerCase() : '';
                b = b.study ? b.study.acronym.toLowerCase() : '';
                return a > b ? 1 : -1;
            });

            localStorage.setItem('corc_equip_sortStudy', ">")
        }

        props.setEquipCertifications(equips);
    }

    const toggleCenterSort = () => {

        const equips = JSON.parse(JSON.stringify(props.equipCertifications));
        let sort = localStorage.getItem('corc_equip_sortCenter');

        if (sort === ">") {

            equips.sort((a, b) => {
                a = a.center ? a.center.site_num.toLowerCase() : '';
                b = b.center ? b.center.site_num.toLowerCase() : '';
                return a < b ? 1 : -1;
            });
            localStorage.setItem('corc_equip_sortCenter', "<")
        }
        else {
            equips.sort((a, b) => {
                a = a.center ? a.center.site_num.toLowerCase() : '';
                b = b.center ? b.center.site_num.toLowerCase() : '';
                return a > b ? 1 : -1;
            });
            localStorage.setItem('corc_equip_sortCenter', ">")
        }

        props.setEquipCertifications(equips);
    }

    const toggleExamSort = () => {

        const equips = JSON.parse(JSON.stringify(props.equipCertifications));
        let sort = localStorage.getItem('corc_equip_sortExam');

        if (sort === ">") {

            equips.sort((a, b) => {
                a = a.exam ? a.exam.name.toLowerCase() : '';
                b = b.exam ? b.exam.name.toLowerCase() : '';
                return a < b ? 1 : -1;
            });

            localStorage.setItem('corc_equip_sortExam', "<")
        }
        else {

            equips.sort((a, b) => {
                a = a.exam ? a.exam.name.toLowerCase() : '';
                b = b.exam ? b.exam.name.toLowerCase() : '';
                return a > b ? 1 : -1;
            });

            localStorage.setItem('corc_equip_sortExam', ">")
        }

        props.setEquipCertifications(equips);
    }

    const toggleDateSort = () => {

        const equips = JSON.parse(JSON.stringify(props.equipCertifications));
        let sort = localStorage.getItem('corc_equip_sortDate');

        if (sort === ">") {
            equips.sort((a, b) => ( parse(a.grading_date, 'dd-MM-yyyy HH:mm', new Date()) < parse(b.grading_date, 'dd-MM-yyyy HH:mm', new Date()) ) ? 1 : -1 );
            localStorage.setItem('corc_equip_sortDate', "<");
        }
        else {
            equips.sort((a, b) => ( parse(a.grading_date, 'dd-MM-yyyy HH:mm', new Date()) > parse(b.grading_date, 'dd-MM-yyyy HH:mm', new Date()) ) ? 1 : -1 );
            localStorage.setItem('corc_equip_sortDate', ">");
        }

        props.setEquipCertifications(equips);
    }

    const toggleStatusSort = () => {
        const equips = JSON.parse(JSON.stringify(props.equipCertifications));
        let sort = localStorage.getItem('corc_equip_sortStatus');

        if (sort === ">") {
            equips.sort((a, b) => (a.status.toLowerCase() < b.status.toLowerCase()) ? 1 : -1)
            localStorage.setItem('corc_equip_sortStatus', "<")
        }
        else {
            equips.sort((a, b) => (a.status.toLowerCase() > b.status.toLowerCase()) ? 1 : -1)
            localStorage.setItem('corc_equip_sortStatus', ">")
        }

        props.setEquipCertifications(equips);
    };

    return (
        <React.Fragment>
            <div className="breadcrumbs">
                <div className="path">
                    <ul>
                        <li>Centers</li>
                    </ul>
                </div>

                <div className="sub-menu">
                    <ul className="button-group right"></ul>
                </div>
            </div> {/* .breadcrumbs */}
            <EquipmentCertificationsFilters
                filterEquips={props.filterEquips}
                initialEquipCertifications={props.initialEquipCertifications}
            />
            <div className="visits-list list">
                <div className="list-head">
                    <table>
                        <tbody>
                            <tr>
                                <th width="15%">
                                    <button
                                        style={{padding: 0}}
                                        onClick={toggleCenterSort}
                                    >Center</button>
                                </th>
                                <th width="12%">
                                    <button
                                        style={{padding: 0}}
                                        onClick={toggleExamSort}
                                    >Exam</button>
                                </th>
                                <th width="12%">
                                    <button
                                        style={{padding: 0}}
                                        onClick={toggleStatusSort}
                                    >Status</button>
                                </th>
                                <th width="10%">
                                    <button
                                        style={{padding: 0}}
                                        onClick={toggleDateSort}
                                    >Graded</button>
                                </th>
                                <th width="15%">
                                    Make/model
                                </th>
                                <th width="15%">
                                    Serial Num
                                </th>
                                <th width="15%">
                                    <button
                                        style={{padding: 0}}
                                        onClick={toggleStudySort}
                                    >Study</button>
                                </th>

                            </tr>
                        </tbody>
                    </table>
                </div>
                <div className="list-body">
                    <table id="list-table">
                        <tbody className="list-visits">
                            {props.equipCertifications.map((cert, i) => (
                                <EquipmentCertificationsItem
                                    key={i}
                                    certification={cert}
                                />
                            ))}
                        </tbody>
                    </table>
                </div>
            </div>
        </React.Fragment>
    );
}

function EquipmentCertificationsItem(props) {

    const getStatusDisplay = (status) => {

      if (status == 'notcertified') {
        return "Not Certified";
      }
      else if (status == 'incertification') {
        return "In Certification";
      }
      else if (status == 'pending') {
        return "Pending";
      }
      else if (status == 'certified') {
        return "Certified";
      }

    };

    const getStudyLink = (cert) => {

        if (!cert.study) {
            return '-';
        }

        return ReactHtmlParser('<a target="_blank" rel="noopener noreferrer" href="/studies/' + props.certification.study.id + '/certifications/equipment/' +props.certification.id+'" className="">'+props.certification.study.acronym.toUpperCase()+'</a>');
    };

    return (
        <tr className="visit">
            <td width="15%">{props.certification.center ? props.certification.center.site_num : '-'}</td>
            <td width="12%">{props.certification.exam ? props.certification.exam.name : '-'}</td>
            <td width="12%">{getStatusDisplay(props.certification.status)}</td>
            <td width="10%">{props.certification.grading_date}</td>
            <td width="15%">{props.certification.make_model}</td>
            <td width="15%">{props.certification.serial_num}</td>
            <td width="15%">{getStudyLink(props.certification)}</td>
        </tr>
    );
}

function EquipmentCertificationsFilters(props) {
    return (
        <div className="top-buttons all-users">
            <ul className="button-group right">
                <li>
                    <EquipCenterDropdownFilter initialEquipCertifications={props.initialEquipCertifications} filterEquips={props.filterEquips}/>
                </li>
                <li>
                    <EquipExamDropdownFilter initialEquipCertifications={props.initialEquipCertifications} filterEquips={props.filterEquips}/>
                </li>
                <li>
                    <EquipStatusDropdownFilter filterEquips={props.filterEquips}/>
                </li>
                <li>
                    <EquipStudyDropdownFilter initialEquipCertifications={props.initialEquipCertifications} filterEquips={props.filterEquips}/>
                </li>
                <li>
                    <EquipDateDropdownFilter filterEquips={props.filterEquips}/>
                </li>
            </ul>
        </div>
    );
}

function EquipCenterDropdownFilter(props) {
    let tmp = [];

    const filterByCenter = val => {
        if (val === '-1') {
            localStorage.setItem('corc_equip_filteredByCenter', "");
        }
        else {
            localStorage.setItem('corc_equip_filteredByCenter', val);
        }

        props.filterEquips();

        return false;
    }

    const centerOptions = [];
    props.initialEquipCertifications.map( (cert) => {

        if (cert.center  && tmp.indexOf(cert.center.id) === -1) {
            tmp.push(cert.center.id);
            centerOptions.push({label: cert.center.site_num, value: cert.center.id});
        }

        return false;
    });

    centerOptions.sort((a, b) =>  (a.label.toLowerCase() > b.label.toLowerCase() ? 1 : -1));
    centerOptions.unshift({label: 'All Centers', value: '-1'});



    return (
        <CorcSelect
            id="equip-center-select"
            containerStyles={{width: "165px"}}
            name="equip-center"
            onChange={selectedOption => filterByCenter(selectedOption.value) }
            options={centerOptions}
            isSearchable={true}
            defaultValue={{label: 'All Centers', value: '-1'}}
        />
    );

}

function EquipExamDropdownFilter(props) {
    let tmp = [];

    const filterByExam = val => {
        if (val === '-1') {
            localStorage.setItem('corc_equip_filteredByExam', "");
        }
        else {
            localStorage.setItem('corc_equip_filteredByExam', val);
        }

        props.filterEquips();

        return false;
    }

    const examOptions = [];
    props.initialEquipCertifications.map( (cert) => {

        if (cert.exam  && tmp.indexOf(cert.exam.id) === -1) {
            tmp.push(cert.exam.id);
            examOptions.push({label: cert.exam.name, value: cert.exam.id});
        }

        return false;
    });

    examOptions.sort((a, b) =>  (a.label.toLowerCase() > b.label.toLowerCase() ? 1 : -1));
    examOptions.unshift({label: 'All Exams', value: '-1'});

    return (
        <CorcSelect
            id="equip-exam-select"
            containerStyles={{width: "165px"}}
            name="equip-exam"
            onChange={selectedOption => filterByExam(selectedOption.value) }
            options={examOptions}
            isSearchable={true}
            defaultValue={{label: 'All Exams', value: '-1'}}
        />
    );
}

function EquipStatusDropdownFilter(props) {

    const filterByStatus = val => {
        if (val === '-1') {
            localStorage.setItem('corc_equip_filteredByState', "");
        }
        else {
            localStorage.setItem('corc_equip_filteredByState', val);
        }

        props.filterEquips();

        return false;
    }

    const statusOptions = [
        {label: 'All Status', value: '-1'},
        {label: 'Not Started', value: 'notstarted'},
        {label: 'In Certification', value: 'incertification'},
        {label: 'Certified', value: 'certified'},
        {label: 'Not Certified', value: 'notcertified'},
        {label: 'Pending', value: 'pending'},
    ];

    return (
        <CorcSelect
            id="equip-status-select"
            containerStyles={{width: "165px"}}
            name="equip-status"
            onChange={selectedOption => filterByStatus(selectedOption.value) }
            options={statusOptions}
            isSearchable={false}
            defaultValue={{label: 'All Status', value: '-1'}}
        />
    );
}

function EquipStudyDropdownFilter(props) {
    let tmp = [];

    const filterByStudy = val => {
        if (val === '-1') {
            localStorage.setItem('corc_equip_filteredByStudy', "");
        }
        else {
            localStorage.setItem('corc_equip_filteredByStudy', val);
        }

        props.filterEquips();

        return false;
    }

    const studyOptions = [{label: 'All Studies', value: '-1'}];
    props.initialEquipCertifications.map( (cert) => {

        if (cert.study  && tmp.indexOf(cert.study.id) === -1) {
            tmp.push(cert.study.id);
            studyOptions.push({label: cert.study.acronym.toUpperCase(), value: cert.study.id});
        }

        return false;
    });



    return (
        <CorcSelect
            id="equip-study-select"
            containerStyles={{width: "165px"}}
            name="equip-study"
            onChange={selectedOption => filterByStudy(selectedOption.value) }
            options={studyOptions}
            isSearchable={true}
            defaultValue={{label: 'All Studies', value: '-1'}}
        />
    );
}

function EquipDateDropdownFilter(props) {

    const filterByDate = val => {
        if (val === '-1') {
            localStorage.setItem('corc_equip_filteredByDate', "");
        }
        else {
            localStorage.setItem('corc_equip_filteredByDate', val);
        }

        props.filterEquips();

        return false;
    }

    const dateOptions = [
        {label: 'Last week', value: 'week'},
        {label: 'Last month', value: 'month'},
        {label: 'Last 3 months', value: '3_months'},
        {label: 'Last 6 months', value: '6_months'},
        {label: 'Last Year', value: 'year'},
        {label: 'All Time', value: '-1'},
    ];

    return (
        <CorcSelect
            id="equip-date-select"
            containerStyles={{width: "165px"}}
            name="equip-date"
            onChange={selectedOption => filterByDate(selectedOption.value) }
            options={dateOptions}
            isSearchable={false}
            defaultValue={{label: 'Last week', value: 'week'}}
        />
    );
}
