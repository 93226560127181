import React, {useEffect, useState}  from 'react';
import axios from 'axios';
import {Link} from 'react-router-dom';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import CorcSelect from 'components/utils/CorcSelect';
import {initDate} from 'components/utils/Helpers.js';
import { unstable_batchedUpdates } from 'react-dom';

function PatientDetails(props) {
    useEffect(function() {

        const getPatient = async () => {
            let count = props.overlayVisible;
            props.setOverlayVisible( (count) => count + 1);

            const items = await axios.get(process.env.REACT_APP_API_ENDPOINT+'/patients/'+props.match.params.patient+'/',
                { withCredentials: true, headers: {'Content-Type': 'application/json'}});


            if (props.userAuth.user.role === 'monitor') {
                items.data.study.status = 'closed';
            }

            // fix left/right eyes
            items.data.study_left_right_eyes = false;
            if (items.data.study_left_eye && items.data.study_right_eye) {
                items.data.study_left_right_eyes = true;
            }

            setPatient(items.data);

            props.setOverlayVisible( (count) => count - 1);
        };

        props.setNavLinks([
            {title: 'Studies', url: '/studies/', icon: 'studies'},
            {title: 'Clinical Site', url: '/studies/' + props.match.params.id + '/navigate', icon: 'center'},
            {title: 'Patients', url: '/studies/' + props.match.params.id + '/center/'+props.match.params.center+'/patients', icon: 'pacients'},
            {title: 'View Patient', url: props.location.pathname, icon: 'pacients'}
        ]);
        getPatient();

    }, []);

    const [patient, setPatient] = useState({
        id: '',
        name: '',
        patient_num: '',
        comment: '',
        basal_date: '',
        study_left_eye: false,
        study_right_eye: false,
        study_left_right_eyes: false,
        card_vars: [],
        study: {}
    });

    const onChangeHandler = ev => {
        let name = ev.target.name;
        let val = ev.target.value;

        setPatient({...patient, [name]: val});
    };

    const onStatusChangeHandler = selected => {
        setPatient({...patient, status: selected})
    }

    const [confirmSubmitPopup, setConfirmSubmitPopup] = useState(false);
    const [patientErrors, setPatientErrors] = useState([])

    const onSubmitHandler = (ev) => {

        ev.preventDefault();

        setConfirmSubmitPopup(true);
    };

    const closeConfirmSubmitPopup = () => {
        setConfirmSubmitPopup(false);
    }

    const closePatientErrors = () => {
        setPatientErrors([]);
    }

    const validatePatient = () => {

        let errors = [];
        const changedPatient = JSON.parse(JSON.stringify(patient));

        changedPatient.patient_num = changedPatient.patient_num.trim();
        if (!changedPatient.patient_num) {
            errors.push("Patient Number is Required.");
        }

        if (!changedPatient.study_left_eye && !changedPatient.study_right_eye && !changedPatient.study_left_right_eyes && changedPatient.study.select_patient_eye) {
            errors.push("Study eye(s) is Required.");
        }

        // fix study eyes
        if (changedPatient.study_left_right_eyes) {
            changedPatient.study_left_eye = true;
            changedPatient.study_right_eye = true;
        }

        delete changedPatient["study_left_right_eyes"];

        changedPatient.comment = changedPatient.comment.trim();
        for (let i in changedPatient.card_vars) {
            let v = changedPatient.card_vars[i];
            if(v.var_type.field_type === 'input' || v.var_type.field_type === 'text') {
                v.value = v.value.trim();
            }
        }

        // check if patient card vars are filled
        for (let i in patient.study.patient_card_vars) {
            let found = false;

            for (let j in changedPatient.card_vars) {

                if (changedPatient.card_vars[j].var_type.id == patient.study.patient_card_vars[i].id) {
                    found = true;
                    break;
                }
            }

            if (!found) {
                errors.push(patient.study.patient_card_vars[i].name + " is required.");
            }

        }

        changedPatient.study = changedPatient.study.id;

        // if validation fails, show error popup
        if (errors.length) {
            setConfirmSubmitPopup(false);
            setPatientErrors(errors);
        }
        else {
            // otherwise submit the certification
            submitPatient(changedPatient);
        }
    }

    const submitPatient = async (patient) => {

        const errMsg = "Sorry, something went wrong when updating this patient. Please contact your IT helpdesk.";

        let count = props.overlayVisible;
        props.setOverlayVisible( (count) => count + 1);
        await axios.patch(process.env.REACT_APP_API_ENDPOINT+'/patients/'+props.match.params.patient+'/', {...patient}, { withCredentials: true})
            .then((response) => {

            setConfirmSubmitPopup(false);

            props.setOverlayVisible( (count) => count - 1);
            if (response.status === 200) {

                // fix left/right eyes
                response.data.study_left_right_eyes = false;
                if (response.data.study_left_eye && response.data.study_right_eye) {
                    response.data.study_left_right_eyes = true;
                }

                setPatient(response.data)
                props.history.push("/studies/"+props.match.params.id+"/center/"+props.match.params.center+"/patients");
            }
            else {
                alert(errMsg);
            }

        }, (error) => {
            setConfirmSubmitPopup(false);
            alert(errMsg);
            props.setOverlayVisible( (count) => count - 1);
        });

    };

    const statusOptions = [
        {value:"-1", label: "N/A"},
        {value:"failure", label: "Screening failure"},
        {value:"ongoing", label: "Ongoing"},
        {value:"completed", label: "Completed"},
        {value:"dropped", label: "Dropped-out"},
        {value:"endpoint", label: "Reached End Point"},
    ];

    const submitHandler = e => {
        e.preventDefault();
    };

    return (

        <React.Fragment>

        {patientErrors.length > 0 && (
            <React.Fragment>
            <div className="modal-backdrop fade in"></div>
            <div id="alertModal" className="modal hide fade in" tabIndex="-1" role="dialog" aria-labelledby="myModalLabel" aria-hidden="false" style={{display: "block"}}>
                <div className="modal-header">
                    <button type="button" className="close" data-dismiss="modal" aria-hidden="true">×</button>
                    <h3 id="alert-modal-title">Errors!</h3>
                </div>
                <div className="modal-body"><br/><br/>
                    <div className="errorSummary"><p>Please fix the following input errors:</p>
                        <ul>
                            {patientErrors.map((message) => (
                            <li key={message}>{message}</li>
                            ))}
                        </ul>
                    </div><br />
                </div>
                <div className="modal-footer">
                    <button onClick={closePatientErrors} id="alert-modal-ok" aria-hidden="true" className="btn small orange align-center right" style={{display: "block"}}>OK</button>
                </div>
            </div>
            </React.Fragment>
        )}

        {confirmSubmitPopup && (
            <React.Fragment>
            <div className="modal-backdrop fade in"></div>
            <div id="alertModal" className="modal hide fade in" tabIndex="-1" role="dialog" aria-labelledby="myModalLabel" aria-hidden="false" style={{display: "block"}}>
                <div className="modal-header">
                    <button type="button" className="close" data-dismiss="modal" aria-hidden="true">×</button>
                    <h3 id="alert-modal-title">Confirmation!</h3>
                </div>
                <div className="modal-body"><br/>Do you really want to save this Patient?<br/><br/></div>
                <div className="modal-footer">
                    <button onClick={validatePatient} id="alert-modal-ok" aria-hidden="true" className="btn small orange align-center right">Yes</button>
                    <button onClick={closeConfirmSubmitPopup} data-dismiss="modal" id="alert-modal-cancel" aria-hidden="true" className="btn small grey align-center right">No</button>
                </div>
            </div>
            </React.Fragment>
        )}

        <div className="home_header_container_class wide">
            <div className="content">
                <div className="breadcrumbs">
                    <div className="path">
                        <ul>
                            <li>Patient details</li>
                        </ul>
                    </div>
                </div> {/* .breadcrumbs */}
                <div className="main-form">
                    <form onSubmit={submitHandler} id="register-participant" method="post">
                        <div className="form-group">
                            <div className="form-flex">
                                <PatientGeneralVars
                                    patient={patient}
                                    setPatient={setPatient}
                                    initDate={initDate}
                                    userAuth={props.userAuth}
                                />
                            </div>
                            <div className="visit-custom-vars form-flex">
                                <PatientOtherVars
                                    patient={patient}
                                    setPatient={setPatient}
                                    initDate={initDate}
                                />
                            </div>

                            <div className="span5" style={{width: "100%", marginBottom: "10px"}}>
                                <div className="row" style={{paddingTop: "4px"}}>
                                    <div className="label">
                                        <label>Comments</label>
                                    </div>
                                    <div>
                                        <textarea
                                            className="submit-comment"
                                            style={{margin: "0px 0", width: "81.5%"}}
                                            name="comment"
                                            onChange={(ev) => onChangeHandler(ev)}
                                            value={patient.comment}
                                            disabled={patient.study.status === 'open' ? "" : "disabled"}
                                        >
                                        </textarea>
                                    </div>
                                </div>
                            </div>


                            <div className="span5" style={{width: "33.5%", float: "right", marginBottom: "10px", marginTop: "-8px"}}>
                                <div className="row" style={{paddingTop: "4px"}}>
                                    <div className="label">
                                        <label htmlFor="status" className="required">Patient Status <span className="required">*</span></label>
                                    </div>
                                    <CorcSelect
                                        options={statusOptions}
                                        name="status"
                                        id="status"
                                        value={statusOptions.filter(o => o.value === patient.status)}
                                        onChange={selectedOption => onStatusChangeHandler(selectedOption.value)}
                                        isSearchable={false}
                                        containerStyles={{width: "175px", float: "left"}}
                                        isDisabled={patient.study.status === 'open' ? "" : "disabled"}
                                    />
                                </div>
                            </div>

                        </div> {/* .form-group  */}

                        <div className="overview">
                            <h2 className="text-shadow">Patient Card Information</h2>
                        </div>

                        <div className="form-group checkbox-group group-page">
                            <div className="row" style={{paddingTop: "4px"}}>

                                <div>
                                    <textarea value={patient.study.patient_details} className="submit-comment" disabled="disabled" style={{marginTop: "10px", width: "94.5% !important" }}></textarea>
                                </div>
                            </div>
                        </div>

                         <div className="form-actions" style={{float: "right"}}>


                            {patient.study.status === 'open' ? (
                                <React.Fragment>
                                <Link to={`/studies/${props.match.params.id}/center/${props.match.params.center}/patients`} className="btn small grey align-center left">Cancel</Link>
                                <button onClick={(ev) => onSubmitHandler(ev)} className="btn small orange align-center left">Save</button>
                                </React.Fragment>
                            ) : (
                                <Link to={`/studies/${props.match.params.id}/center/${props.match.params.center}/patients`} className="btn small grey align-center left">Back</Link>
                            )}

                        </div> {/* .form-actions */}
                    </form>
                </div>
            </div>
        </div>
        </React.Fragment>
    );
}

export default PatientDetails;

function PatientGeneralVars(props) {
    const onChangeStudyEyes = (ev) => {
        let editedPatient = false;
        
	    editedPatient = JSON.parse(JSON.stringify(props.patient));

        if(ev.target.value === 'both') {
            editedPatient.study_left_eye = false;
            editedPatient.study_right_eye = false;
            editedPatient.study_left_right_eyes = true;
        }
	    else if(ev.target.value === 'left') {
            editedPatient.study_left_eye = true;
            editedPatient.study_right_eye = false;
            editedPatient.study_left_right_eyes = false;
        }
        else if(ev.target.value === 'right') {
            editedPatient.study_left_eye = false;
            editedPatient.study_right_eye = true;
            editedPatient.study_left_right_eyes = false;
        }

        props.setPatient(editedPatient);
    };

    const onChangeHandler = (ev) => {
        let name = ev.target.name;
        let val = ev.target.value;

        props.setPatient({...props.patient, [name]: val});
    };

    const onChangeDate = (date) => {
        let txtDate = '';

        if (date) {
            txtDate = date.getFullYear() + '-' + (date.getMonth()+1) + '-' + date.getDate();
        }

        props.setPatient({...props.patient, 'basal_date': txtDate})
    };

    return (
        <React.Fragment>

        <div className="form-flex-3-col" key={1}>
            <div className="row" style={{paddingTop: "4px"}}>
                <div className="label">
                    <label>Patient number</label>
                </div>
                <div className="input">
                    <input
                        id="patient_num"
                        name="patient_num"
                        type="text"
                        value={props.patient.patient_num}
                        onChange={(ev) => onChangeHandler(ev)}
                        disabled={props.patient.study.status === 'open' ? "" : "disabled"}

                    />
                </div>
            </div>
        </div>
        {(props.userAuth.user.role === 'grader' || props.userAuth.user.role === 'admin') && (
        <div className="form-flex-3-col" key={2}>
            <div className="row" style={{paddingTop: "4px"}}>
                <div className="label">
                    <label>Basal date</label>
                </div>
                <div className="input">
                    <DatePicker
                        dateFormat="dd/MM/yyyy"
                        name="basal_date"
                        selected={props.patient.basal_date && props.initDate(props.patient.basal_date)}
                        onChange={(ev) => onChangeDate(ev)}
                        disabled={props.patient.study.status === 'open' ? "" : "disabled"}
                    />
                </div>
            </div>
        </div>
        )}
        <div className="form-flex-3-col" key={3}>
            <div className="row" style={{paddingTop: "4px"}}>
                <div className="label">
                    <label>Study eye(s)</label>
                </div>
                <div className="input">
                    <div className="checkbox-group check">
                        <input
                            value="right"
                            id="study_eyes_re"
                            name="study_eyes"
                            type="radio"
                            checked={props.patient.study_right_eye}
                            onClick={(ev) => onChangeStudyEyes(ev)}
                            disabled={props.patient.study.status === 'open' ? "" : "disabled"}
                        />
                        <label htmlFor="study_eyes_re">RE</label>
                        <input
                            value="left"
                            id="study_eyes_le"
                            name="study_eyes"
                            type="radio"
                            checked={props.patient.study_left_eye}
                            onClick={(ev) => onChangeStudyEyes(ev)}
                            disabled={props.patient.study.status === 'open' ? "" : "disabled"}
                        />
                        <label htmlFor="study_eyes_le">LE</label>
                        <input
                            value="both"
                            id="study_eyes_rle"
                            name="study_eyes"
                            type="radio"
                            checked={props.patient.study_left_right_eyes}
                            onClick={(ev) => onChangeStudyEyes(ev)}
                            disabled={props.patient.study.status === 'open' ? "" : "disabled"}
                        />
                        <label htmlFor="study_eyes_rle">RLE</label>
                    </div>
                </div>
            </div>
        </div>

        {props.patient.study.patient_card_vars && props.patient.study.patient_card_vars.map((v, i) => v.group === 'general' && (
            <div className="form-flex-3-col" key={i+3}>
                <PatientVarItem
                    field={v}
                    patient={props.patient}
                    setPatient={props.setPatient}
                    initDate={props.initDate}
                />
            </div>
        ))}
        </React.Fragment>
    );
}

function PatientOtherVars(props) {
    return (
        <React.Fragment>
        {props.patient.study.patient_card_vars && props.patient.study.patient_card_vars.map((v, i) => v.group === 'other' && (
            <div className="form-flex-2-col" key={i}>
                <PatientVarItem
                    field={v}
                    patient={props.patient}
                    setPatient={props.setPatient}
                    initDate={props.initDate}
                />
            </div>
        ))}
        </React.Fragment>
    );
}

function PatientVarItem(props) {

    const onListChangeHandler = (uid, selectedOption) => {
        let editedPatient = JSON.parse(JSON.stringify(props.patient));

        const card_var = editedPatient.study.patient_card_vars.find(c => {
            return c.uid === uid;
        });

        if (!card_var) {
            return false;
        }

        let found = editedPatient.card_vars.find(c => {
            if (c.var_type) {
                return card_var.uid === c.var_type.uid;
            }
            else {
                return undefined;
            }

        });

        if (found === undefined) {
            let cVar = {'patient': editedPatient.id, 'var_type': card_var, 'value': selectedOption};

            editedPatient.card_vars.push(cVar);
        }
        else {
            found.value = selectedOption;
        }

        props.setPatient(editedPatient);
    };

    const onChangeHandler = (ev) => {
        let name = ev.target.name;
        let val = ev.target.value.toString();
        let editedPatient = JSON.parse(JSON.stringify(props.patient));
        let tmp, tmp_array;

        const card_var = props.patient.study.patient_card_vars.find(c => {
            return c.uid === name;
        })

        if (!card_var) {
            return false;
        }

        let found = editedPatient.card_vars.find(c => {
            if (c.var_type) {
                return card_var.uid === c.var_type.uid;
            }
            else {
                return undefined;
            }

        });

        if (found === undefined) {
            let cVar = {'patient': editedPatient.id, 'var_type': card_var, 'value': val};

            editedPatient.card_vars.push(cVar);
        }
        else {
            if (card_var.field_type === 'check_many') {
                tmp_array = found.value.split(",");
                tmp = tmp_array.indexOf(val);
                if (tmp === -1) {
                    tmp_array.push(val);
                    found.value = tmp_array.toString();
                }
                else {
                    tmp_array.splice(tmp, 1);
                    // is it the last value?
                    if (!tmp_array.length) {
                        for(let i=editedPatient.card_vars.length-1;i>=0;--i) {
                            if(editedPatient.card_vars[i].var_type.uid === card_var.uid) {
                                editedPatient.card_vars.splice(i, 1);
                                break;
                            }
                        }
                    }
                    found.value = tmp_array.toString();
                }
            }
            else {
                found.value = val;
            }
        }

        props.setPatient(editedPatient);

    };

    const onChangeDate = (date, card_var) => {
        let txtDate = '';
        let editedPatient = JSON.parse(JSON.stringify(props.patient));

        if (date) {
            txtDate = date.getFullYear() + '-' + (date.getMonth()+1) + '-' + date.getDate();
        }

        if (!card_var) {
            return false;
        }

        let found = editedPatient.card_vars.find(c => {
            if (c.var_type) {
                return card_var.uid === c.var_type.uid;
            }
            else {
                return undefined;
            }

        });

        if (found === undefined) {
            editedPatient.card_vars.push({'patient': editedPatient.id, 'value': txtDate, 'var_type': card_var});
        }
        else {
            found.value = txtDate;
        }

        props.setPatient(editedPatient);

    };

    const getPatientField = (field_id) => {

        let found = props.patient.card_vars.find(c => {
            if (c.var_type) {
                return c.var_type.id === field_id;
            }
            else {
                return undefined;
            }
        });

        if (found === undefined) {
            return "";
        }

        if(found.var_type.field_type === 'list') {
            let selOption = found.var_type.card_vars_options.find(e => parseInt(e.id, 10) === parseInt(found.value, 10));
            return {label: selOption.value, value: selOption.id};
        }
        else {
            return found.value;
        }
    }

    const getMultiplePatientField = (field_id) => {

        let found = props.patient.card_vars.find(c => {
            if (c.var_type) {
                return c.var_type.id === field_id;
            }
            else {
                return undefined;
            }
        });

        if (found === undefined) {
            return [];
        }


        return found.value.split(",");
    }

    const buildField = (field) => {
        if(field.field_type === 'input') {
            return (
                <input
                    name={field.uid}
                    type="text"
                    value={getPatientField(field.id)}
                    onChange={(ev) => onChangeHandler(ev)}
                    disabled={props.patient.study.status === 'open' ? "" : "disabled"}
                />
            );
        }
        else if(field.field_type === 'date') {
            return (
                <DatePicker
                    dateFormat="dd/MM/yyyy"
                    name={field.uid}
                    selected={getPatientField(field.id) && props.initDate(getPatientField(field.id))}
                    onChange={(date) => onChangeDate(date, field)}
                    disabled={props.patient.study.status === 'open' ? "" : "disabled"}
                />
            );
        }
        else if(field.field_type === 'text') {
            return (
                <textarea
                    name={field.uid}
                    value={getPatientField(field.id)}
                    onChange={(ev) => onChangeHandler(ev)}
                    disabled={props.patient.study.status === 'open' ? "" : "disabled"}
                >
                </textarea>
            );
        }
        else if(field.field_type === 'list') {
            let listOptions = [];
            field.card_vars_options.map(opt => listOptions.push({label: opt.value, value: opt.id}));

            return (
                <CorcSelect
                    name={field.uid}
                    id={field.uid}
                    value={getPatientField(field.id)}
                    onChange={(selectedOption) => onListChangeHandler(field.uid, selectedOption.value)}
                    options={listOptions}
                    placeholder="-"
                    containerStyles={{width: "125px"}}
                    disabled={props.patient.study.status === 'open' ? true : false}
                />
            );
        }
        else if(field.field_type === 'check_one') {

            return (
                <div className="checkbox-group check">
                {field.card_vars_options.map((opt, i) => (
                    <React.Fragment
                        key={i}
                    >
                    <input
                        value={opt.id}
                        id={field.uid + '_' + i}
                        name={field.uid}
                        type="radio"
                        checked={getPatientField(field.id) === opt.id.toString()}
                        onChange={(ev) => onChangeHandler(ev)}
                        disabled={props.patient.study.status === 'open' ? "" : "disabled"}
                    />
                    <label htmlFor={field.uid + '_' + i}>{opt.value}</label>
                    </React.Fragment>
                ))}
                </div>
            );
        }
        else if(field.field_type === 'check_many') {

            return (
                <div className="checkbox-group check">
                {field.card_vars_options.map((opt, i) => (
                    <React.Fragment
                        key={i}
                    >
                    <input
                        value={opt.id}
                        id={field.uid + '_' + i}
                        name={field.uid}
                        type="checkbox"
                        checked={getMultiplePatientField(field.id).indexOf(opt.id.toString()) !== -1}
                        onChange={(ev) => onChangeHandler(ev)}
                        disabled={props.patient.study.status === 'open' ? "" : "disabled"}
                    />
                    <label htmlFor={field.uid + '_' + i}>{opt.value}</label>
                    </React.Fragment>
                ))}
                </div>
            );
        }

        else if(field.field_type === 'eye') {
            return (
                <div className="checkbox-group check">
                    <input
                        value="right"
                        id={field.uid + '_re'}
                        name={field.uid}
                        type="radio"
                        checked={getPatientField(field.id) === 'right'}
                        onChange={(ev) => onChangeHandler(ev)}
                        disabled={props.patient.study.status === 'open' ? "" : "disabled"}
                    />
                    <label htmlFor={field.uid + '_re'}>RE</label>
                    <input
                        value="left"
                        id={field.uid + '_le'}
                        name={field.uid}
                        type="radio"
                        checked={getPatientField(field.id) === 'left'}
                        onChange={(ev) => onChangeHandler(ev)}
                        disabled={props.patient.study.status === 'open' ? "" : "disabled"}
                    />
                    <label htmlFor={field.uid + '_le'}>LE</label>
                    <input
                        value="both"
                        id={field.uid + '_rle'}
                        name={field.uid}
                        type="radio"
                        checked={getPatientField(field.id) === 'both'}
                        onChange={(ev) => onChangeHandler(ev)}
                        disabled={props.patient.study.status === 'open' ? "" : "disabled"}
                    />
                    <label htmlFor={field.uid + '_rle'}>RLE</label>
                </div>
            );
        }
    };

    return (
        <div className="row" style={{paddingTop: "4px"}}>
            <div className="label">
                <label>{props.field.name}</label>
            </div>
            <div className="input">
                {buildField(props.field)}
            </div>
        </div>
    );
}

