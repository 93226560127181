import React, {useEffect, useState}  from 'react';
import axios from 'axios';
import {Link} from 'react-router-dom';
import { CSVLink } from "react-csv";
import jsPDF from "jspdf";
import "jspdf-autotable";
import {formatDate, initDate} from 'components/utils/Helpers.js';

const prettyBytes = require('pretty-bytes');

function Visits(props) {

    useEffect(function() {
        const getPatient = async () => {
            let count = props.overlayVisible;
            props.setOverlayVisible( (count) => count + 1);

            await axios.get(process.env.REACT_APP_API_ENDPOINT+'/patients/'+props.match.params.patient+'/',
                { withCredentials: true, headers: {'Content-Type': 'application/json'}}).then(response => {

                setPatient(response.data);

                // prep visits for patient
                prepVisits(response.data);
                props.setOverlayVisible( (count) => count - 1);

            }, (error) => {
                props.setOverlayVisible( (count) => count - 1);
            });
        };

        props.setNavLinks([
            {title: 'Studies', url: '/studies/', icon: 'studies'},
            {title: 'Clinical Site', url: '/studies/' + props.match.params.id + '/navigate', icon: 'center'},
            {title: 'Patients', url: '/studies/' + props.match.params.id + '/center/'+props.match.params.center+'/patients', icon: 'pacients'},
            {title: 'Visits', url: props.location.pathname, icon: 'visits'}
        ]);

        localStorage.setItem('corc_study_patients_visits_sortVisit', "");
        localStorage.setItem('corc_study_patients_visits_sortExamDate', "");

        getPatient();
    }, []);


    const getStatusDisplay = status => {

        if (status === 'missed') {
            return 'Missed';
        }
        else if (status === 'pending') {
            return 'Scheduled';
        }
        else if (status === 'rejected') {
            return 'Rejected';
        }
        else if (status === 'received') {
            return 'Received';
        }
        else if (status === 'submitted') {
            return 'Submitted'
        }

        return '';

    }

    const prepVisits = (patient) => {

        const dataCSV = [];

        let tmpSchedVisits = patient.visits.filter(v => v.scheduled_visit);
        let tmpUnschedVisits = patient.visits.filter(v => !v.scheduled_visit);
        let tmpSchedVisitsInfo = [];

        if(tmpSchedVisits.length) {
            let currSched = tmpSchedVisits[0].scheduled_visit.id;
            let visitInfoCurr, visitInfoPrev;

            //initial setup
            visitInfoCurr = {...tmpSchedVisits[0].scheduled_visit, exam_date: tmpSchedVisits[0].exam_date, scheduled_date: tmpSchedVisits[0].scheduled_date};
            tmpSchedVisitsInfo.push(visitInfoCurr);


            for(let i=0, len=tmpSchedVisits.length; i<=len-1; ++i) {
                if(tmpSchedVisits[i].scheduled_visit.id !== currSched) {
                    let prevSched = currSched;

                    currSched = tmpSchedVisits[i].scheduled_visit.id;

                    visitInfoCurr = tmpSchedVisitsInfo.find(v => {
                        return v.id === currSched;
                    })

                    if (visitInfoCurr === undefined) {
                        visitInfoCurr = {...tmpSchedVisits[i].scheduled_visit, exam_date: tmpSchedVisits[i].exam_date, scheduled_date: tmpSchedVisits[i].scheduled_date};
                        tmpSchedVisitsInfo.push(visitInfoCurr);
                    }
                    else {
                        visitInfoCurr = {...tmpSchedVisits[i].scheduled_visit, exam_date: tmpSchedVisits[i].exam_date, scheduled_date: tmpSchedVisits[i].scheduled_date};
                    }
                }
            }

            // iterate all schedules, get exams for each schedule and mark the schedule as complete or incomplete
            for(let i=0, len=tmpSchedVisitsInfo.length; i<=len-1; ++i) {

                tmpSchedVisitsInfo[i].waiting_validation = false;
                tmpSchedVisitsInfo[i].completed = true;

                // get exams
                let exams = tmpSchedVisits.filter(v => {
                    return v.scheduled_visit && v.scheduled_visit.id == tmpSchedVisitsInfo[i].id;
                });

                for (let j=0, len2=exams.length; j<=len2-1; ++j) {

                    // if waiting_validation is already true, don't bother to check
                    if (!tmpSchedVisitsInfo[i].waiting_validation) {
                        if ( (exams[j].exam_status == 'submitted' && !exams[j].missed_visit) || (exams[j].exam_status == 'rejected' && !exams[j].missed_visit)) {
                            tmpSchedVisitsInfo[i].waiting_validation = true;
                        }
                    }

                    // if completed is already false, dont bother to check
                    if (tmpSchedVisitsInfo[i].completed) {
                        if (exams[j].exam_status == 'submitted' || exams[j].exam_status == 'rejected' || exams[j].exam_status == 'pending') {
                            tmpSchedVisitsInfo[i].completed = false;
                        }
                    }

                }

            }
        }

        setSchedVisitsInfo(JSON.parse(JSON.stringify(tmpSchedVisitsInfo)));
        setSchedVisits(JSON.parse(JSON.stringify(tmpSchedVisits)));
        setUnschedVisits(JSON.parse(JSON.stringify(tmpUnschedVisits)));

        // build CSV exports
        if (tmpUnschedVisits.length > 0) {
            tmpUnschedVisits.map(function(v) {

                let visit = {};

                visit.visit_name = "- UNSCHEDULED - ";
                visit.exam_date = v.exam_date;
                visit.study_eye = "";
                if (patient.study_left_eye && patient.study_right_eye) {
                    visit.study_eye = "Left & Right";
                }
                else if (patient.study_right_eye) {
                    visit.study_eye = 'Right';
                }
                else if (patient.study_left_eye) {
                    visit.study_eye = 'Left';
                }

                visit.waiting_validation = v.exam_status === 'submitted' || v.exam_status === 'rejected' ? 'Yes' : 'No';
                visit.completed = v.exam_status === 'received' ? 'Yes' : 'No';

                visit.exam_name = v.study_exam.exam_type.name;
                visit.exam_upload_date = v.submission_date;
                visit.exam_uploader = v.user_submitted.username;
                visit.exam_status = v.exam_status;

                if (v.visit_files.length) {
                    visit.exam_file_name = v.visit_files[0] ? v.visit_files[0].name : '';
                    visit.exam_file_size = v.visit_files[0] && v.visit_files[0].file && v.visit_files[0].file.size ? prettyBytes(v.visit_files[0].file.size) : "0 B";
                }

                dataCSV.push(visit);
                return false;
            });
        }

        if (tmpSchedVisitsInfo.length > 0) {

            tmpSchedVisitsInfo.map(function(v) {

                let visit = {};
                visit.visit_name = v.name;
                visit.exam_date = v.exam_date;
                visit.scheduled_date = v.scheduled_date;
                visit.study_eye = "";
                if (patient.study_right_eye) {
                    visit.study_eye = 'Right';
                }
                else if (patient.study_left_eye) {
                    visit.study_eye = 'Left';
                }
                else if (patient.study_left_eye && patient.study_right_eye) {
                    visit.study_eye = "Left & Right";
                }

                visit.waiting_validation = v.waiting_validation ? 'Yes' : 'No';
                visit.completed = v.completed ? 'Yes' : 'No';


                dataCSV.push(visit);

                // check exams for this visit
                tmpSchedVisits.map(sv => {
                    let exam = {};

                    if (sv.scheduled_visit.id === v.id) {

                        exam.exam_name = sv.study_exam.exam_type.name;
                        exam.exam_upload_date = sv.submission_date;
                        exam.exam_uploader = sv.user_submitted ? sv.user_submitted.username : '';
                        exam.exam_status = getStatusDisplay(sv.exam_status);

                        if (sv.visit_files.length) {
                            exam.exam_file_name = sv.visit_files[0] ? sv.visit_files[0].name : '';
                            exam.exam_file_size = sv.visit_files[0] && sv.visit_files[0].file && sv.visit_files[0].file.size ? prettyBytes(sv.visit_files[0].file.size) : "0 B";
                        }

                        dataCSV.push(exam);

                    }

                    return false;

                });

                return false;

            });
        }

        setDataToCSV(dataCSV);
    };

    const [schedVisitsInfo, setSchedVisitsInfo] = useState([]);
    const [schedVisits, setSchedVisits] = useState([]);
    const [unschedVisits, setUnschedVisits] = useState([]);

    const [patient, setPatient] = useState({
        id: '',
        name: '',
        patient_num: '',
        basal_date: '',
        study_left_eye: false,
        study_right_eye: false,
        visits: [],
        study: {},
        center: {},
    });

    const [dataToCSV, setDataToCSV] = useState([]);

    const headersCSV = [
        { label: "Visit", key: "visit_name" },
        { label: "Exam Date", key: "exam_date" },
        { label: "Scheduled Date", key: "scheduled_date" },
        { label: "Study Eye", key: "study_eye" },
        { label: "Waiting for validation", key: "waiting_validation" },
        { label: "Completed", key: "completed" },
        { label: "Exam", key: "exam_name" },
        { label: "Upload date", key: "exam_upload_date" },
        { label: "Uploader", key: "exam_uploader" },
        { label: "File Name", key: "exam_file_name" },
        { label: "File Size", key: "exam_file_size" },
        { label: "Status", key: "exam_status" },
    ];

    const exportPDF = () => {

        const marginLeft = 40;
        const title = "";
        const doc = new jsPDF("landscape", "pt", "A4");

        doc.setFontSize(15);

        const headers = [["Visit", "Exam Date", "Scheduled Date", "Study Eye", "Waiting for validation", "Completed", "Exam", "Upload date", "Uploader", "Status"]];

        const data = dataToCSV.map(i=> [i.visit_name, i.exam_date, i.scheduled_date, i.study_eye, i.waiting_validation, i.completed, i.exam_name, i.exam_upload_date, i.exam_uploader, i.exam_status]);

        let content = {
            startY: 50,
            head: headers,
            body: data
        };

        doc.text(title, marginLeft, 40);
        doc.autoTable(content);
        doc.save("visits-export.pdf")
    }

    useEffect(function() {
        prepVisits(patient);
    }, [patient]);


    return (
        <div className="home_header_container_class wide">
            <div className="content">
                <div className="breadcrumbs">
                    <div className="path">
                        <ul>
                            <li><span className="orange text-shadow">{patient.study.acronym ? patient.study.acronym.toUpperCase() :  ''}</span></li>
                            <li className="separator text-shadow">&gt;</li>
                            <li><span className="orange text-shadow">{patient.center.study_site_num}</span></li>
                            <li className="separator text-shadow">&gt;</li>
                            <li><span className="orange text-shadow">Patient {patient.patient_num}</span></li>
                            <li className="separator text-shadow">&gt;</li>
                            <li>Visits</li>
                        </ul>
                    </div>
                    <div className="sub-menu">
                        <CSVLink className="btn normal grey right" data={dataToCSV} headers={headersCSV} filename={"visits-export.csv"}>
                            <span className="icon icon-list report"></span>
                            Export to CSV
                        </CSVLink>
                        <button onClick={exportPDF} className="btn normal grey right">
                            <span className="icon icon-list report"></span>Export to PDF
                        </button>
                    </div>
                </div> {/* .breadcrumbs */}

                <VisitsList
                    setSchedVisitsInfo={setSchedVisitsInfo}
                    setUnschedVisits={setUnschedVisits}
                    schedVisitsInfo={schedVisitsInfo}
                    schedVisits={schedVisits}
                    unschedVisits={unschedVisits}
                    patient={patient}
                    params={props.match.params}
                    setPatient={setPatient}
                    getStatusDisplay={getStatusDisplay}
                    userAuth={props.userAuth}
                />
            </div>
        </div>

    );
}

export default Visits;

function VisitsList(props) {

    const toggleVisitSort = () => {

        const visits = JSON.parse(JSON.stringify(props.patient.visits));
        let sort = localStorage.getItem('corc_study_patients_visits_sortVisit');

        if (sort === ">") {
            visits.sort((a, b) => {
                let aName = a.scheduled_visit ? a.scheduled_visit.name.toLowerCase() : '';
                let bName = b.scheduled_visit ? b.scheduled_visit.name.toLowerCase() : '';
                return aName < bName ? 1 : -1;
            });
            localStorage.setItem('corc_study_patients_visits_sortVisit', "<")
        }
        else {
            visits.sort((a, b) => {
                let aName = a.scheduled_visit ? a.scheduled_visit.name.toLowerCase() : '';
                let bName = b.scheduled_visit ? b.scheduled_visit.name.toLowerCase() : '';
                return aName > bName ? 1 : -1;
            });
            localStorage.setItem('corc_study_patients_visits_sortVisit', ">")
        }

        props.setPatient({...props.patient, visits: visits})
    }

    const toggleExamDateSort = () => {

        const visits = JSON.parse(JSON.stringify(props.patient.visits));
        let sort = localStorage.getItem('corc_study_patients_visits_sortExamDate');

        if (sort === ">") {

            visits.sort(function(a, b) {
                a = a.exam_date ? initDate(a.exam_date) : new Date(a.exam_date);
                b = b.exam_date ? initDate(b.exam_date) : new Date(b.exam_date);

                return (a < b ? 1 : -1);

            });

            localStorage.setItem('corc_study_patients_visits_sortExamDate', "<")
        }
        else {

            visits.sort(function(a, b) {
                a = a.exam_date ? initDate(a.exam_date) : new Date(a.exam_date);
                b = b.exam_date ? initDate(b.exam_date) : new Date(b.exam_date);

                return (a > b ? 1 : -1);

            });

            localStorage.setItem('corc_study_patients_visits_sortExamDate', ">")
        }

        props.setPatient({...props.patient, visits: visits})
    }

    const toggleScheduledDateSort = () => {

        const visits = JSON.parse(JSON.stringify(props.patient.visits));
        let sort = localStorage.getItem('corc_study_patients_visits_sortScheduledDate');

        if (sort === ">") {

            visits.sort(function(a, b) {
                a = a.scheduled_date ? initDate(a.scheduled_date) : new Date(a.scheduled_date);
                b = b.scheduled_date ? initDate(b.scheduled_date) : new Date(b.scheduled_date);

                return (a < b ? 1 : -1);

            });

            localStorage.setItem('corc_study_patients_visits_sortScheduledDate', "<")
        }
        else {

            visits.sort(function(a, b) {
                a = a.scheduled_date ? initDate(a.scheduled_date) : new Date(a.scheduled_date);
                b = b.scheduled_date ? initDate(b.scheduled_date) : new Date(b.scheduled_date);

                return (a > b ? 1 : -1);

            });

            localStorage.setItem('corc_study_patients_visits_sortScheduledDate', ">")
        }

        props.setPatient({...props.patient, visits: visits})
    }

    return (
        <div className="visits-list list">
            <div className="list-head">
                <table>
                    <tbody>
                        <tr>
                            <th width="10.5%">
                                <button onClick={toggleVisitSort}>Visit</button>
                            </th>
                            <th width="9%">
                                <button onClick={toggleExamDateSort}>Exam Date</button>
                            </th>
                            <th width="9.5%">
                                <button onClick={toggleScheduledDateSort}>Scheduled Date</button>
                            </th>
                            <th width="42.5%">Study Eye</th>
                            <th width="13.5%">Waiting for validation</th>
                            <th>Completion</th>
                        </tr>
                    </tbody>
                </table>
            </div>
            <div className="list-body">
                <table id="list-table">
                    <tbody className="list-visits">
                        {props.schedVisitsInfo.map((visit, i) => (
                            <ScheduledVisit
                                key={i}
                                setSchedVisitsInfo={props.setSchedVisitsInfo}
                                visitWrapper={visit}
                                schedVisits={props.schedVisits}
                                patient={props.patient}
                                schedVisitsInfo={props.schedVisitsInfo}
                                params={props.params}
                                getStatusDisplay={props.getStatusDisplay}
                                userAuth={props.userAuth}
                            />
                        ))}

                        {props.unschedVisits.map((visit, i) => (
                            <UnscheduledVisit
                                key={i}
                                visit={visit}
                                patient={props.patient}
                                setUnschedVisits={props.setUnschedVisits}
                                unschedVisits={props.unschedVisits}
                                params={props.params}
                                getStatusDisplay={props.getStatusDisplay}
                                userAuth={props.userAuth}
                            />
                        ))}
                    </tbody>
                </table>
            </div>
        </div>
    );
}


function ScheduledVisit(props) {

    const expandHandler = (ev) => {

        let editedSchedVisitsInfo = JSON.parse(JSON.stringify(props.schedVisitsInfo));
        let editedVisitWrapper = editedSchedVisitsInfo.find(v => {
            return v.id === props.visitWrapper.id;
        });

        if(ev.target.classList.contains("open")) {
            editedVisitWrapper.expanded = false;
            props.setSchedVisitsInfo(editedSchedVisitsInfo);
            ev.target.classList.remove("open");
        }
        else {
            editedVisitWrapper.expanded = true;
            props.setSchedVisitsInfo(editedSchedVisitsInfo);
            ev.target.classList.add("open");
        }
    };

    return (
        <React.Fragment>
        <tr>
            <td className="no-border" width="10%">{props.visitWrapper.name}</td>
            <td className="no-border" width="10%">{formatDate(props.visitWrapper.exam_date) || '--/--/--'}</td>
            <td className="no-border" width="9%">{formatDate(props.visitWrapper.scheduled_date) || '--/--/--'}</td>
            <td className="no-border" width="43%">
                <span className={"icon min-eye " + ( props.patient.study_right_eye ? "" : "enabled")}></span>
                <span className={"icon min-eye " + ( props.patient.study_left_eye ? "" : "enabled")}></span>
            </td>
            <td className="no-border" width="13%">
                <span>{props.visitWrapper.waiting_validation ? 'Yes' : 'No'}</span>
            </td>
            <td className="no-border">
                <span className="grey">{props.visitWrapper.completed ? 'Complete' : 'Incomplete'}</span>
                <button
                    className="expand"
                    onClick={(ev) => expandHandler(ev)}
                >&nbsp;</button>
            </td>
            {/*
            <td className="btn-list no-border">

                <Link to={"/studies/" + props.params.id + "/center/" + props.params.center + "/patients/" + props.params.patient + "/visits/"+ ( props.schedVisits.filter(v => v.scheduled_visit.id === props.visitWrapper.id)[0].id)} className="btn small grey">
                    <span className="icon-list report"></span>
                    Report
                </Link>
            </td>
            */
            }
        </tr>
        <tr className="expand" style={props.visitWrapper.expanded ? {display: 'table-row'} : {}}>
            <td colSpan="6">
                <table>
                    <thead>
                        <tr className="head">
                            <th width="12%">Submited eye</th>
                            <th width="10%">Exam</th>
                            <th width="10%">Upload date</th>
                            <th width="15%">Uploader</th>
                            <th width="30%">File/size</th>
                            <th width="12%">Status</th>
                            <th width="11%">Actions</th>
                        </tr>
                    </thead>
                    <tbody>
                        {props.schedVisits.map((visit, i) => visit.scheduled_visit.id === props.visitWrapper.id && (
                            <VisitItem
                                key={i}
                                visit={visit}
                                params={props.params}
                                patient={props.patient}
                                getStatusDisplay={props.getStatusDisplay}
                                userAuth={props.userAuth}
                            />
                        ))}
                    </tbody>
                </table>
            </td>
        </tr>
        </React.Fragment>
    );
}

function VisitItem(props) {
    return (
        <tr>
            <td width="12%">
                <span className={"icon min-eye "+( props.visit.right_eye_submit ? "" : "enabled") }></span>
                <span className={"icon min-eye "+( props.visit.left_eye_submit ? "" : "enabled") }></span>
            </td>
            <td width="10%">{props.visit.study_exam.exam_type.name}</td>
            <td width="10%">{formatDate(props.visit.submission_date,true)}</td>
            <td width="15%">{props.visit.user_submitted ? props.visit.user_submitted.username : ''}</td>
            <td width="30%">{props.visit.visit_files && props.visit.visit_files[0] ? props.visit.visit_files[0].name + " (" + prettyBytes(props.visit.visit_files[0].file && props.visit.visit_files[0].file.size ? props.visit.visit_files[0].file.size : 0) + ")" : ''}</td>
            <td width="12%">{props.getStatusDisplay(props.visit.exam_status)}</td>
            <td className="btn-list" style={{paddingLeft: 0}}>
                <ul>
                    <li>
                        {props.visit.exam_status !== 'pending' && (
                        <Link
                            to={"/studies/" + props.params.id + "/center/" + props.params.center + "/patients/" + props.params.patient + "/visits/"+ props.visit.id}
                            className="btn small grey"
                        >
                            &nbsp;&nbsp;<span className="icon-list report"></span>Report
                        </Link>
                        )}
                        {props.visit.exam_status === 'pending' && props.patient.study.status === 'open' && props.userAuth.user.role === 'admin' && (
                        <Link
                            to={"/studies/" + props.params.id + "/center/" + props.params.center + "/patients/" + props.params.patient + "/visits/"+ props.visit.id+ "/exams/" + props.visit.study_exam.exam_type.id + "/submit"}
                            className="btn small align-center" style={{color: 'red'}}
                        >
                            &nbsp;&nbsp;Admin Submit
                        </Link>
                        )}


                    </li>
                </ul>
            </td>
        </tr>
    );
}

function UnscheduledVisit(props) {
    const expandHandler = (ev) => {
        if(ev.target.classList.contains("open")) {
            props.visit.expanded = false;
            props.setUnschedVisits(JSON.parse(JSON.stringify(props.unschedVisits)));
            ev.target.classList.remove("open");
        }
        else {
            props.visit.expanded = true;
            props.setUnschedVisits(JSON.parse(JSON.stringify(props.unschedVisits)));
            ev.target.classList.add("open");
        }
    };

    return (
        <React.Fragment>
        <tr>
            <td className="no-border" width="10%"></td>
            <td className="no-border" width="9%" colSpan="2">{formatDate(props.visit.exam_date) || '--/--/--'}</td>
            <td className="no-border" width="41.5%">
                <span className={"icon min-eye " + ( props.patient.study_right_eye ? "" : "enabled")}></span>
                <span className={"icon min-eye " + ( props.patient.study_left_eye ? "" : "enabled")}></span>
            </td>
            <td className="no-border" width="13%">
                <span>{props.visit.exam_status === 'submitted' || props.visit.exam_status === 'rejected' ? 'Yes' : 'No'}</span>
            </td>
            <td className="no-border" width="14%">
                <span className="grey">{props.visit.exam_status === 'received' ? 'Complete' : 'Incomplete'}</span>
                <button
                    className="expand"
                    onClick={(ev) => expandHandler(ev)}
                ></button>
            </td>
        </tr>
        <tr className="expand" style={props.visit.expanded ? {display: 'table-row'} : {}}>
            <td colSpan="6">
                <table>
                    <thead>
                        <tr className="head">
                            <th width="12%">Submited eye</th>
                            <th width="7%">Exam</th>
                            <th>Upload date</th>
                            <th width="15%">Uploader</th>
                            <th width="40%">File/size</th>
                            <th width="12%">Status</th>
                            <th>Actions</th>
                        </tr>
                    </thead>
                    <tbody>
                        <VisitItem
                            visit={props.visit}
                            patient={props.patient}
                            params={props.params}
                            getStatusDisplay={props.getStatusDisplay}
                            userAuth={props.userAuth}
                        />
                    </tbody>
                </table>
            </td>
        </tr>
        </React.Fragment>
    );
}
