import React, {useEffect, useState}  from 'react';
import axios from 'axios';
import {Link} from 'react-router-dom';
import ReactHtmlParser from 'react-html-parser';
import CorcSelect from 'components/utils/CorcSelect';
import {formatDate} from 'components/utils/Helpers.js';
const prettyBytes = require('pretty-bytes');

function VisitReport(props) {
    useEffect(function() {

        props.setNavLinks([
            {title: 'Studies', url: '/studies/', icon: 'studies'},
            {title: 'Patients', url: '/studies/' + props.match.params.id + '/exams/'+props.match.params.exam+'/patients', icon: 'pacients'},
            {title: 'Visits', url: '/studies/' + props.match.params.id + '/exams/' + props.match.params.exam + '/patients/' + props.match.params.patient + '/visits/', icon: 'visits'},
            {title: 'Report', url: props.location.pathname, icon: 'menu-report'}
        ]);

        getVisit();

    }, []);

    const getVisit = async () => {
        let count = props.overlayVisible;
        props.setOverlayVisible( (count) => count + 1);

        await axios.get(process.env.REACT_APP_API_ENDPOINT+'/visits/'+props.match.params.visit+'/',
            { withCredentials: true, headers: {'Content-Type': 'application/json'}}).then(items => {

                if (items.data.operator) {
                    setOperatorOptions([{value:items.data.operator.id, label: items.data.operator.username + " (" + items.data.operator.name + ")"}]);
                }

                if (items.data.equipment_certification) {
                    setEquipmentOptions([{value:items.data.equipment_certification.id, label: items.data.equipment_certification.equipment_make_model}]);
                }

                if (items.data.exam_status === 'pending') {
                    items.data.related_visits = [];
                    setVisit(items.data);
                    props.history.push('/studies/'+props.match.params.id+'/center/' + props.match.params.center + '/patients/'+ props.match.params.patient + '/visits');
                }
                else {
                    setVisit(items.data);
                }

                props.setOverlayVisible( (count) => count - 1);


            }, (error) => {
                props.history.push('/studies/');
                props.setOverlayVisible( (count) => count - 1);
            });


    };

    const [operatorOptions, setOperatorOptions] = useState([]);
    const [equipmentOptions, setEquipmentOptions] = useState([]);
    const [showAdminFiles, setShowAdminFiles] = useState(false);
    const [uploadedFiles, setUploadedFiles] = useState([]);
    const [uploadedFilesComment, setUploadedFilesComment] = useState("");
    const [visit, setVisit] = useState({
        exam_date: '',
        patient: {
            study: {},
            center: {},
        },
        study_exam: {
            exam_type: {},
        },
        user_submitted: {},
        operator: {},
        user_graded: {},
        visit_files: [],
        related_visits: [],
        form_vars: [],
        grading_comment: '',
        equipment_certification: 0,
    });

    const onChangeHandler = (ev) => {
        let name = ev.target.name;
        let val = ev.target.value;

        setVisit({...visit, [name]: val});
    };

    const submitHandler = e => {
        e.preventDefault();
    };

    const submittedEyeOptions = [
        {value:"left", label: "Left"},
        {value:"right", label: "Right"},
        {value:"both", label: "Both"},
    ];

    const getOperatorChecked = () => {

        if (visit.operator) {
            return operatorOptions.filter(o => o.value === visit.operator.id);
        }

        return "";
    }

    const getEquipmentCertificationChecked = () => {

        if (visit.equipment_certification) {
            return equipmentOptions.filter(o => o.value === visit.equipment_certification.id);
        }

        return "";
    }

    const getEyeChecked = () => {

        if (visit.left_eye_submit && visit.right_eye_submit) {
            return submittedEyeOptions.filter(o => o.value === "both");
        }
        else if (visit.left_eye_submit && !visit.right_eye_submit) {
            return submittedEyeOptions.filter(o => o.value === "left");
        }
        else if (!visit.left_eye_submit && visit.right_eye_submit) {
            return submittedEyeOptions.filter(o => o.value === "right");
        }
        else {
            return "";
        }
    }

    const isGeneralVarVisible = (uid) => {
        if(visit.study_exam.hasOwnProperty('technician_form_vars')) {
            if(visit.study_exam.technician_form_vars.find(v => v.technician_form_var.uid === uid && v.technician_form_var.group === 'general')) {
                return true;
            }
        }

        return false;
    };

    return (
        <div className="home_header_container_class wide">
            <div className="content">
                <div className="breadcrumbs">
                    <div className="path">
                        <ul>


                            <li><span className="orange text-shadow">{visit.patient.study.acronym ? visit.patient.study.acronym.toUpperCase() : ''}</span></li>
                            <li className="separator text-shadow">&gt;</li>
                            <li><span className="orange text-shadow">{visit.study_exam.exam_type.name}</span></li>
                            <li className="separator text-shadow">&gt;</li>
                            <li><span className="orange text-shadow">Patient {visit.patient.patient_num}</span></li>
                            <li className="separator text-shadow">&gt;</li>
                            <li>{visit.scheduled_visit ? visit.scheduled_visit.name : 'Unscheduled visit'}</li>
                        </ul>
                    </div>
                </div> {/* .breadcrumbs */}

                <div className="report-info">
                    <div className="content-report">
                        <div className="content-visit-exam">
                            <div className="visit-content">
                                <div className="main-form">
                                    <form onSubmit={submitHandler} id="register-exam" method="post">
                                        <VisitSubmissionInfo visit={visit}/>
                                        <div className="form-group">
                                            <div className="span5 insert-visit" style={{width:"46%"}}>
                                                {isGeneralVarVisible('exam-date') && (
                                                <div className="row exam-date" style={{paddingTop: "4px"}}>
                                                    <div className="label">
                                                        <label>Exam Date</label>
                                                    </div>
                                                    <div className="input">
                                                        <input
                                                            type="text"
                                                            name="exam_date"
                                                            value={formatDate(visit.exam_date)}
                                                            disabled="disabled"
                                                            onChange={(ev) => onChangeHandler(ev)}
                                                        />
                                                    </div>
                                                </div>
                                                )}

                                                <div className="row" style={{paddingTop: "4px"}}>
                                                    <div className="label">
                                                        <label>Patient Study Eye</label>
                                                    </div>
                                                    <div className="input">
                                                        <div className="study-eye">
                                                            <span className={"icon min-eye" + (visit.patient.study_right_eye ? "" : " enabled")}></span>
                                                            <span className={"icon min-eye" + (visit.patient.study_left_eye ? "" : " enabled")}></span>
                                                        </div>
                                                    </div>
                                                </div> {/* .row */}

                                                {isGeneralVarVisible('submitted-eye') && (
                                                <div className="row" style={{paddingTop: "4px"}}>
                                                    <div className="label">
                                                        <label>Submitted Eye(s)</label>
                                                    </div>
                                                    <div className="input">


                                                        <CorcSelect
                                                            options={submittedEyeOptions}
                                                            name="submitted_eyes"
                                                            id="submitted_eyes"
                                                            value={getEyeChecked()}
                                                            isSearchable={false}
                                                            containerStyles={{width: "175px", float: "left"}}
                                                            isDisabled={"disabled"}
                                                        />

                                                    </div>
                                                </div>
                                                )}

                                                {isGeneralVarVisible('operator') && (
                                                <div className="row" style={{paddingTop: "4px"}}>
                                                    <div className="label">
                                                        <label>Operator</label>
                                                    </div>
                                                    <div className="input">

                                                        <CorcSelect
                                                            options={operatorOptions}
                                                            name="operator"
                                                            id="operator"
                                                            value={getOperatorChecked()}
                                                            isSearchable={false}
                                                            containerStyles={{width: "175px", float: "left"}}
                                                            isDisabled={"disabled"}
                                                        />
                                                    </div>
                                                </div>
                                                )}
                                                {isGeneralVarVisible('equipment') && (
                                                <div className="row" style={{paddingTop: "4px"}}>
                                                    <div className="label">
                                                        <label className="required" htmlFor="equipment">Equipment *</label>
                                                    </div>

                                                    <CorcSelect
                                                        options={equipmentOptions}
                                                        name="equipment"
                                                        id="equipment"
                                                        value={getEquipmentCertificationChecked()}
                                                        isSearchable={false}
                                                        containerStyles={{width: "175px", float: "left"}}
                                                        isDisabled="disabled"
                                                    />

                                                </div>
                                                )}
                                            </div> {/* .span5 */}

                                            <div className="span5" style={{width: "54%"}}>
                                                {isGeneralVarVisible('comment') && (
                                                <textarea
                                                    className="submit-comment"
                                                    placeholder="Comment"
                                                    style={{height:"164px !important"}}
                                                    disabled="disabled"
                                                    value={visit.comment}
                                                ></textarea>
                                                )}
                                            </div> {/* .span5 */}
                                        </div>
                                        <div className="form-group visit-other-vars">
                                            <VisitOtherVars
                                                visit={visit}
                                                setVisit={setVisit}
                                            />
                                        </div>
                                    </form>

                                    <VisitFiles
                                        visit={visit}
                                        setVisit={setVisit}
                                        showAdminFiles={showAdminFiles}
                                        setShowAdminFiles={setShowAdminFiles}
                                        uploadedFiles={uploadedFiles}
                                        setUploadedFiles={setUploadedFiles}
                                        setUploadedFilesComment={setUploadedFilesComment}
                                        uploadedFilesComment={uploadedFilesComment}
                                        userAuth={props.userAuth}
                                    />

                                    {(visit.grading_comment || visit.grading_internal_comment) && (
                                    <div className="form-group">

                                        {visit.grading_comment && (
                                        <div className="row" style={{paddingTop: "4px"}}>
                                            <div className="label">
                                                <label htmlFor="status" className="">Grading Comment</label>
                                            </div>
                                            <textarea
                                                className="submit-comment"
                                                name="grading_comment"
                                                style={{height:"164px !important", width: "80%"}}
                                                value={visit.grading_comment}
                                                disabled="disabled"
                                            ></textarea>
                                        </div>
                                        )}

                                        {visit.grading_internal_comment && (props.userAuth.user.role == 'admin' || props.userAuth.user.role == 'grader') && (
                                        <div className="row" style={{paddingTop: "4px"}}>
                                            <div className="label">
                                                <label htmlFor="status" className="">Grading Comment</label>
                                            </div>
                                            <textarea
                                                className="submit-comment"
                                                name="grading_internal_comment"
                                                style={{height:"164px !important", width: "80%"}}
                                                value={visit.grading_internal_comment}
                                                disabled="disabled"
                                            ></textarea>
                                        </div>
                                        )}


                                    </div>
                                    )}


                                    <div className="form-actions" style={{float: "right"}}>
                                        <Link to={'/studies/' + props.match.params.id + '/exams/' + props.match.params.exam + '/patients/' + props.match.params.patient + '/visits/'} className="btn small grey align-center left">Back</Link>
                                    </div>
                                </div> {/* .main-form */}
                            </div> {/* .visit-content */}
                        </div> {/* .content-visit-exam */}
                    </div> {/* .content-report */}
                </div>

            </div>
        </div>
    );
}

export default VisitReport;

function VisitSubmissionInfo(props) {

    let evaluatedBy = "", submittedBy = "", rejectedMessage = "";

    const getStatusDisplay = status => {

        if (status === 'pending') {
            return 'Scheduled';
        }
        else if (status === 'missed') {
            return 'Missed';
        }
        else if (status === 'rejected') {
            return 'Rejected';
        }
        else if (status === 'received') {
            return 'Received';
        }
        else if (status === 'submitted') {
            return 'Submitted'
        }

        return '';

    }

    if (props.visit.user_graded) {
        evaluatedBy = "<p>EVALUATED BY: "+ props.visit.user_graded.name + ' ' + props.visit.user_graded.last_name+"</p><p>EVALUATED ON: "+formatDate(props.visit.grading_date, true)+"</p><br />";
    }

    if (props.visit.user_submitted) {
        submittedBy = '<p>SUBMITTED BY: '+props.visit.user_submitted.name + ' ' + props.visit.user_submitted.last_name+'</p><p>SUBMITTED ON: '+formatDate(props.visit.submission_date, true)+'</p><br />';
    }

    if (props.visit.exam_status == 'rejected' && props.visit.reject_message) {
        rejectedMessage = '<br /><p>REJECTED MESSAGE: '+props.visit.reject_message+"</p><br />";
    }

    return (
        <div className="form-group content">
            <br />

            { ReactHtmlParser (evaluatedBy) }
            { ReactHtmlParser (submittedBy) }

            <p>VISIT STATUS: {getStatusDisplay(props.visit.exam_status)}</p>

            { ReactHtmlParser (rejectedMessage) }


        </div>
    );
}

function VisitFiles(props) {
    return (
        <React.Fragment>
            {props.visit.visit_files.map( (file, i) => (
            <VisitFileItem
                key={i}
                file={file}
                visit={props.visit}
            />
            ))}
        </React.Fragment>
    );
}


function VisitFileItem(props) {
//    const onChangeHandler = ev => {
//        let val = ev.target.value;
//        let name = ev.target.name;
//    };

    const style = {
        width: "40%",
        float: "right",
        marginRight: "32px",
        display: "block"
    }

    return (
        <div className="form-group">
            <div className="file-uploaded">
                <div className="row">
                    <a target="_blank" rel="noopener noreferrer" href={process.env.REACT_APP_API_ENDPOINT+'/visits/'+props.visit.id+'/file/'+props.file.id+'/download/'}  className="cancel-btn-class btn small grey align-center">Download</a>
                    <div className="file-name">
                        <span className="filename-class">{props.file ? props.file.name : ''}<b>{" (" + prettyBytes(props.file && props.file.file.size ? props.file.file.size : 0) + ")"}</b></span>
                    </div>

                   <div className="file-comment" style={style} ref={(node) => {
                      if (node) {
                        node.style.setProperty("float", "right", "important");
                      }
                    }}>
                        <input
                            maxLength="255"
                            type="text"
                            className="uploaded_file"
                            value={props.file ? props.file.description || '' : ''}
                            placeholder="Comment"
                            //onChange={(ev) => onChangeHandler(ev)}
                            disabled="disabled"
                        />
                    </div>
                </div>
            </div>
        </div>
    );

}

function VisitOtherVars(props) {
    return (
        <React.Fragment>
        {props.visit.study_exam.technician_form_vars && props.visit.study_exam.technician_form_vars.map((v, i) => v.technician_form_var.group === 'other' && (
            <div key={i}>
                <VisitVarItem
                    field={v.technician_form_var}
                    visit={props.visit}
                    setVisit={props.setVisit}
                />
            </div>
        ))}
        </React.Fragment>
    );
}

function VisitVarItem(props) {

    const onListChangeHandler = (uid, selectedOption) => {
        let editedVisit = JSON.parse(JSON.stringify(props.visit));

        const form_var = editedVisit.study_exam.technician_form_vars.find(c => {
            return c.technician_form_var.uid === uid;
        });

        if (!form_var) {
            return false;
        }

        let found = editedVisit.form_vars.find(c => {
            if (c.var_type) {
                return form_var.uid === c.var_type.uid;
            }
            else {
                return undefined;
            }

        });

        if (found === undefined) {
            let cVar = {'visit': editedVisit.id, 'var_type': form_var, 'value': selectedOption};

            editedVisit.form_vars.push(cVar);
        }
        else {
            found.value = selectedOption;
        }

        props.setVisit(editedVisit);
    };

    const onChangeHandler = (ev) => {
        let name = ev.target.name;
        let val = ev.target.value.toString();
        let editedVisit = JSON.parse(JSON.stringify(props.visit));
        let tmp, tmp_array;

        let form_var = props.visit.study_exam.technician_form_vars.find(c => {
            return c.technician_form_var.uid === name;
        })

        if (!form_var) {
            return false;
        }
        else {
            form_var = form_var.technician_form_var;
        }

        let found = editedVisit.form_vars.find(c => {
            if (c.var_type) {
                return form_var.uid === c.var_type.uid;
            }
            else {
                return undefined;
            }

        });

        if (found === undefined) {
            let cVar = {'visit': editedVisit.id, 'var_type': form_var, 'value': val};

            editedVisit.form_vars.push(cVar);
        }
        else {
            if (form_var.field_type === 'check_many') {
                tmp_array = found.value.split(",");
                tmp = tmp_array.indexOf(val);
                if (tmp === -1) {
                    tmp_array.push(val);
                    found.value = tmp_array.toString();
                }
                else {
                    tmp_array.splice(tmp, 1);
                    // is it the last value?
                    if (!tmp_array.length) {
                        for(let i=editedVisit.form_vars.length-1;i>=0;--i) {
                            if(editedVisit.form_vars[i].var_type.uid === form_var.uid) {
                                editedVisit.form_vars.splice(i, 1);
                                break;
                            }
                        }
                    }
                    found.value = tmp_array.toString();
                }
            }
            else {
                found.value = val;
            }
        }

        props.setVisit(editedVisit);

    };

    const onChangeDate = (date, form_var) => {
        let txtDate = '';
        let editedVisit = JSON.parse(JSON.stringify(props.visit));

        if (date) {
            txtDate = date.getFullYear() + '-' + (date.getMonth()+1) + '-' + date.getDate();
        }

        if (!form_var) {
            return false;
        }

        let found = editedVisit.form_vars.find(c => {
            if (c.var_type) {
                return form_var.uid === c.var_type.uid;
            }
            else {
                return undefined;
            }

        });

        if (found === undefined) {
            editedVisit.form_vars.push({'visit': editedVisit.id, 'value': txtDate, 'var_type': form_var});
        }
        else {
            found.value = txtDate;
        }

        props.setVisit(editedVisit);

    };

    const getVisitField = (field_id) => {

        let found = props.visit.form_vars.find(c => {
            if (c.var_type) {
                return c.var_type.id === field_id;
            }
            else {
                return undefined;
            }
        });

        if (found === undefined) {
            return "";
        }

        if(found.var_type.field_type === 'list') {
            let selOption = found.var_type.technician_vars_options.find(e => parseInt(e.id, 10) === parseInt(found.value, 10));
            return {label: selOption.value, value: selOption.id};
        }
        else {
            return found.value;
        }
    }

    const getMultipleVisitField = (field_id) => {

        let found = props.visit.form_vars.find(c => {
            if (c.var_type) {
                return c.var_type.id === field_id;
            }
            else {
                return undefined;
            }
        });

        if (found === undefined) {
            return [];
        }


        return found.value.split(",");
    }

    const buildField = (field) => {
        if(field.field_type === 'input') {
            return (
                <input
                    name={field.uid}
                    type="text"
                    value={getVisitField(field.id)}
                    onChange={(ev) => onChangeHandler(ev)}
                    disabled={true}
                />
            );
        }
        else if(field.field_type === 'date') {
            return (
                <input
                    name={field.uid}
                    selected={getVisitField(field.id)}
                    onChange={(date) => onChangeDate(date, field)}
                    disabled={true}
                />
            );
        }
        else if(field.field_type === 'text') {
            return (
                <textarea
                    name={field.uid}
                    value={getVisitField(field.id)}
                    onChange={(ev) => onChangeHandler(ev)}
                    disabled={true}
                >
                </textarea>
            );
        }
        else if(field.field_type === 'list') {
            let listOptions = [];
            field.technician_vars_options.map(opt => listOptions.push({label: opt.value, value: opt.id}));

            return (
                <CorcSelect
                    name={field.uid}
                    id={field.uid}
                    value={getVisitField(field.id)}
                    onChange={(selectedOption) => onListChangeHandler(field.uid, selectedOption.value)}
                    options={listOptions}
                    placeholder="-"
                    containerStyles={{width: "125px"}}
                    isDisabled={true}
                />
            );
        }
        else if(field.field_type === 'check_one') {

            return (
                <div className="checkbox-group check">
                {field.technician_vars_options.map((opt, i) => (
                    <React.Fragment
                        key={i}
                    >
                    <input
                        value={opt.id}
                        id={field.uid + '_' + i}
                        name={field.uid}
                        type="radio"
                        checked={getVisitField(field.id) === opt.id.toString()}
                        onChange={(ev) => onChangeHandler(ev)}
                        disabled={true}
                    />
                    <label htmlFor={field.uid + '_' + i}>{opt.value}</label>
                    </React.Fragment>
                ))}
                </div>
            );
        }
        else if(field.field_type === 'check_many') {

            return (
                <div className="checkbox-group check form-flex">
                {field.technician_vars_options.map((opt, i) => (
                    <div className="form-flex-3-col" key={i}>
                        <input
                            value={opt.id}
                            id={field.uid + '_' + i}
                            name={field.uid}
                            type="checkbox"
                            checked={getMultipleVisitField(field.id).indexOf(opt.id.toString()) !== -1}
                            onChange={(ev) => onChangeHandler(ev)}
                            disabled={true}
                        />
                        <label htmlFor={field.uid + '_' + i}>{opt.value}</label>
                    </div>
                ))}
                </div>
            );
        }

        else if(field.field_type === 'eye') {
            return (
                <div className="checkbox-group check">
                    <input
                        value="right"
                        id={field.uid + '_re'}
                        name={field.uid}
                        type="radio"
                        checked={getVisitField(field.id) === 'right'}
                        onChange={(ev) => onChangeHandler(ev)}
                        disabled={true}
                    />
                    <label htmlFor={field.uid + '_re'}>RE</label>
                    <input
                        value="left"
                        id={field.uid + '_le'}
                        name={field.uid}
                        type="radio"
                        checked={getVisitField(field.id) === 'left'}
                        onChange={(ev) => onChangeHandler(ev)}
                        disabled={true}
                    />
                    <label htmlFor={field.uid + '_le'}>LE</label>
                    <input
                        value="both"
                        id={field.uid + '_rle'}
                        name={field.uid}
                        type="radio"
                        checked={getVisitField(field.id) === 'both'}
                        onChange={(ev) => onChangeHandler(ev)}
                        disabled={true}
                    />
                    <label htmlFor={field.uid + '_rle'}>RLE</label>
                </div>
            );
        }
    };

    return (
        <div className="row" style={{paddingTop: "4px"}}>
            <div className="label">
                <label>{props.field.name}</label>
            </div>
            <div className="input">
                {buildField(props.field)}
            </div>
        </div>
    );
}

