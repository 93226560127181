import React, {useEffect, useState}  from 'react';
import axios from 'axios';
import { CSVLink } from "react-csv";
import jsPDF from "jspdf";
import "jspdf-autotable";
import CorcSelect from 'components/utils/CorcSelect';
import {initDate} from 'components/utils/Helpers.js';

function Logs(props) {

    const [logs, setLogs] = useState([]);
    const [initialLogs, setInitialLogs] = useState([]);
    const [currentVisit, setCurrentVisit] = useState({});
    const [detailsPopupVisible, setDetailsPopupVisible] = useState(false);
    const [dataToCSV, setDataToCSV] = useState([]);

    const headersCSV = [
        { label: "Date", key: "date" },
        { label: "User", key: "user" },
        { label: "IP Address", key: "ip_address" },
        { label: "Action", key: "action" },
        { label: "Actor", key: "actor" },
        { label: "Description", key: "description" },
        { label: "User Agent", key: "user_agent" },
        { label: "Details", key: "details" },
    ];

    const getActionDisplay = value => {

        if (value === 'created') {
            return 'Created';
        }
        else if (value === 'updated') {
            return 'Updated';
        }
        else if (value === 'deleted') {
            return 'Deleted';
        }

        return '';

    }

    const getActorDisplay = value => {

        if (value === 'centers') {
            return 'Centers';
        }
        else if (value === 'certifications') {
            return 'Certifications';
        }
        else if (value === 'studies') {
            return 'Studies';
        }
        else if (value === 'documents') {
            return 'Documents';
        }
        else if (value === 'patients') {
            return 'Patients';
        }
        else if (value === 'visits') {
            return 'Visits';
        }
        else if (value === 'settings') {
            return 'Settings';
        }
        else if (value === 'users') {
            return 'Users';
        }
        else if (value === 'homepage') {
            return 'Homepage';
        }
        else if (value === 'profile') {
            return 'Profile';
        }

        return '';

    }

    useEffect(function() {
        filter();
    }, [initialLogs]);

    useEffect(function() {

        let dataCSV = [];

        logs.map((l) => {

            let entry = {};
            entry.date = l.created_at;
            entry.user = l.user.username;
            entry.ip_address = l.ip_address;
            entry.action = getActionDisplay(l.action);
            entry.actor = getActorDisplay(l.actor);
            entry.description = l.description;
            if (l.diff) {
                entry.details = l.diff;
            }

            if (l.meta && l.meta.hasOwnProperty("HTTP_USER_AGENT")) {
                entry.user_agent = l.meta["HTTP_USER_AGENT"];
            }

            dataCSV.push(entry);

            return true;
        });

        setDataToCSV(dataCSV);


    }, [logs]);



    useEffect(function() {

        const getLogs = async () => {
            let count = props.overlayVisible, 
                items = [];
            props.setOverlayVisible( (count) => count + 1);

            await axios.get(process.env.REACT_APP_API_ENDPOINT+'/logs/',
                { withCredentials: true, headers: {'Content-Type': 'application/json'}}).then(response => {

                items = response.data.map((i) => {

                    try {
                        return {...i, meta: JSON.parse(i.meta)};
                    }
                    catch (e) {
                        return i;
                    }
                })



                setLogs(items);
                setInitialLogs(items);

                props.setOverlayVisible( (count) => count - 1);

            }, (error) => {
                props.setOverlayVisible( (count) => count - 1);
            });
        };

        props.setNavLinks([
            {title: 'Logs', url: '/logs', icon: 'menu-report'},
        ]);

        // do we have permissions?
        if (props.userAuth.user.role !== 'admin') {
            props.history.push('/studies');
            return;
        }

        // clear localstorage
        localStorage.setItem('corc_logs_filteredByUser', "");
        localStorage.setItem('corc_logs_filteredByAction', "");
        localStorage.setItem('corc_logs_filteredByActor', "");
        localStorage.setItem('corc_logs_filteredByIpAddress', "");

        localStorage.setItem('corc_logs_sortDate', "");
        localStorage.setItem('corc_logs_sortUser', "");
        localStorage.setItem('corc_logs_sortIpAddress', "");
        localStorage.setItem('corc_logs_sortAction', "");
        localStorage.setItem('corc_logs_sortActor', "");
        localStorage.setItem('corc_logs_sortDescription', "");

        getLogs();
    }, []);

    const filter = () => {

        let filteredLogs = initialLogs;
        let filteredByUser = localStorage.getItem('corc_logs_filteredByUser');
        let filteredByAction = localStorage.getItem('corc_logs_filteredByAction');
        let filteredByActor = localStorage.getItem('corc_logs_filteredByActor');
        let filteredByIpAddress = localStorage.getItem('corc_logs_filteredByIpAddress');

        if (filteredByUser) {
            filteredLogs = filteredLogs.filter(l => l.user.id === parseInt(filteredByUser,10));
        }

        if (filteredByAction) {
            filteredLogs = filteredLogs.filter(l => l.action === filteredByAction);
        }

        if (filteredByActor) {
            filteredLogs = filteredLogs.filter(l => l.actor === filteredByActor);
        }

        if (filteredByIpAddress) {
            filteredLogs = filteredLogs.filter(l => l.ip_address === filteredByIpAddress);
        }

        setLogs(filteredLogs);
    }

    const exportPDF = () => {

        const marginLeft = 40;
        const title = "";
        const doc = new jsPDF("landscape", "pt", "A4");
        const headers = [["Date", "User", "IP Address", "Action", "Actor", "Description"]];
        let data = [], content;

        doc.setFontSize(15);


       //alert('chamar export PDF');

        logs.forEach((l) => {

            let entry = [];
            entry.push(l.created_at);
            entry.push(l.user.username);
            entry.push(l.ip_address);
            entry.push(getActionDisplay(l.action));
            entry.push(getActorDisplay(l.actor));
            entry.push(l.description);

            data.push(entry);

            if (l.meta && l.meta.hasOwnProperty("HTTP_USER_AGENT")) {
                entry = [];
                entry.push("", "", "", "", "", l.meta["HTTP_USER_AGENT"]);
                data.push(entry);
            }

            if (l.diff) {
                entry = [];
                entry.push("", "", "", "", "", l.diff);
                data.push(entry);
            }

        });

        content = {
          startY: 50,
          head: headers,
          body: data,
          styles: {
            overflow: 'linebreak',
            //columnWidth: 'wrap',
          },
          columnStyles: {
              0: {
                  columnWidth: 100,
              },
              1: {
                  columnWidth: 80,
              },
              2: {
                  columnWidth: 80,
              },
              3: {
                  columnWidth: 50,
              },
              4: {
                  columnWidth: 80,
              }

          }

        };

        doc.text(title, marginLeft, 40);
        doc.autoTable(content);
        doc.save("logs-export.pdf")
  }

    return (
        <div className="home_header_container_class wide">
            <div className="content">
                <div className="breadcrumbs">
                    <div className="path">
                        <ul>
                            <li></li>
                        </ul>
                    </div>

                    <div className="sub-menu">
                        <ul className="button-group right">
                            <li>
                            </li>
                        </ul>

                    </div>
                </div> {/* .breadcrumbs */}

                <div className="top-buttons all-users">
                    <ul className="button-group right">
                        <li>
                            <UserDropdownFilter initialLogs={initialLogs} filter={filter}/>
                        </li>
                        <li>
                            <IpAddressDropdownFilter initialLogs={initialLogs} filter={filter}/>
                        </li>
                        <li>
                            <ActionDropdownFilter filter={filter}/>
                        </li>
                        <li>
                            <ActorDropdownFilter filter={filter}/>
                        </li>
                        <li>
                            <button onClick={() => exportPDF()} className="btn normal grey right">
                                <span className="icon icon-list report"></span>
                                Export to PDF
                            </button>
                        </li>
                        <li>

                            <CSVLink className="btn normal grey right" data={dataToCSV} headers={headersCSV} filename={"logs-export.csv"}>
                                <span className="icon icon-list report"></span>
                                Export to CSV
                            </CSVLink>
                        </li>

                    </ul>
                </div>


                {detailsPopupVisible && (
                <LogDetails
                    currentVisit={currentVisit}
                    setCurrentVisit={setCurrentVisit}
                    setDetailsPopupVisible={setDetailsPopupVisible}
                    detailsPopupVisible={detailsPopupVisible}
                />
                )}

                <LogList
                    logs={logs}
                    setLogs={setLogs}
                    currentVisit={currentVisit}
                    setCurrentVisit={setCurrentVisit}
                    setDetailsPopupVisible={setDetailsPopupVisible}
                    detailsPopupVisible={detailsPopupVisible}
                    getActionDisplay={getActionDisplay}
                    getActorDisplay={getActorDisplay}
                />
            </div>
        </div>

    );
}

export default Logs;

function UserDropdownFilter(props) {

    let userTmp = [];

    const filterByUser = val => {
        if (val === '-1') {
            localStorage.setItem('corc_logs_filteredByUser', "");
        }
        else {
            localStorage.setItem('corc_logs_filteredByUser', val);
        }

        props.filter();

        return false;
    }

    const usersOptions = [{label: 'All Users', value: '-1'}];
    props.initialLogs.map( (log) => {

        if (userTmp.indexOf(log.user.id) === -1) {
            userTmp.push(log.user.id);
            usersOptions.push({label: log.user.username, value: log.user.id});
        }

        return false;
    });

    return (
        <CorcSelect
            id="user-select"
            containerStyles={{width: "165px"}}
            name="user"
            onChange={selectedOption => filterByUser(selectedOption.value) }
            options={usersOptions}
            isSearchable={false}
            defaultValue={{label: 'All Users', value: '-1'}}
        />
    );
}

function IpAddressDropdownFilter(props) {

    let ipTmp = [];

    const filterByIpAddress = val => {
        if (val === '-1') {
            localStorage.setItem('corc_logs_filteredByIpAddress', "");
        }
        else {
            localStorage.setItem('corc_logs_filteredByIpAddress', val);
        }

        props.filter();

        return false;
    }

    const ipAddressesOptions = [{label: 'All IP Addresses', value: '-1'}];
    props.initialLogs.map( (log) => {

        if (ipTmp.indexOf(log.ip_address) === -1) {
            ipTmp.push(log.ip_address);
            ipAddressesOptions.push({label: log.ip_address, value: log.ip_address});
        }

        return false;
    });

    return (
        <CorcSelect
            id="ipaddress-select"
            containerStyles={{width: "165px"}}
            name="ipaddress"
            onChange={selectedOption => filterByIpAddress(selectedOption.value) }
            options={ipAddressesOptions}
            isSearchable={false}
            defaultValue={{label: 'All IP Addresses', value: '-1'}}
        />
    );
}

function ActionDropdownFilter(props) {

    const filterByAction = val => {
        if (val === '-1') {
            localStorage.setItem('corc_logs_filteredByAction', "");
        }
        else {
            localStorage.setItem('corc_logs_filteredByAction', val);
        }

        props.filter();

        return false;
    }

    const actionsOptions = [
        {label: 'All Actions', value: '-1'},
        {label: 'Created', value: 'created'},
        {label: 'Deleted', value: 'deleted'},
        {label: 'Updated', value: 'updated'},
    ];

    return (
        <CorcSelect
            id="action-select"
            containerStyles={{width: "165px"}}
            name="action"
            onChange={selectedOption => filterByAction(selectedOption.value) }
            options={actionsOptions}
            isSearchable={false}
            defaultValue={{label: 'All Actions', value: '-1'}}
        />
    );
}

function ActorDropdownFilter(props) {

    const filterByActor = val => {
        if (val === '-1') {
            localStorage.setItem('corc_logs_filteredByActor', "");
        }
        else {
            localStorage.setItem('corc_logs_filteredByActor', val);
        }

        props.filter();

        return false;
    }

    const actorsOptions = [
        {label: 'All Actors', value: '-1'},
        {label: 'Centers', value: 'centers'},
        {label: 'Certifications', value: 'certifications'},
        {label: 'Documents', value: 'documents'},
        {label: 'Homepage', value: 'homepage'},
        {label: 'Patients', value: 'patients'},
        {label: 'Profile', value: 'profile'},
        {label: 'Settings', value: 'settings'},
        {label: 'Studies', value: 'studies'},
        {label: 'Users', value: 'users'},
        {label: 'Visits', value: 'visits'},


    ];

    return (
        <CorcSelect
            id="actor-select"
            containerStyles={{width: "165px"}}
            name="actor"
            onChange={selectedOption => filterByActor(selectedOption.value) }
            options={actorsOptions}
            isSearchable={false}
            defaultValue={{label: 'All Actors', value: '-1'}}
        />
    );
}

function LogList(props) {

    const toggleUserSort = () => {

        const logs = JSON.parse(JSON.stringify(props.logs));
        let sort = localStorage.getItem('corc_logs_sortUser');

        if (sort === ">") {
            logs.sort((a, b) => (a.user.username.toLowerCase() < b.user.username.toLowerCase()) ? 1 : -1)
            localStorage.setItem('corc_logs_sortUser', "<")
        }
        else {
            logs.sort((a, b) => (a.user.username.toLowerCase() > b.user.username.toLowerCase()) ? 1 : -1)
            localStorage.setItem('corc_logs_sortUser', ">")
        }

        props.setLogs(logs);
    }

    const toggleActionSort = () => {

        const logs = JSON.parse(JSON.stringify(props.logs));
        let sort = localStorage.getItem('corc_logs_sortAction');

        if (sort === ">") {
            logs.sort((a, b) => (a.action.toLowerCase() < b.action.toLowerCase()) ? 1 : -1)
            localStorage.setItem('corc_logs_sortAction', "<")
        }
        else {
            logs.sort((a, b) => (a.action.toLowerCase() > b.action.toLowerCase()) ? 1 : -1)
            localStorage.setItem('corc_logs_sortAction', ">")
        }

        props.setLogs(logs);
    }

    const toggleActorSort = () => {

        const logs = JSON.parse(JSON.stringify(props.logs));
        let sort = localStorage.getItem('corc_logs_sortActor');

        if (sort === ">") {
            logs.sort((a, b) => (a.actor.toLowerCase() < b.actor.toLowerCase()) ? 1 : -1)
            localStorage.setItem('corc_logs_sortActor', "<")
        }
        else {
            logs.sort((a, b) => (a.actor.toLowerCase() > b.actor.toLowerCase()) ? 1 : -1)
            localStorage.setItem('corc_logs_sortActor', ">")
        }

        props.setLogs(logs);
    }

    const toggleDescriptionSort = () => {

        const logs = JSON.parse(JSON.stringify(props.logs));
        let sort = localStorage.getItem('corc_logs_sortDescription');

        if (sort === ">") {
            logs.sort((a, b) => (a.description.toLowerCase() < b.description.toLowerCase()) ? 1 : -1)
            localStorage.setItem('corc_logs_sortDescription', "<")
        }
        else {
            logs.sort((a, b) => (a.description.toLowerCase() > b.description.toLowerCase()) ? 1 : -1)
            localStorage.setItem('corc_logs_sortDescription', ">")
        }

        props.setLogs(logs);
    }

    const toggleIpAddressSort = () => {

        const logs = JSON.parse(JSON.stringify(props.logs));
        let sort = localStorage.getItem('corc_logs_sortIpAddress');

        if (sort === ">") {
            logs.sort((a, b) => (a.ip_address < b.user.ip_address) ? 1 : -1)
            localStorage.setItem('corc_logs_sortIpAddress', "<")
        }
        else {
            logs.sort((a, b) => (a.ip_address > b.ip_address) ? 1 : -1)
            localStorage.setItem('corc_logs_sortIpAddress', ">")
        }

        props.setLogs(logs);
    }


    const toggleDateSort = () => {

        const logs = JSON.parse(JSON.stringify(props.logs));
        let sort = localStorage.getItem('corc_logs_sortDate');

        if (sort === ">") {

            logs.sort(function(a, b) {
                a = a.created_at ? initDate(a.created_at) : new Date(a.created_at);
                b = b.created_at ? initDate(b.created_at) : new Date(b.created_at);

                return (a < b ? 1 : -1);

            });

            localStorage.setItem('corc_logs_sortDate', "<")
        }
        else {

            logs.sort(function(a, b) {
                a = a.created_at ? initDate(a.created_at) : new Date(a.created_at);
                b = b.created_at ? initDate(b.created_at) : new Date(b.created_at);

               return (a > b ? 1 : -1);

            });

            localStorage.setItem('corc_logs_sortDate', ">")
        }

        props.setLogs(logs);
    }

    return (

        <div className="visits-list list">
            <div className="list-head">
                <table>
                    <tbody>
                        <tr>
                            <th width="15%">
                                <button onClick={toggleDateSort}><b>Date</b></button>
                            </th>
                            <th width="10%">
                                <button onClick={toggleUserSort}>User</button>
                            </th>
                            <th width="10%">
                                <button onClick={toggleIpAddressSort}>Ip Address</button>
                            </th>
                            <th width="10%">
                                <button onClick={toggleActionSort}>Action</button>
                            </th>
                            <th width="10%">
                                <button onClick={toggleActorSort}>Actor</button>
                            </th>
                            <th width="35%">
                                <button onClick={toggleDescriptionSort}>Description</button>
                            </th>
                            <th width="10%">
                                <button></button>
                            </th>


                        </tr>
                    </tbody>
                </table>
            </div>
            <div className="list-body">
                <table id="list-table">
                    <tbody className="list-visits">

                        {props.logs.map((log, i) => (
                            <LogItem
                                key={i}
                                log={log}
                                currentVisit={props.currentVisit}
                                setCurrentVisit={props.setCurrentVisit}
                                setDetailsPopupVisible={props.setDetailsPopupVisible}
                                detailsPopupVisible={props.detailsPopupVisible}
                                getActionDisplay={props.getActionDisplay}
                                getActorDisplay={props.getActorDisplay}


                            />
                        ))}


                    </tbody>
                </table>
            </div>
        </div>
    );
}

function LogDetails(props) {

    const closePopup = () => {
        props.setDetailsPopupVisible(false);
        props.setCurrentVisit({});
    }

    const getUserAgent = () => {

        if (props.currentVisit.meta && props.currentVisit.meta.hasOwnProperty("HTTP_USER_AGENT")) {
            return props.currentVisit.meta["HTTP_USER_AGENT"];
        }

        return "";

    }

    return (
        <React.Fragment>
        <div className="modal fade in">
            <div className="modal-header">
                <h3>{props.currentVisit.description}</h3>
            </div>
            <div className="modal-body">

                <div className="form-group">
                    <div className="span5" style={{width: "100%"}}>

                        <div className="row">
                            <div className="label" style={{width: "136px"}}>
                                <span>User-Agent</span>
                            </div>
                            <div className="input">
                                <input
                                    style={{width: "100%"}}
                                    name="full_name"
                                    id="full_name"
                                    type="text"
                                    value={getUserAgent()}
                                    readOnly
                                />
                            </div>
                        </div>

                        {props.currentVisit.diff && (
                        <div className="row">
                            <div className="label" style={{width: "136px"}}>
                                <span>Changes in object</span>
                            </div>
                            <div className="input">
                                <textarea
                                    className="no-required"
                                    type="text"
                                    style={{width: "100%", height: "200px"}}
                                    name="diff"
                                    value={props.currentVisit.diff}
                                    readOnly
                                ></textarea>
                            </div>
                        </div>
                        )}

                    </div>
                </div>

            </div> {/* .modal-body */}
            <div className="modal-footer">
                <button
                    className="btn small orange align-center right"
                    onClick={closePopup}
                >OK
                </button>
            </div>
        </div>
        <div className="modal-backdrop fade in"></div>
        </React.Fragment>
    );

}

function LogItem(props) {

    const showDetails = (log) => {

        props.setCurrentVisit(log);
        props.setDetailsPopupVisible(true);
    }



    return (
        <tr className="visit">
            <td width="15%">{props.log.created_at}</td>
            <td>{props.log.user ? props.log.user.username : ''}</td>
            <td>{props.log.ip_address}</td>
            <td>{props.getActionDisplay(props.log.action)}</td>
            <td>{props.getActorDisplay(props.log.actor)}</td>
            <td>{props.log.description}</td>
            <td className="btn-list">
                <ul>
                    <li>
                        <button
                            onClick={() => showDetails(props.log)}
                            className="btn small grey"
                        >
                            <span className="study-visit-icon">Details</span>
                        </button>
                    </li>
                </ul>
            </td>
        </tr>
    );
}
