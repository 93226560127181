import {format, parse} from 'date-fns';

const formatDate = (rawDate, includeTime) => {
    if(rawDate) {
        let parsedDate;
        if(!includeTime) {
            parsedDate = parse(rawDate.split(' ')[0], 'yyyy-MM-dd', new Date());
            return format(parsedDate, 'dd-MM-yyyy');
        }
        else {
            parsedDate = parse(rawDate, 'yyyy-MM-dd HH:mm:ss', new Date());
            return format(parsedDate, 'dd-MM-yyyy HH:mm:ss');
        }
    }

    return "";
};

const initDate = txtDate => {

    if(txtDate && txtDate.trim()) {
        return parse(txtDate, 'yyyy-MM-dd', new Date());
    }


    return new Date();
};

const initDateTime = txtDate => {

    if(txtDate && txtDate.trim()) {
        return parse(txtDate, 'yyyy-MM-dd HH:mm:ss', new Date());
    }


    return new Date();
};

export {
    initDate,
    initDateTime,
    formatDate,
}
