import React, {useEffect, useState} from 'react';
//import {Link} from 'react-router-dom';
import CorcSelect from 'components/utils/CorcSelect';
import axios from 'axios';
import { CSVLink } from "react-csv";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import jsPDF from "jspdf";
import "jspdf-autotable";
import {initDate} from 'components/utils/Helpers.js';

function Users(props) {

    const [users, setUsers] = useState([]);
    const [initialUsers, setInitialUsers] = useState([]);
    const [errorPopupMessages, setErrorPopupMessages] = useState([]);
    const [errorPopupFields, setErrorPopupFields] = useState([]);

    useEffect(function() {

        const getUsers = async () => {

            let count = props.overlayVisible;
            props.setOverlayVisible( (count) => count + 1);

            await axios.get(process.env.REACT_APP_API_ENDPOINT+'/users/', { withCredentials: true}).then(response => {

                count = props.overlayVisible;
                props.setOverlayVisible(count => count - 1);

                if (response.status === 200) {
                    setUsers(response.data);
                    setInitialUsers(response.data);

                    if (props.match.params.hasOwnProperty("id")) {
                        showEditUserHandler(props.match.params.id);
                    }

                }

                }, (error) => {

                    count = props.overlayVisible;
                    props.setOverlayVisible(count => count - 1);

                    if (error.response.status === 401) {
                        props.resetAuth();
                        props.history.push('/login');
                    }
                }
            );

        }

        const getCenters = async () => {

            let count = props.overlayVisible;
            props.setOverlayVisible(count => count + 1);

            await axios.get(process.env.REACT_APP_API_ENDPOINT+'/centers/', { withCredentials: true}).then(response => {

                count = props.overlayVisible;
                props.setOverlayVisible(count => count - 1);

                if (response.status === 200) {
                        setCenters(response.data);
                    }

                }, (error) => {

                    count = props.overlayVisible;
                    props.setOverlayVisible(count => count - 1);

                    if (error.response.status === 401) {
                        props.resetAuth();
                        props.history.push('/login');
                    }
                });
        }

        // do we have permissions?
        if (props.userAuth.user.role !== 'admin') {
            props.history.push('/studies');
            return;
        }

        props.setNavLinks([{title: 'Users', url: '/users/', icon: 'users'}]);

        getUsers();
        getCenters();

        // clear localstorage
        localStorage.setItem('corc_users_filteredByCenter', "");
        localStorage.setItem('corc_users_filteredByGrade', "");
        localStorage.setItem('corc_users_sortUsername', "");
        localStorage.setItem('corc_users_sortCenter', "");
        localStorage.setItem('corc_users_sortProfile', "");

    }, []);

    useEffect(function() {

        const dataCSV = [];

        users.map(function(s) {

            let i, j, len, lenTechnicians, lenCoordinators, lenMonitors, lenGraders, lenActivations, lenStudyActivations, h, rows = [], item = {}, me = JSON.parse(JSON.stringify(s));

            me.center = me.center ? me.center.acronym : '---';
            me.status = me.is_active ? "Active" : "Not Active";
            me.role = me.role[0].toUpperCase() + me.role.substr(1);


            lenTechnicians = 0;
            lenCoordinators = 0;
            lenMonitors = 0;
            lenGraders = 0;
            lenActivations = 0;
            lenStudyActivations = 0;


            // studies where the user has been added
            if (me.hasOwnProperty("study_activation_history")) {

                lenStudyActivations = me.study_activation_history.length;
                if (lenStudyActivations >= 1) {
                    h = me.study_activation_history[0];
                    if (h.study) {
                        me.observations = h.study.acronym.toUpperCase() + ": ";
                        if (h.start_date) {
                            me.observations += h.start_date;
                        }

                        if (h.end_date) {
                            me.observations += " - " + h.end_date;
                        }
                        else {
                            me.observations += " - ongoing";
                        }
                    }
                }
            }


            // activations for this user
            if (me.hasOwnProperty("corc_activation_history")) {
                lenActivations = me.corc_activation_history.length;
                if (lenActivations >= 1) {
                    h = me.corc_activation_history[0];
                    if (h.start_date) {
                        me.date_enabled = h.start_date;
                    }
                    if (h.end_date) {
                        me.date_disabled = h.end_date;
                    }
                }
            }

            dataCSV.push(me);

            // see if we need to build new rows
            for (i=1; i<lenStudyActivations; ++i) {

                item = {};

                h = me.study_activation_history[i];
                if (h.study) {
                    item.observations = h.study.acronym.toUpperCase() + ": ";
                    if (h.start_date) {
                        item.observations += h.start_date;
                    }
                    if (h.end_date) {
                        item.observations += " - " + h.end_date;
                    }
                    else {
                        item.observations += " - ongoing";
                    }

                    rows.push(item);
                }
            }

            for (i=1, j=0; i<lenActivations; ++i, ++j) {

                if (typeof rows[j] === 'undefined') {
                    item = {};
                }
                else {
                    item = rows[j];
                }


                h = me.corc_activation_history[i];
                if (h.start_date) {
                    item.date_enabled = h.start_date;
                }
                if (h.end_date) {
                    item.date_disabled = h.end_date;
                }

                rows[j] = item;

            }

            // finally, iterate rows and add the lines
            for (i=0; i<rows.length; ++i) {
                dataCSV.push(rows[i]);
            }


            return false;

        });

        setDataToCSV(dataCSV);

    }, [users]);

    useEffect(function() {
        filter();

    }, [initialUsers]);

    const filter = () => {

        let filteredUsers = initialUsers;
        let filteredByGrade = localStorage.getItem('corc_users_filteredByGrade');
        let filteredByCenter = localStorage.getItem('corc_users_filteredByCenter');

        if (filteredByGrade) {
            filteredUsers = filteredUsers.filter(u => u.role === filteredByGrade);
        }

        if (filteredByCenter) {
            filteredUsers = filteredUsers.filter(u => u.center && parseInt(u.center.id, 10) === parseInt(filteredByCenter, 10));
        }

        setUsers(filteredUsers);
    }

    const showEditUserHandler = async (user_id) => {
        const errMsg = "Sorry, something went wrong while fetching this user's information. Please contact your IT helpdesk.";
        let count = props.overlayVisible;
        props.setOverlayVisible( (count) => count + 1);

        await axios.get(process.env.REACT_APP_API_ENDPOINT+'/users/'+user_id+'/', { withCredentials: true})
            .then((response) => {
                if (response.status === 200 && response.data && response.data.id) {
                    let user = response.data;

                    if(user.role === 'coordinator') {
                        user.studies = user.coordinator_studies;
                    }
                    else if(user.role === 'tech') {
                        user.studies = user.technician_studies;
                    }
                    else if(user.role === 'grader') {
                        user.studies = user.grader_studies;
                    }
                    else if(user.role === 'monitor') {
                        user.studies = user.monitor_studies;
                    }

                    setCurrentUser({...user});
                    setUserPopupVisible(true);
                    setErrorPopupFields([]);

                }
                else {
                    alert(errMsg);
                }
            }, (error)=> {
                alert(errMsg);
            });

        props.setOverlayVisible( (count) => count - 1);
    };

    const toggleUsernameSort = () => {

        let sort = localStorage.getItem('corc_users_sortUsername');

        if (sort === ">") {
            users.sort((a, b) => (a.username.toLowerCase() < b.username.toLowerCase()) ? 1 : -1)
            localStorage.setItem('corc_users_sortUsername', "<")
        }
        else {
            users.sort((a, b) => (a.username.toLowerCase() > b.username.toLowerCase()) ? 1 : -1)
            localStorage.setItem('corc_users_sortUsername', ">")
        }

        localStorage.setItem('corc_users_sortCenter', "");
        localStorage.setItem('corc_users_sortProfile', "");

        setUsers(JSON.parse(JSON.stringify(users)));
    }

    const toggleCenterSort = () => {

        let sort = localStorage.getItem('corc_users_sortCenter');

        if (sort === ">") {
            users.sort(function(a,b) {

                let aName = '', bName = '';
                if (a.center) {
                    aName = a.center.acronym.toLowerCase();
                }
                if (b.center) {
                    bName = b.center.acronym.toLowerCase();
                }

                return (aName < bName) ? 1 : -1
            })

            localStorage.setItem('corc_users_sortCenter', "<")
        }
        else {
            users.sort(function(a,b) {

                let aName = '', bName = '';
                if (a.center) {
                    aName = a.center.acronym.toLowerCase();
                }
                if (b.center) {
                    bName = b.center.acronym.toLowerCase();
                }

                return (aName > bName) ? 1 : -1
            })

            localStorage.setItem('corc_users_sortCenter', ">")
        }

        localStorage.setItem('corc_users_sortUsername', "");
        localStorage.setItem('corc_users_sortProfile', "");

        setUsers(JSON.parse(JSON.stringify(users)));
    }

    const toggleProfileSort = () => {

        let sort = localStorage.getItem('corc_users_sortProfile');

        if (sort === ">") {
            users.sort((a, b) => (a.role.toLowerCase() < b.role.toLowerCase()) ? 1 : -1)
            localStorage.setItem('corc_users_sortProfile', "<")
        }
        else {
            users.sort((a, b) => (a.role.toLowerCase() > b.role.toLowerCase()) ? 1 : -1)
            localStorage.setItem('corc_users_sortProfile', ">")
        }

        localStorage.setItem('corc_users_sortUsername', "");
        localStorage.setItem('corc_users_sortCenter', "");

        setUsers(JSON.parse(JSON.stringify(users)));

    }


    const [centers, setCenters] = useState([]);
    const [userPopupVisible, setUserPopupVisible] = useState(false);
    const [addUserPopupVisible, setAddUserPopupVisible] = useState(false);
    const [notificationPopupVisible, setNotificationPopupVisible] = useState(false);
    const [currentUser, setCurrentUser] = useState({
        center: {},
        studies: [],
    });
    const [newUser, setNewUser] = useState({
        center: {},
        username: '',
        role: '',
        email: ''
    });
    const [newUserType, setNewUserType] = useState('');
    const [activeTab, setActiveTab] = useState(1);

    const showAddUserPopup = () => {
        setAddUserPopupVisible(true);
    };

    const [dataToCSV, setDataToCSV] = useState([]);

    const headersCSV = [
      { label: "Username", key: "username" },
      { label: "Center", key: "center" },
      { label: "Name", key: "name" },
      { label: "Date Enabled", key: "date_enabled" },
      { label: "Date Disabled", key: "date_disabled" },
      { label: "Profile", key: "role" },
      { label: "Status", key: "status" },
      { label: "Observations", key: "observations" },
    ];

    const exportPDF = () => {

        const marginLeft = 40;
        const title = "";
        const doc = new jsPDF("landscape", "pt", "A4");

        doc.setFontSize(15);

        const headers = [["Username", "Center", "Name", "Data Enabled", "Date Disabled", "Profile", "Status", "Observations"]];

        const data = dataToCSV.map(i=> [i.username, i.center, i.name, i.date_enabled, i.date_disabled, i.role, i.status, i.observations]);
        let content = {
          startY: 50,
          head: headers,
          body: data
        };

        doc.text(title, marginLeft, 40);
        doc.autoTable(content);
        doc.save("users-export.pdf")
  }


    return (
        <div className="home_header_container_class">
            <div className="content">

                <div className="breadcrumbs">
                    <div className="path">
                        <ul>
                            <li>
                                Manage Users
                            </li>
                        </ul>
                    </div>
                    <div className="sub-menu">
                    </div>
                </div>

                <div className="top-buttons all-users">
                    <ul className="button-group right">
                        <li>
                            <CenterDropdownFilter centers={centers} filter={filter}/>
                        </li>
                        <li>
                            <GradeDropdownFilter centers={centers} filter={filter}/>
                        </li>
                        <li>
                            <button
                                id="add-user"
                                className="btn normal orange align-center"
                                style={{marginLeft: "8px"}}
                                onClick={() => showAddUserPopup()}
                            >
                                <span className="icon"></span>
                                Add User
                            </button>
                        </li>
                        <li>
                            <button onClick={() => exportPDF()} className="btn normal grey right">
                                <span className="icon icon-list report"></span>
                                Export to PDF
                            </button>
                        </li>
                        <li>

                            <CSVLink className="btn normal grey right" data={dataToCSV} headers={headersCSV} filename={"users-export.csv"}>
                                <span className="icon icon-list report"></span>
                                Export to CSV
                            </CSVLink>
                        </li>

                    </ul>
                </div>

                <div className="visits-list list">
                    <div className="list-head">
                        <table>
                            <tbody>
                                <tr>
                                    <th style={{width:"30%"}}>
                                        <button
                                            style={{padding: 0}}
                                            onClick={toggleUsernameSort}
                                        >
                                            Username
                                        </button>
                                    </th>
                                    <th style={{width:"32%"}}>
                                        <button onClick={toggleCenterSort}>
                                            Center
                                        </button>
                                    </th>
                                    <th style={{width:"30%"}}>
                                        <button onClick={toggleProfileSort}>
                                            Profile
                                        </button>
                                    </th>
                                    <th style={{width:"13%"}}></th>
                                </tr>
                            </tbody>
                        </table>
                    </div>

                    <div className="list-body">
                        <table id="list-table">
                            <tbody className="list-vists">
                                {users.map( (user, i) => (
                                    <UserListItem
                                        key={user.id}
                                        user={user}
                                        setUserPopupVisible={setUserPopupVisible}
                                        setCurrentUser={setCurrentUser}
                                        setNotificationPopupVisible={setNotificationPopupVisible}
                                        userAuth={props.userAuth}
                                        overlayVisible={props.overlayVisible}
                                        setOverlayVisible={props.setOverlayVisible}
                                        setErrorPopupFields={setErrorPopupFields}
                                        showEditUserHandler={showEditUserHandler}
                                    />
                                ))}
                            </tbody>
                        </table>
                    </div>
                </div>
                {userPopupVisible && (
                    <UserPopup
                        setUserPopupVisible={setUserPopupVisible}
                        currentUser={currentUser}
                        setCurrentUser={setCurrentUser}
                        activeTab={activeTab}
                        setActiveTab={setActiveTab}
                        users={users}
                        setUsers={setUsers}
                        userAuth={props.userAuth}
                        overlayVisible={props.overlayVisible}
                        setOverlayVisible={props.setOverlayVisible}
                        setErrorPopupFields={setErrorPopupFields}
                        errorPopupFields={errorPopupFields}
                    />
                )}

                {addUserPopupVisible && (
                    <UserAddPopup
                        setAddUserPopupVisible={setAddUserPopupVisible}
                        newUser={newUser}
                        setNewUser={setNewUser}
                        setNewUserType={setNewUserType}
                        centers={centers}
                        users={users}
                        setUsers={setUsers}
                        userAuth={props.userAuth}
                        overlayVisible={props.overlayVisible}
                        setOverlayVisible={props.setOverlayVisible}
                        setErrorPopupMessages={setErrorPopupMessages}
                        setErrorPopupFields={setErrorPopupFields}
                        errorPopupMessages={errorPopupMessages}
                        errorPopupFields={errorPopupFields}
                        filter={filter}
                        initialUsers={initialUsers}
                        setInitialUsers={setInitialUsers}
                    />
                )}

                {notificationPopupVisible && (
                    <UserNotificationPopup
                        setNotificationPopupVisible={setNotificationPopupVisible}
                        currentUser={currentUser}
                        setCurrentUser={setCurrentUser}
                        users={users}
                        setUsers={setUsers}
                        userAuth={props.userAuth}
                        overlayVisible={props.overlayVisible}
                        setOverlayVisible={props.setOverlayVisible}

                    />
                )}
            </div>
        </div>
    );
}


function GradeDropdownFilter(props) {

    const filterByGrade = val => {

        if (val === '-1') {
            localStorage.setItem('corc_users_filteredByGrade', "");
        }
        else {
            localStorage.setItem('corc_users_filteredByGrade', val);
        }

        props.filter();
    }

    const filterOptions = [
        {label: 'All Profiles', value: '-1'},
        {label: 'Technician', value: 'tech'},
        {label: 'Grader', value: 'grader'},
        {label: 'Monitor', value: 'monitor'},
        {label: 'Coordinator', value: 'coordinator'},
        {label: 'Administrator', value: 'admin'},
    ];

     return (

        <CorcSelect
            id="profile-select"
            containerStyles={{width: "165px"}}
            name="profile"
            onChange={selectedOption => filterByGrade(selectedOption.value) }
            options={filterOptions}
            isSearchable={false}
            defaultValue={{label: 'All profiles', value: '-1'}}
        />
     );

}

function CenterDropdownFilter(props) {

    const filterByCenter = val => {
        if (val === '-1') {
            localStorage.setItem('corc_users_filteredByCenter', "");
        }
        else {
            localStorage.setItem('corc_users_filteredByCenter', val);
        }

        props.filter();

        return false;
    }

    const centersOptions = [{label: 'All Centers', value: '-1'}];
    props.centers.map( (center) => {
        centersOptions.push({label: center.acronym, value: center.id});
        return false;
    });

    return (
        <CorcSelect
            id="center-select"
            containerStyles={{width: "165px"}}
            name="center"
            onChange={selectedOption => filterByCenter(selectedOption.value) }
            options={centersOptions}
            isSearchable={false}
            defaultValue={{label: 'All Centers', value: '-1'}}
        />
    );
}

export default Users;


function UserListItem(props) {
    const userTypes = {
        'tech': 'Technician',
        'admin': 'Administrator',
        'monitor': 'Monitor',
        'grader': 'Grader',
        'coordinator': 'Coordinator',
    };
    //const userType = 'admin';
    const showNotificationPopup = () => {
        props.setCurrentUser({...props.user});
        props.setErrorPopupFields([]);
        props.setNotificationPopupVisible(true);
    };



    return (
        <tr className={"visit" + (props.user.confirmed_registration ? '' : ' light')}>


            {!props.user.is_active ? (

            <React.Fragment>
            <td style={{width: "31%", textDecorationLine: "line-through"}}>
                {props.user.username}
            </td>

            <td style={{width: "32%", textDecorationLine: "line-through"}}>
                {props.user.center ? props.user.center.acronym : ''}
            </td>
            </React.Fragment>
            ) :
            (
            <React.Fragment>
            <td style={{width: "31%"}}>
                {props.user.username}
            </td>

            <td style={{width: "32%"}}>
                {props.user.center ? props.user.center.acronym : ''}
            </td>
            </React.Fragment>
            )}


            <td style={{width: "30%"}}>
                {userTypes[props.user.role]}
            </td>
            <td className="btn-list" width="10%">
                <ul>
                    <li>
                        {!props.user.confirmed_registration ? (
                            <button
                                className="btn xsmall grey"
                                onClick={() => showNotificationPopup()}
                            >
                                <span className="icon-list user-small"></span>
                            </button>
                        ) :
                        (
                            <span style={{display: 'inline-block', width: '27px', height: '39px'}}>&nbsp;</span>
                        )}
                    </li>
                    <li>
                        <button
                            className="btn xsmall grey edit-user"
                            onClick={() => props.showEditUserHandler(props.user.id)}
                        >
                            <span className="icon-list study-details-small"></span>
                        </button>
                    </li>
                </ul>
            </td>
        </tr>
    );
}

function UserPopup(props) {
    const onChangeTab = (ev, tab) => {

        ev.preventDefault();

        ev.target.classList.toggle('select');
        props.setActiveTab(tab);
    };

    const validateUser = (user) => {

        let errorFields = [];
        let editedUser = JSON.parse(JSON.stringify(user));

        // validate required fields
        editedUser.name = editedUser.name.trim();
        editedUser.last_name = editedUser.last_name.trim();
        editedUser.email = editedUser.email.trim();
        editedUser.birth_date = editedUser.birth_date ? editedUser.birth_date.trim() : '';
        editedUser.address = editedUser.address.trim();
        editedUser.postcode = editedUser.postcode.trim();
        editedUser.country = editedUser.country.trim();
        editedUser.phone = editedUser.phone.trim();
        editedUser.fax = editedUser.fax.trim();

        if (!editedUser.name) {
            errorFields.push('name');
        }

        if (!editedUser.last_name) {
            errorFields.push('last_name');
        }

        if (!editedUser.email || !(/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(editedUser.email))) {
            errorFields.push('email');
        }

        if (!editedUser.country) {
            errorFields.push('country');
        }

        if (!editedUser.phone) {
            errorFields.push('phone');
        }

        props.setErrorPopupFields(errorFields);
        props.setCurrentUser({...editedUser});

        if (errorFields.length) {
            return false;
        }

      return true;

    };

    const submitUserHandler = async () => {
        const errMsg = "Sorry, something went wrong when editing this user. Please contact your IT helpdesk.";

        if (validateUser(props.currentUser)) {
            let count = props.overlayVisible;
            props.setOverlayVisible( (count) => count + 1);

            await axios.patch(process.env.REACT_APP_API_ENDPOINT+'/users/'+props.currentUser.id+'/', {...props.currentUser}, { withCredentials: true})
                .then((response) => {

                if (response.status === 200 && response.data && response.data.id) {

                    let currUser = response.data;
                    let editedUsers = JSON.parse(JSON.stringify(props.users));

                    //props.setCurrentUser({...response.data, errorFields: []});

                    for(let i=0, len=editedUsers.length; i<=len-1; ++i) {
                        if(editedUsers[i].id === currUser.id) {
                            editedUsers.splice(i, 1, currUser);
                        }
                    }

                    props.setUsers(editedUsers);
                    props.setUserPopupVisible(false);
                    props.setErrorPopupFields([]);


                }
                else {
                    alert(errMsg);
                }

            }, (error) => {
                alert(errMsg);
            });

            props.setOverlayVisible( (count) => count - 1);
        }
    };

    const activateUserHandler = async () => {
        const errMsg = "Sorry, something went wrong when changing this user's state. Please contact your IT helpdesk.";

        let count = props.overlayVisible;
        props.setOverlayVisible( (count) => count + 1);

        await axios.post(process.env.REACT_APP_API_ENDPOINT+'/users/'+props.currentUser.id+'/change_state/', {state: !props.currentUser.is_active}, { withCredentials: true})
            .then((response) => {

                if (response.status === 200 && response.data && response.data.id) {

                    //props.setCurrentUser({...response.data, errorFields: []});

                    let editedUsers = JSON.parse(JSON.stringify(props.users));
                    for(let i=0, len=editedUsers.length; i<=len-1; ++i) {
                        if(editedUsers[i].id === response.data.id) {
                            editedUsers.splice(i, 1, response.data);
                        }
                    }

                    props.setUsers(editedUsers);
                    props.setUserPopupVisible(false)

                }
                else {
                    alert(errMsg);
                }
            }, (error)=> {
                alert(errMsg);
            });

        props.setOverlayVisible( (count) => count - 1);
    };

    return (
        <React.Fragment>
        <div className="modal fade in">
            <div className="modal-body">
                <div className="modal-tabs">
                    <ul>
                        <li><a href="#tab1" onClick={(ev) => onChangeTab(ev, 1)} className={props.activeTab === 1 ? "select" : ""}>User Details</a></li>
                        <li><a href="#tab2" onClick={(ev) => onChangeTab(ev, 2)} className={props.activeTab === 2 ? "select" : ""}>Studies</a></li>
                    </ul>

                    <div className="content-tabs">
                        <div id="tab1" style={{display: props.activeTab === 1 ? 'block' : 'none'}}>
                            <UserPopupDetails
                                currentUser={props.currentUser}
                                setCurrentUser={props.setCurrentUser}
                                errorPopupFields={props.errorPopupFields}
                            />
                        </div>
                        <div id="tab2" style={{display: props.activeTab === 2 ? 'block' : 'none'}}>
                            <UserPopupStudies
                                currentUser={props.currentUser}
                                setCurrentUser={props.setCurrentUser}
                                setUserPopupVisible={props.setUserPopupVisible}
                                userAuth={props.userAuth}
                                overlayVisible={props.overlayVisible}
                                setOverlayVisible={props.setOverlayVisible}

                            />
                        </div>
                    </div> {/* .content-tabs */}
                </div> {/* .modal-tabs */}
            </div> {/* .modal-body */}
            <div className="modal-footer">
                <input
                    type="submit"
                    className="btn small orange align-center right"
                    value="Save"
                    onClick={() => submitUserHandler()}
                />
                <button
                    className="btn small grey align-center right"
                    onClick={() => props.setUserPopupVisible(false)}
                >Close</button>
                <button
                    className="btn small grey align-center right"
                    onClick={() => activateUserHandler()}
                >{props.currentUser.is_active ? 'Deactivate' : 'Activate'}</button>
            </div>
        </div>

        <div className="modal-backdrop fade in"></div>
        </React.Fragment>
    );
}

function UserPopupDetails(props) {
    const onChangeHandler = ev => {
        let name = ev.target.name;
        let value = ev.target.value;

        let editedUser = JSON.parse(JSON.stringify(props.currentUser));
        props.setCurrentUser({...editedUser, [name]: value});
    };

    const getErrorClass = (type) => {

        if (props.errorPopupFields.indexOf(type) !== -1) {
            return "error";
        }

        return "";

    }

    const onChangeBirth = (date) => {
        let txtDate = '';

        if (date) {
            txtDate = date.getFullYear() + '-' + (date.getMonth()+1) + '-' + date.getDate();
            props.setCurrentUser({...props.currentUser, 'birth_date': txtDate});
        }
        else {
            props.setCurrentUser({...props.currentUser, 'birth_date': ''});
        }
    };

    const submitHandler = e => {
        e.preventDefault();
    };

    return (

        <form onSubmit={submitHandler} id="register-center" method="post">
            <div className="modal-form">
                <div className="form-group">
                    <div className="modal-errors" style={{marginLeft: "15px"}}></div>

                    <div className="span5" style={{width: "100%"}}>
                        <div className="row">
                            <div className="label" style={{width: "136px", marginTop: "-8px"}}>
                                <label>Status:</label>
                            </div>
                            <div className="input" style={{paddingTop: "2px", paddingBottom: "4px"}}>
                                <span>{props.currentUser.is_active ? 'Active' : 'Not active'}</span>
                            </div>
                        </div>
                        <div className="row">
                            <div className="label" style={{width: "136px"}}>
                                <label htmlFor="username" className="required">Username <span className="required">*</span></label>
                            </div>
                            <div className="input">
                                <input
                                    style={{width: "338px"}}
                                    name="username"
                                    id="username"
                                    type="text"
                                    value={props.currentUser.username}
                                    disabled={true}
                                />
                            </div>
                        </div>

                        <div className="row">
                            <div className="label" style={{width: "136px"}}>
                                <label htmlFor="name" className="required">Name <span className="required">*</span></label>
                            </div>
                            <div className="input">
                                <input
                                    style={{width: "338px"}}
                                    name="name"
                                    id="name"
                                    type="text"
                                    defaultValue={props.currentUser.name}
                                    onBlur={ev => onChangeHandler(ev)}
                                    className={getErrorClass('name')}
                                />
                            </div>
                        </div>

                        <div className="row">
                            <div className="label" style={{width: "136px"}}>
                                <label htmlFor="last_name" className="required">Last name <span className="required">*</span></label>
                            </div>
                            <div className="input">
                                <input
                                    style={{width: "338px"}}
                                    name="last_name"
                                    id="last_name"
                                    type="text"
                                    defaultValue={props.currentUser.last_name}
                                    onBlur={ev => onChangeHandler(ev)}
                                    className={getErrorClass('last_name')}
                                />
                            </div>
                        </div>

                        <div className="row">
                            <div className="label" style={{width: "136px"}}>
                                <label htmlFor="email" className="required">Email <span className="required">*</span></label>
                            </div>

                            <div className="input">

                                <input
                                    style={{width: "338px"}}
                                    name="email"
                                    id="email"
                                    type="text"
                                    defaultValue={props.currentUser.email}
                                    onBlur={ev => onChangeHandler(ev)}
                                    className={getErrorClass('email')}
                                />
                            </div>
                        </div>

                        <div className="row">
                            <div className="label" style={{width: "136px"}}>
                                <label htmlFor="birth_date">Date of Birth</label>
                            </div>

                            <div className="input">
                                <DatePicker
                                    dateFormat="dd/MM/yyyy"
                                    name="birth_date"
                                    selected={props.currentUser.birth_date && initDate(props.currentUser.birth_date)}
                                    onChange={(ev) => onChangeBirth(ev)}
                                />
                            </div>
                        </div>

                        <div className="row">
                            <div className="label" style={{width: "136px", marginTop: "-8px"}}>
                                <label>Center:</label>
                            </div>
                            <div className="input" style={{paddingTop: "2px", paddingBottom: "4px", width: "360px"}}>
                                <span>{props.currentUser.center ? (props.currentUser.center.full_name ? props.currentUser.center.full_name : props.currentUser.center.acronym) : ''}</span>
                            </div>
                        </div>

                        <div className="row">
                            <div className="label" style={{width: "136px"}}>
                                <label htmlFor="address">Address</label>
                            </div>
                            <div className="input">
                                <input
                                    style={{width: "338px"}}
                                    name="address"
                                    id="address"
                                    type="text"
                                    defaultValue={props.currentUser.address}
                                    onBlur={ev => onChangeHandler(ev)}
                                />
                            </div>
                        </div>

                        <div className="row">
                            <div className="label" style={{width: "136px"}}>
                                <label htmlFor="postcode">Post Code</label>
                            </div>
                            <div className="input">
                                <input
                                    style={{width: "131px"}}
                                    name="postcode"
                                    id="postcode"
                                    type="text"
                                    defaultValue={props.currentUser.postcode}
                                    onBlur={ev => onChangeHandler(ev)}
                                />
                            </div>
                        </div>

                        <div className="row">
                            <div className="label" style={{width: "136px"}}>
                                <label htmlFor="country" className="required">Country <span className="required">*</span></label>
                            </div>
                            <div className="input">
                                <input
                                    style={{width: "338px"}}
                                    name="country"
                                    id="country"
                                    type="text"
                                    defaultValue={props.currentUser.country}
                                    onBlur={ev => onChangeHandler(ev)}
                                    className={getErrorClass('country')}
                                />
                            </div>
                        </div>

                        <div className="row">
                            <div className="label" style={{width: "136px"}}>
                                <label htmlFor="phone" className="required">Telephone <span className="required">*</span></label>
                            </div>
                            <div className="input">
                                <input
                                    style={{width: "131px"}}
                                    name="phone"
                                    id="phone"
                                    type="text"
                                    defaultValue={props.currentUser.phone}
                                    onBlur={ev => onChangeHandler(ev)}
                                    className={getErrorClass('phone')}
                                />
                            </div>
                        </div>

                        <div className="row">
                            <div className="label" style={{width: "136px"}}>
                                <label htmlFor="fax">Fax</label>
                            </div>
                            <div className="input">
                                <input
                                    style={{width: "131px"}}
                                    name="fax"
                                    id="fax"
                                    type="text"
                                    defaultValue={props.currentUser.fax}
                                    onBlur={ev => onChangeHandler(ev)}
                                />
                            </div>
                        </div>

                        <br />

                        <div className="row">
                            <div className="label" style={{width: "126px"}}>
                                <label htmlFor="comment">Observation</label>
                            </div>
                            <div className="input">
                                <textarea
                                    className="submit-comment"
                                    style={{width: "328px !important"}}
                                    name="comments"
                                    id="comments"
                                    defaultValue={props.currentUser.comments}
                                    onBlur={ev => onChangeHandler(ev)}
                                ></textarea>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </form>
    );
}

function UserPopupStudies(props) {

    const changeUserStudyState = async (studyUser) => {
        const errMsg = "Sorry, something went wrong when changing this user's state. Please contact your IT helpdesk.";

        let count = props.overlayVisible;
        props.setOverlayVisible( (count) => count + 1);

        await axios.put(process.env.REACT_APP_API_ENDPOINT+'/studies/'+studyUser.study.id+'/users/'+studyUser.id+'/change_user_state/', {state: !studyUser.has_permission, role: props.currentUser.role}, { withCredentials: true})
            .then((response) => {
                props.setOverlayVisible( (count) => count - 1);
                if (response.status === 200) {
                    studyUser.has_permission = !studyUser.has_permission;
                    props.setCurrentUser({...props.currentUser, errorFields: []});
                    props.setUserPopupVisible(false)
                }
                else {
                    alert(errMsg);
                }
            }, (error)=> {
                alert(errMsg);
                props.setOverlayVisible( (count) => count - 1);
            });
    };

    return (
        <div className="studies-user">
            {props.currentUser.hasOwnProperty("studies") && props.currentUser.studies.map((sUser, i) => (
            <div className="study-user" key={i}>

                {sUser.has_permission ? (
                <label>{sUser.study.acronym}</label>
                ) : (
                <label style={{textDecorationLine: "line-through"}}>{sUser.study.acronym}</label>
                )}
                <button
                    onClick={() => changeUserStudyState(sUser)}
                    className="btn small orange align-center right"
                >{sUser.has_permission ? 'Deactivate' : 'Activate'}</button>
            </div>
            ))}
        </div>
    );
}

function UserAddPopup(props) {

    const userTypeOptions = [
        {label: 'Technician', value: 'tech'},
        {label: 'Grader', value: 'grader'},
        {label: 'Monitor', value: 'monitor'},
        {label: 'Coordinator', value: 'coordinator'},
        {label: 'Administrator', value: 'admin'},
    ];

    const onChangeHandler = (ev) => {
        let val = ev.target.value.trim();
        let name = ev.target.name;

        let editedNewUser = JSON.parse(JSON.stringify(props.newUser));
        props.setNewUser({...editedNewUser, [name]: val});
    };

    const onChangeUserType = (val) => {
        let editedNewUser = JSON.parse(JSON.stringify(props.newUser));
        props.setNewUser({...editedNewUser, role: val});
    };

    const onChangeCenterHandler = (val) => {
        let editedNewUser = JSON.parse(JSON.stringify(props.newUser));
        props.setNewUser({...editedNewUser, center: {id: parseInt(val, 10)}});
    };

    let centersOptions = [];
    props.centers.forEach(c => {
        centersOptions.push({label: c.acronym, value: c.id});
    });

    const validateUser = (user) => {

        let errorFields = [];
        let editedUser = JSON.parse(JSON.stringify(user));

        // validate required fields
        editedUser.name = editedUser.name.trim();
        editedUser.last_name = editedUser.last_name.trim();
        editedUser.email = editedUser.email.trim();
        editedUser.birth_date = editedUser.birth_date ? editedUser.birth_date.trim() : '';
        editedUser.address = editedUser.address.trim();
        editedUser.postcode = editedUser.postcode.trim();
        editedUser.country = editedUser.country.trim();
        editedUser.phone = editedUser.phone.trim();
        editedUser.fax = editedUser.fax.trim();

        if (!editedUser.name) {
            errorFields.push('name');
        }

        if (!editedUser.last_name) {
            errorFields.push('last_name');
        }

        if (!editedUser.email || !(/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(editedUser.email))) {
            errorFields.push('email');
        }

        if (!editedUser.country) {
            errorFields.push('country');
        }

        if (!editedUser.phone) {
            errorFields.push('phone');
        }

        props.setErrorPopupFields(errorFields);
        props.setCurrentUser({...editedUser});

        if (errorFields.length) {
            return false;
        }

      return true;

    };

    const validateAddUser = (user) => {
        // const errMsg = "Please fill all fields!";
        let errorFields = [], errorMessages = [];
        let editedUser = JSON.parse(JSON.stringify(props.newUser));

        // validate required fields
        editedUser.username = editedUser.username.trim();
        editedUser.email = editedUser.email.trim();

        if (!editedUser.role) {
            errorFields.push('role');
            errorMessages.push('Role cannot be blank.');
        }

        if (editedUser.role === 'tech' || editedUser.role === 'coordinator') {
            if (!editedUser.center.hasOwnProperty('id') || !editedUser.center.id) {
                errorFields.push('center');
                errorMessages.push('Center cannot be blank.');
            }
        }


        if (!editedUser.username) {
            errorFields.push('username');
            errorMessages.push('Username cannot be blank.');
        }

        if (!editedUser.email || !(/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(editedUser.email))) {
            errorFields.push('email');
            errorMessages.push('The email is not valid.');
        }

        // we need to check if we already have the username
        props.users.map((u) => {

           if (u.username === editedUser.username) {
               errorFields.push('username');
               errorMessages.push('The username you want to add already exists.');
               return true;
           }

           return false;
        });

        props.setErrorPopupFields(errorFields);
        props.setNewUser({...editedUser});

        if (errorFields.length) {
            props.setErrorPopupMessages(errorMessages);
            //alert(errMsg);
            return false;
        }

      return true;
    }

    const submitNewUserHandler = async () => {
        const errMsg = "Sorry, something went wrong when adding this user. Please contact your IT helpdesk.";

        if (validateAddUser(props.newUser)) {
            let count = props.overlayVisible;
            props.setOverlayVisible( (count) => count + 1);

            await axios.post(process.env.REACT_APP_API_ENDPOINT+'/users/', {...props.newUser}, { withCredentials: true})
                .then((response) => {

                if (response.status === 201 && response.data && response.data.id) {

                    let currUser = response.data;
                    let editedInitial = JSON.parse(JSON.stringify(props.initialUsers));
                    editedInitial.push(currUser);
                    props.setInitialUsers(editedInitial);

                    props.setAddUserPopupVisible(false);
                    props.setNewUser({center: {}, username: '', role: '', email: ''});

                    props.setErrorPopupMessages([]);
                    props.setErrorPopupFields([]);

                }
                else {
                    alert(errMsg);
                }

                props.setOverlayVisible( (count) => count - 1);

            }, (error) => {
                props.setOverlayVisible( (count) => count - 1);
                alert(errMsg);
            });


        }

    };

    const cancelNewUser = () => {

        props.setAddUserPopupVisible(false);
        props.setNewUser({center: {}, username: '', role: '', email: ''});
        props.setErrorPopupMessages([]);
        props.setErrorPopupFields([]);
    };

    const getErrorClass = (type) => {

        if (props.errorPopupFields.indexOf(type) !== -1) {
            return "error";
        }

        return "";

    };

    const submitHandler = e => {
        e.preventDefault();
    };

    return (
        <React.Fragment>
        <div className="modal fade in">
            <div className="modal-header">
                <h3>Add New User</h3>
            </div>
            <div className="modal-body no-overflow">

                    {props.errorPopupMessages.length > 0 && (
                    <div className="errorSummary"><p>Please fix the following input errors:</p>
                        <ul>
                            {props.errorPopupMessages.map((message) => (
                            <li key={message}>{message}</li>
                            ))}
                        </ul>
                    </div>
                    )}

                <form onSubmit={submitHandler} method="post">
                    <div className="modal-form">
                        <div className="form-group">
                            <div className="span5" style={{width: "100%"}}>
                                <div className="row">
                                    <div className="label" style={{width: "216px"}}>
                                        User Type
                                    </div>
                                    <div className="input">
                                        <CorcSelect
                                            id="role"
                                            name="role"
                                            containerStyles={{width: "297px"}}
                                            onChange={selectedOption => onChangeUserType(selectedOption.value)}
                                            options={userTypeOptions}
                                            isSearchable={false}
                                            placeholder="Select Profile"
                                        />
                                    </div>
                                </div> {/* .row */}

                                {(props.newUser.role === 'tech' || props.newUser.role === 'coordinator') && (
                                    <div className="row">
                                        <div className="label" style={{width: "216px"}}>
                                            Pick Clinical Site:
                                        </div>
                                        <div className="input">
                                            <CorcSelect
                                                id="center"
                                                name="center"
                                                containerStyles={{width: "297px"}}
                                                onChange={selectedOption => onChangeCenterHandler(selectedOption.value)}
                                                value={centersOptions.filter(c => c.value === props.newUser.center.id)}
                                                isSearchable={false}
                                                placeholder="Select Clinical Site"
                                                options={centersOptions}
                                            />
                                        </div>
                                    </div>
                                )}

                                <div className="row">
                                    <div className="label" style={{width: "216px"}}>
                                        Username:
                                    </div>
                                    <div className="input">
                                        <input
                                            type="text"
                                            style={{width: "282px"}}
                                            name="username"
                                            defaultValue={props.newUser.username}
                                            onBlur={onChangeHandler}
                                            className={getErrorClass('username')}
                                        />
                                    </div>
                                </div> {/* .row */}

                                <div style={{width: "100%", marginBottom: "15px", height: "15px", borderBottom: "1px solid #eee"}}></div>

                                <div className="row">
                                    <div className="label" style={{width: "216px"}}>
                                        E-mail:
                                    </div>
                                    <div className="input">
                                        <input
                                            type="text"
                                            style={{width: "282px"}}
                                            name="email"
                                            defaultValue={props.newUser.email}
                                            onBlur={onChangeHandler}
                                            className={getErrorClass('email')}
                                        />
                                    </div>
                                </div> {/* .row */}

                            </div> {/* .span5 */}
                        </div>
                    </div>

                </form>
            </div> {/* .modal-body */}
            <div className="modal-footer">
                <input
                    type="submit"
                    className="btn small orange align-center right"
                    value="OK"
                    onClick={() => submitNewUserHandler()}
                />
                <button
                    className="btn small grey align-center right"
                    onClick={() => cancelNewUser()}
                >Cancel</button>
            </div>
        </div>

        <div className="modal-backdrop fade in"></div>
        </React.Fragment>
    );
}

function UserNotificationPopup(props) {
    const confirmNotificationHandler = async () => {
        const errMsg = "Sorry, something went wrong while fetching this user's information. Please contact your IT helpdesk.";

        let count = props.overlayVisible;
        props.setOverlayVisible( (count) => count + 1);

        await axios.post(process.env.REACT_APP_API_ENDPOINT+'/users/'+props.currentUser.id+'/resend_register/', { withCredentials: true})
            .then((response) => {

                let editedUsers;
                if (response.status === 200 && response.data && response.data.id) {

                    props.setCurrentUser({center: {}, studies: []});


                    let editedUsers = JSON.parse(JSON.stringify(props.users));
                    for(let i=0, len=editedUsers.length; i<=len-1; ++i) {
                        if(editedUsers[i].id === response.data.id) {
                            editedUsers.splice(i, 1, response.data);
                        }
                    }

                    props.setUsers(editedUsers);
                    props.setNotificationPopupVisible(false);

                }
                else {
                    alert(errMsg);
                }
                props.setOverlayVisible( (count) => count - 1);
            }, (error)=> {
                alert(errMsg);
                props.setOverlayVisible( (count) => count - 1);
            });
    };

    const cancelNotificationHandler = () => {
        props.setCurrentUser({center: {}, studies: []});
        props.setNotificationPopupVisible(false);
    };

    return (
        <React.Fragment>
        <div className="modal fade in">
            <div className="modal-header">
                <h3>Confirmation</h3>
            </div>
            <div className="modal-body">
                <p>Do you really want to resend a registration email to {props.currentUser.username}?</p>
            </div> {/* .modal-body */}
            <div className="modal-footer">
                <button
                    className="btn small orange align-center right"
                    onClick={() => confirmNotificationHandler()}
                >Yes</button>
                <button
                    className="btn small grey align-center right"
                    onClick={() => cancelNotificationHandler()}
                >No</button>
            </div>
        </div>

        <div className="modal-backdrop fade in"></div>
        </React.Fragment>
    );
}
