import React from 'react';
import Select from 'react-select';

function CorcSelect(props) {
    const customStyles = {
        option: (provided, state) => ({
            ...provided,
        }),
        control: (provided, state) => ({
            ...provided,
            fontSize: '12px',
            minHeight: 'auto',
            background: 'linear-gradient(0deg, rgba(224,224,224,1) 0%, rgba(255,255,255,1) 100%)',
        }),
        singleValue: (provided) => ({
            ...provided,
            color: '#808080',
            fontWeight: 'bold',
        }),
        container: (provided, state) => ({
            ...provided,
            paddingRight: '3px',
            ...props.containerStyles,
        }),
        menu: (provided) => ({
            ...provided,
            fontSize: '12px',
        }),
        indicatorSeparator: () => ({
            display: 'none',
        }),
        dropdownIndicator: (provided) => ({
            ...provided,
            padding: '5px',
        }),
    };

    return (
        <Select 
            {...props}
            styles={customStyles}
        />
    );
}

export default CorcSelect;

