import React, {useEffect, useState}  from 'react';
import axios from 'axios';
import {Link} from 'react-router-dom';
import ReactHtmlParser from 'react-html-parser';
import CorcSelect from 'components/utils/CorcSelect';
import {formatDate} from 'components/utils/Helpers.js';
import {useDropzone} from 'react-dropzone'

const prettyBytes = require('pretty-bytes');

function EquipmentCertificationSubmit(props) {

    useEffect(function() {

        const getCertification = async () => {
            let count = props.overlayVisible;
            props.setOverlayVisible( (count) => count + 1);

            axios.get(process.env.REACT_APP_API_ENDPOINT+'/certifications/equipment/'+props.match.params.certification+'/',
                { withCredentials: true, headers: {'Content-Type': 'application/json'}})
                .then((response) => {
                    props.setOverlayVisible( (count) => count - 1);
                    setCertification(response.data);

                    // we can't recertify a certification that is archived or from a study that is not opened
                    if (response.data.archived || response.data.study.status !== 'open') {
                        props.history.push("/studies/"+props.match.params.id+"/certifications");
                        return;
                    }

                    if (props.userAuth.user.role !== 'tech') {
                        props.history.push("/studies/"+props.match.params.id+"/certifications");
                        return;
                    }

                    if (response.data.study_exam.exam_type.has_equipments_with_camera) {
                        setShowCameraFields(true);
                    }

                })
                .catch((error) => {
                    props.setOverlayVisible( (count) => count - 1);
                    props.history.push("/studies/"+props.match.params.id+"/certifications");
                    return;
                })
        };

        props.setNavLinks([{title: 'Studies', url: '/studies/', icon: 'studies'},{title: 'Certification', url: '/studies/'+props.match.params.id+'/certifications/', icon: 'certification'},{title: 'Equipment', url: props.location.pathname, icon: 'submit'}]);

        getCertification();

        props.setShowPrompt(true);
        window.addEventListener('beforeunload', props.showPromptHandler);
        //on unmount
        return () => {
            props.setShowPrompt(false);
            window.removeEventListener('beforeunload', props.showPromptHandler);
        };
    }, []);


    const [certification, setCertification] = useState({
        "id": 0,
        "technician": {id: 0, username: ''},
        "study_exam": {id: 0, exam_type: {id: 0, name: ''}},
        "study_center": {id: 0, study_site_num: ''},
        "status": "",
        "submission_date": "",
        "evaluation_date": "",
        "equipment_make_model": "",
        "camera_make_model": "",
        "camera_resolution": "",
        "serial_num": "",
        "software_version": "",
        "submitter_comment": "",
        "grader_comment": "",
        "study": {},
        "equipment_files": [],
    });

    const [noFiles, setNoFiles] = useState(false);
    const [noFilesErrorPopup, setNoFilesErrorPopup] = useState(false);
    const [showAdminFiles, setShowAdminFiles] = useState(false);
    const [uploadedFiles, setUploadedFiles] = useState([]);
    const [uploadedFilesComment, setUploadedFilesComment] = useState("");
    const [showCameraFields, setShowCameraFields] = useState(false)
    const [certificationErrors, setCertificationErrors] = useState([]);
    const [showConfirmSubmit, setShowConfirmSubmit] = useState(false);

    const [percentUploaded, setPercentUploaded] = useState(0);

    const closeNoFilesPopup = () => {
        setNoFilesErrorPopup(false);
    }

    const closeErrorPopup = () => {
        setCertificationErrors([]);
    }

    const submitHandler = async (e) => {
        setShowConfirmSubmit(false);

        const errMsg = "Sorry, something went wrong when recertifying this equipment. Please contact your IT helpdesk.";
        let count = props.overlayVisible;

        let changedCertification = {id: certification.id, submitter_comment: certification.submitter_comment.trim(), camera_make_model: certification.camera_make_model.trim(), camera_resolution: certification.camera_resolution.trim(), equipment_make_model: certification.equipment_make_model.trim(), serial_num: certification.serial_num.trim(), software_version: certification.software_version.trim() }

        const formData = new FormData();
        const config = {
            withCredentials: true,
            headers: {
                'content-type': 'multipart/form-data'
            },
            onUploadProgress: progressEvent => {
                setPercentUploaded( Math.round( (progressEvent.loaded * 100) / progressEvent.total ) );
            }
        };

        for (var i in uploadedFiles) {
            formData.append('file',uploadedFiles[i].file);
        }
        formData.append('certification', JSON.stringify(changedCertification));

        props.setFilesUploading(true);
        props.setOverlayVisible( (count) => count + 1);

        await axios.patch(process.env.REACT_APP_API_ENDPOINT+'/certifications/equipment/'+props.match.params.certification+'/', formData,config).then(function (response) {

            props.setOverlayVisible( (count) => count - 1);
            props.setFilesUploading(false);

            if (response.status === 200) {
                props.setShowPrompt(false);

                // go to certifications of study
                props.history.push("/studies/"+props.match.params.id+"/certifications");

            }
            else {
                alert(errMsg);
            }

        }).catch(function(error) {
            props.setOverlayVisible( (count) => count - 1);
            props.setFilesUploading(false);
            alert(errMsg);
        });

/*
        await axios.patch(process.env.REACT_APP_API_ENDPOINT+'/certifications/equipment/'+props.match.params.certification+'/', {...changedCertification}, { withCredentials: true})
            .then((response) => {

                props.setOverlayVisible( (count) => count - 1);
                if (response.status === 200) {
                    props.history.push("/studies/"+props.match.params.id+"/certifications");
                }
                else {
                    alert(errMsg);
                }

        }, (error) => {
            props.setOverlayVisible( (count) => count - 1);
            alert(errMsg);
        });
*/
    }

    const validateSubmit = () => {

        let errors = [];
        const changedCertification = JSON.parse(JSON.stringify(certification));

        if (showCameraFields) {
            changedCertification.camera_make_model = changedCertification.camera_make_model.trim();
            if (!changedCertification.camera_make_model) {
                errors.push("Camera Make and Model cannot be blank.")
            }

            changedCertification.camera_resolution = changedCertification.camera_resolution.trim();
            if (!changedCertification.camera_resolution) {
                errors.push("Camera Resolution cannot be blank.")
            }
        }

        changedCertification.equipment_make_model = changedCertification.equipment_make_model.trim();
        if (!changedCertification.equipment_make_model) {
            errors.push("Equipment Make and Model cannot be blank.")
        }

        changedCertification.serial_num = changedCertification.serial_num.trim();
        if (!changedCertification.serial_num) {
            errors.push("Serial Number cannot be blank.")
        }

        changedCertification.software_version = changedCertification.software_version.trim();
        if (!changedCertification.software_version) {
            errors.push("Software Version cannot be blank.")
        }


        if (errors.length) {
            setCertificationErrors(errors);
            return;
        }

        setShowConfirmSubmit(true);

    };

    return (

        <React.Fragment>


            {showConfirmSubmit && (
                <React.Fragment>
                    <div id="alertModal" className="modal hide fade in" tabIndex="-1" role="dialog" aria-labelledby="myModalLabel" aria-hidden="false" style={{display: "block"}}>
                        <div className="modal-header">
                            <button type="button" className="close" data-dismiss="modal" aria-hidden="true">×</button>
                            <h3 id="alert-modal-title">Do you really want to finish and submit?</h3>
                        </div>
                        <div className="modal-body">
                        </div>

                        <div className="modal-footer">
                            <button onClick={() => submitHandler()} id="alert-modal-ok" aria-hidden="true" className="btn small orange align-center right">Yes</button>
                            <button onClick={() => setShowConfirmSubmit(false)} data-dismiss="modal" id="alert-modal-cancel" aria-hidden="true" className="btn small grey align-center right">No</button>
                        </div>
                    </div>
                    <div className="modal-backdrop fade in"></div>
                </React.Fragment>
            )}


            {certificationErrors.length > 0 && (
                <React.Fragment>
                <div className="modal-backdrop fade in"></div>
                <div id="alertModal" className="modal hide fade in" tabIndex="-1" role="dialog" aria-labelledby="myModalLabel" aria-hidden="false" style={{display: "block"}}>
                    <div className="modal-header">
                        <button type="button" className="close" data-dismiss="modal" aria-hidden="true">×</button>
                        <h3 id="alert-modal-title">Errors!</h3>
                    </div>
                    <div className="modal-body"><br/>
                        <div className="errorSummary"><p>Please fix the following input errors:</p>
                            <ul>
                                {certificationErrors.map((message) => (
                                <li key={message}>{message}</li>
                                ))}
                            </ul>
                        </div><br />
                    </div>
                    <div className="modal-footer">
                        <button onClick={closeErrorPopup} id="alert-modal-ok" aria-hidden="true" className="btn small orange align-center right" style={{display: "block"}}>OK</button>
                    </div>
                </div>
                </React.Fragment>
            )}


            {noFilesErrorPopup && (

                <React.Fragment>
                <div className="modal-backdrop fade in"></div>
                <div id="alertModal" className="modal hide fade in" tabIndex="-1" role="dialog" aria-labelledby="myModalLabel" aria-hidden="false" style={{display: "block"}}>
                    <div className="modal-header">
                        <button type="button" className="close" data-dismiss="modal" aria-hidden="true">×</button>
                        <h3 id="alert-modal-title">Warning!</h3>
                    </div>
                    <div className="modal-body"><br/><br/>You are submitting without files. Please add files or check the checkbox<br/></div>
                    <div className="modal-footer">
                        <button onClick={closeNoFilesPopup} data-dismiss="modal" id="alert-modal-ok" aria-hidden="true" className="btn small orange align-center right">OK</button>
                    </div>
                </div>
                </React.Fragment>
            )}

            <div className="home_header_container_class">
                <div className="content">
                    <div className="breadcrumbs">
                        <div className="path">
                            <ul>
                                <li><span className="orange text-shadow">{certification.study && certification.study.acronym ? certification.study.acronym.toUpperCase() : ""}</span></li>
                                <li className="separator text-shadow">&gt;</li>
                                <li><span className="orange text-shadow">Certifications</span></li>
                                <li className="separator text-shadow">&gt;</li>
                                <li><span className="orange text-shadow">Equipment</span></li>
                                <li className="separator text-shadow">&gt;</li>
                                <li><span className="orange text-shadow">{certification.study_exam && certification.study_exam.exam_type.name}</span></li>
                                <li className="separator text-shadow">&gt;</li>
                                <li>{certification.study_center.study_site_num}</li>
                            </ul>
                        </div>
                    </div> {/* .breadcrumbs */}

                    <div className="main-form">
                        <form onSubmit={submitHandler} id="register-certification" method="post">
                            <EquipmentCertificationSubmissionInfo
                                certification={certification}
                            />

                            <EquipmentCertificationData
                                certification={certification}
                                setCertification={setCertification}
                                showCameraFields={showCameraFields}
                                setShowCameraFields={setShowCameraFields}
                            />

                        </form>

                        <EquipmentCertificationFiles
                            certification={certification}
                            setCertification={setCertification}
                            noFiles={noFiles}
                            setNoFiles={setNoFiles}
                            showAdminFiles={showAdminFiles}
                            setShowAdminFiles={setShowAdminFiles}
                            uploadedFiles={uploadedFiles}
                            setUploadedFiles={setUploadedFiles}
                            setUploadedFilesComment={setUploadedFilesComment}
                            uploadedFilesComment={uploadedFilesComment}
                            percentUploaded={percentUploaded}
                        />

                        <EquipmentCertificationActions
                            setNoFilesErrorPopup={setNoFilesErrorPopup}
                            noFiles={noFiles}
                            uploadedFiles={uploadedFiles}
                            setUploadedFiles={setUploadedFiles}
                            certification={certification}
                            submitCertification={validateSubmit}
                            setCertification={setCertification}
                        />
                    </div>
                </div> {/* .content */}
            </div>
        </React.Fragment>
    );
}

export default EquipmentCertificationSubmit;

function EquipmentCertificationSubmissionInfo(props) {

    let evaluatedBy = "", submittedBy = "";

    const getStatusDisplay = status => {

        if (status === 'notstarted') {
            return 'Not Started';
        }
        else if (status === 'pending') {
            return 'Pending';
        }
        else if (status === 'notcertified') {
            return 'Not Certified'
        }
        else if (status === 'incertification') {
            return 'In Certification'
        }
        else if (status === 'certified') {
            return 'Certified'
        }

        return '';

    }

    if (props.certification.user_graded) {
        evaluatedBy = '<p>EVALUATED BY: '+props.certification.user_graded.name + ' '+ props.certification.user_graded.last_name +' ('+props.certification.user_graded.username+')</p><p>EVALUATED ON: '+formatDate(props.certification.grading_date, true)+'</p><br />';
    }

    if (props.certification.user_submitted) {
        submittedBy = '<p>SUBMITTED BY: '+props.certification.user_submitted.name + ' ' + props.certification.user_submitted.last_name +' ('+props.certification.user_submitted.username+')</p><p>SUBMITTED ON: '+formatDate(props.certification.submission_date, true)+'</p><br />';
    }


    return (
        <div className="form-group content">
            <br />

            { ReactHtmlParser (evaluatedBy) }
            { ReactHtmlParser (submittedBy) }

            <p>CERTIFICATION STATUS: {getStatusDisplay(props.certification.status)}</p>
        </div>
    );
}

function EquipmentCertificationData(props) {

    const onChangeHandler = (ev) => {
        let name = ev.target.name;
        let value = ev.target.value;

        props.setCertification({...props.certification, [name]: value});
    };

    return (
        <div className="form-group">
            <div className="span5 insert-visit" style={{width: "46%"}}>

                <div className="row" >
                    <div className="label">
                        <label htmlFor="SubmitEquipmentForm_examId">Exam</label>
                    </div>
                    <div className="input">
                        <CorcSelect
                            id="study_exam"
                            name="study_exam"
                            value={{label: props.certification.study_exam.exam_type.name, value: props.certification.study_exam.id}}
                            placeholder="----"
                            isSearchable={false}
                            isDisabled={true}
                            containerStyles={{width: "300px"}}
                        />
                    </div>
                </div> {/* .row */}

                <div className="row" >
                    <div className="label">
                        <label htmlFor="SubmitEquipmentForm_operator">Operator</label>
                    </div>
                    <div className="input">
                        <CorcSelect
                            name="technician"
                            id="technician"
                            isDisabled={true}
                            value={{label: props.certification.technician.username, value: props.certification.technician.id}}
                            isSearchable={false}
                            placeholder="----"
                            containerStyles={{width: "300px"}}
                        />
                    </div>
                </div> {/* .row */}

                {props.showCameraFields && (
                <React.Fragment>
                <div className="row">
                    <div className="label">
                        <label htmlFor="SubmitEquipmentForm_cameraMakeModel" className="required">Camera Make Model *</label>
                    </div>
                    <div className="input">
                        <input
                            name="camera_make_model"
                            id="camera_make_model"
                            type="text"
                            value={props.certification.camera_make_model}
                            onChange={(ev) => onChangeHandler(ev)}
                        />
                    </div>
                </div>

                <div className="row" >
                    <div className="label">
                        <label htmlFor="SubmitEquipmentForm_cameraResolution" className="required">Camera Resolution *</label>
                    </div>
                    <div className="input">
                        <input
                            name="camera_resolution"
                            id="camera_resolution"
                            type="text"
                            value={props.certification.camera_resolution}
                            onChange={(ev) => onChangeHandler(ev)}
                        />
                    </div>
                </div>
                </React.Fragment>
                )}

                <div className="row" >
                    <div className="label">
                        <label htmlFor="SubmitEquipmentForm_equipmentName" className="required">Equipment Make and Model *</label>
                    </div>
                    <div className="input">
                        <input
                            name="equipment_make_model"
                            id="name"
                            type="text"
                            value={props.certification.equipment_make_model}
                            onChange={(ev) => onChangeHandler(ev)}
                        />
                    </div>
                </div> {/* .row */}

                <div className="row" >
                    <div className="label">
                        <label htmlFor="SubmitEquipmentForm_serialNumber" className="required">Serial Number *</label>
                    </div>
                    <div className="input">
                        <input
                            name="serial_num"
                            id="serial_num"
                            type="text"
                            maxLength="100"
                            value={props.certification.serial_num}
                            onChange={(ev) => onChangeHandler(ev)}
                        />
                    </div>
                </div> {/* .row */}


                <div className="row" >
                    <div className="label">
                        <label htmlFor="SubmitEquipmentForm_softwareVersion" className="required">Software Version *</label>
                    </div>
                    <div className="input">
                        <input
                            name="software_version"
                            id="software_version"
                            type="text"
                            maxLength="100"
                            value={props.certification.software_version}
                            onChange={(ev) => onChangeHandler(ev)}
                        />
                    </div>
                </div> {/* .row */}

            </div>

            <div className="span5" style={{width: "54%"}}>
                <textarea
                    style={{height: "200px"}}
                    className="submit-comment"
                    maxLength="5000"
                    placeholder="Comments"
                    name="submitter_comment"
                    id="submitter_comment"
                    value={props.certification.submitter_comment}
                    onChange={(ev) => onChangeHandler(ev)}
                ></textarea>
            </div>
        </div>
    );
}


function EquipmentCertificationFiles(props) {

    return (
        <React.Fragment>

        {!props.noFiles && (
        <EquipmentCertificationFileUploader
            uploadedFiles={props.uploadedFiles}
            setUploadedFiles={props.setUploadedFiles}
            setShowAdminFiles={props.setShowAdminFiles}
            percentUploaded={props.percentUploaded}
        />
        )}

        <EquipmentCertificationNoFiles
            noFiles={props.noFiles}
            setNoFiles={props.setNoFiles}
        />

        {props.certification.equipment_files.map( (file, i) => (
        <EquipmentCertificationFileItem
            certification={props.certification}
            key={i}
            file={file}
        />
        ))}

        </React.Fragment>
    );
}

function EquipmentCertificationNoFiles(props) {
    return (
        <div className="form-group content" id="nofileinput">
            <br />
            <input
                type="checkbox"
                id="checkbox-nofiles"
                checked={props.noFiles}
                onChange={(ev) => props.setNoFiles(ev.target.checked)}
            />
            <label htmlFor="checkbox-nofiles">No files are to be submitted</label>
            <br />
        </div>
    );
}

function EquipmentCertificationFileUploader(props) {

    const removeFileHandler = (ev,file) =>  {

        ev.preventDefault();

        let editedUploadedFiles = props.uploadedFiles.filter(f => f.key !== file.key);
        props.setUploadedFiles(editedUploadedFiles);

        return false;
    };

    const onDrop = acceptedFiles => {
        let newFiles = [];

        acceptedFiles.map(file => {
            newFiles.push({file: file, key: Math.floor(Math.random(5) * 1000), name: file.name});
            return false;
        });

        newFiles = newFiles.concat(props.uploadedFiles);
        props.setUploadedFiles(newFiles);
    };

    const {getRootProps, getInputProps} = useDropzone({onDrop});

    return (
        <form id="fileupload" method="post" encType="multipart/form-data">
            <div className="form-group">
                <div id="files" className="files">
                    <div {...getRootProps({
                            className: "info"
                    })}>
                        <span className="icon submit ico"></span>
                        <h2 className="text-shadow">Drop files here</h2>
                        <span className="or">
                            <hr />
                            <span>or</span>
                            <hr />
                        </span>
                        <span className="btn large orange fileinput-button">
                            <span>Select files from your computer</span>
                            <input {...getInputProps()} />
                        </span>
                    </div>
                    <div id="filesProgress" className="clearfix">
                        {props.uploadedFiles.map((file, i) => (
                        <div
                            key={i}
                            className="file-upload-box">
                            <p>
                                <span className="file-name">{file.name}</span>
                                <span className="file-actions">
                                    <button
                                        className="btn small grey align-center left other"
                                        onClick={(ev) => removeFileHandler(ev, file)}
                                    >REMOVE</button>
                                </span>
                                <span className="progress active">
                                    <span className="progress-bar progress-bar-warning" role="progressbar" style={{width: `${props.percentUploaded}%`}}></span>
                                    <span className="progress_value">
                                        {props.percentUploaded ? `${props.percentUploaded} % (a few seconds left)` : 'READY TO UPLOAD' }
                                    </span>
                                </span>
                            </p>
                        </div>
                        ))}
                    </div>
                </div>
            </div>
        </form>
    );
}

function EquipmentCertificationFileItem(props) {


    const style = {
        width: "40%",
        float: "right",
        marginRight: "32px",
        display: "block"
    }

    return (
        <div className="form-group">
            <div className="file-uploaded">
                <div className="row">
                    <a target="_blank" rel="noopener noreferrer" href={process.env.REACT_APP_API_ENDPOINT + "/certifications/equipment/"+props.certification.id+'/file/'+props.file.id+'/download/'} className="cancel-btn-class btn small grey align-center">Download</a>
                    <div className="file-name">
                        <span className="filename-class">{props.file.name}<b>{" (" + prettyBytes(props.file.file && props.file.file.size ? props.file.file.size : 0) + ")"}</b></span>
                    </div>

                   <div className="file-comment" style={style} ref={(node) => {
                      if (node) {
                        node.style.setProperty("float", "right", "important");
                      }
                    }}>
                        <input
                            maxLength="255"
                            type="text"
                            className="uploaded_file"
                            value={props.file.description}
                            placeholder="Comment"
                            disabled="disabled"
                        />
                    </div>
                </div>
            </div>
        </div>
    );
}


function EquipmentCertificationActions(props) {

    const onSubmitHandler = (ev) => {

        ev.preventDefault();

        if (!props.uploadedFiles.length && !props.noFiles) {
            props.setNoFilesErrorPopup(true)
            return false;
        }

        props.submitCertification();
        return false;
    };

    const buttons = () => {
        return (
            <React.Fragment>
            <Link
                to={"/studies/" + props.certification.study.id + "/certifications/"}
                className="btn small grey align-center left"
            >Back</Link>
            <button
                name=""
                className="btn small orange align-center left"
                onClick={onSubmitHandler}
            >Submit</button>
            </React.Fragment>
        );
    };

    return (
        <div className="form-actions" style={{float: "right"}}>
            {buttons()}
        </div>

    );
}




