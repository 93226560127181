import React, {useEffect, useState} from 'react';
import {Link} from 'react-router-dom';
import axios from 'axios';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import {initDate} from 'components/utils/Helpers.js';

function EditUser(props) {
    useEffect(function() {
        getUser();

        props.setNavLinks([{title: 'Edit Profile', url: '/users/'+props.match.params.id+'/', icon: 'users'}]);
    }, []);

    const getUser = async () => {
        let count = props.overlayVisible;
        props.setOverlayVisible(count => count + 1);

        await axios.get(process.env.REACT_APP_API_ENDPOINT+'/users/'+props.match.params.id+'/', { withCredentials: true}).then(response => {


            if (response.status === 200) {
                setUser({...response.data, 'new_password': '', 'confirm_password': '', 'previous_password': '', changedPassword: false});
            }
            props.setOverlayVisible(count => count - 1);

            }, (error) => {
                props.setOverlayVisible(count => count - 1);

                if (error.response.status === 401) {
                    props.resetAuth();
                    props.history.push('/login');
                }
            }
        );

    };

    const [user, setUser] = useState({
        username: '',
        new_password: '',
        changedPassword: false,
        previous_password: '',
        confirm_password: '',
        email: '',
        name: '',
        last_name: '',
        birth_date: '',
        address: '',
        city: '',
        postcode: '',
        country: '',
        phone: '',
        fax: '',
    });
    const [changePasswordVisible, setChangePasswordVisible] = useState(false);
    const [showSavePopup, setShowSavePopup] = useState(false);
    const [errorPopupMessages, setErrorPopupMessages] = useState(false);
    const passwordValidator = require('password-validator');
    let passwordSchema = new passwordValidator();
    passwordSchema.is().min(5).has().lowercase().has().digits().has().symbols()

    const globalHeaders = {
        withCredentials: true,
        headers: {
        }
    };

    const handleSubmit = e => {
        e.preventDefault();
        validateUser(user);
    };

    const submitUser = async (user) => {
        const errMsg = "Sorry, something went wrong when saving the user. Please contact your IT helpdesk.";
        setShowSavePopup(false);

        let count = props.overlayVisible;
        props.setOverlayVisible(count => count + 1);

        await axios.patch(process.env.REACT_APP_API_ENDPOINT+'/users/'+user.id+'/', {...user}, globalHeaders)
            .then((response) => {
            props.setOverlayVisible(count => count - 1);
            if (response.status === 200) {

                props.updateUserInAuth(response.data);
                //window.location.reload();
            }
            else {
                alert(errMsg);
            }

        }, (error) => {
            alert(errMsg);
            props.setOverlayVisible(count => count - 1);
        });
    };

    const validateUser = async (user) => {
        let errors = [];
        let editedUser = JSON.parse(JSON.stringify(user));
        const errMsg = "Sorry, something went wrong when validating this user. Please contact your IT helpdesk.";

        const changesToSave = ['id', 'username', 'name', 'last_name', 'country', 'phone', 'email', 'new_password', 'previous_password', 'confirm_password', 'changedPassword', 'birth_date', 'address', 'city', 'postcode', 'fax'];

        for (let i in editedUser) {
            if (editedUser.hasOwnProperty(i) && !changesToSave.includes(i)) {
                delete editedUser[i];
            }
        }

        editedUser.fax = editedUser.fax.trim();
        if (editedUser.birth_date) {
           editedUser.birth_date = editedUser.birth_date.trim();
        }
        if (!editedUser.birth_date) {
            editedUser.birth_date = null;
        }
        editedUser.city = editedUser.city.trim();
        editedUser.address = editedUser.address.trim();
        editedUser.postcode = editedUser.postcode.trim();

        editedUser.username = editedUser.username.trim();
        // do we have requiredfields?
        if (!editedUser.username) {
            errors.push('Username cannot be blank.');
        }
        editedUser.name = editedUser.name.trim();
        if (!editedUser.name) {
            errors.push('Name cannot be blank.');
        }

        editedUser.last_name = editedUser.last_name.trim();
        if (!editedUser.last_name) {
            errors.push('Last name cannot be blank.');
        }

        editedUser.country = editedUser.country.trim();
        if (!editedUser.country) {
            errors.push('Country cannot be blank.');
        }

        editedUser.phone = editedUser.phone.trim();
        if (!editedUser.phone) {
            errors.push('Phone cannot be blank.');
        }

        editedUser.email = editedUser.email.trim();
        if (!editedUser.email) {
            errors.push('E-mail cannot be blank.');
        }

        editedUser.new_password = editedUser.new_password.trim();
        editedUser.confirm_password = editedUser.confirm_password.trim();
        editedUser.previous_password = editedUser.previous_password.trim();

        setUser(editedUser);

        if(editedUser.changedPassword) {
            if(!editedUser.previous_password) {
                errors.push('Previous password cannot be blank.');
            }

            if(!editedUser.confirm_password) {
                errors.push('Password confirmation cannot be blank.');
            }

            if(editedUser.new_password !== editedUser.confirm_password) {
                errors.push('Password and confirmation must be the same.');
            }

            if (!passwordSchema.validate(editedUser.new_password)) {
                errors.push('Your password must have at least 5 characters combining letters, numbers and symbols/special characters (#,@,&,%,/,*, etc).');
            }

            // make an ajax call to check existing password
            // only then should it save the user or show the errors
            if (errors.length) {
                setErrorPopupMessages(errors);
            }
            else {
                let count = props.overlayVisible;
                props.setOverlayVisible(count => count + 1);

                await axios.post(process.env.REACT_APP_API_ENDPOINT+'/users/'+editedUser.id+'/check_password/', {password: editedUser.previous_password}, globalHeaders)
                .then((response) => {
                    props.setOverlayVisible(count => count - 1);
                    if (response.status === 200) {
                        if (response.data === true) {
                            submitUser(editedUser);
                        }
                        else {
                            errors.push('Your previous password is not right!');
                            setErrorPopupMessages(errors);
                        }
                    }
                    else {
                        errors.push('Your previous password is not right!');
                        setErrorPopupMessages(errors);
                    }

                }, (error) => {
                    alert(errMsg);
                    props.setOverlayVisible(count => count - 1);
                });
            }
        }
        else {

            // if validation fails, show error popup
            if (errors.length) {
                setErrorPopupMessages(errors);
            }
            else {
                submitUser(editedUser);
            }
        }

    };

    return (
        <React.Fragment>
        <div className="home_header_container_class">
            <div className="content">
                <div className="main-form">
                    <form onSubmit={handleSubmit} id="update-user">
                        <div className="overview">
                            <h2 className="text-shadow">Account Details</h2>
                            <AccountDetails
                                user={user}
                                setUser={setUser}
                                changePasswordVisible={changePasswordVisible}
                                setChangePasswordVisible={setChangePasswordVisible}
                            />
                        </div> {/* .overview */}

                        <div className="overview">
                            <h2 className="text-shadow">User Details</h2>
                            <UserDetails
                                user={user}
                                setUser={setUser}
                            />
                        </div> {/* .overview */}

                        <div className="form-actions" style={{float:"right"}}>
                        
                            <Link
                                to={"/studies/"}
                                className="btn small grey align-center left"
                            >Cancel</Link>
                        
                            <input
                                type="submit"
                                className="btn small orange align-center left"
                                value="Save"
                            />
                        </div>
                    </form>
                </div>
            </div>
        </div>

        {errorPopupMessages.length > 0 && (
            <UserErrorPopup errorPopupMessages={errorPopupMessages} setErrorPopupMessages={setErrorPopupMessages}/>
        )}
        </React.Fragment>
    );
}

export default EditUser;

function AccountDetails(props) {
    const onChangeHandler = ev => {
        let name = ev.target.name,
            val = ev.target.value;

        props.setUser({...props.user, [name]: val});
    };

    const showPasswordChange = (e, visible) => {
        e.preventDefault();
        props.setChangePasswordVisible(visible);
        props.setUser({...props.user, 'new_password': '', 'confirm_password': '', 'previous_password': '', 'changedPassword': visible});
    };

    return (
        <div className="form-group">
            <div style={{width: '100%', marginTop: '10px'}}></div>

            <div className="span5" style={{width: "100%"}}>
                <div className="row" style={{paddingTop: "4px"}}>
                    <div className="label">
                        <label htmlFor="username" className="required">Username <span className="required">*</span></label>
                    </div>
                    <div>
                        <input
                            name="username"
                            id="username"
                            style={{width: "32%"}}
                            type="text"
                            defaultValue={props.user.username}
                            disabled={true}
                        />
                    </div>
                </div>
            </div> {/* .span5 username */}

            <div className="span5" style={{width: "100%"}}>
                <div className="row" style={{paddingTop: "4px"}}>
                    <div className="label">
                        <label htmlFor="email" className="required">Email <span className="required">*</span></label>
                    </div>
                    <div>
                        <input
                            name="email"
                            id="email"
                            type="text"
                            style={{width: "32%"}}
                            value={props.user.email}
                            onChange={ev=>onChangeHandler(ev)}
                        />
                    </div>
                </div>
            </div> {/* .span5 username */}

            {props.changePasswordVisible && (
                <React.Fragment>
                    <div className="span5" style={{width: "100%"}}>
                        <div className="row" style={{paddingTop: "4px"}}>
                            <div className="label">
                                <label htmlFor="previous_password" className="required">Previous Password <span className="required">*</span></label>
                            </div>
                            <div>
                                <input
                                    name="previous_password"
                                    id="previous_password"
                                    type="password"
                                    value={props.user.previous_password}
                                    onChange={ev=>onChangeHandler(ev)}
                                />
                            </div>
                        </div>
                    </div>

                    <div className="span5" style={{width: "100%"}}>
                        <div className="row" style={{paddingTop: "4px"}}>
                            <div className="label">
                                <label htmlFor="new_password" className="required">Password <span className="required">*</span></label>
                            </div>
                            <div>
                                <input
                                    name="new_password"
                                    id="new_password"
                                    type="password"
                                    value={props.user.new_password}
                                    onChange={ev=>onChangeHandler(ev)}
                                />
                            </div>
                        </div>
                    </div>

                    <div className="span5" style={{width: "100%"}}>
                        <div className="row" style={{paddingTop: "4px"}}>
                            <div className="label">
                                <label htmlFor="confirm_password" className="required">Confirm Password <span className="required">*</span></label>
                            </div>
                            <div>
                                <input
                                    name="confirm_password"
                                    id="confirm_password"
                                    type="password"
                                    value={props.confirm_password}
                                    onChange={ev=>onChangeHandler(ev)}
                                />
                            </div>
                        </div>
                    </div>
                    <button
                        id="btn-cancel-password"
                        className="change-password"
                        type="button"
                        style={{display: "inline"}}
                        onClick={(ev)=>showPasswordChange(ev, false)}
                    >Cancel</button>
                </React.Fragment>
            )}

            {!props.changePasswordVisible && (
                <button
                    id="btn-change-password"
                    className="change-password"
                    type="button"
                    style={{display: "inline"}}
                    onClick={ev => showPasswordChange(ev, true)}
                >Do you want change password?</button>
            )}
            <div style={{width: '100%', marginTop: '10px'}}></div>
        </div>
    );
}

function UserDetails(props) {
    const onChangeHandler = ev => {
        let name = ev.target.name,
            val = ev.target.value;

        props.setUser({...props.user, [name]: val});
    };

    const userBirthDateChangeHandler = (date) => {
        let txtDate = '';

        if (date) {
            txtDate = date.getFullYear() + '-' + (date.getMonth()+1) + '-' + date.getDate();
        }

        props.setUser({...props.user, 'birth_date': txtDate})
    };

    return (
        <div className="form-group">
            <div style={{width: '100%', marginTop: '10px'}}></div>

            <div className="span5" style={{width: "100%"}}>
                <div className="row" style={{paddingTop: "4px"}}>
                    <div className="label">
                        <label htmlFor="name" className="required">Name <span className="required">*</span></label>
                    </div>
                    <div>
                        <input
                            name="name"
                            id="name"
                            type="text"
                            style={{width: "32%"}}
                            value={props.user.name}
                            onChange={(ev)=>onChangeHandler(ev)}
                        />
                    </div>
                </div>
            </div> {/* .span5 name */}

            <div className="span5" style={{width: "100%"}}>
                <div className="row" style={{paddingTop: "4px"}}>
                    <div className="label">
                        <label htmlFor="last_name" className="required">Last Name <span className="required">*</span></label>
                    </div>
                    <div>
                        <input
                            name="last_name"
                            id="last_name"
                            type="text"
                            style={{width: "32%"}}
                            value={props.user.last_name}
                            onChange={(ev)=>onChangeHandler(ev)}
                        />
                    </div>
                </div>
            </div> {/* .span5 last_name */}

            <div className="span5" style={{width: "100%"}}>
                <div className="row" style={{paddingTop: "4px"}}>
                    <div className="label">
                        <label htmlFor="birth_date">Date of Birth</label>
                    </div>
                    <div>
                        <DatePicker
                            dateFormat="dd/MM/yyyy"
                            id="birth_date"
                            name="birth_date"
                            selected={props.user.birth_date && initDate(props.user.birth_date)}
                            onChange={userBirthDateChangeHandler}
                        />
                    </div>
                </div>
            </div> {/* .span5 last_name */}

            <div className="span5" style={{width: "100%"}}>
                <div className="row" style={{paddingTop: "4px"}}>
                    <div className="label">
                        <label htmlFor="address">Address</label>
                    </div>
                    <div>
                        <input
                            name="address"
                            id="address"
                            type="text"
                            style={{width: "32%"}}
                            value={props.user.address}
                            onChange={(ev)=>onChangeHandler(ev)}
                        />
                    </div>
                </div>
            </div>

            <div className="span5" style={{width: "100%"}}>
                <div className="row" style={{paddingTop: "4px"}}>
                    <div className="label">
                        <label htmlFor="city">City</label>
                    </div>
                    <div>
                        <input
                            name="city"
                            id="city"
                            type="text"
                            style={{width: "32%"}}
                            value={props.user.city}
                            onChange={(ev)=>onChangeHandler(ev)}
                        />
                    </div>
                </div>
            </div>

            <div className="span5" style={{width: "100%"}}>
                <div className="row" style={{paddingTop: "4px"}}>
                    <div className="label">
                        <label htmlFor="postcode">Post Code</label>
                    </div>
                    <div>
                        <input
                            name="postcode"
                            id="postcode"
                            type="text"
                            value={props.user.postcode}
                            onChange={(ev)=>onChangeHandler(ev)}
                        />
                    </div>
                </div>
            </div>

            <div className="span5" style={{width: "100%"}}>
                <div className="row" style={{paddingTop: "4px"}}>
                    <div className="label">
                        <label htmlFor="country" className="required">Country <span className="required">*</span></label>
                    </div>
                    <div>
                        <input
                            name="country"
                            id="country"
                            type="text"
                            value={props.user.country}
                            style={{width: "32%"}}
                            onChange={(ev)=>onChangeHandler(ev)}
                        />
                    </div>
                </div>
            </div>

            <div className="span5" style={{width: "100%"}}>
                <div className="row" style={{paddingTop: "4px"}}>
                    <div className="label">
                        <label htmlFor="phone" className="required">Telephone <span className="required">*</span></label>
                    </div>
                    <div>
                        <input
                            name="phone"
                            id="phone"
                            type="text"
                            value={props.user.phone}
                            onChange={(ev)=>onChangeHandler(ev)}
                        />
                    </div>
                </div>
            </div>

            <div className="span5" style={{width: "100%"}}>
                <div className="row" style={{paddingTop: "4px"}}>
                    <div className="label">
                        <label htmlFor="fax">Fax</label>
                    </div>
                    <div>
                        <input
                            name="fax"
                            id="fax"
                            type="text"
                            value={props.user.fax}
                            onChange={(ev)=>onChangeHandler(ev)}
                        />
                    </div>
                </div>
            </div>
            <div style={{width: '100%', marginBottom: '10px', overflow: 'hidden'}}></div>
        </div>
    );
}

function UserErrorPopup(props) {
    return (
        <React.Fragment>
            <div id="alertModal" className="modal hide fade in" tabIndex="-1" role="dialog" aria-labelledby="myModalLabel" aria-hidden="false" style={{display: "block"}}>
                <div className="modal-header">
                    <h3 id="alert-modal-title">Errors!</h3>
                </div>
                <div className="modal-body">
                    <div className="errorSummary"><p>Please fix the following input errors:</p>
                        <ul>
                            {props.errorPopupMessages.map((message) => (
                            <li key={message}>{message}</li>
                            ))}
                        </ul>
                    </div>
                </div>
                <div className="modal-footer">
                    <button onClick={() => props.setErrorPopupMessages(false)} data-dismiss="modal" id="alert-modal-ok" aria-hidden="true" className="btn small orange align-center right">OK</button>
                </div>
            </div>
            <div className="modal-backdrop fade in"></div>
        </React.Fragment>
    );
}
