import React, {useEffect, useState}  from 'react';
import axios from 'axios';
import {Link} from 'react-router-dom';

function CertificationsTechnicianList(props) {

    useEffect(function() {

        const getStudy = async () => {
            let count = props.overlayVisible;
            props.setOverlayVisible( (count) => count + 1);

            const items = await axios.get(process.env.REACT_APP_API_ENDPOINT+'/studies/'+props.match.params.id+'/certifications/',
                { withCredentials: true, headers: {'Content-Type': 'application/json'}});

            setStudy(items.data);

            props.setOverlayVisible( (count) => count - 1);
        };
        
        if (props.userAuth.user.role !== 'tech') {
            props.history.push("/studies/"+props.match.params.id);                                
        }
        

        props.setNavLinks([{title: 'Studies', url: '/studies/', icon: 'studies'},{title: 'Certification', url: props.location.pathname, icon: 'certification'}]);
        getStudy();
    }, []);

    const [study, setStudy] = useState({
        'id': 0,
        'status': '',
        'acronym': '',
        'full_name': '',
        'start_date': '',
        'end_date': '',
        'description': '',
        'select_patient_eye': false,
        'patient_details': '',
        'patient_card_vars': [],
        'protocol_num': '',
        'corc_num': '',
        'exams': [],
        'centers': [],
        'scheduled_visits': [],
        'technicians': [],
        'graders': [],
        'monitors': [],
        'technician_certifications': [],
        'equipment_certifications': [],
    });

    const [technicianHistory, setTechnicianHistory] = useState({});
    const [equipmentHistory, setEquipmentHistory] = useState({});

    const getStatusDisplay = status => {

        if (status === 'notstarted') {
            return 'Not Started';
        }
        else if (status === 'pending') {
            return 'Pending';
        }
        else if (status === 'notcertified') {
            return 'Not Certified'
        }
        else if (status === 'incertification') {
            return 'In Certification'
        }
        else if (status === 'certified') {
            return 'Certified'
        }

        return '';

    }

    return (
        <div className="home_header_container_class">
            <div className="content">
                <div className="breadcrumbs">
                    <div className="path">
                        <ul>
                            <li><span className="orange text-shadow">{study.acronym ? study.acronym.toUpperCase() : study.acronym}</span></li>
                            <li className="separator text-shadow">&gt;</li>
                            <li>Certifications</li>
                        </ul>
                    </div>
                    <div className="sub-menu">
                        <Link to={study.centers.length > 0 ? "/studies/"+study.id+"/certifications/equipment/new/"+study.centers[0].center.id : ""}
                            className="btn normal grey  align-center right"
                            id="add-equipment"
                        >
                            <span className="icon "></span>Add Equipment
                        </Link>
                    </div>
                </div> {/* .breadcrumbs */}

                <div className="certifications">
                    <div className="overview">
                        <h2 className="text-shadow" style={{display: "inline-block"}}>Equipment</h2>
                    </div>
                    <div id="equipments-cert">
                        {study.equipment_certifications.map((cert, i) => (
                        <EquipmentCertificationListItem
                            key={i}
                            getStatusDisplay={getStatusDisplay}
                            study={study}
                            certification={cert}
                            equipmentHistory={equipmentHistory}
                            setEquipmentHistory={setEquipmentHistory}
                            overlayVisible={props.overlayVisible}
                            setOverlayVisible={props.setOverlayVisible}
                        />
                        ))}
                    </div>
                    <div className="overview">
                        <h2 className="text-shadow">Technician</h2>
                    </div>
                    <div id="technicians-cert">
                        {study.technician_certifications.map((cert, i) => (

                            (cert && cert.study_exam.equipment_certification_required) && <TechnicianCertificationListItem
                            key={i}
                            getStatusDisplay={getStatusDisplay}
                            certification={cert}
                            study={study}
                            technicianHistory={technicianHistory}
                            setTechnicianHistory={setTechnicianHistory}
                            overlayVisible={props.overlayVisible}
                            setOverlayVisible={props.setOverlayVisible}
                        />
                        ))}
                    </div>
                </div>
            </div> {/* .content */}
        </div>
    );
}

export default CertificationsTechnicianList;


function EquipmentCertificationListItem(props) {
    const expandHandler = (ev, cert) => {

        let editedHistory = JSON.parse(JSON.stringify(props.equipmentHistory)), response, error;
        const errMsg = "Sorry, something went wrong when retrieving the history of this certification. Please contact your IT helpdesk.";

        if(ev.target.classList.contains("open")) {

            if (editedHistory.hasOwnProperty(cert.id)) {
                editedHistory[cert.id].expanded = false;
                props.setEquipmentHistory(editedHistory);
            }

            ev.target.classList.remove("open");
        }
        else {
            ev.target.classList.add("open");

            let count = props.overlayVisible;
            props.setOverlayVisible( (count) => count + 1);

            response = axios.get(process.env.REACT_APP_API_ENDPOINT+'/certifications/equipment/'+props.certification.id+'/history/')
                .then((response) => {

                props.setOverlayVisible( (count) => count - 1);

                if (response.status === 200) {

                    if (!editedHistory.hasOwnProperty(cert.id)) {
                        editedHistory[cert.id] = {'expanded': true, history: response.data};
                    }
                    else {
                        editedHistory[cert.id].expanded = true;
                    }

                    props.setEquipmentHistory(editedHistory);
                }
                else {
                    alert(errMsg);
                }

            }, (error) => {
                alert(errMsg);
                props.setOverlayVisible( (count) => count - 1);
            });

        }
    };

    return (
        <div className="block-border">
            <table className="certifications-table">
                <thead>
                    <tr>
                        <th width="15%">Equipment for</th>
                        <th width="15%">Center</th>
                        <th width="15%">Model/Maker</th>
                        <th width="15%">Serial Number</th>
                        <th width="15%">Software Version</th>
                        <th width="15%">Status</th>
                        <th></th>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td>{props.certification.study_exam.exam_type.name}</td>
                        <td>{props.certification.study_center.study_site_num}</td>
                        <td>{props.certification.equipment_make_model}</td>
                        <td>{props.certification.serial_num}</td>
                        <td>{props.certification.software_version}</td>
                        <td colSpan="2">{props.getStatusDisplay(props.certification.status)}
                            {props.certification.number_history_certifications > 0 && (
                                <button
                                    className="expand"
                                    onClick={(ev) => expandHandler(ev, props.certification)}
                                >&nbsp;
                                </button>
                            )}
                        </td>
                        <td></td>
                    </tr>
                    <tr className="expand" style={props.equipmentHistory.hasOwnProperty(props.certification.id) && props.equipmentHistory[props.certification.id].expanded ? {display: 'table-row'} : {}}>
                        <td colSpan="2"></td>
                        <td colSpan="5">
                            <table>
                                <tbody>
                                    {props.equipmentHistory.hasOwnProperty(props.certification.id) && props.equipmentHistory[props.certification.id].history.map((item, i) => (
                                        <EquipmentHistoryItem
                                            getStatusDisplay={props.getStatusDisplay}
                                            item={item}
                                            key={i}
                                            study={props.study}
                                        />
                                    ))}
                                </tbody>
                            </table>
                        </td>
                    </tr>
                </tbody>
            </table>
            <div className="buttons">
                <Link
                    to={"/studies/"+props.study.id+"/certifications/equipment/"+props.certification.id}
                    className="btn normal grey align-center"
                >View Details</Link>

                {props.study.status === 'open' && props.certification.status == 'certified' && (
                <Link
                    to={"/studies/"+props.study.id+"/certifications/equipment/"+props.certification.id+"/submit"}
                    className={"btn normal grey align-center"}
                >Recertify</Link>
                )}

                {props.study.status === 'open' && (props.certification.status == 'notcertified' || props.certification.status == 'incertification' || props.certification.status == 'pending') && (
                <Link
                    to={"/studies/"+props.study.id+"/certifications/equipment/"+props.certification.id+"/submit"}
                    className={"btn normal grey align-center"}
                >Submit new cases</Link>
                )}
                
                
            </div>
        </div>
    );
}

function EquipmentHistoryItem(props) {

    return (
        <tr>
            <td width="23%">{props.item.equipment_make_model}</td>
            <td width="23%">{props.item.serial_num}</td>
            <td width="23%">{props.item.software_version}</td>
            <td width="21%">{props.getStatusDisplay(props.item.status)}</td>
            <td className="btn-list">
                <ul style={{float: "right"}}>
                    <li>
                        <Link
                            to={"/studies/" + props.study.id + "/certifications/equipment/" + props.item.id}
                            className="btn small grey align-center"
                        >View Details
                        </Link>
                    </li>
                </ul>
            </td>
        </tr>
    );
}

function TechnicianHistoryItem(props) {

    return (
        <tr>
            <td width="28%">{props.getStatusDisplay(props.item.status)}</td>
            <td width="25%">{props.item.grading_date || '--/--/--'}</td>

            <td className="btn-list">
                <ul style={{float: "right"}}>
                    {props.item.status !== 'notstarted' && (
                    <li>
                        <Link
                            to={"/studies/" + props.study.id + "/certifications/technician/" + props.item.id}
                            className="btn small grey align-center"
                        >View Details
                        </Link>
                    </li>
                    )}
                </ul>
            </td>

        </tr>
    );
}
function TechnicianCertificationListItem(props) {

    const expandHandler = (ev, cert) => {

        let editedHistory = JSON.parse(JSON.stringify(props.technicianHistory)), response, error;
        const errMsg = "Sorry, something went wrong when retrieving the history of this certification. Please contact your IT helpdesk.";

        if(ev.target.classList.contains("open")) {

            if (editedHistory.hasOwnProperty(cert.id)) {
                editedHistory[cert.id].expanded = false;
                props.setTechnicianHistory(editedHistory);
            }

            ev.target.classList.remove("open");
        }
        else {
            ev.target.classList.add("open");

            let count = props.overlayVisible;
            props.setOverlayVisible( (count) => count + 1);

            response = axios.get(process.env.REACT_APP_API_ENDPOINT+'/certifications/technician/'+props.certification.id+'/history/')
                .then((response) => {

                props.setOverlayVisible( (count) => count - 1);

                if (response.status === 200) {

                    if (!editedHistory.hasOwnProperty(cert.id)) {
                        editedHistory[cert.id] = {'expanded': true, history: response.data};
                    }
                    else {
                        editedHistory[cert.id].expanded = true;
                    }

                    props.setTechnicianHistory(editedHistory);
                }
                else {
                    alert(errMsg);
                }

            }, (error) => {
                alert(errMsg);
                props.setOverlayVisible( (count) => count - 1);
            });

        }

    };


    return (

        <div className="block-border">
            <table className="certifications-table">
                <thead>
                    <tr>
                        <th width="30%">Exam</th>
                        <th width="20%">Status</th>
                        <th width="10%">Date Certified</th>
                        <th></th>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td>{props.certification.study_exam.exam_type.name}</td>
                        <td>{props.getStatusDisplay(props.certification.status)}</td>
                        <td colSpan="2">{props.certification.grading_date || '--/--/--' }
                            {props.certification.number_history_certifications > 0 && (
                            <button
                                className="expand"
                                onClick={(ev) => expandHandler(ev, props.certification)}
                                style={{marginLeft: "35px"}}
                                >&nbsp;
                            </button>
                            )}
                        </td>
                    </tr>
                    <tr className="expand" style={props.technicianHistory.hasOwnProperty(props.certification.id) && props.technicianHistory[props.certification.id].expanded ? {display: 'table-row'} : {}}>
                        <td></td>
                        <td colSpan="3">
                            <table>
                                <tbody>
                                    {props.technicianHistory.hasOwnProperty(props.certification.id) && props.technicianHistory[props.certification.id].history.map((item, i) => (
                                        <TechnicianHistoryItem
                                            getStatusDisplay={props.getStatusDisplay}
                                            item={item}
                                            key={i}
                                            study={props.study}
                                        />
                                    ))}

                                </tbody>
                            </table>
                        </td>
                    </tr>
                </tbody>
            </table>
            <div className="buttons">
                {props.certification.status !== 'notstarted' && (
                <Link
                    to={"/studies/"+props.study.id+"/certifications/technician/"+props.certification.id}
                    className="btn normal  grey align-center"
                >View Details</Link>
                )}
                
                {props.certification.status === 'certified' && (
                <Link
                    to={"/studies/"+props.study.id+"/certifications/technician/"+props.certification.id+"/submit"}
                    className={"btn normal grey align-center" + (props.study.status === 'closed' ? " disabled" : "")}
                    onClick={(e) => {if(props.study.status === 'closed') {e.preventDefault()}}}
                >Recertify</Link>
                )}

                {(props.certification.status === 'incertification' || props.certification.status === 'pending' || props.certification.status === 'notstarted' || props.certification.status === 'notcertified') && (
                <Link
                    to={"/studies/"+props.study.id+"/certifications/technician/"+props.certification.id+"/submit"}
                    className={"btn normal grey align-center" + (props.study.status === 'closed' ? " disabled" : "")}
                    onClick={(e) => {if(props.study.status === 'closed') {e.preventDefault()}}}
                >Submit new cases</Link>
                )}

            </div>
        </div>
    );
}

