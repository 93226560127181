import React, {useEffect, useState}  from 'react';
import axios, {post} from 'axios';
import {Link} from 'react-router-dom';
import {useDropzone} from 'react-dropzone'
import ReactHtmlParser from 'react-html-parser';
import CorcSelect from 'components/utils/CorcSelect';
import {formatDate} from 'components/utils/Helpers';
const prettyBytes = require('pretty-bytes');

function VisitReport(props) {
    useEffect(function() {
        props.setNavLinks([
            {title: 'Studies', url: '/studies/', icon: 'studies'},
            {title: 'Clinical Site', url: '/studies/' + props.match.params.id + '/navigate', icon: 'center'},
            {title: 'Patients', url: '/studies/' + props.match.params.id + '/navigate/center/'+props.match.params.center+'/patients', icon: 'pacients'},
            {title: 'Visits', url: '/studies/'+props.match.params.id+'/center/' + props.match.params.center + '/patients/'+ props.match.params.patient + '/visits', icon: 'visits'},
            {title: 'Report', url: props.location.pathname, icon: 'menu-report'}
        ]);

        getVisit();

    }, []);

    const getVisit = async () => {
        let count = props.overlayVisible;
        props.setOverlayVisible( (count) => count + 1);

        const items = await axios.get(process.env.REACT_APP_API_ENDPOINT+'/visits/'+props.match.params.visit+'/',
            { withCredentials: true, headers: {'Content-Type': 'application/json'}});

        if (items.data.operator) {
            setOperatorOptions([{value:items.data.operator.id, label: items.data.operator.username + " (" + items.data.operator.name + ")"}]);
        }

        if (items.data.equipment_certification) {
            setEquipmentOptions([{value:items.data.equipment_certification.id, label: items.data.equipment_certification.equipment_make_model}]);
        }

        console.log(items.data);

        if (items.data.exam_status === 'pending') {
            items.data.related_visits = [];
            setVisit(items.data);
            props.history.push('/studies/'+props.match.params.id+'/center/' + props.match.params.center + '/patients/'+ props.match.params.patient + '/visits');
        }
        else {
            setVisit(items.data);
        }

        props.setOverlayVisible( (count) => count - 1);
    };

    const [equipmentOptions, setEquipmentOptions] = useState([]);
    const [operatorOptions, setOperatorOptions] = useState([]);
    const [showAdminFiles, setShowAdminFiles] = useState(false);
    const [uploadedFiles, setUploadedFiles] = useState([]);
    const [uploadedFilesComment, setUploadedFilesComment] = useState("");
    const [visit, setVisit] = useState({
        exam_date: '',
        patient: {
            study: {},
            center: {},
        },
        study_exam: {
            exam_type: {},
        },
        user_submitted: {},
        operator: {},
        grader: {},
        visit_files: [],
        related_visits: [],
        grading_comment: '',
        grading_internal_comment: '',
        form_vars: [],
        equipment_certification: 0,
    });

    const [percentUploaded, setPercentUploaded] = useState(0);

    const onChangeHandler = (ev) => {
        let name = ev.target.name;
        let val = ev.target.value;

        setVisit({...visit, [name]: val});
    };

    const submitHandler = async e => {
        e.preventDefault();

        const errMsg = "Sorry, something went wrong when adding this center. Please contact your IT helpdesk.";

        let count = props.overlayVisible;
        props.setOverlayVisible( (count) => count + 1);

        // change status of exam
        await axios.post(process.env.REACT_APP_API_ENDPOINT+'/visits/'+visit.id+'/change_exam_status/', {'exam': visit.study_exam.id, 'status': newStatus, 'message': newStatusMessage}, { withCredentials: true})
            .then((response) => {

            if (response.status === 200) {
                setVisit({...visit, 'exam_status': newStatus});
                closePopup();
                props.setOverlayVisible( (count) => count - 1);
                props.setFilesUploading(false);
            }
            else {
                alert(errMsg);
            }

        }, (error) => {
            closePopup();
            props.setOverlayVisible( (count) => count - 1);
            props.setFilesUploading(false);
            alert(errMsg);
        });


    };

    const submittedEyeOptions = [
        {value:"left", label: "Left"},
        {value:"right", label: "Right"},
        {value:"both", label: "Both"},
    ];

    const getOperatorChecked = () => {

        if (visit.operator) {
            return operatorOptions.filter(o => o.value === visit.operator.id);
        }

        return "";
    }

    const getEquipmentCertificationChecked = () => {

        if (visit.equipment_certification) {
            return equipmentOptions.filter(o => o.value === visit.equipment_certification.id);
        }

        return "";
    }

    const getEyeChecked = () => {

        if (visit.left_eye_submit && visit.right_eye_submit) {
            return submittedEyeOptions.filter(o => o.value === "both");
        }
        else if (visit.left_eye_submit && !visit.right_eye_submit) {
            return submittedEyeOptions.filter(o => o.value === "left");
        }
        else if (!visit.left_eye_submit && visit.right_eye_submit) {
            return submittedEyeOptions.filter(o => o.value === "right");
        }
        else {
            return "";
        }
    }

    const statusOptions = [
        {value:"submitted", label: "Submitted"},
        {value:"received", label: "Received"},
        {value:"rejected", label: "Rejected"},
    ];

    const onStatusChangeHandler = selected => {
        let item = statusOptions.find(i => {
            return i.value === selected;
        });

        if (props.userAuth.user.role == 'monitor') {
            return false;
        }

        if (item) {
            setConfirmStatus(item.label);
            setNewStatus(item.value);
        }
    };
    const [confirmStatus, setConfirmStatus] = useState("");
    const [newStatus, setNewStatus] = useState("");
    const [newStatusMessage, setNewStatusMessage] = useState("");

    const [showRenameFilePopup, setShowRenameFilePopup] = useState(false);
    const [renameFileName, setRenameFileName] = useState("");
    const [renameFileId, setRenameFileId] = useState(0);

    const closePopup = () => {
        setConfirmStatus("");
        setNewStatusMessage("");
        setNewStatus("");
    };

    const closeRenameFilePopup = () => {
        setRenameFileName("");
        setRenameFileId(0);
        setShowRenameFilePopup(false);
    };

    const submitRenameFilePopup = async () => {

        const errMsg = "Sorry, something went wrong when renaming this file. Please contact your IT helpdesk.";
        let name = renameFileName;

        // check if name is trimmed
        if (!name || !name.trim()) {
            return false;
        }

        name = name.trim();

        let count = props.overlayVisible;
        props.setOverlayVisible( (count) => count + 1);

        // change status of exam
        await axios.post(process.env.REACT_APP_API_ENDPOINT+'/visits/'+visit.id+'/file/'+ renameFileId +'/rename/', {'name': name}, { withCredentials: true})
            .then((response) => {

            let files, ext;
            if (response.status === 200) {

                files = visit.visit_files.map(f => {
                    if (f.id == renameFileId) {
                        ext = f.name.lastIndexOf('.');
                        if (ext !== -1) {
                            f.name = name + f.name.substr(ext);
                        }
                        else {
                            f.name = name;
                        }
                    }

                    return f;

                })

                setVisit({...visit, 'visit_files': files});
                setRenameFileName("");
                setRenameFileId(0);
                setShowRenameFilePopup(false);
                props.setOverlayVisible( (count) => count - 1);
            }
            else {
                props.setOverlayVisible( (count) => count - 1);
                alert(errMsg);
            }

        }, (error) => {
            props.setOverlayVisible( (count) => count - 1);
            alert(errMsg);
        });

    };

    const isGeneralVarVisible = (uid) => {
        if(visit.study_exam.hasOwnProperty('technician_form_vars')) {
            if(visit.study_exam.technician_form_vars.find(v => v.technician_form_var.uid === uid && v.technician_form_var.group === 'general')) {
                return true;
            }
        }

        return false;
    };

    const resendNotification = async (e) => {
        const errMsg = "Sorry, something went wrong when renaming this file. Please contact your IT helpdesk.";

        let count = props.overlayVisible;
        props.setOverlayVisible( (count) => count + 1);

        // change status of exam
        await axios.get(process.env.REACT_APP_API_ENDPOINT+'/visits/'+visit.id+'/resend_notification/', { withCredentials: true})
        .then((response) => {

            if (response.status === 200) {
                props.setOverlayVisible( (count) => count - 1);
            }
            else {
                props.setOverlayVisible( (count) => count - 1);
                alert(errMsg);
            }
        }, (error) => {
            props.setOverlayVisible( (count) => count - 1);
            alert(errMsg);
        });
    };

    return (
        <React.Fragment>

        {showRenameFilePopup && (
            <React.Fragment>
            <div className="modal-backdrop fade in"></div>
            <div id="alertModal" className="modal hide fade in" tabIndex="-1" role="dialog" aria-labelledby="myModalLabel" aria-hidden="false" style={{display: "block"}}>
                <div className="modal-body"><br/>Do you want to change the name of this file?<br/><br/>

                    <input
                        type="text"
                        name="rename-file"
                        style={{width: "90%"}}
                        onChange={(ev) => setRenameFileName(ev.target.value)}
                        value={renameFileName}>
                    </input>
                </div>

                <div className="modal-footer">
                    <button onClick={submitRenameFilePopup} id="alert-modal-ok" aria-hidden="true" className="btn small orange align-center right">Save</button>
                    <button onClick={closeRenameFilePopup} data-dismiss="modal" id="alert-modal-cancel" aria-hidden="true" className="btn small grey align-center right">Cancel</button>
                </div>
            </div>
            </React.Fragment>
        )}

        {confirmStatus && (
            <React.Fragment>
            <div className="modal-backdrop fade in"></div>
            <div id="alertModal" className="modal hide fade in" tabIndex="-1" role="dialog" aria-labelledby="myModalLabel" aria-hidden="false" style={{display: "block"}}>
                <div className="modal-body"><br/>Do you really want to change the status of this exam to "{confirmStatus}"?<br/><br/>

                    {confirmStatus === 'Rejected' && (
                        <textarea
                            value={newStatusMessage}
                            placeholder="Please write the reasons for rejecting this exam"
                            className="form-comment"
                            onChange={(ev) => setNewStatusMessage(ev.target.value)}
                            name="reject-message" >
                        </textarea>
                    )}
                </div>

                <div className="modal-footer">
                    <button onClick={submitHandler} id="alert-modal-ok" aria-hidden="true" className="btn small orange align-center right">Yes</button>
                    <button onClick={closePopup} data-dismiss="modal" id="alert-modal-cancel" aria-hidden="true" className="btn small grey align-center right">No</button>
                </div>
            </div>
            </React.Fragment>
        )}

        <div className="home_header_container_class wide">
            <div className="content">
                <div className="breadcrumbs">
                    <div className="path">
                        <ul>
                            <li><span className="orange text-shadow">{visit.patient.study.acronym ? visit.patient.study.acronym.toUpperCase() : ''}</span></li>
                            <li className="separator text-shadow">&gt;</li>
                            <li><span className="orange text-shadow">{visit.patient.center.study_site_num}</span></li>
                            <li className="separator text-shadow">&gt;</li>
                            <li><span className="orange text-shadow">Patient {visit.patient.patient_num}</span></li>
                            <li className="separator text-shadow">&gt;</li>
                            <li>{visit.scheduled_visit ? visit.scheduled_visit.name : 'Unscheduled visit'}</li>
                        </ul>
                    </div>
                </div> {/* .breadcrumbs */}

                <div className="report-info">
                    <div className="exams-report">
                        <ul>
                            {visit.related_visits && visit.related_visits.map((vis, i) => (
                            <li key={i}>
                                <Link
                                    className={visit.study_exam.exam_type.id === vis.study_exam.exam_type.id ? "selected" : ""}
                                    to={"/studies/"+props.match.params.id+"/center/"+props.match.params.center+"/patients/"+props.match.params.patient+"/visits/"+vis.id}
                                    onClick={() => {props.match.params.visit = vis.id; getVisit(); }}
                                >{vis.study_exam.exam_type.name}</Link>
                            </li>
                            ))}
                        </ul>

                        {visit.patient.study.status === "open" && (
                        <Link
                            to={"/studies/" + props.match.params.id + "/center/" + props.match.params.center + "/patients/" + props.match.params.patient}
                            className="btn normal grey right"
                        >
                                <span className="icon user-small"></span>Edit patient details
                        </Link>
                        )}
                    </div>
                    <div className="content-report">
                        <div className="content-visit-exam">
                            <div className="visit-content">
                                <div className="main-form">
                                    <form onSubmit={submitHandler} id="register-exam" method="post">
                                        <VisitSubmissionInfo visit={visit}/>
                                        <div className="form-group">
                                            <div className="span5 insert-visit" style={{width:"46%"}}>
                                                {isGeneralVarVisible('exam-date') && (
                                                <div className="row exam-date" style={{paddingTop: "4px"}}>
                                                    <div className="label">
                                                        <label>Exam Date</label>
                                                    </div>
                                                    <div className="input">
                                                        <input
                                                            type="text"
                                                            name="exam_date"
                                                            value={visit.exam_date || ''}
                                                            disabled="disabled"
                                                            onChange={(ev) => onChangeHandler(ev)}
                                                        />
                                                    </div>
                                                </div>
                                                )}

                                                <div className="row" style={{paddingTop: "4px"}}>
                                                    <div className="label">
                                                        <label>Patient Study Eye</label>
                                                    </div>
                                                    <div className="input">
                                                        <div className="study-eye">
                                                            <span className={"icon min-eye" + (visit.patient.study_right_eye ? "" : " enabled")}></span>
                                                            <span className={"icon min-eye" + (visit.patient.study_left_eye ? "" : " enabled")}></span>
                                                        </div>
                                                    </div>
                                                </div>

                                                {isGeneralVarVisible('submitted-eye') && (
                                                <div className="row" style={{paddingTop: "4px"}}>
                                                    <div className="label">
                                                        <label>Submitted Eye(s)</label>
                                                    </div>
                                                    <div className="input">


                                                        <CorcSelect
                                                            options={submittedEyeOptions}
                                                            name="submitted_eyes"
                                                            id="submitted_eyes"
                                                            value={getEyeChecked()}
                                                            isSearchable={false}
                                                            containerStyles={{width: "175px", float: "left"}}
                                                            isDisabled={"disabled"}
                                                        />

                                                    </div>
                                                </div>
                                                )}

                                                {isGeneralVarVisible('operator') && (
                                                <div className="row" style={{paddingTop: "4px"}}>
                                                    <div className="label">
                                                        <label>Operator</label>
                                                    </div>
                                                    <div className="input">

                                                        <CorcSelect
                                                            options={operatorOptions}
                                                            name="operator"
                                                            id="operator"
                                                            value={getOperatorChecked()}
                                                            isSearchable={false}
                                                            containerStyles={{width: "275px", float: "left"}}
                                                            isDisabled={"disabled"}
                                                        />
                                                    </div>
                                                </div>
                                                )}
                                                {isGeneralVarVisible('equipment') && (
                                                <div className="row" style={{paddingTop: "4px"}}>
                                                    <div className="label">
                                                        <label className="required" htmlFor="equipment">Equipment *</label>
                                                    </div>

                                                    <CorcSelect
                                                        options={equipmentOptions}
                                                        name="equipment"
                                                        id="equipment"
                                                        value={getEquipmentCertificationChecked()}
                                                        isSearchable={false}
                                                        containerStyles={{width: "175px", float: "left"}}
                                                        isDisabled="disabled"
                                                    />

                                                </div>
                                                )}
                                            </div> {/* .span5 */}
                                            <div className="span5" style={{width: "54%"}}>
                                                {isGeneralVarVisible('comment') && (
                                                <textarea
                                                    className="submit-comment"
                                                    placeholder="Comment"
                                                    style={{height:"164px !important"}}
                                                    disabled="disabled"
                                                    value={visit.comment}
                                                ></textarea>
                                                )}
                                            </div> {/* .span5 */}
                                            <div className="span5" style={{width: "33.5%", float: "right", marginBottom: "10px", marginTop: "-8px"}}>
                                                <div className="row" style={{paddingTop: "4px"}}>
                                                    <div className="label">
                                                        <label htmlFor="status" className="required">Exam Status <span className="required"></span></label>
                                                    </div>
                                                    <CorcSelect
                                                        options={statusOptions}
                                                        name="status"
                                                        id="status"
                                                        value={statusOptions.filter(o => o.value === visit.exam_status)}
                                                        onChange={selectedOption => onStatusChangeHandler(selectedOption.value)}
                                                        isSearchable={false}
                                                        containerStyles={{width: "175px", float: "left"}}
                                                        isDisabled={props.userAuth.user.role == 'monitor' || visit.patient.study.status != 'open' ? "disabled" : ""}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="form-group visit-other-vars">
                                            <VisitOtherVars
                                                visit={visit}
                                                setVisit={setVisit}
                                            />
                                        </div>
                                    </form>

                                    <VisitFiles
                                        visit={visit}
                                        setVisit={setVisit}
                                        showAdminFiles={showAdminFiles}
                                        setShowAdminFiles={setShowAdminFiles}
                                        uploadedFiles={uploadedFiles}
                                        setUploadedFiles={setUploadedFiles}
                                        setUploadedFilesComment={setUploadedFilesComment}
                                        uploadedFilesComment={uploadedFilesComment}
                                        userAuth={props.userAuth}
                                        setShowRenameFilePopup={setShowRenameFilePopup}
                                        setRenameFileName={setRenameFileName}
                                        setRenameFileId={setRenameFileId}
                                        setOverlayVisible={props.setOverlayVisible}
                                        overlayVisible={props.overlayVisible}
                                        percentUploaded={percentUploaded}
                                        setPercentUploaded={setPercentUploaded}
                                        setFilesUploading={props.setFilesUploading}
                                    />

                                    <div className="form-group">

                                        <div className="row" style={{paddingTop: "4px"}}>
                                            <div className="label">
                                                <label htmlFor="status" className="">Grading Comment</label>
                                            </div>
                                            <textarea
                                                className="submit-comment"
                                                name="grading_comment"
                                                style={{height:"164px !important", width: "80%"}}
                                                value={visit.grading_comment || ''}
                                                disabled="disabled"
                                            ></textarea>
                                        </div>

                                        {(props.userAuth.user.role ==='admin' || props.userAuth.user.role == 'grader') && (
                                        <div className="row" style={{paddingTop: "4px"}}>
                                            <div className="label">
                                                <label htmlFor="status" className="">Grading Internal Comment</label>
                                            </div>
                                            <textarea
                                                className="submit-comment"
                                                name="grading_internal_comment"
                                                style={{height:"164px !important", width: "80%"}}
                                                value={visit.grading_internal_comment || ''}
                                                disabled="disabled"
                                            ></textarea>
                                        </div>
                                        )}


                                    </div>


                                    <div className="form-actions" style={{float: "right"}}>
                                        {(props.userAuth.user.role === 'admin') && visit.patient.study.status === "open" && visit.patient.study.notifications.find(n => n.uid === 'exam-submitted') && (
                                            <button
                                                className="btn normal grey align-center left other"
                                                onClick={resendNotification}
                                            >Resend notification</button>
                                        )}
                                        <Link to={'/studies/'+props.match.params.id+'/center/' + props.match.params.center + '/patients/'+ props.match.params.patient + '/visits'} className="btn small grey align-center left">Back</Link>
                                    </div>
                                </div> {/* .main-form */}
                            </div> {/* .visit-content */}
                        </div> {/* .content-visit-exam */}
                    </div> {/* .content-report */}
                </div>

            </div>
        </div>
        </React.Fragment>
    );
}

export default VisitReport;

function VisitSubmissionInfo(props) {

    let evaluatedBy = "", submittedBy = "", rejectedMessage = "";

    const getStatusDisplay = status => {

        if (status === 'missed') {
            return 'Missed';
        }
        else if (status === 'pending') {
            return 'Scheduled';
        }
        else if (status === 'rejected') {
            return 'Rejected';
        }
        else if (status === 'received') {
            return 'Received';
        }
        else if (status === 'submitted') {
            return 'Submitted'
        }

        return '';

    }

    if (props.visit.user_graded) {
        evaluatedBy = "<p>EVALUATED BY: "+ props.visit.user_graded.name+' '+props.visit.user_graded.last_name+"</p><p>EVALUATED ON: "+formatDate(props.visit.grading_date, true)+"</p><br />";
    }

    if (props.visit.user_submitted) {
        submittedBy = '<p>SUBMITTED BY: '+props.visit.user_submitted.name+' '+props.visit.user_submitted.last_name+'</p><p>SUBMITTED ON: '+formatDate(props.visit.submission_date, true)+'</p><br />';
    }

    if (props.visit.exam_status == 'rejected' && props.visit.reject_message) {
        rejectedMessage = '<br /><p>REJECTED MESSAGE: '+props.visit.reject_message+"</p><br />";
    }

    return (
        <div className="form-group content">
            <br />

            { ReactHtmlParser (evaluatedBy) }
            { ReactHtmlParser (submittedBy) }

            <p>VISIT STATUS: {getStatusDisplay(props.visit.exam_status)}</p>

            { ReactHtmlParser (rejectedMessage) }

        </div>
    );
}

function VisitFiles(props) {
    return (
        <React.Fragment>
            {props.visit.visit_files.map( (file, i) => (
            <VisitFileItem
                key={i}
                file={file}
                visit={props.visit}
                setShowRenameFilePopup={props.setShowRenameFilePopup}
                setRenameFileName={props.setRenameFileName}
                setRenameFileId={props.setRenameFileId}
                userAuth={props.userAuth}
            />
            ))}

            <VisitFileUpload
                setUploadedFilesComment={props.setUploadedFilesComment}
                uploadedFilesComment={props.uploadedFilesComment}
                showAdminFiles={props.showAdminFiles}
                setShowAdminFiles={props.setShowAdminFiles}
                uploadedFiles={props.uploadedFiles}
                setUploadedFiles={props.setUploadedFiles}
                visit={props.visit}
                setVisit={props.setVisit}
                userAuth={props.userAuth}
                setOverlayVisible={props.setOverlayVisible}
                overlayVisible={props.overlayVisible}
                percentUploaded={props.percentUploaded}
                setPercentUploaded={props.setPercentUploaded}
                setFilesUploading={props.setFilesUploading}
            />
        </React.Fragment>
    );
}


function VisitFileItem(props) {
//    const onChangeHandler = ev => {
//        let val = ev.target.value;
//        let name = ev.target.name;
//    };

    const style = {
        width: "40%",
        float: "right",
        marginRight: "32px",
        display: "block"
    }

    const renameFile = () => {

        let name = props.file ? props.file.name : '';
        let id = props.file ? props.file.id : 0;
        let ext = name.lastIndexOf('.');

        if (ext !== -1) {
            name = name.substr(0, name.lastIndexOf('.'));
        }

        props.setRenameFileName(name);
        props.setRenameFileId(id);
        props.setShowRenameFilePopup(true);
    }

    return (
        <div className="form-group">
            <div className="file-uploaded">
                <div className="row">
                    <a target="_blank" rel="noopener noreferrer" href={process.env.REACT_APP_API_ENDPOINT+'/visits/'+props.visit.id+'/file/'+props.file.id+'/download/'}  className="cancel-btn-class btn small grey align-center">Download</a>


                    <div className="file-name">
                        {props.userAuth.user.role === 'admin' && (
                            <span onClick={() => renameFile()} style={{'cursor': 'pointer'}} className="filename-class">{props.file ? props.file.name : ''}<b>{" (" + prettyBytes(props.file && props.file.file.size ? props.file.file.size : 0) + ")"}</b></span>
                        )}
                        {props.userAuth.user.role != 'admin' && (
                            <span className="filename-class">{props.file ? props.file.name : ''}<b>{" (" + prettyBytes(props.file && props.file.file.size ? props.file.file.size : 0) + ")"}</b></span>
                        )}
                    </div>

                   <div className="file-comment" style={style} ref={(node) => {
                      if (node) {
                        node.style.setProperty("float", "right", "important");
                      }
                    }}>
                        <input
                            maxLength="255"
                            type="text"
                            className="uploaded_file"
                            value={props.file ? props.file.description || '' : ''}
                            placeholder="Comment"
                            //onChange={(ev) => onChangeHandler(ev)}
                            disabled="disabled"
                        />
                    </div>
                </div>
            </div>
        </div>
    );

}

function VisitFileUpload(props) {
    const showAdminFilesHandler = () => {
        props.setShowAdminFiles(true);
    };

    const onChangeHandler = ev => {
        let val = ev.target.value;

        props.setUploadedFilesComment(val);
    };

    const adminFileSubmitHandler = (event) => {

        const url = process.env.REACT_APP_API_ENDPOINT+'/visits/'+props.visit.id+'/uploader/';
        const formData = new FormData();
        const config = {
            withCredentials: true,
            headers: {
                'content-type': 'multipart/form-data'
            },
            onUploadProgress: progressEvent => {
                props.setPercentUploaded( Math.round( (progressEvent.loaded * 100) / progressEvent.total ) );
            }
        };

        event.preventDefault();

        let count = props.overlayVisible;
        props.setFilesUploading(true);
        props.setOverlayVisible( (count) => count + 1);

        if (!props.uploadedFiles.length) {
            props.setUploadedFilesComment('');
            props.setUploadedFiles([]);
            props.setShowAdminFiles(false);
            props.setFilesUploading(false);
            return false;
        }

        for (let i in props.uploadedFiles) {
            formData.append('file',props.uploadedFiles[i].file);
        }
        formData.set('comment', props.uploadedFilesComment);

        post(url, formData,config).then(function (response) {

           if (response.status === 200 && response.data) {
               props.setUploadedFilesComment('');
               props.setUploadedFiles([]);
               props.setShowAdminFiles(false);

               // update visit object
               props.setVisit(response.data);
               props.setPercentUploaded(0);
           }

            props.setOverlayVisible( (count) => count - 1);
            props.setFilesUploading(false);
        }, (error) => {
            props.setOverlayVisible( (count) => count - 1);
            props.setFilesUploading(false);
            alert(error);
        });

        return false;

    };

    const removeFileHandler = (ev,file) =>  {

        ev.preventDefault();

        let editedUploadedFiles = props.uploadedFiles.filter(f => f.key !== file.key);
        props.setUploadedFiles(editedUploadedFiles);

        if (!editedUploadedFiles.length) {
            props.setUploadedFilesComment('');
            props.setShowAdminFiles(false);
        }


        return false;
    };

    const onDrop = acceptedFiles => {
        let newFiles = [];

        acceptedFiles.map(file => {
            newFiles.push({file: file, key: Math.floor(Math.random(5) * 1000), name: file.name});
            return false;
        });

        newFiles = newFiles.concat(props.uploadedFiles);
        props.setUploadedFiles(newFiles);
    };

    const {getRootProps, getInputProps} = useDropzone({onDrop});

    return (
        <React.Fragment>
        {!props.showAdminFiles && props.visit.patient.study.status === "open" && props.userAuth.user.role != 'monitor' && (
        <div className="form-group">
            <button
                className="btn large orange  align-center right"
                onClick={() => showAdminFilesHandler()}
            >Add files</button>
        </div>
        )}

        {props.showAdminFiles && (
        <form id="fileupload" method="post" encType="multipart/form-data">
            <div className="form-group">
                <div id="files" className="files">
                    <div {...getRootProps({
                        className: "info"
                    })}>
                        <span className="icon submit ico"></span>
                        <h2 className="text-shadow">Drop files here</h2>
                        <span className="or">
                            <hr />
                            <span>or</span>
                            <hr />
                        </span>
                        <span className="btn large orange fileinput-button">
                            <span>Select files from your computer</span>
                            <input {...getInputProps()} />
                        </span>
                    </div>
                    <div id="filesProgress" className="clearfix">
                        {props.uploadedFiles.map((file, i) => (
                        <div
                            key={i}
                            className="file-upload-box">
                            <p>
                                <span className="file-name">{file.name}</span>
                                <span className="file-actions">
                                    <button
                                        className="btn small grey align-center left other"
                                        onClick={(ev) => removeFileHandler(ev, file)}
                                    >REMOVE</button>
                                </span>
                                <span className="progress active">
                                    <span className="progress-bar progress-bar-warning" role="progressbar" style={{width: `${props.percentUploaded}%`}}></span>
                                    <span className="progress_value">
                                        {props.percentUploaded ? `${props.percentUploaded} % (a few seconds left)` : 'READY TO UPLOAD' }
                                    </span>
                                </span>
                            </p>
                        </div>
                        ))}
                    </div>
                </div>
                <textarea
                    className="submit-comment"
                    data-disabled="false"
                    name="info_admin_submit"
                    placeholder="Reason for adding files as admin.."
                    style={{margin: "10px 15px", width: "94.5%"}}
                    onChange={(ev) => onChangeHandler(ev)}
                    value={props.uploadedFilesComment}
                ></textarea>
                <p>
                    <input
                        type="submit"
                        value="Submit"
                        className="btn small orange align-center right"
                        onClick={adminFileSubmitHandler}
                    />
                </p>
            </div>
        </form>
        )}
        </React.Fragment>
    );
}

function VisitOtherVars(props) {
    return (
        <React.Fragment>
        {props.visit.study_exam.technician_form_vars && props.visit.study_exam.technician_form_vars.map((v, i) => v.technician_form_var.group === 'other' && (
            <div key={i}>
                <VisitVarItem
                    field={v.technician_form_var}
                    visit={props.visit}
                    setVisit={props.setVisit}
                />
            </div>
        ))}
        </React.Fragment>
    );
}

function VisitVarItem(props) {

    const onListChangeHandler = (uid, selectedOption) => {
        let editedVisit = JSON.parse(JSON.stringify(props.visit));

        const form_var = editedVisit.study_exam.technician_form_vars.find(c => {
            return c.technician_form_var.uid === uid;
        });

        if (!form_var) {
            return false;
        }

        let found = editedVisit.form_vars.find(c => {
            if (c.var_type) {
                return form_var.uid === c.var_type.uid;
            }
            else {
                return undefined;
            }

        });

        if (found === undefined) {
            let cVar = {'visit': editedVisit.id, 'var_type': form_var, 'value': selectedOption};

            editedVisit.form_vars.push(cVar);
        }
        else {
            found.value = selectedOption;
        }

        props.setVisit(editedVisit);
    };

    const onChangeHandler = (ev) => {
        let name = ev.target.name;
        let val = ev.target.value.toString();
        let editedVisit = JSON.parse(JSON.stringify(props.visit));
        let tmp, tmp_array;

        let form_var = props.visit.study_exam.technician_form_vars.find(c => {
            return c.technician_form_var.uid === name;
        })

        if (!form_var) {
            return false;
        }
        else {
            form_var = form_var.technician_form_var;
        }

        let found = editedVisit.form_vars.find(c => {
            if (c.var_type) {
                return form_var.uid === c.var_type.uid;
            }
            else {
                return undefined;
            }

        });

        if (found === undefined) {
            let cVar = {'visit': editedVisit.id, 'var_type': form_var, 'value': val};

            editedVisit.form_vars.push(cVar);
        }
        else {
            if (form_var.field_type === 'check_many') {
                tmp_array = found.value.split(",");
                tmp = tmp_array.indexOf(val);
                if (tmp === -1) {
                    tmp_array.push(val);
                    found.value = tmp_array.toString();
                }
                else {
                    tmp_array.splice(tmp, 1);
                    // is it the last value?
                    if (!tmp_array.length) {
                        for(let i=editedVisit.form_vars.length-1;i>=0;--i) {
                            if(editedVisit.form_vars[i].var_type.uid === form_var.uid) {
                                editedVisit.form_vars.splice(i, 1);
                                break;
                            }
                        }
                    }
                    found.value = tmp_array.toString();
                }
            }
            else {
                found.value = val;
            }
        }

        props.setVisit(editedVisit);

    };

    const onChangeDate = (date, form_var) => {
        let txtDate = '';
        let editedVisit = JSON.parse(JSON.stringify(props.visit));

        if (date) {
            txtDate = date.getFullYear() + '-' + (date.getMonth()+1) + '-' + date.getDate();
        }

        if (!form_var) {
            return false;
        }

        let found = editedVisit.form_vars.find(c => {
            if (c.var_type) {
                return form_var.uid === c.var_type.uid;
            }
            else {
                return undefined;
            }

        });

        if (found === undefined) {
            editedVisit.form_vars.push({'visit': editedVisit.id, 'value': txtDate, 'var_type': form_var});
        }
        else {
            found.value = txtDate;
        }

        props.setVisit(editedVisit);

    };

    const getVisitField = (field_id) => {

        let found = props.visit.form_vars.find(c => {
            if (c.var_type) {
                return c.var_type.id === field_id;
            }
            else {
                return undefined;
            }
        });

        if (found === undefined) {
            return "";
        }

        if(found.var_type.field_type === 'list') {
            let selOption = found.var_type.technician_vars_options.find(e => parseInt(e.id, 10) === parseInt(found.value, 10));
            return {label: selOption.value, value: selOption.id};
        }
        else {
            return found.value;
        }
    }

    const getMultipleVisitField = (field_id) => {

        let found = props.visit.form_vars.find(c => {
            if (c.var_type) {
                return c.var_type.id === field_id;
            }
            else {
                return undefined;
            }
        });

        if (found === undefined) {
            return [];
        }


        return found.value.split(",");
    }

    const buildField = (field) => {
        if(field.field_type === 'input') {
            return (
                <input
                    name={field.uid}
                    type="text"
                    value={getVisitField(field.id)}
                    onChange={(ev) => onChangeHandler(ev)}
                    disabled={true}
                />
            );
        }
        else if(field.field_type === 'date') {
            return (
                <input
                    name={field.uid}
                    selected={getVisitField(field.id)}
                    onChange={(date) => onChangeDate(date, field)}
                    disabled={true}
                />
            );
        }
        else if(field.field_type === 'text') {
            return (
                <textarea
                    name={field.uid}
                    value={getVisitField(field.id)}
                    onChange={(ev) => onChangeHandler(ev)}
                    disabled={true}
                >
                </textarea>
            );
        }
        else if(field.field_type === 'list') {
            let listOptions = [];
            field.technician_vars_options.map(opt => listOptions.push({label: opt.value, value: opt.id}));

            return (
                <CorcSelect
                    name={field.uid}
                    id={field.uid}
                    value={getVisitField(field.id)}
                    onChange={(selectedOption) => onListChangeHandler(field.uid, selectedOption.value)}
                    options={listOptions}
                    placeholder="-"
                    containerStyles={{width: "125px"}}
                    isDisabled={true}
                />
            );
        }
        else if(field.field_type === 'check_one') {

            return (
                <div className="checkbox-group check">
                {field.technician_vars_options.map((opt, i) => (
                    <React.Fragment
                        key={i}
                    >
                    <input
                        value={opt.id}
                        id={field.uid + '_' + i}
                        name={field.uid}
                        type="radio"
                        checked={getVisitField(field.id) === opt.id.toString()}
                        onChange={(ev) => onChangeHandler(ev)}
                        disabled={true}
                    />
                    <label htmlFor={field.uid + '_' + i}>{opt.value}</label>
                    </React.Fragment>
                ))}
                </div>
            );
        }
        else if(field.field_type === 'check_many') {

            return (
                <div className="checkbox-group check form-flex">
                {field.technician_vars_options.map((opt, i) => (
                    <div className="form-flex-3-col" key={i}>
                        <input
                            value={opt.id}
                            id={field.uid + '_' + i}
                            name={field.uid}
                            type="checkbox"
                            checked={getMultipleVisitField(field.id).indexOf(opt.id.toString()) !== -1}
                            onChange={(ev) => onChangeHandler(ev)}
                            disabled={true}
                        />
                        <label htmlFor={field.uid + '_' + i}>{opt.value}</label>
                    </div>
                ))}
                </div>
            );
        }

        else if(field.field_type === 'eye') {
            return (
                <div className="checkbox-group check">
                    <input
                        value="right"
                        id={field.uid + '_re'}
                        name={field.uid}
                        type="radio"
                        checked={getVisitField(field.id) === 'right'}
                        onChange={(ev) => onChangeHandler(ev)}
                        disabled={true}
                    />
                    <label htmlFor={field.uid + '_re'}>RE</label>
                    <input
                        value="left"
                        id={field.uid + '_le'}
                        name={field.uid}
                        type="radio"
                        checked={getVisitField(field.id) === 'left'}
                        onChange={(ev) => onChangeHandler(ev)}
                        disabled={true}
                    />
                    <label htmlFor={field.uid + '_le'}>LE</label>
                    <input
                        value="both"
                        id={field.uid + '_rle'}
                        name={field.uid}
                        type="radio"
                        checked={getVisitField(field.id) === 'both'}
                        onChange={(ev) => onChangeHandler(ev)}
                        disabled={true}
                    />
                    <label htmlFor={field.uid + '_rle'}>RLE</label>
                </div>
            );
        }
    };

    return (
        <div className="row" style={{paddingTop: "4px"}}>
            <div className="label">
                <label>{props.field.name}</label>
            </div>
            <div className="input">
                {buildField(props.field)}
            </div>
        </div>
    );
}

